import { DebouncedInput } from "components/Input/DebouncedInput";
import React from "react";
import { IRequestGetTasksPacksByFilter } from "root/requests";
import { Checkbox } from "shared/ui";

type TFilterByTaskPropertiesProps = {
  onChange: (taskListFilter: IRequestGetTasksPacksByFilter) => void;
  value: IRequestGetTasksPacksByFilter;
};

export const FilterTaskPack: React.FC<TFilterByTaskPropertiesProps> = ({
  onChange,
  value,
}) => {
  return (
    <>
      <DebouncedInput
        defaultValue={value.name}
        onChange={(titlePattern) => onChange({ ...value, name: titlePattern })}
      />
      <Checkbox
        checked={value.isOnlyMine}
        onChange={() => onChange({ ...value, isOnlyMine: !value.isOnlyMine })}
      >
        Только мои варианты
      </Checkbox>
    </>
  );
};
