import React from "react";
import { InputNumber as RSInputNumber } from "rsuite";

import { InputNumberProps } from "./types";

const inputNumber = (props: InputNumberProps, ref: React.Ref<unknown>) => (
  <RSInputNumber {...props} ref={ref} />
);

export const InputNumber: React.ForwardRefExoticComponent<
  InputNumberProps<string | number> & React.RefAttributes<unknown>
> = React.forwardRef(inputNumber);
