import React, { useState } from "react";
import { Form, InputGroup } from "rsuite";
import { ValidationResult } from "stores/models/ValidationResult";
import EyeIcon from "@rsuite/icons/legacy/Eye";
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";

const styles = {
  width: 220,
};
export interface IEditPasswordStudentFormProps {
  onChangeForm: (formValue: any) => void;
  validationResult?: ValidationResult;
}

const EditPasswordStudentForm: React.FC<IEditPasswordStudentFormProps> = (
  props: IEditPasswordStudentFormProps
) => {
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visiblePasswordConfirm, setVisiblePasswordConfirm] = useState(false);
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");

  return (
    <div style={{ marginTop: 20, marginBottom: 20 }}>
      <Form
        className="we-form-change-password"
        layout="horizontal"
        onChange={(formValue: any) => {
          props.onChangeForm(formValue);
        }}
      >
        <Form.Group controlId="password">
          <Form.ControlLabel>Новый пароль</Form.ControlLabel>
          <InputGroup style={styles}>
            <Form.Control
              value={password}
              name="password"
              type={visiblePassword ? "text" : "password"}
              onChange={(value: string) =>
                setPassword(value.replace(/\s/g, ""))
              }
              errorMessage={props.validationResult?.getError("password")}
            />
            <InputGroup.Button
              onClick={() => {
                setVisiblePassword(!visiblePassword);
              }}
            >
              {visiblePassword ? <EyeIcon /> : <EyeSlashIcon />}
            </InputGroup.Button>
          </InputGroup>
        </Form.Group>
        <Form.Group controlId="passwordConfirm">
          <Form.ControlLabel>Подтвердите пароль</Form.ControlLabel>
          <InputGroup style={styles}>
            <Form.Control
              value={passwordConfirm}
              name="passwordConfirm"
              type={visiblePasswordConfirm ? "text" : "password"}
              onChange={(value: string) =>
                setPasswordConfirm(value.replace(/\s/g, ""))
              }
              errorMessage={props.validationResult?.getError("passwordConfirm")}
            />
            <InputGroup.Button
              onClick={() => {
                setVisiblePasswordConfirm(!visiblePasswordConfirm);
              }}
            >
              {visiblePasswordConfirm ? <EyeIcon /> : <EyeSlashIcon />}
            </InputGroup.Button>
          </InputGroup>
        </Form.Group>
      </Form>
    </div>
  );
};
export default EditPasswordStudentForm;
