import React from "react";
import { PasswordRequirement } from "./PasswordRequirement";
import { MIN_PASSWORD_LENGTH, requirements } from "./constants";

type PasswordRequirementListProps = { value: string };

export const PasswordRequirementList: React.FC<
  PasswordRequirementListProps
> = ({ value }) => (
  <>
    <PasswordRequirement
      label="Содержит не менее 6 символов"
      meets={value.length >= MIN_PASSWORD_LENGTH}
    />
    {requirements.map((requirement, index) => (
      <PasswordRequirement
        key={index}
        label={requirement.label}
        meets={requirement.re.test(value)}
      />
    ))}
  </>
);
