import {  IDateRange } from "./IDateRange";
import { Role } from "./IUser";

export interface IGlobalNotification {
  id: number;
  // Заголовок
  title: string;
  // Описание
  description: string;
  // Дата начала и окончания уведомления
  dateRange: IDateRange;
  // Тип уведомления
  type: GlobalNotificationType;
  // Показывать пользователям выбранной роли
  forRole?: Role;
  // Какому конкретному пользователю показывать уведомление
  userId?: number;
}
export enum GlobalNotificationType {
  // Техническое уведомление (прим: об предстоящем обновлении системы)
  Technical = 1,
  // Информационное уведомление (прим: об обновлении в системе)
  Info = 2,
  // Предупреждение (прим: о поломке в системе)
  Alert = 3,
}
