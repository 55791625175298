import { Link } from "react-router-dom";
import { Button } from "shared/ui";

export const SuccessMessage: React.FC = () => (
  <>
    <h5 style={{ marginBottom: 10, textAlign: "center" }}>
      Оплата прошла успешно!
    </h5>
    <p style={{ marginBottom: 30, textAlign: "center" }}>
      Мы рады, что ты с нами! <p>Оплаченные материалы уже доступны ❤️</p>
    </p>

    <Button appearance="primary" block as={Link} to="/lessons/">
      Перейти к урокам
    </Button>
  </>
);
