import { observer } from "mobx-react";
import React from "react";
import { ProfileDropdownMenu } from "./ProfileDropdownMenu";
import { TPopoverProps } from "..";
import { Popover } from "shared/ui";

const PopoverProfileMenuComponent = React.forwardRef<
  HTMLDivElement,
  TPopoverProps
>(function PopoverProfileMenu({ className, onClose, left, top }, ref) {
  return (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <ProfileDropdownMenu onSelect={onClose} />
    </Popover>
  );
});
export const PopoverProfileMenu = observer(PopoverProfileMenuComponent);
