import React from "react";
import { Form } from "formik";

type Props = React.ComponentProps<typeof Form>;

export type BaseFormProps = Props;

export default function BaseForm({ children, ...rest }: BaseFormProps) {
  return <Form {...rest}>{children}</Form>;
}
