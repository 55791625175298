import { useDebouncedFunction, useIsMounted, useStore } from "hooks";
import { useNumberParams } from "hooks/useNumberParams";
import _ from "lodash";
import { useEffect, useState } from "react";
import {
  HomeworksCheckOrder,
  IRequestGetHomeworksCheckByFilter,
} from "root/requests";
import { IHomeworkCheck } from "stores";

export const useStudentHomeworkSwitch = () => {
  const { lessonId, studentId, homeworkId, packId } = useNumberParams();

  const isMounted = useIsMounted();
  const { homeworkStore } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [homeworksByLesson, setHomeworksByLesson] = useState<IHomeworkCheck[]>(
    []
  );

  useEffect(() => {
    if (!lessonId || !studentId) {
      return;
    }

    const filter: IRequestGetHomeworksCheckByFilter = {
      lessonId,
      studentId,
      count: 0,
      orderBy: HomeworksCheckOrder.SubmitDeadlineAsc,
    };

    setHomeworkChecks(filter);
  }, [lessonId, studentId, homeworkStore.onSaving]);

  const setHomeworkChecks = useDebouncedFunction(
    (filter: IRequestGetHomeworksCheckByFilter) => {
      setIsLoading(true);
      homeworkStore
        .loadByFilter(filter, { reloadDataPage: false })
        .then(
          (homeworks) =>
            isMounted() && homeworks && setHomeworksByLesson(homeworks.data)
        )
        .finally(() => setIsLoading(false));
    },
    200
  );

  const isActive: boolean =
    _.isNumber(lessonId) &&
    lessonId > 0 &&
    _.isNumber(studentId) &&
    studentId > 0 &&
    _.isNil(homeworkId) &&
    _.isNil(packId);

  const linkTo = `/checks/${lessonId}/${studentId}`;

  return { isActive, linkTo, homeworksByLesson, isLoading };
};
