import {
  AlertGlobalNotification,
  TechnicalGlobalNotification,
} from "components";
import { PaymentNotification } from "components/Notification/PaymentNotification";
import React from "react";

export const NotificationView: React.FC = () => (
  <>
    <TechnicalGlobalNotification />
    <AlertGlobalNotification />
    <PaymentNotification />
  </>
);
