import { Header, Menu } from "containers";
import { useContainerPage } from "containers/hooks";
import { observer } from "mobx-react";
import { Routes } from "react-router-dom";
import { Container, Header as RsuiteHeader } from "rsuite";
import { Col, Grid, Row } from "shared/ui";

export const ContainerPageComponent: React.FC = () => {
  const {
    isAuthorized,
    isMenuOpen,
    handleClickHeader,
    handleClickMenu,
    routes,
  } = useContainerPage();

  return (
    <div className="show-fake-browser sidebar-page">
      <Container>
        <RsuiteHeader>
          <Header onClick={handleClickHeader} />
        </RsuiteHeader>
        <Container>
          <Grid className="show-grid-container" fluid>
            <Row className="show-grid">
              <Col
                className="col-menu"
                xsHidden={isMenuOpen}
                smHidden={isMenuOpen}
                mdHidden={isMenuOpen}
                lg={isAuthorized ? 5 : 3}
                xl={isAuthorized ? 5 : 4}
                xxl={isAuthorized ? 5 : 4}
              >
                <Menu stateMenu={isMenuOpen} onClick={handleClickMenu} />
              </Col>

              <Col
                className="show-grid-col-container"
                lg={18}
                xl={18}
                xxl={16}
                style={{ marginBottom: 50 }}
              >
                <Container>
                  <Routes>{routes}</Routes>
                </Container>
              </Col>
            </Row>
          </Grid>
        </Container>
      </Container>
    </div>
  );
};
export const ContainerPage = observer(ContainerPageComponent);
