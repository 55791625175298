import { RawHTML } from "components/FormComponents";
import { observer } from "mobx-react";
import { Cell } from "rsuite-table";
import { ITask } from "stores";

interface IHtmlCellProps {
  rowData?: ITask;
  dataKey: string;
}

const HtmlCellComponent: React.FC<IHtmlCellProps> = (props) => {
  const data = props.rowData?.question;
  return (
    <Cell {...props}>
      <RawHTML html={data} style={{ display: "inline-flex" }} />
    </Cell>
  );
};
export const HtmlCell = observer(HtmlCellComponent);
