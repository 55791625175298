import CheckOutlineIcon from "@rsuite/icons/CheckOutline";
import VisibleIcon from "@rsuite/icons/Visible";
import { StatusHomeworkBtn } from "components";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Whisper } from "rsuite";
import { Col, Grid, Row, IconButton, Tooltip } from "shared/ui";
import { IAnswer, IHomeWorkTaskPack, ILesson } from "stores";

export interface IStatusPanelProps {
  answersByLesson?: IAnswer[];
  lesson: ILesson;
  taskPack?: IHomeWorkTaskPack;
  homeWorkId?: number;
  handleOnClick: () => void;
}
const StatusPanelComponent: React.FC<IStatusPanelProps> = (
  props: IStatusPanelProps
) => {
  const { lessonsStore } = useStore();

  const [score, setScore] = useState<number>();
  const [maxScore, setMaxScore] = useState<number>();

  useEffect(() => {
    const score = props.answersByLesson?.reduce((total, answer) => {
      return total + answer.score;
    }, 0);
    setScore(score);
    const maxScore = props.taskPack?.tasks.reduce((total, task) => {
      return total + (task.maxScore || 0);
    }, 0);
    setMaxScore(maxScore);
  }, [props.lesson, props.taskPack, props.homeWorkId, props.answersByLesson]);
  const handleOnClick = () => {
    lessonsStore.checkViewed(props.lesson.id);
  };
  return (
    <Grid fluid>
      <Row className="row-status-panel">
        <Col xs={12} sm={12} md={12} lg={12} xl={12} xxl={12}>
          <div style={{ marginBottom: 10 }}>
            {(!props.taskPack || !props.homeWorkId) &&
              !props.lesson.isViewed && (
                <Whisper
                  controlId="control-id-hover"
                  trigger="hover"
                  speaker={
                    <Tooltip>{"Пересмотреть сможешь в любое время 😉"}</Tooltip>
                  }
                >
                  <IconButton
                    loading={lessonsStore.onSaving}
                    icon={<CheckOutlineIcon />}
                    onClick={() => handleOnClick()}
                  >
                    отметить как просмотренный
                  </IconButton>
                </Whisper>
              )}
            {score !== undefined &&
              props.taskPack &&
              props.homeWorkId &&
              props.taskPack.isHomeWorkChecked && (
                <StatusHomeworkBtn
                  lessonId={props.lesson.id}
                  score={score}
                  maxScore={maxScore}
                  isHomeWorkChecked={props.taskPack?.isHomeWorkChecked}
                  isHomeWorkSended={props.taskPack?.isHomeWorkSended}
                />
              )}
          </div>
        </Col>
        <Col
          xs={12}
          sm={12}
          md={12}
          lg={12}
          xlHidden
          xxlHidden
          className="col-homework-panel-btn"
        >
          <div>
            <IconButton
              style={{ marginBottom: 10 }}
              placement="right"
              icon={<VisibleIcon />}
              onClick={() => props.handleOnClick()}
            >
              скрипты и домашка
            </IconButton>
          </div>
        </Col>
      </Row>
    </Grid>
  );
};
export const StatusPanel = observer(StatusPanelComponent);
