import { observer } from "mobx-react";
import React from "react";
import { StyleHelper } from "root/StyleHelper";
import { Pill } from "shared/ui";

interface IButtonInfoScoreProps {
  title: string;
  score: number;
  maxScore: number;
}

const ButtonInfoScoreComponent: React.FC<IButtonInfoScoreProps> = ({
  title,
  score,
  maxScore,
}) => {
  return (
    <Pill
      style={{ marginBottom: 6 }}
      size="sm"
      color={StyleHelper.getColorByScore(score, maxScore)}
    >
      {title}
    </Pill>
  );
};

export const ButtonInfoScore = observer(ButtonInfoScoreComponent);
