import { IHomeWorkTaskPack, IHomeWorkTaskPackRequest } from "./ITaskPack";

export interface IHomeWork {
  id: number;
  title: string;
  description: string;
  lessonId: number;
  disableForTop: boolean;
  packs: IHomeWorkTaskPack[];
}

export interface IHomeWorkRequest {
  id?: number;
  title: string;
  description?: string;
  lessonId?: number;
  disableForTop?: boolean;
  packs?: IHomeWorkTaskPackRequest[];
}

export interface IHomeworkCheck {
  tasksPackHwStudentId: number;
  score: number;
  maxScore: number;
  lessonId: number;
  packId: number;
  homeworkId: number;
  studentId: number;
  teacherId: number;
  mentorId?: number;
  homeworkStatus: HomeworkStudentStatus;
  checkStatus: HomeworkStudentCheckStatus;
  checkDeadline: Date;
  submitDeadline: Date;
}

export interface IHomeworkResult {
  lessonId: number;
  checks: IHomeworkCheck[];
}

export enum HomeworkStudentStatus {
  sendCorrect = 1, // Сдана вовремя
  sendLate = 2, // Сдана после дедлайна
  notSend = 3, // Не сдана
  sended = 4, // Сдана впринципе
}

export enum HomeworkStudentCheckStatus {
  checkedCorrect = 1, // Проверена вовремя
  checkedLate = 2, // Проверена после дедлайна
  notChecked = 3, // Не проверена
  checked = 4, // Проверена впринципе
}

export enum HomeworkStudentDeadlineStatus {
  soon = 1, // Скоро (уже прошел дедлайн сдачи)
  pass = 2, // Прошел
}

export interface ISummary {
  advantages: string;
  flaws: string;
  finalConclusion: string;
}
