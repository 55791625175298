import { IPage, IPayment, IPayOrder, TOrder } from "stores";
import * as requests from "../requests";
import { get, post } from "../WebHelper";

export class PaymentController {
  public async CalculateAmount(data: IPayOrder): Promise<IPayment | undefined> {
    return await post(`/Payment/CalculateAmount/`, data);
  }
  public async PayUrl(
    data: IPayOrder,
    expectedAmount: number
  ): Promise<string | undefined> {
    return await post(`/Payment/CreatePayUrl/${expectedAmount}`, data);
  }
  public async GetPaymentStatus(
    studentId: number | undefined = undefined,
    paymentId: string | undefined = undefined
  ): Promise<number | undefined> {
    return await get(`/Payment/GetPaymentStatus/`, {
      params: {
        studentId,
        paymentId,
      },
    });
  }
  public async GetPaymentsByFilter(
    params: requests.IRequestGetPaymentsByFilter,
    refetch?: boolean
  ): Promise<IPage<TOrder> | undefined> {
    return await get(`/Payment/Filter/`, { params, refetch });
  }
}
