import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { Modal } from "rsuite";
import { useDebouncedFunction, useStore } from "../hooks";
import { StatusPanel } from "../pages/Student/Lessons/component/StatusPanel";
import {
  IHomeWorkTaskPack,
  ILesson,
  IStudentHomework,
  RoleNames,
} from "stores";
import { TaskPackItem } from "../pages/Student/Lessons/TaskPackItem";
import { WebinarItem } from "../pages/Student/Lessons/WebinarItem";
import { authStore } from "../stores/AuthStore";
import { LessonsControlButtons } from "./LessonsControlButtons";
import { LessonsHomeWorkPanel } from "./LessonsHomeWorkPanel";
import { useLessonsControlPanel } from "./useLessonsControlPanel";
import { Rate, Panel } from "shared/ui";

export interface ILessonContentProps {
  lesson: ILesson;
  taskpackId?: number;
  homeworkId?: number;
}
const LessonContentComponent: React.FC<ILessonContentProps> = ({
  lesson,
  homeworkId,
  taskpackId,
}) => {
  const { selectedLesson, handleOnChange } = useLessonsControlPanel(lesson);
  const { homeworkStore } = useStore();
  const [openHomeWorkPanel, setOpenHomeWorkPanel] = useState(false);
  const [answersByLesson, setAnswersByLesson] = useState<IStudentHomework>();
  const [taskPack, setTaskPack] = useState<IHomeWorkTaskPack | undefined>(
    undefined
  );

  useEffect(() => {
    if (authStore.me?.role == RoleNames.Student) {
      loadAnswerByLesson(lesson.id);
    }
  }, [lesson, homeworkId, taskpackId]);

  useEffect(() => {
    if (!taskpackId || !homeworkId) {
      setTaskPack(undefined);
      return;
    }
    const homework = lesson.homeWorks?.find(
      (homeWork) => homeWork.id === homeworkId
    );
    const foundTaskPack = homework?.packs.find(
      (pack) => pack.id === taskpackId
    );
    setTaskPack(foundTaskPack);
  }, [lesson, homeworkId, taskpackId]);

  const loadAnswerByLesson = useDebouncedFunction(async (lessonId: number) => {
    const answers = await homeworkStore.getAnswers(lessonId);
    setFilteredAnswersByTaskpack(answers);
  }, 300);

  const setFilteredAnswersByTaskpack = (
    answers: IStudentHomework[] | undefined
  ) => {
    if (!answers) {
      return;
    }
    const filteredAnswers = answers?.find(
      (x) => x.packId === taskpackId && x.homeworkId === homeworkId
    );
    setAnswersByLesson(filteredAnswers);
  };

  if (!lesson.enabled) {
    return <></>;
  }

  return (
    <>
      <Modal
        open={openHomeWorkPanel}
        onClose={() => setOpenHomeWorkPanel(false)}
      >
        <Modal.Header>
          <LessonsControlButtons lesson={lesson} onChange={handleOnChange} />
        </Modal.Header>
        <Modal.Body>
          <LessonsHomeWorkPanel
            currentLesson={selectedLesson}
            lessonFromParent={lesson}
            homeworkFromParent={homeworkId}
            taskpackFromParent={taskpackId}
            onClick={() => setOpenHomeWorkPanel(false)}
            withTitle
          />
        </Modal.Body>
      </Modal>

      <StatusPanel
        answersByLesson={answersByLesson?.answers}
        lesson={lesson}
        taskPack={taskPack}
        homeWorkId={homeworkId}
        handleOnClick={() => setOpenHomeWorkPanel(true)}
      />
      {!taskpackId || !homeworkId ? (
        <>
          <WebinarItem lesson={lesson} />
          <Panel bordered style={{ marginTop: 20 }}>
            <h5>Как тебе вебчик?</h5>
            <Rate defaultValue={4} size="lg" />
          </Panel>
        </>
      ) : (
        <TaskPackItem
          answers={answersByLesson?.answers}
          taskPackId={taskpackId || 0}
          homeWorkId={homeworkId || 0}
          lesson={lesson}
        />
      )}
    </>
  );
};
export const LessonContent = observer(LessonContentComponent);
