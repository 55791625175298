import React, { useEffect, useRef } from "react";
import { Whisper, Stack } from "rsuite";
import { observer } from "mobx-react";
import { ITask, TaskType } from "stores";
import TextareaAutosize from "react-textarea-autosize";
import InfoIcon from "@rsuite/icons/legacy/Info";
import { IRequestAnswerHomework } from "root";
import { OverlayTriggerHandle } from "rsuite/esm/Overlay/OverlayTrigger";
import { Input, Tooltip } from "shared/ui";

export interface IInputAnswerProps {
  homeWorkId: number;
  task: ITask;
  answer: IRequestAnswerHomework | undefined;
  onChange?: (answer: any, taskId: number, taskType: TaskType) => void;
  disabled?: boolean;
}
const InputAnswerComponent: React.FC<IInputAnswerProps> = (
  props: IInputAnswerProps
) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const inputTextAreaRef = useRef<HTMLTextAreaElement>(null);
  const whisperRef = useRef<OverlayTriggerHandle>(null);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    if (inputTextAreaRef.current) {
      inputTextAreaRef.current.focus();
    }
  }, []);

  if (props.task.type === TaskType.FirstPart) {
    return (
      <Stack spacing={15}>
        <Input
          ref={inputRef}
          disabled={props.disabled}
          value={props.answer?.answerPart1 || ""}
          placeholder="Введите ответ"
          style={{ width: 250 }}
          onChange={(value: string) => {
            if (props.onChange) {
              if (/^\d+$/.test(value) || value.length === 0) {
                props.onChange(value, props.task.id, props.task.type);
                whisperRef.current?.close();
                return;
              }
              whisperRef.current?.open();
            }
          }}
        />
        <Whisper
          ref={whisperRef}
          speaker={<Tooltip>Ответ должен быть числом</Tooltip>}
        >
          <InfoIcon />
        </Whisper>
      </Stack>
    );
  }

  return (
    <>
      <Input
        ref={inputTextAreaRef}
        disabled={props.disabled}
        value={props.answer?.answerPart2 || ""}
        as={TextareaAutosize}
        placeholder="Введите ответ"
        style={{ resize: "none" }}
        onChange={(value) => {
          if (props.onChange) {
            props.onChange(value, props.task.id, props.task.type);
          }
        }}
      />
    </>
  );
};
export const InputAnswer = observer(InputAnswerComponent);
