import { GlobalConstants } from "root/GlobalConstants";
import { usePageFilter } from "./usePageNavigation";
import { useStore } from "./useStore";
import { IStudentTariffWithPayments } from "stores";
import { IStudentTariffsByFilterRequest } from "root/requests";

const initialFilter = {
  count: 10,
  page: 1,
};

export function useStudentTariffPageNavigation() {
  const { studentTariffStore } = useStore();

  const { filter, dataPage, setLimit, setActivePage, onChangeFilter } =
    usePageFilter<IStudentTariffWithPayments, IStudentTariffsByFilterRequest>(
      initialFilter,
      studentTariffStore,
      GlobalConstants.filterStudentTariffs
    );

  return {
    filter,
    dataPage,
    setLimit,
    setActivePage,
    onChangeFilter,
  };
}
