import { IPage, ITaskPack } from "stores";
import { get, post } from "../WebHelper";
import * as requests from "../requests";

export class TaskPackController {
  public async RemoveTaskFromPack(taskId: number, packId: number) {
    await post(`/TaskPack/task/remove/` + packId + `/` + taskId);
  }
  public async AddTaskFromPack(taskId: number, packId: number) {
    await post(`/TaskPack/task/add/` + packId + `/` + taskId);
  }
  public async GetAllPacks(
    refetch?: boolean
  ): Promise<ITaskPack[] | undefined> {
    return await get(`/TaskPack/all/`, { refetch });
  }
  public async CreatePack(model: any) {
    await post(`/TaskPack/`, model);
  }

  public async GetTasksPacksByFilter(
    data: requests.IRequestGetTasksPacksByFilter
  ): Promise<IPage<ITaskPack> | undefined> {
    return await post(`/TaskPack/filter/`, data);
  }
  public async GetTasksPackById(id: number): Promise<ITaskPack | undefined> {
    return await get(`/TaskPack/` + id);
  }
}
