export class LinkHelper {
  /**
   * @description Инициирует скачивание файлы с ссылки от сервера.
   * Создаёт невидимую ссылку с параметрами скачивания в DOM,
   * кликает на неё и сразу удаляет.
   *
   * @param linkHref Строка с ссылкой для скачивания.
   
   */
  public static fileDownload(linkHref: string) {
    const link = document.createElement("a");
    link.href = linkHref;
    link.download = linkHref.substring(linkHref.lastIndexOf("/") + 1);
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
  /**
   * @description Инициирует переход по ссылке.
   * Создаёт невидимую ссылку в DOM,
   * кликает на неё и сразу удаляет.
   *
   * @param linkHref Строка с ссылкой.
   * @param isNewWindow Если нужно открыть ссылку в новой вкладке. По дэфолту открывает в этом окне.
   */
  public static openLink(linkHref: string, isNewWindow = false) {
    const link = document.createElement("a");
    link.href = linkHref;
    isNewWindow && link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    link.remove();
  }
}
