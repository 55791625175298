import { AutoCheckIcon } from "components/Icon/AutoCheckIcon";
import { useIsMounted, useStore } from "hooks";
import React, { CSSProperties, useEffect, useState } from "react";

import { TypeAttributes } from "rsuite/esm/@types/common";
import { Button } from "shared/ui";
import { CheckHomeworkType, ILesson } from "stores";

const btnStyle: CSSProperties = {
  borderRadius: 30,
  cursor: "default",
};

interface IStatusHomeworkBtnProps {
  loading?: boolean;
  lessonId: number;
  score?: number;
  maxScore?: number;
  isHomeWorkChecked?: boolean;
  isHomeWorkSended?: boolean;
}

export const StatusHomeworkBtn: React.FC<IStatusHomeworkBtnProps> = (props) => {
  const isMounted = useIsMounted();
  const { authStore, lessonsStore } = useStore();

  const [lesson, setLesson] = useState<ILesson>();
  useEffect(() => {
    lessonsStore.findOrLoad(props.lessonId).then((lesson) => {
      if (!isMounted()) {
        return;
      }
      setLesson(lesson);
    });
  }, [props.lessonId]);

  let checkOrSendTitleBtn: React.JSX.Element | string = "";
  let checkOrSendColorBtn: TypeAttributes.Color | undefined = undefined;
  let appearanceBtn: TypeAttributes.Appearance | undefined = undefined;

  if (!props.isHomeWorkChecked && !props.isHomeWorkSended) {
    checkOrSendTitleBtn = "не сдано";
    checkOrSendColorBtn = "violet";
    appearanceBtn = "primary";
  }
  if (props.isHomeWorkSended) {
    checkOrSendTitleBtn = "на проверке";
    appearanceBtn = "default";
  }

  const score = props.score?.toString() || "-";

  if (props.isHomeWorkChecked) {
    checkOrSendTitleBtn = score + " баллов из " + props.maxScore;
    checkOrSendColorBtn = "green";
    appearanceBtn = "primary";
  }

  if (
    lesson?.checkHomeworkType === CheckHomeworkType.Auto ||
    (authStore.myMonthNumbersVideoLessons.some(
      (x) =>
        x.monthNumber === lesson?.monthNumber && x.tariffId === lesson?.tariffId
    ) &&
      props.isHomeWorkChecked)
  ) {
    checkOrSendTitleBtn = (
      <>
        <span>авто</span>
        <AutoCheckIcon />
      </>
    );
    checkOrSendColorBtn = "green";
    appearanceBtn = "primary";
  }
  return (
    <Button
      loading={props.loading}
      style={btnStyle}
      size="xs"
      color={checkOrSendColorBtn}
      appearance={appearanceBtn}
    >
      {checkOrSendTitleBtn}
    </Button>
  );
};
