import { Link } from "react-router-dom";
import { FlexboxGrid } from "rsuite";
import {
  HomeworkStudentCheckStatus,
  HomeworkStudentStatus,
  IHomeworkCheck,
} from "stores";
import { styleCenter } from "../styles";
import { Button } from "shared/ui";

interface IProps {
  check: IHomeworkCheck;
}

export const ActionCell: React.FC<IProps> = ({ check }) => {
  const notChecked =
    check.checkStatus === HomeworkStudentCheckStatus.notChecked;
  const notSend =
    check.tasksPackHwStudentId === 0 ||
    check.homeworkStatus === HomeworkStudentStatus.notSend;

  const appearance = notChecked ? "primary" : undefined;
  const color = notChecked ? "green" : undefined;
  const title = notChecked ? "проверить" : "к проверке";

  return (
    <FlexboxGrid.Item colspan={4} style={styleCenter}>
      {notSend ? (
        <></>
      ) : (
        <Button
          color={color}
          appearance={appearance}
          size="sm"
          as={Link}
          to={`/checks/${check.lessonId}/${check.studentId}/${check.homeworkId}/${check.packId}`}
        >
          {title}
        </Button>
      )}
    </FlexboxGrid.Item>
  );
};
