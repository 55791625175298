import { BlockIcon, FailedMessage, PaymentPanelLayout } from "../components";
import { useFailPayment } from "../hooks";

export const FailPayment: React.FC = () => {
  const { message } = useFailPayment();
  return (
    <PaymentPanelLayout
      icon={<BlockIcon />}
      message={<FailedMessage message={message} />}
    />
  );
};
