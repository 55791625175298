import React from "react";
import { observer } from "mobx-react";
import { ScheduleCellItem } from "./ScheduleCellItem";
import { EventType } from "stores";
import { useEvents } from "./hooks/useEvents";
import { Button } from "shared/ui";

interface IProps {
  date: Date;
  eventType: EventType | null;
  tariffId: number | null;
}

const ScheduleCellContentComponent: React.FC<IProps> = ({
  date,
  eventType,
  tariffId,
}) => {
  const { events, leftEvents } = useEvents(date, eventType, tariffId);

  if (!events || !events.length) {
    return null;
  }

  return (
    <ul
      className="we-calendar-todo-list-home-cell"
      style={{ listStyle: "none", margin: 0, padding: 0 }}
    >
      {events.map((event) => (
        <li key={`${event.title}_${event.description}`}>
          {<ScheduleCellItem event={event} />}
        </li>
      ))}
      {leftEvents ? (
        <Button size="xs" block>
          {`+ ${leftEvents} события`}
        </Button>
      ) : null}
    </ul>
  );
};
export const ScheduleCellContent = observer(ScheduleCellContentComponent);
