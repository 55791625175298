import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form } from "rsuite";
import { IAnswerFileList } from "root/requests";
import { ITask, TaskType } from "stores";
import { FileUploader } from "components";
import { UploaderDraggable } from "components/FormComponents/UploaderDraggable";
import { FileUploaderHelper } from "root/FileUploaderHelper";
import { FileType } from "rsuite/esm/Uploader";
import { useDebouncedFunction } from "hooks";
import { Input } from "shared/ui";

export interface ITaskViewFormControlFileAnswerProps {
  answer: IAnswerFileList[] | undefined;
  task: ITask;
  onChange: (
    answer: IAnswerFileList[],
    taskId: number,
    taskType: TaskType,
    fileName?: string
  ) => void;
  onRemoveFile: (fileName: string, taskId: number) => void;
}
const TaskViewFormControlFileAnswerComponent: React.FC<
  ITaskViewFormControlFileAnswerProps
> = (props) => {
  const [answerFileIds, setAnswerFileIds] = useState<string>("");
  const [answerFileList, setAnswerFileList] = useState<IAnswerFileList[]>([]);

  useEffect(() => {
    if (Array.isArray(props.answer) && props.answer.length > 0) {
      const answerFileIds = props.answer.map((x) => x.id).join(", ");
      setAnswerFileIds(answerFileIds);
    }
  }, [props.answer]);

  useEffect(() => {
    if (answerFileList.length === 0) {
      return;
    }
    doSaveDraft(answerFileList);
  }, [answerFileList]);

  const doSaveDraft = useDebouncedFunction(
    (answerFileList: IAnswerFileList[]) => {
      onChange(answerFileList);
    },
    500
  );

  const onChange = (answerFileList: IAnswerFileList[]) => {
    const answerId = answerFileList.map((x) => x.id).join(", ");
    setAnswerFileIds((prev) => (prev ? `${prev}, ${answerId}` : answerId));

    props.onChange(answerFileList, props.task.id, TaskType.FileAnswer);
    setAnswerFileList([]);
  };

  const handleOnSuccessUpload = (id: number, fileName: string) => {
    setAnswerFileList((prev) => prev.concat([{ id, fileName }]));
  };

  function mapFiles(answer: IAnswerFileList[] | undefined): FileType[] {
    if (!answer || !Array.isArray(answer) || answer.length === 0) {
      return [];
    }
    return answer.map((x) => {
      return {
        name: x.fileName,
        fileKey: x.id,
        status: "finished",
        url: x.fileName,
      };
    });
  }

  return (
    <>
      <Form.Group controlId={`task-${props.task.id}`}>
        <Form.HelpText>Можно прикрепить до 10 файлов</Form.HelpText>
        <Form.Control
          listType="text"
          name={`${props.task.id}`}
          accepter={FileUploader}
          defaultFileList={mapFiles(props.answer)}
          shouldQueueUpdate={(fileList: FileType[]) =>
            FileUploaderHelper.canUpload(fileList, 10)
          }
          onSuccess={(id: number, fileName: string) => {
            handleOnSuccessUpload(id, fileName);
          }}
          onRemove={(fileName: string) => {
            props.onRemoveFile(fileName, props.task.id);
          }}
          draggable
        >
          <div>
            <UploaderDraggable />
          </div>
        </Form.Control>
      </Form.Group>
      <Form.Group
        controlId={`task-${props.task.id}`}
        style={{ display: "none" }}
      >
        <Form.Control
          value={answerFileIds}
          name={`${props.task.id}`}
          accepter={Input}
        />
      </Form.Group>
    </>
  );
};
export const TaskViewFormControlFileAnswer = observer(
  TaskViewFormControlFileAnswerComponent
);
