import { Placeholder } from "rsuite";
import { Panel } from "shared/ui";

export const StudentHomeworkPlaceholder: React.FC = () => (
  <>
    <Panel bordered style={{ marginTop: 10 }}>
      <Placeholder.Paragraph rows={5} active />
    </Panel>
    <Panel bordered style={{ marginTop: 10 }}>
      <Placeholder.Paragraph rows={3} active />
    </Panel>
  </>
);
