import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Stack, Whisper } from "rsuite";
import { ITariff, ITariffsStudentPaymentStatus } from "stores";
import { MdIndeterminateCheckBox, MdCheckBox } from "react-icons/md";
import { OverlayTriggerHandle } from "rsuite/esm/Overlay/OverlayTrigger";
import { IMonthStatusByTariff } from "components/Modal/ITariffMonthStatus";
import { MonthButtonWithCheckbox } from "./MonthButtonWithCheckbox";
import { Button, Tooltip } from "shared/ui";

interface IStackButtonTariffMonthComponentProps {
  tariff: ITariff;
  tariffsStudentPaymentStatus: ITariffsStudentPaymentStatus | undefined;
  selectedMonth: number[];
  onLoading: boolean;
  onChange: (tariffMonth: IMonthStatusByTariff[]) => void;
}

const StackButtonTariffMonthComponent: React.FC<
  IStackButtonTariffMonthComponentProps
> = (props: IStackButtonTariffMonthComponentProps) => {
  const whisperRef = useRef<OverlayTriggerHandle>(null);
  const ref = useRef<HTMLButtonElement>(null);

  const [allChecked, setAllChecked] = useState(false);
  const [tariffMonthStatusList, setTariffMonthStatusList] = useState<
    IMonthStatusByTariff[]
  >([]);

  useEffect(() => {
    const paidMonthNumbers = props.tariffsStudentPaymentStatus?.months
      .filter((x) => x.isPaid)
      .map((x) => x.number);

    const onlyAllowableVideoLessons = props.tariffsStudentPaymentStatus?.months
      .filter((x) => x.onlyAllowableVideoLessons)
      .map((x) => x.number);

    const result: IMonthStatusByTariff[] = Array(
      props.tariff.durationInBusinessMonths
    )
      .fill(null)
      .map((_, i) => {
        const id = props.tariff.id;
        const monthNumber = i + 1;
        const isChecked = props.selectedMonth.includes(monthNumber);
        const isPaidMonth = paidMonthNumbers
          ? paidMonthNumbers.includes(monthNumber)
          : false;
        const isAutoCheck = onlyAllowableVideoLessons
          ? onlyAllowableVideoLessons.includes(monthNumber)
          : false;
        return { id, monthNumber, isChecked, isPaidMonth, isAutoCheck };
      });
    setTariffMonthStatusList(result);
  }, [
    props.tariff.durationInBusinessMonths,
    props.tariffsStudentPaymentStatus,
  ]);

  useEffect(() => {
    const isAllChecked = tariffMonthStatusList.every(
      (x) => x.isChecked === true
    );
    setAllChecked(isAllChecked);
    props.onChange(tariffMonthStatusList);
  }, [tariffMonthStatusList]);

  const handleOnClick = (monthNumber: number) => {
    const checkedBoxes = tariffMonthStatusList.filter(
      (x) => x.isChecked && !x.isPaidMonth
    );
    if (
      checkedBoxes.length === 1 &&
      checkedBoxes[0].monthNumber === monthNumber
    ) {
      whisperRef.current?.open();
      return;
    }
    const сheckedMonths = tariffMonthStatusList.map((x) => {
      if (x.monthNumber === monthNumber) {
        x = { ...x, isChecked: !x.isChecked };
      }
      return x;
    });
    setTariffMonthStatusList(сheckedMonths);
    whisperRef.current?.close();
  };

  const toggleCheckBox = () => {
    setAllChecked(!allChecked);
    const unPaidMonthIndex = tariffMonthStatusList.findIndex(
      (x) => !x.isPaidMonth
    );
    const сheckedMonths = tariffMonthStatusList.map((x, i) => ({
      ...x,
      isChecked: i === unPaidMonthIndex ? true : !allChecked,
    }));
    setTariffMonthStatusList(сheckedMonths);
    ref.current?.blur();
    whisperRef.current?.close();
  };

  return (
    <>
      <Whisper
        ref={whisperRef}
        placement="bottom"
        controlId="control-id-hover"
        trigger="none"
        speaker={
          <Tooltip
            onClick={() => {
              whisperRef.current?.close();
            }}
          >
            Должен быть выбран хотя бы 1 месяц
          </Tooltip>
        }
      >
        <Stack spacing={6} wrap>
          {props.tariff.durationInBusinessMonths > 2 && (
            <Button
              disabled={props.onLoading}
              ref={ref}
              active={allChecked}
              startIcon={
                allChecked ? <MdCheckBox /> : <MdIndeterminateCheckBox />
              }
              size="sm"
              onClick={toggleCheckBox}
            >
              Все
            </Button>
          )}
          {tariffMonthStatusList.map((x) => (
            <MonthButtonWithCheckbox
              key={x.monthNumber}
              month={x.monthNumber}
              isPaid={x.isPaidMonth}
              isAutoCheck={x.isAutoCheck}
              checked={x.isChecked}
              onLoading={props.onLoading}
              onClick={handleOnClick}
            />
          ))}
        </Stack>
      </Whisper>
    </>
  );
};
export const StackButtonTariffMonth = observer(StackButtonTariffMonthComponent);
