import PlusIcon from "@rsuite/icons/Plus";
import { SelectPickerMentors } from "components/FormComponents/SelectPickerMentors";
import { SelectPickerStudents } from "components/FormComponents/SelectPickerStudents";
import { SelectPickerTariffId } from "components/FormComponents/SelectPickerTariffId";
import { observer } from "mobx-react";
import { useState } from "react";
import {
  IStudentTariffsByFilterRequest,
  StudentTariffOrder,
} from "root/requests";
import { SelectPicker, Stack } from "rsuite";
import { LinkModal } from "./LinkModal";
import { EnumConverter } from "root/EnumConverter";
import { Button } from "shared/ui";

type TStudentTariffFilterProps = {
  onChange: (studentTariffsFilter: IStudentTariffsByFilterRequest) => void;
  value: IStudentTariffsByFilterRequest;
};

const StudentTariffFilterComponent: React.FC<TStudentTariffFilterProps> = ({
  value,
  onChange,
}) => {
  const [isOpenLinkModal, setOpenLinkModal] = useState<boolean>(false);

  const taskOrderList = EnumConverter.mapEnum(
    StudentTariffOrder,
    EnumConverter.StudentTariffOrderToStr
  );

  return (
    <>
      <LinkModal
        tariffId={value.tariffId}
        studentId={value.studentId}
        mentorId={value.mentorId}
        isOpen={isOpenLinkModal}
        onClose={() => setOpenLinkModal(false)}
      />

      <Stack spacing={10} style={{ marginBottom: 15 }}>
        <Button
          onClick={() => setOpenLinkModal(true)}
          appearance="primary"
          color="orange"
        >
          <PlusIcon />
        </Button>
        <SelectPickerTariffId
          defaultValue={value.tariffId}
          onChange={(tariffId) =>
            onChange({ ...value, tariffId: tariffId ?? undefined })
          }
        />
        <SelectPickerStudents
          defaultValue={value.studentId}
          onChange={(studentId) =>
            onChange({ ...value, studentId: studentId ?? undefined })
          }
        />
        <SelectPickerMentors
          defaultValue={value.mentorId}
          onChange={(mentorId) =>
            onChange({ ...value, mentorId: mentorId ?? undefined })
          }
        />
      </Stack>
      <SelectPicker
        defaultValue={value.orderBy}
        onChange={(orderBy) =>
          onChange({
            ...value,
            orderBy: orderBy ?? StudentTariffOrder.DeletedDesc,
          })
        }
        label="Сортировка"
        data={taskOrderList}
        style={{ width: 224 }}
      />
    </>
  );
};
export const StudentTariffFilter = observer(StudentTariffFilterComponent);
