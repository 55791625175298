import { PropsWithChildren, Ref } from "react";
import { IconButton as RSIconButton } from "rsuite";
import { IconButtonProps } from "./types";
import { RefForwardingComponent } from "../types";
import React from "react";
import { Button } from "../button/Button";

const iconButton = (
  { children, ...props }: PropsWithChildren<IconButtonProps>,
  ref: Ref<HTMLElement>
) => (
  <RSIconButton {...props} ref={ref}>
    {children}
  </RSIconButton>
);

export const IconButton: RefForwardingComponent<
  typeof Button,
  IconButtonProps & {
    ref?: React.Ref<HTMLElement>;
  }
> = React.forwardRef(iconButton);
