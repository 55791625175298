import React from "react";
import { Stack } from "rsuite";
import { observer } from "mobx-react";
import { Link, useNavigate } from "react-router-dom";
import ArowBackIcon from "@rsuite/icons/ArowBack";
import { StatusSaveDraft } from "./StatusSaveDraft";
import { IconButton } from "shared/ui";

export interface ITitleProps {
  lessonTitle: string;
}
const TitleComponent: React.FC<ITitleProps> = (props: ITitleProps) => {
  const navigate = useNavigate();

  return (
    <>
      <Stack alignItems="center" spacing={10} style={{ marginBottom: 15 }}>
        <Link to="/lessons">
          <IconButton
            icon={<ArowBackIcon />}
            size="xs"
            onClick={() => navigate(-1)}
          >
            Назад
          </IconButton>
        </Link>

        <h5>{props.lessonTitle}</h5>

        <StatusSaveDraft text={"Все ответы сохранены"} />
      </Stack>
    </>
  );
};
export const Title = observer(TitleComponent);
