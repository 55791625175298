import { EnumConverter } from "root";
import { Table } from "rsuite";
import { IRegToken } from "stores";
const { Cell } = Table;

interface ITokenTypeCellProps {
  rowData?: IRegToken;
  dataKey: string;
}

export const TokenTypeCell: React.FC<ITokenTypeCellProps> = (props) => {
  if (!props.rowData) {
    return <Cell {...props}></Cell>;
  }
  return (
    <Cell {...props}>
      {EnumConverter.regTokenTypeToStr(props.rowData.type)}
    </Cell>
  );
};
