import React, { useEffect, useState } from "react";
import { Stack, Whisper } from "rsuite";
import { observer } from "mobx-react";
import Api from "../root/Api";
import { Link, useLocation } from "react-router-dom";
import { DeadlineBtnComponent } from ".";
import { LinkHelper, NotificationHelper } from "root";
import { ILesson, FileLessonType, RoleNames, IHomeWork } from "stores";
import { HomeWork } from "./HomeWork";
import { useIsMounted, useStore } from "hooks";
import { authStore } from "stores/AuthStore";
import { Button, Divider, Panel, Tooltip } from "shared/ui";

export interface ILessonsHomeWorkPanelProps {
  currentLesson: ILesson;
  lessonFromParent: ILesson;
  homeworkFromParent?: number;
  taskpackFromParent?: number;
  onClick?: () => void;
  withTitle?: boolean;
}
const LessonsHomeWorkPanelComponent: React.FC<ILessonsHomeWorkPanelProps> = ({
  currentLesson,
  lessonFromParent,
  homeworkFromParent,
  taskpackFromParent,
  onClick,
  withTitle = false,
}) => {
  const isMounted = useIsMounted();
  const { homeworkStore } = useStore();
  const [homeWorks, setHomeWorks] = useState<IHomeWork[]>();
  const [isAllChecked, setIsAllChecked] = useState(false);
  const [summaryHomework, setSummaryHomework] = useState<string>();

  const location = useLocation();
  const hasSummary = location.pathname.includes("summary");
  useEffect(() => {
    const currentHomeWorks = currentLesson.homeWorks;
    setHomeWorks(currentHomeWorks);
    const isAllChecked =
      currentHomeWorks !== undefined &&
      currentHomeWorks.length > 0 &&
      areAllHomeworkPacksChecked(currentHomeWorks);
    setIsAllChecked(isAllChecked);

    if (authStore.me?.role !== RoleNames.Student) {
      return;
    }

    homeworkStore.getSummary(currentLesson.id).then((summaryHomework) => {
      if (!isMounted()) {
        return;
      }
      setSummaryHomework(summaryHomework);
    });
  }, [currentLesson]);

  function areAllHomeworkPacksChecked(homeWorks: IHomeWork[]): boolean {
    return homeWorks.every((homework) =>
      homework.packs.every((pack) => pack.isHomeWorkChecked)
    );
  }

  const handleGetFilesForLesson = async () => {
    const result = await Api.files.GetDownloadFilesByLesson(currentLesson.id);
    if (!result) {
      NotificationHelper.ShowError(
        "Возможно, это был временный сбой — просто попробуйте снова или обратитесь в тех. поддержку.",
        "Не удалось получить скрипты."
      );
      return;
    }
    LinkHelper.fileDownload(result);
  };

  if (!currentLesson.enabled) {
    return (
      <>
        <Whisper
          placement="top"
          controlId="control-id-hover"
          trigger="hover"
          speaker={
            <Tooltip>
              {`Доступ к месяцу № ${currentLesson.monthNumber} заблокирован. Для
            разблокировки уроков необходимо оплатить ${currentLesson.monthNumber}й месяц курса.`}
            </Tooltip>
          }
        >
          <Panel bordered style={{ display: "flex", justifyContent: "center" }}>
            <span>Нет доступа 🔒</span>
          </Panel>
        </Whisper>
      </>
    );
  }
  return (
    <>
      <Panel bordered>
        {withTitle && (
          <div style={{ marginBottom: 20, textAlign: "center", fontSize: 16 }}>
            {currentLesson.title}
          </div>
        )}
        {currentLesson.files &&
          currentLesson.files?.filter((x) => x.type === FileLessonType.Material)
            .length !== 0 && (
            <Panel bodyFill style={{ marginBottom: 15 }}>
              <>
                <Button
                  size="sm"
                  block
                  onClick={() => {
                    handleGetFilesForLesson();
                  }}
                >
                  Скачай скрипты
                </Button>
              </>
            </Panel>
          )}
        <Button
          size="sm"
          active={
            currentLesson.id === lessonFromParent.id &&
            !homeworkFromParent &&
            !taskpackFromParent &&
            !hasSummary
          }
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
          block
          as={Link}
          to={"/lessons/" + currentLesson.id}
          onClick={() => {
            onClick?.call(undefined);
          }}
        >
          Вебчик{" "}
          {currentLesson.isViewed && (
            <Button
              size="xs"
              style={{ borderRadius: 30, cursor: "default" }}
              color="green"
              appearance="primary"
            >
              просмотрен
            </Button>
          )}
        </Button>
        {homeWorks && homeWorks.length !== 0 && (
          <Divider
            style={{ marginTop: 20 }}
            label={
              <Stack spacing={6}>
                Домашка
                <DeadlineBtnComponent
                  deadlineDate={currentLesson?.submitDeadline}
                />
              </Stack>
            }
          />
        )}
        {homeWorks?.map((homeWork) => (
          <HomeWork
            homeWork={homeWork}
            currentLesson={currentLesson}
            lessonFromParent={lessonFromParent}
            homeworkFromParent={homeworkFromParent}
            taskpackFromParent={taskpackFromParent}
            key={homeWork.id}
            onClick={onClick}
          />
        ))}
        {isAllChecked && summaryHomework && summaryHomework.length > 0 ? (
          <>
            <Divider style={{ marginTop: 16, marginBottom: 16 }} />
            <Button
              active={currentLesson.id === lessonFromParent.id && hasSummary}
              size="sm"
              block
              as={Link}
              to={`/lessons/summary/${currentLesson.id}`}
            >
              Вывод по домашке
            </Button>
          </>
        ) : null}
      </Panel>
    </>
  );
};
export const LessonsHomeWorkPanel = observer(LessonsHomeWorkPanelComponent);
