import { Cell } from "rsuite-table";
import { useStore } from "hooks";
import { ITariff } from "stores";
import { observer } from "mobx-react";
import { ButtonToolbar, Whisper } from "rsuite";
import { useState } from "react";
import EditIcon from "@rsuite/icons/Edit";
import TrashIcon from "@rsuite/icons/Trash";
import { ConfirmModal } from "components";
import { EditTariffModal } from "../components";
import { AiFillBulb, AiOutlineBulb } from "react-icons/ai";
import { IconButton, Tooltip } from "shared/ui";

enum TypeAction {
  Delete,
  Change,
}

interface IActionTariffCellProps {
  rowData?: ITariff;
  dataKey: string;
}

const ActionTariffCellComponent: React.FC<IActionTariffCellProps> = (props) => {
  const { tariffStore } = useStore();
  const [isOpenEditModal, setOpenEditModal] = useState(false);
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [action, setAction] = useState<TypeAction>();

  const handleChangeEnableForBuy = async () => {
    if (!props.rowData) {
      return;
    }
    const result = await tariffStore.save({
      ...props.rowData,
      enableForBuy: !props.rowData.enableForBuy,
    });
    if (result) {
      setIsOpenModal(false);
    }
  };

  const handleDeleteTariff = async () => {
    if (!props.rowData) {
      return;
    }
    const result = await tariffStore.delete(props.rowData.id);
    if (result) {
      setIsOpenModal(false);
    }
  };

  const getDescription = (): string => {
    if (!props.rowData) {
      return "Курс не найден";
    }
    let result = "";
    if (action === TypeAction.Change) {
      const verb = props.rowData.enableForBuy ? "снять" : "разрешить";
      result = `Вы действительно хотите ${verb} курс "${props.rowData.title}" ${
        props.rowData.enableForBuy ? "с продажи" : "к покупке"
      }?`;
    }
    if (action === TypeAction.Delete) {
      result = "Вы действительно хотите поместить курс в архив?";
    }
    return result;
  };

  const submitHandler = () => {
    if (!props.rowData) {
      return;
    }
    if (action === TypeAction.Change) {
      handleChangeEnableForBuy();
      return;
    }
    handleDeleteTariff();
  };

  if (!props.rowData) {
    return <></>;
  }
  return (
    <>
      <ConfirmModal
        isOpen={isOpenModal}
        onSubmit={submitHandler}
        onCancel={() => setIsOpenModal(false)}
        isLoading={tariffStore.onDeleting || tariffStore.onSaving}
        description={getDescription()}
      />
      <EditTariffModal
        tariffId={props.rowData?.id}
        isOpen={isOpenEditModal}
        onClose={() => setOpenEditModal(false)}
      />
      <Cell
        {...props}
        className="link-group"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexWrap: "wrap",
        }}
      >
        <ButtonToolbar>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={
              <Tooltip>
                {props.rowData.enableForBuy ? "На продаже" : "Снят с продажи"}
              </Tooltip>
            }
          >
            <IconButton
              data-testId="course-enable-for-buy-button"
              onClick={() => {
                setIsOpenModal(true);
                setAction(TypeAction.Change);
              }}
              icon={
                props.rowData.enableForBuy ? <AiFillBulb /> : <AiOutlineBulb />
              }
              size="sm"
              color="violet"
              appearance={props.rowData.enableForBuy ? "primary" : "default"}
            />
          </Whisper>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Редактировать</Tooltip>}
          >
            <IconButton
              data-testId="course-edit-button"
              onClick={() => {
                setOpenEditModal(true);
              }}
              icon={<EditIcon />}
              size="sm"
              color="orange"
              appearance="primary"
            />
          </Whisper>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Архивировать</Tooltip>}
          >
            <IconButton
              data-testId="course-delete-button"
              onClick={() => {
                setIsOpenModal(true);
                setAction(TypeAction.Delete);
              }}
              icon={<TrashIcon />}
              size="sm"
              color="red"
              appearance="primary"
            />
          </Whisper>
        </ButtonToolbar>
      </Cell>
    </>
  );
};
export const ActionTariffCell = observer(ActionTariffCellComponent);
