import { Cell } from "rsuite-table";
import { observer } from "mobx-react";

interface INumberCellProps {
  rowData?: any;
  dataKey: string;
}

const NumberCellComponent: React.FC<INumberCellProps> = (props) => {
  let number;
  if (props.rowData[props.dataKey] && props.rowData[props.dataKey] > 0) {
    number = props.rowData[props.dataKey] + ".";
  } else {
    number = undefined;
  }
  if (!props.rowData) {
    return <></>;
  }

  return (
    <Cell {...props} className="link-group">
      <p>
        <b>{number}</b>
      </p>
    </Cell>
  );
};
export const NumberCell = observer(NumberCellComponent);
