import React from "react";
import CheckIcon from "@rsuite/icons/Check";
import CloseIcon from "@rsuite/icons/Close";

type PasswordRequirementProps = { label: string; meets: boolean };

export const PasswordRequirement: React.FC<PasswordRequirementProps> = ({
  label,
  meets,
}) => (
  <div
    style={{
      display: "flex",
      alignItems: "center",
      gap: "10px",
      paddingLeft: "8px",
      paddingRight: "8px",
      paddingBottom: "8px",

      color: meets ? "#12b886" : "#fa5252",
    }}
  >
    {meets ? <CheckIcon /> : <CloseIcon />}
    <div style={{ fontSize: 14 }}>{label}</div>
  </div>
);
