import { useDebouncedFunction, useStore } from "hooks";
import { useMemo, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { GlobalConstants } from "root/GlobalConstants";
import { StudentDraftAnswersCheckStorage } from "root/SaveCheckDraftHelper";
import { ValidationHelper } from "root/ValidationHelper";
import {
  IRequestGetHomeworksCheckByFilter,
  IRequestSaveCheckHomework,
  IRequestTeacherAnswer,
} from "root/requests";
import { sessionStorageWrapper } from "root/wrappers";
import {
  HomeworkStudentCheckStatus,
  HomeworkStudentStatus,
  IAnswer,
  IAnswerWithTasksPackHwStudentId,
  TaskType,
} from "stores";
import { ValidationResult } from "stores/models/ValidationResult";
import { useAnswers } from "./useAnswers";
import { useReCheckComment } from "./useReCheckComment";
import { useTaskPack } from "./useTaskPack";

export const useTaskPackEditor = (
  lessonId: number,
  studentId: number,
  homeworkId: number,
  packId: number
) => {
  const { homeworkStore } = useStore();
  const navigate = useNavigate();
  const [validationResult, setValidationResult] = useState<ValidationResult>();
  const [delayReason, setDelayReason] = useState<string>();

  const currentAnswers = useRef<IAnswer[]>([]);
  const updateCurrentAnswers = (newCurrentAnswers: IAnswer[]) =>
    (currentAnswers.current = newCurrentAnswers);

  const {
    checkByLesson,
    isLoading: isLoadingAnswers,
    tasksPackHwStudentId,
    delayReason: currentDelayReason,
  } = useAnswers(lessonId, studentId, homeworkId, packId, updateCurrentAnswers);

  const handleInputAnswer = (
    taskId: number,
    comment: string | undefined,
    score: number | undefined
  ) => {
    const updatedAnswers: IAnswerWithTasksPackHwStudentId[] =
      currentAnswers.current?.map((answer) => {
        if (answer.taskId === taskId) {
          return {
            ...answer,
            comment: comment ?? answer.comment,
            score: score ?? answer.score,
            tasksPackHwStudentId: tasksPackHwStudentId ?? 0,
          };
        }

        return { ...answer, tasksPackHwStudentId: tasksPackHwStudentId ?? 0 };
      });
    currentAnswers.current = updatedAnswers;
    doSaveDraft(updatedAnswers);
  };
  const doSaveDraft = useDebouncedFunction(
    (answers: IAnswerWithTasksPackHwStudentId[]) => {
      StudentDraftAnswersCheckStorage.saveDraft(
        answers,
        lessonId,
        homeworkId,
        packId,
        studentId
      );
    },
    3_000
  );

  const { isLoading: isLoadingTaskPack, taskPack } = useTaskPack(packId);

  const { reCheckComment } = useReCheckComment(tasksPackHwStudentId);

  const isLoading = isLoadingTaskPack || isLoadingAnswers;

  const isChecked = useMemo(
    () =>
      checkByLesson.find(
        (x) => x.packId === packId && x.homeworkId === homeworkId
      )?.checkStatus !== HomeworkStudentCheckStatus.notChecked,
    [checkByLesson]
  );

  const isSended = useMemo(
    () =>
      checkByLesson.find(
        (x) => x.packId === packId && x.homeworkId === homeworkId
      )?.homeworkStatus !== HomeworkStudentStatus.notSend,
    [checkByLesson]
  );

  const isSendedAfterDeadline = useMemo(
    () =>
      checkByLesson.find(
        (x) => x.packId === packId && x.homeworkId === homeworkId
      )?.homeworkStatus === HomeworkStudentStatus.sendLate,
    [checkByLesson]
  );

  const handlePostAnswersCheck = async () => {
    const answers: IRequestTeacherAnswer[] = currentAnswers.current
      ?.filter(
        (x) =>
          x.taskType === TaskType.FileAnswer ||
          x.taskType === TaskType.SecondPart
      )
      .map((x) => {
        return {
          taskId: x.taskId,
          comment: x.comment,
          score: x.score,
        };
      });

    const request: IRequestSaveCheckHomework = {
      tasksPackHwStudentId,
      answers: answers,
      delayReason,
    };
    const resultValidation = ValidationHelper.homeworkCheckValidate(request);
    setValidationResult(resultValidation);
    if (
      resultValidation.hasNotError() &&
      (await homeworkStore.saveAnswersCheck(request))
    ) {
      currentAnswers.current = [];
      StudentDraftAnswersCheckStorage.removeDraft(
        lessonId,
        homeworkId,
        packId,
        studentId
      );

      const checksFilter = sessionStorageWrapper.get(
        GlobalConstants.filterCheck
      ) as IRequestGetHomeworksCheckByFilter;
      homeworkStore.loadByFilter(checksFilter, {
        refetch: true,
      });

      homeworkStore.studentAnswersByLesson(
        lessonId,
        studentId,
        homeworkId,
        packId,
        true
      );
      navigate(`/checks/${lessonId}/${studentId}`);
    }
  };

  const isFirstPart = taskPack?.tasks.every(
    (task) => task.type === TaskType.FirstPart
  );

  const handleChangeDelayReason = (value?: string) =>
    setDelayReason(value || undefined);
  return {
    isFirstPart,
    currentDelayReason,
    isSendedAfterDeadline,
    taskPack,
    isLoading,
    currentAnswers,
    reCheckComment,
    handleInputAnswer,
    isSended,
    isChecked,
    tasksPackHwStudentId,
    validationResult,
    handlePostAnswersCheck,
    handleChangeDelayReason,
  };
};
