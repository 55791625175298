import { Cell } from "rsuite-table";
import { useStore } from "hooks";
import { IPromotion } from "stores";
import { observer } from "mobx-react";
import { ButtonToolbar, Whisper } from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import { useState } from "react";
import EditIcon from "@rsuite/icons/Edit";
import { ConfirmModal } from "components";
import { EditPromotionModal } from "../components/EditPromotionModal";
import { IconButton, Tooltip } from "shared/ui";

interface IActionPromotionCellProps {
  rowData?: IPromotion;
  dataKey: string;
}

const ActionPromotionCellComponent: React.FC<IActionPromotionCellProps> = (
  props
) => {
  const { promotionStore } = useStore();
  const [selectedPromotion, setSelectedPromotion] = useState<
    IPromotion | undefined
  >(undefined);
  const [isOpenEditModal, setOpenEditModal] = useState<boolean>(false);
  const [isOpenConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  if (!props.rowData) {
    return <></>;
  }
  return (
    <>
      <ConfirmModal
        isOpen={isOpenConfirmModal}
        onSubmit={() => {
          setOpenConfirmModal(false);
          if (selectedPromotion && selectedPromotion.id) {
            promotionStore.delete(selectedPromotion.id);
          }
        }}
        onCancel={() => {
          setOpenConfirmModal(false);
        }}
        isLoading={promotionStore.onDeleting}
        description="Вы действительно хотите поместить акцию в архив?"
      />
      <EditPromotionModal
        promotionId={selectedPromotion?.id}
        isOpen={isOpenEditModal}
        onClose={() => setOpenEditModal(false)}
      />
      <Cell
        {...props}
        className="link-group"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexWrap: "wrap",
        }}
      >
        <ButtonToolbar>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Редактировать</Tooltip>}
          >
            <IconButton
              onClick={() => {
                setSelectedPromotion(props.rowData);
                setOpenEditModal(true);
              }}
              icon={<EditIcon />}
              size="sm"
              color="orange"
              appearance="primary"
            />
          </Whisper>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Архивировать</Tooltip>}
          >
            <IconButton
              onClick={() => {
                setSelectedPromotion(props.rowData);
                setOpenConfirmModal(true);
              }}
              icon={<TrashIcon />}
              size="sm"
              color="red"
              appearance="primary"
            />
          </Whisper>
        </ButtonToolbar>
      </Cell>
    </>
  );
};
export const ActionPromotionCell = observer(ActionPromotionCellComponent);
