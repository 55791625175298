import { observer } from "mobx-react";
import { useTaskPackEditor } from "../hooks";
import { CheckButtonControl } from "./CheckButtonControl";
import { EmptyStudentHomework } from "./EmptyStudentHomework";
import { ReCheckComment } from "./ReCheckComment";
import { StudentHomeworkPlaceholder } from "./StudentHomeworkPlaceholder";
import { TaskWithAnswerList } from "./TaskWithAnswerList";
import { DelayReasonMessage } from "./DelayReasonMessage";

export type ITaskPackEditorProps = {
  lessonId: number;
  studentId: number;
  homeworkId: number;
  packId: number;
};

const TaskPackEditorComponent: React.FC<ITaskPackEditorProps> = ({
  lessonId,
  studentId,
  homeworkId,
  packId,
}) => {
  const {
    isFirstPart,
    isSendedAfterDeadline,
    currentDelayReason,
    taskPack,
    reCheckComment,
    currentAnswers,
    handleInputAnswer,
    isLoading,
    isSended,
    isChecked,
    tasksPackHwStudentId,
    validationResult,
    handlePostAnswersCheck,
    handleChangeDelayReason,
  } = useTaskPackEditor(lessonId, studentId, homeworkId, packId);

  if (isLoading) {
    return (
      <>
        <StudentHomeworkPlaceholder />
        <StudentHomeworkPlaceholder />
      </>
    );
  }

  if (!isSended) {
    return <EmptyStudentHomework />;
  }

  return (
    <div style={{ marginBottom: 150 }}>
      <ReCheckComment reCheckComment={reCheckComment} />
      <DelayReasonMessage message={currentDelayReason} />
      <TaskWithAnswerList
        taskPack={taskPack}
        validationResult={validationResult}
        disabledInput={isChecked}
        answers={currentAnswers.current}
        onChange={handleInputAnswer}
      />
      <CheckButtonControl
        isFirstPart={isFirstPart}
        isSendedAfterDeadline={isSendedAfterDeadline}
        isChecked={isChecked}
        onSubmit={handlePostAnswersCheck}
        tasksPackHwStudentId={tasksPackHwStudentId}
        onChangeDelayReason={handleChangeDelayReason}
      />
    </div>
  );
};

export const TaskPackEditor = observer(TaskPackEditorComponent);
