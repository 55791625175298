import * as mentor from "../pages/Mentor";
import { IRoute } from "./IRoute";
import { GeneralRoutes } from "./GeneralRoutes";

export const MentorRoutes = () => {
  const routes: IRoute[] = [];
  routes.push({ title: "Главная", path: "*", element: <mentor.Home /> });
  routes.push({ title: "Главная", path: "/", element: <mentor.Home /> });
  routes.push(GeneralRoutes.HomeworkSummary);
  routes.push(GeneralRoutes.CheckLessons);
  routes.push(GeneralRoutes.Students);
  routes.push(GeneralRoutes.TaskPackEditor);
  routes.push(GeneralRoutes.Top);
  routes.push({
    title: "Показатели",
    path: "/dashboard",
    element: <mentor.Dashboard />,
  });

  return routes;
};
