import { useStore } from "hooks";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { PanelGoCourse } from "../../components";
import { MiniSchedule } from "./MiniSchedule";
import { Panel } from "shared/ui";

const ScheduleDisplayComponent: React.FC = () => {
  const { eventStore, tariffStore } = useStore();

  useEffect(() => {
    eventStore.load(new Date());
  }, []);

  if (tariffStore.myTariffs && tariffStore.myTariffs.length === 0) {
    return (
      <Panel bodyFill bordered>
        <div
          style={{
            padding: 12,
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
            height: 180,
          }}
        >
          <img
            src={`${process.env.PUBLIC_URL}/images/calendar-3.png`}
            style={{ width: "auto", height: 60 }}
          ></img>
          <PanelGoCourse text="пока что нет ближайших занятий" />
        </div>
      </Panel>
    );
  }

  return <MiniSchedule countRenderDays={4} />;
};
export const ScheduleDisplay = observer(ScheduleDisplayComponent);
