import { useStore } from "hooks";
import { useEffect, useState } from "react";
import { ISubject } from "stores";

export const useLogo = () => {
  const { authStore, tariffStore, subjectStore } = useStore();

  const [myTariffs, setMyTariffs] = useState(tariffStore.getMyTariffs());
  const [selectedSubject, setSelectedSubject] = useState<ISubject>();

  useEffect(() => {
    const tariffs = tariffStore.getMyTariffs();
    setMyTariffs(tariffs);
  }, [tariffStore.myTariffs]);

  useEffect(() => {
    if (!tariffStore.selectedTariff || !subjectStore.data.length) {
      return;
    }
    const subject = subjectStore.data.find(
      (x) =>
        x.id === tariffStore.find(tariffStore.selectedTariff?.id)?.subjectId
    );
    setSelectedSubject(subject);
  }, [tariffStore.data, tariffStore.selectedTariff, subjectStore.data]);

  const isAuthorized = authStore.me;

  const subjectTitle = selectedSubject?.title;

  const hasSubjectTitle = myTariffs.length !== 0 && isAuthorized;

  return {
    myTariffs,
    isAuthorized,
    subjectTitle,
    hasSubjectTitle,
  };
};
