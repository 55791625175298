import _ from "lodash";
import { useMemo } from "react";
import { TOrder } from "stores";
import { TResultObject } from "./RenderRowExpanded";
import { TariffItemInfo } from "./TariffItemInfo";

type TStudentTariffTableProps = { order?: TOrder };

export const TariffsInfo: React.FC<TStudentTariffTableProps> = ({ order }) => {
  const transformedTariffItems: TResultObject[] = useMemo(
    () =>
      _.map(
        _.groupBy(order?.tariffItems, "tariffId"),
        (groupedObjects, tariffId) => ({
          tariffId: parseInt(tariffId),
          properties: _.map(groupedObjects, (object) => ({
            monthNumber: object.monthNumber,
            paymentType: object.paymentType,
          })),
        })
      ),
    [order]
  );

  return (
    <>
      {transformedTariffItems.map((x) => (
        <TariffItemInfo tariffOrderItem={x} key={x.tariffId} />
      ))}
    </>
  );
};
