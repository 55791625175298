import { observer } from "mobx-react";
import React from "react";
import { ButtonInstructionsSummary } from "./ButtonInstructionsSummary";
import { ButtonSubmit } from "./ButtonSubmit";
import { StudentHomeworkScore } from "./StudentHomeworkScore";
import { TextareaInput } from "./TextareaInput";
import { useFormHomeworkSummary } from "./useFormHomeworkSummary";

type TFormHomeworkSummaryProps = {
  lessonId: number;
  studentId: number;
};

const FormHomeworkSummaryComponent: React.FC<TFormHomeworkSummaryProps> = ({
  lessonId,
  studentId,
}) => {
  const {
    handleOnClick,
    isDisabledSubmit,
    isLoading,
    setAdvantages,
    advantages,
    setFlaws,
    flaws,
    setFinalConclusion,
    finalConclusion,
  } = useFormHomeworkSummary(lessonId, studentId);
  return (
    <>
      <ButtonInstructionsSummary />
      <StudentHomeworkScore lessonId={lessonId} studentId={studentId} />
      <>
        <TextareaInput
          helpText="Опишите положительные стороны ответов в домашке ученика"
          label="Преимущества"
          onChange={setAdvantages}
          defaultValue={advantages}
        />
        <TextareaInput
          helpText="Опишите слабые стороны ответов в домашке ученика"
          label="Недостатки"
          onChange={setFlaws}
          defaultValue={flaws}
        />
        <TextareaInput
          helpText="Выразите напутствия"
          label="Вывод"
          onChange={setFinalConclusion}
          defaultValue={finalConclusion}
        />
      </>
      <ButtonSubmit
        isDisable={isDisabledSubmit}
        isLoading={isLoading}
        onClick={handleOnClick}
      />
    </>
  );
};

export const FormHomeworkSummary = observer(FormHomeworkSummaryComponent);
