import { useStore } from "hooks";
import { useCallback, useEffect, useMemo, useState } from "react";
import { EnumConverter } from "root/EnumConverter";
import { EventType, RoleNames } from "stores";
import { authStore } from "stores/AuthStore";

export const useSchedule = () => {
  const { eventStore } = useStore();
  const [selectedTariff, setSelectedTariff] = useState<number | null>(null);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [selectedType, setSelectedType] = useState<EventType | null>(null);

  useEffect(() => {
    eventStore.load(new Date());
  }, []);

  const handleChange = useCallback((date: Date) => {
    setSelectedDate(date);
    setOpen(true);
  }, []);

  const data = useMemo(
    () =>
      EnumConverter.mapEnum(EventType, EnumConverter.eventTypeToStr).filter(
        (x) =>
          authStore.me?.role !== RoleNames.Student ||
          x.value === EventType.Lesson ||
          x.value === EventType.HomeworkDeadlineSubmit
      ),
    []
  );

  const isLoading = eventStore.onLoading;
  const isStudent = authStore.me?.role === RoleNames.Student;

  return {
    isStudent,
    data,
    handleChange,
    open,
    setOpen,
    selectedDate,
    selectedType,
    setSelectedType,
    isLoading,
    selectedTariff,
    setSelectedTariff,
  };
};
