import { EditorState } from "draft-js";
import React from "react";
import { ColorButton } from "./ColorButton";
import { ImageInsertButton } from "./ImageInsertButton";
import { LinkInsertButton } from "./LinkInsertButton";
import { PasteButton } from "./PasteButton";

interface CustomToolbarButtonsProps {
  resetStyleOnPaste: boolean;
  setResetStyleOnPaste: React.Dispatch<React.SetStateAction<boolean>>;
  editorState: EditorState | undefined;
  onEditorStateChange: (editorState: EditorState) => void;
}

export const CustomToolbarButtons: React.FC<CustomToolbarButtonsProps> = ({
  resetStyleOnPaste,
  setResetStyleOnPaste,
  editorState,
  onEditorStateChange,
}) => (
  <>
    <PasteButton
      resetStyleOnPaste={resetStyleOnPaste}
      onClick={() => setResetStyleOnPaste((prev) => !prev)}
    />
    <ColorButton
      inlineStyle="color-var(--we-color-text-red)"
      editorState={editorState}
      icon="🔴"
      onEditorStateChange={onEditorStateChange}
      title="Цвет шрифта(красный)"
    />
    <ColorButton
      inlineStyle="color-var(--we-color-text-green)"
      editorState={editorState}
      icon="🟢"
      onEditorStateChange={onEditorStateChange}
      title="Цвет шрифта(зеленый)"
    />
    <ColorButton
      inlineStyle="color-var(--rs-text-primary)"
      editorState={editorState}
      icon="⚫"
      onEditorStateChange={onEditorStateChange}
      title="Цвет шрифта(системный)"
    />
    <LinkInsertButton
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
    />
    <ImageInsertButton
      editorState={editorState}
      onEditorStateChange={onEditorStateChange}
    />
  </>
);
