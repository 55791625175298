import { CatalogItem } from "./components";
import { ICartItem, ITariff } from "stores";
import { EmptyStatePanel, PlaceholderCatalogTariffList } from "components";

interface ITariffListProps {
  loading: boolean;
  tariffs: ITariff[];
  cartItem: ICartItem[];
  onClick: (tariff: ITariff) => void;
}

export const TariffList: React.FC<ITariffListProps> = (props) => {
  if (props.loading) {
    return <PlaceholderCatalogTariffList items={3} />;
  }
  if (!props.tariffs || props.tariffs.length === 0) {
    return <EmptyStatePanel style={{ width: "100%" }} />;
  }
  return (
    <>
      {props.tariffs.map((x) => (
        <div style={{ marginBottom: 10 }} key={x.id}>
          <CatalogItem
            tariff={x}
            cartItem={props.cartItem}
            onClick={props.onClick}
          />
        </div>
      ))}
    </>
  );
};
