import { Cell } from "rsuite-table";
import { GlobalNotificationType, IGlobalNotification } from "stores";
import { observer } from "mobx-react";
import { ButtonToolbar, Whisper } from "rsuite";

import InfoOutlineIcon from "@rsuite/icons/InfoOutline";
import RemindOutlineIcon from "@rsuite/icons/RemindOutline";
import ToolsIcon from "@rsuite/icons/Tools";
import { EnumConverter } from "root/EnumConverter";
import { ReactElement } from "react";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { IconButton, Tooltip } from "shared/ui";

interface ITypeGlobalNotificationCellProps {
  rowData?: IGlobalNotification;
  dataKey: string;
}

const TypeGlobalNotificationCellComponent: React.FC<
  ITypeGlobalNotificationCellProps
> = (props) => {
  if (!props.rowData) {
    return <Cell></Cell>;
  }

  let icon: ReactElement;
  let color: TypeAttributes.Color | undefined = undefined;
  let appearance: TypeAttributes.Appearance | undefined = undefined;
  switch (props.rowData.type) {
    case GlobalNotificationType.Alert:
      icon = <RemindOutlineIcon />;
      color = "orange";
      appearance = "primary";
      break;
    case GlobalNotificationType.Info:
      icon = <InfoOutlineIcon />;
      break;
    case GlobalNotificationType.Technical:
      icon = <ToolsIcon />;
      color = "blue";
      appearance = "primary";
      break;
  }
  return (
    <>
      <Cell
        {...props}
        className="link-group"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexWrap: "wrap",
        }}
      >
        <ButtonToolbar>
          <Whisper
            speaker={
              <Tooltip>
                {EnumConverter.globalNotificationTypeToStr(props.rowData.type)}
              </Tooltip>
            }
          >
            <IconButton
              color={color}
              appearance={appearance}
              size="xs"
              icon={icon}
            />
          </Whisper>
        </ButtonToolbar>
      </Cell>
    </>
  );
};
export const TypeGlobalNotificationCell = observer(
  TypeGlobalNotificationCellComponent
);
