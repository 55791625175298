import { observer } from "mobx-react";
import React from "react";
import { TaskPackEditor } from "./TaskPackEditor";
import { HomeworkSummary } from "../Summary";

interface ITaskPackViewerProps {
  lessonId: number;
  studentId: number;
  homeworkId: number | undefined;
  packId: number | undefined;
}

const TaskPackViewerComponent: React.FC<ITaskPackViewerProps> = ({
  lessonId,
  studentId,
  homeworkId,
  packId,
}) => {
  if (homeworkId && packId) {
    return (
      <TaskPackEditor
        lessonId={lessonId}
        studentId={studentId}
        homeworkId={homeworkId}
        packId={packId}
      />
    );
  }

  return <HomeworkSummary lessonId={lessonId} studentId={studentId} />;
};

export const TaskPackViewer = observer(TaskPackViewerComponent);
