import {
  ContextProvider,
  ErrorProvider,
  QueryProvider,
  ThemeProvider,
  YandexMetrikaProvider,
} from "provider";
import React, { PropsWithChildren } from "react";
import "rsuite/dist/rsuite.min.css";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../App.css";

export const Providers: React.FC<PropsWithChildren> = ({ children }) => {
  return (
    <YandexMetrikaProvider>
      <QueryProvider>
        <ContextProvider>
          <ThemeProvider>
            <ErrorProvider>{children}</ErrorProvider>
          </ThemeProvider>
        </ContextProvider>
      </QueryProvider>
    </YandexMetrikaProvider>
  );
};
