import { DateCell } from "components";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import { Table } from "rsuite";
import { Cell, Column, HeaderCell } from "rsuite-table";
import { ActionTariffCell } from "./Cell";
import { ButtonCreateTariff } from "./components";
import { Panel } from "shared/ui";

const TariffTableComponent: React.FC = () => {
  const { tariffStore } = useStore();

  return (
    <>
      <ButtonCreateTariff />
      <Panel bordered bodyFill>
        <Table
          rowKey="id"
          loading={tariffStore.onLoading}
          height={600}
          data={tariffStore.data}
          style={{ marginTop: 25 }}
        >
          <Column flexGrow={1}>
            <HeaderCell>id</HeaderCell>
            <Cell dataKey="id" />
          </Column>
          <Column minWidth={150} flexGrow={3} fullText>
            <HeaderCell>Название</HeaderCell>
            <Cell dataKey="title" />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Уроков в месяц</HeaderCell>
            <Cell dataKey="lessonsPerMonth" />
          </Column>
          <Column flexGrow={2} fullText>
            <HeaderCell>Дата с</HeaderCell>
            <DateCell dataKey="dateBegin" />
          </Column>
          <Column flexGrow={2} fullText>
            <HeaderCell>Дата до</HeaderCell>
            <DateCell dataKey="dateEnd" />
          </Column>
          <Column minWidth={350} flexGrow={7} fullText>
            <HeaderCell>Описание</HeaderCell>
            <Cell dataKey="description" />
          </Column>
          <Column width={150} align="center">
            <HeaderCell>Кнопки</HeaderCell>
            <ActionTariffCell dataKey="id" />
          </Column>
        </Table>
      </Panel>
    </>
  );
};
export const TariffTable = observer(TariffTableComponent);
