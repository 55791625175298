import { useIsMounted, useStore } from "hooks";
import { useEffect, useState } from "react";

export const useStudentHomeworkScore = (
  lessonId: number,
  studentId: number
) => {
  const isMounted = useIsMounted();
  const { statisticStore } = useStore();

  const [firstPartScore, setFirstPartScore] = useState<number>();
  const [firstPartMaxScore, setFirstPartMaxScore] = useState<number>();
  const [secondPartScore, setSecondPartScore] = useState<number>();
  const [secondPartMaxScore, setSecondPartMaxScore] = useState<number>();

  // TODO получать баллы с другого места, тк в перфомансе баллы добавляются только после дедлайна наставника
  useEffect(() => {
    statisticStore
      .getProgressChartData(studentId)
      .then((studentPerformance) => {
        const filteredData = studentPerformance?.find(
          (x) => x.lessonId === lessonId
        );
        if (!filteredData || !isMounted()) {
          return;
        }
        setFirstPartScore(filteredData.firstPartScore);
        setFirstPartMaxScore(filteredData.firstPartMaxScore);
        setSecondPartScore(filteredData.secondPartScore);
        setSecondPartMaxScore(filteredData.secondPartMaxScore);
      });
  }, [lessonId, studentId]);

  return {
    firstPartScore,
    firstPartMaxScore,
    secondPartScore,
    secondPartMaxScore,
  };
};
