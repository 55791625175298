import { PropsWithChildren } from "react";
import { CheckboxProps } from "./types";
import { Checkbox as RSCheckbox } from "rsuite";
import { RefForwardingComponent } from "../types";
import React from "react";

const checkbox = <E extends React.ElementType>(
  { children, ...props }: PropsWithChildren<CheckboxProps>,
  ref: React.ForwardedRef<E>
) => (
  <RSCheckbox {...props} ref={ref}>
    {children}
  </RSCheckbox>
);

export const Checkbox: RefForwardingComponent<"div", CheckboxProps> =
  React.forwardRef(checkbox);
