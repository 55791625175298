import { del, get, post } from "../WebHelper";
import * as requests from "../requests";
import { CrudController } from "./CrudController";
import { IHomeWork, IHomeworkCheck, IPage, IStudentHomework } from "stores";

export class HomeworkController extends CrudController<IHomeWork> {
  constructor() {
    super("Homework");
  }

  public async SaveAnswersHomework(data: requests.IRequestSaveAnswersHomework) {
    await post(`/Homework/SaveAnswers/`, data);
  }
  public async GetSummaryHomework(
    lessonId: number,
    studentId?: number
  ): Promise<string | undefined> {
    return await get(`/Homework/GetSummary/${lessonId}`, {
      params: { studentId },
    });
  }

  public async SaveSummaryHomework(
    lessonId: number,
    studentId: number,
    summary: string
  ) {
    await post(`/Homework/SaveSummary/${lessonId}/${studentId}`, summary);
  }

  public async SaveAnswersCheckHomework(
    data: requests.IRequestSaveCheckHomework
  ) {
    await post(`/Homework/SaveChecks/`, data);
  }

  public async GetAnswersByLesson(
    lessonId: number,
    refetch?: boolean
  ): Promise<IStudentHomework[] | undefined> {
    return await get(`/Homework/GetMyAnswers/` + lessonId, { refetch });
  }
  public async GetStudentAnswersByLesson(
    lessonId: number,
    studentId: number,
    homeworkId?: number,
    packId?: number,
    refetch?: boolean
  ): Promise<IStudentHomework[] | undefined> {
    return await get(`/Homework/GetStudentAnswers/${lessonId}/${studentId}`, {
      params: { homeworkId, packId },
      refetch,
    });
  }

  public async GetHomeworksCheckByFilter(
    data?: requests.IRequestGetHomeworksCheckByFilter
  ): Promise<IPage<IHomeworkCheck> | undefined> {
    return await post(`/Homework/Filter/`, data);
  }

  public async ReCheck(tasksPackHwStudentId: number, comment: string) {
    await post(`/Homework/ReCheck/${tasksPackHwStudentId}`, comment);
  }
  public async GetRecheckComment(
    tasksPackHwStudentId: number
  ): Promise<string | undefined> {
    return await get(`/Homework/GetRecheckComment/${tasksPackHwStudentId}`);
  }

  public async DropAnswers(
    lessonId: number,
    studentId: number,
    taskPackId: number
  ) {
    return await del(
      `/Homework/DropAnswers/${lessonId}/${studentId}/${taskPackId}`
    );
  }
}
