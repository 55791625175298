import UserIcon from "@rsuite/icons/legacy/User";
import { observer } from "mobx-react";
import React from "react";
import { Whisper } from "rsuite";
import { useStore } from "../../../../hooks";
import { PopoverProfileMenu } from "./PopoverProfileMenu";
import { Avatar } from "shared/ui";

const ProfileButtonComponent: React.FC = () => {
  const { authStore } = useStore();

  const imageUrl = authStore.me?.imageUrl;
  return (
    <Whisper
      placement="bottomEnd"
      trigger="click"
      speaker={(props, ref) => <PopoverProfileMenu {...props} ref={ref} />}
    >
      <div className="we-header-avatar">
        <Avatar src={imageUrl ?? undefined} style={{ cursor: "pointer" }}>
          {!imageUrl && <UserIcon />}
        </Avatar>
      </div>
    </Whisper>
  );
};
export const ProfileButton = observer(ProfileButtonComponent);
