import React from "react";
import { Modal } from "rsuite";
import { TariffEditAdd } from "../TariffEditAdd";

interface IEditTariffModalProps {
  tariffId?: number;
  isOpen: boolean;
  onClose: () => void;
}

export const EditTariffModal: React.FC<IEditTariffModalProps> = (props) => {
  return (
    <Modal
      backdrop="static"
      open={props.isOpen}
      onClose={() => props.onClose()}
      size="sm"
    >
      <Modal.Header>
        <Modal.Title>
          {props.tariffId && props.tariffId > 0
            ? "Редактирование курса"
            
            : "Создание курса"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <TariffEditAdd tariffId={props.tariffId} onClose={props.onClose} />
      </Modal.Body>
    </Modal>
  );
};
