import { Button } from "shared/ui";
import { ReCheckControl } from "./ReCheckControl";

type TCheckButtonProps = {
  setReCheckComment: (value: string) => void;
  onSubmit: () => void;
  isChecked?: boolean;
  isLoading?: boolean;
  isFirstPart?: boolean;
};

export const CheckButton: React.FC<TCheckButtonProps> = ({
  setReCheckComment,
  onSubmit,
  isChecked,
  isLoading,
  isFirstPart,
}) => {
  if (!isChecked) {
    return (
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button
          loading={isLoading}
          appearance="primary"
          color="violet"
          onClick={onSubmit}
        >
          Работа проверена
        </Button>
      </div>
    );
  }

  return (
    <ReCheckControl
      isFirstPart={isFirstPart}
      isLoading={isLoading}
      onSubmit={onSubmit}
      setReCheckComment={setReCheckComment}
    />
  );
};
