import { observer } from "mobx-react";
import { CSSProperties } from "react";
import { IHomeworkCheck } from "stores";
import { useStudentHomeworkStatus } from "../hooks";

const generalStatusStyle: CSSProperties = {
  marginLeft: 3,
  bottom: "-8px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: 32,
  height: 18,
  padding: "0px 3px",
  fontSize: 10,
  lineHeight: 11,
  letterSpacing: "0.02em",
  whiteSpace: "nowrap",
  borderRadius: "24px",
  overflow: "visible",
};

const notSendAndNotCheckedStatusStyle: CSSProperties = {
  ...generalStatusStyle,
  color: "var(--rs-btn-primary-text)",
  backgroundColor: "var(--rs-violet-500)",
};

const notSendStatusStyle: CSSProperties = {
  ...generalStatusStyle,
  color: "var(--rs-btn-default-text)",
  backgroundColor: "var(--rs-iconbtn-addon)",
};

const notCheckedStatusStyle: CSSProperties = {
  ...generalStatusStyle,
  color: "var(--rs-btn-primary-text)",
  backgroundColor: "var(--rs-green-500)",
};

type TStudentHomeworkStatusProps = {
  homeworkCheck: IHomeworkCheck;
};

const StudentHomeworkStatusComponent: React.FC<TStudentHomeworkStatusProps> = ({
  homeworkCheck,
}) => {
  const { isNotSend, taskTitle, isNotChecked } =
    useStudentHomeworkStatus(homeworkCheck);

  if (isNotSend) {
    return (
      <>
        <span>{taskTitle}</span>
        <div style={notSendStatusStyle}>
          <span>Не сдана</span>
        </div>
      </>
    );
  }

  if (isNotChecked) {
    return (
      <>
        <span>{taskTitle}</span>
        <div style={notSendAndNotCheckedStatusStyle}>
          <span>Нет проверки</span>
        </div>
      </>
    );
  }

  return (
    <>
      <span>{taskTitle}</span>
      <div style={notCheckedStatusStyle}>
        <span>Проверена</span>
      </div>
    </>
  );
};

export const StudentHomeworkStatus = observer(StudentHomeworkStatusComponent);
