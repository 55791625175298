import { PageNavigator } from "components";
import { useStudentTariffPageNavigation } from "hooks/useStudentTariffPageNavigation";
import { observer } from "mobx-react";
import { StudentTariffTable } from "./MentorStudentTariffsTable";
import { StudentTariffFilter } from "./components";
import { StudentTariffOrder } from "root/requests";

const initialFilter = {
  count: 10,
  page: 1,
  orderBy: StudentTariffOrder.DeletedDesc,
};

const StudentTariffTableBaseComponent: React.FC = () => {
  const { filter, dataPage, setLimit, setActivePage, onChangeFilter } =
    useStudentTariffPageNavigation();

  return (
    <>
      <StudentTariffFilter onChange={onChangeFilter} value={filter} />

      <StudentTariffTable data={dataPage?.data} />

      <div style={{ marginTop: 12 }}>
        <PageNavigator
          total={dataPage?.total}
          limit={filter.count || initialFilter.count}
          activePage={filter.page || initialFilter.page}
          onChangeLimit={setLimit}
          onChangeActivePage={setActivePage}
        />
      </div>
    </>
  );
};
export const StudentTariffTableBase = observer(StudentTariffTableBaseComponent);
