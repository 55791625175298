import { InputGroup } from "rsuite";
import { InputLeftSectionProps } from "./types";

export const InputLeftSection = ({
  leftSection,
  leftSectionPointerEvents,
}: InputLeftSectionProps) => {
  return (
    <>
      {leftSection && (
        <InputGroup.Addon style={{ pointerEvents: leftSectionPointerEvents }}>
          {leftSection}
        </InputGroup.Addon>
      )}
    </>
  );
};
