import { EmptyStatePanel } from "components";
import { useIsMounted, useStore } from "hooks";
import { observer } from "mobx-react";
import { PlaceholderHomeworkChecks } from "pages/Student/components";
import { useEffect, useState } from "react";
import { List, PanelGroup } from "rsuite";
import { IHomeworkResult } from "stores";
import { HeaderPanel } from "./HeaderPanel";
import { HomeworkItem } from "./HomeworkItem";
import { Panel } from "shared/ui";

interface IHomeworksProps {
  homeworks: IHomeworkResult[];
}

const HomeworksComponent: React.FC<IHomeworksProps> = (
  props: IHomeworksProps
) => {
  const isMounted = useIsMounted();
  const { lessonsStore, homeworkStore } = useStore();
  const [homeworks, setHomeworks] = useState<IHomeworkResult[]>([]);

  useEffect(() => {
    getSortedHomeworks(props.homeworks).then((homeworks) => {
      if (!isMounted()) {
        return;
      }
      setHomeworks(homeworks);
    });
  }, [props.homeworks]);

  const getSortedHomeworks = async (
    homeworks: IHomeworkResult[]
  ): Promise<IHomeworkResult[]> => {
    const homeworksWithLessons = await Promise.all(
      homeworks.map(async (h) => ({
        ...h,
        lesson: await lessonsStore.findOrLoad(h.lessonId),
      }))
    );

    return homeworksWithLessons
      .slice()
      .sort(
        (a, b) =>
          new Date(b.lesson?.submitDeadline || 0).getTime() -
          new Date(a.lesson?.submitDeadline || 0).getTime()
      );
  };

  if (homeworkStore.onLoading || lessonsStore.onLoading) {
    return <PlaceholderHomeworkChecks />;
  }

  if (!homeworks || !homeworks.length) {
    return <EmptyStatePanel />;
  }

  return (
    <>
      <PanelGroup accordion bordered style={{ minWidth: 700 }}>
        {homeworks.map((homeWorkResult) => {
          return (
            <Panel
              key={homeWorkResult.lessonId}
              header={<HeaderPanel homeWorkResult={homeWorkResult} />}
            >
              <List bordered hover>
                {homeWorkResult.checks.map((check) => {
                  return (
                    <List.Item key={check.homeworkId + "_" + check.packId}>
                      <HomeworkItem
                        lessonId={homeWorkResult.lessonId}
                        check={check}
                      />
                    </List.Item>
                  );
                })}
              </List>
            </Panel>
          );
        })}
      </PanelGroup>
    </>
  );
};

export const Homeworks = observer(HomeworksComponent);
