import React from "react";
import { Nav, Sidebar, Sidenav } from "rsuite";
import { useMenu } from "containers/hooks";
import { observer } from "mobx-react";

type TMenuProps = {
  stateMenu: boolean;
  onClick: () => void;
};
const MenuComponent: React.FC<TMenuProps> = ({ onClick, stateMenu }) => {
  const {
    menu,
    changeSelectedItem,
    expand,
    selected,
    widthSidebar,
    sidebarStyle,
    backdropStyle,
    boxClassName,
  } = useMenu(stateMenu, onClick);

  return (
    <div className={boxClassName}>
      <Sidebar style={sidebarStyle} width={widthSidebar} collapsible>
        <Sidenav expanded={expand} defaultOpenKeys={["3"]}>
          <Sidenav.Body>
            <Nav activeKey={selected} onSelect={changeSelectedItem}>
              {menu}
            </Nav>
          </Sidenav.Body>
        </Sidenav>
      </Sidebar>

      <div
        className="rs-sidebar-media-backdrop "
        style={backdropStyle}
        onClick={onClick}
      />
    </div>
  );
};

export const Menu = observer(MenuComponent);
