import { useState } from "react";
import { Modal, RadioGroup } from "rsuite";
import { TextareaInput } from "../Summary/TextareaInput";
import { ValueType } from "rsuite/esm/Checkbox";
import { Button, Radio } from "shared/ui";

enum EDelayReason {
  Respectful = 1,
  JustOverdue = 2,
}

type TDelayReasonModalProps = {
  opened: boolean;
  onClose: () => void;
  onSubmit: (value?: string) => void;
};

export const DelayReasonModal: React.FC<TDelayReasonModalProps> = ({
  onClose,
  onSubmit,
  opened,
}) => {
  const [value, setValue] = useState<ValueType>();
  const [delayReason, setDelayReason] = useState<string>("");

  const handleSubmit = () => onSubmit(delayReason || undefined);

  return (
    <Modal open={opened} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Причина просрочки</Modal.Title>
      </Modal.Header>
      <Modal.Body style={{ paddingLeft: 10 }}>
        <p>Домашка сдана после дедлайна. Укажите причину просрочки</p>
        <RadioGroup name="radioList" value={value} onChange={setValue}>
          <Radio
            value={EDelayReason.JustOverdue}
            onChange={() => setDelayReason("")}
          >
            Просто просрочка
          </Radio>
          <Radio value={EDelayReason.Respectful}>Уважительная причина</Radio>
        </RadioGroup>
        {value === EDelayReason.Respectful && (
          <TextareaInput
            label="Описание причины просрочки"
            defaultValue={delayReason}
            onChange={(value) => setDelayReason(value)}
          />
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button
          onClick={handleSubmit}
          appearance="primary"
          disabled={
            !value ||
            (value === EDelayReason.Respectful && delayReason.length < 5)
          }
        >
          Отправить
        </Button>
        <Button onClick={onClose} appearance="subtle">
          Отмена
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
