import Api from "../root/Api";
import { CrudStore } from "./CrudStore";
import { IThemaCategory } from "./models";

export class ThemaCategoryStore extends CrudStore<IThemaCategory> {
  constructor() {
    super(Api.themesCategories);
  }
  public filterBySubjectId(subjectId?: number) {
    return this.data.filter((x) => x.subjectId === subjectId);
  }
}
