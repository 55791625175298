import { LogoIcon } from "components";
import { observer } from "mobx-react";
import React from "react";
import { Stack } from "rsuite";
import { useLogo } from "containers/hooks";

const LogoComponent: React.FC = () => {
  const { hasSubjectTitle, subjectTitle } = useLogo();
  return (
    <Stack className="we-left-stack">
      <div className="we-logo">
        <LogoIcon size={30} color="#6E4ABE" />
      </div>
      <>
        <span className="we-logo-name">wavege</span>
        {hasSubjectTitle && (
          <span className="we-logo-subject-name">{subjectTitle}</span>
        )}
      </>
    </Stack>
  );
};
export const Logo = observer(LogoComponent);
