import { usePageFilter, useStore } from "hooks";
import { useMemo } from "react";
import { GlobalConstants, IRequestGetTasksPacksByFilter } from "root";
import { ITaskPack, ITaskText } from "stores";

const initialFilter = {
  count: 10,
  page: 1,
};

export const useTaskPackPageNavigation = () => {
  const { taskPackStore } = useStore();

  const { filter, dataPage, setLimit, setActivePage, onChangeFilter } =
    usePageFilter<ITaskPack, IRequestGetTasksPacksByFilter>(
      initialFilter,
      taskPackStore,
      GlobalConstants.filterTaskPack
    );

  const data = useMemo(
    () =>
      dataPage?.data.map((pack) => {
        return {
          id: pack.id,
          title: pack.name,
          children: pack.tasks
            .map((task, i) => {
              return {
                index: i + 1,
                id: pack.id + "_" + task.id,
                packId: pack.id,
                answer: task.answer,
                difficulty: task.difficulty,
                taskId: task.id,
                isFavorite: task.isFavorite,
                kim: task.kim,
                question: task.question,
                source: task.source,
                textDto: task.textDto,
                themeId: task.themeId,
                type: task.type,
                maxScore: task.maxScore,
                keys: task.keys,
              };
            })
            .concat({
              index: 0,
              id: pack.id + "_last_",
              packId: pack.id,
              answer: "",
              difficulty: 0,
              taskId: 0,
              isFavorite: false,
              kim: 0,
              question: "не пусто",
              source: "",
              textDto: {} as ITaskText,
              themeId: 0,
              type: 1,
              maxScore: 0,
              keys: "",
            }),
        };
      }),
    [dataPage?.data]
  );
  const isLoading = taskPackStore.onLoading;
  const total = dataPage?.total;

  return {
    filter,
    data,
    isLoading,
    total,
    setLimit,
    setActivePage,
    onChangeFilter,
  };
};
