import { CSSProperties } from "react";

export const styleCenter: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "60px",
};

export const slimText: CSSProperties = {
  fontSize: "1em",
  color: "#97969B",
  fontWeight: "lighter",
  paddingBottom: 5,
};

export const titleStyle: CSSProperties = {
  paddingBottom: 5,
  fontWeight: 500,
};
