import { Cell } from "rsuite-table";
import { IUserFullData, RoleNames } from "stores";
import { observer } from "mobx-react";
import { BiRightArrowAlt } from "react-icons/bi";
import Api from "root/Api";
import { SelectPicker, ButtonToolbar, Whisper } from "rsuite";
import { useState } from "react";
import { IRequestChangeRole } from "root/requests";
import { FaExchangeAlt } from "react-icons/fa";
import { MdOutlineAlternateEmail } from "react-icons/md";
import { EnumConverter } from "root/EnumConverter";
import { useIsMounted } from "hooks";
import { IconButton, Tooltip } from "shared/ui";

interface IActionCellProps {
  rowData?: IUserFullData;
  dataKey: string;
  onChange: () => void;
}

const ActionCellComponent: React.FC<IActionCellProps> = (props) => {
  const isMounted = useIsMounted();
  const [changeRoleRequest, setChangeRoleRequest] = useState<
    IRequestChangeRole | undefined
  >(undefined);

  const cellsProps = {
    ...props,
    className: "link-group",
    style: {
      display: "flex",
      alignItems: "center",
    },
  };

  const rolesList = EnumConverter.mapEnum(
    RoleNames,
    EnumConverter.RoleNamesToStr
  );

  if (!props.rowData) {
    return <></>;
  }
  const user = props.rowData;
  if (changeRoleRequest?.userId == user.id) {
    const filteredRoleList = rolesList.filter(
      (x) => x.value !== RoleNames.Admin && x.value !== RoleNames.Super
    );
    return (
      <Cell {...cellsProps}>
        <SelectPicker
          data={filteredRoleList}
          value={changeRoleRequest.role}
          onChange={(x) => {
            setChangeRoleRequest({ ...changeRoleRequest, role: x });
          }}
          style={{ width: 150 }}
        />
        <IconButton
          onClick={() => {
            Api.tech.ConvertUser(changeRoleRequest).then(() => {
              props.onChange();
              if (!isMounted()) {
                return;
              }
              setChangeRoleRequest(undefined);
            });
          }}
          disabled={changeRoleRequest.role == user.role}
          icon={<BiRightArrowAlt />}
          size="sm"
          color="green"
          appearance="primary"
        />
      </Cell>
    );
  }

  return (
    <Cell {...cellsProps}>
      <ButtonToolbar>
        {user.role !== RoleNames.Admin && user.role !== RoleNames.Super ? (
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Сменить роль</Tooltip>}
          >
            <IconButton
              onClick={() => {
                setChangeRoleRequest({ userId: user.id, role: user.role });
              }}
              icon={<FaExchangeAlt />}
              size="sm"
              color="orange"
              appearance="primary"
            />
          </Whisper>
        ) : (
          <></>
        )}

        {!user.emailConfirmed ? (
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={
              <Tooltip>
                Подтвердить емейл за ученика. ЕСЛИ УВЕРЕН, ЧТО ДЕЛАЕШЬ
              </Tooltip>
            }
          >
            <IconButton
              onClick={() => {
                Api.users.ConfirmById(user.id).then(() => props.onChange());
              }}
              icon={<MdOutlineAlternateEmail />}
              size="sm"
              color="green"
              appearance="primary"
            />
          </Whisper>
        ) : (
          <></>
        )}
      </ButtonToolbar>
    </Cell>
  );
};
export const ActionCell = observer(ActionCellComponent);
