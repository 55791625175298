import { FileType } from "rsuite/esm/Uploader";
import { NotificationHelper } from "./NotificationHelper";

export class FileUploaderHelper {
  /**
   * @description Проверяет можно ли загрузить файл
   *
   * @param fileList Массив фалов для проверки формата **`FileType[]`**.
   * @param maxUploadedFiles Максимальное количество файло для загрузки, по дэфолту 100.
   * @param maxUploadedSize Максимальный размер каждого файла в байтах, по дэфолту 52000000 байта(50МБ).
   * @returns Вовращает **`true`** если файлы можно загрузить,
   * **`false`**, если файлов слишком много, если файлы сликом тяжелые или нет расширения.
   */
  public static canUpload(
    fileList: FileType[],
    maxUploadedFiles = 100,
    maxUploadedSize = 52000000 //Размер в байтах, 50МБ
  ): boolean {
    if (!fileList) {
      return false;
    }
    if (fileList.length > maxUploadedFiles) {
      NotificationHelper.ShowError(
        `Превышено количество файлов. Максимальное количество файлов ${maxUploadedFiles}`,
        "Файл не загружен"
      );
      return false;
    }
    const files = fileList.map((fileType) => {
      return {
        size: fileType.blobFile?.size,
        type:
          fileType.blobFile?.type || fileType.name?.match(/\.([^.]+)$/)?.at(0),
      };
    });
    if (
      files.some((sizeFile) => sizeFile.size && sizeFile.size > maxUploadedSize)
    ) {
      NotificationHelper.ShowError(
        `Превышен размер файла. Максимальный размер файлов ${Math.ceil(
          maxUploadedSize * 0.000000953674
        )} МБ`,
        "Файл не загружен"
      );
      return false;
    }
    if (files.some((file) => !file.type)) {
      NotificationHelper.ShowError(
        `Неизвестный формат файла.`,
        "Файл не загружен"
      );
      return false;
    }
    return true;
  }
}
