import { DateCell } from "components";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { Table } from "rsuite";
import { Cell, Column, HeaderCell } from "rsuite-table";
import { ActionPromocodeCell, RuleCell, SaleTypeCell } from "./Cell";
import { ButtornCreatePromocode } from "./components";
import { Panel } from "shared/ui";

const PromocodeTableComponent: React.FC = () => {
  const { promocodeStore } = useStore();

  useEffect(() => {
    promocodeStore.load();
  }, []);

  return (
    <>
      <ButtornCreatePromocode />
      <Panel bordered bodyFill>
        <Table
          rowKey="id"
          loading={promocodeStore.onLoading}
          height={600}
          data={promocodeStore.data}
          style={{ marginTop: 25 }}
        >
          <Column flexGrow={1}>
            <HeaderCell>id</HeaderCell>
            <Cell dataKey="id" />
          </Column>
          <Column minWidth={150} flexGrow={3} fullText>
            <HeaderCell>Код-слово</HeaderCell>
            <Cell dataKey="code" />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Тип</HeaderCell>
            <SaleTypeCell dataKey="saleType" />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>Размер</HeaderCell>
            <Cell dataKey="saleCost" />
          </Column>
          <Column flexGrow={2} fullText>
            <HeaderCell>Начало действия</HeaderCell>
            <DateCell dataKey="dateBegin" />
          </Column>
          <Column flexGrow={2} fullText>
            <HeaderCell>Окончание действия</HeaderCell>
            <DateCell dataKey="dateEnd" />
          </Column>
          <Column minWidth={130} flexGrow={3} fullText>
            <HeaderCell>Описание</HeaderCell>
            <RuleCell dataKey="rule" />
          </Column>
          <Column width={150} align="center">
            <HeaderCell>Кнопки</HeaderCell>
            <ActionPromocodeCell dataKey="id" />
          </Column>
        </Table>
      </Panel>
    </>
  );
};
export const PromocodeTable = observer(PromocodeTableComponent);
