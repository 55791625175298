import React, { PropsWithChildren } from "react";
import { ButtonProps, Stack } from "rsuite";
import { Panel } from "shared/ui";

interface ILayoutPanelWithButtonProps {
  title?: string;
  button: React.JSX.Element;
}

export const LayoutPanelWithButton: React.FC<
  PropsWithChildren<ILayoutPanelWithButtonProps>
> = ({ title, button, children }) => {
  const defaultButtonProps: ButtonProps = { appearance: "default" };
  const buttonWithDefaultProps = React.cloneElement(button, defaultButtonProps);
  return (
    <Panel
      bodyFill
      header={
        <Stack justifyContent="space-between">
          {title ? <span>{title}</span> : <></>}
          {buttonWithDefaultProps}
        </Stack>
      }
    >
      {children}
    </Panel>
  );
};
