import React, { CSSProperties } from "react";
import { ExpandableText } from "components";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { Message } from "shared/ui";

interface IBigMessageProps {
  title: string;
  description: string;
  maxLength: number;
  style?: CSSProperties;
  textStyle?: CSSProperties;
  type: TypeAttributes.Status;
}

export const BigMessage: React.FC<IBigMessageProps> = ({
  title,
  description,
  maxLength,
  style,
  textStyle,
  type,
}) => {
  return (
    <>
      <Message showIcon type={type} style={style} header={title}>
        <ExpandableText
          style={textStyle}
          text={description}
          maxLength={maxLength}
        />
      </Message>
    </>
  );
};
