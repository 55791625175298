import { useEffect, useState } from "react";

const initialHeight = 500;
const initialRotate = 0;

const scrollContainerStyle = {
  height: 500,
  maxWidth: 500,
  borderRadius: 15,
};

const boxStyle = { display: "flex", justifyContent: "space-between" };

const scrollContainerWrapperStyle = {
  borderRadius: 15,
  border: "1px solid var(--rs-border-primary)",
  width: "100%",
};

const buttonToolBarStyle = { margin: "20px 0px 0px 20px" };

export const useFileViewer = (imgSrc: string) => {
  const [imgHeight, setImgHeight] = useState(initialHeight);
  const [rotate, setRotate] = useState(initialRotate);

  const handleReloadImg = () => {
    setImgHeight(initialHeight);
    setRotate(initialRotate);
  };

  useEffect(() => {
    handleReloadImg();
  }, [imgSrc]);

  useEffect(() => {
    imgHeight < 100 && setImgHeight(100);
  }, [imgHeight]);

  const handleButtonRightTurn = () => setRotate(rotate + 90);
  const handleButtonLeftTurn = () => setRotate(rotate - 90);
  const handleWheelChangeHeight = (
    event: React.WheelEvent<HTMLImageElement>
  ) => {
    const { deltaY } = event;
    if (deltaY > 0) {
      setImgHeight((prev) => prev - 50);
      return;
    }
    setImgHeight((prev) => prev + 50);
  };

  const imgStyle = {
    height: imgHeight,
    cursor: "grab",
    transform: `rotate(${rotate}deg)`,
  };

  const handleImgIncrease = () => setImgHeight((prev) => prev + 50);
  const handleImgDecrease = () => setImgHeight((prev) => prev - 50);

  return {
    buttonToolBarStyle,
    imgStyle,
    scrollContainerStyle,
    boxStyle,
    scrollContainerWrapperStyle,
    handleImgIncrease,
    handleImgDecrease,
    handleReloadImg,
    handleWheelChangeHeight,
    handleButtonLeftTurn,
    handleButtonRightTurn,
  };
};
