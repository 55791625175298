import { ContainerPage } from "containers";
import { Providers } from "provider";
import React, { createContext } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import RootStore from "stores/rootStore";

export const store = new RootStore();
export const StoreContext = createContext(store);

export function App() {
  return (
    <React.StrictMode>
      <Providers>
        <Router>
          <ContainerPage />
        </Router>
      </Providers>
    </React.StrictMode>
  );
}
