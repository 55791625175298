import { makeAutoObservable, runInAction } from "mobx";
import Api from "../root/Api";
import { ITeacher } from "./models/IUser";
import { queryClient } from "provider/QueryProvider/QueryProvider";

export class TeacherStore {
  teachers: ITeacher[] = [];
  onLoading = false;
  onUpdating = false;

  constructor() {
    makeAutoObservable(this);
  }

  public find(id: number) {
    return this.teachers.find((x) => x.id === id);
  }
  public async load(refetch = false) {
    this.setOnLoading(true);
    const queryKey = ["load", "Api.teachers.GetAll"];
    try {
      refetch && queryClient.removeQueries(queryKey);
      const result = await queryClient.fetchQuery({
        queryKey,
        queryFn: async () => await Api.teachers.GetAll(refetch),
      });

      runInAction(() => result && (this.teachers = result));
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
    return null;
  }

  private setOnLoading(value: boolean) {
    this.onLoading = value;
  }
  public clear() {
    runInAction(() => {
      this.teachers = [];
    });
  }
}
