import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FileUploaderHelper, GlobalConstants, ValidationHelper } from "root";
import { ButtonToolbar, Form, Rate, SelectPicker, TagPicker } from "rsuite";
import { FileType } from "rsuite/esm/Uploader";
import { ITask, TaskType } from "stores";
import { ValidationResult } from "stores/models/ValidationResult";
import { useStore } from "../../hooks";
import { FileUploader } from "../FormComponents";
import { RichTextArea } from "../FormComponents/RichTextArea";
import { UploaderDraggable } from "../FormComponents/UploaderDraggable";
import { HeaderTheme } from "./HeaderTheme";
import { Button, Divider, Input, InputNumber } from "shared/ui";

interface ITaskEditFormProps {
  task?: ITask;
  onClose?: () => void;
}

const TaskEditFormComponent: React.FC<ITaskEditFormProps> = (
  props: ITaskEditFormProps
) => {
  const { task } = props;

  const { taskStore } = useStore();

  const [valueCriteria, setValueCriteria] = useState([]);
  const [valueQuestion, setValueQuestion] = useState([]);

  const [typeTask, setTypeTask] = useState<number | null>(task?.type || null);
  const [kimFirstPart, setKimFirstPart] = useState([{}]);
  const [kimSecondPart, setKimSecondPart] = useState([{}]);
  const [selectKim, setSelectKim] = useState([{}]);
  const [maxScoreValue, setMaxScoreValue] = useState<number>(0);

  const [formValue, setFormValue] = useState<any>({});
  const [validationResult, setValidationResult] = useState<ValidationResult>();
  const selectDataThemeTag: any[] = [];

  const [emptyValueHeader, setEmptyValueHeader] = useState(false);

  useEffect(() => {
    if (!task) {
      return;
    }
    setMaxScoreValue(task.maxScore || 0);
    setFormValue({
      ...task,
    });
  }, [task]);

  const defaultFormValue = {
    themeId: 0,
    kim: 0,
    type: undefined,
    mainTaskId: undefined,
    question: undefined,
    answer: "",
    maxScore: undefined,
    keys: undefined,
    commentForReviewer: undefined,
    fileQuestionId: undefined,
    TagPicker: undefined,
    difficulty: 0,
  };

  useEffect(() => {
    setKimFirstPart(
      GlobalConstants.kimBySocialScience.kimFirstPart.map((item) => ({
        label: item,
        value: item,
      }))
    );
    setKimSecondPart(
      GlobalConstants.kimBySocialScience.kimSecondPart.map((item) => ({
        label: item,
        value: item,
      }))
    );
  }, []);

  useEffect(() => {
    if (!typeTask) {
      return;
    }
    switch (typeTask) {
      case TaskType.FirstPart:
        setSelectKim(kimFirstPart);
        break;
      case TaskType.SecondPart:
        setSelectKim(kimSecondPart);
        break;
      case TaskType.FileAnswer:
      case TaskType.FileQuestion:
        setMaxScoreValue(1);
        setMaxScore(1);
        break;
      default:
        break;
    }
  }, [typeTask, kimFirstPart, kimSecondPart]);

  const onCloseOrResetForm = () => {
    if (props.onClose) {
      props.onClose();
    }
    setEmptyValueHeader(true);
    setFormValue(defaultFormValue);
  };

  const createOrUpdateTask = async () => {
    const resultValidation = ValidationHelper.taskValidate(formValue as ITask);
    setValidationResult(resultValidation);
    if (
      resultValidation.hasNotError() &&
      (await taskStore.save(formValue as ITask))
    ) {
      onCloseOrResetForm();
    }
  };

  function mapFiles(): FileType[] {
    if (!task || (!task.fileCriteriaId && !task.fileQuestionId)) {
      return [];
    }
    let name = "";
    let fileKey = 0;
    let url: string | undefined = "";

    if (task.fileCriteriaId) {
      name = "критерии";
      fileKey = task.fileCriteriaId;
      url = task.fileCriteriaUrl;
    }
    if (task.fileQuestionId) {
      name = "файлы для скачивания";
      fileKey = task.fileQuestionId;
      url = task.fileQuestionUrl;
    }

    return [
      {
        name: name,
        fileKey: fileKey,
        status: "finished",
        url: url,
      },
    ];
  }

  const setMaxScore = (score: number) => {
    setFormValue({ ...formValue, maxScore: score });
    setMaxScoreValue(score);
  };

  const handleSetMaxScoreValue = (value: number) => {
    const kimMaxScoreOne = [1, 9, 3, 12];
    const kimMaxScoreThree = [19, 20, 21, 23];
    const kimMaxScoreFour = [22, 24];
    const kimMaxScoreSix = [25];

    if (kimMaxScoreOne.some((x) => x === value)) {
      setMaxScore(1);
      return;
    }
    if (kimMaxScoreSix.some((x) => x === value)) {
      setMaxScore(6);
      return;
    }
    if (kimMaxScoreThree.some((x) => x === value)) {
      setMaxScore(3);
      return;
    }
    if (kimMaxScoreFour.some((x) => x === value)) {
      setMaxScore(4);
      return;
    }
    setMaxScore(2);
  };

  useEffect(() => {
    if (!formValue.kim) {
      return;
    }
    handleSetMaxScoreValue(formValue.kim);
  }, [formValue.kim]);

  const onChangeIsEmpty = () => {
    setEmptyValueHeader(false);
  };

  return (
    <Form
      formValue={formValue}
      onChange={(formValue: any) => {
        setFormValue(formValue);
      }}
      onSubmit={createOrUpdateTask}
    >
      <HeaderTheme
        validationResult={validationResult}
        onChangeIsEmpty={onChangeIsEmpty}
        isEmpty={emptyValueHeader}
        task={props.task}
        onChangeType={(type) => {
          setTypeTask(type);
        }}
        setFormValue={(formValueHeader) => {
          setFormValue({ ...formValue, ...formValueHeader });
        }}
      />
      <Divider style={{ marginBottom: 30 }} />
      {(typeTask === TaskType.FirstPart ||
        typeTask === TaskType.SecondPart) && (
        <Form.Group>
          <Form.Control
            name="kim"
            accepter={SelectPicker}
            label="Номер КИМ"
            data={selectKim}
            errorMessage={validationResult?.getError("kim")}
          />
        </Form.Group>
      )}
      <Form.Group controlId="textarea-6e">
        <Form.ControlLabel>Задание</Form.ControlLabel>
        <Form.Control
          name="question"
          accepter={RichTextArea}
          defaultValue={task?.question}
          style={{ width: 700 }}
          errorMessage={validationResult?.getError("question")}
        />
      </Form.Group>

      {typeTask === TaskType.FirstPart && (
        <Form.Group controlId="textarea-6">
          <Form.ControlLabel>Ответ</Form.ControlLabel>
          <Form.Control
            name="answer"
            accepter={Input}
            errorMessage={validationResult?.getError("answer")}
            autoComplete="off"
          />
        </Form.Group>
      )}

      <Form.Group controlId="selectPicker">
        <Form.ControlLabel>Максимальный балл</Form.ControlLabel>
        <Form.Control
          disabled
          name="maxScore"
          accepter={InputNumber}
          value={maxScoreValue}
          errorMessage={validationResult?.getError("maxScore")}
        />
      </Form.Group>
      {(typeTask === TaskType.FirstPart ||
        typeTask === TaskType.SecondPart) && (
        <Form.Group controlId="textarea-6">
          <Form.ControlLabel>Ключи</Form.ControlLabel>
          <Form.Control
            name="keys"
            accepter={RichTextArea}
            defaultValue={task?.keys}
            style={{ width: 700 }}
            errorMessage={validationResult?.getError("keys")}
          />
        </Form.Group>
      )}
      {typeTask === TaskType.SecondPart && (
        <Form.Group controlId="textarea-6">
          <Form.ControlLabel>Пояснения для проверяющего</Form.ControlLabel>
          <Form.Control
            name="commentForReviewer"
            accepter={RichTextArea}
            defaultValue={task?.commentForReviewer}
            style={{ width: 700 }}
          />
        </Form.Group>
      )}
      {typeTask === TaskType.FileQuestion && (
        <Form.Group controlId="fileQuestionId">
          <Form.ControlLabel>Файл для ученика</Form.ControlLabel>
          <Form.Control
            value={valueQuestion}
            onChange={setValueQuestion}
            disabled={valueQuestion.length >= 1}
            name="fileQuestionId"
            accepter={FileUploader}
            errorMessage={validationResult?.getError("fileQuestionId")}
            defaultFileList={mapFiles()}
            shouldQueueUpdate={(fileList: FileType[]) =>
              FileUploaderHelper.canUpload(fileList, 1)
            }
            onSuccess={(id: number) => {
              formValue.fileQuestionId = id;
              setFormValue({ ...formValue });
            }}
            onRemove={() => {
              formValue.fileQuestionId = null;
            }}
            draggable
            style={{ width: 700 }}
          >
            <div>
              <UploaderDraggable />
            </div>
          </Form.Control>
        </Form.Group>
      )}
      {typeTask === TaskType.SecondPart && (
        <Form.Group controlId="criteria">
          <Form.ControlLabel>Критерии</Form.ControlLabel>

          <Form.Control
            value={valueCriteria}
            onChange={setValueCriteria}
            disabled={valueCriteria.length >= 1}
            accept=".jpg, .jpeg, .png"
            name="fakeNameForForm2"
            accepter={FileUploader}
            defaultFileList={mapFiles()}
            shouldQueueUpdate={(fileList: FileType[]) =>
              FileUploaderHelper.canUpload(fileList, 1)
            }
            onSuccess={(id: number) => {
              formValue.fileCriteriaId = id;
              setFormValue({ ...formValue });
            }}
            onRemove={() => {
              formValue.fileCriteriaId = null;
            }}
            draggable
            style={{ width: 700 }}
          >
            <div
              style={{
                height: 50,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <span>Нажми или перетащи файлы для загрузки</span>
            </div>
          </Form.Control>
        </Form.Group>
      )}

      <Form.Group controlId="TagPicker">
        <Form.ControlLabel>Теги</Form.ControlLabel>
        <Form.Control
          name="TagPicker"
          accepter={TagPicker}
          data={selectDataThemeTag}
        />
      </Form.Group>

      <Form.Group>
        <Form.ControlLabel>Сложность задания</Form.ControlLabel>
        <Form.Control
          name="difficulty"
          accepter={Rate}
          allowHalf
          size="sm"
          errorMessage={validationResult?.getError("difficulty")}
        />
      </Form.Group>

      <Form.Group>
        <ButtonToolbar>
          <Button
            loading={taskStore.onUpdating}
            appearance="primary"
            type="submit"
          >
            Отправить
          </Button>
          <Button
            appearance="default"
            onClick={() => {
              onCloseOrResetForm();
            }}
          >
            Отмена
          </Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  );
};
export const TaskEditForm = observer(TaskEditFormComponent);
