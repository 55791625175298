interface ISubject {
  kimFirstPart: number[];
  kimSecondPart: number[];
}

export class GlobalConstants {
  static wavegeCart = "wavege_cart";
  static tabTitleForContentWithoutTitle = "Онлайн-школа подготовки к ЕГЭ и ОГЭ";
  static postFixedBrowserTabTitle = "| wavege";
  static globalNotificationFilterForSessionStorage =
    "we_global_notification_filter";
  static viewedAndClosedAlertNotificationIds =
    "we_viewed_and_closed_alert_global_notification_filter";
  static filterTasks = "wavege-task-list-filter";
  static filterLessons = "wavege-lessons-list-filter";
  static filterCheck = "wavege-homeworks-check-filter";
  static filterCatalog = "wavege_catalog_filter";
  static allExamType = 0;
  static allTariffType = 0;
  static onlyFreeTariff = "free";
  static kimBySocialScience: ISubject = {
    kimFirstPart: [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12, 13, 14, 15, 16],
    kimSecondPart: [17, 18, 19, 20, 21, 22, 23, 24, 25],
  };
  static isUnauthorizedGetPayUrl = "wavege_is_unauthorized_get_pay_url";
  static tariffIdForFreeGet = "wavege_tariff_id_for_free_get";
  static telegramLinkToSupport = "https://t.me/wavege";
  static selectedTariffId = "wavege-selected-tariff-id";
  static filterStudentTariffs = "wavege-student-tariff-list-filter";
  static filterHistoryOrders = "wavege-student-history-orders-list-filter";
  static filterTaskPack = "wavege-taskpack-list-filter";
  static eventFailedAuth = "failedAuth";
  static eventDraftSaved = "draftSaved";
  static eventDraftSaving = "draftSaving";
}
