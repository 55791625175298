import { CardWithImage } from "components";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import { Placeholder } from "rsuite";
import { PlaceholderHomeworkChecks } from "../components";
import { Check } from "./Check";
import { Panel } from "shared/ui";

export const StatusComponent: React.FC = () => {
  const { tariffStore } = useStore();

  if (tariffStore.onLoading) {
    return (
      <>
        <Panel bordered style={{ marginBottom: 12 }}>
          <Placeholder.Paragraph active rows={1} />
        </Panel>
        <PlaceholderHomeworkChecks />
      </>
    );
  }

  if (tariffStore.myTariffs && tariffStore.myTariffs.length === 0) {
    return (
      <CardWithImage
        imgSrc={`${process.env.PUBLIC_URL}/images/no-checks.png`}
        title="здесь пока ничего нет"
        description="позже здесь появится список твоих домашек"
        imageStyle={{ width: "auto", height: 125 }}
      />
    );
  }
  return <Check />;
};
export const Status = observer(StatusComponent);
