import { useStore } from "hooks";
import { useEffect, useState } from "react";
import { StudentDraftAnswersCheckStorage } from "root/SaveCheckDraftHelper";
import {
  HomeworksCheckOrder,
  IRequestGetHomeworksCheckByFilter,
} from "root/requests";
import { IAnswer, IHomeworkCheck } from "stores";

const filter: IRequestGetHomeworksCheckByFilter = {
  count: 0,
  orderBy: HomeworksCheckOrder.SubmitDeadlineAsc,
};

export const useAnswers = (
  lessonId: number,
  studentId: number,
  homeworkId: number,
  packId: number,
  updateCurrentAnswers: (newAnswers: IAnswer[]) => void
) => {
  const { homeworkStore } = useStore();
  const [tasksPackHwStudentId, setTasksPackHwStudentId] = useState<number>();
  const [checkByLesson, setCheckByLesson] = useState<IHomeworkCheck[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [delayReason, setDelayReason] = useState<string | undefined>(undefined);

  useEffect(() => {
    if (!lessonId || !studentId || !homeworkId || !packId) {
      return;
    }
    updateHomeworkStatus(lessonId, studentId);
    updateAnswers(lessonId, studentId, homeworkId, packId);
  }, [lessonId, studentId, homeworkId, packId]);

  const updateAnswers = async (
    lessonId: number,
    studentId: number,
    homeworkId: number,
    packId: number
  ) => {
    try {
      setIsLoading(true);
      setDelayReason(undefined);
      const draftAnswers = StudentDraftAnswersCheckStorage.getDraftAnswers(
        lessonId,
        homeworkId,
        packId,
        studentId
      );
      if (draftAnswers && draftAnswers.length) {
        updateCurrentAnswers(draftAnswers);
        setTasksPackHwStudentId(draftAnswers[0].tasksPackHwStudentId);
      } else {
        const res = await loadAnswers(lessonId, homeworkId, packId, studentId);
        updateCurrentAnswers(res?.[0]?.answers || []);
        setTasksPackHwStudentId(res?.[0]?.tasksPackHwStudentId);
        setDelayReason(res?.[0]?.delayReason ?? undefined);
      }
    } catch (error) {
      console.error("Error update answers:", error);
    } finally {
      setIsLoading(false);
    }
  };

  const loadAnswers = async (
    lessonId: number,
    homeworkId: number,
    packId: number,
    studentId: number
  ) => {
    return await homeworkStore.studentAnswersByLesson(
      lessonId,
      studentId,
      homeworkId,
      packId
    );
  };

  const loadCheckByLesson = async (
    lessonId: number,
    studentId: number
  ): Promise<IHomeworkCheck[] | undefined> => {
    const foundHomework = homeworkStore.dataPage?.data.filter(
      (x) => x.lessonId === lessonId && x.studentId === studentId
    );

    if (foundHomework) {
      return foundHomework;
    } else {
      const result = await homeworkStore.loadByFilter(
        {
          ...filter,
          lessonId,
          studentId,
        },
        { reloadDataPage: false }
      );
      return result?.data;
    }
  };

  const updateHomeworkStatus = (lessonId: number, studentId: number) => {
    setIsLoading(true);
    loadCheckByLesson(lessonId, studentId)
      .then((res) => {
        setCheckByLesson(res || []);
      })
      .finally(() => setIsLoading(false));
  };

  return { tasksPackHwStudentId, checkByLesson, isLoading, delayReason };
};
