/**
 * @description
 * Takes an Array<V>, and a grouping function,
 * and returns a Map of the array grouped by the grouping function.
 *
 * @param list An array of type V.
 * @param keyGetter A Function that takes the the Array type V as an input, and returns a value of type K.
 *                  K is generally intended to be a property key of V.
 *
 * @returns Map of the array grouped by the grouping function.
 */
export class DataHelper {
  public static groupBy<K, V>(
    list: Array<V>,
    keyGetter: (input: V) => K
  ): Map<K, Array<V>> {
    const map = new Map<K, Array<V>>();
    list.forEach((item: any) => {
      const key = keyGetter(item);
      const collection = map.get(key);
      if (!collection) {
        map.set(key, [item]);
      } else {
        collection.push(item);
      }
    });
    return map;
  }

  public static sum<V>(
    list: Array<V>,
    valueGetter: (input: V) => number | undefined
  ): number {
    let result = 0;
    list.forEach((item: any) => {
      const value = valueGetter(item);
      result += value || 0;
    });
    return result;
  }

  public static max<V>(
    list: Array<V>,
    valueGetter: (input: V) => number | undefined
  ): number {
    const result = list.reduce(function (a, b) {
      return (valueGetter(a) || 0) > (valueGetter(b) || 0) ? a : b;
    });
    return valueGetter(result) || 0;
  }
}
