import { Table } from "rsuite";
import { Checkbox } from "shared/ui";
const { Cell } = Table;

export const CheckCell = ({
  rowData,
  onChange,
  checkedKeys,
  dataKey,
  ...props
}: any) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div style={{ lineHeight: "46px" }}>
      <Checkbox
        value={rowData[dataKey]}
        inline
        onChange={onChange}
        checked={
          checkedKeys.some((item: any) => item === rowData[dataKey]) as
            | boolean
            | undefined
        }
      />
    </div>
  </Cell>
);

export const CheckReadonlyCell = ({ rowData, dataKey, ...props }: any) => (
  <Cell {...props} style={{ padding: 0 }}>
    <div style={{ lineHeight: "46px" }}>
      <Checkbox checked={rowData[dataKey]} readOnly inline />
    </div>
  </Cell>
);
