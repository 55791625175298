import { makeObservable, observable, runInAction } from "mobx";
import { IRequestGetLessonsByFilter, NotificationHelper } from "../root";
import Api from "../root/Api";
import { CrudStore } from "./CrudStore";
import {
  IHomeWorkTaskPack,
  ILesson,
  IPage,
  IPageableStore,
  LessonType,
} from "./models";
import { queryClient } from "provider/QueryProvider/QueryProvider";

export class LessonsStore
  extends CrudStore<ILesson>
  implements IPageableStore<IRequestGetLessonsByFilter, ILesson>
{
  publishedLessons: ILesson[] = [];
  filter: IRequestGetLessonsByFilter | undefined;
  dataPage: IPage<ILesson> | undefined;
  lessonsNotPayment: ILesson[] = [];

  constructor() {
    super(Api.lessons);
    makeObservable(this, {
      dataPage: observable,
    });
  }

  public async findOrLoad(id: number): Promise<ILesson | undefined> {
    let result = this.find(id);
    if (result) {
      return result;
    }
    result = await queryClient.fetchQuery({
      queryKey: ["lesson", id],
      queryFn: async () => await this.getLesson(id),
    });
    runInAction(() => result && this.data.push(result));
    return result;
  }

  private async getLesson(id: number): Promise<ILesson | undefined> {
    this.setOnLoading(true);
    try {
      return await Api.lessons.Get(id);
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
  }

  public async loadByFilter(
    filter: IRequestGetLessonsByFilter,
    refetch = false
  ): Promise<IPage<ILesson> | undefined> {
    this.setOnLoading(true);
    const queryKey = ["lessons", filter];
    try {
      refetch && queryClient.removeQueries(queryKey);
      const result = await queryClient.fetchQuery({
        queryKey,
        queryFn: async () => await Api.lessons.GetLessonsByFilter(filter),
      });
      runInAction(() => {
        result && this.addOrUpdateLessons(result.data);
        this.filter = filter;
        this.dataPage = result;
      });
      return result;
    } catch (error) {
      console.error(error);
      return undefined;
    } finally {
      this.setOnLoading(false);
    }
  }

  public async save(
    obj: any,
    entityId: number | undefined = undefined
  ): Promise<boolean> {
    this.setOnSaving(true);
    try {
      if (!entityId && obj.id && obj.id > 0) {
        entityId = obj.id;
      }
      const id = entityId
        ? await Api.lessons.Update(entityId, obj)
        : await Api.lessons.Create(obj);
      NotificationHelper.ShowSuccess("Успешно сохранено!");
      this.refresh(id);
      this.filter && this.loadByFilter(this.filter, true);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.setOnSaving(false);
    }
  }

  public async load(refetch = false) {
    this.setOnLoading(true);
    const queryKey = ["load", "Api.lessons.GetAll"];

    try {
      refetch && queryClient.removeQueries(queryKey);
      const result = await queryClient.fetchQuery({
        queryKey,
        queryFn: async () => await Api.lessons.GetAll(refetch),
      });

      runInAction(() => {
        if (result) {
          this.data = result;
          const filteredLessons = this.filterPublishedLessons(result);
          this.publishedLessons = filteredLessons;
          this.lessonsNotPayment = this.getLessonsNotPayment(filteredLessons);
        }
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
    return null;
  }

  private getLessonsNotPayment(lessons: ILesson[]): ILesson[] {
    const baseLessons = lessons.filter((x) => x.lessonType === LessonType.Base);

    const lessonsByTariff: { [tariffId: number]: ILesson } = {};

    for (const lesson of baseLessons) {
      const tariffId = lesson.tariffId;

      if (
        !(tariffId in lessonsByTariff) ||
        lesson.dateTime > lessonsByTariff[tariffId].dateTime
      ) {
        lessonsByTariff[tariffId] = lesson;
      }
    }
    const currentLessons = Object.values(lessonsByTariff).filter(
      (x) => !x.enabled
    );
    return currentLessons;
  }

  private addOrUpdateLessons(lessons: ILesson[]) {
    lessons.forEach((newData) => {
      const index = this.data.findIndex((item) => item.id === newData.id);

      if (index === -1) {
        runInAction(() => {
          this.data.push(newData);
        });
      } else {
        runInAction(() => {
          this.data[index] = newData;
        });
      }
    });
  }

  public async checkViewed(id: number) {
    this.setOnSaving(true);
    try {
      await Api.lessons.checkViewedLesson(id);
      NotificationHelper.ShowSuccess("Вебчик просмотрен!");
      const lesson = this.find(id);
      if (lesson) {
        lesson.isViewed = !lesson.isViewed;
      }
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnSaving(false);
    }
  }

  public getHomeworkTaskPack = (
    lessonId: number,
    homeworkId: number,
    packId: number
  ): IHomeWorkTaskPack | undefined => {
    const lesson = this.find(lessonId);
    if (!lesson) return undefined;

    const homework = lesson.homeWorks?.find((h) => h.id === homeworkId);
    if (!homework) return undefined;

    const pack = homework.packs.find((p) => p.id === packId);
    if (!pack) return undefined;

    return pack;
  };

  //   прячу уроки из списка уроков, если материалы ещё не прикрепляли
  // ps если материалов нет - значит это просто анонс и должен быть только в расписании
  private filterPublishedLessons(lessons: ILesson[]) {
    const filteredLessons = lessons.filter(
      (x) => x.webinarImageUrl !== null && x.imageUrl !== null
    );
    return filteredLessons;
  }

  public clear() {
    super.clear();
    runInAction(() => {
      this.publishedLessons = [];
      this.filter = undefined;
      this.dataPage = undefined;
      this.lessonsNotPayment = [];
    });
  }
}
