import { useIsMounted, useStore } from "hooks";
import { useEffect, useState } from "react";
import { IStudentHomework } from "stores";

export const useAnswersStudent = (
  homeworkId: number | undefined,
  lessonId: number | undefined,
  packId: number | undefined,
  studentId: number | undefined
) => {
  const isMounted = useIsMounted();

  const { homeworkStore } = useStore();

  const [answersStudent, setAnswersStudent] = useState<IStudentHomework[]>();

  useEffect(() => {
    if (!lessonId || !homeworkId || !packId || !studentId) {
      return;
    }
    loadStudentAnswers(lessonId, homeworkId, packId, studentId);
  }, [lessonId, studentId, homeworkId, packId, homeworkStore.onSaving]);

  const loadStudentAnswers = async (
    lessonId: number,
    homeworkId: number,
    packId: number,
    studentId: number
  ) => {
    const answers = await homeworkStore.studentAnswersByLesson(
      lessonId,
      studentId,
      homeworkId,
      packId
    );
    if (!isMounted()) {
      return;
    }
    setAnswersStudent(answers);
  };

  return { answersStudent };
};
