import React from "react";
import { Placeholder } from "rsuite";
import { Panel } from "shared/ui";

export const PlaceholderTaskPack: React.FC = () => {
  return (
    <>
      <Panel bordered style={{ marginTop: 10 }}>
        <Placeholder.Paragraph rows={1} active />
      </Panel>
      <Panel bordered style={{ marginTop: 10 }}>
        <Placeholder.Paragraph rows={3} active />
      </Panel>
      <Panel bordered style={{ marginTop: 10 }}>
        <Placeholder.Paragraph rows={2} active />
      </Panel>
      <Panel bordered style={{ marginTop: 10 }}>
        <Placeholder.Paragraph rows={1} active />
      </Panel>
    </>
  );
};
