import { IRequestChangeRole } from "root/requests";
import { RoleNames } from "../../stores/models";
import { post } from "../WebHelper";

export class TechController {
  public async ConvertUser(request: IRequestChangeRole) {
    let roleId = 0;
    switch (request.role) {
      case RoleNames.Student:
        roleId = 1;
        break;
      case RoleNames.Teacher:
        roleId = 2;
        break;
      case RoleNames.Mentor:
        roleId = 5;
        break;
    }
    await post(`/Tech/ConvertUser/${request.userId}/${roleId}`);
  }
}
