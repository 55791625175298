import { observer } from "mobx-react";
import React from "react";
import { ButtonToolbar } from "rsuite";
import { Button } from "shared/ui";
import { useStore } from "../../../hooks";

type AutoLoginProps = {
  onLogin?: () => void;
};

const AutoLoginComponent: React.FC<AutoLoginProps> = ({ onLogin }) => {
  const { authStore } = useStore();

  const handleAutoLogin = async (login: string, psw: string) => {
    await authStore.auth(login, psw);
    if (onLogin) {
      onLogin();
    }
  };

  if (process.env.NODE_ENV !== "development") {
    return null;
  }

  return (
    <div style={{ marginTop: 24 }}>
      <div>Автологин:</div>
      <ButtonToolbar>
        <Button
          appearance="link"
          onClick={() => handleAutoLogin("vsb", "IYS8sECx")}
        >
          vsb (Super)
        </Button>
        <Button
          appearance="link"
          onClick={() => handleAutoLogin("vsb_admin", "123")}
        >
          vsb_admin
        </Button>
        <Button
          appearance="link"
          onClick={() => handleAutoLogin("vsb_teacher", "123")}
        >
          vsb_teacher
        </Button>
        <Button
          appearance="link"
          onClick={() => handleAutoLogin("vsb_student", "123")}
        >
          vsb_student
        </Button>
        <Button
          appearance="link"
          onClick={() => handleAutoLogin("student2", "Qwerty1234")}
        >
          student2
        </Button>

        <Button
          appearance="link"
          onClick={() => handleAutoLogin("mentor", "123")}
        >
          mentor
        </Button>
        <Button
          appearance="link"
          onClick={() => handleAutoLogin("euc49939@omeie.com", "Uchenik")}
        >
          Ученик (Тест)
        </Button>
      </ButtonToolbar>
    </div>
  );
};

export const AutoLogin = observer(AutoLoginComponent);
