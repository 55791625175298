import { Cell } from "rsuite-table";
import { IPromotion, SaleType } from "stores";
import { observer } from "mobx-react";

interface ISaleCostCellProps {
  rowData?: IPromotion;
  dataKey: string;
}

const SaleCostCellComponent: React.FC<ISaleCostCellProps> = (props) => {
  if (!props.rowData) {
    return <Cell></Cell>;
  }

  return (
    <Cell {...props}>{`${props.rowData.saleCost}${
      props.rowData.saleType === SaleType.Percent ? "%" : "₽"
    }`}</Cell>
  );
};
export const SaleCostCell = observer(SaleCostCellComponent);
