import { RichTextArea } from "components/FormComponents";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import React from "react";
import { mapEveryField } from "root/RawHelper";
import { Form, RadioGroup, SelectPicker } from "rsuite";
import { isBefore } from "rsuite/esm/utils/dateUtils";
import { DatePicker, Input, Radio } from "shared/ui";
import { ILessonRequest, LessonType } from "stores";
import { ValidationResult } from "stores/models/ValidationResult";

interface IPreviewProps {
  lessonRequest: ILessonRequest;
  validationResult?: ValidationResult;
}

const PreviewComponent: React.FC<IPreviewProps> = (props: IPreviewProps) => {
  const { tariffStore } = useStore();

  const selectTarif = tariffStore.data.map((x) => ({
    label: x.title,
    value: x.id,
  }));

  return (
    <>
      <Form
        fluid
        formValue={props.lessonRequest}
        onChange={(formValue: any) => {
          mapEveryField(props.lessonRequest, formValue, true);
        }}
      >
        <Form.Group controlId="title">
          <Form.ControlLabel>Название урока</Form.ControlLabel>
          <Form.Control
            name="title"
            accepter={Input}
            errorMessage={props.validationResult?.getError("title")}
          />
        </Form.Group>

        <Form.Group controlId="description">
          <Form.ControlLabel>Описание урока</Form.ControlLabel>
          <Form.Control
            data-testid="description"
            name="description"
            accepter={RichTextArea}
            defaultValue={props.lessonRequest.description}
            isPreview={false}
            errorMessage={props.validationResult?.getError("description")}
          />
        </Form.Group>

        <Form.Group>
          <Form.Control
            data-testid="tariffId"
            name="tariffId"
            accepter={SelectPicker}
            label="Курс"
            data={selectTarif}
            errorMessage={props.validationResult?.getError("tariffId")}
          />
          <Form.HelpText>
            Для учеников какого курса будут доступны уроки.
          </Form.HelpText>
        </Form.Group>

        <Form.Group controlId="dateTime">
          <Form.ControlLabel>Дата и время проведения занятия</Form.ControlLabel>
          <Form.Control
            data-testid="dateTime"
            style={{ width: 260 }}
            placeholder="Дата и время"
            format="dd-MM-yyyy HH:mm"
            name="dateTime"
            accepter={DatePicker}
            shouldDisableDate={(date: any) =>
              date ? isBefore(date, new Date()) : false
            }
            errorMessage={props.validationResult?.getError("dateTime")}
          />
        </Form.Group>

        <Form.Group controlId="lessonType">
          <Form.Control
            data-testid="lessonType"
            name="lessonType"
            accepter={RadioGroup}
            errorMessage={props.validationResult?.getError("lessonType")}
          >
            <Radio value={LessonType.Base} data-testid="lessonType-base">Основной урок</Radio>
            <Radio value={LessonType.Additional} data-testid="lessonType-additional">Дополнительный</Radio>
            <Radio value={LessonType.Public} data-testid="lessonType-public">Открытый</Radio>
          </Form.Control>
        </Form.Group>
      </Form>
    </>
  );
};
export const Preview = observer(PreviewComponent);
