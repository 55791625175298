import { useRef } from "react";
import { MdOutlineLightMode, MdOutlineNightlight } from "react-icons/md";
import { useStore } from "../../hooks";
import { Icon } from "shared/ui";

export const useThemeButton = () => {
  const ref = useRef<HTMLElement>(null);
  const { uiStore } = useStore();
  const iconImage =
    uiStore.theme === "light" ? MdOutlineNightlight : MdOutlineLightMode;

  const handleClick = () => {
    uiStore.invertTheme();
    ref.current?.blur();
  };
  const icon = <Icon as={iconImage} style={{ fontSize: 18 }} />;
  const style = { border: "none" };

  return { icon, handleClick, ref, style };
};
