import { EmptyStatePanel } from "components";
import { useNumberParams } from "hooks/useNumberParams";
import { DropAnswersButton } from "./components/DropAnswersButton/DropAnswersButton";
import { LessonHomeworkTitle } from "./components/LessonHomeworkTitle/LessonHomeworkTitle";
import { StudentHomeworkSwitch } from "./components/StudentHomeworkSwitch/StudentHomeworkSwitch";
import { TaskPackViewer } from "./components/TaskPackViewer/TaskPackViewer";

export const CheckEditorPage: React.FC = () => {
  const { lessonId, studentId, homeworkId, packId } = useNumberParams();

  if (!lessonId || !studentId) {
    return <EmptyStatePanel />;
  }

  return (
    <>
      <LessonHomeworkTitle lessonId={lessonId} studentId={studentId} />
      <StudentHomeworkSwitch />
      <DropAnswersButton />
      <TaskPackViewer
        lessonId={lessonId}
        studentId={studentId}
        homeworkId={homeworkId}
        packId={packId}
      />
    </>
  );
};
