import { observer } from "mobx-react";
import { Stack } from "rsuite";
import { Input, Pill } from "shared/ui";
import { IAnswer } from "stores";
import { useAnswerFirstPart } from "./useAnswerFirstPart";

type TAnswerFirstPartProps = {
  score?: number;
  maxScore: number;
  studentAnswer?: IAnswer["answerPart1"];
};

const AnswerFirstPartComponent: React.FC<TAnswerFirstPartProps> = ({
  maxScore,
  studentAnswer,
  score,
}) => {
  const { color, inputStyle } = useAnswerFirstPart(score, maxScore);

  return (
    <Stack spacing={8}>
      <Input
        disabled
        value={studentAnswer}
        placeholder="Ответа нет"
        style={inputStyle}
      />

      <Pill color={color}>
        {score} / {maxScore}
      </Pill>
    </Stack>
  );
};

export const AnswerFirstPart = observer(AnswerFirstPartComponent);
