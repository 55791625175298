import { ITask, IAnswer, TaskType } from "stores";
import { AnswerFirstPart } from "./AnswerFirstPart/AnswerFirstPart";
import { CommentForReviewer } from "./CommentForReviewer";
import { ITaskWithAnswer } from "./ITaskWithAnswer";
import { TaskKeys } from "./TaskKeys";
import { TaskQuestion } from "./TaskQuestion";
import { AnswerCriteria } from "./AnswerSecondPart/AnswerCriteria";
import { AnswerSrcList } from "./AnswerFileAnswer/AnswerSrcList";
import { InputComment } from "./AnswerSecondPart/InputComment";
import { TaskWithInputScore } from "./TaskWithInputScore";
import { Panel } from "shared/ui";

interface ITaskWithAnswerItemProps extends ITaskWithAnswer {
  task: ITask;
  answer: IAnswer | undefined;
}

export const TaskWithAnswerItem: React.FC<ITaskWithAnswerItemProps> = ({
  validationResult,
  disabledInput,
  answer,
  onChange,
  task,
}) => {
  const answerPart1 = answer?.answerPart1;
  const answerPart2 = answer?.comment || answer?.answerPart2 || "";
  const answerFileUrlList = answer?.answerFileUrlList || [];
  const score = answer?.score;
  const {
    keys,
    maxScore = 0,
    id,
    commentForReviewer,
    difficulty,
    kim,
    question,
    fileCriteriaUrl,
    type,
  } = task;

  const handleChangeScore = (score: number | undefined) =>
    onChange(id, undefined, score);
  const handleChangeComment = (comment: string | undefined) =>
    onChange(id, comment, undefined);

  const scoreError = validationResult?.getError(`score-${id}`);

  return (
    <>
      <TaskQuestion difficulty={difficulty} kim={kim} question={question} />
      <Panel bordered style={{ marginTop: 10, marginBottom: 25 }}>
        <CommentForReviewer commentForReviewer={commentForReviewer} />

        {type === TaskType.FirstPart && (
          <AnswerFirstPart
            score={score}
            maxScore={maxScore}
            studentAnswer={answerPart1}
          />
        )}

        {(type === TaskType.SecondPart || type === TaskType.FileAnswer) && (
          <TaskWithInputScore
            disabledInput={disabledInput}
            score={score}
            maxScore={maxScore}
            onChange={handleChangeScore}
            scoreError={scoreError}
          >
            {type === TaskType.SecondPart && (
              <InputComment
                answerComment={answerPart2}
                disabledInput={disabledInput}
                onChange={handleChangeComment}
              />
            )}
            {type === TaskType.FileAnswer && (
              <AnswerSrcList answerFileUrlList={answerFileUrlList} />
            )}
          </TaskWithInputScore>
        )}

        <AnswerCriteria fileCriteriaUrl={fileCriteriaUrl} />
        <TaskKeys keys={keys} />
      </Panel>
    </>
  );
};
