import { useStore } from "hooks";
import { observer } from "mobx-react";
import React from "react";
import { ScheduleDrawerItem } from "./ScheduleDrawerItem";

interface IProps {
  date: Date;
}
const ScheduleDrawerContentComponent: React.FC<IProps> = ({ date }) => {
  const { eventStore } = useStore();

  const events = eventStore.getEventList(date);
  if (!events || events.length === 0) {
    return null;
  }

  return (
    <ul
      className="we-calendar-todo-list-home-cell"
      style={{ listStyle: "none", margin: 0, padding: 0 }}
    >
      {events.map((event) => (
        <li key={event.title}>{<ScheduleDrawerItem event={event} />}</li>
      ))}
    </ul>
  );
};

export const ScheduleDrawerContent = observer(ScheduleDrawerContentComponent);
