import React from "react";

type RawHTMLProps = Omit<React.HTMLAttributes<HTMLDivElement>, "children"> & {
  html?: string;
};

export const RawHTML: React.FC<RawHTMLProps> = ({ html, ...rest }) => {
  if (!html) {
    return null;
  }
  if (html?.indexOf("<script") >= 0) {
    throw Error("В описании обнаружен js-скрипт");
  }
  return <div {...rest} dangerouslySetInnerHTML={{ __html: html }} />;
};
