import MenuIcon from "@rsuite/icons/Menu";
import React, { CSSProperties } from "react";
import { IconButton } from "shared/ui";

const iconStyle: CSSProperties = {
  border: "none",
  marginRight: 5,
};

type TButtonMenuProps = { onClick: () => void };

export const ButtonMenu: React.FC<TButtonMenuProps> = ({ onClick }) => (
  <IconButton
    className="invisible-by-min-width-993"
    style={iconStyle}
    icon={<MenuIcon />}
    onClick={onClick}
  />
);
