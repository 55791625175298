import { TypeAttributes } from "rsuite/esm/@types/common";

export const palette: Record<TypeAttributes.Color, string> = {
  red: "#f44336",
  orange: "#fa8900",
  yellow: "#ffb300",
  green: "#4caf50",
  cyan: "#00bcd4",
  blue: "#2196f3",
  violet: "#673ab7",
};
