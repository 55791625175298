import { CardWithImage } from "components/CardWithImage";
import React from "react";

export const NotificationEmpty: React.FC = () => (
  <div className="we-scrollable-container we-flex-container">
    <CardWithImage
      imgSrc={`${process.env.PUBLIC_URL}/images/no-notification.png`}
      title="здесь пока ничего нет"
      description="Тут будут все уведомления о событиях на платформе"
      imageStyle={{ width: "auto", height: 100 }}
    />
  </div>
);
