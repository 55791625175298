import { palette } from "../constants";
import { badgeStyle, emptyBadgeStyle, unionStyle } from "./styles";
import { BadgeProps } from "./types";

export const Badge: React.FC<BadgeProps> = ({
  children,
  content: contentProps,
  color = "red",
  maxCount,
}) => {
  const content =
    typeof contentProps === "number" && maxCount && maxCount < contentProps
      ? `${maxCount}+`
      : contentProps;
  const style = {
    ...badgeStyle,
    backgroundColor: palette[color],
    ...(!contentProps && emptyBadgeStyle),
  };

  return (
    <div style={unionStyle}>
      {children}
      {contentProps !== false && <div style={style}>{content}</div>}
    </div>
  );
};
