import React, { useEffect, useState } from "react";
import { ButtonToolbar, Drawer } from "rsuite";
import { getDate, addDays } from "rsuite/esm/utils/dateUtils";
import PagePreviousIcon from "@rsuite/icons/PagePrevious";
import PageNextIcon from "@rsuite/icons/PageNext";
import { ScheduleDrawerContent } from "./ScheduleDrawerContent";
import { IconButton } from "shared/ui";

interface IProps {
  isOpen: boolean;
  date: Date;
  onClose: () => void;
}
export const ScheduleDrawer: React.FC<IProps> = ({ isOpen, date, onClose }) => {
  const [selectedDate, setSelectedDate] = useState(new Date());

  useEffect(() => {
    setSelectedDate(date);
  }, [date]);

  const weekdays: string[] = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];
  const months: string[] = [
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
  ];

  return (
    <Drawer size="xs" open={isOpen} onClose={onClose}>
      <Drawer.Header>
        <Drawer.Title>
          {
            <>
              {getDate(selectedDate)}
              <span style={{ marginLeft: 3 }}>{`${
                months[selectedDate.getMonth()]
              }, ${weekdays[selectedDate.getDay()]}`}</span>
            </>
          }
        </Drawer.Title>
        <Drawer.Actions>
          <ButtonToolbar>
            <IconButton
              icon={<PagePreviousIcon />}
              onClick={() => setSelectedDate(addDays(selectedDate, -1))}
            />
            <IconButton
              icon={<PageNextIcon />}
              onClick={() => setSelectedDate(addDays(selectedDate, 1))}
            />
          </ButtonToolbar>
        </Drawer.Actions>
      </Drawer.Header>
      <Drawer.Body>
        <ScheduleDrawerContent date={selectedDate} />
      </Drawer.Body>
    </Drawer>
  );
};
