import { useIsMounted, useStore } from "hooks";
import { useEffect, useState } from "react";

export const useReCheckComment = (tasksPackHwStudentId: number | undefined) => {
  const isMounted = useIsMounted();
  const { homeworkStore } = useStore();

  const [reCheckComment, setReCheckComment] = useState<string>();

  useEffect(() => {
    if (!tasksPackHwStudentId) {
      return;
    }
    loadReCheckComment(tasksPackHwStudentId);
  }, [tasksPackHwStudentId]);

  const loadReCheckComment = (tasksPackHwStudentId: number) =>
    homeworkStore
      .getRecheckComment(tasksPackHwStudentId)
      .then((res) => isMounted() && setReCheckComment(res));

  return { reCheckComment };
};
