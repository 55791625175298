import { LessonsControlButtons } from "components";
import { observer } from "mobx-react";
import React from "react";
import { ILesson } from "stores";
import { LessonsHomeWorkPanel } from "./LessonsHomeWorkPanel";
import { useLessonsControlPanel } from "./useLessonsControlPanel";

interface ILessonsControlPanel {
  lesson: ILesson;
  homeworkId: number | undefined;
  taskpackId: number | undefined;
  onClick?: () => void;
}

const LessonsControlPanelComponent: React.FC<ILessonsControlPanel> = ({
  lesson,
  homeworkId,
  taskpackId,
  onClick,
}) => {
  const { selectedLesson, handleOnChange } = useLessonsControlPanel(lesson);

  return (
    <>
      <LessonsControlButtons lesson={lesson} onChange={handleOnChange} />
      <LessonsHomeWorkPanel
        currentLesson={selectedLesson}
        lessonFromParent={lesson}
        homeworkFromParent={homeworkId}
        taskpackFromParent={taskpackId}
        onClick={onClick}
      />
    </>
  );
};
export const LessonsControlPanel = observer(LessonsControlPanelComponent);
