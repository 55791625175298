import { observer } from "mobx-react";
import { useStore } from "hooks";
import { CardWithImage } from "components/CardWithImage";
import { Top } from ".";
import { RoleNames } from "stores";
import { authStore } from "stores/AuthStore";
import { List, Placeholder } from "rsuite";
import { Panel } from "shared/ui";

const PageTopComponent: React.FC = () => {
  const { tariffStore } = useStore();

  if (authStore.onLoading || tariffStore.onLoading) {
    return (
      <>
        <Panel bordered style={{ marginBottom: 12 }}>
          <Placeholder.Paragraph active rows={1} />
        </Panel>
        <List bordered hover>
          <List.Item>
            <Placeholder.Paragraph active graph="square" />
          </List.Item>
          <List.Item>
            <Placeholder.Paragraph active graph="square" />
          </List.Item>
          <List.Item>
            <Placeholder.Paragraph active graph="square" />
          </List.Item>
        </List>
      </>
    );
  }

  if (
    authStore.me &&
    authStore.me.role === RoleNames.Student &&
    tariffStore.myTariffs &&
    tariffStore.myTariffs.length === 0
  ) {
    return (
      <CardWithImage
        imgSrc={`${process.env.PUBLIC_URL}/images/no-top.png`}
        title="здесь пока ничего нет"
        description="позже здесь появится список успешных учеников"
        imageStyle={{ width: "auto", height: 125 }}
      />
    );
  }

  return <Top />;
};

export const PageTop = observer(PageTopComponent);
