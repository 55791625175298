import React, { useEffect, useRef, useState } from "react";
import { observer } from "mobx-react";
import { Form, Stack, Whisper } from "rsuite";
import { ITask, TaskType } from "stores";

import InfoIcon from "@rsuite/icons/legacy/Info";
import { OverlayTriggerHandle } from "rsuite/esm/Picker";
import { Input, Tooltip } from "shared/ui";

export interface ITaskViewFormControlFirstPartProps {
  answer: number | undefined;
  task: ITask;
  onChange: (
    answer: string,
    taskId: number,
    taskType: TaskType,
    fileName?: string
  ) => void;
}
const TaskViewFormControlFirstPartComponent: React.FC<
  ITaskViewFormControlFirstPartProps
> = (props) => {
  const [value, setValue] = useState<string>("");

  useEffect(() => {
    if (!props.answer) {
      return;
    }
    setValue(props.answer.toString());
  }, [props.answer]);
  const whisperRef = useRef<OverlayTriggerHandle>(null);
  return (
    <Form.Group controlId={`task-${props.task.id}`}>
      <Form.ControlLabel>
        <p
          className="p-answerTitle"
          style={{ marginBottom: 5, color: "#bbbbbb" }}
        >
          твой ответ:
        </p>
      </Form.ControlLabel>
      <Stack spacing={15}>
        <Form.Control
          inputmode="numeric"
          autoComplete="off"
          placeholder="Введите ответ"
          value={value}
          name={`${props.task.id}`}
          accepter={Input}
          style={{ width: 250 }}
          onChange={(value: string) => {
            if (/^\d*$/.test(value)) {
              setValue(value);
              props.onChange(value, props.task.id, props.task.type);
              whisperRef.current?.close();
            } else {
              whisperRef.current?.open();
            }
          }}
        />
        <Whisper
          ref={whisperRef}
          speaker={<Tooltip>Ответ должен быть числом</Tooltip>}
        >
          <InfoIcon />
        </Whisper>
      </Stack>
    </Form.Group>
  );
};
export const TaskViewFormControlFirstPart = observer(
  TaskViewFormControlFirstPartComponent
);
