import { Cell } from "rsuite-table";
import { useStore } from "hooks";
import { ITask } from "stores";
import { observer } from "mobx-react";
import { AiFillHeart, AiOutlineHeart } from "react-icons/ai";
import { Whisper } from "rsuite";
import { useEffect, useState } from "react";
import { IconButton, Tooltip } from "shared/ui";

interface IFavoritesCellProps {
  rowData?: ITask;
  dataKey: string;
}

const FavoritesCellComponent: React.FC<IFavoritesCellProps> = (props) => {
  const { taskStore } = useStore();

  const [favoriteKeys, setFavoriteKeys] = useState<number[]>([]);
  const task = props.rowData;
  useEffect(() => {
    if (taskStore.onLoading) {
      return;
    }
    const favoriteTasksIds = taskStore.tasks
      .filter((task) => task.isFavorite)
      .map((task) => task.id);
    setFavoriteKeys(favoriteTasksIds);
  }, [taskStore.onLoading, taskStore.tasks]);

  const beFavorite = favoriteKeys.some((item: number) => item === task?.id);

  const handleOnClick = () => {
    if (!task) {
      return;
    }
    task.isFavorite = !task.isFavorite;
    const keys = beFavorite
      ? favoriteKeys.filter((item: number) => item !== task.id)
      : [...favoriteKeys, task.id];

    beFavorite
      ? taskStore.removeFavorite(task.id)
      : taskStore.addFavorite(task.id);

    setFavoriteKeys(keys);
  };
  if (!task) {
    return <></>;
  }
  return (
    <Cell
      {...props}
      className="link-group"
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexWrap: "wrap",
      }}
    >
      <Whisper
        placement="top"
        controlId="control-id-hover"
        trigger="hover"
        speaker={
          <Tooltip>
            {beFavorite ? "Убрать из избранного" : "Добавить в избранное"}
          </Tooltip>
        }
      >
        <IconButton
          style={{ display: "flex" }}
          onClick={() => handleOnClick()}
          icon={beFavorite ? <AiFillHeart /> : <AiOutlineHeart />}
          size="sm"
          appearance={beFavorite ? "primary" : "default"}
        />
      </Whisper>
    </Cell>
  );
};
export const FavoritesCell = observer(FavoritesCellComponent);
