import { observer } from "mobx-react";
import { IHomeworkResult } from "stores";
import { useState } from "react";
import { Filter } from "./component/Filter";
import { Homeworks } from "./component/Homeworks";

const HomeworkCheckComponent: React.FC = () => {
  const [homeWorksResult, setHomeWorksResult] = useState<IHomeworkResult[]>([]);

  const handleChangeFilter = (homeWorksResult: IHomeworkResult[]) => {
    setHomeWorksResult(homeWorksResult);
  };

  return (
    <>
      <Filter handleChange={handleChangeFilter} />
      <div style={{ overflowX: "auto" }}>
        <Homeworks homeworks={homeWorksResult} />
      </div>
    </>
  );
};

export const Check = observer(HomeworkCheckComponent);
