export interface IPromocode {
  id: number;
  code: string;
  saleType: SaleType;
  // если фикс, то сумма скидки. если процент, то процент скидки
  saleCost: number;
  dateBegin: Date;
  dateEnd: Date;
  rule: IPromocodeRule;
  isEnabled?: boolean;
}

export interface IPromocodeRule {
  // Если указан, промокод на конкретный курс, иначе на всю корзину
  tariffId?: number;
  // Если указан - промокод персональный
  studentId?: number;
  // только "белая" цена да/нет
  onlyWhiteCost: boolean;
  // Одноразовый промокод для каждого пользователя
  // (или для 1го пользователя, если персональный)
  onePerUser: boolean;
}

export enum SaleType {
  // 1 - фикс
  Amount = 1,
  //2 - %
  Percent = 2,
}

export interface IFormPromocode {
  id?: number;
  code: string;
  saleType: SaleType;
  saleCost: number;
  dateBegin: Date;
  dateEnd: Date;
  tariffId: number | undefined;
  studentId: number | undefined;
  onlyWhiteCost: boolean;
  onePerUser: boolean;
}
