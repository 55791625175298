import { Cell } from "rsuite-table";
import { useStore } from "hooks";
import { IPromocode } from "stores";
import { observer } from "mobx-react";
import { ButtonToolbar, Whisper } from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import { useState } from "react";
import EditIcon from "@rsuite/icons/Edit";
import { ConfirmModal } from "components";
import { EditPromocodeModal } from "../components/EditPromocodeModal";
import { AiFillBulb, AiOutlineBulb } from "react-icons/ai";
import { IconButton, Tooltip } from "shared/ui";

enum TypeAction {
  Delete,
  Change,
}

interface IActionPromocodeCellProps {
  rowData?: IPromocode;
  dataKey: string;
}

const ActionPromocodeCellComponent: React.FC<IActionPromocodeCellProps> = (
  props
) => {
  const { promocodeStore } = useStore();
  const [selectedPromocode, setSelectedPromocode] = useState<
    IPromocode | undefined
  >(undefined);
  const [isOpenEditModal, setOpenEditModal] = useState<boolean>(false);
  const [isOpenConfirmModal, setOpenConfirmModal] = useState(false);
  const [action, setAction] = useState<TypeAction>();

  const getDescription = (): string => {
    if (!props.rowData) {
      return "Промокод не найден";
    }
    let result = "";
    if (action === TypeAction.Change) {
      const verb = props.rowData.isEnabled ? "выключить" : "включить";
      result = `Вы действительно хотите ${verb} промокод "${props.rowData.code}"?`;
    }
    if (action === TypeAction.Delete) {
      result = "Вы действительно хотите удалить промокод?";
    }

    return result;
  };

  const handleChangeEnableForBuy = async () => {
    if (!props.rowData) {
      return;
    }
    const result = await promocodeStore.save({
      ...props.rowData,
      isEnabled: !props.rowData.isEnabled,
    });
    if (result) {
      setOpenConfirmModal(false);
    }
  };

  const handleDeleteTariff = async () => {
    setOpenConfirmModal(false);
    if (selectedPromocode && selectedPromocode.id) {
      promocodeStore.delete(selectedPromocode.id);
    }
  };

  const submitHandler = () => {
    if (!props.rowData) {
      return;
    }
    if (action === TypeAction.Change) {
      handleChangeEnableForBuy();
      return;
    }
    handleDeleteTariff();
  };

  if (!props.rowData) {
    return <></>;
  }
  return (
    <>
      <ConfirmModal
        isOpen={isOpenConfirmModal}
        onSubmit={submitHandler}
        onCancel={() => {
          setOpenConfirmModal(false);
        }}
        isLoading={promocodeStore.onDeleting}
        description={getDescription()}
      />
      <EditPromocodeModal
        promocodeId={selectedPromocode?.id}
        isOpen={isOpenEditModal}
        onClose={() => setOpenEditModal(false)}
      />
      <Cell
        {...props}
        className="link-group"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexWrap: "wrap",
        }}
      >
        <ButtonToolbar>
          <Whisper
            placement="auto"
            speaker={
              <Tooltip>
                {props.rowData.isEnabled ? "Активен" : "Неактивен"}
              </Tooltip>
            }
          >
            <IconButton
              onClick={() => {
                setOpenConfirmModal(true);
                setAction(TypeAction.Change);
              }}
              icon={
                props.rowData.isEnabled ? <AiFillBulb /> : <AiOutlineBulb />
              }
              size="sm"
              color="violet"
              appearance={props.rowData.isEnabled ? "primary" : "default"}
            />
          </Whisper>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Редактировать</Tooltip>}
          >
            <IconButton
              onClick={() => {
                setSelectedPromocode(props.rowData);
                setOpenEditModal(true);
              }}
              icon={<EditIcon />}
              size="sm"
              color="orange"
              appearance="primary"
            />
          </Whisper>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Архивировать</Tooltip>}
          >
            <IconButton
              onClick={() => {
                setAction(TypeAction.Delete);
                setSelectedPromocode(props.rowData);
                setOpenConfirmModal(true);
              }}
              icon={<TrashIcon />}
              size="sm"
              color="red"
              appearance="primary"
            />
          </Whisper>
        </ButtonToolbar>
      </Cell>
    </>
  );
};
export const ActionPromocodeCell = observer(ActionPromocodeCellComponent);
