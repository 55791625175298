import { useStore } from "hooks";
import { observer } from "mobx-react";
import { Table } from "rsuite";
import { IRegToken } from "stores";
const { Cell } = Table;

interface ITariffTitleCellProps {
  rowData?: IRegToken;
  dataKey: string;
}

export const TariffTitleCellComponent: React.FC<ITariffTitleCellProps> = (
  props
) => {
  const { tariffStore } = useStore();

  if (!props.rowData) {
    return <Cell {...props}></Cell>;
  }
  const tariffTitle = tariffStore.find(props.rowData.objectId)?.title;
  return <Cell {...props}>{tariffTitle}</Cell>;
};

export const TariffTitleCell = observer(TariffTitleCellComponent);
