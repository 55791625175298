import { Link } from "react-router-dom";
import { PaymentType, TOrder } from "stores";
import { TariffsInfo } from "./TariffsInfo";
import { Button } from "shared/ui";

export type TResultObject = {
  tariffId: number;
  properties: {
    monthNumber: number;
    paymentType: PaymentType;
  }[];
};

type TStudentTariffTableProps = { rowData?: TOrder };

export const RenderRowExpanded: React.FC<TStudentTariffTableProps> = ({
  rowData,
}) => {
  const paymentUrl = rowData?.paymentUrl;
  return (
    <div>
      {paymentUrl && (
        <span>
          Ссылка для оплаты:
          <Button appearance="link" as={Link} target="_blanc" to={paymentUrl}>
            {paymentUrl}
          </Button>
        </span>
      )}
      <div>
        Заказ:
        <TariffsInfo order={rowData} />
      </div>
    </div>
  );
};
