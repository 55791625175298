import React from "react";
import { observer } from "mobx-react";
import { ITariff } from "stores";
import { useStore } from "hooks";

interface ITitleAndDescriptionTariffProps {
  tariff: ITariff;
}

const TitleAndDescriptionTariffComponent: React.FC<
  ITitleAndDescriptionTariffProps
> = (props) => {
  const { subjectStore } = useStore();
  const subjectTitle =
    subjectStore.find(props.tariff.subjectId)?.title || "Предмет";

  return (
    <>
      <h6>{`${subjectTitle}. ${props.tariff.title}`}</h6>
      <span style={{ overflowWrap: "break-word" }}>
        {props.tariff.description}
      </span>
    </>
  );
};
export const TitleAndDescriptionTariff = observer(
  TitleAndDescriptionTariffComponent
);
