import { FastPayModal } from "components/Modal/FastPayModal";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { toaster } from "rsuite";
import { ToastContainerProps } from "rsuite/esm/toaster/ToastContainer";
import { ILesson } from "stores";
import { PaymentNotificationContent } from "./PaymentNotificationContent";

const options: ToastContainerProps = {
  placement: "bottomEnd",
  duration: 24 * 60 * 60 * 1000,
};

const PaymentNotificationComponent: React.FC = () => {
  const { lessonsStore, tariffStore } = useStore();
  const [lessonFromPay, setLessonFromPay] = useState<ILesson | undefined>();
  const [isOpenPayModal, setIsOpenPayModal] = useState(false);

  const handleClick = (lesson: ILesson) => {
    toaster.clear();
    setLessonFromPay(lesson);
    setIsOpenPayModal(true);
  };
  useEffect(() => {
    if (!lessonsStore.lessonsNotPayment || !tariffStore.data) {
      return;
    }
    lessonsStore.lessonsNotPayment.forEach((lessonNotPayment) => {
      toaster.push(
        <PaymentNotificationContent
          lessonNotPayment={lessonNotPayment}
          onClick={() => handleClick(lessonNotPayment)}
          key={lessonNotPayment.id}
        />,
        options
      );
    });
  }, [lessonsStore.data, tariffStore.data]);

  return (
    <FastPayModal
      lesson={lessonFromPay}
      isOpen={isOpenPayModal}
      onCancel={() => {
        setIsOpenPayModal(false);
      }}
    />
  );
};

export const PaymentNotification = observer(PaymentNotificationComponent);
