import React, { CSSProperties } from "react";

interface IValidationErrorProps {
  title?: string;
  style?: CSSProperties;
}

export const ValidationErrorComponent: React.FC<IValidationErrorProps> = (
  props
) => {
  return (
    <>
      {props.title && (
        <div style={props.style}>
          <span className="rs-form-error-message rs-form-error-message-show">
            <span className="rs-form-error-message-arrow"></span>
            <span className="rs-form-error-message-inner">{props.title}</span>
          </span>
        </div>
      )}
    </>
  );
};
