import * as admin from "../pages/Admin";
import * as superAdmin from "../pages/AdminSuper";
import { GeneralRoutes } from "./GeneralRoutes";
import { IRoute } from "./IRoute";

export const AdminRoutes = () => {
  const routes: IRoute[] = [];
  routes.push({ title: "Главная", path: "*", element: <admin.Home /> });
  routes.push({ title: "Главная", path: "/", element: <admin.Home /> });
  routes.push({
    title: "Глобальные уведомления",
    path: "/notifications",
    element: <admin.TableGlobalNotification />,
  });
  routes.push({
    title: "Курсы",
    path: "/tariffs",
    element: <admin.TariffTable />,
  });
  routes.push({
    title: "Акции",
    path: "/promotions",
    element: <admin.PromotionTable />,
  });
  routes.push({
    title: "Промокоды",
    path: "/promocodes",
    element: <admin.PromocodeTable />,
  });
  routes.push({
    title: "Ссылки для регистрации",
    path: "/regtokens",
    element: <admin.RegTokenTable />,
  });
  routes.push({
    title: "Курсы учеников",
    path: "/student-tariffs",
    element: <admin.StudentTariffTableBase />,
  });
  routes.push({
    title: "История оплат",
    path: "/history-orders",
    element: <superAdmin.HistoryOrders />,
  });
  routes.push(GeneralRoutes.CheckLessons);
  routes.push(GeneralRoutes.Students);
  routes.push(GeneralRoutes.TaskPackEditor);
  routes.push(GeneralRoutes.HomeworkSummary);
  routes.push(GeneralRoutes.Top);
  routes.push(GeneralRoutes.Lessons);
  routes.push(GeneralRoutes.LessonsCard);
  routes.push(GeneralRoutes.LessonsCardWithHw);
  routes.push(GeneralRoutes.Schedule);
  routes.push({
    title: "События",
    path: "/events",
    element: <admin.EventsList />,
  });
  routes.push(GeneralRoutes.AllTask);
  routes.push(GeneralRoutes.TaskPack);
  routes.push(GeneralRoutes.CreateTask);
  routes.push(GeneralRoutes.TaskFavorite);
  routes.push(GeneralRoutes.TaskArchive);
  routes.push({
    title: "Статистика",
    path: "/statistics/1",
    element: <admin.Statistic1 />,
  });

  return routes;
};
