import React, { CSSProperties } from "react";
import { observer } from "mobx-react";
import { ITariff } from "stores";
import { DateHelper } from "root/DateHelper";
import { isBefore } from "rsuite/esm/utils/dateUtils";

const autoCheckBlockStyle: CSSProperties = {
  backgroundColor: "var(--rs-sidenav-default-hover-bg)",
  borderRadius: 8,
  marginRight: 8,
  marginTop: 10,
  padding: 1,
  paddingRight: 8,
  paddingLeft: 8,
};

interface IFactsTariffProps {
  tariff: ITariff;
}

const FactsTariffComponent: React.FC<IFactsTariffProps> = (props) => {
  const hasMonthStarted = isBefore(
    new Date(props.tariff.dateBegin),
    new Date()
  );

  return (
    <>
      <div style={{ display: "grid", gridTemplateColumns: "1fr 1fr" }}>
        <div style={autoCheckBlockStyle}>
          <span>
            {DateHelper.getMonthDeclension(
              props.tariff.durationInBusinessMonths
            )}
          </span>
        </div>
        {props.tariff.countHomeworksPerMonth > 0 && (
          <div style={autoCheckBlockStyle}>
            <span>{`от ${props.tariff.countHomeworksPerMonth} домашних заданий`}</span>
          </div>
        )}
        <div style={autoCheckBlockStyle}>
          <span>
            {hasMonthStarted
              ? `старт в ${DateHelper.FormatDateInPrepositional(
                  props.tariff.dateBegin
                )}`
              : "можно присоединиться"}
          </span>
        </div>
        <div style={autoCheckBlockStyle}>
          <span>{`от ${props.tariff.countLessonsPerMonth} вебинаров`}</span>
        </div>
      </div>
    </>
  );
};
export const FactsTariff = observer(FactsTariffComponent);
