import React from "react";
import { Modal } from "rsuite";
import { StudentTariffPay } from "../StudentTariffPay";
import { IStudentTariffPayment } from "stores";

interface IPayModalProps {
  tariffId?: number;
  studentId?: number;
  payments?: IStudentTariffPayment[];
  isOpen: boolean;
  onClose: () => void;
}

export const PayModal: React.FC<IPayModalProps> = ({
  tariffId,
  studentId,
  payments,
  isOpen,
  onClose,
}) => {
  return (
    <Modal backdrop="static" open={isOpen} onClose={onClose} size="sm">
      <Modal.Header>
        <Modal.Title>Оплата курса</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <StudentTariffPay
          tariffId={tariffId}
          studentId={studentId}
          payments={payments}
          onClose={onClose}
        />
      </Modal.Body>
    </Modal>
  );
};
