import React from "react";
import { Stack } from "rsuite";
import { observer } from "mobx-react";
import { ITask, ITaskPack } from "stores";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { IAnswerHomework } from "root/requests";
import { Button } from "shared/ui";

export interface ITaskSwitchProps {
  answers?: IAnswerHomework[];
  onChange: (taskId: number) => void;
  taskPack?: ITaskPack;
  taskId?: number;
}

const TaskSwitchComponent: React.FC<ITaskSwitchProps> = (
  props: ITaskSwitchProps
) => {
  const colorButton = (task: ITask): TypeAttributes.Color | undefined => {
    const answersByTask = props.answers?.find((x) => x.taskId === task.id);

    if (!answersByTask) {
      return "violet";
    }

    if (
      answersByTask.answerFileList?.length ||
      answersByTask.answerPart2?.length ||
      answersByTask.answerPart1?.toString().length
    ) {
      return "green";
    }

    return "violet";
  };

  const styleButton = (task: ITask): React.CSSProperties => {
    return props.taskId === task.id
      ? {
          backgroundColor: "var(--rs-btn-ghost-text)",
          color: "var(--rs-bg-card)",
        }
      : {};
  };
  return (
    <>
      <div style={{ overflowX: "auto", overflowY: "hidden" }}>
        <Stack
          spacing={8}
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginBottom: 20,
            marginTop: 5,
            marginLeft: 5,
            flexWrap: "wrap",
          }}
        >
          {props.taskPack?.tasks.map((task, i) => {
            return (
              <Button
                className="switch-checked-btn"
                key={task.id}
                appearance="ghost"
                color={colorButton(task)}
                size="md"
                style={styleButton(task)}
                onClick={() => {
                  props.onChange(task.id);
                }}
              >
                {i + 1}
              </Button>
            );
          })}
        </Stack>
      </div>
    </>
  );
};
export const TaskSwitch = observer(TaskSwitchComponent);
