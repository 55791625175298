import { CardWithImage } from "components";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import { PlaceholderLessonList } from "../components";
import { LessonList } from "./LessonList";

const PageLessonListComponent: React.FC = () => {
  const { tariffStore } = useStore();

  if (tariffStore.onLoading) {
    return <PlaceholderLessonList />;
  }

  if (tariffStore.myTariffs && tariffStore.myTariffs.length === 0) {
    return (
      <CardWithImage
        imgSrc={`${process.env.PUBLIC_URL}/images/no-lessons-128.png`}
        title="здесь пока ничего нет"
        description="позже здесь появится список твоих уроков"
        imageStyle={{ width: "auto", height: 125 }}
      />
    );
  }

  return <LessonList />;
};
export const PageLessonList = observer(PageLessonListComponent);
