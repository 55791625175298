import { Cell } from "rsuite-table";
import { IStudentTariffWithPayments } from "stores";
import { DataHelper } from "root/DataHelpers";

interface ILastPaymentCellProps {
  rowData?: IStudentTariffWithPayments;
  dataKey: string;
}

export const LastPaymentCell: React.FC<ILastPaymentCellProps> = (props) => {
  const studentTariff = props.rowData;

  if (!studentTariff) {
    return <Cell {...props}></Cell>;
  }
  const lastPayedMonth =
    studentTariff.payments?.length > 0
      ? DataHelper.max(studentTariff.payments, (x) => x.monthNumber)
      : undefined;
  return (
    <Cell
      {...props}
      className="link-group"
      style={{
        display: "flex",
        alignContent: "center",
        flexWrap: "wrap",
      }}
    >
      {lastPayedMonth || "-"}
    </Cell>
  );
};
