import { FormikConfig } from "formik";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import React from "react";
import { ButtonToolbar } from "rsuite";
import { Button, FInput, Form } from "shared/ui";
import { initialValues } from "./constants";
import { $RecoveryRequest, RecoveryRequest } from "./types";

interface IRecoveryProps {
  title: string;
  onClose: () => void;
}

const RecoveryComponent: React.FC<IRecoveryProps> = ({ title, onClose }) => {
  const { authStore } = useStore();
  const cfg: FormikConfig<RecoveryRequest> = {
    initialValues,
    validationSchema: $RecoveryRequest,
    onSubmit: async ({ login }) => {
      if (await authStore.recovery(login)) {
        onClose();
      }
    },
  };

  return (
    <>
      <h3 style={{ marginBottom: 12 }}>{title}</h3>
      <Form config={cfg} disableOverlay>
        <FInput
          name="login"
          placeholder="Логин или Email"
          label="Электронная почта или логин"
          withAsterisk
        />
        <ButtonToolbar style={{ marginTop: 24 }}>
          <Button appearance="primary" type="submit">
            Восстановить
          </Button>
          <Button appearance="link" onClick={onClose}>
            Назад
          </Button>
        </ButtonToolbar>
      </Form>
    </>
  );
};

export const Recovery = observer(RecoveryComponent);
