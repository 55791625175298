import EyeIcon from "@rsuite/icons/legacy/Eye";
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";
import React, { useState } from "react";
import { Progress, Whisper } from "rsuite";
import { REGEXP_PASSWORD_SPACES } from "shared/constant";
import { Button, Input, Popover } from "shared/ui";
import { PasswordRequirementList } from "./components";
import { PasswordInputProps } from "./types";
import { getStrength } from "./utils";

export const PasswordInput: React.FC<PasswordInputProps> = ({
  value,
  withSpaces,
  withStrengthMeter,
  onChange,
  visibilityToggleIcon,
  ...rest
}) => {
  const [focus, setFocus] = useState(false);
  const [visible, setVisible] = useState(false);

  const strength = getStrength(value);
  const handleChange = () => setVisible((prev) => !prev);

  const handleChangeInput = (value: string) => {
    if (withSpaces) {
      onChange?.(value);
      return;
    }
    onChange?.(value.replace(REGEXP_PASSWORD_SPACES, ""));
  };

  const defaultIcon = visible ? <EyeIcon /> : <EyeSlashIcon />;
  const icon = visibilityToggleIcon
    ? visibilityToggleIcon(visible)
    : defaultIcon;

  const progressColor =
    strength > 80 ? "#12b886" : strength > 50 ? "#fab005" : "#fa5252";
  const progress = value.length > 0 ? strength : 0;

  const inputType = visible ? "text" : "password";

  const handleFocus = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    rest.onFocus?.(e);
    setFocus(true);
  };
  const handleBlur = (e: React.FocusEvent<HTMLInputElement, Element>) => {
    rest.onBlur?.(e);
    setFocus(false);
  };

  return (
    <Whisper
      open={withStrengthMeter && focus}
      trigger="none"
      placement="bottom"
      speaker={
        <Popover arrow={false} style={{ padding: "8px 0 0 0" }}>
          <Progress.Line
            percent={progress}
            strokeColor={progressColor}
            showInfo={false}
          />

          <PasswordRequirementList value={value} />
        </Popover>
      }
    >
      <div className="we-input-wrapper">
        <Input
          {...rest}
          type={inputType}
          value={value}
          onChange={handleChangeInput}
          onFocus={handleFocus}
          onBlur={handleBlur}
          rightSection={
            <Button appearance="subtle" ripple={false} onClick={handleChange}>
              {icon}
            </Button>
          }
        />
      </div>
    </Whisper>
  );
};
