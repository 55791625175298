import {
  IPage,
  IStudent,
  IStudentTariffWithPayments,
  IUserFullData,
} from "../../stores/models";
import { get, post } from "../WebHelper";
import * as requests from "../requests";
import { CrudController } from "./CrudController";
import {
  IRequestPayTariff,
  IStudentTariffsByFilterRequest,
} from "root/requests";

export class StudentController extends CrudController<IStudent> {
  constructor() {
    super("Student");
  }

  public async UpdateProfile(
    data: requests.IRequestUpdateStudentProfile
  ): Promise<IUserFullData | undefined> {
    return await post(`/Student/Update`, data);
  }

  public async GetTariffs(
    params?: IStudentTariffsByFilterRequest,
    refetch?: boolean
  ): Promise<IPage<IStudentTariffWithPayments> | undefined> {
    return await get(`/Student/GetTariffs/`, { params, refetch });
  }

  public async AddTariff(tariffId: number, studentId?: number) {
    await post(`/Student/AddTariff/${tariffId}/${studentId}`);
  }

  public async RemoveTariff(tariffId: number, studentId?: number) {
    await post(`/Student/RemoveTariff/${tariffId}/${studentId}`);
  }

  public async PayTariff(request: IRequestPayTariff): Promise<void> {
    await post(`/Student/PayTariff`, request);
  }

  public async AddFreeTariff(tariffId: number) {
    await post(`/Student/AddFreeTariff/${tariffId}`);
  }

  public async AddTariffByToken(token: string) {
    await post(`/Student/AddTariffByToken/${token}`);
  }
}
