import { observer } from "mobx-react";
import React from "react";
import { useAllNotification } from "../hook/useAllNotification";
import { NotificationPanel } from "./NotificationPanel";

const NotificationListComponent: React.FC = () => {
  const { allNotifications } = useAllNotification();

  return (
    <>
      {allNotifications.map((x, i) => (
        <NotificationPanel
          key={i}
          title={x.title}
          description={x.description}
          date={x.dateCreate}
        />
      ))}
    </>
  );
};
export const NotificationList = observer(NotificationListComponent);
