import React from "react";
import { observer } from "mobx-react";
import { Stack } from "rsuite";

import TrashIcon from "@rsuite/icons/Trash";
import { IHomeWorkTaskPackRequest } from "stores";
import { ValidationResult } from "stores/models/ValidationResult";
import { ValidationErrorComponent } from "components";
import { IconButton, Input } from "shared/ui";

interface IHomeWorkPackEditProps {
  taskPack: IHomeWorkTaskPackRequest;
  validationResult?: ValidationResult;
  onRemoveTaskPack: (id: number) => void;
  onChangeTaskPack: (id: number, btnTitle: string) => void;
}
const HomeWorkPackEditComponent: React.FC<IHomeWorkPackEditProps> = (
  props: IHomeWorkPackEditProps
) => {
  const taskPackError = props.validationResult?.getError(`btnTitle`);

  return (
    <Stack spacing={6} style={{ marginTop: 20, marginBottom: 10 }}>
      {props.taskPack.name} :
      <>
        <Input
          data-testid="homeWorkBtnName"
          placeholder="Название кнопки"
          defaultValue={props.taskPack.btnTitle}
          onChange={(value) => {
            props.onChangeTaskPack(props.taskPack.id, value);
          }}
        />
        <ValidationErrorComponent title={taskPackError} />
      </>
      <IconButton
        icon={<TrashIcon />}
        onClick={() => props.onRemoveTaskPack(props.taskPack.id)}
      />
    </Stack>
  );
};
export const HomeWorkPackEdit = observer(HomeWorkPackEditComponent);
