import _ from "lodash";
import {
  BsCameraVideoFill,
  BsFillBookmarkHeartFill,
  BsFillPencilFill,
} from "react-icons/bs";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { EventType, IEvent } from "stores";

type ScoreThresholds = {
  green: number;
  orange: number;
};

export class StyleHelper {
  public static getColorByScore = (
    score: number | undefined,
    maxScore: number | undefined,
    thresholds?: Partial<ScoreThresholds>
  ): TypeAttributes.Color => {
    if (_.isNil(score) || _.isNil(maxScore)) {
      return "red";
    }
    const defaultThresholds = {
      green: maxScore,
      orange: Math.round(maxScore / 2),
    };

    const mergedThresholds = { ...defaultThresholds, ...thresholds };

    if (score >= mergedThresholds.green) {
      return "green";
    }

    if (score >= mergedThresholds.orange) {
      return "orange";
    }

    return "red";
  };

  public static getColorByEvent = (event: IEvent): string | undefined => {
    switch (event.type) {
      case EventType.Custom:
        return "var(--we-calendar-btn-custom)";
      case EventType.Lesson:
        return "var(--we-calendar-btn-lesson)";
      case EventType.HomeworkDeadlineSubmit:
        return "var(--we-calendar-btn-homework-submit)";
    }
  };
  public static getIconByEvent = (
    event: IEvent
  ): React.JSX.Element | undefined => {
    switch (event.type) {
      case EventType.Custom:
        return <BsFillBookmarkHeartFill />;
      case EventType.Lesson:
        return <BsCameraVideoFill />;
      case EventType.HomeworkDeadlineSubmit:
        return <BsFillPencilFill />;
    }
  };
}
