import { useStore } from "hooks";
import { useNumberParams } from "hooks/useNumberParams";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { RoleNames } from "stores";
import { authStore } from "stores/AuthStore";
import { useAnswersStudent } from ".";

export const useDropAnswersButton = () => {
  const { lessonId, studentId, homeworkId, packId } = useNumberParams();
  const navigate = useNavigate();
  const { homeworkStore } = useStore();

  const [isOpenConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const { answersStudent } = useAnswersStudent(
    homeworkId,
    lessonId,
    packId,
    studentId
  );

  const isRenderButton =
    answersStudent?.length !== 0 &&
    authStore.me?.role === RoleNames.Super &&
    lessonId &&
    studentId &&
    packId &&
    homeworkId;

  const onSubmit = async () => {
    if (!isRenderButton) {
      return;
    }
    (await homeworkStore.dropAnswer(lessonId, studentId, packId, homeworkId)) &&
      navigate("/checks");
  };

  const isLoading = homeworkStore.onSaving;

  return {
    isOpenConfirmModal,
    setOpenConfirmModal,
    isRenderButton,
    onSubmit,
    isLoading,
  };
};
