import { PageNavigator } from "components/Filter";
import { observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
import { FilterTaskPack } from "./FilterTaskPack";
import { TaskPackTable } from "./TaskPackTable";
import { useTaskPackPageNavigation } from "./hooks/useTaskPackPageNavigation";
import { Button } from "shared/ui";

const initialFilter = {
  count: 10,
  page: 1,
};

const TaskPackListComponent: React.FC = () => {
  const {
    data,
    isLoading,
    total,
    filter,
    onChangeFilter,
    setActivePage,
    setLimit,
  } = useTaskPackPageNavigation();

  return (
    <>
      <Button
        style={{ marginBottom: 15 }}
        appearance="primary"
        color="orange"
        as={Link}
        to="/alltask"
      >
        Создать вариант
      </Button>

      <FilterTaskPack onChange={onChangeFilter} value={filter} />

      <TaskPackTable data={data} loading={isLoading} />

      <div style={{ marginTop: 12 }}>
        <PageNavigator
          total={total}
          limit={filter.count || initialFilter.count}
          activePage={filter.page || initialFilter.page}
          onChangeLimit={setLimit}
          onChangeActivePage={setActivePage}
        />
      </div>
    </>
  );
};

export const TaskPackList = observer(TaskPackListComponent);
