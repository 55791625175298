import { useStore } from "hooks";
import { observer } from "mobx-react";
import { useState } from "react";
import { TextHelper } from "root/TextHelpers";
import { ButtonToolbar, Form, SelectPicker } from "rsuite";
import { Button } from "shared/ui";

interface IStudentTariffLinkForm {
  tariffId?: number;
  studentId?: number;
  mentorId?: number;
}

interface IStudentTariffLinkProps extends IStudentTariffLinkForm {
  onClose?: () => void;
}
const StudentTariffLinkComponent: React.FC<IStudentTariffLinkProps> = ({
  mentorId,
  onClose,
  studentId,
  tariffId,
}) => {
  const { tariffStore, studentsStore, studentTariffStore, mentorStore } =
    useStore();

  const [formValue, setFormValue] = useState<IStudentTariffLinkForm>({
    tariffId,
    studentId,
    mentorId,
  });

  const handleSubmite = async () => {
    if (!formValue.tariffId || !formValue.studentId) {
      return;
    }
    const success = formValue.mentorId
      ? await mentorStore.linkStudentTariff(
          formValue.mentorId,
          formValue.tariffId,
          formValue.studentId
        )
      : await studentTariffStore.add(formValue.tariffId, formValue.studentId);

    if (success) {
      onClose && onClose();
    }
  };

  const tariffIdOptions = tariffStore.data.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const studentIdOptions = studentsStore.data.map((item) => ({
    label: TextHelper.GetShortName(item.user, true),
    value: item.id,
  }));

  const mentorIdOptions = mentorStore.mentors.map((item) => ({
    label: TextHelper.GetShortName(item.user, true),
    value: item.id,
  }));

  const isLoading = mentorStore.onLoading || studentTariffStore.onLoading;
  const isDisable = isLoading || !formValue.tariffId || !formValue.studentId;

  return (
    <>
      <Form
        formValue={formValue}
        onChange={setFormValue}
        onSubmit={handleSubmite}
      >
        <Form.Group controlId="tariffId">
          <Form.ControlLabel>Курс</Form.ControlLabel>
          <Form.Control
            name="tariffId"
            accepter={SelectPicker}
            disabled={!!tariffId}
            data={tariffIdOptions}
          />
        </Form.Group>

        <Form.Group controlId="studentId">
          <Form.ControlLabel>Ученик</Form.ControlLabel>
          <Form.Control
            name="studentId"
            accepter={SelectPicker}
            disabled={!!studentId}
            data={studentIdOptions}
          />
        </Form.Group>

        <Form.Group controlId="mentorId">
          <Form.ControlLabel>Ментор</Form.ControlLabel>
          <Form.Control
            name="mentorId"
            accepter={SelectPicker}
            disabled={!!mentorId}
            data={mentorIdOptions}
          />
        </Form.Group>

        <Form.Group>
          <ButtonToolbar>
            <Button
              appearance="primary"
              type="submit"
              loading={isLoading}
              disabled={isDisable}
            >
              Сохранить
            </Button>
            <Button onClick={onClose}>Отмена</Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};

export const StudentTariffLink = observer(StudentTariffLinkComponent);
