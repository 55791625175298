import React from "react";
import { ErrorProps } from "./types";

export const Error: React.FC<ErrorProps> = ({ error, ...rest }) => {
  return (
    <div style={{ position: "relative", marginTop: 4 }}>
      {error ? (
        <span
          {...rest}
          className="wavege-error-text"
          style={{ position: "absolute" }}
        >
          {error}
        </span>
      ) : null}
    </div>
  );
};
