export interface IEvent {
  title: string;
  description: string;
  eventDateTime: Date;
  tariffId: number;
  type: EventType;
}

export enum EventType {
  "Lesson" = 1,
  "Custom" = 2,
  "HomeworkDeadlineSubmit" = 3,
  "HomeworkDeadlineCheck" = 4,
}
