import { Cell } from "rsuite-table";
import { useStore } from "hooks";
import { ITask } from "stores";
import { observer } from "mobx-react";

interface IThemeCellProps {
  rowData?: ITask;
  dataKey: string;
}

const ThemeCellComponent: React.FC<IThemeCellProps> = (props) => {
  const { themaStore } = useStore();
  if (!props.rowData) {
    return <></>;
  }
  const theme = themaStore.find(props.rowData.themeId);
  return (
    <Cell {...props} className="link-group">
      <p>{theme?.title}</p>
    </Cell>
  );
};
export const ThemeCell = observer(ThemeCellComponent);
