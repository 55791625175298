import { RangeType } from "rsuite/esm/DateRangePicker/types";
import {
  endOfDay,
  startOfDay,
  addMonths,
  endOfMonth,
  startOfMonth,
  addDays,
  endOfWeek,
  startOfWeek,
  subDays,
  format,
  differenceInDays,
  differenceInHours,
  differenceInMinutes,
} from "date-fns";
import { ru } from "date-fns/locale";

type ChangeDate = Date | string | undefined;
interface Range extends RangeType {
  appearance?: "default" | "primary" | "link" | "subtle" | "ghost";
}

interface IWords {
  one: string;
  two: string;
  many: string;
}

export class DateHelper {
  /**
   * Возвращает строку с правильным склонением слова "месяц" в зависимости от переданного числа.
   *
   * @param count - количество, для которого нужно определить склонение слова "месяц".
   * @returns Возвращает строку с количество и правильным склонением слова "месяц".
   */
  public static getMonthDeclension(count: number): string {
    if (count < 0) {
      return "не назначено";
    }
    const word: IWords = { one: "месяц", two: "месяца", many: "месяцев" };
    const plur = this.getPluralForm(count, word);
    return `${count} ${plur}`;
  }

  /**
   * Возвращает правильную форму множественного числа слова на основе заданного числового значения.
   * @param value - числовое значение, для которого нужно определить форму множественного числа слова.
   * @param words - объект, содержащий различные формы слова.
   * @returns Правильная форма множественного числа слова.
   */
  public static getPluralForm(value: number, words: IWords): string {
    value = Math.abs(value) % 100;
    const num = value % 10;
    if (value > 10 && value < 20) return words.many;
    if (num > 1 && num < 5) return words.two;
    if (num == 1) return words.one;
    return words.many;
  }

  /**
   *  Возвращает отформатированную строку даты с возможностью указания времени.
   * @param date Дата, которую необходимо отформатировать.
   * @param withTime Указывает, следует ли включить время в форматированную строку. По умолчанию `false`.
   * @returns Отформатированная строка с датой и/или временем в формате "dd MMM" или "dd MMM в HH:mm".
   * Если `date` равно `null`, возвращается строка "не назначено".
   */
  public static getDateToStrLocaleShort(
    date: ChangeDate,
    withTime = false
  ): string {
    if (!date) {
      return "не назначено";
    }

    const dateObj = new Date(date);
    let dateFormat = format(dateObj, "dd MMM", { locale: ru });

    if (withTime) {
      const timeFormat = format(dateObj, "HH:mm", { locale: ru });
      dateFormat += ` в ${timeFormat}`;
    }

    return dateFormat;
  }

  /**
   * @description Отдаёт сегодняшнюю дату с русской локалью,
   * вида **`понедельник, 23 января`**
   * @returns Вовращает строку
   */
  public static getTodayStr(): string {
    const today = format(new Date(), "EEEE, d MMMM", { locale: ru });

    return today;
  }

  /**
   * @description Преобразование даты в строку с русской локалью,
   * вида **`понедельник, 23 января 2022 г.`**
   * @returns Вовращает строку
   */
  public static changeDateToStrLocaleLongWeekday(
    date: ChangeDate,
    withYear = false
  ): string {
    if (!date) {
      return "не назначен";
    }

    const dateFormat = withYear ? "EEEE, d MMMM yyyy" : "EEEE, d MMMM";
    const dateString = format(new Date(date), dateFormat, { locale: ru });

    return dateString;
  }

  /**
   * @description Преобразование даты в строку с русской локалью,
   * вида **`10:00`**
   * @returns Вовращает строку
   */
  public static changeDateToStrLocaleOnlyTime(date: ChangeDate): string {
    if (!date) {
      return "не назначен";
    }

    const dateObj = new Date(date);
    const timeFormat = format(dateObj, "HH:mm", { locale: ru });
    return timeFormat;
  }

  /**
   * @description Преобразование даты в строку с русской локалью,
   * вида **`23 января 00:13`**
   *@param date Дата для преобразования.
   * @returns Вовращает строку
   */
  public static changeDateToStrLocaleLongWithTime(date: Date | string): string {
    const dateObj = new Date(date);
    if (isNaN(dateObj.getTime())) {
      return "нет даты";
    }
    const dateFormat = format(dateObj, "d MMMM HH:mm", { locale: ru });
    return dateFormat;
  }

  /**
   * @description Преобразование даты в строку с русской локалью,
   * вида **`2023-1-23`** или **`2023-1-23 0:23:8`**
   *@param d Дата для преобразования.
   *@param withTime Флаг со времнем, дефолт **`false`**.
   * @returns Вовращает строку
   */
  public static DateToStringWithTime(d?: Date, withTime = false) {
    if (!d) {
      return "";
    }

    const dateFormat = withTime ? "yyyy-MM-dd HH:mm:ss" : "yyyy-MM-dd";
    const dateString = format(d, dateFormat);

    return dateString;
  }

  /**
   * @description Преобразование даты в строку с русской локалью,
   * вида **`16.06.2023, 12:01`**
   *@param d Дата для преобразования.
   * @returns Вовращает строку
   */
  public static DateToStringAndTime(d: string | Date | undefined): string {
    if (!d) {
      return "нет даты";
    }
    const dateObj = new Date(d);
    if (isNaN(dateObj.getTime())) {
      return "нет даты";
    }

    const formattedDate = format(dateObj, "dd.MM.yyyy, HH:mm");
    return formattedDate;
  }

  /**
   * @description Преобразование даты в строку с русской локалью,
   * название месяца в предложном падеже, вида **`январе`**
   *@param d Дата для преобразования.
   * @returns Вовращает строку
   */
  public static FormatDateInPrepositional(d: Date) {
    if (!d) {
      return "";
    }
    const monthsInGenitive = [
      "январе",
      "феврале",
      "марте",
      "апреле",
      "мае",
      "июне",
      "июле",
      "августе",
      "сентябре",
      "октябре",
      "ноябре",
      "декабре",
    ];

    const monthInGenitive = monthsInGenitive[new Date(d).getMonth()];

    return monthInGenitive;
  }
  /**
   * @description Преобразование даты в строку с русской локалью,
   * рассчитывает дни и часы до даты **`6 дн. и 2 час.`**
   * только часы, если осталось меньше дня **`2 час.`**
   * только минуты, если осталось меньше часа **`2 мин.`**
   * **`undefined`**, если дата прошла или прямо сейчас
   *@param targetDate Дата для рассчёта.
   * @returns Вовращает строку
   */
  public static GetTimeUntilDate(targetDate: Date): string | undefined {
    const now = new Date();
    const dateObj = new Date(targetDate);

    if (dateObj.getTime() <= now.getTime()) {
      return;
    }

    const minutesLeft = differenceInMinutes(dateObj, now, {
      roundingMethod: "ceil",
    });
    if (minutesLeft < 60) {
      return `${minutesLeft} мин.`;
    }
    const hoursLeft = differenceInHours(dateObj, now) % 24;
    const daysLeft = differenceInDays(dateObj, now);

    return daysLeft <= 0
      ? `${hoursLeft} час.`
      : `${daysLeft} дн. и ${hoursLeft} час.`;
  }

  public static GetPredefinedRanges(d: Date): Range[] {
    return [
      {
        label: "Сегодня",
        value: [startOfDay(d), endOfDay(d)],
        placement: "left",
      },
      {
        label: "Вчера",
        value: [addDays(startOfDay(d), -1), addDays(endOfDay(d), -1)],
        placement: "left",
      },
      {
        label: "Последние 7 дней",
        value: [subDays(startOfDay(d), 6), endOfDay(d)],
        placement: "left",
      },
      {
        label: "Последний месяц",
        value: [subDays(startOfDay(d), 29), endOfDay(d)],
        placement: "left",
      },
      {
        label: "Этот месяц",
        value: [startOfMonth(startOfDay(d)), endOfDay(d)],
        placement: "left",
      },
      {
        label: "Прошлый месяц",
        value: [
          startOfMonth(addMonths(startOfDay(d), -1)),
          endOfMonth(addMonths(startOfDay(d), -1)),
        ],
        placement: "left",
      },
      {
        label: "Этот год",
        value: [new Date(startOfDay(d).getFullYear(), 0, 1), endOfDay(d)],
        placement: "left",
      },
      {
        label: "Прошлый год",
        value: [
          new Date(startOfDay(d).getFullYear() - 1, 0, 1),
          new Date(startOfDay(d).getFullYear(), 0, 0),
        ],
        placement: "left",
      },
      {
        label: "Все время",
        value: [new Date(startOfDay(d).getFullYear() - 5, 0, 1), endOfDay(d)],
        placement: "left",
      },
      {
        label: "<",
        closeOverlay: false,
        value: (value) => {
          const [start = startOfDay(d)] = value || [];
          return [
            addDays(startOfWeek(start, { weekStartsOn: 0 }), -7),
            addDays(endOfWeek(start, { weekStartsOn: 0 }), -7),
          ];
        },
        appearance: "default",
      },
      {
        label: "эта неделя",
        closeOverlay: false,
        value: [startOfWeek(startOfDay(d)), endOfWeek(endOfDay(d))],
        appearance: "default",
      },
      {
        label: ">",
        closeOverlay: false,
        value: (value) => {
          const [start = startOfDay(d)] = value || [];
          return [
            addDays(startOfWeek(start, { weekStartsOn: 0 }), 7),
            addDays(endOfWeek(start, { weekStartsOn: 0 }), 7),
          ];
        },
        appearance: "default",
      },
    ];
  }
}
