import { DateRange } from "rsuite/esm/DateRangePicker";
import { IDateRange } from "stores";

export class DataRangeAdapter {
  private date1: Date;
  private date2: Date;

  constructor(range: DateRange);
  constructor(range: IDateRange);
  constructor(range: DateRange | IDateRange) {
    if (Array.isArray(range)) {
      this.date1 = range[0];
      this.date2 = range[1];
    } else {
      this.date1 = range.begin;
      this.date2 = range.end;
    }
  }

  toObject(): IDateRange | undefined {
    return { begin: this.date1, end: this.date2 };
  }

  toArray(): DateRange | undefined {
    return [this.date1, this.date2];
  }
}
