import ArrowDownLineIcon from "@rsuite/icons/ArrowDownLine";
import { observer } from "mobx-react";
import React, { CSSProperties, useEffect, useState } from "react";
import { Whisper } from "rsuite";
import { PopoverTariffChange } from "./PopoverTariffChange";
import { usePopoverTariffChange } from "containers/hooks";
import { useStore } from "hooks";

const iconStyle: CSSProperties = {
  marginRight: 10,
  fontSize: "1.5em",
  color: "#7978F8",
};

const ButtonTariffChangeComponent: React.FC = () => {
  const {
    handleSelectTariff,
    myTariffs,
    selectedTariffId,
    hasTariffs,
    trigger,
  } = usePopoverTariffChange();
  const [tariffTitle, setTariffTitle] = useState<string>();
  const { tariffStore } = useStore();


  useEffect(() => {
    const tariffs = tariffStore.getMyTariffs();
    const selectedTariffId = tariffStore.selectedTariff?.id;
    const title = tariffs?.find((x) => x.id === selectedTariffId)?.title;
    setTariffTitle(title);
  }, [tariffStore.selectedTariff, tariffStore.myTariffs]);

  if (!hasTariffs) {
    return null;
  }

  return (
    <Whisper
      placement="bottom"
      trigger="click"
      ref={trigger}
      speaker={(props, ref) => (
        <PopoverTariffChange
          {...props}
          ref={ref}
          myTariffs={myTariffs}
          selectedTariffId={selectedTariffId}
          onSelect={handleSelectTariff}
        />
      )}
    >
      <div className="we-change-course">
        <span className="we-change-course-name">{tariffTitle}</span>
        <ArrowDownLineIcon style={iconStyle} />
      </div>
    </Whisper>
  );
};
export const ButtonTariffChange = observer(ButtonTariffChangeComponent);
