import { GlobalConstants } from "root/GlobalConstants";
import { IMenuItem } from "./IMenuItem";

export const AnonymousMenu = () => {
  const menuItems: IMenuItem[] = [];
  menuItems.push({
    title: "Все курсы",
    path: "/catalog",
  });
  menuItems.push({
    title: "Попробовать бесплатно",
    path: `/catalog/${GlobalConstants.onlyFreeTariff}`,
  });
  return menuItems;
};
