import { ContentState, EditorState, convertToRaw } from "draft-js";
import draftToHtml from "draftjs-to-html";
import htmlToDraft from "html-to-draftjs";
import React, { useEffect, useState } from "react";
import { Editor } from "react-draft-wysiwyg";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import { CustomToolbarButtons } from "./CustomToolbarButtons";
import { RawHTML } from "./RawHTML";

const editorOptions = [
  "inline",
  "list",
  "textAlign",
  "link",
  "remove",
  "history",
];
const toolbar = {
  options: editorOptions,
  link: {
    options: ["unlink"],
  },
};

export interface IRichTextArea {
  isPreview: boolean;
  defaultValue?: string;
  onChange?: (value: string) => void;
  value?: string;
}

export const RichTextArea = React.forwardRef<Editor, IRichTextArea>(
  function RichTextArea(props, ref) {
    const [editorState, onEditorStateChange] = useState<EditorState>();
    const [value, setValue] = useState<string | undefined>(undefined);
    const [resetStyleOnPaste, setResetStyleOnPaste] = useState<boolean>(true);

    useEffect(() => {
      parseHtml(props.defaultValue);
    }, [props.defaultValue]);

    useEffect(() => {
      parseHtml(props.value);
    }, [props.value]);

    useEffect(() => {
      if (props.onChange && editorState) {
        const value = draftToHtml(
          convertToRaw(editorState.getCurrentContent())
        );
        setValue(value);
        props.onChange(value);
      }
    }, [editorState]);

    const parseHtml = (html: string | undefined) => {
      if (html === value) {
        return;
      }
      if (!html) {
        html = " ";
      }
      const contentBlock = htmlToDraft(html);
      if (contentBlock) {
        const contentState = ContentState.createFromBlockArray(
          contentBlock.contentBlocks,
          contentBlock.entityMap
        );
        const editorState = EditorState.createWithContent(contentState);
        onEditorStateChange(editorState);
      }
    };

    if (props.isPreview) {
      return <RawHTML html={props.defaultValue} />;
    }

    return (
      <Editor
        ref={ref}
        toolbar={toolbar}
        toolbarCustomButtons={[
          <CustomToolbarButtons
            key={1}
            resetStyleOnPaste={resetStyleOnPaste}
            setResetStyleOnPaste={setResetStyleOnPaste}
            editorState={editorState}
            onEditorStateChange={onEditorStateChange}
          />,
        ]}
        stripPastedStyles={resetStyleOnPaste}
        editorState={editorState}
        onEditorStateChange={onEditorStateChange}
      />
    );
  }
);
