import React, { ReactNode } from "react";
import { observer } from "mobx-react";
import { Avatar, Button } from "shared/ui";

interface IInfoItem {
  title: string;
  description: string;
  href: string;
  icon: ReactNode;
}

const InfoItemComponent: React.FC<IInfoItem> = (props) => {
  return (
    <div style={{ display: "flex" }}>
      <Avatar style={{ width: 40, backgroundColor: "rgb(173, 57, 152)" }}>
        {props.icon}
      </Avatar>
      <div style={{ marginLeft: 12 }}>
        <p
          style={{
            fontSize: 15,
            marginBottom: "-3px",
            whiteSpace: "nowrap",
          }}
        >
          {props.title}
        </p>
        <p
          style={{
            color: "var(--rs-text-tertiary)",
            maxWidth: 220,
            marginTop: 0,
          }}
        >
          {props.description}
        </p>

        <Button
          target="_blank"
          size="xs"
          href={props.href}
          style={{ borderRadius: "var(--we-border-radius)" }}
        >
          перейти
        </Button>
      </div>
    </div>
  );
};
export const InfoItem = observer(InfoItemComponent);
