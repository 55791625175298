export const PromotionCreationRules: React.FC = () => {
  return (
    <>
      <p>
        <strong>Название акции:</strong>
        <br />
        Указывается в женском роде. Коротко и информативно. При использовании
        акции пользователь будет видеть надпись:{" "}
        <code
          style={{
            backgroundColor: "var(--rs-body)",
            fontFamily: '"Courier New", monospace',
          }}
        >
          {`{название_акции} применена. Скидка составила -{сумма_или_ % _скидки}`}
        </code>
      </p>
      <p>
        <strong>
          Описание акции:&nbsp;
          <br />
        </strong>
        Описание используется для администаторов. В описании можете оставлять
        пометки для себя.
        <br />
        <br />
        <strong>Как действует акция:</strong>
        <br />
        Акция применяется в корзине автоматически.
        <br />
        Для применения акции все условия должны быть соблюдены.
      </p>
      <br />
      <p>
        <strong>Примеры:</strong>
      </p>
      <p>
        <strong>1)</strong> Скидка 10% на покупку 2х{" "}
        <span style={{ textDecoration: "underline" }}>любых курсов</span>:
        <br />
        <span>
          <span style={{ textDecoration: "underline" }}>Тип акции:</span>{" "}
          Процент
          <br />
          <span style={{ textDecoration: "underline" }}>% скидки:</span> 10
          <br />
        </span>
        <span style={{ textDecoration: "underline" }}>Условия:</span>&nbsp;
        <span>
          Необходимо установить 2 условия, и в каждом выбрать "Любой курс"
          <br />
          <strong>Итог:</strong> При покупке 2х любых курсов, на любое
          количество месяцев пользователь получит скидку -10% на каждый месяц
          указанных курсов.
        </span>
      </p>
      <br />
      <p>
        <strong>2)</strong> Cкидка полцены за покупку 3х месяцев курса 'Годовой
        курс ЕГЭ 2021':
        <br />
        <span>
          <span style={{ textDecoration: "underline" }}>Тип акции:</span>{" "}
          Процент
          <br />
          <span style={{ textDecoration: "underline" }}>% скидки:</span> 50
        </span>
        <br />
        <span style={{ textDecoration: "underline" }}>Условия:</span>&nbsp;
        <span>
          Необходимо установить 1 условие, выбрать "Годовой курс ЕГЭ
          2021".&nbsp;
        </span>
        <span>
          Количество месяцев установить 3.
          <br />
          <strong>Итог:</strong> При покупке "Годовой курс ЕГЭ 2021", от 3х
          месяцев пользователь получит скидку -50% на каждый месяц курса.
          <br />
          <br />
        </span>
      </p>
      <br />
      <p>
        <strong>3)</strong> Скидка 1000р за покупку 3х определенных курсов по 2
        месяца:
        <br />
        <span>
          <span style={{ textDecoration: "underline" }}>Тип акции:</span>{" "}
          Фиксированная сумма
          <br />
          <span style={{ textDecoration: "underline" }}>% скидки:</span> 1000
        </span>
        <br />
        <span style={{ textDecoration: "underline" }}>Условия:</span>&nbsp;
        <span>
          Необходимо установить 3 условия, в каждом выбрать участвующие в акции
          курсы.&nbsp;
        </span>
        <span>
          Количество месяцев в каждом установить 2.
          <br />
          <strong>Итог:</strong> При покупке сразу 3х курсов, участвующих в
          акции, от 2х месяцев пользователь получит скидку -1000руб. на весь чек
          <br />
        </span>
      </p>
    </>
  );
};
