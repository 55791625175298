import { RawHTML } from "components";
import { observer } from "mobx-react";
import React from "react";

interface ITaskKeysProps {
  keys: string | undefined;
}

const TaskKeysComponent: React.FC<ITaskKeysProps> = ({ keys }) => {
  if (!keys?.length) {
    return null;
  }

  return (
    <>
      <p style={{ marginBottom: 5, marginTop: 15, color: "#bbbbbb" }}>
        ключи к заданию:
      </p>
      <RawHTML html={keys} />
    </>
  );
};

export const TaskKeys = observer(TaskKeysComponent);
