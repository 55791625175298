import React, { CSSProperties, createRef } from "react";
import { Cropper, ReactCropperElement } from "react-cropper";
import { ButtonToolbar } from "rsuite";
import "cropperjs/dist/cropper.css";
import { observer } from "mobx-react";
import { Button } from "shared/ui";

interface IImageCropperProps {
  imageSrc: string;
  onCrop: (croppedCanvas: HTMLCanvasElement) => void;
  onCancel: () => void;
  disabledButton?: boolean;
  loadingButton?: boolean;
  styleButton?: CSSProperties;
  styleCropper?: CSSProperties;
  style?: CSSProperties;
}

const ImageCropperComponent: React.FC<IImageCropperProps> = (props) => {
  const cropperRef = createRef<ReactCropperElement>();

  const Crop = () => {
    const croppedCanvas = getCroppedCanvas();
    if (!croppedCanvas) {
      return;
    }
    props.onCrop(croppedCanvas);
  };

  const getCroppedCanvas = (): HTMLCanvasElement | undefined => {
    if (typeof cropperRef.current?.cropper === "undefined") {
      return;
    }
    const croppedCanvas: HTMLCanvasElement =
      cropperRef?.current?.cropper.getCroppedCanvas();
    return croppedCanvas;
  };

  return (
    <div style={props.style}>
      <Cropper
        ref={cropperRef}
        style={props.styleCropper}
        aspectRatio={1}
        preview=".img-preview"
        src={props.imageSrc}
        viewMode={2}
        minCropBoxHeight={10}
        minCropBoxWidth={10}
        background={false}
        responsive={true}
        autoCropArea={0.5}
        checkOrientation={false}
        guides={false}
        dragMode="move"
        movable={false}
        zoomable={false}
        toggleDragModeOnDblclick={false}
      />

      <ButtonToolbar style={props.styleButton}>
        <Button
          onClick={Crop}
          appearance="primary"
          color="violet"
          size="sm"
          disabled={props.disabledButton}
          loading={props.loadingButton}
        >
          Сохранить изменения
        </Button>
        <Button onClick={props.onCancel} size="sm">
          Вернуться назад
        </Button>
      </ButtonToolbar>
    </div>
  );
};
export const ImageCropper = observer(ImageCropperComponent);
