import React, { useEffect, useState } from "react";
import { ButtonGroup, ButtonToolbar } from "rsuite";
import { useStore } from "../../hooks";
import { observer } from "mobx-react";
import { LogIn } from "./Login/LogIn";
import { SignIn } from "./SignIn";
import { Recovery } from "./Recovery";
import { Button, Loader } from "shared/ui";

interface ILoginCardProps {
  token?: string;
  onLogin?: () => void;
}

const LoginCardComponent: React.FC<ILoginCardProps> = ({ token, onLogin }) => {
  const { authStore } = useStore();
  const [showRecovery, setShowRecovery] = useState(false);
  const [showRegistration, setShowRegistration] = useState(false);

  useEffect(() => {
    if (!token) {
      return;
    }
    setShowRegistration(true);
  }, [token]);

  const loginNavigation = () => {
    return (
      <div
        style={{
          marginBottom: 18,
          display: "flex",
          justifyContent: "center",
        }}
      >
        <ButtonToolbar>
          <ButtonGroup>
            <Button
              active={!showRegistration}
              onClick={() => setShowRegistration(false)}
            >
              Вход
            </Button>
            <Button
              active={showRegistration}
              onClick={() => setShowRegistration(true)}
            >
              Регистрация
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </div>
    );
  };

  const renderLoginForm = () => {
    return (
      <LogIn
        title="Войти в wavege"
        onClickRecovery={() => setShowRecovery(true)}
        onLogin={onLogin}
      >
        {loginNavigation()}
      </LogIn>
    );
  };

  const renderRegistrationForm = () => {
    return (
      <SignIn
        title="Регистрация в wavege"
        onClose={() => setShowRegistration(false)}
        registrationToken={token}
      >
        {loginNavigation()}
      </SignIn>
    );
  };

  const renderRecoveryForm = () => {
    return (
      <Recovery
        title="Восстановить доступ"
        onClose={() => setShowRecovery(false)}
      />
    );
  };

  const renderLoader = () => {
    return (
      authStore.onLoading && (
        <Loader
          backdrop
          content={showRegistration ? "Регистрация..." : "Входим..."}
          vertical
        />
      )
    );
  };

  let formComponent;
  switch (true) {
    case showRegistration:
      formComponent = renderRegistrationForm();
      break;
    case showRecovery:
      formComponent = renderRecoveryForm();
      break;
    default:
      formComponent = renderLoginForm();
      break;
  }

  return (
    <>
      {formComponent}
      {renderLoader()}
    </>
  );
};
export const LoginCard = observer(LoginCardComponent);
