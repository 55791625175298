import {
  EOrderPaymentStatus,
  HomeworkStudentCheckStatus,
  HomeworkStudentDeadlineStatus,
  HomeworkStudentStatus,
  IDateRange,
  IFilter,
  ITariff,
  LessonType,
  PaymentType,
  RegTokenType,
  RoleNames,
} from "../../stores/models";

export interface IRequestGetTopStudents {
  tariffId?: number;
  teacherId?: number;
  date?: Date;
}

export interface IRequestSaveAnswersHomework {
  isDraft: boolean;
  homeworkId: number;
  packId: number;
  answers: IRequestAnswerHomework[];
}

export interface IRequestAnswerHomework {
  taskId: number;
  lessonId?: number;
  homeWorkId?: number;
  taskPackId?: number;
  answerPart1?: number | null;
  answerPart2?: string;
  answerFileIdList?: number[];
}

export interface IRequestAnswerHomework {
  taskId: number;
  answerPart?: number;
}

export interface IAnswerHomework {
  taskId: number;
  lessonId: number;
  homeWorkId: number;
  taskPackId: number;
  answerPart1?: number;
  answerPart2?: string;
  answerFileList?: IAnswerFileList[];
}
export interface IAnswerFileList {
  id: number;
  fileName: string;
}

export interface IRequestSaveCheckHomework {
  teacherId?: number;
  mentorId?: number;
  //   id домашки конкретного ученика
  tasksPackHwStudentId?: number;
  //   Результаты проверки ответов ученика
  answers: IRequestTeacherAnswer[];
  //   Уважительная причина просрочки дз
  delayReason?: string;
}

export interface IRequestTeacherAnswer {
  taskId: number;
  score?: number;
  comment?: string;
}

export interface IRequestGetTasksByFilter extends IFilter {
  subjectId?: number;
  themeCategoryId?: number;
  themeId?: number;
  type?: number;
  isArchive?: boolean; //если true = архивные, false = не архивные, undefinde = все
  isFavorite?: boolean; //если true = избранные, false = не избранные, undefinde = все
  // Номер КИМ
  kim?: number;
  // Источник
  searchPattern?: string;
  // Сортировка
  orderBy?: TaskOrder;
}

export enum TaskOrder {
  // От легких к сложным
  DifficultyAsc = 1,
  // От сложных к легким
  DifficultyDesc = 2,
  // От старых к новым
  DateCreateAsc = 3,
  // От новых к старым
  DateCreateDesc = 4,
}

export interface IStudentTariffsByFilterRequest extends IFilter {
  studentId?: number;
  tariffId?: number;
  mentorId?: number;
  // Сортировка
  orderBy?: StudentTariffOrder;
}

export enum StudentTariffOrder {
  // Сначала удаленные
  DeletedAsc = 1,
  // Сначала не удаленные
  DeletedDesc = 2,
  // По алфавиту
  StudentNameAsc = 3,
  // По алфавиту в обратном порядке
  StudentNameDesc = 4,
}

export interface IRequestGetLessonsByFilter extends IFilter {
  titlePattern?: string;
  tariffId?: number;
  lessonType?: LessonType;
  lessonDateTime?: IDateRange;
  orderBy: LessonsOrder;
  isPassed?: boolean;
}

export enum LessonPassedType {
  // По дате проведения урока. Сначала старые
  isPassed = 1,
  // Сортировка по названию. От А до Я.
  isComing = 2,
}

export enum LessonsOrder {
  // По дате проведения урока. Сначала новые
  DateTimeDesc = 1,
  // По дате проведения урока. Сначала старые
  DateTimeAsc = 2,
  // По дате создания урока. Сначала новые
  CreateDateDesc = 3,
  // По дате создания урока. Сначала старые
  CreateDateAsc = 4,
  // Сортировка по названию. От А до Я.
  TitleAsc = 5,
  // Сортировка по названию. От Я до А.
  TitleDesc = 6,
}

export interface IRequestGetTasksPacksByFilter extends IFilter {
  name?: string;
  isOnlyMine?: boolean;
}

export interface IRequestGetHomeworksCheckByFilter extends IFilter {
  teacherId?: number | null;
  mentorId?: number | null;
  tariffId?: number | null; // Курс
  lessonId?: number | null; // Урок
  studentId?: number | null; // Ученик
  homeworkStatus?: HomeworkStudentStatus | null; // Статус домашки
  checkStatus?: HomeworkStudentCheckStatus | null; // Статус проверки домашки
  deadlineStatus?: HomeworkStudentDeadlineStatus | null; // Статус дедлайна
  orderBy: HomeworksCheckOrder;
}

export enum HomeworksCheckOrder {
  // По возрастанию даты дедлайна проверки дз (сначала старые)
  CheckDeadlineAsc = 1,
  // По уменьшению даты дедлайна проверки дз (сначала новые)
  CheckDeadlineDesc = 2,
  // По возрастанию даты дедлайна сдачи дз (сначала старые)
  SubmitDeadlineAsc = 3,
  // По уменьшению даты дедлайна сдачи дз (сначала новые)
  SubmitDeadlineDesc = 4,
}

export interface IRequestGetStudentsTop {
  tariffId: number; // Курс
  onlyClan: boolean; // Клан/ по всему курсу
  monthNumber?: number;
}

export interface IRequestChangeRole {
  userId: number;
  role: RoleNames | null;
}

export interface IRequestUpdateUserProfile {
  password?: string;
  passwordConfirm?: string;
  fileImageId?: number;
  firstName?: string;
  middleName?: string;
  lastName?: string;
  email?: string;
  phone?: string;
}

export interface IRequestUpdateStudentProfile
  extends IRequestUpdateUserProfile {
  goal?: number;
  firstTestResult?: number;
}

export interface IImgStudentRequest {
  fileImageId: number;
}

//lint ругается, что пустой интерфес такой же, как и родитель
// включить, когда у ментора появятся специфичные свойства
// export interface IRequestUpdateMentorProfile
//   extends IRequestUpdateUserProfile {}

export interface IRequestUpdateTeacherProfile
  extends IRequestUpdateUserProfile {
  subjectId: number;
}

export interface IRequestPayTariff {
  studentId?: number;
  tariffId: number;
  type: PaymentType;
  amount: number;
  monthNumber?: number;
  comment: string;
}

export interface IRequestTariff extends ITariff {
  //Картинка курса
  fileImageId: number;
}

export interface IRequestRegToken {
  //ID курса
  objectId: number;
  //Тип доступа
  type: RegTokenType;
  token?: string;
}

export interface IRequestGetPaymentsByFilter extends IFilter {
  studentId?: number;
  tariffId?: number;
  promocode?: string;
  status?: EOrderPaymentStatus;
}
