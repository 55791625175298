import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { useIsMounted, useStore } from "../hooks";
import { StatusHomeworkBtn } from ".";
import { TextHelper } from "root";
import {
  ILesson,
  IHomeWorkTaskPack,
  IHomeWork,
  RoleNames,
  IStudentHomework,
} from "stores";
import { authStore } from "stores/AuthStore";
import { Button } from "shared/ui";

export interface IHomeworkTaskPackButtonProps {
  pack: IHomeWorkTaskPack;
  homeWork: IHomeWork;
  currentLesson: ILesson;
  lessonFromParent: ILesson;
  homeworkFromParent?: number;
  taskpackFromParent?: number;
  onClick?: () => void;
}
const HomeworkTaskPackButtonComponent: React.FC<
  IHomeworkTaskPackButtonProps
> = ({
  pack,
  homeWork,
  currentLesson,
  lessonFromParent,
  homeworkFromParent,
  taskpackFromParent,
  onClick,
}) => {
  const isMounted = useIsMounted();

  const { homeworkStore } = useStore();
  const [answersByLesson, setAnswersByLesson] = useState<IStudentHomework[]>();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (authStore.me?.role == RoleNames.Student) {
      loadAnswerByLesson(currentLesson.id);
    }
  }, [currentLesson]);

  const loadAnswerByLesson = async (lessonId: number) => {
    setIsLoading(true);
    const answers = await homeworkStore.getAnswers(lessonId);
    if (!isMounted()) {
      return;
    }
    setAnswersByLesson(answers);
    setIsLoading(false);
  };

  const getScore = (pack: IHomeWorkTaskPack, homeWork: IHomeWork): number => {
    const score = answersByLesson
      ?.find((x) => x.packId === pack.id && x.homeworkId === homeWork.id)
      ?.answers?.reduce((total, answer) => {
        return total + answer.score;
      }, 0);
    return score || 0;
  };
  const getMaxScore = (pack: IHomeWorkTaskPack): number => {
    const maxScore = pack.tasks.reduce((total, task) => {
      return total + (task.maxScore || 0);
    }, 0);
    return maxScore || 0;
  };

  return (
    <Button
      size="sm"
      active={
        lessonFromParent.id === currentLesson.id &&
        homeworkFromParent === homeWork.id &&
        taskpackFromParent === pack.id
      }
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
      }}
      block
      as={Link}
      to={"/lessons/" + currentLesson.id + "/" + homeWork.id + "/" + pack.id}
      onClick={() => {
        onClick?.call(undefined);
      }}
    >
      {TextHelper.ShrinkText(pack.btnTitle, 14)}

      <StatusHomeworkBtn
        loading={isLoading}
        lessonId={currentLesson.id}
        score={getScore(pack, homeWork)}
        maxScore={getMaxScore(pack)}
        isHomeWorkChecked={pack.isHomeWorkChecked}
        isHomeWorkSended={pack.isHomeWorkSended}
      />
    </Button>
  );
};
export const HomeworkTaskPackButton = observer(HomeworkTaskPackButtonComponent);
