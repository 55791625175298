import { useStore } from "hooks";
import { observer } from "mobx-react";
import { CSSProperties } from "react";
import { TextHelper } from "root/TextHelpers";
import { FlexboxGrid } from "rsuite";
import { IHomeworkCheck } from "stores";
import { styleCenter } from "../styles";
import { TitlePlaceholder } from "./TitlePlaceholder";

const titleStyle: CSSProperties = {
  paddingBottom: 5,
  fontWeight: 500,
};

interface IProps {
  studentId: IHomeworkCheck["studentId"];
}

const StudentCellComponent: React.FC<IProps> = ({ studentId }) => {
  const { studentsStore } = useStore();

  const studentName = TextHelper.GetShortName(
    studentsStore.data.find((s) => s.id === studentId)?.user
  );

  return (
    <FlexboxGrid.Item
      colspan={5}
      style={{
        ...styleCenter,
        flexDirection: "column",
        alignItems: "flex-start",
        overflow: "hidden",
      }}
    >
      <div style={titleStyle}>{studentName ?? <TitlePlaceholder />}</div>
    </FlexboxGrid.Item>
  );
};
export const StudentCell = observer(StudentCellComponent);
