import { TypeAttributes } from "rsuite/esm/@types/common";
import {
  EOrderPaymentStatus,
  EventType,
  ExamType,
  GlobalNotificationType,
  HomeworkStudentCheckStatus,
  HomeworkStudentDeadlineStatus,
  HomeworkStudentStatus,
  LessonType,
  PaymentType,
  RegTokenType,
  Role,
  RoleNames,
  SaleType,
  TariffType,
  TaskType,
} from "stores";
import MediaIcon from "@rsuite/icons/Media";
import CloseIcon from "@rsuite/icons/Close";
import DocPassIcon from "@rsuite/icons/DocPass";
import { StringKeyOf } from "ts-enum-util/dist/types/types";
import { $enum } from "ts-enum-util";
import {
  LessonPassedType,
  LessonsOrder,
  LogLevel,
  StudentTariffOrder,
  TaskOrder,
} from ".";
import { HwCheckStatusType } from "pages/Student/Checks/component/HwCheckStatusType";

type EnumValue<T> = T[Extract<keyof T, string>];

type EnumTypeList<V extends string, T> = {
  label: V;
  value: EnumValue<T>;
};

export class EnumConverter {
  /**
   * @description Преобразовывает значения **`Enum TaskType`** в человекопонятную строку
   *
   *@param value **`Enum TaskType`**.
   * @returns Вовращает человекопонятную строку
   */
  public static typeTaskToStr(value: TaskType) {
    switch (value) {
      case TaskType.FirstPart:
        return "Первая часть (Краткий ответ)";
      case TaskType.SecondPart:
        return "Вторая часть (Развернутый ответ)";
      case TaskType.FileAnswer:
        return "Сдать файл";
      case TaskType.FileQuestion:
        return "Прикрепить файл";
    }
  }

  /**
   * @description Преобразовывает значения **`Enum HomeworkStudentDeadlineStatus`** в человекопонятную строку
   *
   *@param value **`Enum HomeworkStudentDeadlineStatus`**.
   * @returns Вовращает человекопонятную строку
   */
  public static homeworkStudentDeadlineStatusToStr(
    value: HomeworkStudentDeadlineStatus
  ) {
    switch (value) {
      case HomeworkStudentDeadlineStatus.soon:
        return "Скоро";
      case HomeworkStudentDeadlineStatus.pass:
        return "Прошел";
    }
  }

  /**
   * @description Задаёт уникальный цвет для значений **`Enum EventType`**
   *
   *@param value **`Enum EventType`**.
   * @returns Вовращает **`TypeAttributes.Color`** и **`значение цвета`**
   */
  public static eventTypeToColor(type: EventType) {
    let color: TypeAttributes.Color | undefined = undefined;
    switch (type) {
      case EventType.Custom:
        color = "green";
        break;
      case EventType.Lesson:
        color = "yellow";
        break;
      case EventType.HomeworkDeadlineCheck:
        color = "violet";
        break;
      case EventType.HomeworkDeadlineSubmit:
        color = "blue";
        break;
    }
    return color;
  }

  /**
   * @description Преобразовывает значения **`Enum eventType`** в человекопонятную строку
   *
   *@param value **`Enum eventType`**.
   * @returns Вовращает человекопонятную строку
   */
  public static eventTypeToStr(value: EventType) {
    switch (value) {
      case EventType.Lesson:
        return "Уроки";
      case EventType.HomeworkDeadlineCheck:
        return "Дедлайны проверок";
      case EventType.HomeworkDeadlineSubmit:
        return "Дедлайны домашек";
      case EventType.Custom:
        return "Пользовательские события";
    }
  }

  /**
   * @description Преобразовывает значения **`Enum HomeworkStudentCheckStatus`** в человекопонятную строку
   *
   *@param value **`Enum HomeworkStudentCheckStatus`**.
   * @returns Вовращает человекопонятную строку
   */
  public static homeworkStudentCheckStatusToStr(
    value: HomeworkStudentCheckStatus
  ) {
    switch (value) {
      case HomeworkStudentCheckStatus.checkedCorrect:
        return "Проверена вовремя ✅";
      case HomeworkStudentCheckStatus.checkedLate:
        return "Проверена после дедлайна 💀";
      case HomeworkStudentCheckStatus.notChecked:
        return "Не проверена";
      case HomeworkStudentCheckStatus.checked:
        return "Проверена";
    }
  }

  /**
   * @description Преобразовывает значения **`Enum HomeworkStudentStatus`** в человекопонятную строку
   *
   *@param value **`Enum HomeworkStudentStatus`**.
   * @returns Вовращает человекопонятную строку
   */
  public static homeworkStudentStatusToStr(value: HomeworkStudentStatus) {
    switch (value) {
      case HomeworkStudentStatus.notSend:
        return "Не сдана";
      case HomeworkStudentStatus.sendCorrect:
        return "Сдана вовремя";
      case HomeworkStudentStatus.sendLate:
        return "Сдана после дедлайна";
      case HomeworkStudentStatus.sended:
        return "Сдана";
    }
  }

  /**
   * @description Преобразовывает значения **`Enum EOrderPaymentStatus`** в человекопонятную строку
   *
   *@param value **`Enum EOrderPaymentStatus`**.
   * @returns Вовращает человекопонятную строку
   */
  public static orderPaymentStatusToStr(value: EOrderPaymentStatus) {
    switch (value) {
      case EOrderPaymentStatus.Canceled:
        return "Отменена";
      case EOrderPaymentStatus.Pending:
        return "В обработке";
      case EOrderPaymentStatus.Succeeded:
        return "Успешно";
      case EOrderPaymentStatus.WaitingForCapture:
        return "Ожидается снятие";
    }
  }

  /**
   * @description Задаёт уникальный цвет для значений **`Enum EOrderPaymentStatus`**
   *
   *@param value **`Enum EOrderPaymentStatus`**.
   * @returns Вовращает **`TypeAttributes.Color`** и **`значение цвета`**
   */
  public static orderPaymentStatusToColor(type: EOrderPaymentStatus) {
    let color: TypeAttributes.Color | undefined = undefined;
    switch (type) {
      case EOrderPaymentStatus.Canceled:
        color = "red";
        break;
      case EOrderPaymentStatus.Pending:
        color = "orange";
        break;
      case EOrderPaymentStatus.Succeeded:
        color = "green";
        break;
      case EOrderPaymentStatus.WaitingForCapture:
        color = "violet";
        break;
    }
    return color;
  }

  /**
   * @description Преобразовывает значения **`Enum RoleNames`** в название роли на русском
   *
   *@param value **`Enum RoleNames`**.
   * @returns Вовращает название роли на русском
   */
  public static RoleNamesToStr(value: RoleNames) {
    switch (value) {
      case RoleNames.Student:
        return "Ученик";
      case RoleNames.Mentor:
        return "Ментор";
      case RoleNames.Teacher:
        return "Учитель";
      case RoleNames.Admin:
        return "Администратор";
      case RoleNames.Super:
        return "Бог";
    }
  }

  /**
   * @description Преобразовывает значения **`Enum Role`** в название роли на русском
   *
   *@param value **`Enum Role`**.
   * @returns Вовращает название роли на русском
   */
  public static RoleToStr(value: Role) {
    switch (value) {
      case Role.Student:
        return "Ученик";
      case Role.Mentor:
        return "Ментор";
      case Role.Teacher:
        return "Учитель";
      case Role.Admin:
        return "Администратор";
      case Role.Super:
        return "Бог";
    }
  }

  /**
   * Возвращает номер роли на основе заданной роли.
   * @param role Роль.
   * @returns Номер роли или undefined, если роль не найдена.
   */
  public static GetNumberByRoleNames(role: RoleNames): number | undefined {
    const roles = Object.values(RoleNames);
    const number = roles.indexOf(role) + 1;
    return number > 0 ? number : undefined;
  }

  /**
   * @description Преобразовывает значения **`Enum LogLevel`** в название роли на русском
   *
   *@param value **`Enum LogLevel`**.
   * @returns Вовращает название роли на русском
   */
  public static LogLevelToStr(value: LogLevel) {
    switch (value) {
      case LogLevel.Trace:
        return "Trace";
      case LogLevel.Debug:
        return "Debug";
      case LogLevel.Information:
        return "Information";
      case LogLevel.Warning:
        return "Warning";
      case LogLevel.Error:
        return "Error";
      case LogLevel.Critical:
        return "Critical";
      case LogLevel.None:
        return "None";
    }
  }

  /**
   * @description Преобразовывает значения **`Enum PaymentType`** в название типа платежа на русском
   *
   *@param value **`Enum PaymentType`**.
   * @returns Вовращает название типа платежа на русском
   */
  public static PaymentTypeToStr(value: PaymentType) {
    switch (value) {
      case PaymentType.Full:
        return "Полный доступ";
      case PaymentType.VideoLessons:
        return "Только видео-уроки(проверка авто)";
      default:
        return "Нет типа оплаты, это баг";
    }
  }

  /**
   * @description Преобразовывает значения **`Enum PaymentType`** в картинку типа платежа
   *
   *@param value **`Enum PaymentType`**.
   * @returns Вовращает название типа платежа картинкой
   */
  public static PaymentTypeToImg(value: PaymentType): React.JSX.Element {
    switch (value) {
      case PaymentType.Full:
        return <MediaIcon />;
      case PaymentType.VideoLessons:
        return <DocPassIcon />;
      default:
        return <CloseIcon />;
    }
  }
  /**
   * @description Преобразовывает значения **`Enum TariffType`** в человекопонятную строку
   *
   *@param value **`Enum TariffType`**.
   * @returns Вовращает человекопонятную строку
   */
  public static typeTariffToStr(value: TariffType) {
    switch (value) {
      case TariffType.Base:
        return "Основные";
      case TariffType.Additional:
        return "Спец-курсы";
      case TariffType.Free:
        return "Бесплатные";
    }
  }

  /**
   * @description Преобразовывает значения **`Enum ExamType`** в человекопонятную строку
   *
   *@param value **`Enum ExamType`**.
   * @returns Вовращает человекопонятную строку
   */
  public static typeExamToStr(value: ExamType) {
    switch (value) {
      case ExamType.Ege:
        return "ЕГЭ";
      case ExamType.Oge:
        return "ОГЭ";
    }
  }

  /**
   * @description Преобразовывает значения **`Enum LessonsOrder`** в человекопонятную строку
   *
   *@param value **`Enum LessonsOrder`**.
   * @returns Вовращает человекопонятную строку
   */
  public static lessonsOrderToStr(value: LessonsOrder) {
    switch (value) {
      case LessonsOrder.TitleAsc:
        return "От А до Я";
      case LessonsOrder.TitleDesc:
        return "От Я до А";
      case LessonsOrder.DateTimeDesc:
        return "Сначала новые";
      case LessonsOrder.DateTimeAsc:
        return "Сначала старые";
      case LessonsOrder.CreateDateDesc:
        return "Созданные недавно";
      case LessonsOrder.CreateDateAsc:
        return "Созданные давно";
    }
  }

  /**
   * @description Преобразовывает значения **`Enum TaskOrder`** в человекопонятную строку
   *
   *@param value **`Enum TaskOrder`**.
   * @returns Вовращает человекопонятную строку
   */
  public static TaskOrderToStr(value: TaskOrder) {
    switch (value) {
      case TaskOrder.DifficultyAsc:
        return "Сначала лёгкие";
      case TaskOrder.DifficultyDesc:
        return "Сначала сложные";
      case TaskOrder.DateCreateAsc:
        return "Сначала старые";
      case TaskOrder.DateCreateDesc:
        return "Сначала новые";
    }
  }

  /**
   * @description Преобразовывает значения **`Enum StudentTariffOrder`** в человекопонятную строку
   *
   *@param value **`Enum StudentTariffOrder`**.
   * @returns Вовращает человекопонятную строку
   */
  public static StudentTariffOrderToStr(value: StudentTariffOrder) {
    switch (value) {
      case StudentTariffOrder.DeletedAsc:
        return "Сначала активные";
      case StudentTariffOrder.DeletedDesc:
        return "Сначала удаленные";
      case StudentTariffOrder.StudentNameAsc:
        return "По алфавиту";
      case StudentTariffOrder.StudentNameDesc:
        return "По алфавиту в обратном порядке";
    }
  }

  /**
   * @description Преобразовывает значения **`Enum LessonType`** в человекопонятную строку
   *
   *@param value **`Enum LessonType`**.
   * @returns Вовращает человекопонятную строку
   */
  public static lessonTypeToStr(value: LessonType) {
    switch (value) {
      case LessonType.Base:
        return "Основные";
      case LessonType.Public:
        return "Публичные";
      case LessonType.Additional:
        return "Дополнительные";
    }
  }

  /**
   * @description Преобразовывает значения **`Enum LessonPassedType`** в человекопонятную строку
   *
   *@param value **`Enum LessonPassedType`**.
   * @returns Вовращает человекопонятную строку
   */
  public static lessonPassedTypeToStr(value: LessonPassedType) {
    switch (value) {
      case LessonPassedType.isPassed:
        return "Прошедший";
      case LessonPassedType.isComing:
        return "Будущий";
    }
  }

  /**
   * @description Преобразовывает значения **`Enum SaleType`** в человекопонятную строку
   *
   *@param value **`Enum SaleType`**.
   * @returns Вовращает человекопонятную строку
   */
  public static saleTypeToStr(value: SaleType) {
    switch (value) {
      case SaleType.Amount:
        return "Фиксированная сумма";
      case SaleType.Percent:
        return "Процент";
    }
  }

  /**
   * @description Преобразовывает значения **`Enum RegTokenType`** в человекопонятную строку
   *
   *@param value **`Enum RegTokenType`**.
   * @returns Вовращает человекопонятную строку
   */
  public static regTokenTypeToStr(value: RegTokenType) {
    switch (value) {
      case RegTokenType.Tariff:
        return "Курс, без оплат";
      case RegTokenType.TariffFull:
        return "Курс, все месяца оплачены";
    }
  }

  /**
   * @description Преобразовывает значения **`Enum GlobalNotificationType`** в человекопонятную строку
   *
   *@param value **`Enum GlobalNotificationType`**.
   * @returns Вовращает человекопонятную строку
   */
  public static globalNotificationTypeToStr(value: GlobalNotificationType) {
    switch (value) {
      case GlobalNotificationType.Alert:
        return "Предупреждение";
      case GlobalNotificationType.Info:
        return "Информационное";
      case GlobalNotificationType.Technical:
        return "Техническое";
    }
  }

  /**
   * @description Преобразовывает значения **`Enum HwCheckStatusType`** в человекопонятную строку
   *
   *@param value **`Enum HwCheckStatusType`**.
   * @returns Вовращает человекопонятную строку
   */
  public static statusTypeToStr = (value: HwCheckStatusType) => {
    switch (value) {
      case HwCheckStatusType.all:
        return "Все";
      case HwCheckStatusType.notSend:
        return "Не сдана";
      case HwCheckStatusType.sendCorrect:
        return "Сдана";
      case HwCheckStatusType.sendLate:
        return "Сдана после дедлайна";
      case HwCheckStatusType.notChecked:
        return "Не проверена";
      case HwCheckStatusType.checkedCorrect:
        return "Проверена";
    }
  };

  public static mapEnum<T extends Record<StringKeyOf<T>, number | string>>(
    enumObj: T,
    method: (arg: EnumValue<T>) => string
  ): EnumTypeList<string, T>[] {
    const result = $enum(enumObj)
      .getValues()
      .map((x) => ({
        label: method(x),
        value: x,
      }));
    return result;
  }
}
