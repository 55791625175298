import React from "react";
import { observer } from "mobx-react";
import { useStore } from "hooks";
import { GlobalNotificationType } from "stores";
import { isBefore, isAfter } from "date-fns";
import { ExpandableText } from "components";
import { Message } from "shared/ui";

const TechnicalGlobalNotificationComponent: React.FC = () => {
  const { globalNotificationStore } = useStore();
  const currentDate = new Date();

  const technicalGlobalNotification = globalNotificationStore.data.filter(
    (x) =>
      x.type === GlobalNotificationType.Technical &&
      isBefore(new Date(x.dateRange.begin), currentDate) &&
      isAfter(new Date(x.dateRange.end), currentDate)
  );

  return (
    <>
      {technicalGlobalNotification.map((x) => {
        return (
          <div key={x.id} className="we-technical-global-notification">
            <Message
              showIcon
              type="info"
              className="we-technical-global-notification-message"
            >
              <b>{x.title}</b>
              <br />
              <ExpandableText text={x.description} maxLength={100} />
            </Message>
          </div>
        );
      })}
    </>
  );
};
export const TechnicalGlobalNotification = observer(
  TechnicalGlobalNotificationComponent
);
