import React, { useEffect, useState } from "react";
import { Modal } from "rsuite";
import { useDebouncedFunction, useIsMounted, useStore } from "hooks";
import {
  ICartItem,
  ILesson,
  IPayOrder,
  IPayOrderTariff,
  IPayment,
  ITariff,
  PaymentType,
} from "stores";
import { LinkHelper } from "root";
import { observer } from "mobx-react";
import { ITariffMonthStatus } from "./ITariffMonthStatus";
import { DescriptionPromotions, OrderItem } from "pages/Student/Payment";
import { Promocode } from "pages/Student/Payment/Cart/components/Promocode";
import { Button } from "shared/ui";

interface IFastPayModalProps {
  lesson: ILesson | undefined;
  isOpen: boolean;
  onCancel: () => void;
}

const FastPayModalComponent: React.FC<IFastPayModalProps> = (props) => {
  const isMounted = useIsMounted();
  const { tariffStore, paymentStore } = useStore();

  const [inputPromocode, setInputPromocode] = useState("");
  const [paymentOrder, setPaymentOrder] = useState<IPayment | undefined>();
  const [finishOrder, setFinishOrder] = useState<IPayOrder>();
  const [cartItem, setCartItem] = useState<ICartItem>({
    tariffId: 0,
    selectedMonth: [0],
  });

  useEffect(() => {
    if (!finishOrder) {
      return;
    }
    calculatePaymentAmount({
      tariffs: finishOrder?.tariffs,
      promocode: inputPromocode,
    });
  }, [inputPromocode]);

  useEffect(() => {
    const selectedTariff = tariffStore.find(props.lesson?.tariffId);
    if (!selectedTariff || !props.lesson) {
      return;
    }
    addItem(selectedTariff, props.lesson.monthNumber);
  }, [props.lesson]);

  const addItem = (tariff: ITariff, lessonMonthNumber: number) => {
    setCartItem((prevOrder) => ({
      ...prevOrder,
      tariffId: tariff.id,
      selectedMonth: [lessonMonthNumber],
    }));

    const tariffFromOrder: IPayOrderTariff = {
      tariffId: tariff.id,
      type: PaymentType.Full,
      monthNumbers: [lessonMonthNumber],
      fullPay: false,
    };
    calculatePaymentAmount({ tariffs: [tariffFromOrder] });
  };

  const calculatePaymentAmount = useDebouncedFunction(
    async (order: IPayOrder) => {
      const result = await paymentStore.calculateAmount(order);
      if (!isMounted() || !result) {
        return;
      }

      setPaymentOrder(result);
      setFinishOrder(order);
    },
    250
  );

  const loadPayUrl = async (factAmount: number) => {
    if (!finishOrder) {
      return;
    }
    const result = await paymentStore.getPayUrl(finishOrder, factAmount);
    if (result) {
      LinkHelper.openLink(result);
    }
  };

  const handleOnChangeOrderItem = (tariffMonth: ITariffMonthStatus) => {
    if (!finishOrder) {
      return;
    }
    const selectedMonths: number[] = tariffMonth.monthStatus
      .filter((x) => x.isChecked)
      .map((x) => x.monthNumber);

    const updatedFinishOrder: IPayOrder = {
      ...finishOrder,
      tariffs: [
        {
          ...finishOrder.tariffs[0],
          monthNumbers: selectedMonths,
        },
      ],
    };
    calculatePaymentAmount(updatedFinishOrder);
  };

  return (
    <Modal open={props.isOpen} onClose={props.onCancel} size="md">
      <Modal.Header>
        <Modal.Title></Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <OrderItem
          payOrder={paymentOrder}
          сartItem={cartItem}
          isFastPay={true}
          onChange={handleOnChangeOrderItem}
          onLoading={paymentStore.onLoading}
        />
        <DescriptionPromotions promotions={paymentOrder?.promotions} />
        <Promocode
          payOrder={paymentOrder}
          onChange={(inputPromocode: string) =>
            setInputPromocode(inputPromocode)
          }
        />
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onCancel} appearance="subtle">
          Отмена
        </Button>
        <Button
          loading={paymentStore.onLoading}
          onClick={() => loadPayUrl(paymentOrder?.factAmount || 0)}
          color="violet"
          appearance="primary"
        >
          {`перейти к оплате ${paymentOrder?.factAmount || 0}₽`}
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
export const FastPayModal = observer(FastPayModalComponent);
