import InfoIcon from "@rsuite/icons/legacy/Info";
import { ValidationErrorComponent } from "components";
import React, { useEffect, useState } from "react";
import { Stack, Whisper } from "rsuite";
import { useAnswerFirstPart } from "./AnswerFirstPart/useAnswerFirstPart";
import { InputNumber, Tooltip } from "shared/ui";

export interface IInputScoreProps {
  score?: number;
  maxScore: number;
  onChange: (score: number | undefined) => void;
  scoreError: string | undefined;
  disabledInput?: boolean;
}
export const InputScore: React.FC<IInputScoreProps> = ({
  maxScore,
  score,
  onChange,
  disabledInput,
  scoreError,
}) => {
  const [value, setValue] = useState<number | undefined>(score);
  const hasScore = score !== null && score !== undefined;

  useEffect(() => {
    hasScore && setValue(score);
  }, [score, hasScore]);

  const handleChange = (value: string | number) => {
    const score = typeof value === "string" ? parseInt(value) : value;
    onChange(score);
    setValue(score);
  };

  const { inputStyle } = useAnswerFirstPart(score, maxScore);
  const style = hasScore && disabledInput ? inputStyle : {};
  return (
    <>
      <Stack spacing={10} style={{ marginTop: 10 }}>
        <>
          <InputNumber
            autoComplete="off"
            className="inputScore"
            disabled={disabledInput}
            value={value}
            max={maxScore}
            min={0}
            placeholder="Балл"
            style={{ ...style, width: 100 }}
            onChange={handleChange}
          />
          {<ValidationErrorComponent title={scoreError} />}
        </>
        <Whisper speaker={<Tooltip>Максимальный балл {maxScore}</Tooltip>}>
          <InfoIcon />
        </Whisper>
      </Stack>
    </>
  );
};
