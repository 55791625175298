import FileDownloadIcon from "@rsuite/icons/FileDownload";
import { ViewFileModal } from "components/Modal/ViewFileModal/ViewFileModal";
import { useEffect, useState } from "react";
import { ExtensionHelper } from "root/ExtensionHelper";
import { LinkHelper } from "root/LinkHelper";
import { IconButton } from "shared/ui";

interface IDocumentSrcProps {
  src: string;
  isDownload: boolean;
}

interface IAnswerSrcListProps {
  answerFileUrlList: string[] | undefined;
}

export const AnswerSrcList: React.FC<IAnswerSrcListProps> = ({
  answerFileUrlList,
}) => {
  const [imgSrc, setImgSrc] = useState("");
  const [isOpenConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [documentSrcList, setDocumentSrcList] = useState<IDocumentSrcProps[]>(
    []
  );

  useEffect(() => {
    const filteredUrls =
      answerFileUrlList
        ?.filter((file) => !ExtensionHelper.isImage(file))
        .map((x) => ({ src: x, isDownload: false })) || [];

    setDocumentSrcList(filteredUrls);
  }, [answerFileUrlList]);

  const handleFlagIsDownload = (src: string) => {
    const updatedList = documentSrcList.map((document) =>
      document.src === src ? { ...document, isDownload: true } : document
    );
    setDocumentSrcList(updatedList);
  };

  const imgSrcList = answerFileUrlList?.filter((fileUrl) =>
    ExtensionHelper.isImage(fileUrl)
  );

  const handleChange = (document: IDocumentSrcProps) => {
    ExtensionHelper.isPdf(document.src) || ExtensionHelper.isTxt(document.src)
      ? LinkHelper.openLink(document.src, true)
      : LinkHelper.fileDownload(document.src);
    handleFlagIsDownload(document.src);
  };

  if (!answerFileUrlList || !answerFileUrlList.length) {
    return <h6>Ученик не прислал файлы-ответы 😥</h6>;
  }

  return (
    <>
      <ViewFileModal
        imgSrcList={imgSrcList}
        imgSrc={imgSrc}
        isOpen={isOpenConfirmModal}
        onCancel={() => {
          setOpenConfirmModal(false);
        }}
      />
      <div style={{ display: "flex", flexWrap: "wrap" }}>
        {imgSrcList?.map((x) => (
          <img
            key={x}
            className="img-file-answer"
            src={x}
            alt="Картинка-ответ"
            onClick={() => {
              setImgSrc(x);
              setOpenConfirmModal(true);
            }}
          />
        ))}
      </div>
      {documentSrcList.map((x) => (
        <IconButton
          key={x.src}
          block
          appearance={x.isDownload ? "primary" : "default"}
          color={x.isDownload ? "green" : undefined}
          onClick={() => handleChange(x)}
          icon={<FileDownloadIcon />}
        >
          Файл-ответ
        </IconButton>
      ))}
    </>
  );
};
