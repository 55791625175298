import ReviewPassIcon from "@rsuite/icons/ReviewPass";
import ReviewRefuseIcon from "@rsuite/icons/ReviewRefuse";
import React from "react";

interface IPasteButtonProps {
  resetStyleOnPaste: boolean;
  onClick: () => void;
}

export const PasteButton: React.FC<IPasteButtonProps> = ({
  resetStyleOnPaste,
  onClick,
}) => {
  return (
    <div
      className="rdw-option-wrapper"
      onClick={() => onClick()}
      title={
        resetStyleOnPaste
          ? "Форматирование при вставке сбрасывется"
          : "Форматирование при вставке сохраняется"
      }
    >
      {resetStyleOnPaste ? <ReviewRefuseIcon /> : <ReviewPassIcon />}
    </div>
  );
};
