import { Cell } from "rsuite-table";
import { IStudent, PartPercentType } from "stores";
import { observer } from "mobx-react";
import { ButtonToolbar, Whisper, Drawer, Modal } from "rsuite";
import TrendIcon from "@rsuite/icons/Trend";
import MemberIcon from "@rsuite/icons/Member";
import {
  StudentProgressChart,
  StudentProgressPart,
} from "components/ProgressChart";
import { Account } from "pages/Student/Account";
import { useState } from "react";
import { TextHelper } from "root/TextHelpers";
import { IconButton, Tooltip } from "shared/ui";

interface IActionCellProps {
  rowData?: IStudent;
  dataKey: string;
}

const ActionCellComponent: React.FC<IActionCellProps> = (props) => {
  const [open, setOpen] = useState(false);
  const [openProfile, setOpenProfile] = useState(false);

  if (!props.rowData) {
    return <></>;
  }

  const studentId = props.rowData.id;
  return (
    <>
      <Drawer size="full" open={open} onClose={() => setOpen(false)}>
        <Drawer.Header>
          <Drawer.Title>
            {TextHelper.GetShortName(props.rowData.user, true)}
          </Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <StudentProgressChart studentId={studentId} />
          <StudentProgressPart
            partPercentType={PartPercentType.AllPart}
            studentId={studentId}
          />
          <StudentProgressPart
            partPercentType={PartPercentType.FirstPart}
            studentId={studentId}
          />
          <StudentProgressPart
            partPercentType={PartPercentType.SecondPart}
            studentId={studentId}
          />
        </Drawer.Body>
      </Drawer>

      <Modal open={openProfile} onClose={() => setOpenProfile(false)}>
        <Modal.Header></Modal.Header>
        <Modal.Body>
          <Account studentId={studentId} />
        </Modal.Body>
      </Modal>
      <Cell
        {...props}
        className="link-group"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexWrap: "wrap",
        }}
      >
        <ButtonToolbar>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Успеваемость студента</Tooltip>}
          >
            <IconButton
              onClick={() => setOpen(true)}
              icon={<TrendIcon />}
              size="sm"
              color="green"
              appearance="primary"
            />
          </Whisper>

          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Профиль студента</Tooltip>}
          >
            <IconButton
              onClick={() => setOpenProfile(true)}
              icon={<MemberIcon />}
              size="sm"
              color="green"
              appearance="primary"
            />
          </Whisper>
        </ButtonToolbar>
      </Cell>
    </>
  );
};
export const ActionCell = observer(ActionCellComponent);
