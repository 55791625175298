import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { toaster } from "rsuite";
import { useStore } from "hooks";
import { GlobalNotificationType } from "stores";
import { isBefore, isAfter } from "date-fns";
import { useLocalStorage } from "hooks/useLocalSessionsStorage";
import { GlobalConstants } from "root/GlobalConstants";
import { Notification } from "shared/ui";

const AlertGlobalNotificationComponent: React.FC = () => {
  const { globalNotificationStore } = useStore();
  const [closedAlertIds, setClosedAlertIds] = useLocalStorage<number[]>(
    GlobalConstants.viewedAndClosedAlertNotificationIds,
    []
  );

  useEffect(() => {
    const currentDate = new Date();

    const filteredNotifications = globalNotificationStore.data.filter((x) => {
      const isAlertType = x.type === GlobalNotificationType.Alert;
      const isClosed = closedAlertIds.includes(x.id);
      const isDateRangeValid =
        isBefore(new Date(x.dateRange.begin), currentDate) &&
        isAfter(new Date(x.dateRange.end), currentDate);

      return isAlertType && !isClosed && isDateRangeValid;
    });

    filteredNotifications.forEach((notification) => {
      toaster.push(
        <Notification
          style={{ whiteSpace: "pre-wrap" }}
          type="warning"
          header={notification.title}
          closable
          onClose={() =>
            setClosedAlertIds((prev) => [...prev, notification.id])
          }
        >
          <p>{notification.description}</p>
        </Notification>,
        { placement: "bottomEnd", duration: 24 * 60 * 60 * 1000 }
      );
    });
  }, [globalNotificationStore.data]);

  return null;
};

export const AlertGlobalNotification = observer(
  AlertGlobalNotificationComponent
);
