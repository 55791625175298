import { runInAction, makeObservable, observable } from "mobx";
import { CrudController } from "root/controllers";
import { NotificationHelper } from "../root";
import { queryClient } from "provider/QueryProvider/QueryProvider";

export interface IEntity {
  id: number;
}

export abstract class CrudStore<T extends IEntity> {
  private apiController: CrudController<T>;
  public data: T[] = [];
  public onLoading = false;
  public onSaving = false;
  public onDeleting = false;

  constructor(controller: CrudController<T>) {
    this.apiController = controller;

    makeObservable(this, {
      data: observable,
      onLoading: observable,
      onSaving: observable,
      onDeleting: observable,
    });
  }

  public find(id?: number): T | undefined {
    return this.data.find((x) => x.id === id);
  }

  public isEmpty(): boolean {
    return !this.data || this.data.length === 0;
  }

  public async load(refetch = false) {
    this.setOnLoading(true);
    const queryKey = ["load", this.apiController];
    try {
      refetch && queryClient.removeQueries(queryKey);
      const result = await queryClient.fetchQuery({
        queryKey,
        queryFn: async () => await this.apiController.GetAll(refetch),
      });
      runInAction(() => result && (this.data = result));
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
    return null;
  }

  public async refresh(id: number | undefined = undefined) {
    if (!id) {
      this.load(true);
      return;
    }
    this.setOnLoading(true);
    try {
      const result = await this.apiController.Get(id);
      if (!result) {
        return;
      }
      runInAction(() => {
        const index = this.data.findIndex((x) => x.id === id);

        if (index !== -1) {
          this.data = this.data.filter((x) => x.id !== id);
        }

        const insertionIndex = index !== -1 ? index : this.data.length;

        this.data = [
          ...this.data.slice(0, insertionIndex),
          result,
          ...this.data.slice(insertionIndex),
        ];
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
  }

  public async save(
    obj: any,
    entityId: number | undefined = undefined
  ): Promise<boolean> {
    this.setOnSaving(true);
    try {
      if (!entityId && obj.id && obj.id > 0) {
        entityId = obj.id;
      }
      const id = entityId
        ? await this.apiController.Update(entityId, obj)
        : await this.apiController.Create(obj);
      NotificationHelper.ShowSuccess("Успешно сохранено!");
      this.refresh(id);
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.setOnSaving(false);
    }
  }

  public async delete(entityId: number): Promise<boolean> {
    this.setOnDeleting(true);
    try {
      await this.apiController.Delete(entityId);
      runInAction(
        () => (this.data = this.data.filter((x) => x.id !== entityId))
      );
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.setOnDeleting(false);
    }
  }

  protected setOnLoading(value: boolean) {
    runInAction(() => {
      this.onLoading = value;
    });
  }
  protected setOnSaving(value: boolean) {
    runInAction(() => {
      this.onSaving = value;
    });
  }
  protected setOnDeleting(value: boolean) {
    runInAction(() => {
      this.onDeleting = value;
    });
  }
  public clear() {
    runInAction(() => {
      this.data = [];
    });
  }
}
