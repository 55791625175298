import { useStore } from "hooks";
import { ILesson } from "stores";

export const usePaymentNotificationContent = (lessonNotPayment: ILesson) => {
  const { tariffStore, authStore } = useStore();

  const tariffTitle = tariffStore.find(lessonNotPayment.tariffId)?.title;
  const firstName = authStore.me?.firstName;
  return { tariffTitle, firstName };
};
