import React from "react";
import { observer } from "mobx-react";
import { IPromotionByPayment, SaleType } from "stores";

interface IDescriptionPromotionsProps {
  promotions: IPromotionByPayment[] | undefined;
}

const DescriptionPromotionsComponent: React.FC<IDescriptionPromotionsProps> = ({
  promotions,
}) => {
  if (!promotions || promotions.length === 0) {
    return null;
  }

  const sortedPromotion = promotions.slice().sort((left, right) => {
    if (left.isPromocode) {
      return -1;
    }
    if (right.isPromocode) {
      return 1;
    }
    return 0;
  });

  return (
    <>
      <span style={{ color: "var(--rs-text-tertiary)" }}>
        {sortedPromotion.map((x) => {
          return (
            <p key={x.promocodeId}>
              {x.name} {x.isPromocode ? "применен" : "применена"}. Скидка
              составила -{x.sale.toLocaleString("ru-RU")}
              {x.type === SaleType.Amount ? "₽" : "%"}
            </p>
          );
        })}
      </span>
    </>
  );
};
export const DescriptionPromotions = observer(DescriptionPromotionsComponent);
