import React, { PropsWithChildren } from "react";
import { Notification as RSNotification } from "rsuite";
import { RefForwardingComponent } from "../types";
import { NotificationProps } from "./types";

const notification = <E extends React.ElementType>(
  { children, ...props }: PropsWithChildren<NotificationProps>,
  ref: React.ForwardedRef<E>
) => (
  <RSNotification {...props} ref={ref}>
    {children}
  </RSNotification>
);

export const Notification: RefForwardingComponent<"div", NotificationProps> =
  React.forwardRef(notification);
