import { makeObservable, observable, runInAction } from "mobx";
import { queryClient } from "provider/QueryProvider/QueryProvider";
import Api from "../root/Api";
import { CrudStore } from "./CrudStore";
import { IStudentTariff, ITariff } from "./models";

export class TariffStore extends CrudStore<ITariff> {
  selectedTariff: ITariff | undefined = undefined;
  myTariffs: IStudentTariff[] | undefined = undefined;
  constructor() {
    super(Api.tariffs);
    makeObservable(this, { myTariffs: observable, selectedTariff: observable });
  }

  public getEnabledMyTariffs(): ITariff[] {
    const result = (this.myTariffs || [])
      .filter((x) => x.enabled)
      .map((x) => x.tariff);
    return result;
  }

  public getMyTariffs(): ITariff[] {
    const result = (this.myTariffs || []).map((x) => x.tariff);
    return result;
  }

  public async loadMyTariffs(refetch = false): Promise<void> {
    this.setOnLoading(true);
    const queryKey = ["tariffs.GetTariffsForStudent"];
    try {
      refetch && queryClient.removeQueries(queryKey);
      const result = await queryClient.fetchQuery({
        queryKey,
        queryFn: async () => await Api.tariffs.GetTariffsForStudent(refetch),
      });
      runInAction(() => result && (this.myTariffs = result));
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
  }
  public setSelectedTariff(tariffId: number) {
    const foundTariff = this.find(tariffId);
    runInAction(() => {
      this.selectedTariff = foundTariff;
    });
  }

  public clear() {
    super.clear();
    runInAction(() => {
      this.selectedTariff = undefined;
      this.myTariffs = undefined;
    });
  }
}
