import { useStore } from "../../hooks";

export const Home = () => {
  const { authStore } = useStore();
  return (
    <>
      <h1>Добро пожаловать {authStore.me?.firstName}!</h1>
    </>
  );
};
