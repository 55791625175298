import ExitIcon from "@rsuite/icons/Exit";
import SendIcon from "@rsuite/icons/Send";
import UserBadgeIcon from "@rsuite/icons/UserBadge";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
import { GlobalConstants } from "root/GlobalConstants";
import { logOutHelper } from "root/LogoutHelper";
import { Dropdown } from "rsuite";
import { RoleNames } from "stores";

type TProfileDropdownMenu = { onSelect: () => void };

const ProfileDropdownMenuComponent: React.FC<TProfileDropdownMenu> = ({
  onSelect,
}) => {
  const { authStore } = useStore();
  const isStudent = authStore.me?.role === RoleNames.Student;
  return (
    <Dropdown.Menu onSelect={onSelect}>
      {isStudent && (
        <Dropdown.Item
          as={Link}
          to="/profile"
          style={{ display: "flex", alignItems: "center" }}
        >
          <UserBadgeIcon />
          Профиль
        </Dropdown.Item>
      )}

      <Dropdown.Item
        style={{ display: "flex", alignItems: "center" }}
        as={Link}
        to={GlobalConstants.telegramLinkToSupport}
        target="_blank"
        rel="noopener noreferrer"
      >
        <SendIcon />
        Помощь
      </Dropdown.Item>

      <Dropdown.Item
        onClick={() => logOutHelper.logout()}
        style={{ display: "flex", alignItems: "center" }}
      >
        <ExitIcon />
        Выйти
      </Dropdown.Item>
    </Dropdown.Menu>
  );
};

export const ProfileDropdownMenu = observer(ProfileDropdownMenuComponent);
