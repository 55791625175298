import UserIcon from "@rsuite/icons/legacy/User";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import React from "react";
import { DateHelper } from "root";
import { Avatar, Panel } from "shared/ui";

const WelcomeUserComponent: React.FC = () => {
  const { authStore } = useStore();
  const firstName = authStore.myStudentData?.user?.firstName ?? "Ученик";
  const today = DateHelper.getTodayStr();

  return (
    <Panel bodyFill>
      <Panel>
        <>
          <div className="we-welcome-panel"></div>
          <div
            style={{
              display: "flex",
              alignItems: "center",
              position: "relative",
            }}
          >
            {authStore.me?.imageUrl ? (
              <Avatar size="lg" circle src={authStore.me?.imageUrl} />
            ) : (
              <Avatar size="lg" circle>
                <UserIcon />
              </Avatar>
            )}
            <div style={{ marginLeft: 8 }}>
              <p
                style={{ fontSize: 28, fontWeight: 400, lineHeight: "34px" }}
              >{`Привет, ${firstName}!`}</p>
              Сегодня {today}
            </div>
          </div>
        </>
      </Panel>
    </Panel>
  );
};
export const WelcomeUser = observer(WelcomeUserComponent);
