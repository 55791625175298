import React, { PropsWithChildren } from "react";
import { Row as RSRow } from "rsuite";
import { RefForwardingComponent } from "../types";
import { RowProps } from "./types";

const row = <E extends React.ElementType>(
  { children, ...props }: PropsWithChildren<RowProps>,
  ref: React.ForwardedRef<E>
) => (
  <RSRow {...props} ref={ref}>
    {children}
  </RSRow>
);

export const Row: RefForwardingComponent<"div", RowProps> =
  React.forwardRef(row);
