import { ILesson, IPage } from "../../stores/models";
import { post } from "../WebHelper";
import * as requests from "../requests";
import { CrudController } from "./CrudController";

export class LessonController extends CrudController<ILesson> {
  constructor() {
    super("Lesson");
  }

  public async checkViewedLesson(id: number) {
    await post(`/Lesson/CheckAsViewed/${id}`);
  }

  public async GetLessonsByFilter(
    filter: requests.IRequestGetLessonsByFilter
  ): Promise<IPage<ILesson> | undefined> {
    return await post(`/Lesson/filter/`, filter);
  }
}
