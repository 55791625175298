import { GlobalConstants, LinkHelper } from "root";
import { Button } from "shared/ui";

export const TimeOutMessage: React.FC = () => (
  <>
    <h5 style={{ marginBottom: 10, textAlign: "center" }}>
      Платёж не поступил
    </h5>

    <p style={{ marginBottom: 30, textAlign: "center" }}>
      Платежная система пока не ответила.
      <p>Попробуйте позже или обратитесь в тех. поддержку</p>
    </p>

    <Button
      block
      onClick={() => {
        LinkHelper.openLink(GlobalConstants.telegramLinkToSupport, true);
      }}
    >
      Тех. поддержка
    </Button>
  </>
);
