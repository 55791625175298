import { EmptyStatePanel, FastPayModal } from "components";
import React, { useState } from "react";
import { Button } from "shared/ui";
import { ILesson } from "stores";

type TLessonWithoutEnableProps = {
  lesson: ILesson;
};

export const LessonWithoutEnable: React.FC<TLessonWithoutEnableProps> = ({
  lesson,
}) => {
  const [isOpenPayModal, setIsOpenPayModal] = useState(false);

  return (
    <>
      <FastPayModal
        lesson={lesson}
        isOpen={isOpenPayModal}
        onCancel={() => {
          setIsOpenPayModal(false);
        }}
      />
      <EmptyStatePanel>
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <h2>Урок не оплачен</h2>
          <img
            src={`${process.env.PUBLIC_URL}/images/lessonWithoutEnable.jpg`}
            style={{ width: "50%", height: "auto" }}
          ></img>
          <h3>Этот урок входит в неоплаченный месяц</h3>
          <p>
            Для получения доступа вам необходимо оплатить месяц в который входит
            данный урок
          </p>
          <Button
            appearance="primary"
            style={{ margin: 20 }}
            onClick={() => setIsOpenPayModal(true)}
          >
            Получить доступ сейчас
          </Button>
        </div>
      </EmptyStatePanel>
    </>
  );
};
