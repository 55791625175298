import InfoRoundIcon from "@rsuite/icons/InfoRound";
import { SummaryWritingRules } from "components";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { Drawer, Whisper } from "rsuite";
import { Tooltip } from "shared/ui";

const ButtonInstructionsSummaryComponent: React.FC = () => {
  const [openDrawer, setOpenDrawer] = useState(false);

  return (
    <>
      <Drawer open={openDrawer} onClose={() => setOpenDrawer(false)}>
        <Drawer.Header>
          <Drawer.Title>Правила написания вывода по домашке</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <SummaryWritingRules />
        </Drawer.Body>
      </Drawer>
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Whisper
          placement="auto"
          speaker={<Tooltip>Инструкция по написанию вывода</Tooltip>}
        >
          <InfoRoundIcon onClick={() => setOpenDrawer(true)} />
        </Whisper>
      </div>
    </>
  );
};

export const ButtonInstructionsSummary = observer(
  ButtonInstructionsSummaryComponent
);
