import { makeAutoObservable, runInAction } from "mobx";
import Api from "../root/Api";
import { IPage, IPageableStore, IStudentTariffWithPayments } from "./models";
import {
  IRequestPayTariff,
  IStudentTariffsByFilterRequest,
  NotificationHelper,
} from "../root";
import { queryClient } from "provider/QueryProvider/QueryProvider";

export class StudentTariffStore
  implements
    IPageableStore<IStudentTariffsByFilterRequest, IStudentTariffWithPayments>
{
  studentTariffs: IStudentTariffWithPayments[] = [];
  onLoading = false;
  onUpdating = false;
  filter: IStudentTariffsByFilterRequest | undefined;
  dataPage: IPage<IStudentTariffWithPayments> | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  public async add(tariffId: number, studentId?: number): Promise<boolean> {
    this.setOnUpdating(true);
    try {
      await Api.students.AddTariff(tariffId, studentId);
      NotificationHelper.ShowSuccess("Успешно сохранено!");
      this.loadByFilter(this.filter, true);
      return true;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnUpdating(false);
    }
    return false;
  }

  public async addFree(tariffId: number): Promise<boolean> {
    this.setOnUpdating(true);
    try {
      await Api.students.AddFreeTariff(tariffId);
      NotificationHelper.ShowSuccess(
        'Доступ к курсу получен.\nВсе вебинары находятся во вкладе "Уроки"'
      );
      return true;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnUpdating(false);
    }
    return false;
  }

  public async pay(request: IRequestPayTariff): Promise<boolean> {
    this.setOnUpdating(true);
    try {
      await Api.students.PayTariff(request);
      NotificationHelper.ShowSuccess("Успешно сохранено!");
      this.loadByFilter(this.filter, true);
      return true;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnUpdating(false);
    }
    return false;
  }

  public async delete(tariffId: number, studentId?: number) {
    this.setOnUpdating(true);
    try {
      await Api.students.RemoveTariff(tariffId, studentId);
      this.loadByFilter(this.filter, true);
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnUpdating(false);
    }
    return null;
  }

  public async loadByFilter(
    filter = this.filter,
    refetch = false
  ): Promise<IPage<IStudentTariffWithPayments> | undefined> {
    this.setOnLoading(true);
    const queryKey = ["studentTariffs", filter];
    try {
      refetch && queryClient.removeQueries(queryKey);
      const result = await queryClient.fetchQuery({
        queryKey,
        queryFn: async () => await Api.students.GetTariffs(filter, refetch),
      });
      runInAction(() => {
        this.filter = filter;
        this.dataPage = result;
      });
      return result;
    } catch (error) {
      console.error(error);
      return undefined;
    } finally {
      this.setOnLoading(false);
    }
  }

  private setOnLoading(value: boolean) {
    this.onLoading = value;
  }

  private setOnUpdating(value: boolean) {
    this.onUpdating = value;
  }
  public clear() {
    runInAction(() => {
      this.studentTariffs = [];
      this.filter = undefined;
      this.dataPage = undefined;
    });
  }
}
