import React, { CSSProperties, useState } from "react";

const textExpandStyle: React.CSSProperties = {
  textDecoration: "underline",
  cursor: "pointer",
};

interface IExpandableTextProps {
  text: string;
  maxLength: number;
  style?: CSSProperties;
}

export const ExpandableText: React.FC<IExpandableTextProps> = ({
  text,
  maxLength,
  style,
}) => {
  const [expanded, setExpanded] = useState(false);

  const handleClick = () => {
    setExpanded(!expanded);
  };

  const renderText = () => {
    if (text.length <= maxLength) {
      return text;
    }

    if (expanded) {
      return (
        <>
          {text}{" "}
          <span style={textExpandStyle} onClick={handleClick}>
            свернуть
          </span>
        </>
      );
    }

    return (
      <>
        {text.slice(0, maxLength)}
        {"... "}
        <span style={textExpandStyle} onClick={handleClick}>
          показать
        </span>
      </>
    );
  };

  return <div style={style}>{renderText()}</div>;
};
