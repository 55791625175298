import CloseIcon from "@rsuite/icons/Close";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { ButtonGroup, Modal, Stack, Whisper } from "rsuite";
import { FormCreateTaskPack } from "./FormCreateTaskPack";
import { Button, IconButton, Tooltip } from "shared/ui";

const ButtonCreateTaskPackComponent: React.FC = () => {
  const { taskStore } = useStore();
  const [openModalCreateTaskPack, setOpenModalCreateTaskPack] = useState(false);
  useEffect(() => {
    if (taskStore.tasksForCreateTaskPack.length === 0) {
      handleCloseList();
    }
  }, [taskStore.tasksForCreateTaskPack]);
  const handleCloseList = () => setOpenModalCreateTaskPack(false);

  return (
    <>
      <Stack
        justifyContent="flex-end"
        style={
          taskStore.tasksForCreateTaskPack.length === 0
            ? { marginRight: 20, visibility: "hidden" }
            : { marginRight: 20, visibility: "visible" }
        }
      >
        <ButtonGroup>
          <Button onClick={() => setOpenModalCreateTaskPack(true)}>
            Заданий: {taskStore.tasksForCreateTaskPack.length}
          </Button>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Расформировать</Tooltip>}
          >
            <IconButton
              onClick={() => taskStore.clearTasksForCreateTaskPack()}
              icon={<CloseIcon />}
            />
          </Whisper>
        </ButtonGroup>
      </Stack>

      <Modal
        backdrop={true}
        keyboard={false}
        open={openModalCreateTaskPack}
        onClose={handleCloseList}
      >
        <Modal.Header>
          <Modal.Title>Список заданий</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormCreateTaskPack tasks={taskStore.tasksForCreateTaskPack} />
        </Modal.Body>
      </Modal>
    </>
  );
};
export const ButtonCreateTaskPack = observer(ButtonCreateTaskPackComponent);
