import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Form } from "rsuite";
import { ITask, TaskType } from "stores";
import { Textarea } from "components";

export interface ITaskViewFormControlSecondPartProps {
  answer: string | undefined;
  task: ITask;
  onChange: (
    answer: string,
    taskId: number,
    taskType: TaskType,
    fileName?: string
  ) => void;
}
const TaskViewFormControlSecondPartComponent: React.FC<
  ITaskViewFormControlSecondPartProps
> = (props) => {
  const [value, setValue] = useState<string>("");
  useEffect(() => {
    if (!props.answer) {
      return;
    }
    setValue(props.answer);
  }, [props.answer]);

  return (
    <Form.Group controlId={`task-${props.task.id}`}>
      <Form.ControlLabel>
        <p
          className="p-answerTitle"
          style={{ marginBottom: 5, color: "#bbbbbb" }}
        >
          твой ответ:
        </p>
      </Form.ControlLabel>

      <Form.Control
        value={value}
        name={`${props.task.id}`}
        accepter={Textarea}
        autoComplete="off"
        placeholder="Введите ответ"
        onChange={(value: string) => {
          setValue(value);
          props.onChange(value, props.task.id, props.task.type);
        }}
      />
    </Form.Group>
  );
};
export const TaskViewFormControlSecondPart = observer(
  TaskViewFormControlSecondPartComponent
);
