import { CSSProperties, useState } from "react";
import { Link } from "react-router-dom";
import { DateHelper, TextHelper } from "root";
import { Whisper } from "rsuite";
import {
  AutoCheckWhisper,
  FastPayModal,
  RawHTML,
  TimeEventIcon,
} from "components";
import { useStore } from "hooks";
import { AutoCheckIcon } from "components/Icon/AutoCheckIcon";
import { ILesson, CheckHomeworkType } from "stores";
import { Panel, Tooltip } from "shared/ui";

const accessBlockStyle: CSSProperties = {
  position: "absolute",
  backgroundColor: "var(--wg-modal-bg)",
  borderRadius: 8,
  padding: 1,
  paddingRight: 3,
  paddingLeft: 3,
  zIndex: 1,
};
const autoCheckBlockStyle: CSSProperties = {
  position: "absolute",
  backgroundColor: "var(--wg-modal-bg)",
  borderRadius: 8,
  top: 0,
  right: 0,
  marginRight: 15,
  marginTop: 10,
  padding: 1,
  paddingRight: 3,
  paddingLeft: 3,
};

const imgStyle: CSSProperties = {
  verticalAlign: "top",
  width: "100%",
  borderRadius: 10,
  objectFit: "cover",
  height: 170,
};

const textLinkNoneDecoration = {
  textDecoration: "none",
  ":active": { textDecoration: "none", color: "#000" },
  ":hover": { textDecoration: "none", color: "#000" },
  color: "inherit",
};

interface LessonsListItemProps {
  lesson: ILesson;
}
export const LessonsListItem: React.FC<LessonsListItemProps> = (props) => {
  const { lesson } = props;
  const { authStore } = useStore();

  const [accessTitle, setAccessTitle] = useState("Нет доступа 🔒");
  const [lessonFromPay, setLessonFromPay] = useState<ILesson | undefined>();
  const [payOrderModal, setPayOrderModal] = useState(false);

  const onCilckByLesson = () => {
    if (lesson.enabled) {
      return;
    }

    setLessonFromPay(lesson);
    setPayOrderModal(true);
  };
  return (
    <>
      <FastPayModal
        lesson={lessonFromPay}
        isOpen={payOrderModal}
        onCancel={() => {
          setPayOrderModal(false);
        }}
      />
      <Link
        to={lesson.enabled ? "/lessons/" + lesson.id : "/lessons/"}
        style={textLinkNoneDecoration}
        onMouseOver={() => setAccessTitle("Получить доступ ✅")}
        onMouseLeave={() => setAccessTitle("Нет доступа 🔒")}
        onClick={onCilckByLesson}
      >
        <Panel bodyFill className="panel-lesson">
          {/* тэг автопроверки для tariffs.payments.type */}
          <div
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            {(lesson.checkHomeworkType === CheckHomeworkType.Auto ||
              authStore.myMonthNumbersVideoLessons.some(
                (x) =>
                  x.monthNumber === lesson.monthNumber &&
                  x.tariffId === lesson.tariffId
              )) && (
              <AutoCheckWhisper>
                <div style={autoCheckBlockStyle}>
                  <span>авто</span>
                  <AutoCheckIcon />
                </div>
              </AutoCheckWhisper>
            )}

            {!lesson.enabled && (
              <Whisper
                placement="top"
                controlId="control-id-hover"
                trigger="hover"
                speaker={
                  <Tooltip>
                    {`Доступ к месяцу № ${lesson.monthNumber} заблокирован. Для
                  разблокировки уроков необходимо оплатить ${lesson.monthNumber}й месяц курса.`}
                  </Tooltip>
                }
              >
                <div style={accessBlockStyle}>
                  <span>{accessTitle}</span>
                </div>
              </Whisper>
            )}
            <img
              src={lesson.webinarImageUrl}
              style={
                lesson.enabled
                  ? imgStyle
                  : { ...imgStyle, filter: "blur(3px) saturate(150%)" }
              }
            />
          </div>
          <div style={{ margin: "5px 0px 0px 5px" }}>
            <h6>{lesson.title}</h6>

            {lesson.enabled && (
              <div style={{ display: "flex" }}>
                <div>
                  <TimeEventIcon
                    style={{ fontSize: "1.5em", marginRight: 5 }}
                  />
                </div>
                <span>
                  {`
              ${DateHelper.changeDateToStrLocaleLongWeekday(
                new Date(lesson.dateTime)
              )} в 
              ${DateHelper.changeDateToStrLocaleOnlyTime(
                new Date(lesson.dateTime)
              )}`}
                </span>
              </div>
            )}
          </div>
          <RawHTML html={TextHelper.ShrinkText(lesson.description, 30)} />
        </Panel>
      </Link>
    </>
  );
};
