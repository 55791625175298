import { useStore } from "hooks";
import { useEffect, useState } from "react";
import { EnumConverter, TextHelper, ValidationHelper } from "root";
import { ButtonToolbar, Form, InputPicker, SelectPicker, Toggle } from "rsuite";
import { Button, DatePicker, Input, InputNumber } from "shared/ui";
import { IFormPromocode, IPromocode, IPromocodeRule, SaleType } from "stores";
import { ValidationResult } from "stores/models/ValidationResult";

interface IPromocodeFormProps {
  promocodeId?: number;
  onClose: () => void;
}

const defaultPromocode: IFormPromocode = {
  code: "",
  saleType: SaleType.Amount,
  saleCost: 0,
  dateBegin: new Date(Date.now()),
  dateEnd: new Date(Date.now()),
  tariffId: undefined,
  studentId: undefined,
  onlyWhiteCost: true,
  onePerUser: true,
};

export const PromocodeForm: React.FC<IPromocodeFormProps> = (props) => {
  const { tariffStore, studentsStore, promocodeStore } = useStore();
  const [formValue, setFormValue] = useState<IFormPromocode>(defaultPromocode);
  const [validationResult, setValidationResult] = useState<ValidationResult>();

  useEffect(() => {
    const promocode = promocodeStore.find(props.promocodeId);
    if (!promocode) {
      return;
    }
    const formByPromocodeId: IFormPromocode = {
      id: promocode.id,
      code: promocode.code,
      saleType: promocode.saleType,
      saleCost: promocode.saleCost,
      dateBegin: new Date(promocode.dateBegin),
      dateEnd: new Date(promocode.dateEnd),
      tariffId: promocode.rule.tariffId,
      studentId: promocode.rule.studentId,
      onlyWhiteCost: promocode.rule.onlyWhiteCost,
      onePerUser: promocode.rule.onePerUser,
    };
    setFormValue(formByPromocodeId);
  }, [props.promocodeId]);

  const selectTarif = tariffStore.data.map((x) => ({
    label: x.title,
    value: x.id,
  }));
  const studentList = studentsStore.data.map((x) => ({
    label: TextHelper.GetShortName(x.user),
    value: x.id,
  }));
  const saleType = EnumConverter.mapEnum(SaleType, EnumConverter.saleTypeToStr);
  const isPercent = formValue.saleType === SaleType.Percent;

  const hanbdleOnSubmit = async () => {
    const resultValidation = ValidationHelper.promocodeValidate(formValue);

    setValidationResult(resultValidation);
    if (!resultValidation.hasNotError()) {
      return;
    }
    const promocodeRule: IPromocodeRule = {
      tariffId: formValue.tariffId || undefined,
      studentId: formValue.studentId || undefined,
      onlyWhiteCost: formValue.onlyWhiteCost,
      onePerUser: formValue.onePerUser,
    };
    const promocode: IPromocode = {
      id: formValue.id || 0,
      code: formValue.code,
      saleType: formValue.saleType,
      saleCost: formValue.saleCost,
      dateBegin: formValue.dateBegin,
      dateEnd: formValue.dateEnd,
      rule: promocodeRule,
    };
    if (await await promocodeStore.save(promocode)) {
      props.onClose();
    }
  };
  return (
    <Form
      style={{ margin: 5 }}
      formValue={formValue}
      onChange={(formValue: unknown) => {
        setFormValue(formValue as IFormPromocode);
      }}
      onSubmit={hanbdleOnSubmit}
    >
      <Form.Group controlId="code">
        <Form.ControlLabel>Код-слово</Form.ControlLabel>
        <Form.Control
          name="code"
          accepter={Input}
          errorMessage={validationResult?.getError("code")}
        />
        <Form.HelpText tooltip>Промокод для ввода студентами</Form.HelpText>
      </Form.Group>
      <Form.Group controlId="saleType">
        <Form.ControlLabel>Тип промокода</Form.ControlLabel>
        <Form.Control
          style={{ width: 224 }}
          name="saleType"
          accepter={InputPicker}
          data={saleType}
          errorMessage={validationResult?.getError("saleType")}
        />
      </Form.Group>
      <Form.Group controlId="saleCost">
        <Form.ControlLabel>{`${
          isPercent ? "%" : "Cумма"
        } скидки`}</Form.ControlLabel>
        <Form.Control
          style={{ width: 224 }}
          name="saleCost"
          postfix={isPercent ? "%" : "₽"}
          accepter={InputNumber}
          min={0}
          max={isPercent ? 100 : 5000}
          errorMessage={validationResult?.getError("saleCost")}
        />
      </Form.Group>
      <Form.Group controlId="dateBegin">
        <Form.ControlLabel>Дата начала действия промокода</Form.ControlLabel>
        <Form.Control
          name="dateBegin"
          accepter={DatePicker}
          style={{ width: 224 }}
          placeholder="Дата"
          format="dd-MM-yyyy HH:mm"
          errorMessage={validationResult?.getError("dateBegin")}
        />
      </Form.Group>
      <Form.Group controlId="dateEnd">
        <Form.ControlLabel>Дата окончания действия промокода</Form.ControlLabel>
        <Form.Control
          name="dateEnd"
          accepter={DatePicker}
          style={{ width: 224 }}
          placeholder="Дата"
          format="dd-MM-yyyy HH:mm"
          errorMessage={validationResult?.getError("dateEnd")}
        />
      </Form.Group>
      <Form.Group controlId="tariffId">
        <Form.ControlLabel>Курс</Form.ControlLabel>
        <Form.Control
          style={{ width: 224 }}
          name="tariffId"
          accepter={SelectPicker}
          data={selectTarif}
        />
        <Form.HelpText tooltip>
          Если указан курс - промокод на конкретный курс.
          <br /> Иначе - на всю корзину.
        </Form.HelpText>
      </Form.Group>
      <Form.Group controlId="studentId">
        <Form.ControlLabel>Ученик</Form.ControlLabel>
        <Form.Control
          style={{ width: 224 }}
          name="studentId"
          accepter={SelectPicker}
          data={studentList}
        />
        <Form.HelpText tooltip>
          Если указан ученик - промокод персональный.
          <br /> Иначе - для всех.
        </Form.HelpText>
      </Form.Group>
      <Form.Group controlId="onlyWhiteCost">
        <Form.Control
          style={{ width: 224 }}
          name="onlyWhiteCost"
          accepter={Toggle}
          data={studentList}
          checkedChildren='Только "белая" цена'
          unCheckedChildren="Акции суммируются"
        />
        <Form.HelpText tooltip>
          <b>Только "белая" цена</b> - промокод не будет работать, если на курс
          уже действует скидка.
          <br />
          <b>Акции суммируются</b> - промокод будет применен к курсу со скидкой.
          Промокод будет рассчитан от цены с учетом глобальной скидки на курс.
        </Form.HelpText>
      </Form.Group>
      <Form.Group controlId="onePerUser">
        <Form.Control
          style={{ width: 224 }}
          name="onePerUser"
          accepter={Toggle}
          data={studentList}
          checkedChildren="Одноразовый"
          unCheckedChildren="Многоразовый"
        />
        <Form.HelpText tooltip>
          <b>Одноразовый</b> - действует 1 раз на аккаунт.
          <br />
          <b>Многоразовым</b> промокодом каждый ученик сможет пользоваться
          неограниченное количество раз, пока не закончится срок действия.
        </Form.HelpText>
      </Form.Group>

      <Form.Group>
        <ButtonToolbar>
          <Button
            appearance="primary"
            type="submit"
            loading={promocodeStore.onSaving}
            disabled={promocodeStore.onSaving}
          >
            Сохранить
          </Button>
          <Button
            disabled={promocodeStore.onSaving}
            appearance="default"
            onClick={() => {
              props.onClose();
            }}
          >
            Отмена
          </Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  );
};
