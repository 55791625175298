import { IAuth } from "stores/models/IAuth";
import { IUserFullData, IUserRegistration } from "../../stores/models";
import { get, post } from "../WebHelper";

export class UserController {
  public async GetAll(): Promise<IUserFullData[] | undefined> {
    return await get("/User/GetAll");
  }

  public async Auth(
    login: string,
    psw: string
  ): Promise<IAuth | undefined> {
    return await get("/User/Auth", { params:{login, psw} });
  }
  public async Logout() {
    await post("/User/Logout");
  }
  public async Recovery(login: string) {
    await get("/User/Recovery", { params:{login} });
  }
  public async Registration(data: IUserRegistration) {
    await post("/User/Registration", data);
  }
  public async Confirm(token: string) {
    await get("/User/ConfirmEmail", { params:{token} });
  }
  public async ConfirmById(userId: number) {
    await post(`/User/ConfirmEmail/${userId}`);
  }
}
