import React, { useState } from "react";
import { Drawer, Modal, Stack, Whisper } from "rsuite";
import { FormGlobalNotification } from "./FormGlobalNotification";
import InfoRoundIcon from "@rsuite/icons/InfoRound";
import { GlobalNotificationCreationRules } from "components";
import { Tooltip } from "shared/ui";

interface IGlobalNotificationFormModalProps {
  globalNotificationId?: number;
  isOpen: boolean;
  onClose: () => void;
}

export const ModalFormGlobalNotification: React.FC<
  IGlobalNotificationFormModalProps
> = ({ globalNotificationId, isOpen, onClose }) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Drawer size="md" open={open} onClose={() => setOpen(false)}>
        <Drawer.Header>
          <Drawer.Title>Как создавать глобальные уведомления</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <GlobalNotificationCreationRules />
        </Drawer.Body>
      </Drawer>
      <Modal
        backdrop="static"
        open={isOpen}
        onClose={() => onClose()}
        size="sm"
      >
        <Modal.Header>
          <Modal.Title>
            <Stack spacing={6}>
              {globalNotificationId && globalNotificationId > 0
                ? "Редактирование глобального уведомления"
                : "Создание глобального уведомления"}
              <Whisper
                placement="auto"
                speaker={<Tooltip>Посмотреть примеры</Tooltip>}
              >
                <span>
                  <InfoRoundIcon onClick={() => setOpen(true)} />
                </span>
              </Whisper>
            </Stack>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <FormGlobalNotification
            globalNotificationId={globalNotificationId}
            onClose={onClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
