import { EmptyStatePanel } from "components";
import { useStore } from "hooks";
import _ from "lodash";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { CheckHomeworkType, ILesson } from "stores";
import { PlaceholderLessonList } from "../components";
import { LessonCol } from "./LessonCol";
import { LessonsListItem } from "./LessonsListItem";
import { Grid, Row } from "shared/ui";

const LessonListComponent: React.FC = () => {
  const { lessonsStore, tariffStore } = useStore();

  const [selectedTariffId, setSelectedTariffId] = useState<number>();
  const [lessons, setLessons] = useState<ILesson[]>([]);

  useEffect(() => {
    setSelectedTariffId(tariffStore.selectedTariff?.id);
  }, [tariffStore.selectedTariff]);

  useEffect(() => {
    if (lessonsStore.onLoading) {
      setLessons([]);
      return;
    }
    setLessons(getLessonsAndEnabledModels());
  }, [lessonsStore.onLoading, lessonsStore.publishedLessons, selectedTariffId]);

  const filterLessonsByTariff = (lessons: ILesson[]): ILesson[] => {
    const filteredLessons = lessons.filter(
      (x) => x.tariffId === selectedTariffId
    );
    return filteredLessons;
  };

  const convertEnabledLessonsInPaymentModel = (
    lessons: ILesson[]
  ): ILesson[] => {
    const disabledLessons = lessons.filter(
      (x) => !x.enabled && x.monthNumber !== null
    );
    const uniqueLessons = _.uniqBy(disabledLessons, "monthNumber");
    return uniqueLessons.map((x) => ({
      ...x,
      title: `Уроки месяца № ${x.monthNumber}`,
      description: "",
      checkHomeworkType: CheckHomeworkType.Manual,
    }));
  };

  const getLessonsAndEnabledModels = (): ILesson[] => {
    let lessonList = lessonsStore.publishedLessons;
    if (selectedTariffId !== 0) {
      lessonList = filterLessonsByTariff(lessonList);
    }
    const newLessons = lessonList
      .filter((x) => x.enabled)
      .concat(convertEnabledLessonsInPaymentModel(lessonList));
    return newLessons;
  };

  if (lessonsStore.onLoading || tariffStore.onLoading) {
    return <PlaceholderLessonList />;
  }

  if (!lessons || lessons.length === 0) {
    return <EmptyStatePanel />;
  }

  return (
    <Grid fluid>
      <Row className="show-grid">
        {lessons
          .slice()
          .sort(
            (a, b) =>
              new Date(b.dateTime).getTime() - new Date(a.dateTime).getTime()
          )
          .map((x) => (
            <LessonCol key={x.id}>
              <LessonsListItem lesson={x} />
            </LessonCol>
          ))}
      </Row>
    </Grid>
  );
};
export const LessonList = observer(LessonListComponent);
