import { TitlePlaceholder } from "components/Checks/Table/Cell/TitlePlaceholder";
import { observer } from "mobx-react";
import { Stack } from "rsuite";
import { IHomeworkCheck } from "stores";
import { useStudentHomeworkInfo } from "./useStudentHomeworkInfo";

type IStudentHomeworkSwitchButtonProps = {
  studentHomework: IHomeworkCheck;
};

const StudentHomeworkInfoComponent: React.FC<
  IStudentHomeworkSwitchButtonProps
> = ({ studentHomework }: IStudentHomeworkSwitchButtonProps) => {
  const { isHomeworkNotSend, dateTitle, dateEmoji, isHomeworkChecked } =
    useStudentHomeworkInfo(studentHomework);

  if (isHomeworkNotSend) {
    return <p>Домашка не сдана</p>;
  }

  return (
    <>
      {isHomeworkChecked ? (
        <p>
          <b>Балл:</b> {`${studentHomework.score}/${studentHomework.maxScore}`}
        </p>
      ) : (
        ""
      )}
      <div>
        <Stack spacing={6} style={{ minHeight: 26 }}>
          <b>Дата сдачи:</b>
          {dateTitle && dateEmoji ? (
            <>
              {dateTitle}
              {dateEmoji}
            </>
          ) : (
            <TitlePlaceholder />
          )}
        </Stack>
      </div>
    </>
  );
};

export const StudentHomeworkInfo = observer(StudentHomeworkInfoComponent);
