import { GeneralMenuItems } from "./GeneralMenuItems";
import { IMenuItem } from "./IMenuItem";

export const TeacherMenu = () => {
  const menuItems: IMenuItem[] = [];
  menuItems.push(GeneralMenuItems.Home);
  menuItems.push(GeneralMenuItems.StudentDropdown);
  menuItems.push(GeneralMenuItems.Lessons);
  menuItems.push(GeneralMenuItems.Schedule);
  menuItems.push(GeneralMenuItems.TasksDropdown);

  return menuItems;
};
