import { observer } from "mobx-react";
import React, { useState } from "react";
import { HelpfulModal } from "./HelpfulModal";
import { MentorInfo } from "./MentorInfo";
import { TeacherInfo } from "./TeacherInfo";
import { LayoutPanelWithButton } from "../Layout/LayoutPanelWithButton";
import { Button, Divider, Panel } from "shared/ui";

const HelpfulInformationComponent: React.FC = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <HelpfulModal isOpen={open} onClose={() => setOpen(false)} />
      <LayoutPanelWithButton
        button={<Button onClick={() => setOpen(true)}>Все полезности</Button>}
      >
        <Panel bordered style={{ height: 180 }}>
          <div style={{ marginTop: "-6px" }}>
            <TeacherInfo />
            <Divider style={{ marginTop: 12, marginBottom: 12 }} />
            <MentorInfo />
          </div>
        </Panel>
      </LayoutPanelWithButton>
    </>
  );
};
export const HelpfulInformation = observer(HelpfulInformationComponent);
