import { Route } from "react-router-dom";
import { ContentContainer } from "../containers";
import { IRoute } from "./IRoute";
import { RoleNames } from "stores";
import {
  AdminRoutes,
  MentorRoutes,
  StudentRoutes,
  SuperRoutes,
  TeacherRoutes,
} from ".";
import { AnonymousRoutes } from "./AnonymousRoutes";

export const getRoutes = (role?: RoleNames) => {
  let routes: IRoute[] = [];
  switch (role) {
    case RoleNames.Super:
      routes = SuperRoutes();
      break;
    case RoleNames.Admin:
      routes = AdminRoutes();
      break;
    case RoleNames.Teacher:
      routes = TeacherRoutes();
      break;
    case RoleNames.Mentor:
      routes = MentorRoutes();
      break;
    case RoleNames.Student:
      routes = StudentRoutes();
      break;
    default:
      routes = AnonymousRoutes();
      break;
  }
  return mapRoutes(routes);
};

function mapRoutes(routes: IRoute[]) {
  return routes.map((x) => {
    return (
      <Route
        path={x.path}
        key={x.path}
        element={
          <ContentContainer title={x.title} hideTitle={x.hideTitle}>
            {x.element}
          </ContentContainer>
        }
      />
    );
  });
}
