import { PaymentType, SaleType } from ".";

export interface IPayment {
  // Базовая стоимость корзины
  baseAmount: number;
  // Сумма к оплате корзины
  factAmount: number;
  //описание ошибки, если промокод не прошел
  promocodeError?: string;
  promotions: IPromotionByPayment[];
  tariffs: IPaymentTariffs[];
}

export interface IPromotionByPayment {
  //сумма скидки в рублях
  discount: number;
  //Флаг промокод
  isPromocode: boolean;
  //Название
  name: string;
  promocodeId: number;
  //размер % (или фикс)
  sale: number;
  //к какому тарифу применяем
  tariffId: number;
  //% или фикс
  type: SaleType;
}

export interface IPaymentTariffs {
  // Предмет
  subjectId: number;
  // Курс
  tariffId: number;
  // Базовая стоимость курса
  baseAmount: number;
  // Сумма к оплате
  factAmount: number;
  // Месяца к оплате
  monthNumbers: number[];
  // Тип платежа
  paymentType: PaymentType;
  // Описание оплаты
  description: string;
}

export interface IPayOrder {
  tariffs: IPayOrderTariff[];
  promocode?: string;
}

export interface IPayOrderTariff {
  tariffId: number;
  type: PaymentType;
  monthNumbers: number[];
  fullPay: boolean;
}

export enum StatusPaymentType {
  // Оплата прошла успешно
  Success = 3,
  // Платёж не прошёл
  Failed = 4,
}

export type TOrder = {
  dateCreate: Date;
  amount: number;
  paymentId: string;
  paymentUrl: string;
  studentId: number;
  promocode: string;
  tariffItems: TOrderTariffItem[];
  status: EOrderPaymentStatus;
};

export type TOrderTariffItem = {
  tariffId: number;
  monthNumber: number;
  paymentType: PaymentType;
};

export enum EOrderPaymentStatus {
  // Ожидание
  Pending = 1,
  /// Ожидается снятие
  WaitingForCapture = 2,
  /// Успех
  Succeeded = 3,
  /// Отмена
  Canceled = 4,
}
