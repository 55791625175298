import React from "react";
import { Whisper } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { Pill, Tooltip } from "shared/ui";

interface IStatusSendedBtnProps {
  homeworkStatus?: boolean;
}

export const StatusSendedBtnComponent: React.FC<IStatusSendedBtnProps> = (
  props
) => {
  let goodOrBadTitleTooltip = "";
  let goodOrBadTitleBtn = "";
  let goodOrBadColorBtn: TypeAttributes.Color;

  if (!props.homeworkStatus) {
    goodOrBadTitleTooltip =
      "В топе домашка будет засчитана на 0 баллов, так как сдача после дедлайна 😢";
    goodOrBadTitleBtn = "0 баллов";
    goodOrBadColorBtn = "red";
  } else {
    goodOrBadTitleTooltip =
      "Каждая вовремя сданная домашка приближает тебя к цели 🤘🏻";
    goodOrBadTitleBtn = "круто!";
    goodOrBadColorBtn = "blue";
  }

  return (
    <Whisper
      trigger="hover"
      speaker={<Tooltip>{goodOrBadTitleTooltip}</Tooltip>}
    >
      <Pill color={goodOrBadColorBtn}>{goodOrBadTitleBtn}</Pill>
    </Whisper>
  );
};
