import { requirements, MIN_PASSWORD_LENGTH } from "./components";

export function getStrength(password: string) {
  let multiplier = password.length >= MIN_PASSWORD_LENGTH ? 0 : 1;

  requirements.forEach((requirement) => {
    if (!requirement.re.test(password)) {
      multiplier += 1;
    }
  });

  return Math.max(100 - (100 / (requirements.length + 1)) * multiplier, 0);
}
