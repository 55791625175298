import { TaskType } from "./ITask";

export interface IFileLesson {
  fileId: number;
  fileName?: string;
  dateCreate?: Date;
  url?: string;
  type: FileLessonType;
}

export interface IFileAnswer {
  fileId?: number;
  taskId?: number;
  type?: TaskType;
  fileName?: string;
}

export enum FileLessonType {
  // Обложка урока
  MainImage = 1,

  // Постер для вебинара
  WebinarImage = 2,

  // Видео вебинара
  WebinarVideo = 3,

  // Скрипты и прочее
  Material = 4,

  // Критерии для заданий
  Criteria = 5,
}
