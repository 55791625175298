import { CheckReadonlyCell } from "components";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import { Table } from "rsuite";
import { IStudentTariffWithPayments } from "stores";
import { ActionCell } from "./Cell/ActionCell";
import { LastPaymentCell } from "./Cell/LastPaymentCell";
import { MentorCell } from "./Cell/MentorCell";
import { StudentCell } from "./Cell/StudentCell";
import { TariffCell } from "./Cell/TariffCell";
import { Panel } from "shared/ui";

type TStudentTariffTableProps = { data?: IStudentTariffWithPayments[] };

const StudentTariffTableComponent: React.FC<TStudentTariffTableProps> = ({
  data,
}) => {
  const { Column, HeaderCell } = Table;

  const { tariffStore, studentTariffStore, studentsStore } = useStore();

  const isLoading =
    studentTariffStore.onLoading ||
    studentsStore.onLoading ||
    tariffStore.onLoading;

  return (
    <Panel
      bordered
      bodyFill
      style={{ marginTop: 15, maxHeight: "60vh", overflow: "auto" }}
    >
      <Table rowKey="id" autoHeight loading={isLoading} data={data}>
        <Column minWidth={150} flexGrow={3} fullText>
          <HeaderCell>Курс</HeaderCell>
          <TariffCell dataKey="tariffId" />
        </Column>
        <Column minWidth={100} flexGrow={2} fullText>
          <HeaderCell>Студент</HeaderCell>
          <StudentCell dataKey="studentId" />
        </Column>
        <Column minWidth={100} flexGrow={2} fullText>
          <HeaderCell>Ментор</HeaderCell>
          <MentorCell dataKey="mentorId" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Оплачено</HeaderCell>
          <CheckReadonlyCell dataKey="enabled" />
        </Column>
        <Column flexGrow={1}>
          <HeaderCell>Оплачено до</HeaderCell>
          <LastPaymentCell dataKey="payments" />
        </Column>
        <Column width={180} align="center">
          <HeaderCell> </HeaderCell>
          <ActionCell dataKey="id" />
        </Column>
      </Table>
    </Panel>
  );
};
export const StudentTariffTable = observer(StudentTariffTableComponent);
