import { EmptyStatePanel } from "components";
import React from "react";
import { Link } from "react-router-dom";
import { Button } from "shared/ui";

export const LessonNotFound: React.FC = () => (
  <EmptyStatePanel>
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      <h2>Упс...</h2>
      <img
        src={`${process.env.PUBLIC_URL}/images/lessonNotFound.jpg`}
        style={{ width: "50%", height: "auto" }}
      ></img>
      <h3>Урок не найден</h3>
      <p>Похоже, такого урока нет...попробуйте посмотреть ещё</p>
      <Button
        appearance="primary"
        style={{ margin: 20 }}
        as={Link}
        to="/lessons"
      >
        К урокам
      </Button>
    </div>
  </EmptyStatePanel>
);
