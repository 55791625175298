import { useIsMounted, useStore } from "hooks";
import { ReactNode, useEffect, useState } from "react";
import { IRequestGetLessonsByFilter, LessonsOrder } from "root";
import { ItemDataType } from "rsuite/esm/@types/common";
import { ListProps } from "rsuite/esm/Windowing";
import { ILesson, IPage } from "stores";
import { RenderMenu } from "./RenderMenu";

const defaultFilter: IRequestGetLessonsByFilter = {
  count: 12,
  orderBy: LessonsOrder.DateTimeDesc,
  isPassed: true,
};

export const useSelectPickerLessonId = (
  defaultValue?: number | null,
  tariffId?: number
) => {
  const isMounted = useIsMounted();

  const { lessonsStore } = useStore();
  const [lessons, setLessons] = useState<ItemDataType<number>[]>([]);
  const [activePage, setActivePage] = useState(0);
  const [isLoading, setIsLoading] = useState(false);
  const [hasNextPage, setHasNextPage] = useState(true);

  useEffect(() => {
    const foundLessonId = lessons.find((x) => x.value === defaultValue)?.value;
    if (foundLessonId || !defaultValue || lessonsStore.onLoading) {
      return;
    }
    lessonsStore.findOrLoad(defaultValue).then((res) => {
      if (!res || !isMounted()) {
        return;
      }
      const formattedLessons = formatterLessons([res]);
      setLessons((prev) => prev.concat(formattedLessons));
    });
  }, [defaultValue]);

  useEffect(() => {
    setLessons([]);
    setHasNextPage(false);
    setActivePage(0);
    fetchData({ ...defaultFilter, tariffId });
  }, [tariffId]);

  const formatterLessons = (
    lessons: ILesson[] | undefined
  ): ItemDataType<number>[] =>
    lessons
      ?.filter((x) => !tariffId || x.tariffId === tariffId)
      .map((x) => ({
        label: x.title,
        value: x.id,
      })) || [];

  const load = async (
    filter: IRequestGetLessonsByFilter
  ): Promise<IPage<ILesson> | undefined> => {
    setIsLoading(true);
    const result = await lessonsStore.loadByFilter(filter);
    isMounted() && setIsLoading(false);
    return result;
  };

  const loadMore = (filter: Partial<IRequestGetLessonsByFilter>) => {
    if (isLoading || !hasNextPage) {
      return;
    }

    const currentFilter: IRequestGetLessonsByFilter = {
      tariffId: tariffId,
      ...defaultFilter,
      ...filter,
    };
    fetchData(currentFilter);
  };

  const fetchData = (filter: IRequestGetLessonsByFilter) => {
    load(filter).then((res) => {
      if (!res || !isMounted()) {
        return;
      }
      const formattedLessons = formatterLessons(res.data);
      setLessons((prev) => prev.concat(formattedLessons));
      setActivePage(res.currentPage);
      setHasNextPage(res.hasNextPage);
    });
  };
  const listProps: Partial<ListProps> = {
    onItemsRendered({ visibleStopIndex }) {
      if (visibleStopIndex >= lessons.length - 1) {
        loadMore({ page: activePage + 1 });
      }
    },
  };

  const renderMenu = (menu: ReactNode) => (
    <RenderMenu menu={menu} loading={isLoading} />
  );

  return { isLoading, lessons, listProps, renderMenu };
};
