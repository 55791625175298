import { ITaskPack, TaskType } from "stores";
import { IAnswerHomework, IRequestAnswerHomework } from "./requests";
import { isString } from "lodash";
type PropertyMap = {
  [K in Exclude<TaskType, TaskType.FileQuestion>]: keyof IAnswerHomework;
};
const propertyMap: PropertyMap = {
  [TaskType.FirstPart]: "answerPart1",
  [TaskType.SecondPart]: "answerPart2",
  [TaskType.FileAnswer]: "answerFileList",
};

export class AnswerHomeworkHelper {
  /**
   * @description Конвертирует FormData собранную из ответов в IRequestAnswerHomework[]
   *
   *@param formData FormData собранная из ответов
   *@param taskPack Вариант со всеми вопросами из FormData
   *
   * @returns Возвращает IRequestAnswerHomework[].
   */

  public static convertToRequestAnswerHomeworkList(
    formData: FormData,
    taskPack: ITaskPack
  ): IRequestAnswerHomework[] {
    const dataArray: IRequestAnswerHomework[] = [];

    Array.from(formData.entries()).forEach(([key, value]) => {
      const taskId = Number(key);
      if (isNaN(taskId)) {
        return;
      }

      const task = taskPack.tasks.find((x) => x.id === taskId);

      switch (task?.type) {
        case TaskType.FirstPart: {
          if (!isString(value) || value.length < 1) {
            dataArray.push({
              taskId,
              answerPart1: null,
            });
            return;
          }
          const numericValue = Number(value);
          const parsedValue = isNaN(numericValue) ? null : numericValue;
          dataArray.push({
            taskId,
            answerPart1: parsedValue,
          });
          break;
        }
        case TaskType.SecondPart: {
          if (!isString(value)) {
            return;
          }
          const answerPart2 = value;
          dataArray.push({ taskId, answerPart2 });
          break;
        }
        case TaskType.FileAnswer:
          {
            if (isString(value)) {
              const valueNoSpace = value.replace(/\s/g, "");
              const regex = /[^,\d]/g;
              if (regex.test(valueNoSpace) || value.length < 1) {
                dataArray.push({ taskId, answerFileIdList: [] });
                return;
              }
              const answerFileIdList = valueNoSpace
                .split(",")
                .map(Number)
                .filter((num) => !isNaN(num));

              dataArray.push({ taskId, answerFileIdList });
            }
          }
          break;

        default:
          break;
      }
    });

    return dataArray;
  }

  public static removeEmptyAnswer(
    answers: IAnswerHomework[],
    taskId: number
  ): IAnswerHomework[] {
    const newAnswers = answers.filter((x) => x.taskId !== taskId);
    return newAnswers;
  }

  public static createAnswer(
    result: unknown,
    lessonId: number,
    taskPackId: number,
    homeWorkId: number,
    taskId: number,
    property: keyof IAnswerHomework
  ): IAnswerHomework[] {
    const newAnswer: IAnswerHomework = {
      lessonId,
      taskPackId,
      homeWorkId,
      taskId,
      [property]: result,
    };
    const newAnswers = [newAnswer];
    return newAnswers;
  }

  public static updateAnswerFileList(
    result: unknown,
    taskId: number,
    taskPackId: number,
    homeWorkId: number,
    answers: IAnswerHomework[]
  ): IAnswerHomework[] {
    if (!Array.isArray(result)) {
      return answers;
    }
    const answerToUpdate = answers.find(
      (answer) =>
        answer.taskId === taskId &&
        answer.homeWorkId === homeWorkId &&
        answer.taskPackId === taskPackId
    );
    if (!answerToUpdate) {
      return answers;
    }

    const actualAnswerFileList = answerToUpdate.answerFileList || [];
    const updateAnswerHomework: IAnswerHomework = {
      ...answerToUpdate,
      answerFileList: actualAnswerFileList.concat(result),
    };

    const updatedAnswers = answers.map((answer) =>
      answer === answerToUpdate ? updateAnswerHomework : answer
    );
    return updatedAnswers;
  }

  public static convertToAnswerHomeworkList(
    result: unknown,
    taskId: number,
    taskType: Exclude<TaskType, TaskType.FileQuestion>,
    lessonId: number,
    taskPackId: number,
    homeWorkId: number,
    answers: IAnswerHomework[]
  ): IAnswerHomework[] | undefined {
    const actualAnswers = answers.filter(
      (x) => x.homeWorkId === homeWorkId && x.taskPackId === taskPackId
    );
    if (
      (Array.isArray(result) || typeof result === "string") &&
      result.length === 0
    ) {
      return this.removeEmptyAnswer(answers, taskId);
    }

    const property: keyof IAnswerHomework = propertyMap[taskType];

    if (actualAnswers.length === 0) {
      return this.createAnswer(
        result,
        lessonId,
        taskPackId,
        homeWorkId,
        taskId,
        property
      );
    }

    const taskExists = answers.some((answer) => answer.taskId === taskId);

    if (!taskExists) {
      const newAnswer = this.createAnswer(
        result,
        lessonId,
        taskPackId,
        homeWorkId,
        taskId,
        property
      );
      const newAnswers = answers.concat(newAnswer);
      return newAnswers;
    }

    const isAnswerFileList = property == "answerFileList";
    if (!isAnswerFileList) {
      const newAnswers = answers.map((x) =>
        x.taskId === taskId &&
        x.homeWorkId === homeWorkId &&
        x.taskPackId === taskPackId
          ? { ...x, [property]: result }
          : x
      );
      return newAnswers;
    }

    if (!Array.isArray(result)) {
      return answers;
    }
    const answerToUpdate = answers.find(
      (answer) =>
        answer.taskId === taskId &&
        answer.homeWorkId === homeWorkId &&
        answer.taskPackId === taskPackId
    );
    if (!answerToUpdate) {
      return answers;
    }

    const updateAnswerFileList = this.updateAnswerFileList(
      result,
      taskId,
      taskPackId,
      homeWorkId,
      answers
    );

    return updateAnswerFileList;
  }
}
