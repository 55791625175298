import { useFormikContext } from "formik";
import { Loader } from "shared/ui";
/**
 *  Оверлей формы вовремя загрузки. (formik.isSubmitting = true)
 */
export type FormOverlayProps = {
  isLoading: boolean;
};

export default function FormOverlay({ isLoading }: FormOverlayProps) {
  const ctx = useFormikContext();
  if (!ctx.isSubmitting && !isLoading) {
    return null;
  }
  return (
    <Loader backdrop content="Загрузка..." vertical style={{ zIndex: 9999 }} />
  );
}
