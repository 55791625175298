import { Placeholder } from "rsuite";
import { Panel } from "shared/ui";

interface IPlaceholderCatalogTariffListProps {
  items: number;
}
export const PlaceholderCatalogTariffList: React.FC<
  IPlaceholderCatalogTariffListProps
> = ({ items }) => {
  const fakeArray = Array(items).fill(0);
  return (
    <>
      {fakeArray.map((x, i) => (
        <Panel bordered style={{ width: "100%", marginBottom: 10 }} key={i}>
          <Placeholder.Paragraph
            style={{ marginTop: 30 }}
            rows={5}
            graph="image"
            active
          />
        </Panel>
      ))}
    </>
  );
};
