export interface IRegToken {
  id: number;
  //GUID
  token: string;
  //ID курса
  objectId: number;
  //Тип доступа
  type: RegTokenType;
}

export enum RegTokenType {
  // Курс, без оплат
  Tariff = 1,
  // Курс, все месяца оплачены
  TariffFull = 2,
}
