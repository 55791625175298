import { DividerProps } from "./types";

export const Divider: React.FC<DividerProps> = ({
  label,
  labelPosition = "center",
  orientation = "horizontal",
  ...props
}) => {
  const isVertical = orientation === "vertical";

  return (
    <div
      {...props}
      data-position={labelPosition}
      className={`wavege-divider-${orientation}`}
    >
      {!isVertical && label && <span style={{ padding: 10 }}>{label}</span>}
    </div>
  );
};
