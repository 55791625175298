import { makeAutoObservable, runInAction } from "mobx";
import { LogHelper, NotificationHelper, localStorageWrapper } from "../root";
import Api from "../root/Api";
import { cookies } from "../root/CookieHelper";
import {
  IStudentTariffWithPayments,
  IStudentWithPayments,
  IUserFullData,
  IUserRegistration,
  PaymentType,
  TStudentMonthNumbersVideoLessons,
} from "./models";
import { IAuth } from "./models/IAuth";

const cookieName = "auth-data";
const localStorageName = "wavege-student-tariff-with-payments";

const defaultCookieOptions = {
  maxAge: 8 * 24 * 60 * 60,
  path: "/",
};

interface IFunction {
  action(): void;
}

export class AuthStore {
  me?: IUserFullData = undefined;
  myStudentData?: IStudentWithPayments = undefined;
  myMonthNumbersVideoLessons: TStudentMonthNumbersVideoLessons[] = [];
  onLoading = false;
  private actionAfterAuth?: IFunction = undefined;

  constructor() {
    makeAutoObservable(this);

    try {
      const authData = cookies.get<IAuth>(cookieName);

      const storedMonthNumbersVideoLessons =
        localStorage.getItem(localStorageName);

      const parsedMonthNumbersVideoLessons: IStudentTariffWithPayments[] =
        storedMonthNumbersVideoLessons
          ? JSON.parse(storedMonthNumbersVideoLessons)
          : [];

      if (parsedMonthNumbersVideoLessons) {
        this.setMyMonthNumbersVideoLessons(parsedMonthNumbersVideoLessons);
      }

      if (authData && authData.studentData) {
        authData.studentData.tariffs = parsedMonthNumbersVideoLessons;
      }

      if (authData) {
        runInAction(() => {
          this.me = authData.userData;
          this.myStudentData = authData.studentData;
        });
      }
    } catch (error: any) {
      console.error(error);
      this.logout();
      LogHelper.LogFatalToBackend(error, "", "");
    }
  }

  setActionAfterAuth(action: IFunction) {
    this.actionAfterAuth = action;
    if (this.me?.id !== undefined) {
      this.actionAfterAuth.action();
    }
  }

  public async logout() {
    Api.users.Logout();
    cookies.remove(cookieName, defaultCookieOptions);
  }

  public async auth(login: string, rawPassword: string) {
    this.setOnLoading(true);
    try {
      const hash = rawPassword;
      const result = await Api.users.Auth(login, hash);

      this.setCookiesAuthData(result);
      runInAction(() => {
        this.me = result?.userData;
        this.myStudentData = result?.studentData;
      });
      this.setMyMonthNumbersVideoLessons(result?.studentData?.tariffs);

      this.actionAfterAuth?.action();
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
    return null;
  }

  public async recovery(login: string): Promise<boolean> {
    this.setOnLoading(true);
    try {
      await Api.users.Recovery(login);
      NotificationHelper.ShowSuccess(
        "Письмо с инструкцией отправлено вам на почту"
      );
      return true;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
    return false;
  }

  public async register(model: IUserRegistration): Promise<boolean> {
    this.setOnLoading(true);
    try {
      await Api.users.Registration(model);
      NotificationHelper.ShowSuccess(
        "Для завершения регистрации вам необходимо подтвердить адрес электронной почты. Письмо с инструкцией отправлено вам на почту.",
        "Успех!",
        60 * 60 * 1000
      );
      return true;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
    return false;
  }

  public updateUser(
    userFullData: IUserFullData,
    studentData: IStudentWithPayments
  ) {
    userFullData.token = userFullData.token || this.me?.token || "";
    const myStudentData = { ...studentData, user: userFullData };
    runInAction(() => {
      this.me = userFullData;
      this.myStudentData = myStudentData;
    });

    this.setCookiesAuthData({
      userData: userFullData,
      studentData: myStudentData,
    });
  }

  private setMyMonthNumbersVideoLessons(
    tariffs: IStudentTariffWithPayments[] | undefined
  ) {
    const myMonthNumbersVideoLessons = tariffs
      ? tariffs
          .flatMap((tariff) =>
            tariff.payments.filter(
              (payment) => payment.type === PaymentType.VideoLessons
            )
          )
          .map((payment) => ({
            monthNumber: payment.monthNumber || 0,
            tariffId: payment.tariffId,
          }))
      : [];

    runInAction(() => {
      this.myMonthNumbersVideoLessons = myMonthNumbersVideoLessons;
    });
  }

  private setOnLoading(value: boolean) {
    this.onLoading = value;
  }

  // TODO нужно начать сохранять в indexDB
  private setCookiesAuthData(cookieData: IAuth | undefined) {
    if (!cookieData) {
      this.logout();
      return;
    }

    if (cookieData.studentData && cookieData.studentData.tariffs) {
      localStorageWrapper.set(localStorageName, cookieData.studentData.tariffs);
      cookieData.studentData.tariffs = [];
    }

    cookies.set(cookieName, cookieData, defaultCookieOptions);
  }

  public clear() {
    runInAction(() => {
      this.me = undefined;
      this.myStudentData = undefined;
      this.myMonthNumbersVideoLessons = [];
    });
  }
}
export const authStore = new AuthStore();
