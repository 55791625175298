import { useCallback, useLayoutEffect, useRef } from "react";

export function useEvent<T extends (...arg: any[]) => any>(fn: T) {
  const fnRef = useRef(fn);

  useLayoutEffect(() => {
    fnRef.current = fn;
  }, [fn]);
  
  const eventCb = useCallback(
    (...arg: Parameters<T>) => {
      return fnRef.current.apply(null, arg);
    },
    [fnRef]
  );
  return eventCb;
}
