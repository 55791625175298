import { makeAutoObservable } from "mobx";

export class UIStore {
  theme: "light" | "dark" | "high-contrast";

  constructor() {
    makeAutoObservable(this);
    const savedValue = localStorage.getItem("theme-color") as
      | "light"
      | "dark"
      | "high-contrast"
      | undefined;

    const prefersDarkModeQuery = window.matchMedia(
      "(prefers-color-scheme: dark)"
    );

    const prefersTheme = prefersDarkModeQuery.matches ? "dark" : "light";

    this.theme = savedValue || prefersTheme;

    const updateTheme = (e: MediaQueryListEvent) => {
      const hasStorageThemeColor = localStorage.getItem("theme-color");
      if (hasStorageThemeColor) {
        return;
      }
      this.theme = e.matches ? "dark" : "light";
    };

    prefersDarkModeQuery.addEventListener("change", updateTheme);
  }

  changeTheme(newTheme: "light" | "dark" | "high-contrast") {
    this.theme = newTheme;
    localStorage.setItem("theme-color", this.theme);
  }

  invertTheme() {
    this.theme = this.theme === "dark" ? "light" : "dark";
    localStorage.setItem("theme-color", this.theme);
  }
}
