import { useStore } from "hooks";
import React, { useEffect, useLayoutEffect, useState } from "react";
import { Placeholder } from "rsuite";
import * as am5 from "@amcharts/amcharts5";
import * as am5xy from "@amcharts/amcharts5/xy";
import am5themes_Animated from "@amcharts/amcharts5/themes/Animated";
import am5themes_Dark from "@amcharts/amcharts5/themes/Dark";
import { observer } from "mobx-react";
import { Panel } from "shared/ui";

interface ChartData {
  lessonTitle: string | undefined;
  firstPercent: number;
  secondPercent: number;
}

interface IStudentProgressChartProps {
  studentId?: number;
}

export const StudentProgressChartComponent: React.FC<
  IStudentProgressChartProps
> = (props) => {
  const { lessonsStore, statisticStore, uiStore } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [isEmptyData, setIsEmptyData] = useState(false);
  const [chart, setChart] = useState<am5.Root>();
  const [chartData, setChartData] = useState<ChartData[]>([]);

  useEffect(() => {
    if (!props.studentId) {
      return;
    }
    setIsLoading(true);
    statisticStore.getProgressChartData(props.studentId);
  }, [props.studentId]);

  useEffect(() => {
    if (!statisticStore.progressChart) {
      setIsLoading(true);
      return;
    }
    if (
      !statisticStore.onLoading &&
      statisticStore.progressChart.length === 0
    ) {
      setIsEmptyData(true);
    }
    setIsLoading(false);

    getChartData().then((chartData) => setChartData(chartData));
  }, [statisticStore.onLoading, statisticStore.progressChart]);

  const getChartData = async (): Promise<ChartData[]> => {
    const chartData: ChartData[] = await Promise.all(
      statisticStore.progressChart.map(async (x) => {
        const lesson = await lessonsStore.findOrLoad(x.lessonId);
        return {
          lessonTitle: lesson?.title,
          firstPercent: x.firstPartPercent,
          secondPercent: x.secondPartPercent,
        };
      })
    );

    return chartData;
  };

  useEffect(() => {
    if (chart) {
      chart.setThemes(
        uiStore.theme === "light"
          ? [am5themes_Animated.new(chart)]
          : [am5themes_Animated.new(chart), am5themes_Dark.new(chart)]
      );
    }
  }, [uiStore.theme]);

  useLayoutEffect(() => {
    const root = am5.Root.new("chartdiv");
    setChart(root);
    root.setThemes(
      uiStore.theme === "light"
        ? [am5themes_Animated.new(root)]
        : [am5themes_Animated.new(root), am5themes_Dark.new(root)]
    );
    const chart = root.container.children.push(
      am5xy.XYChart.new(root, {
        panX: true,
        panY: false,
        wheelX: "panX",
        wheelY: "zoomX",
        layout: root.verticalLayout,
        pinchZoomX: true,
      })
    );

    // Add cursor
    // https://www.amcharts.com/docs/v5/charts/xy-chart/cursor/
    const cursor = chart.set(
      "cursor",
      am5xy.XYCursor.new(root, {
        behavior: "none",
      })
    );
    cursor.lineY.set("visible", false);
    cursor.lineX.set("visible", false);

    // Define data

    const data = chartData;

    // Create axes
    // https://www.amcharts.com/docs/v5/charts/xy-chart/axes/
    const xRenderer = am5xy.AxisRendererX.new(root, {});
    xRenderer.grid.template.set("visible", false);
    xRenderer.labels.template.setAll({
      location: 0.5,
      multiLocation: 0.5,
    });

    const xAxis = chart.xAxes.push(
      am5xy.CategoryAxis.new(root, {
        categoryField: "lessonTitle",
        renderer: xRenderer,
        tooltip: am5.Tooltip.new(root, {}),
      })
    );

    xAxis.data.setAll(data);

    const yAxis = chart.yAxes.push(
      am5xy.ValueAxis.new(root, {
        min: -5,
        max: 105,
        strictMinMax: true,
        maxPrecision: 0,
        renderer: am5xy.AxisRendererY.new(root, {}),
      })
    );

    // Add series
    // https://www.amcharts.com/docs/v5/charts/xy-chart/series/

    function createSeries(name: string, field: string) {
      const series = chart.series.push(
        am5xy.LineSeries.new(root, {
          name: name,
          xAxis: xAxis,
          yAxis: yAxis,
          valueYField: field,
          categoryXField: "lessonTitle",
          tooltip: am5.Tooltip.new(root, {
            pointerOrientation: "horizontal",
            labelText: "[bold]{name}[/]\n{categoryX}: {valueY}",
          }),
        })
      );

      series.bullets.push(function () {
        return am5.Bullet.new(root, {
          sprite: am5.Circle.new(root, {
            radius: 5,
            fill: series.get("fill"),
          }),
        });
      });

      // create hover state for series and for mainContainer, so that when series is hovered,
      // the state would be passed down to the strokes which are in mainContainer.
      series.set("setStateOnChildren", true);
      series.states.create("hover", {});

      series.mainContainer.set("setStateOnChildren", true);
      series.mainContainer.states.create("hover", {});

      series.strokes.template.states.create("hover", {
        strokeWidth: 4,
      });

      series.data.setAll(data);
      series.appear(1000);
    }

    createSeries("Первая часть", "firstPercent");
    createSeries("Вторая часть", "secondPercent");

    // Add scrollbar
    // https://www.amcharts.com/docs/v5/charts/xy-chart/scrollbars/
    chart.set(
      "scrollbarX",
      am5.Scrollbar.new(root, {
        orientation: "horizontal",
        marginBottom: 20,
        start: 0.5,
      })
    );

    const legend = chart.children.push(
      am5.Legend.new(root, {
        centerX: am5.p50,
        x: am5.p50,
      })
    );

    legend.data.setAll(chart.series.values);

    // Make stuff animate on load
    // https://www.amcharts.com/docs/v5/concepts/animations/
    chart.appear(1000, 100);

    return () => {
      root.dispose();
    };
  }, [chartData, statisticStore.progressChart]);

  return (
    <>
      <Panel bordered style={{ marginBottom: 11 }}>
        {(isLoading || isEmptyData) && (
          <div
            style={{
              width: "100%",
              height: "290px",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isLoading && <Placeholder.Graph active height={290} />}
            {isEmptyData && <p>Нет данных</p>}
          </div>
        )}

        <div
          id="chartdiv"
          style={{
            width: "100%",
            height: "290px",
            display: isLoading || isEmptyData ? "none" : "",
          }}
        ></div>
      </Panel>
    </>
  );
};
export const StudentProgressChart = observer(StudentProgressChartComponent);
