import { DateHelper } from "root/DateHelper";
import { EnumConverter } from "root/EnumConverter";
import { FlexboxGrid } from "rsuite";
import { HomeworkStudentCheckStatus, IHomeworkCheck } from "stores";
import { slimText, styleCenter, titleStyle } from "../styles";
import { TitlePlaceholder } from "./TitlePlaceholder";

interface IProps {
  check: IHomeworkCheck;
}

export const DeadlineCell: React.FC<IProps> = ({ check }) => {
  const title =
    check.checkStatus === HomeworkStudentCheckStatus.notChecked
      ? `${DateHelper.getDateToStrLocaleShort(check.checkDeadline, true)} (${
          DateHelper.GetTimeUntilDate(check.checkDeadline) || "дд прошел 🛑"
        })`
      : EnumConverter.homeworkStudentCheckStatusToStr(check.checkStatus);

  return (
    <FlexboxGrid.Item
      colspan={7}
      style={{ ...styleCenter, justifyContent: "flex-end" }}
    >
      <div style={{ textAlign: "right" }}>
        <div style={slimText}>ДД проверки</div>
        <div style={titleStyle}>{title ?? <TitlePlaceholder />}</div>
      </div>
    </FlexboxGrid.Item>
  );
};
