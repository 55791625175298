import React, { CSSProperties } from "react";
import { Link } from "react-router-dom";
import { Button } from "shared/ui";

const buttonStyle: CSSProperties = { color: "var(--rs-text-primary)" };

export const ButtonAllTariff: React.FC = () => (
  <Button
    className="invisible-by-max-width-993"
    appearance="link"
    style={buttonStyle}
    as={Link}
    to={"/catalog"}
  >
    все курсы
  </Button>
);
