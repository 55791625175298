import { observer } from "mobx-react";
import React, { useMemo } from "react";
import { TextHelper } from "root/TextHelpers";
import { SelectPicker } from "rsuite";
import { useStore } from "../../hooks";

interface IProps {
  onChange: (value: number | null) => void;
  defaultValue?: number | null;
}

const SelectPickerStudentsComponent: React.FC<IProps> = ({
  defaultValue,
  onChange,
}) => {
  const { studentsStore } = useStore();

  const students = useMemo(
    () =>
      studentsStore.data
        .slice()
        .reverse()
        .map((item) => ({
          label: TextHelper.GetShortName(item.user, true),
          value: item.id,
        })),
    [studentsStore.data]
  );

  return (
    <SelectPicker
      defaultValue={defaultValue ?? undefined}
      label="Ученик"
      data={students}
      onChange={onChange}
      style={{ width: 224 }}
    />
  );
};
export const SelectPickerStudents = observer(SelectPickerStudentsComponent);
