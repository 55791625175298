import { useStore } from "hooks";
import { useCallback, useMemo } from "react";
import { INotification } from "stores";

export const useAllNotification = () => {
  const { globalNotificationStore, notificationStore } = useStore();

  const sortNotificationsByDate = useCallback(
    (notifications: INotification[]): INotification[] => {
      return notifications.sort((a, b) => {
        const beginDateA: Date = new Date(a.dateCreate);
        const beginDateB: Date = new Date(b.dateCreate);

        if (beginDateA < beginDateB) {
          return 1;
        }
        if (beginDateA > beginDateB) {
          return -1;
        }
        return 0;
      });
    },
    []
  );

  const allNotifications = useMemo(() => {
    const mappedNotifications: INotification[] = globalNotificationStore.data
      .map((globalNotification) => ({
        id: globalNotification.id,
        title: globalNotification.title,
        description: globalNotification.description,
        dateCreate: globalNotification.dateRange.begin,
      }))
      .concat(notificationStore.data);

    const sortedNotifications = sortNotificationsByDate(mappedNotifications);
    return sortedNotifications;
  }, [globalNotificationStore.data, notificationStore.data]);

  return { allNotifications };
};
