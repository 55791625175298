import UserIcon from "@rsuite/icons/legacy/User";
import { EmptyStatePanel } from "components";
import { observer } from "mobx-react";
import { CSSProperties } from "react";
import { Col, FlexboxGrid, List, Placeholder } from "rsuite";
import { IStudentTopDto } from "stores";
import { useStore } from "../../hooks";
import { Avatar } from "shared/ui";

const styleCenter: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "60px",
};

const slimText: CSSProperties = {
  fontSize: "1em",
  color: "#97969B",
  fontWeight: "lighter",
  paddingBottom: 5,
};

const titleStyle: CSSProperties = {
  paddingBottom: 5,
  whiteSpace: "nowrap",
  fontWeight: 500,
};

const dataStyle: CSSProperties = {
  fontSize: "1.2em",
  fontWeight: 500,
};

export interface IViewTopProps {
  isLoadingStudentTop: boolean;
  studentsTop: IStudentTopDto[] | undefined;
}

const ViewTopComponent: React.FC<IViewTopProps> = (props: IViewTopProps) => {
  const { isLoadingStudentTop, studentsTop } = props;
  const { authStore } = useStore();
  const studentId = authStore.myStudentData?.id;

  if (isLoadingStudentTop) {
    return (
      <>
        <List bordered hover>
          <List.Item>
            <Placeholder.Paragraph active graph="square" />
          </List.Item>
          <List.Item>
            <Placeholder.Paragraph active graph="square" />
          </List.Item>
          <List.Item>
            <Placeholder.Paragraph active graph="square" />
          </List.Item>
        </List>
      </>
    );
  }

  if (!studentsTop || studentsTop.length === 0) {
    return <EmptyStatePanel />;
  }

  return (
    <>
      <List style={{ overflowX: "auto" }} bordered hover>
        {studentsTop?.map((item, i) => (
          <List.Item
            key={item.studentId}
            index={i + 1}
            style={
              studentId === item.studentId
                ? { backgroundColor: "var(--rs-list-hover-bg)" }
                : {}
            }
          >
            <FlexboxGrid>
              <FlexboxGrid.Item colspan={1} style={styleCenter}>
                <span>{i + 1}</span>
              </FlexboxGrid.Item>

              <FlexboxGrid.Item
                as={Col}
                colspan={6}
                md={3}
                lg={3}
                sm={4}
                style={styleCenter}
              >
                {item.student?.user?.imageUrl ? (
                  <Avatar circle src={item.student?.user.imageUrl} size="lg" />
                ) : (
                  <Avatar circle size="lg" style={{ cursor: "pointer" }}>
                    <UserIcon />
                  </Avatar>
                )}
              </FlexboxGrid.Item>

              <FlexboxGrid.Item
                colspan={9}
                style={{
                  ...styleCenter,
                  flexDirection: "column",
                  alignItems: "flex-start",
                  overflow: "hidden",
                }}
              >
                <div style={titleStyle}>
                  {`${item.student?.user.firstName} ${item.student?.user.lastName[0]}.`}
                </div>
              </FlexboxGrid.Item>
              <FlexboxGrid.Item style={styleCenter}>
                <div style={{ textAlign: "right" }}>
                  <div style={slimText}>Успеваемость</div>
                  <div style={dataStyle}>{item.performance}%</div>
                </div>
              </FlexboxGrid.Item>
            </FlexboxGrid>
          </List.Item>
        ))}
      </List>
    </>
  );
};

export const ViewTop = observer(ViewTopComponent);
