import { useState } from "react";

const titleStyle = {
  marginBottom: 5,
  marginTop: 15,
  color: "#bbbbbb",
};

export const useAnswerCriteria = () => {
  const [isOpenViewFileModal, setOpenViewFileModal] = useState(false);

  const handleOpenModal = () => setOpenViewFileModal(true);
  const handleCloseModal = () => setOpenViewFileModal(false);

  return { isOpenViewFileModal, handleOpenModal, handleCloseModal, titleStyle };
};
