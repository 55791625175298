import { useStore } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { getRoutes } from "routes";

export const useContainerPage = () => {
  const { authStore } = useStore();
  const isAuthorized = authStore.me;
  const role = authStore.me?.role;

  if (process.env.NODE_ENV === "development") {
    document.title = "DEV";
  }

  const [isMenuOpen, setStateMenu] = useState(true);

  useEffect(() => {
    if (!isMenuOpen) {
      document.body.classList.add("bg-black");
      return;
    }

    document.body.classList.remove("bg-black");
  }, [isMenuOpen]);

  const handleClickHeader = () => {
    setStateMenu(!isMenuOpen);
  };

  const handleClickMenu = () => {
    setStateMenu(true);
  };

  const routes = useMemo(() => getRoutes(role), [role]);

  return {
    isAuthorized,
    handleClickHeader,
    handleClickMenu,
    isMenuOpen,
    routes,
  };
};
