import { LessonsControlPanel, RawHTML } from "components";
import { useNumberParams } from "hooks/useNumberParams";
import { observer } from "mobx-react";
import { Title } from "pages/Student/Lessons/component/Title";
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { ILesson, RoleNames } from "stores";
import { authStore } from "stores/AuthStore";
import { useIsMounted, useStore } from "../hooks";
import { LessonContent } from "./LessonContent";
import { LessonNotFound } from "./LessonNotFound";
import { LessonWithoutEnable } from "./LessonWithoutEnable";
import { PlaceholderLessonCard } from "./Placeholder/PlaceholderLessonCard";
import { Col, Grid, Panel, Row } from "shared/ui";

const LessonsCardComponent: React.FC = () => {
  const isMounted = useIsMounted();
  const { lessonsStore, homeworkStore } = useStore();
  const { id, homeworkId, taskpackId } = useNumberParams();
  const location = useLocation();
  const hasSummary = location.pathname.includes("summary");
  const [lesson, setLesson] = useState<ILesson>();
  const [summaryHomework, setSummaryHomework] = useState<string>();
  useEffect(() => {
    if (!id) {
      return;
    }

    lessonsStore.findOrLoad(id).then((lesson) => {
      if (!isMounted()) {
        return;
      }
      setLesson(lesson);
    });
    if (authStore.me?.role !== RoleNames.Student) {
      return;
    }
    homeworkStore.getSummary(id).then((summaryHomework) => {
      if (!isMounted()) {
        return;
      }
      setSummaryHomework(summaryHomework);
    });
  }, [id, lessonsStore.onLoading]);

  if (lessonsStore.onLoading) {
    return <PlaceholderLessonCard />;
  }

  if (!lesson) {
    return <LessonNotFound />;
  }

  if (!lesson.enabled) {
    return <LessonWithoutEnable lesson={lesson} />;
  }

  return (
    <Grid fluid>
      <Row className="show-grid">
        <Col xs={24} sm={24} md={24} lg={24} xl={15} xxl={16}>
          <Title lessonTitle={lesson.title || "Название урока"} />
          {hasSummary ? (
            <Panel bordered>
              <RawHTML html={summaryHomework} />
            </Panel>
          ) : (
            <LessonContent
              lesson={lesson}
              taskpackId={taskpackId}
              homeworkId={homeworkId}
            />
          )}
        </Col>
        <Col
          xxl={8}
          xl={9}
          lgHidden
          mdHidden
          smHidden
          xsHidden
          style={{ paddingLeft: 12 }}
        >
          <LessonsControlPanel
            lesson={lesson}
            homeworkId={homeworkId}
            taskpackId={taskpackId}
          />
        </Col>
      </Row>
    </Grid>
  );
};
export const LessonsCard = observer(LessonsCardComponent);
