import React from "react";
import { observer } from "mobx-react";
import { RawHTML } from "../../FormComponents";
import { LinkHelper } from "root";
import { ITask } from "stores";
import { Button, Rate } from "shared/ui";

interface ITaskViewProps {
  task?: ITask;
}

const TaskViewComponent: React.FC<ITaskViewProps> = (props: ITaskViewProps) => {
  const { task } = props;

  return (
    <div style={{ whiteSpace: "pre-line" }}>
      <div style={{ display: "flex", justifyContent: "space-between" }}>
        <b>{task?.kim}.</b>
        <Rate readOnly allowHalf size="xs" defaultValue={task?.difficulty} />
      </div>

      <RawHTML html={task?.question} />

      {task?.fileQuestionUrl && (
        <Button
          style={{ marginBottom: 10 }}
          block
          onClick={() => {
            if (task.fileQuestionUrl) {
              LinkHelper.fileDownload(task.fileQuestionUrl);
            }
          }}
        >
          посмотреть
        </Button>
      )}
      <div style={{ display: "flex", justifyContent: "flex-end" }}>
        <Button style={{ cursor: "default" }} size="xs" active>
          {task?.maxScore} / {task?.maxScore}
        </Button>
      </div>

      {task?.keys !== null && (
        <>
          <p style={{ marginBottom: 5, marginTop: 10, color: "#bbbbbb" }}>
            пояснения к заданию:
          </p>
          <RawHTML html={task?.keys} />
        </>
      )}

      {task?.fileCriteriaUrl && (
        <>
          <p style={{ marginBottom: 5, marginTop: 10, color: "#bbbbbb" }}>
            критерии к заданию:
          </p>
          <img
            src={task?.fileCriteriaUrl}
            alt="Критерии не загрузились"
            style={{
              borderRadius: 15,
              maxHeight: 302,
              display: "flex",
              alignItems: "center",
              justifyContent: "space-around",
            }}
          />
        </>
      )}
    </div>
  );
};
export const TaskView = observer(TaskViewComponent);
