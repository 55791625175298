import React from "react";
import { InputGroup } from "rsuite";
import { useDebouncedInput } from "./hooks/useDebouncedInput";
import { CloseButton } from "./components/CloseButton";
import { Tooltip } from "./components/Tooltip";
import { Input } from "shared/ui";

type TDebouncedInputProps = {
  onChange: (value: string | undefined) => void;
  defaultValue: string | undefined;
  placeholder?: string;
  tooltipTitle?: string;
  delay?: number;
  minLength?: number;
  style?: React.CSSProperties;
};

export const DebouncedInput: React.FC<TDebouncedInputProps> = ({
  onChange,
  defaultValue,
  placeholder = "Найти по названию",
  tooltipTitle = "Для поиска введите от 4 символов",
  delay = 700,
  minLength = 3,
  style = { marginBottom: 10, border: "none", maxWidth: 965 },
}) => {
  const { inputRef, whisperRef, titlePatternValue, handleChange, handleClick } =
    useDebouncedInput(onChange, defaultValue, delay, minLength);

  return (
    <InputGroup style={style}>
      <Tooltip tooltipTitle={tooltipTitle} ref={whisperRef} />
      <Input
        ref={inputRef}
        value={titlePatternValue}
        placeholder={placeholder}
        onChange={handleChange}
      />
      <CloseButton
        onClick={handleClick}
        rendered={!!titlePatternValue?.length}
      />
    </InputGroup>
  );
};
