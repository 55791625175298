import { PropsWithChildren } from "react";
import { RefForwardingComponent } from "../types";
import { PopoverProps } from "./types";
import { Popover as RSPopover } from "rsuite";
import React from "react";

const popover = <E extends React.ElementType>(
  { children, ...props }: PropsWithChildren<PopoverProps>,
  ref: React.ForwardedRef<E>
) => (
  <RSPopover {...props} ref={ref}>
    {children}
  </RSPopover>
);

export const Popover: RefForwardingComponent<"div", PopoverProps> =
  React.forwardRef(popover);
