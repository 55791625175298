import React from "react";
import { Whisper } from "rsuite";
import { Tooltip } from "shared/ui";

interface IAutoCheckWhisperProps {
  children: React.ReactElement;
}

export const AutoCheckWhisper: React.FC<IAutoCheckWhisperProps> = (
  props: IAutoCheckWhisperProps
) => {
  return (
    <Whisper
      placement="top"
      controlId="control-id-hover"
      trigger="hover"
      speaker={
        <Tooltip>
          Вся домашка в уроке будет проверена автоматически. Урок не учитывается
          в ТОПе
        </Tooltip>
      }
    >
      {props.children}
    </Whisper>
  );
};
