import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { SlSocialVkontakte } from "react-icons/sl";
import { IUser } from "stores";
import UserIcon from "@rsuite/icons/legacy/User";
import { GlobalConstants } from "root/GlobalConstants";
import { useStore } from "hooks";
import { IconButton, Avatar } from "shared/ui";

const MentorInfoComponent: React.FC = () => {
  const { mentorStore } = useStore();
  const [mentor, setMentor] = useState<IUser | undefined>();
  useEffect(() => {
    if (
      !mentorStore.mentors ||
      mentorStore.mentors.length == 0 ||
      mentorStore.onLoading
    ) {
      return;
    }
    setMentor(mentorStore.mentors[0].user);
  }, [mentorStore.mentors, mentorStore.onLoading]);

  return (
    <div style={{ display: "flex" }}>
      {mentor ? (
        <Avatar src={`${mentor?.imageUrl}`} />
      ) : (
        <Avatar>
          <UserIcon />
        </Avatar>
      )}
      <div style={{ marginLeft: 12 }}>
        <p
          style={{
            fontSize: 15,
            marginBottom: "-3px",
            whiteSpace: "nowrap",
          }}
        >
          {mentor ? `${mentor?.firstName} ${mentor?.lastName}` : "Подбираем..."}
        </p>
        <p style={{ color: "var(--rs-text-tertiary)", marginTop: 0 }}>
          твой наставник
        </p>
        {mentor ? (
          <IconButton
            block={false}
            icon={
              <SlSocialVkontakte
                style={{
                  marginLeft: "-75px",
                  position: "absolute",
                  top: 6,
                }}
              />
            }
            size="xs"
            href={GlobalConstants.telegramLinkToSupport}
          >
            <p
              style={{
                marginLeft: "-10px",
              }}
            >
              перейти
            </p>
          </IconButton>
        ) : null}
      </div>
    </div>
  );
};
export const MentorInfo = observer(MentorInfoComponent);
