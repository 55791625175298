import React from "react";
import { StyleHelper } from "root/StyleHelper";
import { IEvent } from "stores";
import { format } from "date-fns";
import { Divider } from "shared/ui";

interface IProps {
  event: IEvent;
}

export const ScheduleEvent: React.FC<IProps> = ({ event }) => {
  const time = format(new Date(event.eventDateTime), "HH:mm");

  return (
    <p style={{ padding: 3 }}>
      <div
        className="we-calendar-todo-list-home-item"
        style={{
          backgroundColor: StyleHelper.getColorByEvent(event),
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {StyleHelper.getIconByEvent(event)}
          <span style={{ marginLeft: 8 }}>{time}</span>
        </div>
        <Divider />
        <span style={{ whiteSpace: "nowrap" }}>{event.title}</span>
      </div>
    </p>
  );
};
