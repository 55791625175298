import React, { CSSProperties, useEffect, useState } from "react";
import { observer } from "mobx-react";
import { SelectPicker } from "rsuite";
import { useStore } from "../../hooks";
import { ITask, TaskType, IThema } from "stores";
import { ValidationResult } from "stores/models/ValidationResult";
import { ValidationErrorComponent } from "components";
import { EnumConverter } from "root";
import { Panel } from "shared/ui";

const labelStyle: CSSProperties = {
  marginBottom: 5,
};

const errorLabelStyle: CSSProperties = {
  marginTop: -10,
};

interface IHeaderThemeProps {
  task?: ITask;
  validationResult?: ValidationResult;
  onChangeType: (type: number) => void;
  setFormValue: (formValue: any) => void;
  isEmpty: boolean;
  onChangeIsEmpty: () => void;
}

const HeaderThemeComponent: React.FC<IHeaderThemeProps> = (
  props: IHeaderThemeProps
) => {
  const { task } = props;

  const { subjectStore, themaCategoryStore, themaStore } = useStore();

  const [collapsiblePanel, setCollapsiblePanel] = useState<boolean>(false);
  const [subjectHeader, setSubjectHeader] = useState<string>();
  const [themaCategoryHeader, setThemaCategoryHeader] = useState<string>();
  const [themaHeader, setThemaHeader] = useState<string>();
  const [sourseHeader, setSourceHeader] = useState<string>();
  const [headerPanel, setHeaderPanel] = useState("");
  const [typeTask, setTypeTask] = useState(null);

  const [headerThemeValue, setHeaderThemeValue] = useState<any>({});
  const [themeCategory, setThemeCategory] = useState<any[]>([]);
  const [selectThemeCategory, setSelectDataThemeCategory] =
    useState<any[]>(themeCategory);
  const [themes, setThemes] = useState<any[]>([]);
  const [selectTheme, setSelectDataTheme] = useState<any[]>(themes);

  const [valueTypeTask, setValueTypeTask] = useState<any>();
  const [valueSubject, setValueSubject] = useState<any>();
  const [valueThemeCategory, setValueThemeCategory] = useState<any>();
  const [valueTheme, setValueTheme] = useState<any>();
  const [valueSource, setValueSource] = useState<any>();

  useEffect(() => {
    if (props.isEmpty) {
      setValueTypeTask(null);
      setValueSubject(null);
      setValueThemeCategory(null);
      setValueTheme(null);
      setValueSource(null);
      setHeaderPanel("");
      setCollapsiblePanel(false);
      props.onChangeIsEmpty();
    }
  }, [props.isEmpty]);

  useEffect(() => {
    props.setFormValue(headerThemeValue);
  }, [headerThemeValue]);

  useEffect(() => {
    setThemeCategory(
      themaCategoryStore.data.map((x) => ({
        label: x.title,
        value: x.id,
      }))
    );
  }, [themaCategoryStore.data]);
  useEffect(() => {
    setSelectDataThemeCategory(themeCategory);
  }, [themeCategory]);

  useEffect(() => {
    setThemes(themaStore.themes.map((x) => ({ label: x.title, value: x.id })));
  }, [themaStore.themes]);
  useEffect(() => {
    setSelectDataTheme(themes);
  }, [themes]);

  useEffect(() => {
    setCollapsiblePanel(!!sourseHeader);
  }, [sourseHeader]);

  useEffect(() => {
    if (!collapsiblePanel) {
      setHeaderPanel("");
    } else {
      setHeaderPanel(
        (subjectHeader || "Предмет не выбран") +
          " / " +
          (themaCategoryHeader || "Категория не выбрана") +
          " / " +
          (themaHeader || "Тема не выбрана") +
          " / " +
          (sourseHeader || "Источник не задан")
      );
    }
  }, [
    collapsiblePanel,
    subjectHeader,
    themaCategoryHeader,
    themaHeader,
    sourseHeader,
  ]);

  const selectOrigin = [
    "Котова и Лискова",
    "Лазебникова",
    "Решу ЕГЭ",
    "Авторские задания",
    "ФИПИ",
    "Реальный ЕГЭ",
    "Задания от НЕЗло",
  ].map((item) => ({ label: item, value: item }));

  const selectTypeTask = EnumConverter.mapEnum(
    TaskType,
    EnumConverter.typeTaskToStr
  );

  const selectSubject = subjectStore.data.map((x) => ({
    label: x.title,
    value: x.id,
  }));

  const handleSelectCollapsible = (type: any) => {
    setTypeTask(type);
  };

  useEffect(() => {
    if (!task) {
      return;
    }
    const thema = themaStore.find(task.themeId);
    const themaTitle = thema?.title;
    const themeCategoryId = thema?.themeCategoryId;
    const subjectId = themaCategoryStore.data.find(
      (x) => x.id === themeCategoryId
    )?.subjectId;
    handleChangeSelectPickerSubject(subjectId);
    onChangeThemeId(themeCategoryId);
    props.setFormValue({
      selectPickerSubject: subjectId,
      selectPickerThemeCategory: themeCategoryId,
      ...task,
    });
    setThemaHeader(themaTitle || "");
    setSourceHeader(task.source || "");
    handleSelectCollapsible(task.type);

    setValueTypeTask(task.type);
    setValueSubject(subjectId);
    setValueThemeCategory(themeCategoryId);
    setValueTheme(task.themeId);
    setValueSource(task.source);
  }, [task]);

  const onChangeThemeId = (сategoryId: number | undefined) => {
    const list = themaStore
      .filterByCategoryId(сategoryId)
      .map((x) => ({ label: x.title, value: x.id }));
    setSelectDataTheme(list);
    const themaCategory = themaCategoryStore.find(сategoryId)?.title;
    if (themaCategory) {
      setThemaCategoryHeader(themaCategory);
    }
  };
  const handleChangeSelectPickerSubject = (subjectId: number | undefined) => {
    if (!subjectId) {
      setSelectDataThemeCategory(themeCategory);
      return;
    }
    const categories = themaCategoryStore.filterBySubjectId(subjectId);
    const list = categories.map((x) => ({
      label: x.title,
      value: x.id,
    }));
    setSelectDataThemeCategory(list);
    let themesBySubject: IThema[] = [];
    categories.forEach((category) => {
      const themes = themaStore.filterByCategoryId(category.id);
      themesBySubject = themesBySubject.concat(themes);
    });
    
    const subjectTitle = subjectStore.find(subjectId)?.title;
    if (subjectTitle) {
      setSubjectHeader(subjectTitle);
    }
  };
  const handleChangeSelectThemeCategory = (сategoryId: number) => {
    const themaCategory = themaCategoryStore.find(сategoryId);
    setSubjectHeader(subjectStore.find(themaCategory?.subjectId)?.title);
    setThemaCategoryHeader(themaCategory?.title);
    if (!сategoryId) {
      setSelectDataTheme(themes);
      return;
    }
    const list = themaStore
      .filterByCategoryId(сategoryId)
      .map((x) => ({ label: x.title, value: x.id }));
    setSelectDataTheme(list);
  };

  const handleChangeSelectThema = (themaId: number) => {
    const thema = themaStore.find(themaId);
    const themaCategory = themaCategoryStore.find(thema?.themeCategoryId);
    const subject = subjectStore.find(themaCategory?.subjectId);
    setSubjectHeader(subject?.title);
    setThemaCategoryHeader(themaCategory?.title);
    setThemaHeader(thema?.title || "");
    setValueSubject(subject?.id);
    setValueThemeCategory(themaCategory?.id);
  };

  const style = { marginBottom: 10, width: 300 };

  const taskPackError = props.validationResult?.getError("type");
  const themeIdError = props.validationResult?.getError("themeId");
  const sourceError = props.validationResult?.getError("source");

  return (
    <>
      <div
        style={{
          display: "flex",
          flexWrap: "wrap",
          flexDirection: "column",
        }}
      >
        <label style={labelStyle}>Тип</label>
        <>
          <SelectPicker
            data={selectTypeTask}
            value={valueTypeTask}
            style={style}
            onChange={(type: any) => {
              props.onChangeType(type);
              setTypeTask(type);
              setHeaderThemeValue({ ...headerThemeValue, type: type });
              setValueTypeTask(type);
            }}
          />

          <ValidationErrorComponent
            style={errorLabelStyle}
            title={taskPackError}
          />
        </>
      </div>
      {typeTask && (
        <Panel
          header={headerPanel}
          collapsible={collapsiblePanel}
          bodyFill
          style={{
            marginBottom: 24,
          }}
        >
          <div
            style={{
              display: "flex",
              flexWrap: "wrap",
              flexDirection: "column",
            }}
          >
            <label style={labelStyle}>Предмет</label>

            <SelectPicker
              data={selectSubject}
              value={valueSubject}
              style={style}
              onChange={(value: any) => {
                handleChangeSelectPickerSubject(value);
                setHeaderThemeValue({ ...headerThemeValue, subject: value });
                setValueSubject(value);
              }}
            />

            <label style={labelStyle}>Категория</label>

            <SelectPicker
              data={selectThemeCategory}
              value={valueThemeCategory}
              style={style}
              onChange={(сategoryId: any) => {
                handleChangeSelectThemeCategory(сategoryId);
                setHeaderThemeValue({
                  ...headerThemeValue,
                  themeCategory: сategoryId,
                });
                setValueThemeCategory(сategoryId);
              }}
            />

            <label style={labelStyle}>Темы</label>
            <>
              <SelectPicker
                data={selectTheme}
                value={valueTheme}
                style={style}
                onChange={(themeId: any) => {
                  handleChangeSelectThema(themeId);
                  setHeaderThemeValue({
                    ...headerThemeValue,
                    themeId: themeId,
                  });
                  setValueTheme(themeId);
                }}
              />

              <ValidationErrorComponent
                style={errorLabelStyle}
                title={themeIdError}
              />
            </>
            <label style={labelStyle}>Источник</label>
            <>
              <SelectPicker
                data={selectOrigin}
                value={valueSource}
                style={style}
                onChange={(source: any) => {
                  setSourceHeader(source || "");
                  setHeaderThemeValue({
                    ...headerThemeValue,
                    source: source,
                  });
                  setValueSource(source);
                }}
              />

              <ValidationErrorComponent
                style={errorLabelStyle}
                title={sourceError}
              />
            </>
          </div>
        </Panel>
      )}
    </>
  );
};
export const HeaderTheme = observer(HeaderThemeComponent);
