import ArrowDownIcon from "@rsuite/icons/ArrowDown";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { EnumConverter } from "root/EnumConverter";
import {
  HomeworksCheckOrder,
  IRequestGetHomeworksCheckByFilter,
} from "root/requests";
import { ButtonGroup, ButtonToolbar, Dropdown, Whisper } from "rsuite";
import {
  HomeworkStudentCheckStatus,
  HomeworkStudentStatus,
  IHomeworkResult,
  ILesson,
} from "stores";
import { $enum } from "ts-enum-util";
import { HwCheckStatusType } from "./HwCheckStatusType";
import { Button, IconButton, Popover } from "shared/ui";

const initialFilter: IRequestGetHomeworksCheckByFilter = {
  tariffId: null,
  lessonId: null,
  studentId: null,
  homeworkStatus: null,
  checkStatus: null,
  deadlineStatus: null,
  mentorId: null,
  teacherId: null,
  count: 0,
  orderBy: HomeworksCheckOrder.SubmitDeadlineAsc,
};

interface IProps {
  handleChange: (homeWorksResult: IHomeworkResult[]) => void;
}

const FilterComponent: React.FC<IProps> = ({ handleChange }) => {
  const { tariffStore, homeworkStore, lessonsStore } = useStore();
  const [selectedHomeworkStatus, setSelectedHomeworkStatus] = useState(0);
  const [selectedTariffId, setSelectedTariffId] = useState<number>();

  useEffect(() => {
    if (!tariffStore.selectedTariff) {
      return;
    }
    setSelectedTariffId(tariffStore.selectedTariff.id);
  }, [tariffStore.selectedTariff]);

  useEffect(() => {
    homeworkStore.loadByFilter(initialFilter, { reloadDataPage: false });
  }, []);

  useEffect(() => {
    if (
      !selectedTariffId ||
      lessonsStore.data.length === 0 ||
      homeworkStore.homeWorksResult.length === 0
    ) {
      return;
    }
    const filteredHomework = homeworkFilter(
      lessonsStore.data,
      selectedTariffId,
      selectedHomeworkStatus,
      homeworkStore.homeWorksResult
    );
    handleChange(filteredHomework);
  }, [
    lessonsStore.data,
    selectedHomeworkStatus,
    selectedTariffId,
    homeworkStore.homeWorksResult,
  ]);

  const homeworkFilter = (
    lesson: ILesson[],
    selectedTariffId: number,
    selectedHomeworkStatus: number,
    homeWorksResult: IHomeworkResult[]
  ) => {
    let hwStatus: HomeworkStudentStatus | undefined = undefined;
    let checkStatus: HomeworkStudentCheckStatus | undefined = undefined;

    const lessonIds = lesson
      .filter((lesson) => lesson?.tariffId == selectedTariffId)
      .map((lesson) => lesson.id);

    switch (selectedHomeworkStatus) {
      case HwCheckStatusType.all:
        break;
      case HwCheckStatusType.notSend:
        hwStatus = HomeworkStudentStatus.notSend;
        break;
      case HwCheckStatusType.sendCorrect:
        hwStatus = HomeworkStudentStatus.sendCorrect;
        break;
      case HwCheckStatusType.sendLate:
        hwStatus = HomeworkStudentStatus.sendLate;
        break;
      case HwCheckStatusType.notChecked:
        checkStatus = HomeworkStudentCheckStatus.notChecked;
        break;
      case HwCheckStatusType.checkedCorrect:
        checkStatus = HomeworkStudentCheckStatus.checkedCorrect;
        break;
    }

    const filtered = homeWorksResult
      .map((x) => {
        return {
          lessonId: x.lessonId,
          checks: x.checks.filter(
            (check) =>
              (!hwStatus || check.homeworkStatus === hwStatus) &&
              (!checkStatus || check.checkStatus === checkStatus) &&
              (!lessonIds ||
                lessonIds.some((lessonId) => check.lessonId === lessonId))
          ),
        };
      })
      .filter((x) => x.checks.length);
    return filtered;
  };

  const statusHomework = $enum(HwCheckStatusType)
    .getValues()
    .map((x) => EnumConverter.statusTypeToStr(x));

  const isLoading = homeworkStore.onLoading || lessonsStore.onLoading;

  return (
    <>
      <ButtonToolbar style={{ marginBottom: 15 }}>
        <ButtonGroup>
          <Button appearance="primary" color="blue" size="sm">
            Статус домашки: {statusHomework[selectedHomeworkStatus]}
          </Button>
          <Whisper
            placement="bottomEnd"
            trigger="click"
            speaker={({ onClose, left, top, className }, ref) => {
              const handleSelect = (eventKey: any) => {
                setSelectedHomeworkStatus(eventKey);
                onClose();
              };
              return (
                <Popover
                  ref={ref}
                  className={className}
                  style={{ left, top }}
                  full
                >
                  <Dropdown.Menu onSelect={handleSelect}>
                    {statusHomework.map((x, i) => {
                      return (
                        <Dropdown.Item
                          disabled={isLoading}
                          key={x}
                          eventKey={i}
                        >
                          {x}
                        </Dropdown.Item>
                      );
                    })}
                  </Dropdown.Menu>
                </Popover>
              );
            }}
          >
            <IconButton
              size="sm"
              appearance="primary"
              color="blue"
              icon={<ArrowDownIcon />}
            />
          </Whisper>
        </ButtonGroup>
      </ButtonToolbar>
    </>
  );
};

export const Filter = observer(FilterComponent);
