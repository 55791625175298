import React from "react";
import { Radio as RSRadio } from "rsuite";
import { RadioProps, ValueType } from "./types";

const radio = (props: RadioProps, ref: React.Ref<unknown>) => (
  <RSRadio {...props} ref={ref}></RSRadio>
);

export const Radio: React.ForwardRefExoticComponent<
  RadioProps<ValueType> & React.RefAttributes<unknown>
> = React.forwardRef(radio);
