import { get, post } from "../WebHelper";

export class LogController {
  public async Get(
    dateTimeFrom?: Date | null,
    dateTimeTo?: Date | null,
    logLevel?: LogLevel | null,
    search?: string | undefined
  ): Promise<ILogRow[] | undefined> {
    return await get("/Log", {
      params: { dateTimeFrom, dateTimeTo, logLevel, search },
    });
  }
  public async LogInfo(userName: string, message: string, stackTrace: string) {
    if (process.env.NODE_ENV === "development") {
      return;
    }
    await post("/Log/Info", {
      person: userName,
      message,
      stack: stackTrace,
    });
  }
  public async LogError(userName: string, message: string, stackTrace: string) {
    if (process.env.NODE_ENV === "development") {
      return;
    }
    await post("/Log/Error", {
      person: userName,
      message,
      stack: stackTrace,
    });
  }
  public async LogFatal(userName: string, message: string, stackTrace: string) {
    if (process.env.NODE_ENV === "development") {
      return;
    }
    await post("/Log/Fatal", {
      person: userName,
      message,
      stack: stackTrace,
    });
  }
}
export interface ILogRow {
  id: number;
  timestamp: string;
  eventId: number;
  logLevel: string;
  category: string;
  message: string;
}

export enum LogLevel {
  Trace = "Trace",
  Debug = "Debug",
  Information = "Information",
  Warning = "Warning",
  Error = "Error",
  Critical = "Critical",
  None = "None",
}
