import PlayOutlineIcon from "@rsuite/icons/PlayOutline";
import { Link } from "react-router-dom";
import { ILesson } from "stores";
import { LayoutImagePanel } from "../../Layout/LayoutImagePanel";
import { IconButton } from "shared/ui";

interface ILessonItemWithButtonProps {
  lesson: ILesson;
}
export const LessonItem: React.FC<ILessonItemWithButtonProps> = ({
  lesson,
}) => {
  return (
    <LayoutImagePanel
      button={
        <IconButton
          icon={<PlayOutlineIcon />}
          placement="left"
          size="sm"
          color="violet"
          appearance="primary"
          as={Link}
          to={`/lessons/${lesson?.id}`}
        >
          Смотреть
        </IconButton>
      }
      image={<img src={lesson.imageUrl} alt={lesson.title} />}
      title={lesson.title}
    />
  );
};
