import EditIcon from "@rsuite/icons/legacy/Edit";
import { useIsMounted, useStore } from "hooks";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { IHomeworkCheck } from "stores";
import { LayoutImagePanel } from "../../Layout/LayoutImagePanel";
import { IconButton } from "shared/ui";

interface ILessonItemWithButtonProps {
  homework: IHomeworkCheck;
}
const HomeworkItemComponent: React.FC<ILessonItemWithButtonProps> = ({
  homework,
}) => {
  const isMounted = useIsMounted();
  const { lessonsStore } = useStore();

  const [homeworkTitle, setHomeworkTitle] = useState("");
  const [lessonTitle, setLessonTitle] = useState("");
  const [lessonImg, setLessonImg] = useState("");

  useEffect(() => {
    if (!homework) {
      return;
    }
    setLessonOption(homework);
  }, [homework]);

  const setLessonOption = async (homework: IHomeworkCheck) => {
    const homeworkLesson = await lessonsStore.findOrLoad(homework.lessonId);
    const homeworkDetails = homeworkLesson?.homeWorks
      ?.find((hw) => hw.id === homework.homeworkId)
      ?.packs.find((p) => p.id === homework.packId);
    if (!isMounted()) {
      return;
    }
    setHomeworkTitle(homeworkDetails?.btnTitle ?? "Домашка");
    setLessonTitle(homeworkLesson?.title ?? "Урок");
    setLessonImg(homeworkLesson?.imageUrl ?? "");
  };

  return (
    <LayoutImagePanel
      button={
        <IconButton
          icon={<EditIcon />}
          placement="left"
          size="sm"
          color="blue"
          appearance="primary"
          as={Link}
          to={`/lessons/${homework.lessonId}/${homework.homeworkId}/${homework.packId}`}
        >
          Решать
        </IconButton>
      }
      image={<img src={lessonImg} alt={homeworkTitle} />}
      title={`${homeworkTitle} (${lessonTitle})`}
    />
  );
};
export const HomeworkItem = observer(HomeworkItemComponent);
