import { SelectPickerTariffId } from "components/FormComponents/SelectPickerTariffId";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { DateHelper } from "root/DateHelper";
import { EnumConverter } from "root/EnumConverter";
import {
  IRequestGetLessonsByFilter,
  LessonPassedType,
  LessonsOrder,
} from "root/requests";
import { DateRangePicker, SelectPicker, Stack } from "rsuite";
import { DateRange } from "rsuite/esm/DateRangePicker/types";
import { LessonType } from "stores";
import { CreateLesson } from "./CreateLesson";
import { DebouncedInput } from "components/Input/DebouncedInput";

interface IFilterByLessonPropertiesProps {
  onChange: (lessonsListFilter: IRequestGetLessonsByFilter) => void;
  defaultValue: IRequestGetLessonsByFilter;
}

const FilterByLessonPropertiesComponent: React.FC<
  IFilterByLessonPropertiesProps
> = (props) => {
  const [filter, setFilter] = useState<IRequestGetLessonsByFilter>(
    props.defaultValue
  );

  useEffect(() => {
    props.onChange(filter);
  }, [filter]);

  const lessonPassedList = EnumConverter.mapEnum(
    LessonPassedType,
    EnumConverter.lessonPassedTypeToStr
  );
  const lessonTypeList = EnumConverter.mapEnum(
    LessonType,
    EnumConverter.lessonTypeToStr
  );
  const lessonsOrderList = EnumConverter.mapEnum(
    LessonsOrder,
    EnumConverter.lessonsOrderToStr
  );

  const handleOnChangeIsPassed = (value: number | null) => {
    switch (value) {
      case LessonPassedType.isPassed:
        setFilter((prev) => ({
          ...prev,
          isPassed: true,
        }));
        break;
      case LessonPassedType.isComing:
        setFilter((prev) => ({
          ...prev,
          isPassed: false,
        }));
        break;
      default:
        setFilter((prev) => ({
          ...prev,
          isPassed: undefined,
        }));
        break;
    }
  };

  const getLessonPassedType = (
    isPassed: boolean | undefined
  ): LessonPassedType | undefined => {
    let defaultValue;

    switch (true) {
      case isPassed:
        defaultValue = LessonPassedType.isPassed;
        break;
      case isPassed === false:
        defaultValue = LessonPassedType.isComing;
        break;
      default:
        defaultValue = undefined;
        break;
    }
    return defaultValue;
  };

  return (
    <>
      <Stack spacing={10} wrap style={{ marginBottom: 10 }}>
        <CreateLesson />
        <SelectPickerTariffId
          defaultValue={filter.tariffId}
          onChange={(value: number | null) =>
            setFilter((prev) => ({
              ...prev,
              tariffId: value || undefined,
            }))
          }
        />

        <SelectPicker
          defaultValue={filter.lessonType}
          onChange={(value: number | null) =>
            setFilter((prev) => ({
              ...prev,
              lessonType: value || undefined,
            }))
          }
          label="Тип урока"
          data={lessonTypeList}
          style={{ width: 224 }}
        />
        <SelectPicker
          defaultValue={getLessonPassedType(filter.isPassed)}
          onChange={(value: number | null) => handleOnChangeIsPassed(value)}
          label="Статус проведения"
          data={lessonPassedList}
          style={{ width: 224 }}
        />
        <DateRangePicker
          defaultValue={
            filter.lessonDateTime && [
              new Date(filter.lessonDateTime.begin),
              new Date(filter.lessonDateTime.end),
            ]
          }
          onChange={(value: DateRange | null) => {
            const [begin, end] = value ?? [];
            setFilter((prev) => ({
              ...prev,
              lessonDateTime: begin && end ? { begin, end } : undefined,
            }));
          }}
          placeholder="Период проведения"
          showOneCalendar
          ranges={DateHelper.GetPredefinedRanges(new Date())}
          style={{ width: 224 }}
          placement="auto"
        />
      </Stack>

      <DebouncedInput
        defaultValue={props.defaultValue.titlePattern}
        onChange={(titlePattern) =>
          setFilter((prev) => ({
            ...prev,
            titlePattern,
          }))
        }
      />

      <SelectPicker
        value={filter.orderBy}
        onChange={(value: number | null) =>
          setFilter((prev) => ({
            ...prev,
            orderBy: value || LessonsOrder.DateTimeDesc,
          }))
        }
        label="Сортировка"
        data={lessonsOrderList}
        style={{ width: 224, marginBottom: 10 }}
      />
    </>
  );
};
export const FilterByLessonProperties = observer(
  FilterByLessonPropertiesComponent
);
