import React, { PropsWithChildren } from "react";
import { Grid as RSGrid } from "rsuite";
import { RefForwardingComponent } from "../types";
import { GridProps } from "./types";

const grid = <E extends React.ElementType>(
  { children, ...props }: PropsWithChildren<GridProps>,
  ref: React.ForwardedRef<E>
) => (
  <RSGrid {...props} ref={ref}>
    {children}
  </RSGrid>
);

export const Grid: RefForwardingComponent<"div", GridProps> =
  React.forwardRef(grid);
