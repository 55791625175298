import ArrowDownIcon from "@rsuite/icons/ArrowDown";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { ButtonGroup, Dropdown, Whisper } from "rsuite";
import { RoleNames } from "stores";
import { useStore } from "../../../hooks";
import { TextHelper } from "../../../root";
import { Button, IconButton, Popover } from "shared/ui";

export interface IFilterMentorNames {
  title: string;
  id: number;
}

type TSelectMentorButtonProps = {
  disabled?: boolean;
  value?: number;
  onChange: (mentorId: number) => void;
};

const SelectMentorButtonComponent: React.FC<TSelectMentorButtonProps> = ({
  disabled,
  value: selectedMentor = 0,
  onChange,
}) => {
  const { mentorStore, authStore } = useStore();

  const mentorsNames = useMemo(() => {
    const filterMentorNames: IFilterMentorNames[] = mentorStore.mentors.map(
      (x) => ({ title: TextHelper.GetShortName(x.user, true), id: x.id })
    );
    return [{ title: "Все кланы", id: 0 }].concat(filterMentorNames);
  }, [mentorStore.mentors]);

  const buttonTitle = useMemo(
    () =>
      selectedMentor === 0
        ? "Все кланы"
        : TextHelper.GetShortName(mentorStore.find(selectedMentor)?.user, true),
    [selectedMentor]
  );

  if (
    authStore.me?.role === RoleNames.Student ||
    authStore.me?.role === RoleNames.Mentor
  ) {
    return null;
  }

  return (
    <ButtonGroup>
      <Button
        disabled={disabled}
        appearance="primary"
        color="blue"
        loading={mentorStore.onLoading}
      >
        {buttonTitle}
      </Button>
      <Whisper
        placement="bottomStart"
        trigger="click"
        speaker={({ onClose, left, top, className }, ref) => {
          const handleSelect = (eventKey: any) => {
            onClose();
            onChange(eventKey);
          };
          return (
            <Popover ref={ref} className={className} style={{ left, top }} full>
              <Dropdown.Menu onSelect={handleSelect}>
                {mentorsNames.map((x) => {
                  return (
                    <Dropdown.Item key={x.id} eventKey={x.id}>
                      {x.title}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Popover>
          );
        }}
      >
        <IconButton
          disabled={disabled}
          appearance="primary"
          color="blue"
          icon={<ArrowDownIcon />}
        />
      </Whisper>
    </ButtonGroup>
  );
};

export const SelectMentorButton = observer(SelectMentorButtonComponent);
