import React, { useEffect, useState } from "react";
import { ButtonToolbar, ButtonGroup } from "rsuite";
import PageNextIcon from "@rsuite/icons/PageNext";
import PagePreviousIcon from "@rsuite/icons/PagePrevious";
import { observer } from "mobx-react";
import { ILesson } from "stores";
import { useIsMounted, useStore } from "hooks";
import { Button, IconButton } from "shared/ui";

interface ILessonsByTariff {
  lessonId: number;
  tariffId: number;
  enabled: boolean;
}

interface ILessonsControlButtons {
  lesson: ILesson;
  onChange: (currentLesson: ILesson) => void;
}

const LessonsControlButtonsComponent: React.FC<ILessonsControlButtons> = (
  props
) => {
  const isMounted = useIsMounted();
  const { lessonsStore } = useStore();
  const [currentLessonIndex, setCurrentLessonIndex] = useState(-1);
  const [lessonsByTariff, setLessonsByTariff] = useState<
    ILessonsByTariff[] | undefined
  >();
  const [selectedLesson, setSelectedLesson] = useState<ILesson | undefined>(
    undefined
  );

  useEffect(() => {
    if (!selectedLesson) {
      return;
    }
    props.onChange(selectedLesson);
  }, [selectedLesson]);

  useEffect(() => {
    const lessonsByTariff: ILessonsByTariff[] = lessonsStore.data
      .filter(
        (x) =>
          x.tariffId === props.lesson.tariffId &&
          x.files &&
          x.files.length !== 0
      )
      .slice()
      .sort(
        (a, b) =>
          new Date(a.dateTime).getTime() - new Date(b.dateTime).getTime()
      )
      .map((x) => {
        return {
          lessonId: x.id,
          tariffId: x.tariffId,
          enabled: x.enabled,
        };
      });

    setLessonsByTariff(lessonsByTariff);
    const index = lessonsByTariff.findIndex(
      (x) => x.lessonId === props.lesson.id
    );

    setCurrentLessonIndex(Math.max(index, 0));
  }, [props.lesson]);

  useEffect(() => {
    if (currentLessonIndex < 0 || !lessonsByTariff || !lessonsByTariff.length) {
      return;
    }

    const lessonId = lessonsByTariff[currentLessonIndex].lessonId;
    lessonsStore.findOrLoad(lessonId).then((lesson) => {
      if (!isMounted()) {
        return;
      }
      setSelectedLesson(lesson);
    });
  }, [currentLessonIndex, lessonsStore.data, lessonsStore.onLoading]);

  return (
    <ButtonToolbar
      style={{
        display: "flex",
        justifyContent: "center",
        marginBottom: 15,
      }}
    >
      <ButtonGroup>
        <IconButton
          icon={<PagePreviousIcon />}
          placement="left"
          size="xs"
          disabled={currentLessonIndex === 0}
          onClick={() => setCurrentLessonIndex((prev) => prev - 1)}
        >
          Прошлый
        </IconButton>
        <Button size="xs" ripple={false}>{`${
          currentLessonIndex + 1
        } урок`}</Button>
        <IconButton
          icon={<PageNextIcon />}
          placement="right"
          size="xs"
          disabled={currentLessonIndex + 1 === lessonsByTariff?.length}
          onClick={() => setCurrentLessonIndex((prev) => prev + 1)}
        >
          Следующий
        </IconButton>
      </ButtonGroup>
    </ButtonToolbar>
  );
};
export const LessonsControlButtons = observer(LessonsControlButtonsComponent);
