import { observer } from "mobx-react";
import { Link } from "react-router-dom";
import { Whisper } from "rsuite";
import { IHomeworkCheck } from "stores";
import { StudentHomeworkStatus } from "../TaskPackViewer/StudentHomeworkStatus";
import { useStudentHomeworkSwitchButton } from "../hooks";
import { StudentHomeworkInfo } from "./StudentHomeworkInfo";
import { Button, Popover } from "shared/ui";

type TStudentHomeworkSwitchButtonProps = {
  studentHomework: IHomeworkCheck;
};

const StudentHomeworkSwitchButtonComponent: React.FC<
  TStudentHomeworkSwitchButtonProps
> = ({ studentHomework }) => {
  const { isActive, isDisable, linkTo } =
    useStudentHomeworkSwitchButton(studentHomework);
  return (
    <Whisper
      key={studentHomework.tasksPackHwStudentId}
      placement="auto"
      speaker={
        <Popover title="Информация по домашке">
          <StudentHomeworkInfo studentHomework={studentHomework} />
        </Popover>
      }
    >
      <Button
        size="sm"
        as={Link}
        to={linkTo}
        disabled={isDisable}
        active={isActive}
      >
        <StudentHomeworkStatus homeworkCheck={studentHomework} />
      </Button>
    </Whisper>
  );
};

export const StudentHomeworkSwitchButton = observer(
  StudentHomeworkSwitchButtonComponent
);
