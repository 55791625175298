import { useLocation } from "react-router-dom";

export const useFailPayment = () => {
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);

  const message = searchParams.get("Message");

  const currentMessage =
    message && message.toLocaleLowerCase() !== "none" ? message : null;

  return { message: currentMessage };
};
