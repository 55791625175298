import { useStore } from "hooks";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { IEvent } from "stores";
import { ScheduleEvent } from "./ScheduleEvent";
import { Button, Loader } from "shared/ui";

interface IProps {
  date: Date;
}

const ScheduleCellContentComponent: React.FC<IProps> = ({ date }) => {
  const { eventStore } = useStore();
  const [events, setEvents] = useState<IEvent[]>([]);

  useEffect(() => {
    if (!eventStore.events || eventStore.events.length === 0) {
      setEvents([]);
      return;
    }
    const list = eventStore.events.filter(
      (x) => new Date(x.eventDateTime).toDateString() === date.toDateString()
    );

    setEvents(list);
  }, [eventStore.events]);

  const countRenderEvent = 2;

  if (eventStore.onLoading) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Loader />
      </div>
    );
  }

  if (!events || events.length === 0) {
    return null;
  }
  return (
    <ul
      className="we-calendar-todo-list-home-cell"
      style={{ listStyle: "none", margin: 0, padding: 0 }}
    >
      {events.slice(0, countRenderEvent).map((event) => (
        <li key={event.title}>
          <ScheduleEvent
            key={`${event.eventDateTime}_${event.tariffId}`}
            event={event}
          />
        </li>
      ))}
      {events.length > countRenderEvent && (
        <Button size="xs" block>
          {`+ ${events.length - countRenderEvent} события`}
        </Button>
      )}
    </ul>
  );
};
export const ScheduleCellContent = observer(ScheduleCellContentComponent);
