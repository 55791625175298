import { useNumberParams } from "hooks/useNumberParams";
import { HomeworkStudentStatus, IHomeworkCheck } from "stores";

export const useStudentHomeworkSwitchButton = (
  studentHomework: IHomeworkCheck
) => {
  const { lessonId, studentId, homeworkId, packId } = useNumberParams();

  const isActive =
    studentHomework.lessonId === lessonId &&
    studentHomework.studentId === studentId &&
    studentHomework.homeworkId === homeworkId &&
    studentHomework.packId === packId;

  const isDisable =
    studentHomework.homeworkStatus === HomeworkStudentStatus.notSend;

  const linkTo =
    studentHomework.homeworkStatus !== HomeworkStudentStatus.notSend
      ? `/checks/${studentHomework.lessonId}/${studentHomework.studentId}/${studentHomework.homeworkId}/${studentHomework.packId}`
      : "";

  return { isActive, isDisable, linkTo };
};
