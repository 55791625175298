import { GeneralMenuItems } from "./GeneralMenuItems";
import { IMenuItem } from "./IMenuItem";

export const MentorMenu = () => {
  const menuItems: IMenuItem[] = [];
  menuItems.push(GeneralMenuItems.Home);
  menuItems.push(GeneralMenuItems.Students);
  menuItems.push(GeneralMenuItems.Checks);
  menuItems.push(GeneralMenuItems.Top);
  return menuItems;
};
