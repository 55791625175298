import { FormikConfig } from "formik";
import { observer } from "mobx-react";
import React from "react";
import { Stack } from "rsuite";
import { Button } from "shared/ui";
import { FInput, FPasswordInput, Form } from "shared/ui/forms";
import { useStore } from "../../../hooks";
import { AutoLogin } from "./AutoLogin";
import { initialValues } from "./constants";
import { $LoginRequest, LoginRequest } from "./types";

interface ILogInProps {
  title: string;
  onClickRecovery: () => void;
  onLogin?: () => void;
  children?: React.JSX.Element;
}

const LogInComponent: React.FC<ILogInProps> = ({
  title,
  onClickRecovery,
  children,
  onLogin,
}) => {
  const { authStore } = useStore();
  const cfg: FormikConfig<LoginRequest> = {
    initialValues,
    validationSchema: $LoginRequest,
    onSubmit: async ({ login, password }) => {
      await authStore.auth(login, password);
      if (onLogin) {
        onLogin();
      }
    },
  };

  return (
    <>
      <h3 style={{ marginBottom: 12 }}>{title}</h3>
      {children}
      <Form config={cfg}>
        <Stack direction="column" alignItems="stretch" spacing={16}>
          <FInput
            name="login"
            label="Электронная почта или логин"
            withAsterisk
          />

          <FPasswordInput
            name="password"
            label="Пароль"
            withAsterisk
            labelProps={{ style: { marginTop: 24 } }}
          />
        </Stack>
        <div style={{ position: "relative" }}>
          <Button
            size="xs"
            appearance="link"
            onClick={onClickRecovery}
            style={{ position: "absolute", top: -4, right: 0 }}
          >
            <span>Забыли пароль?</span>
          </Button>
        </div>
        <Button
          block
          appearance="primary"
          type="submit"
          id="loginBtn"
          style={{ marginTop: 40 }}
        >
          Войти
        </Button>
      </Form>

      <AutoLogin onLogin={onLogin} />
    </>
  );
};

export const LogIn = observer(LogInComponent);
