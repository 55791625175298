import React from "react";
import { DateHelper } from "root/DateHelper";
import { Panel } from "shared/ui";

interface INotificationPanelProps {
  title: string;
  description: string;
  date: Date;
}

export const NotificationPanel: React.FC<INotificationPanelProps> = ({
  title,
  description,
  date,
}) => {
  const dateTime = DateHelper.getDateToStrLocaleShort(date, true);

  return (
    <>
      <Panel bodyFill style={{ padding: "5px 0px 5px 0px" }}>
        <div>{title}</div>
        <div
          style={{
            marginBottom: 6,
            color: "rgb(187, 187, 187)",
          }}
        >
          {dateTime}
        </div>
        <div>{description}</div>
      </Panel>
      <hr style={{ marginBottom: 0, marginTop: 5 }} />
    </>
  );
};
