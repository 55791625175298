import React from "react";
import { Stack } from "rsuite";
import { ButtonMenu, Logo, ButtonTariffChange } from ".";

type TLeftSideMenu = {
  onClick: () => void;
};

export const LeftSideHeader: React.FC<TLeftSideMenu> = ({ onClick }) => (
  <Stack justifyContent="flex-end" style={{ paddingRight: 35 }}>
    <ButtonMenu onClick={onClick} />
    <Logo />
    <ButtonTariffChange />
  </Stack>
);
