import { NotificationHelper } from "root/NotificationHelper";

type CopyFn = (text: string) => Promise<boolean>; // Return success

export function useCopyToClipboard(withToast = true): CopyFn {
  const copy: CopyFn = async (text) => {
    if (!navigator?.clipboard) {
      console.warn("Clipboard not supported");
      return false;
    }

    // Try to save to clipboard then save it in the state if worked
    try {
      await navigator.clipboard.writeText(text);
      withToast && NotificationHelper.ShowSuccess("Успешно скопировано");
      return true;
    } catch (error) {
      console.warn("Copy failed", error);
      withToast && NotificationHelper.ShowError("Скопировать не удалось");
      return false;
    }
  };

  return copy;
}
