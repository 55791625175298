import React from "react";
import { InputLabelProps } from "./types";

export const InputLabel: React.FC<InputLabelProps> = ({
  label,
  withAsterisk,
  htmlFor,
  children,
  ...rest
}) => {
  return (
    <>
      {label && (
        <div style={{ marginBottom: 4 }}>
          <label htmlFor={htmlFor} {...rest}>
            {label}
            {withAsterisk && (
              <span className="wavege-error-text-color"> *</span>
            )}
          </label>
        </div>
      )}
      {children}
    </>
  );
};
