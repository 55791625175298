import { usePageFilter, useStore } from "hooks";
import { GlobalConstants } from "root/GlobalConstants";
import { IRequestGetPaymentsByFilter } from "root/requests";
import { TOrder } from "stores";

const initialFilter = {
  count: 10,
  page: 1,
};

export function useHistoryOrdersPageNavigation() {
  const { paymentStore } = useStore();

  const { filter, dataPage, setLimit, setActivePage, onChangeFilter } =
    usePageFilter<TOrder, IRequestGetPaymentsByFilter>(
      initialFilter,
      paymentStore,
      GlobalConstants.filterHistoryOrders
    );

  return {
    filter,
    dataPage,
    setLimit,
    setActivePage,
    onChangeFilter,
  };
}
