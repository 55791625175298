import { observer } from "mobx-react";
import { SelectPicker, Stack } from "rsuite";
import { EnumConverter, GlobalConstants } from "root";
import { ExamType, TariffType } from "stores";
import { useEffect } from "react";
import { IFilterCatalog } from "../IFilterCatalog";
import { useSessionStorage } from "hooks/useLocalSessionsStorage";
import { useLocation, useNavigate } from "react-router-dom";

interface IFilterProps {
  onChange: (filterCatalog: IFilterCatalog) => void;
}

const FilterComponent: React.FC<IFilterProps> = (props: IFilterProps) => {
  const [filterCatalog, setFilterCatalog] = useSessionStorage<IFilterCatalog>(
    GlobalConstants.filterCatalog,
    { exam: GlobalConstants.allExamType, tariff: GlobalConstants.allTariffType }
  );
  const navigate = useNavigate();
  const location = useLocation();
  const isFreeTariff = location.pathname.includes(
    GlobalConstants.onlyFreeTariff
  );

  useEffect(() => {
    props.onChange(filterCatalog);
  }, [filterCatalog]);

  useEffect(() => {
    if (!isFreeTariff) {
      setFilterCatalog((prev) => ({ ...prev, tariff: 0 }));
      return;
    }
    setFilterCatalog((prev) => ({ ...prev, tariff: TariffType.Free }));
  }, [isFreeTariff]);

  const examType: {
    label: string;
    value: ExamType | number;
  }[] = EnumConverter.mapEnum(ExamType, EnumConverter.typeExamToStr);
  examType.unshift({
    label: "Все экзамены",
    value: GlobalConstants.allExamType,
  });

  const tariffType: {
    label: string;
    value: TariffType | number;
  }[] = EnumConverter.mapEnum(TariffType, EnumConverter.typeTariffToStr);
  tariffType.unshift({
    label: "Все курсы",
    value: GlobalConstants.allTariffType,
  });

  return (
    <>
      <Stack spacing={10} wrap>
        <SelectPicker
          value={filterCatalog.exam}
          data={examType}
          onChange={(value: number | null) => {
            const exam = value ? value : GlobalConstants.allExamType;
            setFilterCatalog((prev) => ({ ...prev, exam }));
          }}
        />
        <SelectPicker
          value={filterCatalog.tariff}
          data={tariffType}
          onChange={(value: number | null) => {
            if (value === TariffType.Free) {
              navigate(`/catalog/${GlobalConstants.onlyFreeTariff}`);
            } else {
              navigate("/catalog");
            }
            const tariff = value ? value : GlobalConstants.allTariffType;
            setFilterCatalog((prev) => ({ ...prev, tariff }));
          }}
        />
      </Stack>
    </>
  );
};

export const Filter = observer(FilterComponent);
