import React from "react";
import { RefForwardingComponent } from "../types";
import { PaginationProps } from "./types";
import { Pagination as RSPagination } from "rsuite";

const pagination = <E extends React.ElementType>(
  { ...props }: PaginationProps,
  ref: React.ForwardedRef<E>
) => <RSPagination {...props} ref={ref} />;

export const Pagination: RefForwardingComponent<"div", PaginationProps> =
  React.forwardRef(pagination);
