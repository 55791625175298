import { ViewFileModal } from "components/Modal/ViewFileModal/ViewFileModal";
import { useAnswerCriteria } from "./useAnswerCriteria";

const titleStyle = {
  marginBottom: 5,
  marginTop: 15,
  color: "#bbbbbb",
};

type TAnswerCriteriaProps = {
  fileCriteriaUrl: string | undefined;
};

export const AnswerCriteria: React.FC<TAnswerCriteriaProps> = ({
  fileCriteriaUrl,
}) => {
  const { handleCloseModal, handleOpenModal, isOpenViewFileModal } =
    useAnswerCriteria();
  if (!fileCriteriaUrl) {
    return null;
  }
  return (
    <>
      <ViewFileModal
        imgSrc={fileCriteriaUrl}
        isOpen={isOpenViewFileModal}
        onCancel={handleCloseModal}
      />
      <p style={titleStyle}>Критерии:</p>
      <img
        className="img-file-answer"
        src={fileCriteriaUrl}
        alt="Критерии"
        onClick={handleOpenModal}
      />
    </>
  );
};
