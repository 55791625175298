import { SelectPickerMentors } from "components/FormComponents/SelectPickerMentors";
import { SelectPickerStudents } from "components/FormComponents/SelectPickerStudents";
import { SelectPickerTariffId } from "components/FormComponents/SelectPickerTariffId";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import {
  EnumConverter,
  IRequestGetHomeworksCheckByFilter,
  TextHelper,
} from "root";
import { SelectPicker, Stack } from "rsuite";
import {
  HomeworkStudentCheckStatus,
  HomeworkStudentDeadlineStatus,
  HomeworkStudentStatus,
  RoleNames,
} from "stores";
import { SelectPickerLessonId } from "../../FormComponents/SelectPickerLessonId/SelectPickerLessonId";

interface IFilterByHomeworkCheckProps {
  onChange: (lessonsListFilter: IRequestGetHomeworksCheckByFilter) => void;
  defaultValue: IRequestGetHomeworksCheckByFilter;
}

const FilterByHomeworkCheckComponent: React.FC<IFilterByHomeworkCheckProps> = (
  props
) => {
  const { teacherStore, authStore } = useStore();

  const [filter, setFilter] = useState<IRequestGetHomeworksCheckByFilter>(
    props.defaultValue
  );

  useEffect(() => {
    props.onChange(filter);
  }, [filter]);

  const teacherList = teacherStore.teachers.map((x) => ({
    label: TextHelper.GetShortName(x.user),
    value: x.id,
  }));

  const checkStatusList = EnumConverter.mapEnum(
    HomeworkStudentCheckStatus,
    EnumConverter.homeworkStudentCheckStatusToStr
  );
  const homeWorkStatusList = EnumConverter.mapEnum(
    HomeworkStudentStatus,
    EnumConverter.homeworkStudentStatusToStr
  );
  const dedlineHomeWorkList = EnumConverter.mapEnum(
    HomeworkStudentDeadlineStatus,
    EnumConverter.homeworkStudentDeadlineStatusToStr
  );

  return (
    <>
      <Stack spacing={10} wrap>
        {(authStore.me?.role === RoleNames.Super ||
          authStore.me?.role === RoleNames.Admin) && (
          <SelectPicker
            defaultValue={filter.teacherId}
            label="Преподаватель"
            data={teacherList}
            style={{ width: 224 }}
            onChange={(value) => {
              setFilter((prev) => ({
                ...prev,
                teacherId: value || undefined,
              }));
            }}
          />
        )}
        {(authStore.me?.role === RoleNames.Super ||
          authStore.me?.role === RoleNames.Admin ||
          authStore.me?.role === RoleNames.Teacher) && (
          <SelectPickerMentors
            defaultValue={filter.mentorId}
            onChange={(value) =>
              setFilter((prev) => ({
                ...prev,
                mentorId: value || undefined,
              }))
            }
          />
        )}
        <SelectPickerStudents
          defaultValue={filter.studentId}
          onChange={(value) =>
            setFilter((prev) => ({
              ...prev,
              studentId: value || undefined,
            }))
          }
        />
        <SelectPickerTariffId
          defaultValue={filter.tariffId || undefined}
          onChange={(value: number | null) =>
            setFilter((prev) => ({
              ...prev,
              tariffId: value,
              lessonId: null,
            }))
          }
        />
        <SelectPickerLessonId
          defaultValue={filter.lessonId}
          tariffId={filter.tariffId || undefined}
          onChange={(value) => {
            setFilter((prev) => ({
              ...prev,
              lessonId: value,
            }));
          }}
        />
      </Stack>
      <Stack spacing={10} wrap style={{ marginTop: 15 }}>
        <SelectPicker
          searchable={false}
          defaultValue={filter.homeworkStatus}
          label="Статус домашки"
          data={homeWorkStatusList}
          style={{ width: 224 }}
          onChange={(value) => {
            setFilter((prev) => ({
              ...prev,
              homeworkStatus: value || undefined,
            }));
          }}
        />
        <SelectPicker
          searchable={false}
          defaultValue={filter.checkStatus}
          label="Статус проверки"
          data={checkStatusList}
          style={{ width: 224 }}
          onChange={(value) => {
            setFilter((prev) => ({
              ...prev,
              checkStatus: value || undefined,
            }));
          }}
        />
        <SelectPicker
          searchable={false}
          defaultValue={filter.deadlineStatus}
          label="Дедлайн проверки"
          data={dedlineHomeWorkList}
          style={{ width: 224 }}
          onChange={(value) => {
            setFilter((prev) => ({
              ...prev,
              dedlineHomeWork: value,
            }));
          }}
        />
      </Stack>
    </>
  );
};

export const FilterByHomeworkCheck = observer(FilterByHomeworkCheckComponent);
