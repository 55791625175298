import CheckOutlineIcon from "@rsuite/icons/CheckOutline";
import React from "react";
import { Panel } from "shared/ui";

interface IProps {
  title: string;
}

export const CheckmarkPanel: React.FC<IProps> = ({ title }) => {
  return (
    <Panel bordered style={{ marginBottom: 10 }}>
      <p>
        <CheckOutlineIcon
          style={{ color: "green", marginRight: 10, fontSize: "2.1em" }}
        />
        {title}
      </p>
    </Panel>
  );
};
