import { Placeholder } from "rsuite";

interface IPlaceholderLessonProps {
  items: number;
}
export const PlaceholderLesson: React.FC<IPlaceholderLessonProps> = (
  props: IPlaceholderLessonProps
) => {
  const fakeArray = Array(props.items).fill(0);
  return (
    <>
      {fakeArray.map((x, i) => (
        <Placeholder.Graph
          key={i}
          active
          height={170}
          style={{
            borderRadius: "var(--we-default-border-radius)",
            border: "1px solid var(--rs-border-primary)",
            margin: 5,
          }}
        />
      ))}
    </>
  );
};
