import { useStore } from "hooks";
import React, { useEffect, useState } from "react";
import { Placeholder, Stack, Whisper } from "rsuite";
import { observer } from "mobx-react";
import { PartPercentType } from "stores";
import InfoIcon from "@rsuite/icons/legacy/Info";
import { EmptyStatePanel } from "components";
import { StudentProgressLine } from "./StudentProgressLine";
import { Panel, Tooltip } from "shared/ui";

interface IStudentProgressPartProps {
  studentId?: number;
  partPercentType: PartPercentType;
}

export const StudentProgressPartComponent: React.FC<
  IStudentProgressPartProps
> = (props) => {
  const { statisticStore } = useStore();
  const [isLoading, setIsLoading] = useState(true);
  const [isEmptyData, setIsEmptyData] = useState(false);
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  useEffect(() => {
    if (!props.studentId) {
      return;
    }

    statisticStore.getProgressChartData(props.studentId);
  }, [props.studentId]);

  useEffect(() => {
    if (statisticStore.onLoading) {
      setIsLoading(true);
      return;
    }
    if (
      !statisticStore.onLoading &&
      statisticStore.progressChart.length === 0
    ) {
      setIsEmptyData(true);
      setIsLoading(false);
      return;
    }
    switch (props.partPercentType) {
      case PartPercentType.FirstPart:
        setTitle("Качество по 1 частям");
        setDescription("Средний балл по 1 частям в домашках");
        break;
      case PartPercentType.SecondPart:
        setTitle("Качество по 2 частям");
        setDescription("Средний балл по 2 частям в домашках");
        break;
      case PartPercentType.AllPart:
        setTitle("Общая успеваемость");
        setDescription("Средний балл в домашках");
        break;
    }
    setIsLoading(false);
  }, [statisticStore.onLoading, statisticStore.progressChart]);

  if (isLoading) {
    return (
      <Panel bordered style={{ marginBottom: 11 }}>
        <Placeholder.Paragraph active={true} />
      </Panel>
    );
  }
  if (isEmptyData) {
    return (
      <Panel bordered style={{ marginBottom: 11 }}>
        <EmptyStatePanel bordered={false} bodyFill={true} />
      </Panel>
    );
  }

  return (
    <>
      <Panel bordered style={{ marginBottom: 11 }}>
        <>
          <Stack spacing={10}>
            <span>{title}</span>
            <Whisper speaker={<Tooltip>{description}</Tooltip>}>
              <InfoIcon />
            </Whisper>
          </Stack>
          <StudentProgressLine
            strokeWidth={25}
            partPercentType={props.partPercentType}
          />
        </>
      </Panel>
    </>
  );
};
export const StudentProgressPart = observer(StudentProgressPartComponent);
