import { useStore } from "hooks";
import { observer } from "mobx-react";
import { TextHelper } from "root/TextHelpers";
import { Cell } from "rsuite-table";
import { IGlobalNotification } from "stores";

interface IUserGlobalNotificationCellProps {
  rowData?: IGlobalNotification;
  dataKey: string;
}

export const UserGlobalNotificationCellComponent: React.FC<
  IUserGlobalNotificationCellProps
> = (props) => {
  const { userStore } = useStore();

  if (!props.rowData) {
    return <Cell {...props}></Cell>;
  }

  if (!props.rowData.userId) {
    return <Cell {...props}>—</Cell>;
  }

  return (
    <>
      <Cell {...props}>
        {TextHelper.GetShortName(userStore.find(props.rowData.userId))}
      </Cell>
    </>
  );
};
export const UserGlobalNotificationCell = observer(
  UserGlobalNotificationCellComponent
);
