import { useIsMounted, useStore } from "hooks";
import { useEffect, useMemo, useState } from "react";
import { DateHelper } from "root/DateHelper";
import {
  HomeworkStudentCheckStatus,
  HomeworkStudentStatus,
  IHomeworkCheck,
  IStudentHomework,
} from "stores";

export const useStudentHomeworkInfo = (studentHomework: IHomeworkCheck) => {
  const isMounted = useIsMounted();
  const { homeworkStore } = useStore();

  const [answersStudent, setAnswersStudent] = useState<IStudentHomework>();

  useEffect(() => {
    handleGetStudentAnswers(
      studentHomework.lessonId,
      studentHomework.studentId
    );
  }, [studentHomework]);

  const handleGetStudentAnswers = async (
    lessonId: number,
    studentId: number
  ) => {
    const result = await homeworkStore.studentAnswersByLesson(
      lessonId,
      studentId
    );
    if (!isMounted()) {
      return;
    }
    setAnswersStudent(
      result?.find(
        (x) =>
          x.packId === studentHomework.packId &&
          x.homeworkId === studentHomework.homeworkId &&
          x.lessonId === studentHomework.lessonId
      )
    );
  };

  const getEmojiByStatus = (
    homeworkStatus: HomeworkStudentStatus
  ): React.JSX.Element | null => {
    switch (homeworkStatus) {
      case HomeworkStudentStatus.sendLate:
        return <>💀</>;
      case HomeworkStudentStatus.sendCorrect:
        return <>✅</>;
      case HomeworkStudentStatus.notSend:
        return <>🛑</>;
      default:
        return null;
    }
  };

  const dateTitle = useMemo(
    () =>
      answersStudent &&
      DateHelper.changeDateToStrLocaleLongWithTime(
        answersStudent?.answers?.[0]?.dateCreate
      ),
    [answersStudent]
  );
  const dateEmoji = useMemo(
    () => getEmojiByStatus(studentHomework.homeworkStatus),
    [studentHomework.homeworkStatus]
  );

  const isHomeworkNotSend =
    studentHomework.homeworkStatus === HomeworkStudentStatus.notSend;

  const isHomeworkChecked =
    studentHomework.checkStatus !== HomeworkStudentCheckStatus.notChecked;

  return { isHomeworkNotSend, isHomeworkChecked, dateTitle, dateEmoji };
};
