import { CSSProperties, useEffect, useMemo, useState } from "react";
import { useStore } from "../../hooks";
import { getMenu } from "containers/Menu/Map";

const fixedMenu: CSSProperties = {
  animation: "slide-in-left .3s ease-in-out",
  left: 0,
  pointerEvents: "all",
  visibility: "visible",
  zIndex: 999,
  position: "fixed",
  backgroundColor: "var(--rs-sidenav-bg)",
  height: "100vh",
  display: "block !important",
  width: 250,
};
const fixedMenuBackdrop: CSSProperties = { display: "block" };

export const useMenu = (isOpenMenu: boolean, onClick: () => void) => {
  const { authStore } = useStore();
  const me = authStore.me;
  const expand = true;
  const [isVisibleMenu, setIsVisibleMenu] = useState(true);
  const [selected, setSelected] = useState("");

  useEffect(() => {
    setIsVisibleMenu(isOpenMenu);
  }, [isOpenMenu]);

  const changeSelectedItem = (value: string) => {
    setSelected(value);
    if (value) {
      onClick();
    }
  };

  const widthSidebar = expand ? 210 : 56;
  const sidebarStyle = isVisibleMenu ? {} : fixedMenu;
  const backdropStyle = isVisibleMenu ? {} : fixedMenuBackdrop;
  const boxClassName = me ? "" : "invisible-by-min-width-993";

  const menu = useMemo(() => getMenu(me?.role), [me?.role]);
  return {
    menu,
    boxClassName,
    backdropStyle,
    sidebarStyle,
    me,
    expand,
    widthSidebar,
    isVisibleMenu,
    selected,
    changeSelectedItem,
  };
};
