import { EditorState, RichUtils } from "draft-js";
import React from "react";

interface IProps {
  icon: string;
  inlineStyle: string;
  title: string;
  editorState: EditorState | undefined;
  onEditorStateChange: (value: EditorState) => void;
}

export const ColorButton: React.FC<IProps> = ({
  icon,
  inlineStyle,
  title,
  editorState,
  onEditorStateChange,
}) => {
  const onClick = () => {
    if (!editorState) {
      return;
    }
    const coloredEditorState = RichUtils.toggleInlineStyle(
      editorState,
      inlineStyle
    );
    onEditorStateChange(coloredEditorState);
  };

  return (
    <div className="rdw-option-wrapper" onClick={onClick} title={title}>
      {icon}
    </div>
  );
};
