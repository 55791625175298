import { SelectPickerTariffId } from "components/FormComponents/SelectPickerTariffId";
import { observer } from "mobx-react";
import React from "react";
import { Calendar, SelectPicker, Stack } from "rsuite";
import { ScheduleDrawer } from "./Drawer/ScheduleDrawer";
import { ScheduleCellContent } from "./ScheduleCellContent";
import { SchedulePlaceholder } from "./SchedulePlaceholder";
import { useSchedule } from "./hooks/useSchedule";

const ScheduleComponent: React.FC = () => {
  const {
    isStudent,
    data,
    handleChange,
    open,
    selectedDate,
    selectedType,
    setOpen,
    setSelectedType,
    isLoading,
    selectedTariff,
    setSelectedTariff,
  } = useSchedule();

  if (isLoading) {
    return <SchedulePlaceholder />;
  }

  return (
    <>
      <ScheduleDrawer
        date={selectedDate}
        isOpen={open}
        onClose={() => setOpen(false)}
      />
      <div className="main-schedule">
        <Stack spacing={10}>
          <SelectPicker
            searchable={false}
            onChange={setSelectedType}
            label="Фильтр"
            data={data}
            style={{ width: 224, paddingLeft: 12 }}
          />
          {!isStudent && (
            <SelectPickerTariffId
              onChange={(value: number | null) => setSelectedTariff(value)}
            />
          )}
        </Stack>
        <Calendar
          isoWeek
          bordered
          renderCell={(date) => (
            <ScheduleCellContent
              date={date}
              eventType={selectedType}
              tariffId={selectedTariff}
            />
          )}
          onSelect={handleChange}
        />
      </div>
    </>
  );
};

export const Schedule = observer(ScheduleComponent);
