import { ITaskPack, IAnswer } from "stores";
import { ITaskWithAnswer } from "./ITaskWithAnswer";
import { TaskWithAnswerItem } from "./TaskWithAnswerItem";
import { CSSProperties } from "react";
import { Panel } from "shared/ui";

const style: CSSProperties = {
  marginTop: 10,
  marginBottom: 25,
  whiteSpace: "pre-line",
  overflowWrap: "break-word",
};

interface ITaskWithAnswerListProps extends ITaskWithAnswer {
  taskPack: ITaskPack | undefined;
  answers: IAnswer[] | undefined;
}

export const TaskWithAnswerList: React.FC<ITaskWithAnswerListProps> = ({
  validationResult,
  disabledInput,
  answers,
  onChange,
  taskPack,
}) => {
  return (
    <div style={style}>
      {taskPack?.tasks.map((task) => {
        const answer = answers?.find((x) => x.taskId === task.id);
        return (
          <Panel key={task.id} bodyFill>
            <TaskWithAnswerItem
              answer={answer}
              task={task}
              onChange={onChange}
              validationResult={validationResult}
              disabledInput={disabledInput}
            />
          </Panel>
        );
      })}
    </div>
  );
};
