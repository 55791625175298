import { useStore } from "hooks";
import { observer } from "mobx-react";
import React from "react";
import { Stack } from "rsuite";
import {
  ButtonAllTariff,
  ButtonToolbarForUnauthorized,
  DevEnvSignature,
  // NotificationButton,
  ProfileButton,
  ThemeButton,
} from ".";

const RightSideHeaderComponent: React.FC = () => {
  const { authStore } = useStore();

  return (
    <Stack spacing={8} className="we-right-stack">
      <DevEnvSignature />
      {!authStore.me && <ButtonAllTariff />}
      {/* TODO включить после настройки телеграм бота */}
      {/* {authStore.me && <NotificationButton />} */}
      <ThemeButton />
      {authStore.me && <ProfileButton />}
      {!authStore.me && <ButtonToolbarForUnauthorized />}
    </Stack>
  );
};
export const RightSideHeader = observer(RightSideHeaderComponent);
