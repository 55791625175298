import { makeAutoObservable, runInAction } from "mobx";
import Api from "../root/Api";
import { IPage, IPageableStore, IPayOrder, IPayment, TOrder } from "./models";
import { queryClient } from "provider/QueryProvider/QueryProvider";
import { IRequestGetPaymentsByFilter } from "root/requests";

export class PaymentStore
  implements IPageableStore<IRequestGetPaymentsByFilter, TOrder>
{
  onLoading = false;
  payOrder: IPayment | undefined = undefined;

  filter: IRequestGetPaymentsByFilter | undefined;
  dataPage: IPage<TOrder> | undefined;

  constructor() {
    makeAutoObservable(this);
  }

  public async calculateAmount(order: IPayOrder) {
    this.setOnLoading(true);
    try {
      const result = await queryClient.fetchQuery({
        queryKey: ["Api.payment.CalculateAmount", order],
        queryFn: async () => await Api.payment.CalculateAmount(order),
      });
      runInAction(() => result && (this.payOrder = result));
      return result;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.setOnLoading(false);
    }
  }

  public async getPayUrl(finishOrder: IPayOrder, factAmount: number) {
    this.setOnLoading(true);
    try {
      const result = await Api.payment.PayUrl(finishOrder, factAmount);
      return result;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.setOnLoading(false);
    }
  }

  public async loadByFilter(
    filter: IRequestGetPaymentsByFilter,
    refetch = false
  ): Promise<IPage<TOrder> | undefined> {
    this.setOnLoading(true);
    const queryKey = ["payment", filter];
    try {
      refetch && queryClient.removeQueries(queryKey);
      const result = await queryClient.fetchQuery({
        queryKey,
        queryFn: async () =>
          await Api.payment.GetPaymentsByFilter(filter, refetch),
      });
      runInAction(() => {
        this.filter = filter;
        this.dataPage = result;
      });
      return result;
    } catch (error) {
      console.error(error);
      return undefined;
    } finally {
      this.setOnLoading(false);
    }
  }

  protected setOnLoading(value: boolean) {
    this.onLoading = value;
  }
  public clear() {
    runInAction(() => {
      this.dataPage = undefined;
      this.filter = undefined;
      this.payOrder = undefined;
    });
  }
}
