import { Whisper } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import { useState } from "react";
import { ModalFormGlobalNotification } from "./ModalFormGlobalNotification";
import { IconButton, Tooltip } from "shared/ui";

export const ButtonCreateGlobalNotification: React.FC = () => {
  const [isOpenEditModal, setOpenEditModal] = useState<boolean>(false);

  return (
    <>
      <ModalFormGlobalNotification
        isOpen={isOpenEditModal}
        onClose={() => setOpenEditModal(false)}
      />
      <Whisper speaker={<Tooltip>Создать глобальное уведомление</Tooltip>}>
        <IconButton
          icon={<PlusIcon />}
          size="sm"
          appearance="primary"
          color="orange"
          onClick={() => setOpenEditModal(true)}
        />
      </Whisper>
    </>
  );
};
