import { Textarea } from "components/FormComponents";
import React from "react";
import { Form } from "rsuite";

type TTextareaInputProps = {
  label: string;
  defaultValue?: string;
  onChange: (value: string) => void;
  helpText?: string;
};

export const TextareaInput: React.FC<TTextareaInputProps> = ({
  label,
  defaultValue,
  onChange,
  helpText,
}) => (
  <>
    <label>
      {`${label}:`}
      <Textarea defaultValue={defaultValue} onChange={onChange} />
    </label>
    {helpText && (
      <Form.HelpText style={{ marginTop: 6 }}>{helpText}</Form.HelpText>
    )}
  </>
);
