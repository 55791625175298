import { makeAutoObservable, runInAction } from "mobx";
import Api from "../root/Api";
import { IEvent } from "./models";
import { queryClient } from "provider/QueryProvider/QueryProvider";
import { startOfDay } from "date-fns";

export class EventStore {
  events: IEvent[] | undefined = undefined;
  onLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  getEventList(date: Date) {
    const result = this.events?.filter(
      (x) => new Date(x.eventDateTime).toDateString() === date.toDateString()
    );
    return result;
  }

  async load(date: Date, refetch = false) {
    this.setOnLoading(true);
    const dateForLoad = startOfDay(date.setDate(15));
    const queryKey = ["events", dateForLoad];
    try {
      refetch && queryClient.removeQueries(queryKey);
      const result = await queryClient.fetchQuery({
        queryKey,
        queryFn: async () => await Api.GetEvents(dateForLoad, refetch),
      });
      runInAction(() => result && (this.events = result));
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
    return null;
  }

  private setOnLoading(value: boolean) {
    this.onLoading = value;
  }

  public clear() {
    runInAction(() => {
      this.events = undefined;
    });
  }
}
