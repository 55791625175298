import { useStore } from "hooks";
import { useLocalStorage } from "hooks/useLocalSessionsStorage";
import _ from "lodash";
import { autorun } from "mobx";
import { useEffect, useRef, useState } from "react";
import { GlobalConstants } from "root/GlobalConstants";
import { WhisperInstance } from "rsuite";

export const usePopoverTariffChange = () => {
  const { tariffStore, authStore } = useStore();

  const trigger = useRef<WhisperInstance>(null);

  const [myTariffs, setMyTariffs] = useState(tariffStore.getMyTariffs());

  const [selectedTariffId, setSelectedTariffId] = useLocalStorage<
    number | null
  >(GlobalConstants.selectedTariffId, null);

  useEffect(() => {
    autorun(() => {
      const tariffs = tariffStore.getMyTariffs();
      setMyTariffs(tariffs);
    });
  }, [tariffStore.myTariffs]);

  useEffect(() => {
    !selectedTariffId && authStore.me && setSelectedTariffId(myTariffs[0]?.id);
  }, [tariffStore.data, myTariffs]);

  useEffect(() => {
    autorun(() => {
      selectedTariffId && tariffStore.setSelectedTariff(selectedTariffId);
    });
  }, [selectedTariffId]);

  const handleSelectTariff = (eventKey: string | undefined) => {
    if (!eventKey) {
      return;
    }
    const tariffId = parseInt(eventKey);
    if (_.isNaN(tariffId)) {
      return;
    }
    setSelectedTariffId(tariffId);
    tariffStore.setSelectedTariff(tariffId);
  };

  const hasTariffs = myTariffs.length && authStore.me;

  return {
    handleSelectTariff,
    myTariffs,
    selectedTariffId,
    trigger,
    hasTariffs,
  };
};
