import { useFormikContext } from "formik";
import { useEffect } from "react";

export type DetectorFormUpdateProps = {
  onChange: () => void;
};

export default function DetectorFormUpdate({
  onChange,
}: DetectorFormUpdateProps) {
  const { values } = useFormikContext();

  useEffect(() => {
    onChange();
  }, [values]);

  return null;
}
