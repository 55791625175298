import React, { useRef, useState } from "react";
import { observer } from "mobx-react";
import { Whisper, WhisperInstance } from "rsuite";
import NoticeIcon from "@rsuite/icons/Notice";
import { PopoverNotification } from "./PopoverNotification";
import { NotificationSettingsModal } from "./NotificationSettingsModal";
import { Icon, IconButton } from "shared/ui";

const NotificationButtonComponent: React.FC = () => {
  const trigger = useRef<WhisperInstance>(null);
  const [isOpenModal, setIsOpenModal] = useState(false);

  const handleCloseModal = () => setIsOpenModal(false);
  const handleOpenModal = () => setIsOpenModal(true);

  return (
    <>
      <NotificationSettingsModal
        onClose={handleCloseModal}
        opened={isOpenModal}
      />
      <Whisper
        placement="bottomEnd"
        trigger="click"
        ref={trigger}
        speaker={(props, ref) => (
          <PopoverNotification
            {...props}
            ref={ref}
            onClickSettingsButton={handleOpenModal}
          />
        )}
      >
        <IconButton
          style={{ border: "none" }}
          icon={<Icon as={NoticeIcon} style={{ fontSize: 18 }} />}
        />
      </Whisper>
    </>
  );
};
export const NotificationButton = observer(NotificationButtonComponent);
