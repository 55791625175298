import React from "react";
import { Modal } from "rsuite";
import { StudentTariffLink } from "../StudentTariffLink";

interface ILinkModalProps {
  tariffId?: number;
  studentId?: number;
  mentorId?: number;
  isOpen: boolean;
  onClose: () => void;
  title?: string;
}

export const LinkModal: React.FC<ILinkModalProps> = ({
  tariffId,
  studentId,
  mentorId,
  isOpen,
  onClose,
  title = "Назначение курса",
}) => {
  return (
    <Modal backdrop="static" open={isOpen} onClose={onClose} size="sm">
      <Modal.Header>
        <Modal.Title>{title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ margin: 5 }}>
          <StudentTariffLink
            tariffId={tariffId}
            studentId={studentId}
            mentorId={mentorId}
            onClose={onClose}
          />
        </div>
      </Modal.Body>
    </Modal>
  );
};
