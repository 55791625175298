import { useStore } from "hooks";
import { useState } from "react";
import { EnumConverter } from "root/EnumConverter";
import { TextHelper } from "root/TextHelpers";
import { ValidationHelper } from "root/ValidationHelper";
import { IRequestPayTariff } from "root/requests";
import { ButtonToolbar, Form, SelectPicker } from "rsuite";
import { IStudentTariffPayment, PaymentType } from "stores";
import { ValidationResult } from "stores/models/ValidationResult";
import { StudentTariffPayment } from "./StudentTariffPayment";
import { Button, Input, InputNumber } from "shared/ui";

interface IStudentTariffPayProps {
  tariffId?: number;
  studentId?: number;
  payments?: IStudentTariffPayment[];
  onClose?: () => void;
}
export const StudentTariffPay: React.FC<IStudentTariffPayProps> = (props) => {
  const { tariffStore, studentsStore, studentTariffStore } = useStore();

  const [validationResult, setValidationResult] = useState<ValidationResult>();
  const [formValue, setFormValue] = useState<Partial<IRequestPayTariff>>({
    tariffId: props.tariffId,
    studentId: props.studentId,
    type: undefined,
    amount: 9999,
    monthNumber: undefined,
    comment: "",
  });

  const paymentTypeListItems = EnumConverter.mapEnum(
    PaymentType,
    EnumConverter.PaymentTypeToStr
  );

  const handleSubmit = async () => {
    const request: Partial<IRequestPayTariff> = {
      tariffId: formValue.tariffId,
      studentId: formValue.studentId,
      type: formValue.type,
      amount: formValue.amount,
      comment: formValue.comment,
      monthNumber: formValue.monthNumber,
    };

    const resultValidation = ValidationHelper.studentTariffPayValidate(request);
    setValidationResult(resultValidation);

    if (
      resultValidation.hasNotError() &&
      (await studentTariffStore.pay(request as IRequestPayTariff))
    ) {
      props.onClose && props.onClose();
    }
  };

  const tariffIdOptions = tariffStore.data.map((item) => ({
    label: item.title,
    value: item.id,
  }));

  const studentIdOptions = studentsStore.data.map((item) => ({
    label: TextHelper.GetShortName(item.user, true),
    value: item.id,
  }));

  return (
    <Form
      style={{ padding: 10 }}
      formValue={formValue}
      onChange={setFormValue}
      onSubmit={handleSubmit}
    >
      <Form.Group controlId="tariffId">
        <Form.ControlLabel>Курс</Form.ControlLabel>
        <Form.Control
          name="tariffId"
          accepter={SelectPicker}
          disabled={!!props.tariffId}
          data={tariffIdOptions}
          errorMessage={validationResult?.getError("tariffId")}
        />
      </Form.Group>
      <Form.Group controlId="studentId">
        <Form.ControlLabel>Ученик</Form.ControlLabel>

        <Form.Control
          name="studentId"
          accepter={SelectPicker}
          disabled={!!props.studentId}
          data={studentIdOptions}
          errorMessage={validationResult?.getError("studentId")}
        />
      </Form.Group>
      <Form.Group controlId="type">
        <Form.ControlLabel>Тип оплаты</Form.ControlLabel>
        <Form.Control
          name="type"
          accepter={SelectPicker}
          data={paymentTypeListItems}
          errorMessage={validationResult?.getError("type")}
        />
      </Form.Group>
      <Form.Group controlId="monthNumber">
        <Form.ControlLabel>Номер бизнес-месяца</Form.ControlLabel>
        <Form.Control
          name="monthNumber"
          accepter={InputNumber}
          min={1}
          errorMessage={validationResult?.getError("monthNumber")}
        />
      </Form.Group>
      <StudentTariffPayment payments={props.payments} />
      <Form.Group controlId="amount">
        <Form.ControlLabel>Сумма платежа</Form.ControlLabel>
        <Form.Control
          name="amount"
          accepter={InputNumber}
          min={0}
          errorMessage={validationResult?.getError("amount")}
        />
      </Form.Group>
      <Form.Group controlId="comment">
        <Form.ControlLabel>Комментарий</Form.ControlLabel>
        <Form.Control
          name="comment"
          accepter={Input}
          errorMessage={validationResult?.getError("comment")}
        />
      </Form.Group>
      <Form.Group>
        <ButtonToolbar>
          <Button
            appearance="primary"
            type="submit"
            loading={studentTariffStore.onLoading}
            disabled={studentTariffStore.onLoading}
          >
            Сохранить
          </Button>
          <Button appearance="default" onClick={props.onClose}>
            Отмена
          </Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  );
};
