import { Affix as RSAffix } from "rsuite";
import { RefForwardingComponent } from "../types";
import React, { PropsWithChildren } from "react";
import { AffixProps } from "./types";

const affix = <E extends React.ElementType>(
  { children, ...props }: PropsWithChildren<AffixProps>,
  ref: React.ForwardedRef<E>
) => (
  <RSAffix {...props} ref={ref}>
    {children}
  </RSAffix>
);

export const Affix: RefForwardingComponent<"div", AffixProps> =
  React.forwardRef(affix);
