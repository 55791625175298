import CloseIcon from "@rsuite/icons/Close";
import React from "react";
import { InputGroup } from "rsuite";

type TCloseButtonProps = {
  onClick: () => void;
  rendered?: boolean;
};

export const CloseButton: React.FC<TCloseButtonProps> = ({
  rendered,
  onClick,
}) => {
  if (!rendered) {
    return null;
  }

  return (
    <InputGroup.Button onClick={onClick}>
      <CloseIcon />
    </InputGroup.Button>
  );
};
