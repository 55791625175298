import CloseIcon from "@rsuite/icons/Close";
import { Textarea } from "components";
import { endOfDay, startOfDay, subDays } from "date-fns";
import { useStore } from "hooks";
import _ from "lodash";
import { useEffect, useState } from "react";
import { EnumConverter } from "root/EnumConverter";
import { ValidationHelper } from "root/ValidationHelper";
import {
  ButtonToolbar,
  Form,
  InputPicker,
  PanelGroup,
  Whisper,
} from "rsuite";
import { isBefore } from "rsuite/esm/utils/dateUtils";
import { IPromotion, IPromotionRule, SaleType } from "stores";
import { ValidationResult } from "stores/models/ValidationResult";
import { PromotionRulesForm } from "./PromotionRulesForm";
import {
  Button,
  DatePicker,
  Divider,
  IconButton,
  Input,
  InputNumber,
  Panel,
  Tooltip,
} from "shared/ui";

interface IPromotionFormProps {
  promotionId?: number;
  onClose?: () => void;
}

const defaultPromotionRules: IPromotionRule = {
  id: 1,
  isAnyTariff: true,
  tariffId: undefined,
  countMonths: 1,
};

const defaultPromotion: IPromotion = {
  id: 0,
  title: "",
  description: "",
  dateRange: {
    begin: startOfDay(new Date(Date.now())),
    end: endOfDay(new Date(Date.now())),
  },
  saleCost: 0,
  saleType: SaleType.Amount,
  rules: [defaultPromotionRules],
};

export const PromotionForm: React.FC<IPromotionFormProps> = (props) => {
  const { promotionStore } = useStore();
  const [formValue, setFormValue] = useState<IPromotion>(defaultPromotion);
  const [rules, setRules] = useState<Partial<IPromotionRule>[]>([]);
  const [validationResult, setValidationResult] = useState<ValidationResult>();

  useEffect(() => {
    setFormValue((prev) => ({ ...prev, rules: rules }));
  }, [rules]);

  useEffect(() => {
    const promotion = promotionStore.find(props.promotionId);
    if (!promotion) {
      setFormValue(defaultPromotion);
      setRules([defaultPromotionRules]);
      return;
    }

    setFormValue({
      ...promotion,
      dateRange: {
        begin: new Date(promotion.dateRange.begin || new Date()),
        end: new Date(promotion.dateRange.end || new Date()),
      },
    });
    const updatePromotionRules = promotion.rules.map((x, i) => ({
      ...x,
      id: i + 1,
    }));

    setRules(updatePromotionRules);
  }, [props.promotionId]);

  const saleType = EnumConverter.mapEnum(SaleType, EnumConverter.saleTypeToStr);
  const isPercent = formValue.saleType === SaleType.Percent;

  const handleOnChangePromotionRule = (rule: IPromotionRule) => {
    const foundRuleIndex = rules.findIndex((x) => x.id === rule.id);
    if (foundRuleIndex === -1) {
      setRules((previousState) => [...previousState, rule]);
      return;
    }
    if (_.isEqual(rules[foundRuleIndex], rule)) {
      return;
    }
    rules[foundRuleIndex] = rule;
    setRules([...rules]);
  };

  const addRule = () => {
    setRules((prev) => [
      ...prev,
      {
        id: rules.length + 1,
        isAnyTariff: true,
        tariffId: undefined,
        countMonths: 1,
      },
    ]);
  };

  const onSubmitPromotion = async () => {
    const resultValidation = ValidationHelper.promotionValidate(formValue);
    setValidationResult(resultValidation);
    if (
      resultValidation.hasNotError() &&
      (await promotionStore.save(formValue))
    ) {
      props.onClose?.call(undefined);
    }
  };

  return (
    <Form fluid style={{ margin: 5 }} onSubmit={onSubmitPromotion}>
      <Form.Group controlId="title">
        <Form.ControlLabel>Название</Form.ControlLabel>
        <Form.Control
          value={formValue.title}
          name="title"
          accepter={Input}
          onChange={(value: string) => {
            setFormValue((prev) => ({ ...prev, title: value }));
          }}
          errorMessage={validationResult?.getError("title")}
        />
      </Form.Group>
      <Form.Group controlId="description">
        <Form.ControlLabel>Описание</Form.ControlLabel>
        <Form.Control
          value={formValue.description}
          name="description"
          accepter={Textarea}
          onChange={(value: string) => {
            setFormValue((prev) => ({ ...prev, description: value }));
          }}
          errorMessage={validationResult?.getError("description")}
        />
      </Form.Group>
      <Form.Group controlId="saleType">
        <Form.ControlLabel>Тип акции</Form.ControlLabel>
        <Form.Control
          style={{ maxWidth: 224 }}
          value={formValue.saleType}
          name="saleType"
          accepter={InputPicker}
          data={saleType}
          onChange={(value: SaleType) => {
            setFormValue((prev) => ({ ...prev, saleType: value }));
          }}
          errorMessage={validationResult?.getError("saleType")}
        />
      </Form.Group>
      <Form.Group controlId="saleCost">
        <Form.ControlLabel>{`${
          isPercent ? "%" : "Cумма"
        } скидки`}</Form.ControlLabel>
        <Form.Control
          style={{ maxWidth: 224 }}
          value={formValue.saleCost}
          name="saleCost"
          postfix={isPercent ? "%" : "₽"}
          accepter={InputNumber}
          min={0}
          max={isPercent ? 100 : 5000}
          onChange={(value: number | string) => {
            const saleCost = _.isString(value) ? +value : value;
            setFormValue((prev) => ({ ...prev, saleCost }));
          }}
          errorMessage={validationResult?.getError("saleCost")}
        />
      </Form.Group>
      <Form.Group controlId="begin">
        <Form.ControlLabel>Дата начала акции</Form.ControlLabel>
        <Form.Control
          style={{ maxWidth: 224 }}
          value={formValue.dateRange?.begin}
          shouldDisableDate={(date: Date) =>
            isBefore(date, subDays(new Date(), 1))
          }
          name="begin"
          accepter={DatePicker}
          cleanable={false}
          placeholder="Дата"
          format="dd-MM-yyyy HH:mm"
          onChange={(value: Date | null) => {
            if (!value) {
              return;
            }
            setFormValue((prev) => ({
              ...prev,
              dateRange: {
                ...prev.dateRange,
                end: prev.dateRange.end,
                begin: value,
              },
            }));
          }}
          errorMessage={validationResult?.getError("begin")}
        />
      </Form.Group>
      <Form.Group controlId="end">
        <Form.ControlLabel>Дата окончания акции</Form.ControlLabel>
        <Form.Control
          style={{ maxWidth: 224 }}
          value={formValue.dateRange?.end}
          shouldDisableDate={(date: Date) =>
            formValue.dateRange?.begin
              ? isBefore(date, formValue.dateRange?.begin)
              : isBefore(date, subDays(new Date(), 1))
          }
          name="end"
          accepter={DatePicker}
          cleanable={false}
          placeholder="Дата"
          format="dd-MM-yyyy HH:mm"
          onChange={(value: Date | null) => {
            if (!value) {
              return;
            }
            setFormValue((prev) => ({
              ...prev,
              dateRange: {
                ...prev.dateRange,
                end: value,
                begin: prev.dateRange.begin,
              },
            }));
          }}
          errorMessage={validationResult?.getError("end")}
        />
      </Form.Group>

      <PanelGroup accordion bordered style={{ marginTop: 25 }}>
        {rules.map((rule, i) => {
          const validation = validationResult?.getSubResult(
            ["tariffId", "countMonths"],
            i
          );
          return (
            <>
              <Panel
                header={
                  <Divider
                    label={
                      <div
                        style={{
                          gap: 8,
                          display: "flex",
                          alignItems: "center",
                        }}
                      >
                        Условие {i + 1}
                        <Whisper speaker={<Tooltip>Удалить условие</Tooltip>}>
                          <IconButton
                            appearance="primary"
                            color="red"
                            size="xs"
                            icon={<CloseIcon />}
                            onClick={() => {
                              const updateRules = rules.filter(
                                (x) => x.id !== rule.id
                              );
                              setRules(updateRules);
                            }}
                          />
                        </Whisper>
                      </div>
                    }
                    style={{
                      ...(validation?.hasError() && { color: "red" }),
                    }}
                  />
                }
                eventKey={i}
                id={rule.tariffId}
                key={rule.tariffId}
                style={{ padding: 10 }}
              >
                <PromotionRulesForm
                  rules={rule}
                  onChange={handleOnChangePromotionRule}
                  validationResult={validation}
                />
              </Panel>
            </>
          );
        })}
      </PanelGroup>
      <Button
        block
        style={{ marginTop: 20, marginBottom: 20 }}
        onClick={addRule}
      >
        Добавить условие
      </Button>

      <Form.Group>
        <ButtonToolbar>
          <Button appearance="primary" type="submit">
            Сохранить
          </Button>
          <Button
            appearance="default"
            onClick={() => {
              props.onClose?.call(undefined);
            }}
          >
            Отмена
          </Button>
        </ButtonToolbar>
      </Form.Group>
    </Form>
  );
};
