import React from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { Modal } from "rsuite";
import { Col, Grid, IconButton, Row } from "shared/ui";

type TNotificationSettingsModalProps = { opened: boolean; onClose: () => void };

export const NotificationSettingsModal: React.FC<
  TNotificationSettingsModalProps
> = ({ opened, onClose }) => (
  <Modal open={opened} onClose={onClose}>
    <Modal.Header>
      <Modal.Title>Настройки уведомлений</Modal.Title>
    </Modal.Header>
    <Modal.Body>
      <Grid fluid>
        <Row className="we-notification-show-grid">
          <Col xs={24} sm={24} md={14} lg={14} xl={14} xxl={14}>
            ⏰Добавь бота в Telegram, который будет отправлять тебе уведомления
          </Col>
          <Col xs={24} sm={24} md={9} lg={9} xl={9} xxl={9}>
            <div className="we-btn-notification-block">
              <IconButton
                size="sm"
                appearance="primary"
                color="blue"
                icon={<FaTelegramPlane className="rs-icon" />}
                href="https://t.me/wavege_bot"
                target="_blank"
              >
                Получать уведомления
              </IconButton>
            </div>
          </Col>
        </Row>
      </Grid>
    </Modal.Body>
  </Modal>
);
