import { BsFillBarChartLineFill } from "react-icons/bs";
import { PiWavesBold } from "react-icons/pi";
import { GeneralMenuItems, iconStyle } from "./GeneralMenuItems";
import { IMenuItem } from "./IMenuItem";

export const StudentMenu = () => {
  const menuItems: IMenuItem[] = [];
  menuItems.push(GeneralMenuItems.Home);
  menuItems.push(GeneralMenuItems.Schedule);
  menuItems.push(GeneralMenuItems.Lessons);
  menuItems.push(GeneralMenuItems.Top);
  menuItems.push(GeneralMenuItems.Checks);
  menuItems.push({
    title: "Успеваемость",
    path: "/dashboard",
    icon: <BsFillBarChartLineFill style={iconStyle} />,
  });
  menuItems.push({
    title: "Все курсы",
    path: "/catalog",
    icon: <PiWavesBold style={iconStyle} />,
  });
  return menuItems;
};
