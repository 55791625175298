import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { Pill } from "shared/ui";
import { IAnswer, ITask } from "stores";

export interface IButtonHomeworkCheckedStatusProps {
  answer: IAnswer | undefined;
  task?: ITask;
}
const ButtonHomeworkCheckedStatusComponent: React.FC<
  IButtonHomeworkCheckedStatusProps
> = (props) => {
  const [title, setTitle] = useState("статус проверки");
  const [color, setColor] = useState<TypeAttributes.Color | undefined>(
    undefined
  );
  useEffect(() => {
    const score = props.answer?.score || 0;
    const maxScore = props.task?.maxScore;
    if (score === maxScore) {
      setTitle("правильно");
      setColor("green");
      return;
    }
    if (score === 0) {
      setTitle("неправильно");
      setColor("red");
      return;
    }
    setTitle("почти правильно");
    setColor("orange");
  }, [props.answer, props.task]);

  return <Pill color={color}>{title}</Pill>;
};
export const ButtonHomeworkCheckedStatus = observer(
  ButtonHomeworkCheckedStatusComponent
);
