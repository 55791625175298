import { Cell } from "rsuite-table";
import { ITask } from "stores";
import { observer } from "mobx-react";
import { Whisper } from "rsuite";
import MinusIcon from "@rsuite/icons/Minus";
import PlusIcon from "@rsuite/icons/Plus";
import { useStore } from "hooks";
import { IconButton, Tooltip } from "shared/ui";

interface ICheckTaskCellProps {
  rowData?: ITask;
  dataKey: string;
}

const CheckTaskCellComponent: React.FC<ICheckTaskCellProps> = (props) => {
  const { taskStore } = useStore();
  const handleTaskAdd = (task: ITask) => {
    taskStore.addTasksForCreateTaskPack(task);
  };

  const handleOnRemove = (task: ITask) => {
    taskStore.removeTasksForCreateTaskPack(task);
  };
  const handleOnClick = (task: ITask) => {
    taskStore.hasTasksForCreateTaskPack(task)
      ? handleOnRemove(task)
      : handleTaskAdd(task);
  };
  if (!props.rowData) {
    return <></>;
  }
  const task = props.rowData;
  return (
    <Cell
      {...props}
      className="link-group"
      style={{
        display: "flex",
        justifyContent: "center",
        alignContent: "center",
        flexWrap: "wrap",
      }}
    >
      <Whisper
        placement="top"
        controlId="control-id-hover"
        trigger="hover"
        speaker={
          <Tooltip>
            {taskStore.hasTasksForCreateTaskPack(task)
              ? "Убрать из списка заданий"
              : "Добавить в список заданий"}
          </Tooltip>
        }
      >
        <IconButton
          style={{ display: "flex" }}
          onClick={() => {
            handleOnClick(task);
          }}
          icon={
            taskStore.hasTasksForCreateTaskPack(task) ? (
              <MinusIcon />
            ) : (
              <PlusIcon />
            )
          }
          size="sm"
          appearance="primary"
          color={taskStore.hasTasksForCreateTaskPack(task) ? "red" : "green"}
        />
      </Whisper>
    </Cell>
  );
};
export const CheckTaskCell = observer(CheckTaskCellComponent);
