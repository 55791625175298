import { observer } from "mobx-react";
import { Whisper } from "rsuite";
import { IStudentTariffWithPayments } from "stores";
import { useState } from "react";
import { ConfirmModal } from "components";
import TrashIcon from "@rsuite/icons/Trash";
import { TextHelper } from "root/TextHelpers";
import { useStore } from "hooks";
import { IconButton, Tooltip } from "shared/ui";

interface IButtonDeleteStudentTariffProps {
  studentTariff: IStudentTariffWithPayments;
}

const ButtonDeleteStudentTariffComponent: React.FC<
  IButtonDeleteStudentTariffProps
> = (props) => {
  const { studentTariffStore, studentsStore, tariffStore } = useStore();
  const [isOpenConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const tariffTitle = tariffStore.find(props.studentTariff.tariffId)?.title;

  const student = studentsStore.find(props.studentTariff.studentId);
  const studentName = student
    ? TextHelper.GetShortName(student.user, true)
    : `ID: ${props.studentTariff.studentId}. Кажется удален`;

  return (
    <>
      <ConfirmModal
        isOpen={isOpenConfirmModal}
        onSubmit={() => {
          setOpenConfirmModal(false);
          if (props.studentTariff) {
            studentTariffStore.delete(
              props.studentTariff.tariffId,
              props.studentTariff.studentId
            );
          }
        }}
        onCancel={() => {
          setOpenConfirmModal(false);
        }}
        isLoading={studentTariffStore.onLoading}
        description={`Вы действительно хотите отвязать курс "${tariffTitle}" от ученика "${studentName}"? Оплаченные месяца останутся, но доступ до курса у ученика пропадет`}
      />
      <Whisper
        placement="top"
        controlId="control-id-hover"
        trigger="hover"
        speaker={<Tooltip>Архивировать</Tooltip>}
      >
        <IconButton
          disabled={props.studentTariff.dateDelete !== null}
          onClick={() => {
            setOpenConfirmModal(true);
          }}
          icon={<TrashIcon />}
          size="sm"
          color="red"
          appearance="primary"
        />
      </Whisper>
    </>
  );
};
export const ButtonDeleteStudentTariff = observer(
  ButtonDeleteStudentTariffComponent
);
