import { observer } from "mobx-react";
import React from "react";
import { ButtonInfoScore } from "./components";
import { useStudentHomeworkScore } from "./useStudentHomeworkScore";

type TStudentHomeworkScoreProps = {
  lessonId: number;
  studentId: number;
};

const StudentHomeworkScoreComponent: React.FC<TStudentHomeworkScoreProps> = ({
  lessonId,
  studentId,
}) => {
  const {
    firstPartMaxScore,
    firstPartScore,
    secondPartMaxScore,
    secondPartScore,
  } = useStudentHomeworkScore(lessonId, studentId);

  return (
    <div style={{ marginBottom: 22 }}>
      {firstPartMaxScore ? (
        <ButtonInfoScore
          score={firstPartScore || 0}
          maxScore={firstPartMaxScore}
          title={`Первая часть: ${firstPartScore || 0} / ${firstPartMaxScore}`}
        />
      ) : null}

      {secondPartMaxScore ? (
        <div>
          <ButtonInfoScore
            score={secondPartScore || 0}
            maxScore={secondPartMaxScore}
            title={`Вторая часть: ${
              secondPartScore || 0
            } / ${secondPartMaxScore}`}
          />
        </div>
      ) : null}
    </div>
  );
};

export const StudentHomeworkScore = observer(StudentHomeworkScoreComponent);
