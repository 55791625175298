import React, { useEffect, useState } from "react";
import { ICart, IPayment } from "stores";
import { useLocalStorage } from "hooks/useLocalSessionsStorage";
import { GlobalConstants } from "root/GlobalConstants";
import { Button, Input } from "shared/ui";

interface ICalculateAmountProps {
  payOrder: IPayment | undefined;
  onChange: (inputPromocode: string) => void;
}

export const Promocode: React.FC<ICalculateAmountProps> = ({
  payOrder,
  onChange,
}) => {
  const [isInputPromocode, setIsInputPromocode] = useState(false);
  const [inputPromocode, setInputPromocode] = useState("");
  const [tariffsInCart, setTariffsInCart] = useLocalStorage<ICart>(
    GlobalConstants.wavegeCart,
    { promocode: "", items: [] }
  );

  useEffect(() => {
    if (!tariffsInCart || !tariffsInCart.promocode) {
      return;
    }
    setIsInputPromocode(true);
    setInputPromocode(tariffsInCart.promocode);
  }, [tariffsInCart]);

  const updateCartWithPromo = (promo: string) => {
    setTariffsInCart((prev) => ({
      ...prev,
      promocode: promo,
    }));
    onChange(promo);
  };

  const handleChangePromocode = () => {
    updateCartWithPromo(inputPromocode);
  };

  const handleDeletePropmocode = () => {
    updateCartWithPromo("");
    setInputPromocode("");
  };

  if (!payOrder) {
    return null;
  }

  if (!isInputPromocode) {
    return (
      <div style={{ display: "flex", justifyContent: "center" }}>
        <Button block onClick={() => setIsInputPromocode(true)}>
          ввести промокод
        </Button>
      </div>
    );
  }

  if (payOrder.promocodeError) {
    return (
      <>
        <div style={{ display: "flex", gap: 8 }}>
          <Input value={inputPromocode} disabled />
          <Button style={{ minWidth: 100 }} onClick={handleDeletePropmocode}>
            удалить
          </Button>
        </div>
        <div
          style={{
            marginTop: 5,
            fontSize: 13,
            color: "var(--rs-red-500)",
            letterSpacing: ".02em",
          }}
        >
          <span>{payOrder.promocodeError}</span>
        </div>
      </>
    );
  }
  if (payOrder.promotions && payOrder.promotions.find((x) => x.isPromocode)) {
    return null;
  }

  return (
    <div style={{ display: "flex", gap: 8 }}>
      <Input
        value={inputPromocode}
        onChange={(value) => setInputPromocode(value)}
        placeholder="промокод"
      />
      <Button
        style={{ minWidth: 100 }}
        disabled={!inputPromocode}
        onClick={handleChangePromocode}
      >
        применить
      </Button>
    </div>
  );
};
