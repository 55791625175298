import { Whisper } from "rsuite";
import { IStudentTariffPayment } from "stores";
import { DateHelper } from "root/DateHelper";
import { EnumConverter } from "root/EnumConverter";
import { IconButton, Tooltip } from "shared/ui";

interface IStudentTariffPaymentIconProps {
  studentTariffPayment: IStudentTariffPayment;
}
export const StudentTariffPaymentIcon: React.FC<
  IStudentTariffPaymentIconProps
> = ({ studentTariffPayment }) => {
  const type = EnumConverter.PaymentTypeToStr(studentTariffPayment.type);

  const dateCreate = DateHelper.getDateToStrLocaleShort(
    studentTariffPayment.dateCreate,
    true
  );

  return (
    <Whisper
      placement="top"
      speaker={
        <Tooltip>
          <p>{`Тип оплаты: ${type}`}</p>
          <p>{`Дата оплаты: ${dateCreate}`}</p>
          <p>{`Сумма оплаты: ${studentTariffPayment.amount}₽`}</p>
          <p>{`Комментарий: ${studentTariffPayment.comment ?? "-"}`}</p>
        </Tooltip>
      }
    >
      <IconButton
        icon={EnumConverter.PaymentTypeToImg(studentTariffPayment.type)}
        circle
        size="xs"
      />
    </Whisper>
  );
};
