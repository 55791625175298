import { observer } from "mobx-react";
import React from "react";
import { Dropdown } from "rsuite";
import { ITariff } from "stores";
import { TPopoverProps } from "..";
import { Popover } from "shared/ui";

type TPopoverTariffChange = TPopoverProps & {
  myTariffs: ITariff[];
  selectedTariffId: number | null;
  onSelect: (eventKey: string | undefined) => void;
};

const PopoverTariffChangeComponent = React.forwardRef<
  HTMLDivElement,
  TPopoverTariffChange
>(function PopoverTariffChange(
  { className, onClose, left, top, myTariffs, onSelect, selectedTariffId },
  ref
) {
  const handleSelectTariff = (eventKey: string | undefined) => {
    onClose();
    onSelect(eventKey);
  };
  return (
    <Popover ref={ref} className={className} style={{ left, top }} full>
      <Dropdown.Menu onSelect={handleSelectTariff}>
        {myTariffs.map((x) => {
          return (
            <Dropdown.Item
              key={x.id}
              eventKey={x.id}
              active={x.id === selectedTariffId}
            >
              {x.title}
            </Dropdown.Item>
          );
        })}
      </Dropdown.Menu>
    </Popover>
  );
});
export const PopoverTariffChange = observer(PopoverTariffChangeComponent);
