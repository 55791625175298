import { useStore } from "hooks";
import { observer } from "mobx-react";
import { useMemo } from "react";
import { EnumConverter } from "root/EnumConverter";
import { Whisper } from "rsuite";
import { Pill, Tooltip } from "shared/ui";
import { TResultObject } from "./RenderRowExpanded";

type TStudentTariffTableProps = { tariffOrderItem: TResultObject };

const TariffItemInfoComponent: React.FC<TStudentTariffTableProps> = ({
  tariffOrderItem,
}) => {
  const { tariffStore } = useStore();
  const title = useMemo(
    () => tariffStore.find(tariffOrderItem.tariffId)?.title,
    [tariffOrderItem]
  );

  return (
    <Whisper
      trigger="hover"
      speaker={
        <Tooltip>
          {tariffOrderItem.properties.map((tariffOrderItem) => {
            const type = EnumConverter.PaymentTypeToStr(
              tariffOrderItem.paymentType
            );
            return (
              <p
                key={tariffOrderItem.monthNumber}
              >{` Месяц:${tariffOrderItem.monthNumber} (${type})`}</p>
            );
          })}
        </Tooltip>
      }
    >
      <Pill size="sm" style={{ marginLeft: 5 }}>
        {title}
      </Pill>
    </Whisper>
  );
};

export const TariffItemInfo = observer(TariffItemInfoComponent);
