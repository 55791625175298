import { toaster } from "rsuite";
import { Notification } from "shared/ui";

export class NotificationHelper {
  /**
   * @description Выводит уведомление с иконкой успешкой операции
   *
   *@param message Тело уведомления, описание произошедшего события.
   *@param header Заголовок уведомления, по дэфолту **`Успех`**.
   */
  public static ShowSuccess(
    message: string,
    header = "Успех",
    duration?: number
  ) {
    this.ShowNotification("success", header, message, duration);
  }
  /**
   * @description Выводит уведомление с иконкой информации
   *
   *@param message Тело уведомления, описание произошедшего события.
   *@param header Заголовок уведомления, по дэфолту **`Информация`**.
   */
  public static ShowInfo(
    message: string,
    header = "Информация",
    duration?: number
  ) {
    this.ShowNotification("info", header, message, duration);
  }
  /**
   * @description Выводит уведомление с иконкой ошибки
   *
   *@param message Тело уведомления, описание произошедшего события.
   *@param header Заголовок уведомления, по дэфолту **`Ошибка`**.
   */
  public static ShowError(
    message: string,
    header = "Ошибка",
    duration?: number
  ) {
    this.ShowNotification("error", header, message, duration);
  }
  /**
   * @description Выводит алерт с иконкой восклицательного знака
   *
   *@param message Тело алерта, описание произошедшего события.
   *@param header Заголовок алерта, по дэфолту **`Внимание`**.
   */
  public static ShowAlert(
    message: string,
    header = "Внимание",
    duration?: number
  ) {
    this.ShowNotification("warning", header, message, duration);
  }

  private static ShowNotification(
    type: "info" | "success" | "warning" | "error",
    header: string,
    message: string,
    duration?: number
  ) {
    toaster.push(
      <Notification type={type} header={header} closable>
        <p>{message}</p>
      </Notification>,
      { placement: "bottomStart", duration }
    );
  }
}
