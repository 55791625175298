import { useStore } from "hooks";
import { observer } from "mobx-react";
import React, { CSSProperties } from "react";

const textStyle: CSSProperties = {
  fontSize: 18,
  color: "var(--rs-text-primary)",
};

const DevEnvSignatureComponent: React.FC = () => {
  const { authStore } = useStore();

  if (process.env.NODE_ENV !== "development") {
    return null;
  }

  return <p style={textStyle}>{` DEV ${authStore.me?.role || "anonymous"}`}</p>;
};
export const DevEnvSignature = observer(DevEnvSignatureComponent);
