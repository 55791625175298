import { useMemo } from "react";
import { EnumConverter } from "root/EnumConverter";
import { Cell } from "rsuite-table";
import { Pill } from "shared/ui";
import { TOrder } from "stores";

interface IStudentCellProps {
  rowData?: TOrder;
  dataKey: string;
}

export const StatusCell: React.FC<IStudentCellProps> = (props) => {
  const status = props.rowData?.status;

  const title = useMemo(
    () => status && EnumConverter.orderPaymentStatusToStr(status),
    [status]
  );
  const color = useMemo(
    () => status && EnumConverter.orderPaymentStatusToColor(status),
    [status]
  );

  if (!status) {
    return <Cell {...props}>-</Cell>;
  }
  return (
    <Cell
      {...props}
      className="link-group"
      style={{
        display: "flex",
        alignContent: "center",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      <Pill color={color}>{title}</Pill>
    </Cell>
  );
};
