import { useDebouncedFunction, useStore } from "hooks";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { SaveSummaryDraftHelper } from "root/SaveSummaryDraftHelper";
import { ISummary } from "stores";

export const useFormHomeworkSummary = (lessonId: number, studentId: number) => {
  const navigate = useNavigate();
  const { homeworkStore } = useStore();
  const [advantages, setAdvantages] = useState<string>("");
  const [flaws, setFlaws] = useState<string>("");
  const [finalConclusion, setFinalConclusion] = useState<string>("");

  useEffect(() => {
    const draftAnswers = SaveSummaryDraftHelper.getDraftAnswers(
      lessonId,
      studentId
    );
    if (!draftAnswers) {
      return;
    }
    setAdvantages(draftAnswers.advantages);
    setFlaws(draftAnswers.flaws);
    setFinalConclusion(draftAnswers.finalConclusion);
  }, []);

  useEffect(() => {
    doSaveDraft({ advantages, flaws, finalConclusion });
  }, [advantages, flaws, finalConclusion]);

  const doSaveDraft = useDebouncedFunction(
    ({ advantages, flaws, finalConclusion }: ISummary) => {
      SaveSummaryDraftHelper.saveDraft(
        { advantages, flaws, finalConclusion },
        lessonId,
        studentId
      );
    },
    3_000
  );

  const handleOnClick = () => {
    const summary = `
        <b>ПРЕИМУЩЕСТВА:</b><br />
        <span>${advantages}</span>
        <br />
        <br />
        <b>НЕДОСТАТКИ:</b><br />
        <span>${flaws}</span>
        <br />
        <br />
        <b>ВЫВОД:</b><br />
        <span>${finalConclusion}</span>
      `;

    homeworkStore.saveSummary(lessonId, studentId, summary).then(() => {
      SaveSummaryDraftHelper.removeDraft(lessonId, studentId);
      navigate("/checks");
    });
  };

  const isDisabledSubmit =
    !advantages || !flaws || !finalConclusion || homeworkStore.onSaving;

  const isLoading = homeworkStore.onSaving;
  return {
    isDisabledSubmit,
    handleOnClick,
    isLoading,
    setAdvantages,
    advantages,
    setFlaws,
    flaws,
    setFinalConclusion,
    finalConclusion,
  };
};
