import { useStore } from "hooks";
import _ from "lodash";
import { useEffect, useState } from "react";
import { Form, SelectPicker, Toggle } from "rsuite";
import { InputNumber } from "shared/ui";
import { IPromotionRule } from "stores";
import { ValidationResult } from "stores/models/ValidationResult";

interface IPromotionRulesFormProps {
  rules: IPromotionRule;
  onChange: (formValue: IPromotionRule) => void;
  validationResult?: ValidationResult;
}

export const PromotionRulesForm: React.FC<IPromotionRulesFormProps> = (
  props
) => {
  const { tariffStore } = useStore();
  const [formValue, setFormValue] = useState<Partial<IPromotionRule>>(
    props.rules
  );
  useEffect(() => {
    props.onChange(formValue);
  }, [formValue]);

  return (
    <>
      <Form.Group controlId="countMonths">
        <Form.ControlLabel>
          Количество месяцев
          <Form.HelpText tooltip>
            Минимальное количество месяцев одного тарифа, оплачиваемое
            одновременно, для применения акции
          </Form.HelpText>
        </Form.ControlLabel>
        <Form.Control
          style={{ maxWidth: 224 }}
          value={formValue.countMonths}
          name="countMonths"
          accepter={InputNumber}
          min={0}
          onChange={(value: number | string) => {
            const countMonths = _.isString(value) ? +value : value;
            setFormValue((prev) => ({
              ...prev,
              countMonths,
            }));
          }}
          errorMessage={props.validationResult?.getError("countMonths")}
        />
      </Form.Group>

      <Form.Group controlId="isAnyTariff">
        <div style={{ display: "inline-flex", alignItems: "flex-end" }}>
          <Form.Control
            value={formValue.isAnyTariff}
            name="isAnyTariff"
            accepter={Toggle}
            checkedChildren="Любой курс"
            unCheckedChildren="Выбрать курс"
            onChange={(value: boolean) => {
              if (value) {
                setFormValue((prev) => ({
                  ...prev,
                  isAnyTariff: value,
                  tariffId: undefined,
                }));
                return;
              }
              setFormValue((prev) => ({
                ...prev,
                isAnyTariff: value,
              }));
            }}
          />
          <Form.HelpText tooltip>
            <b>Любой курс</b> - акция действует на все курсы в корзине.
            <br />
            <b>Выбрать курс</b> - акция действует только на выбранный курс.
          </Form.HelpText>
        </div>
      </Form.Group>
      {formValue?.isAnyTariff ? null : (
        <Form.Group controlId="tariffId">
          <Form.ControlLabel>Курс</Form.ControlLabel>
          <Form.Control
            value={formValue.tariffId}
            name="tariffId"
            accepter={SelectPicker}
            data={tariffStore.data.map((item) => ({
              label: item.title,
              value: item.id,
            }))}
            onChange={(value: unknown) => {
              const tariffId = _.isNumber(value) ? value : undefined;
              setFormValue((prev) => ({
                ...prev,
                tariffId,
              }));
            }}
            errorMessage={props.validationResult?.getError("tariffId")}
          />
        </Form.Group>
      )}
    </>
  );
};
