import React, { PropsWithChildren } from "react";
import { Col as RSCol } from "rsuite";
import { RefForwardingComponent } from "../types";
import { ColProps } from "./types";

const col = <E extends React.ElementType>(
  { children, ...props }: PropsWithChildren<ColProps>,
  ref: React.ForwardedRef<E>
) => (
  <RSCol {...props} ref={ref}>
    {children}
  </RSCol>
);

export const Col: RefForwardingComponent<"div", ColProps> =
  React.forwardRef(col);
