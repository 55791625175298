import SearchIcon from "@rsuite/icons/Search";
import React from "react";
import { InputGroup, Whisper } from "rsuite";
import { OverlayTriggerHandle } from "rsuite/esm/Overlay/OverlayTrigger";
import { Tooltip as RsuiteTooltip } from "shared/ui";

type TWhisperProps = {
  tooltipTitle?: string;
};

export const Tooltip = React.forwardRef<OverlayTriggerHandle, TWhisperProps>(
  function Tooltip({ tooltipTitle = "Для поиска введите от 4 символов" }, ref) {
    return (
      <InputGroup.Button>
        <Whisper
          placement="bottomStart"
          ref={ref}
          speaker={<RsuiteTooltip>{tooltipTitle}</RsuiteTooltip>}
        >
          <SearchIcon />
        </Whisper>
      </InputGroup.Button>
    );
  }
);
