import React from "react";
import { Rate as RSRate } from "rsuite";
import { RefForwardingComponent } from "../types";
import { RateProps } from "./types";

const rate = <E extends React.ElementType>(
  { ...props }: RateProps,
  ref: React.ForwardedRef<E>
) => <RSRate {...props} ref={ref} />;

export const Rate: RefForwardingComponent<"ul", RateProps> =
  React.forwardRef(rate);
