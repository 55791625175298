import InfoOutlineIcon from "@rsuite/icons/InfoOutline";
import { Whisper } from "rsuite";
import { Cell } from "rsuite-table";
import { IconButton, Tooltip } from "shared/ui";
import { TOrder } from "stores";

interface IStudentCellProps {
  rowData?: TOrder;
  dataKey: string;
}

export const AmountCell: React.FC<IStudentCellProps> = (props) => {
  const order = props.rowData;
  if (!order) {
    return <></>;
  }
  const promocode = order.promocode;
  return (
    <Cell
      {...props}
      style={{
        display: "flex",
        alignContent: "center",
        flexWrap: "wrap",
      }}
    >
      <span style={{ marginRight: 10 }}>{order.amount}₽</span>
      {promocode && (
        <Whisper
          placement="top"
          speaker={
            <Tooltip>
              <>
                <p>Оплачен с промомодом:</p>
                {order.promocode}
              </>
            </Tooltip>
          }
        >
          <IconButton icon={<InfoOutlineIcon />} circle size="xs" />
        </Whisper>
      )}
    </Cell>
  );
};
