import * as adminTeacherMentor from "pages/AdminTeacherMentor";
import * as general from "../components";

import { IRoute } from "./IRoute";

export class GeneralRoutes {
  /**
   * Уроки /lessons
   */
  static Lessons: IRoute = {
    title: "Уроки",
    path: "/lessons",
    element: <general.Lessons />,
  };

  /**
   * Урок /lessons/:id
   */
  static LessonsCard: IRoute = {
    hideTitle: true,
    title: "Урок",
    path: "/lessons/:id",
    element: <general.LessonsCard />,
  };

  /**
   * Урок /summary/:id
   */
  static SummaryByLesson: IRoute = {
    hideTitle: true,
    title: "Вывод по домашке",
    path: "/lessons/summary/:id",
    element: <general.LessonsCard />,
  };

  /**
   * Урок /lessons/:id/:homeworkId/:taskpackId
   */
  static LessonsCardWithHw: IRoute = {
    hideTitle: true,
    title: "Домашка",
    path: "/lessons/:id/:homeworkId/:taskpackId",
    element: <general.LessonsCard />,
  };

  /**
   * Все задания /alltask
   */
  static AllTask: IRoute = {
    title: "Все задания",
    path: "/alltask",
    element: <general.TasksBase isArchive={false} />,
  };

  /**
   * Готовые варианты /alltaskpack
   */
  static TaskPack: IRoute = {
    title: "Готовые варианты",
    path: "/alltaskpack",
    element: <general.TaskPackList />,
  };

  /**
   * Создание задания /createtask
   */
  static CreateTask: IRoute = {
    title: "Создание задания",
    path: "/createtask",
    element: <general.PanelTaskEdit />,
  };

  /**
   * Избранное /taskfavorite
   */
  static TaskFavorite: IRoute = {
    title: "Избранное",
    path: "/taskfavorite",
    element: <general.TasksBase isFavorite={true} isArchive={false} />,
  };

  /**
   * Удаленные /taskarchive
   */
  static TaskArchive: IRoute = {
    title: "Удаленные",
    path: "/taskarchive",
    element: <general.TasksBase isArchive={true} />,
  };

  /**
   * Таблица с проверками /checks
   */
  static CheckLessons: IRoute = {
    title: "Проверки",
    path: "/checks",
    element: <general.ChecksBase />,
  };
  /**
   * Проверки /summary/:lessonId/:studentId
   */
  static HomeworkSummary: IRoute = {
    title: "Вывод по домашке",
    path: "/checks/:lessonId/:studentId",
    element: <adminTeacherMentor.CheckEditorPage />,
  };
  /**
   * Проверки /checks/:lessonId/:studentId/:homeworkId/:packId
   */
  static TaskPackEditor: IRoute = {
    title: "Проверка домашки",
    path: "/checks/:lessonId/:studentId/:homeworkId/:packId",
    element: <adminTeacherMentor.CheckEditorPage />,
  };

  /**
   * Студенты /students
   */
  static Students: IRoute = {
    title: "Студенты",
    path: "/students",
    element: <general.Students />,
  };
  /**
   * Топ /top
   */
  static Top: IRoute = {
    title: "Топ",
    path: "/top",
    element: <general.PageTop />,
  };
  /**
   * Расписание /schedule
   */
  static Schedule: IRoute = {
    title: "Расписание",
    path: "/schedule",
    element: <general.Schedule />,
  };
}
