import { DebouncedInput } from "components/Input/DebouncedInput";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { EnumConverter } from "root/EnumConverter";
import { GlobalConstants } from "root/GlobalConstants";
import { IRequestGetTasksByFilter, TaskOrder } from "root/requests";
import { SelectPicker, Stack } from "rsuite";
import { TaskType } from "stores";
import { CreateTask } from "../Task/CreateTask";

type SelectData = {
  label: string;
  value: number;
};

interface IFilterByTaskPropertiesProps {
  onChange: (taskListFilter: IRequestGetTasksByFilter) => void;
  defaultValue: IRequestGetTasksByFilter;
  isFavorite?: boolean;
  isArchive?: boolean;
}

const FilterByTaskPropertiesComponent: React.FC<
  IFilterByTaskPropertiesProps
> = ({ onChange, defaultValue, isFavorite, isArchive }) => {
  const [filter, setFilter] = useState<IRequestGetTasksByFilter>(defaultValue);

  const { themaStore, themaCategoryStore } = useStore();
  const [themes, setThemes] = useState<SelectData[]>([]);
  const [themesByCategory, setThemesByCategory] = useState<SelectData[]>([]);
  const subjectId = 1;

  useEffect(() => {
    setFilter((prev) => ({ ...prev, isFavorite, isArchive }));
  }, [isFavorite, isArchive]);

  useEffect(() => {
    onChange(filter);
  }, [filter]);

  useEffect(() => {
    const themesLabel: SelectData[] = themaStore.themes.map((x) => ({
      label: x.title,
      value: x.id,
    }));
    setThemes(themesLabel);
  }, [themaStore.themes]);

  useEffect(() => {
    setThemesByCategory(themes);
  }, [themes]);

  const categories: SelectData[] = themaCategoryStore
    .filterBySubjectId(subjectId)
    .map((x) => ({ label: x.title, value: x.id }));

  const taskTypes = EnumConverter.mapEnum(
    TaskType,
    EnumConverter.typeTaskToStr
  );
  const taskOrderList = EnumConverter.mapEnum(
    TaskOrder,
    EnumConverter.TaskOrderToStr
  );
  const getKimList = () => {
    const kimList = [];

    const kimFirstPart = GlobalConstants.kimBySocialScience.kimFirstPart.map(
      (item) => ({
        label: item,
        value: item,
      })
    );
    kimList.push(...kimFirstPart);
    const kimSecondPart = GlobalConstants.kimBySocialScience.kimSecondPart.map(
      (item) => ({
        label: item,
        value: item,
      })
    );
    kimList.push(...kimSecondPart);

    return kimList;
  };

  const kimList = getKimList();

  return (
    <>
      <Stack spacing={10} wrap style={{ marginBottom: 10 }}>
        <CreateTask />
        <SelectPicker
          defaultValue={filter.themeCategoryId}
          onChange={(categoryId: number | null) => {
            setFilter((prev) => ({
              ...prev,
              themeCategoryId: categoryId || undefined,
            }));
          }}
          label="Категория"
          data={categories}
          style={{ width: 224 }}
        />
        <SelectPicker
          defaultValue={filter.themeId}
          onChange={(themeId: number | null) => {
            setFilter((prev) => ({
              ...prev,
              themeId: themeId || undefined,
            }));
          }}
          label="Тема"
          data={themesByCategory}
          style={{ width: 224 }}
        />
        <SelectPicker
          defaultValue={filter.type}
          onChange={(type: number | null) => {
            setFilter((prev) => ({
              ...prev,
              type: type || undefined,
            }));
          }}
          label="Тип задания"
          data={taskTypes}
          style={{ width: 224 }}
        />
        <SelectPicker
          defaultValue={filter.kim}
          onChange={(kim: number | null) => {
            setFilter((prev) => ({
              ...prev,
              kim: kim || undefined,
            }));
          }}
          label="КИМ"
          data={kimList}
          style={{ width: 224 }}
        />
      </Stack>
      <DebouncedInput
        tooltipTitle="Для поиска введите от 4 символов"
        placeholder="Найти по источнику или тексту задания"
        defaultValue={defaultValue.searchPattern}
        onChange={(searchPattern) =>
          setFilter((prev) => ({
            ...prev,
            searchPattern,
          }))
        }
      />
      <SelectPicker
        defaultValue={filter.orderBy}
        onChange={(orderBy: number | null) => {
          setFilter((prev) => ({
            ...prev,
            orderBy: orderBy || TaskOrder.DifficultyAsc,
          }));
        }}
        label="Сортировка"
        data={taskOrderList}
        style={{ width: 224 }}
      />
    </>
  );
};
export const FilterByTaskProperties = observer(FilterByTaskPropertiesComponent);
