import { observer } from "mobx-react";
import React from "react";
import { Table } from "rsuite";
import { ThemeCell } from "../Cell/ThemeCell";
import { ActionCell, NumberCell } from "./Cell";
import { Panel } from "shared/ui";

type TTaskPackTableProps = { data: any; loading: boolean };

const TaskPackTableComponent: React.FC<TTaskPackTableProps> = ({
  data,
  loading,
}) => {
  const { Column, HeaderCell, Cell } = Table;

  return (
    <Panel
      bordered
      bodyFill
      style={{ marginTop: 15, maxHeight: "60vh", overflow: "auto" }}
    >
      <Table
        isTree
        defaultExpandAllRows={false}
        rowKey="id"
        loading={loading}
        autoHeight
        data={data}
        style={{ marginTop: 25 }}
      >
        <Column minWidth={200} flexGrow={2}>
          <HeaderCell>Вариант</HeaderCell>
          <Cell dataKey="title" />
        </Column>
        <Column width={25} align="right">
          <HeaderCell> </HeaderCell>
          <NumberCell dataKey="index" />
        </Column>
        <Column minWidth={250} flexGrow={3}>
          <HeaderCell>Задания</HeaderCell>
          <ThemeCell dataKey="themeId" />
        </Column>
        <Column minWidth={50} flexGrow={1}>
          <HeaderCell> </HeaderCell>
          <ActionCell dataKey="themeId" />
        </Column>
      </Table>
    </Panel>
  );
};

export const TaskPackTable = observer(TaskPackTableComponent);
