import React from "react";
import { Stack } from "rsuite";
import { observer } from "mobx-react";
import { IAnswer, ITask, ITaskPack } from "stores";
import { StyleHelper } from "root/StyleHelper";
import { Button } from "shared/ui";

const scorePoint: React.CSSProperties = {
  position: "absolute",
  bottom: -8,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  minWidth: 32,
  height: 16,
  padding: "0 3px",
  fontSize: 10,
  lineHeight: 11,
  color: "#101010",
  letterSpacing: ".02em",
  whiteSpace: "nowrap",
  backgroundColor: "#f2f3f5",
  border: "1px solid #e0e2e7",
  borderRadius: 24,
  overflow: "visible",
};

export interface ITaskCheckedSwitchProps {
  answers?: IAnswer[];
  onChange: (taskId: number) => void;
  taskPack?: ITaskPack;
  taskId?: number;
}

const TaskCheckedSwitchComponent: React.FC<ITaskCheckedSwitchProps> = (
  props: ITaskCheckedSwitchProps
) => {
  const getScoreByTask = (task: ITask): number => {
    let score = props.answers?.find((x) => x.taskId === task.id)?.score;
    if (!score) {
      score = 0;
    }
    return score;
  };

  const styleButton = (task: ITask): React.CSSProperties => {
    return props.taskId === task.id
      ? {
          backgroundColor: "var(--rs-btn-ghost-text)",
          color: "var(--rs-bg-card)",
        }
      : {};
  };
  return (
    <>
      <div style={{ overflowX: "auto", overflowY: "hidden" }}>
        <Stack
          spacing={8}
          style={{
            display: "flex",
            alignItems: "flex-start",
            marginBottom: 20,
            marginTop: 5,
            marginLeft: 5,
            flexWrap: "wrap",
          }}
        >
          {props.taskPack?.tasks.map((task, i) => {
            return (
              <Button
                className="switch-checked-btn"
                key={task.id}
                appearance="ghost"
                color={StyleHelper.getColorByScore(
                  getScoreByTask(task),
                  task.maxScore
                )}
                size="md"
                style={styleButton(task)}
                onClick={() => {
                  props.onChange(task.id);
                }}
              >
                {i + 1}
                <div style={scorePoint}>
                  {getScoreByTask(task)} из {task.maxScore}
                </div>
              </Button>
            );
          })}
        </Stack>
      </div>
    </>
  );
};
export const TaskCheckedSwitch = observer(TaskCheckedSwitchComponent);
