import { CSSProperties } from "react";
import { TextHelper } from "root";
import { Whisper } from "rsuite";
import { Panel, Popover } from "shared/ui";
import { IAchievementItem } from "stores";

const imgStyleCompleted: React.CSSProperties = {
  verticalAlign: "top",
  float: "left",
  marginRight: 10,
  marginBottom: 15,
};

const imgStyleUncompleted: React.CSSProperties = {
  verticalAlign: "top",
  float: "left",
  marginRight: 10,
  marginBottom: 15,
  filter: "grayscale(100%)",
  WebkitFilter: "grayscale(100%)",
  msFilter: "grayscale(100%)",
};
const panelStyle: CSSProperties = {
  minHeight: 200,
  margin: 7,
};

function speaker(achievement: IAchievementItem) {
  return (
    <Popover title="ачифка" style={{ width: 350 }}>
      <p>{achievement.title}</p>
      <p>{achievement.description}</p>
    </Popover>
  );
}

interface AchievementItemProps {
  achievement: IAchievementItem;
}
export const AchievementItem: React.FC<AchievementItemProps> = (props) => {
  const { achievement } = props;
  const isCompleted = achievement.progress >= achievement.doneRequirement;
  const imgStyle =
    isCompleted === true ? imgStyleCompleted : imgStyleUncompleted;
  return (
    <Whisper
      placement="top"
      trigger="hover"
      controlId="control-id-hover"
      speaker={speaker(achievement)}
    >
      <Panel bordered header={achievement.title} style={panelStyle}>
        <img src={achievement.imageUrl} width="70" style={imgStyle} />
        <p>{TextHelper.ShrinkText(achievement.description, 60)}</p>
        {!isCompleted && (
          <p>
            Выполнено {achievement.progress}/{achievement.doneRequirement}
          </p>
        )}
      </Panel>
    </Whisper>
  );
};
