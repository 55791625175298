import React, { PropsWithChildren } from "react";
import { Message as RSMessage } from "rsuite";
import { RefForwardingComponent } from "../types";
import { MessageProps } from "./types";

const message = <E extends React.ElementType>(
  { children, ...props }: PropsWithChildren<MessageProps>,
  ref: React.ForwardedRef<E>
) => (
  <RSMessage {...props} ref={ref}>
    {children}
  </RSMessage>
);

export const Message: RefForwardingComponent<"div", MessageProps> =
  React.forwardRef(message);
