import { QueryClient } from "@tanstack/react-query";
import React, { PropsWithChildren } from "react";

export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      refetchOnMount: false,
      refetchOnReconnect: false,
      retry: false,
      staleTime: 60 * 60 * 1000,
    },
  },
});

export const QueryProvider: React.FC<PropsWithChildren> = ({ children }) => {
  return <>{children}</>;
};
