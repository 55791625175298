import { IAnswerWithTasksPackHwStudentId, IStudentHomework } from "stores";
import { emitter } from "./EventHelper";
import { localStorageWrapper } from "./wrappers";
import { GlobalConstants } from "./GlobalConstants";

type IDraftAnswerCheck = Omit<
  IStudentHomework,
  "delayReason" | "tasksPackHwStudentId" | "answers"
> & {
  studentId: number;
  answers: IAnswerWithTasksPackHwStudentId[];
};

export class StudentDraftAnswersCheckStorage {
  private static key = `wavege_student_answers_check`;

  public static saveDraft = (
    answers: IAnswerWithTasksPackHwStudentId[],
    lessonId: number,
    homeworkId: number,
    packId: number,
    studentId: number
  ) => {
    const draftAnswers =
      (localStorageWrapper.get(this.key) as IDraftAnswerCheck[]) || [];
    const index = draftAnswers.findIndex(
      (item) =>
        item.lessonId === lessonId &&
        item.homeworkId === homeworkId &&
        item.packId === packId &&
        item.studentId === studentId
    );

    if (index !== -1) {
      draftAnswers[index].answers = answers;
    } else {
      const draft: IDraftAnswerCheck = {
        lessonId,
        homeworkId,
        packId,
        studentId,
        answers,
      };
      draftAnswers.push(draft);
    }
    localStorageWrapper.set(this.key, draftAnswers);
    emitter.emit(GlobalConstants.eventDraftSaved);
  };

  public static removeDraft = (
    lessonId: number,
    homeworkId: number,
    packId: number,
    studentId: number
  ) => {
    const data = localStorageWrapper.get(this.key) as IDraftAnswerCheck[];
    if (!data) {
      return;
    }
    const filteredData = data.filter(
      (item) =>
        item.lessonId !== lessonId ||
        item.homeworkId !== homeworkId ||
        item.packId !== packId ||
        item.studentId !== studentId
    );
    localStorageWrapper.set(this.key, filteredData);
  };

  public static getDraftAnswers = (
    lessonId: number | undefined,
    homeworkId: number,
    packId: number,
    studentId: number
  ): IAnswerWithTasksPackHwStudentId[] | undefined => {
    const data = localStorageWrapper.get(this.key) as IDraftAnswerCheck[];
    if (!data) {
      return;
    }
    const existedDraft = data.find(
      (x) =>
        x.lessonId == lessonId &&
        x.homeworkId == homeworkId &&
        x.packId == packId &&
        x.studentId === studentId
    );
    return existedDraft?.answers ?? [];
  };
}
