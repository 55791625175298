import { PageNavigator } from "components";
import { useHistoryOrdersPageNavigation } from "./hooks/useHistoryOrdersPageNavigation";
import { HistoryOrdersFilter } from "./components/HistoryOrdersFilter";
import { HistoryOrdersTable } from "./components/HistoryOrdersTable";

export const HistoryOrders: React.FC = () => {
  const { filter, dataPage, setLimit, setActivePage, onChangeFilter } =
    useHistoryOrdersPageNavigation();

  return (
    <>
      <HistoryOrdersFilter onChange={onChangeFilter} value={filter} />

      <HistoryOrdersTable data={dataPage?.data} />

      <div style={{ marginTop: 12 }}>
        <PageNavigator
          total={dataPage?.total}
          limit={filter.count ?? 10}
          activePage={filter.page ?? 1}
          onChangeLimit={setLimit}
          onChangeActivePage={setActivePage}
        />
      </div>
    </>
  );
};
