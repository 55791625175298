import {
  IEvent,
  IExam,
  IRegToken,
  ISubject,
  IThema,
  IThemaCategory,
} from "../stores/models";
import * as controllers from "./controllers";
import { DateHelper } from "./DateHelper";
import * as requests from "./requests";
import { get, post, serverUrl } from "./WebHelper";

interface IUploadFile {
  fileName: string;
  id: number;
}

class Api {
  public static readonly uploadFileEndpoint = serverUrl + "/file/upload";

  public static readonly exams = new controllers.CrudController<IExam>("Exam");
  public static readonly lessons = new controllers.LessonController();
  public static readonly promotions = new controllers.PromotionController();
  public static readonly globalNotifications =
    new controllers.GlobalNotificationController();
  public static readonly notifications =
    new controllers.NotificationController();
  public static readonly students = new controllers.StudentController();
  public static readonly teachers = new controllers.TeacherController();
  public static readonly mentors = new controllers.MentorController();
  public static readonly tariffs = new controllers.TariffController();
  public static readonly tech = new controllers.TechController();
  public static readonly subjects = new controllers.CrudController<ISubject>(
    "Subject"
  );
  public static readonly payment = new controllers.PaymentController();
  public static readonly promocodes = new controllers.PromocodeController();
  public static readonly files = new controllers.FileController();
  public static readonly statistics = new controllers.StatisticController();
  public static readonly logger = new controllers.LogController();
  public static readonly users = new controllers.UserController();
  public static readonly homeworks = new controllers.HomeworkController();
  public static readonly tasks = new controllers.TaskController();
  public static readonly taskPack = new controllers.TaskPackController();
  public static readonly themesCategories =
    new controllers.CrudController<IThemaCategory>("ThemeCategory");
  public static readonly themes = new controllers.CrudController<IThema>(
    "Theme"
  );
  public static readonly regTokens = new controllers.CrudController<IRegToken>(
    "RegToken"
  );

  public static async GetAchievementsForStudent(studentId: number) {
    return await get(`/Achievement/GetForStudent/${studentId}`);
  }
  public static async GetExamsForStudent() {
    return await get(`/Exam/GetForStudent/`);
  }

  public static async GetEvents(
    date: Date,
    refetch?: boolean
  ): Promise<IEvent[] | undefined> {
    return await get(
      `/Event/GetForMonth/${DateHelper.DateToStringWithTime(date)}`,
      { refetch }
    );
  }

  public static async GetTopStudents(params: requests.IRequestGetTopStudents) {
    return await get(`/Rating/GetTopStudents/`, { params });
  }

  public static async UploadFiles(
    formData: FormData
  ): Promise<IUploadFile | undefined> {
    return await post(`${serverUrl}/file/upload`, formData);
  }
}

export default Api;
