import React from "react";
import { observer } from "mobx-react";
import { TaskEditForm } from "./TaskEditForm";
import { Panel } from "shared/ui";

const PanelTaskEditComponent: React.FC = () => {
  return (
    <Panel bordered>
      <TaskEditForm />
    </Panel>
  );
};
export const PanelTaskEdit = observer(PanelTaskEditComponent);
