import { makeAutoObservable, runInAction } from "mobx";
import { NotificationHelper } from "root";
import Api from "../root/Api";
import { IRequestGetTasksByFilter } from "../root/requests";
import { IPageableStore, ITask } from "./models";
import { IPage } from "./models/IPage";
import { queryClient } from "provider/QueryProvider/QueryProvider";

export class TaskStore
  implements IPageableStore<IRequestGetTasksByFilter, ITask>
{
  tasks: ITask[];
  tasksForCreateTaskPack: ITask[];
  onLoading = false;
  onUpdating = false;

  filter: IRequestGetTasksByFilter | undefined;
  dataPage: IPage<ITask> | undefined;

  constructor() {
    makeAutoObservable(this);
    this.tasks = [];
    this.tasksForCreateTaskPack = [];
  }

  public find(id?: number) {
    return this.tasks.find((x) => x.id === id);
  }

  public async save(obj: ITask, taskId: number | undefined = undefined) {
    if (this.onUpdating) {
      return;
    }
    this.setOnUpdating(true);
    try {
      if (!taskId && obj.id > 0) {
        taskId = obj.id;
      }
      const result = taskId
        ? await Api.tasks.Update(taskId, obj)
        : await Api.tasks.Create(obj);
      const id = result;
      NotificationHelper.ShowSuccess("Успешно сохранено!");
      this.filter && this.loadByFilter(this.filter, true);
      return id;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnUpdating(false);
    }
    return null;
  }

  public async delete(id: number) {
    this.setOnUpdating(true);
    try {
      await Api.tasks.Delete(id);
      return true;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnUpdating(false);
    }
    return false;
  }

  public async addFavorite(id: number) {
    try {
      await Api.tasks.AddFavorite(id);
    } catch (error) {
      console.error(error);
    }
  }

  public async removeFavorite(id: number) {
    try {
      await Api.tasks.RemoveFavorite(id);
    } catch (error) {
      console.error(error);
    }
  }

  public async loadMyFavorite() {
    try {
      const result = await queryClient.fetchQuery({
        queryKey: ["Api.tasks.GetFavoriteForUser"],
        queryFn: async () => await Api.tasks.GetFavoriteForUser(),
      });

      return result;
    } catch (error) {
      console.error(error);
    }
  }

  public async loadByFilter(
    filter: IRequestGetTasksByFilter,
    refetch = false
  ): Promise<IPage<ITask> | undefined> {
    this.setOnLoading(true);
    const queryKey = ["tasks", filter];
    try {
      refetch && queryClient.removeQueries(queryKey);
      const result = await queryClient.fetchQuery({
        queryKey,
        queryFn: async () => await Api.tasks.GetTasksByFilter(filter),
      });
      runInAction(() => {
        this.filter = filter;
        this.dataPage = result;
      });
      return result;
    } catch (error) {
      console.error(error);
      return undefined;
    } finally {
      this.setOnLoading(false);
    }
  }

  public addTasksForCreateTaskPack(task: ITask) {
    this.tasksForCreateTaskPack.push(task);
  }
  public removeTasksForCreateTaskPack(task: ITask) {
    this.tasksForCreateTaskPack = this.tasksForCreateTaskPack.filter(
      (x) => x.id !== task.id
    );
  }
  public clearTasksForCreateTaskPack() {
    this.tasksForCreateTaskPack = [];
  }
  public hasTasksForCreateTaskPack(task: ITask): boolean {
    return this.tasksForCreateTaskPack.some((x) => x.id === task.id);
  }

  private setOnLoading(value: boolean) {
    this.onLoading = value;
  }

  private setOnUpdating(value: boolean) {
    this.onUpdating = value;
  }

  public clear() {
    runInAction(() => {
      this.tasks = [];
      this.tasksForCreateTaskPack = [];
      this.filter = undefined;
      this.dataPage = undefined;
    });
  }
}
