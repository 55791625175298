import React, { CSSProperties } from "react";
import { observer } from "mobx-react";
import { Pagination } from "shared/ui";

interface IPageNavigatorProps {
  total: number | undefined;
  limit: number;
  activePage: number;
  onChangeLimit: (limit: number) => void;
  onChangeActivePage: (page: number) => void;
  style?: CSSProperties;
}

const PageNavigatorComponent: React.FC<IPageNavigatorProps> = ({
  activePage,
  limit,
  onChangeActivePage,
  onChangeLimit,
  total = 0,
  style = {},
}) => (
  <Pagination
    prev
    next
    first
    last
    ellipsis
    boundaryLinks
    maxButtons={5}
    size="xs"
    layout={["total", "-", "limit", "|", "pager"]}
    total={total}
    limitOptions={[10, 30, 50]}
    limit={limit}
    activePage={activePage}
    onChangePage={onChangeActivePage}
    onChangeLimit={onChangeLimit}
    style={style}
  />
);

export const PageNavigator = observer(PageNavigatorComponent);
