import { IFileLesson, IHomeWork, IHomeWorkRequest } from ".";

export interface ILesson {
  id: number;
  title: string;
  description: string;
  lessonType: LessonType;
  dateTime: Date;
  imageUrl: string;
  videoUrl: string;
  webinarImageUrl: string;
  monthNumber: number;
  homeWorkTitle: string;
  homeWorkDescription: string;
  submitDeadline: Date;
  checkDeadline: Date;
  tariffId: number;
  homeWorks?: IHomeWork[];
  files: IFileLesson[];
  isViewed: boolean;
  //оплачен ли урок
  enabled: boolean;
  // Как проверять уроки
  checkHomeworkType: CheckHomeworkType;
}

export interface ILessonRequest {
  id?: number;
  title?: string;
  description?: string;
  lessonType?: LessonType;
  dateTime?: Date;
  imageUrl?: string;
  videoUrl?: string;
  webinarImageUrl?: string;
  homeWorkTitle?: string;
  homeWorkDescription?: string;
  submitDeadline?: Date;
  checkDeadline?: Date;
  tariffId?: number;
  homeWorks?: IHomeWorkRequest[];
  files?: IFileLesson[];
  checkHomeworkType?: CheckHomeworkType;
}

export enum LessonType {
  // Публичный
  Public = 1,
  // Основной
  Base = 2,
  // Дополнительный
  Additional = 3,
}

export enum CheckHomeworkType {
  // Наставник+. Домашнюю работу проверяют наставники и учителя
  Manual = 1,
  // Робокоп. Домашнюю работу не проверяют наставники и учителя.
  // Ученик сразу получает правильные ответы для самопроверки.
  Auto = 2,
}
