import React from "react";
import { StyleHelper } from "root/StyleHelper";
import { isAfter } from "rsuite/esm/utils/dateUtils";
import { IEvent } from "stores";

const timeStyle: React.CSSProperties = {
  marginLeft: "auto",
  position: "absolute",
  right: 0,
};

interface IProps {
  event: IEvent;
}

export const ScheduleCellItem: React.FC<IProps> = ({ event }) => {
  const isOldEvent = isAfter(new Date(), new Date(event.eventDateTime));

  const time = new Date(event.eventDateTime).toLocaleString("ru-RU", {
    hour: "2-digit",
    minute: "numeric",
  });

  const timeColor = isOldEvent ? "var(--rs-text-secondary)" : "var(--rs-body)";
  const backgroundColor = isOldEvent
    ? undefined
    : StyleHelper.getColorByEvent(event);

  return (
    <>
      <div
        className="we-calendar-todo-list-home-item"
        style={{
          padding: "0px 6px 0px 6px",
          backgroundColor: backgroundColor,
          marginBottom: 2,
          border: `1px solid ${StyleHelper.getColorByEvent(event)}`,
        }}
      >
        <div
          style={{
            display: "flex",
            position: "relative",
          }}
        >
          <span
            style={{
              whiteSpace: "nowrap",
              left: 0,
              width: "calc(100% - 40px)",
              overflow: "hidden",
            }}
          >
            {event.title}
          </span>
          <span
            style={{
              color: timeColor,
              ...timeStyle,
            }}
          >
            {time}
          </span>
        </div>
      </div>
    </>
  );
};
