import { useRefetchTariff, useStore } from "hooks";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import Api from "root/Api";
import { GlobalConstants } from "root/GlobalConstants";
import { NotificationHelper } from "root/NotificationHelper";
import { localStorageWrapper } from "root/wrappers";
import { Modal } from "rsuite";
import { Loader } from "shared/ui";

const TariffHandlerComponent: React.FC = () => {
  const navigate = useNavigate();
  const { authStore } = useStore();
  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const token = searchParams.get("promo") || undefined;
  const [open, setOpen] = useState(false);

  const { refetchTariff } = useRefetchTariff();

  useEffect(() => {
    handleAuthorization();
  }, [authStore.me]);

  useEffect(() => {
    if (!token || !authStore.me) {
      return;
    }
    Api.students.AddTariffByToken(token);
  }, [token]);

  const handleAuthorization = () => {
    if (!authStore.me) {
      return;
    }
    if (localStorageWrapper.get(GlobalConstants.isUnauthorizedGetPayUrl)) {
      navigate("/cart");
      return;
    }
    const freeTariffId = localStorageWrapper.get(
      GlobalConstants.tariffIdForFreeGet
    ) as number;

    if (freeTariffId) {
      getFreeTariff(freeTariffId);
      setOpen(true);
    }
  };

  const getFreeTariff = (tariffId: number) => {
    Api.students.AddFreeTariff(tariffId).then(async () => {
      NotificationHelper.ShowSuccess(
        'Доступ к курсу получен.\nВсе вебинары находятся во вкладе "Уроки"'
      );
      await refetchTariff(tariffId);

      localStorageWrapper.remove(GlobalConstants.tariffIdForFreeGet);
      setOpen(false);
    });
  };

  return (
    <Modal open={open} size="full">
      <Modal.Body style={{ height: 200 }}>
        <Loader size="lg" vertical content="Загружаем курс..." center />
      </Modal.Body>
    </Modal>
  );
};
export const TariffHandler = observer(TariffHandlerComponent);
