import { get } from "../WebHelper";
import * as requests from "../requests";
import { IStudentPerformance, IStudentTopDto } from "stores";

export class StatisticController {
  public async GetStudentPerformance(
    studentId: number
  ): Promise<IStudentPerformance[] | undefined> {
    return await get(`/Statistic/StudentPerformance/${studentId}`);
  }
  public async GetStudentsTop(
    params: requests.IRequestGetStudentsTop,
    mentorId = 0
  ): Promise<IStudentTopDto[] | undefined> {
    return await get(`/Statistic/StudentsTop/${mentorId}`, { params });
  }
  public async getTopMonths(tariffId: number): Promise<number[] | undefined> {
    return await get("/Statistic/TopMonths/", { params: { tariffId } });
  }
}
