import { Modal } from "rsuite";
import { observer } from "mobx-react";
import { LessonsEdit } from "../LessonsEdit";
import { useIsMounted, useStore } from "hooks";
import { ILesson } from "stores";
import { useState, useEffect } from "react";
import { Loader } from "shared/ui";

interface IModalCreateEditLessonProps {
  lessonId?: number;
  isOpen: boolean;
  onClose: () => void;
}
const ModalCreateEditLessonComponent: React.FC<IModalCreateEditLessonProps> = (
  props
) => {
  const isMounted = useIsMounted();
  const { lessonsStore } = useStore();
  const [lesson, setLesson] = useState<ILesson>();
  useEffect(() => {
    if (!props.lessonId) {
      return;
    }
    lessonsStore.findOrLoad(props.lessonId).then((lesson) => {
      if (!isMounted()) {
        return;
      }
      setLesson(lesson);
    });
  }, [lessonsStore.onLoading, props.lessonId]);

  return (
    <>
      <div className="modal-container">
        <Modal
          backdrop="static"
          open={props.isOpen}
          onClose={props.onClose}
          size="md"
          overflow={false}
        >
          <Modal.Header>
            <Modal.Title>
              {props.lessonId && props.lessonId > 0
                ? "Редактирование урока"
                : "Создание урока"}
            </Modal.Title>
          </Modal.Header>
          <Modal.Body>
            {!lesson && props.lessonId ? (
              <Loader />
            ) : (
              <LessonsEdit lesson={lesson} onClose={props.onClose} />
            )}
          </Modal.Body>
        </Modal>
      </div>
    </>
  );
};
export const ModalCreateEditLesson = observer(ModalCreateEditLessonComponent);
