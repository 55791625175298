import React, { useState } from "react";
import { Drawer, Modal, Stack, Whisper } from "rsuite";
import { PromotionForm } from "../PromotionForm";
import InfoRoundIcon from "@rsuite/icons/InfoRound";
import { PromotionCreationRules } from "components";
import { Tooltip } from "shared/ui";

interface IEditPromotionModalProps {
  promotionId?: number;
  isOpen: boolean;
  onClose: () => void;
}

export const EditPromotionModal: React.FC<IEditPromotionModalProps> = (
  props
) => {
  const [open, setOpen] = useState(false);
  return (
    <>
      <Drawer open={open} onClose={() => setOpen(false)}>
        <Drawer.Header>
          <Drawer.Title>Как создавать акции</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <PromotionCreationRules />
        </Drawer.Body>
      </Drawer>
      <Modal
        backdrop="static"
        open={props.isOpen}
        onClose={() => props.onClose()}
        size="sm"
      >
        <Modal.Header>
          <Modal.Title>
            <Stack spacing={6}>
              {props.promotionId && props.promotionId > 0
                ? "Редактирование акции"
                : "Создание акции"}

              <Whisper
                placement="auto"
                speaker={
                  <Tooltip>
                    Акция применяется в корзине автоматически. <br />
                    Для применения акции все условия должны быть соблюдены.
                    <br />
                    Нажмите на иконку, если нужны примеры.
                  </Tooltip>
                }
              >
                <span>
                  <InfoRoundIcon onClick={() => setOpen(true)} />
                </span>
              </Whisper>
            </Stack>
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <PromotionForm
            promotionId={props.promotionId}
            onClose={props.onClose}
          />
        </Modal.Body>
      </Modal>
    </>
  );
};
