import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { IAnswer, ITask, ITaskPack } from "stores";
import { TaskCheckedSwitch as TaskCheckedSwitch } from "./TaskCheckedSwitch";
import { TaskCheckedView } from "./TaskCheckedView";

export interface IHomeworkCheckedProps {
  answers?: IAnswer[];
  taskPack?: ITaskPack;
}
const HomeworkCheckedComponent: React.FC<IHomeworkCheckedProps> = (
  props: IHomeworkCheckedProps
) => {
  const [selectedTask, setSelectedTask] = useState<ITask>();
  const [taskId, setTaskId] = useState<number>();
  const firstTaskFromPack = props.taskPack?.tasks[0];
  useEffect(() => {
    setSelectedTask(firstTaskFromPack);
    setTaskId(firstTaskFromPack?.id);
  }, [props.taskPack]);

  useEffect(() => {
    if (taskId && taskId > 0) {
      setSelectedTask(props.taskPack?.tasks.find((x) => x.id === taskId));
    }
  }, [taskId]);

  return (
    <>
      <TaskCheckedSwitch
        taskPack={props.taskPack}
        answers={props.answers}
        onChange={(id) => setTaskId(id)}
        taskId={taskId}
      />
      <TaskCheckedView
        answer={props.answers?.find((a) => a.taskId === selectedTask?.id)}
        task={selectedTask}
      />
    </>
  );
};
export const HomeworkChecked = observer(HomeworkCheckedComponent);
