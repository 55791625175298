import _ from "lodash";
import { useState, useEffect } from "react";
import { IFilter, IPage, IPageableStore } from "stores";
import { useSessionStorage } from "./useLocalSessionsStorage";
import { autorun } from "mobx";

export function usePageFilter<TModel, TFilter extends IFilter>(
  initialFilter: TFilter,
  store: IPageableStore<TFilter, TModel>,
  nameStorage: string
) {
  const [filter, setFilter] = useSessionStorage<TFilter>(
    nameStorage,
    initialFilter
  );
  const [limit, setLimit] = useState(filter.count);
  const [activePage, setActivePage] = useState(filter.page || 1);
  const [dataPage, setDataPage] = useState<IPage<TModel>>();

  useEffect(() => {
    autorun(() => {
      store.dataPage && setDataPage(store.dataPage);
    });
  }, []);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      count: limit,
      page: activePage,
    }));
  }, [limit, activePage]);

  useEffect(() => {
    if (!dataPage) {
      return;
    }
    const totalPages = dataPage.totalPages || 1;
    setActivePage(Math.min(activePage, totalPages));
  }, [dataPage]);

  useEffect(() => {
    store.loadByFilter(filter);
  }, [filter]);

  const onChangeFilter = (newFilter: TFilter) => {
    const updatedFilter = {
      ...newFilter,
      count: filter.count || limit,
      page: filter.page || activePage,
    };
    if (_.isEqual(updatedFilter, filter)) {
      return;
    }
    setFilter(updatedFilter);
  };

  return {
    filter,
    dataPage,
    setLimit,
    setActivePage,
    onChangeFilter,
  };
}
