import { observer } from "mobx-react";
import { useState } from "react";
import { IStudentTopDto } from "stores";
import { FilterForTop } from "./Filter/FilterForTop";
import { ViewTop } from "./ViewTop";

const TopComponent: React.FC = () => {
  const [isLoadingStudentTop, setIsLoadingStudentTop] = useState<boolean>(true);
  const [studentsTop, setStudentsTop] = useState<
    IStudentTopDto[] | undefined
  >();

  return (
    <>
      <FilterForTop
        onChangeStudentsTop={setStudentsTop}
        onChangeLoading={setIsLoadingStudentTop}
      />
      <ViewTop
        isLoadingStudentTop={isLoadingStudentTop}
        studentsTop={studentsTop}
      />
    </>
  );
};

export const Top = observer(TopComponent);
