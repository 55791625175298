import React from "react";
import { Button } from "shared/ui";

type TButtonSubmitProps = {
  isDisable?: boolean;
  isLoading?: boolean;
  onClick: () => void;
};

export const ButtonSubmit: React.FC<TButtonSubmitProps> = ({
  isDisable,
  isLoading,
  onClick,
}) => (
  <div
    style={{
      marginTop: 10,
      display: "flex",
      justifyContent: "flex-end",
    }}
  >
    <Button
      disabled={isDisable}
      loading={isLoading}
      appearance="primary"
      color="violet"
      onClick={onClick}
    >
      Сохранить
    </Button>
  </div>
);
