import { observer } from "mobx-react";
import { useState } from "react";
import { EditRegTokenModal } from "./EditRegTokenModal";
import PlusIcon from "@rsuite/icons/Plus";
import { Button } from "shared/ui";

const ButtonCreateRegTokenComponent: React.FC = () => {
  const [isOpenEditModal, setOpenEditModal] = useState<boolean>(false);

  return (
    <>
      <EditRegTokenModal
        isOpen={isOpenEditModal}
        onClose={() => setOpenEditModal(false)}
      />
      <Button
        onClick={() => {
          setOpenEditModal(true);
        }}
        appearance="primary"
        color="orange"
        style={{ marginBottom: 15 }}
      >
        <PlusIcon />
      </Button>
    </>
  );
};
export const ButtonCreateRegToken = observer(ButtonCreateRegTokenComponent);
