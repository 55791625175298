import React, { CSSProperties } from "react";
import { observer } from "mobx-react";
import { LogoIcon } from "components";

const imgStyle: CSSProperties = {
  verticalAlign: "top",
  width: "100%",
  borderRadius: 10,
  objectFit: "cover",
  height: 170,
  marginBottom: 10,
  backgroundColor: "var(--rs-body)",
};

const displayFlexStyle: CSSProperties = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

interface IImageTariffProps {
  imageUrl: string | undefined;
}

const ImageTariffComponent: React.FC<IImageTariffProps> = (props) => {
  const style = props.imageUrl
    ? imgStyle
    : { ...imgStyle, ...displayFlexStyle };
  return (
    <div style={style}>
      {props.imageUrl ? (
        <img style={imgStyle} src={props.imageUrl} alt="Картинка курса" />
      ) : (
        <LogoIcon size={80} color="#6E4ABE" />
      )}
    </div>
  );
};
export const ImageTariff = observer(ImageTariffComponent);
