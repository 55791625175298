export const SummaryWritingRules: React.FC = () => {
  return (
    <>
      <p>
        <strong>Общее положение:</strong>
      </p>
      <p>
        ✅ Необязательно соблюдать формальный стиль, вы можете описывать
        преимущества и недостатки в своем стиле. Главное,{" "}
        <strong>
          <span style={{ textDecoration: "underline" }}>
            без матов и предвзятого отношения к ребенку
          </span>
        </strong>
        . Вывод должен направить ребенка, обозначив слабые места и выделив
        сильные.{" "}
        <span style={{ textDecoration: "underline" }}>
          <strong>
            Уничижительные замечания запрещаются в выводе о работе, даже если
            ребенок ее списал.
            <br />
          </strong>
        </span>
        ✅ Можно использовать смайлики
        <br />
        ✅&nbsp;Пишем{" "}
        <span style={{ textDecoration: "underline" }}>
          <strong>вывод индивидуально под каждого ученика!</strong>
        </span>{" "}
        Если выводы во всех работах будут одинаковыми &ndash; переделываем.
        <br />✅ Итоговый вывод после преимуществ и недостатков начинаем с
        обращение к ученику по имени (например, &laquo;Оля, ты молодец!...)
        <br />✅ Минимум ДВА преимущества и недостатка можно выделить. Если
        преимуществ нет совсем, то постарайтесь выделить хотя бы одно. Если
        ученик все сделал идеально и у него нет недостатков, то так и напишите в
        поле &laquo;Недостатки&raquo;: &laquo;их нет! Ты супер! (это просто
        пример)
      </p>
      <p>
        В каждой работе мы обязательно выделяем преимущества и недостатки в
        работе, также пишем итоговый вывод о работе.
        <br />
        <br />
        <br />В <strong>преимуществах</strong> указываем следующее(
        <em>если имеет место быть</em>):
      </p>
      <p>
        ✅ Состояние первой части (например, тестовая часть сдана вовремя,
        тестовая часть сделана на максимум и пр.)
        <br />✅ Сдаваемость в дд (например, молодец! Сдал работу вовремя)
        <br />✅ Оформление работы (например, &laquo;Молодчинка! Оформлено все
        по правилам&raquo;)
        <br />✅ Состояние заданий в работе (например, &laquo;Отличная работа с
        графиком! Видно, что понимаешь все тонкости по его оформлению&raquo;)
        <br />✅ Отдельные преимущества, которые вас зацепили (например,
        &laquo;У тебя очень складная речь! Вижу, что ты освоил обществоведческий
        язык) и пр.
      </p>
      <p>
        В <strong>недостатках</strong> указываем следующее(
        <em>если имеет место быть</em>):
        <br />✅ Состояние первой части (например, тестовая часть не сдана
        вовремя, тестовая часть сделана плохо и пр.)
        <br />✅ Сдаваемость в дд (например, &laquo;К сожалению, дедлайн
        просрочен. Больше так не делай&raquo;)
        <br />✅ Оформление работы (например, &laquo;Надо еще поработать над
        оформлением! Не забывай прописывать заголовки&raquo;)
        <br />✅ Состояние заданий в работе (например, &laquo;Вижу, что с
        графиком пока не очень хорошо работаешь. Надо повторить неценовые
        факторы спроса и предложения, а также то, как именно меняется
        равновесная цена&raquo;)
        <br />✅ Отдельные недостатки, которые вас зацепили (например,
        &laquo;Работа написана бытовым языком. Нужно использовать
        обществоведческий язык: не &laquo;люди начали покупать больше&raquo;, а
        &laquo;спрос увеличился&raquo;) и пр.
      </p>
      <p>
        <strong>
          Вывод:
          <br />
        </strong>
        ✅ Обобщает все мысли наставника о проверенной работе. <br />✅ Здесь
        можно дать советы, наставления, подытожить недостатки и пр. <br />✅
        После прочтения вывода, ученик должен понять, что у него все получится,
        главное &ndash; не сдаваться и проработать все то, на что указал
        наставник.
      </p>
      <br /> <br />
      <p>
        <strong>
          <em>Пример вывода:</em>
        </strong>
        <strong>&nbsp;</strong>
      </p>
      <p>
        <strong>
          <span>Преимущества:</span>
        </strong>
      </p>
      <p>1) Оформление работы у тебя также очень грамотное, все нравится)</p>
      <p>2) Примеры в задании с социальными конфликтами мне понравились)</p>
      <p>
        <strong>
          <span>Недостатки</span>:
        </strong>
      </p>
      <p>1) Примеры должны быть конкретными, детализированными!!</p>
      <p>
        2) Аргументы должны быть составлены о структуре!!! Тезис + анализ +
        подводка
      </p>
      <p>
        3) В каждом пункте должно быть минимум три подпункта! Помимо тех
        случаев, когда мы просто не можем написать больше подпунктов (уровни
        научного познания: эмпирический, теоретический)
      </p>
      <p>
        <strong>Вывод:</strong>
      </p>
      <p>Кристина, нужно работать над формулированием своих мыслей. Все</p>
      <p>аргументы и примеры должны быть законченными и полноценными.</p>
      <p>Тебе нужно перечитывать свои ответы, чтобы понимать, насколько</p>
      <p>качественно ты сформулировала свою мысль.</p>
      <p>&nbsp;</p>
      <p>&nbsp;</p>
    </>
  );
};
