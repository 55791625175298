import { Placeholder } from "rsuite";

export const TitlePlaceholder: React.FC = () => (
  <Placeholder.Paragraph
    rows={1}
    rowHeight={20}
    rowMargin={0}
    style={{ width: 100 }}
    active
  />
);
