import { IStudent } from "./IStudent";

export interface IStudentPerformance {
  lessonId: number;
  firstPartPercent: number;
  secondPartPercent: number;
  allPartScore: number;
  allPartMaxScore: number;
  firstPartMaxScore: number;
  firstPartScore: number;
  secondPartMaxScore: number;
  secondPartScore: number;
}

export interface IStudentTopDto {
  studentId: number;
  performance: number;
  student: IStudent;
}

export enum PartPercentType {
  // Проценты по первой части
  FirstPart = 1,
  // Проценты по второй части
  SecondPart = 2,
  // Общий процент успеваемости
  AllPart = 3,
}
