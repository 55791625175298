import React, { CSSProperties } from "react";
import { InputProps } from "rsuite";
import TextareaAutosize from "react-textarea-autosize";
import { RsRefForwardingComponent } from "rsuite/esm/@types/common";
import { Input } from "shared/ui";

type TTextareaProps = {
  defaultValue?: string;
  onChange: (value: string) => void;
  style?: CSSProperties;
  placeholder?: string;
  autoComplete?: string | undefined;
};

export const Textarea = React.forwardRef<
  RsRefForwardingComponent<"input", InputProps>,
  TTextareaProps
>(function Textarea(
  {
    onChange,
    autoComplete = "off",
    defaultValue,
    placeholder,
    style,
    ...props
  },
  ref
) {
  return (
    <Input
      autoComplete={autoComplete}
      defaultValue={defaultValue}
      inputRef={ref}
      placeholder={placeholder}
      {...props}
      as={TextareaAutosize}
      minRows={1.5}
      style={{ ...style, resize: "none" }}
      onChange={onChange}
    />
  );
});
