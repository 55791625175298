import { observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
import { ScheduleDisplay } from "./ScheduleDisplay";
import { LayoutPanelWithButton } from "../Layout/LayoutPanelWithButton";
import { Button } from "shared/ui";

const ScheduleUserComponent: React.FC = () => {
  return (
    <LayoutPanelWithButton
      title="Ближайшие занятия"
      button={
        <Button appearance="default" as={Link} to="/schedule">
          Расписание
        </Button>
      }
    >
      <div style={{ overflowX: "auto" }}>
        <ScheduleDisplay />
      </div>
    </LayoutPanelWithButton>
  );
};
export const ScheduleUser = observer(ScheduleUserComponent);
