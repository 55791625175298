import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FlexboxGrid } from "rsuite";
import { ScheduleCell } from "./ScheduleCell";
import { ScheduleDrawer } from "components/Schedule/Drawer/ScheduleDrawer";

export interface IMiniScheduleProps {
  countRenderDays: number;
}

const MiniScheduleComponent: React.FC<IMiniScheduleProps> = (
  props: IMiniScheduleProps
) => {
  const [dates, setDates] = useState<Date[]>([]);
  const [open, setOpen] = useState(false);
  const [selectedDate, setSelectedDate] = useState<Date>(new Date());

  useEffect(() => {
    const dates: Date[] = [];
    const today = new Date();

    for (let i = 0; i < props.countRenderDays; i++) {
      const date: Date = new Date(
        today.getFullYear(),
        today.getMonth(),
        today.getDate() + i
      );

      dates.push(date);
    }
    setDates(dates);
  }, []);

  const handleClick = (date: Date) => {
    setSelectedDate(date);
    setOpen(true);
  };
  return (
    <>
      <ScheduleDrawer
        date={selectedDate}
        isOpen={open}
        onClose={() => setOpen(false)}
      />

      <div className="we-calendar-todo-list-home">
        <FlexboxGrid>
          {dates.map((date: Date) => (
            <ScheduleCell
              key={date.toString()}
              date={date}
              onClick={handleClick}
            />
          ))}
        </FlexboxGrid>
      </div>
    </>
  );
};
export const MiniSchedule = observer(MiniScheduleComponent);
