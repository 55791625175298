import { Textarea } from "components";
import React from "react";
import { Button, Panel } from "shared/ui";

type TReCheckPanelProps = {
  setReCheckComment: (value: string) => void;
  onClick: () => void;
  isLoading?: boolean;
};

export const ReCheckPanel: React.FC<TReCheckPanelProps> = ({
  setReCheckComment,
  onClick,
  isLoading,
}) => (
  <Panel bordered>
    <label>
      <p style={{ marginBottom: 12 }}>Комментарий для перепроверяющего</p>
      <Textarea
        placeholder="Опишите на что необходимо обратить внимание перепроверяющему..."
        onChange={setReCheckComment}
        style={{ marginBottom: 12 }}
      />
    </label>
    <div style={{ display: "flex", justifyContent: "flex-end" }}>
      <Button
        loading={isLoading}
        disabled={isLoading}
        appearance="primary"
        color="red"
        onClick={onClick}
      >
        На перепроверку
      </Button>
    </div>
  </Panel>
);
