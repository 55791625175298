import { useIsMounted, useStore } from "hooks";
import { observer } from "mobx-react";
import { useEffect, useMemo, useState } from "react";
import { FlexboxGrid, Stack } from "rsuite";
import { IHomeworkCheck, TaskType } from "stores";
import { styleCenter, titleStyle } from "../styles";
import { TitlePlaceholder } from "./TitlePlaceholder";

const taskTypes = [
  { type: TaskType.FirstPart, title: "Первая часть" },
  { type: TaskType.SecondPart, title: "Вторая часть" },
  { type: TaskType.FileAnswer, title: "Сдать файл" },
  { type: TaskType.FileQuestion, title: "Файл-вопрос" },
];

interface IProps {
  check: IHomeworkCheck;
}

const LessonCellComponent: React.FC<IProps> = ({ check }) => {
  const { lessonsStore, taskPackStore } = useStore();

  const isMounted = useIsMounted();

  const [lessonTitle, setLessonTitle] = useState<string>();
  const [checkTitle, setCheckTitle] = useState<string>();

  useEffect(() => {
    if (!check) {
      return;
    }
    getLessonTitle(check);
    getCheckTitle(check);
  }, [check]);

  const getTaskPackTypeTitles = useMemo(
    () =>
      async (packId: number): Promise<string> => {
        const taskPack = await taskPackStore.findOrLoad(packId);
        const taskTitles: string[] = [];

        taskTypes.forEach((taskType) => {
          if (taskPack?.tasks.some((task) => task.type === taskType.type)) {
            taskTitles.push(taskType.title);
          }
        });

        return taskTitles.join(", ");
      },
    [check]
  );

  const getLessonTitle = (check: IHomeworkCheck) =>
    lessonsStore
      .findOrLoad(check.lessonId)
      .then((lesson) => isMounted() && lesson && setLessonTitle(lesson.title));

  const getCheckTitle = (check: IHomeworkCheck) =>
    getTaskPackTypeTitles(check.packId).then(
      (taskPackTypeTitles) => isMounted() && setCheckTitle(taskPackTypeTitles)
    );

  return (
    <FlexboxGrid.Item
      colspan={8}
      style={{
        ...styleCenter,
        flexDirection: "column",
        alignItems: "flex-start",
        overflow: "hidden",
      }}
    >
      <div style={titleStyle}>
        <Stack spacing={6} style={{ minHeight: 26 }}>
          Урок:
          {lessonTitle ? lessonTitle : <TitlePlaceholder />}
        </Stack>
        <Stack spacing={6} style={{ minHeight: 26 }}>
          Тип задания:
          {checkTitle ? checkTitle : <TitlePlaceholder />}
        </Stack>
      </div>
    </FlexboxGrid.Item>
  );
};
export const LessonCell = observer(LessonCellComponent);
