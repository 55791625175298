import { CSSProperties } from "react";
import { List, Placeholder } from "rsuite";

interface IPlaceholderLessonItemProps {
  items: number;
  style?: CSSProperties;
}
export const PlaceholderLessonItem: React.FC<IPlaceholderLessonItemProps> = ({
  items,
  style = {},
}) => {
  const fakeArray = Array(items).fill(0);
  return (
    <>
      <List bordered hover style={style}>
        {fakeArray.map((_x, i) => (
          <List.Item key={i}>
            <Placeholder.Paragraph active graph="square" />
          </List.Item>
        ))}
      </List>
    </>
  );
};
