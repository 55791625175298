import RemindIcon from "@rsuite/icons/legacy/Remind";
import React, { useEffect, useState } from "react";
import { emitter } from "root/EventHelper";
import { GlobalConstants } from "root/GlobalConstants";
import { logOutHelper } from "root/LogoutHelper";
import { Modal } from "rsuite";
import { Button } from "shared/ui";

const modalBodyStyle = { display: "flex", alignItems: "center", gap: 15 };
const modalIconStyle = { color: "#ffb300", fontSize: 24 };

export const FailedAuthModal: React.FC = () => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    const handleFailedAuth = () => {
      setOpen(true);
    };
    emitter.on(GlobalConstants.eventFailedAuth, handleFailedAuth);
    return () => {
      emitter.off(GlobalConstants.eventFailedAuth, handleFailedAuth);
    };
  }, []);

  const handleClose = () => setOpen(false);
  const onClickButton = () => {
    logOutHelper.logout();
    handleClose();
  };

  return (
      <Modal open={open} backdrop="static" size="xs">
        <Modal.Body style={modalBodyStyle}>
          <RemindIcon style={modalIconStyle} />
          <div>
            <p>Ошибка авторизации!</p>
            <p>Вам нужно войти еще раз </p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={onClickButton} appearance="primary">
            Ок
          </Button>
        </Modal.Footer>
      </Modal>
  );
};
