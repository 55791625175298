import { useIsMounted } from "hooks";
import { observer } from "mobx-react";
import { RefAttributes, useEffect, useState } from "react";
import Api from "root/Api";
import { EnumConverter } from "root/EnumConverter";
import { ILogRow, LogLevel } from "root/controllers";
import { Table, SelectPicker } from "rsuite";
import { InnerCellProps } from "rsuite-table/lib/Cell";
import { isAfter, isBefore } from "rsuite/esm/utils/dateUtils";
import { Panel, DatePicker, Input } from "shared/ui";
const { Column, HeaderCell, Cell } = Table;

const LogsTableComponent: React.FC = () => {
  const isMounted = useIsMounted();
  const [logs, setLogs] = useState<ILogRow[]>();
  const [dateFrom, setDateFrom] = useState<Date | null>();
  const [dateTo, setDateTo] = useState<Date | null>();
  const [logLevel, setLogLevel] = useState<LogLevel | null>(LogLevel.Critical);
  const [search, setSearch] = useState<string | undefined>();
  const [onLoading, setOnLoading] = useState<boolean>();

  const selectLogLevel = EnumConverter.mapEnum(
    LogLevel,
    EnumConverter.LogLevelToStr
  );

  useEffect(() => {
    setOnLoading(true);
    Api.logger
      .Get(dateFrom, dateTo, logLevel, search)
      .then((result) => {
        if (!result) {
          throw new Error("Api.logger.Get вернул undefined");
        }
        const logList = result.map((x, i) => {
          return { ...x, id: i };
        });
        if (!isMounted()) {
          return;
        }
        setLogs(logList);
        setOnLoading(false);
      })
      .catch((error) => console.error(error));
  }, [dateFrom, dateTo, logLevel, search]);

  const CompactCell = (
    props: JSX.IntrinsicAttributes &
      InnerCellProps &
      RefAttributes<HTMLDivElement>
  ) => <Cell {...props} style={{ padding: 4 }} />;
  const LogLevelCell = ({ rowData, dataKey, ...props }: any) => {
    const rowLevel = rowData[dataKey] as LogLevel;
    let style: React.CSSProperties = {
      display: "flex",
      alignContent: "center",
      flexWrap: "wrap",
      padding: "4px",
    };
    switch (rowLevel) {
      case LogLevel.Critical:
        style = { ...style, backgroundColor: "#ff000ac9", color: "white" };
        break;
      case LogLevel.Warning:
        style = { ...style, backgroundColor: "#ffff0040" };
        break;
      case LogLevel.Information:
        style = { ...style, backgroundColor: "#0061fe1f" };
        break;
    }
    return (
      <Cell {...props} className="link-group" style={style}>
        {rowLevel}
      </Cell>
    );
  };
  return (
    <>
      <Panel bordered bodyFill>
        <DatePicker
          placeholder="Дата от"
          format="dd-MM-yyyy HH:mm"
          name="dateTimeFrom"
          shouldDisableDate={(date: any) =>
            date ? isAfter(date, new Date()) : false
          }
          onChange={(x) => setDateFrom(x)}
          style={{ width: 180 }}
        />
        <DatePicker
          placeholder="Дата до"
          format="dd-MM-yyyy HH:mm"
          name="dateTimeTo"
          shouldDisableDate={(date: any) =>
            date
              ? isAfter(date, new Date()) &&
                (!dateFrom || isBefore(date, dateFrom))
              : false
          }
          onChange={(x) => setDateTo(x)}
          style={{ width: 180 }}
        />
        <SelectPicker
          data={selectLogLevel}
          value={logLevel}
          onChange={(x) => {
            setLogLevel(x);
          }}
          style={{ width: 150 }}
        />
        <Input
          placeholder="Поиск..."
          name="search"
          value={search}
          onChange={(x) => {
            setSearch(x);
          }}
          style={{ width: 250 }}
        />
        <Table
          rowKey="id"
          virtualized
          loading={onLoading}
          height={600}
          data={logs}
          style={{ marginTop: 25, fontFamily: "Consolas" }}
          rowHeight={30}
        >
          <Column>
            <HeaderCell>timestamp</HeaderCell>
            <CompactCell dataKey="timestamp" />
          </Column>
          <Column width={100}>
            <HeaderCell>logLevel</HeaderCell>
            <LogLevelCell dataKey="logLevel" />
          </Column>
          <Column width={700} fullText resizable>
            <HeaderCell>message</HeaderCell>
            <CompactCell dataKey="message" />
          </Column>
          <Column width={400} fullText>
            <HeaderCell>category</HeaderCell>
            <CompactCell dataKey="category" />
          </Column>
        </Table>
      </Panel>
    </>
  );
};
export const LogsTable = observer(LogsTableComponent);
