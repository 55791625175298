import { getDate, isToday } from "date-fns";
import React from "react";

const todayStyle: React.CSSProperties = {
  backgroundColor: "var(--rs-calendar-today-bg)",
  borderRadius: "50%",
  color: "var(--rs-calendar-today-text)",
  height: 20,
  textAlign: "center",
  width: 20,
};

const calendarCellHeaderStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  paddingBottom: 4,
};

interface IProps {
  date: Date;
}

export const ScheduleHeader: React.FC<IProps> = ({ date }) => {
  const weekdays: string[] = ["вс", "пн", "вт", "ср", "чт", "пт", "сб"];
  const months: string[] = [
    "янв",
    "фев",
    "мар",
    "апр",
    "май",
    "июн",
    "июл",
    "авг",
    "сен",
    "окт",
    "ноя",
    "дек",
  ];
  return (
    <p style={calendarCellHeaderStyle}>
      <span style={isToday(date) ? todayStyle : {}}>{`${getDate(date)}`}</span>
      <span style={{ marginLeft: 3 }}>{`${months[date.getMonth()]}, ${
        weekdays[date.getDay()]
      }`}</span>
    </p>
  );
};
