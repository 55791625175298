import React from "react";
import { observer } from "mobx-react";
import { ILesson, IHomeWork } from "stores";
import { HomeworkTaskPackButton } from "./ButtonPack";
import { Panel } from "shared/ui";

export interface IHomeWorkProps {
  homeWork: IHomeWork;
  currentLesson: ILesson;
  lessonFromParent: ILesson;
  homeworkFromParent?: number;
  taskpackFromParent?: number;
  onClick?: () => void;
}
const HomeWorkComponent: React.FC<IHomeWorkProps> = ({
  homeWork,
  currentLesson,
  lessonFromParent,
  homeworkFromParent,
  taskpackFromParent,
  onClick,
}) => {
  return (
    <Panel bodyFill style={{ marginTop: 15 }}>
      <p>
        <b>{homeWork.title}</b>
      </p>
      <p style={{ marginBottom: 10, overflowWrap: "break-word" }}>
        {homeWork.description}
      </p>
      {homeWork.packs?.map((pack) => {
        return (
          <HomeworkTaskPackButton
            pack={pack}
            homeWork={homeWork}
            currentLesson={currentLesson}
            lessonFromParent={lessonFromParent}
            homeworkFromParent={homeworkFromParent}
            taskpackFromParent={taskpackFromParent}
            key={currentLesson.id + "_" + homeWork.id + "_" + pack.id}
            onClick={onClick}
          />
        );
      })}
    </Panel>
  );
};
export const HomeWork = observer(HomeWorkComponent);
