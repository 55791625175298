import EditIcon from "@rsuite/icons/Edit";
import VisibleIcon from "@rsuite/icons/Visible";
import { StatusHomeworkBtn, StatusSendedBtnComponent } from "components";
import { useIsMounted, useStore } from "hooks";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { FlexboxGrid } from "rsuite";
import { IconButton } from "shared/ui";
import {
  CheckHomeworkType,
  HomeworkStudentCheckStatus,
  HomeworkStudentStatus,
  IHomeworkCheck,
  ILesson,
} from "stores";

const linkStyle = {
  display: "flex",
  alignItems: "center",
  textDecoration: "none",
  color: "inherit",
};
const buttonCellStyle = { display: "flex", justifyContent: "flex-end" };

interface IHomeworkItemProps {
  lessonId: number;
  check: IHomeworkCheck;
}

const HomeworkItemComponent: React.FC<IHomeworkItemProps> = (
  props: IHomeworkItemProps
) => {
  const { check } = props;
  const isMounted = useIsMounted();
  const { lessonsStore, authStore } = useStore();

  const [lesson, setLesson] = useState<ILesson>();
  useEffect(() => {
    lessonsStore.findOrLoad(props.lessonId).then((lesson) => {
      if (!isMounted()) {
        return;
      }
      setLesson(lesson);
    });
  }, [props.lessonId]);

  const isChecked = check.checkStatus !== HomeworkStudentCheckStatus.notChecked;
  const isNotSend = check.homeworkStatus === HomeworkStudentStatus.notSend;
  const isSendCorrect =
    check.homeworkStatus === HomeworkStudentStatus.sendCorrect;
  const isSend = check.homeworkStatus !== HomeworkStudentStatus.notSend;
  const isSendLate = check.homeworkStatus === HomeworkStudentStatus.sendLate;

  const isAutoCheck =
    lesson?.checkHomeworkType === CheckHomeworkType.Auto ||
    authStore.myMonthNumbersVideoLessons.some(
      (x) =>
        x.monthNumber === lesson?.monthNumber && x.tariffId === lesson?.tariffId
    );

  const link = `/lessons/${check.lessonId}/${check.homeworkId}/${check.packId}`;
  const homeWorkStatusTitleButton = isNotSend
    ? "Сдать домашку"
    : "Посмотреть проверку";
  const homeWorkStatusIcon = isNotSend ? <EditIcon /> : <VisibleIcon />;
  const title = lessonsStore.getHomeworkTaskPack(
    props.lessonId,
    check.homeworkId,
    check.packId
  );

  const taskPackTitle = title?.btnTitle ?? "Домашка";
  const isSendAndManual = (isSendLate || isSendCorrect) && !isAutoCheck;
  const isNotSendOrChecked = isNotSend || isChecked;

  return (
    <FlexboxGrid style={linkStyle} as={Link} to={link}>
      <FlexboxGrid.Item colspan={4}>{taskPackTitle}</FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={4}>
        <StatusHomeworkBtn
          lessonId={props.lessonId}
          score={check.score}
          maxScore={check.maxScore}
          isHomeWorkChecked={isChecked}
          isHomeWorkSended={isSend}
        />
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={4}>
        {isSendAndManual && (
          <StatusSendedBtnComponent homeworkStatus={isSendCorrect} />
        )}
      </FlexboxGrid.Item>
      <FlexboxGrid.Item colspan={12} style={buttonCellStyle}>
        {isNotSendOrChecked && (
          <IconButton icon={homeWorkStatusIcon} as={Link} to={link}>
            {homeWorkStatusTitleButton}
          </IconButton>
        )}
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export const HomeworkItem = observer(HomeworkItemComponent);
