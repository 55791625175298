import CollaspedOutlineIcon from "@rsuite/icons/CollaspedOutline";
import ExpandOutlineIcon from "@rsuite/icons/ExpandOutline";
import { useMemo } from "react";
import { Cell } from "rsuite-table";
import { IconButton } from "shared/ui";
import { TOrder } from "stores";

interface IStudentCellProps {
  rowData?: TOrder;
  dataKey: string;
  onChange: (value: TOrder) => void;
  expandedRowKeys: string[];
}

export const InfoCell: React.FC<IStudentCellProps> = (props) => {
  const { onChange, expandedRowKeys, ...rest } = props;
  const order = props.rowData;

  const icon = useMemo(
    () =>
      expandedRowKeys.some((key) => key === order?.paymentId) ? (
        <CollaspedOutlineIcon />
      ) : (
        <ExpandOutlineIcon />
      ),
    [expandedRowKeys, order?.paymentId]
  );

  if (!order) {
    return <></>;
  }

  return (
    <Cell {...rest} style={{ padding: 5 }}>
      <IconButton
        appearance="subtle"
        onClick={() => onChange(order)}
        icon={icon}
      />
    </Cell>
  );
};
