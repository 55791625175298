import { Cell } from "rsuite-table";
import { IPromocode, SaleType } from "stores";
import { observer } from "mobx-react";
import { Whisper } from "rsuite";
import { EnumConverter } from "root/EnumConverter";
import { BiRuble } from "react-icons/bi";
import { FiPercent } from "react-icons/fi";
import { IconButton, Tooltip } from "shared/ui";

interface ISaleTypeCellProps {
  rowData?: IPromocode;
  dataKey: string;
}

const SaleTypeCellComponent: React.FC<ISaleTypeCellProps> = (props) => {
  if (!props.rowData) {
    return <Cell {...props}></Cell>;
  }
  const isPercent = props.rowData.saleType === SaleType.Percent;
  return (
    <>
      <Cell
        {...props}
        className="link-group"
        style={{
          display: "flex",

          alignContent: "center",
          flexWrap: "wrap",
        }}
      >
        <Whisper
          placement="top"
          controlId="control-id-hover"
          trigger="hover"
          speaker={
            <Tooltip>
              {EnumConverter.saleTypeToStr(props.rowData.saleType)}
            </Tooltip>
          }
        >
          <IconButton
            active
            icon={isPercent ? <FiPercent /> : <BiRuble />}
            size="sm"
          />
        </Whisper>
      </Cell>
    </>
  );
};
export const SaleTypeCell = observer(SaleTypeCellComponent);
