import { useIsMounted, useStore } from "hooks";
import { useEffect, useState } from "react";
import { DateHelper } from "root/DateHelper";
import { TextHelper } from "root/TextHelpers";
import { ILesson } from "stores";

export const useLessonHomeworkTitle = (lessonId: number, studentId: number) => {
  const isMounted = useIsMounted();
  const { lessonsStore, studentsStore } = useStore();
  const [lesson, setLesson] = useState<ILesson>();

  useEffect(() => {
    if (!lessonId) {
      return;
    }
    lessonsStore.findOrLoad(lessonId).then((lesson) => {
      if (!isMounted()) {
        return;
      }
      setLesson(lesson);
    });
  }, [lessonId, lessonsStore.onLoading]);

  const studentName = studentId
    ? TextHelper.GetFullName(studentsStore.find(studentId)?.user)
    : "Кажется удален";

  const lessonTitle = lesson?.title ?? "Нет названия";

  const submitDeadline = lesson
    ? DateHelper.changeDateToStrLocaleLongWithTime(lesson.submitDeadline)
    : "Нет даты";

  return { lessonTitle, studentName, submitDeadline };
};
