import { makeAutoObservable, runInAction } from "mobx";
import Api from "../root/Api";
import { IAchievementItem } from "./models";

export class AchievementsStore {
  achievements: IAchievementItem[];
  onLoading = false;

  constructor() {
    makeAutoObservable(this);
    this.achievements = [];
  }

  public async load(studentId: number) {
    this.setOnLoading(true);
    try {
      const result = await Api.GetAchievementsForStudent(studentId);
      const data = result as IAchievementItem[];
      runInAction(() => {
        this.achievements = data;
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
    return null;
  }

  private setOnLoading(value: boolean) {
    this.onLoading = value;
  }
  public clear() {
    runInAction(() => {
      this.achievements = [];
    });
  }
}
