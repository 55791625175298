import React from "react";
import { ButtonGroup, ButtonToolbar, Modal } from "rsuite";
import { BiLeftArrow, BiRightArrow } from "react-icons/bi";
import { FileViewer } from "./FileViewer";
import { useViewFileModal } from "./useViewFileModal";
import { IconButton } from "shared/ui";

type IViewFileModalProps = {
  imgSrc: string;
  isOpen: boolean;
  onCancel: () => void;
  imgSrcList?: string[];
};

export const ViewFileModal: React.FC<IViewFileModalProps> = ({
  imgSrc,
  isOpen,
  onCancel,
  imgSrcList,
}) => {
  const {
    buttonToolBarStyle,
    hasButtonSwtich,
    isLeftButtonDisabled,
    isRightButtonDisabled,
    currentImgSrc,
    switchRight,
    switchLeft,
  } = useViewFileModal(imgSrc, imgSrcList);
  return (
    <Modal open={isOpen} onClose={onCancel}>
      <Modal.Header />
      <Modal.Body>
        <FileViewer imgSrc={currentImgSrc} />
      </Modal.Body>
      {hasButtonSwtich && (
        <ButtonToolbar style={buttonToolBarStyle}>
          <ButtonGroup>
            <IconButton
              icon={<BiLeftArrow />}
              disabled={isLeftButtonDisabled}
              onClick={switchLeft}
            />
            <IconButton
              icon={<BiRightArrow />}
              disabled={isRightButtonDisabled}
              onClick={switchRight}
            />
          </ButtonGroup>
        </ButtonToolbar>
      )}
    </Modal>
  );
};
