import { makeAutoObservable, runInAction } from "mobx";
import { NotificationHelper } from "../root";
import Api from "../root/Api";
import { IRequestGetTasksPacksByFilter } from "../root/requests";
import { IPage, IPageableStore, ITask, ITaskPack } from "./models";
import { queryClient } from "provider/QueryProvider/QueryProvider";

export class TaskPackStore
  implements IPageableStore<IRequestGetTasksPacksByFilter, ITaskPack>
{
  packs: ITaskPack[];
  onLoading = false;
  onUpdating = false;

  filter: IRequestGetTasksPacksByFilter | undefined;
  dataPage: IPage<ITaskPack> | undefined;

  constructor() {
    makeAutoObservable(this);
    this.packs = [];
  }
  public find(id?: number) {
    return this.packs.find((x) => x.id === id);
  }

  public async load(refetch = false) {
    this.setOnLoading(true);
    const queryKey = ["load", "taskPack.GetAllPacks"];
    try {
      refetch && queryClient.removeQueries(queryKey);
      const result = await queryClient.fetchQuery({
        queryKey,
        queryFn: async () => await Api.taskPack.GetAllPacks(refetch),
      });
      runInAction(() => result && (this.packs = result));
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
    return null;
  }

  public async removeTask(taskId: number, packId: number) {
    this.setOnUpdating(true);
    try {
      await Api.taskPack.RemoveTaskFromPack(taskId, packId);
      NotificationHelper.ShowSuccess("Задание удалено из варианта");
      this.filter && this.loadByFilter(this.filter, true);
      return true;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnUpdating(false);
    }
    return false;
  }
  public async addTask(task: ITask, packId: number) {
    try {
      await Api.taskPack.AddTaskFromPack(task.id, packId);
      NotificationHelper.ShowSuccess("Задание добавлено!");
      this.filter && this.loadByFilter(this.filter, true);
    } catch (error) {
      console.error(error);
    }
  }
  public async createNewPack(obj: any) {
    if (this.onUpdating) {
      return;
    }
    this.setOnUpdating(true);
    try {
      await Api.taskPack.CreatePack(obj);
      this.load(true);
      NotificationHelper.ShowSuccess("Вариант сформирован!");
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.setOnUpdating(false);
    }
  }
  public async loadByFilter(
    filter: IRequestGetTasksPacksByFilter,
    refetch = false
  ): Promise<IPage<ITaskPack> | undefined> {
    const queryKey = ["taskPack.GetTasksPacksByFilter", filter];
    try {
      this.setOnLoading(true);
      refetch && queryClient.removeQueries(queryKey);

      const result = await queryClient.fetchQuery({
        queryKey,
        queryFn: async () => await Api.taskPack.GetTasksPacksByFilter(filter),
      });
      runInAction(() => {
        this.filter = filter;
        this.dataPage = result;
        result && (this.packs = result?.data);
      });
      return result;
    } catch (error) {
      console.error(error);
      return undefined;
    } finally {
      this.setOnLoading(false);
    }
  }

  public async findOrLoad(packId: number) {
    let result = this.find(packId);
    if (result) {
      return result;
    }
    result = await this.getTasksPack(packId);
    return result;
  }

  private async getTasksPack(id: number): Promise<ITaskPack | undefined> {
    this.setOnLoading(true);
    try {
      const result = await queryClient.fetchQuery({
        queryKey: ["TasksPack", id],
        queryFn: async () => await Api.taskPack.GetTasksPackById(id),
      });
      runInAction(() => result && this.packs.push(result));
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
    return undefined;
  }
  private setOnLoading(value: boolean) {
    this.onLoading = value;
  }

  private setOnUpdating(value: boolean) {
    this.onUpdating = value;
  }
  public clear() {
    runInAction(() => {
      this.packs = [];
      this.filter = undefined;
      this.dataPage = undefined;
    });
  }
}
