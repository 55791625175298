import MinusIcon from "@rsuite/icons/Minus";
import PlusIcon from "@rsuite/icons/Plus";
import ReloadIcon from "@rsuite/icons/Reload";
import React from "react";
import { BsArrow90DegLeft, BsArrow90DegRight } from "react-icons/bs";
import ScrollContainer from "react-indiana-drag-scroll";
import { ButtonGroup, ButtonToolbar } from "rsuite";
import { useFileViewer } from "./useFileViewer";
import { IconButton } from "shared/ui";

type TFileViewerProps = {
  imgSrc: string;
};

export const FileViewer: React.FC<TFileViewerProps> = ({ imgSrc }) => {
  const {
    buttonToolBarStyle,
    imgStyle,
    scrollContainerStyle,
    boxStyle,
    scrollContainerWrapperStyle,
    handleImgIncrease,
    handleImgDecrease,
    handleReloadImg,
    handleWheelChangeHeight,
    handleButtonLeftTurn,
    handleButtonRightTurn,
  } = useFileViewer(imgSrc);

  return (
    <div style={boxStyle}>
      <div style={scrollContainerWrapperStyle}>
        <ScrollContainer style={scrollContainerStyle}>
          <img
            style={imgStyle}
            src={imgSrc}
            alt="Картинка-ответ ученика"
            onWheel={handleWheelChangeHeight}
          />
        </ScrollContainer>
      </div>
      <ButtonToolbar style={buttonToolBarStyle}>
        <ButtonGroup vertical>
          <IconButton icon={<PlusIcon />} onClick={handleImgIncrease} />
          <IconButton icon={<MinusIcon />} onClick={handleImgDecrease} />
          <IconButton icon={<ReloadIcon />} onClick={handleReloadImg} />
          <IconButton
            icon={<BsArrow90DegRight />}
            onClick={handleButtonRightTurn}
          />
          <IconButton
            icon={<BsArrow90DegLeft />}
            onClick={handleButtonLeftTurn}
          />
        </ButtonGroup>
      </ButtonToolbar>
    </div>
  );
};
