import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import Api from "root/Api";
import { Table, SelectPicker } from "rsuite";
import { IUserFullData, RoleNames } from "stores";
const { Column, HeaderCell, Cell } = Table;

import { EnumConverter } from "root";
import { CheckReadonlyCell } from "components";
import { RoleCell } from "./RoleCell";
import { ActionCell } from "./ActionCell";
import { useIsMounted } from "hooks";
import { Panel } from "shared/ui";

const UsersTableComponent: React.FC = () => {
  const isMounted = useIsMounted();
  const [users, setUsers] = useState<IUserFullData[]>();
  const [filteredUsers, setFilteredUsers] = useState<IUserFullData[]>();
  const [selectedRole, setSelectedRole] = useState<RoleNames | null>(null);
  const [onLoading, setOnLoading] = useState<boolean>();

  const rolesList = EnumConverter.mapEnum(
    RoleNames,
    EnumConverter.RoleNamesToStr
  );

  useEffect(() => {
    loadUsers();
  }, []);

  useEffect(() => {
    const filtered = users?.filter(
      (x) => !selectedRole || x.role === selectedRole
    );
    setFilteredUsers(filtered);
  }, [selectedRole, users]);

  const loadUsers = () => {
    setOnLoading(true);
    Api.users.GetAll().then((result) => {
      if (!isMounted()) {
        return;
      }
      setUsers(result);
      setOnLoading(false);
    });
  };

  return (
    <>
      <Panel bordered bodyFill>
        <SelectPicker
          data={rolesList}
          value={selectedRole}
          onChange={(x) => {
            setSelectedRole(x);
          }}
          style={{ width: 150 }}
        />
        <Table
          rowKey="id"
          loading={onLoading}
          height={600}
          data={filteredUsers}
        >
          <Column flexGrow={1}>
            <HeaderCell>id</HeaderCell>
            <Cell dataKey="id" />
          </Column>
          <Column flexGrow={2} fullText>
            <HeaderCell>Роль</HeaderCell>
            <RoleCell dataKey="role" />
          </Column>
          <Column flexGrow={3} fullText>
            <HeaderCell>Фамилия</HeaderCell>
            <Cell dataKey="lastName" />
          </Column>
          <Column flexGrow={3} fullText>
            <HeaderCell>Имя</HeaderCell>
            <Cell dataKey="firstName" />
          </Column>
          <Column flexGrow={3} fullText>
            <HeaderCell>email</HeaderCell>
            <Cell dataKey="email" />
          </Column>
          <Column flexGrow={1}>
            <HeaderCell>email</HeaderCell>
            <CheckReadonlyCell dataKey="emailConfirmed" />
          </Column>
          <Column flexGrow={3} fullText>
            <HeaderCell>Телефон</HeaderCell>
            <Cell dataKey="phone" />
          </Column>
          <Column flexGrow={4} align="left">
            <HeaderCell>...</HeaderCell>
            <ActionCell dataKey="id" onChange={loadUsers} />
          </Column>
        </Table>
      </Panel>
    </>
  );
};
export const UsersTable = observer(UsersTableComponent);
