import { useStore } from "hooks";
import { useMemo } from "react";
import { StyleHelper } from "root/StyleHelper";
import { IEvent } from "stores";

export const useScheduleDrawer = (event: IEvent) => {
  const { lessonsStore, tariffStore } = useStore();

  const title = useMemo(() => {
    const tariffTitle = tariffStore.find(event.tariffId)?.title;
    return `${event.title} (${tariffTitle})`;
  }, [event.tariffId]);

  const time = useMemo(
    () =>
      new Date(event.eventDateTime).toLocaleString("ru-RU", {
        hour: "2-digit",
        minute: "numeric",
      }),
    [event.eventDateTime]
  );
  const lessonId = useMemo(
    () =>
      lessonsStore.publishedLessons.find(
        (x) => x.tariffId === event.tariffId && x.title === event.title
      )?.id,
    [event.description]
  );

  const backgroundColor = useMemo(
    () => StyleHelper.getColorByEvent(event),
    [event]
  );
  const icon = useMemo(() => StyleHelper.getIconByEvent(event), [event]);

  return { title, time, lessonId, backgroundColor, icon };
};
