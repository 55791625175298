import * as teacher from "../pages/Teacher";
import { IRoute } from "./IRoute";
import { GeneralRoutes } from "./GeneralRoutes";

export const TeacherRoutes = () => {
  const routes: IRoute[] = [];
  routes.push({ title: "Главная", path: "*", element: <teacher.Home /> });
  routes.push({ title: "Главная", path: "/", element: <teacher.Home /> });

  routes.push(GeneralRoutes.CheckLessons);
  routes.push(GeneralRoutes.Students);
  routes.push(GeneralRoutes.TaskPackEditor);
  routes.push(GeneralRoutes.HomeworkSummary);
  routes.push(GeneralRoutes.Top);
  routes.push(GeneralRoutes.Lessons);
  routes.push(GeneralRoutes.LessonsCard);
  routes.push(GeneralRoutes.LessonsCardWithHw);
  routes.push(GeneralRoutes.Schedule);
  routes.push(GeneralRoutes.TaskPackEditor);
  routes.push(GeneralRoutes.AllTask);
  routes.push(GeneralRoutes.TaskPack);
  routes.push(GeneralRoutes.CreateTask);
  routes.push(GeneralRoutes.TaskFavorite);
  routes.push(GeneralRoutes.TaskArchive);

  return routes;
};
