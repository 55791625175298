import React from "react";
import { observer } from "mobx-react";

interface IUploaderDraggableProps {
  title?: string;
}

const UploaderDraggableComponent: React.FC<IUploaderDraggableProps> = (
  props: IUploaderDraggableProps
) => {
  const title = props.title
    ? props.title
    : "Нажми или перетащи файлы для загрузки";
  return (
    <div
      style={{
        height: 50,
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
      }}
    >
      <span>{title}</span>
    </div>
  );
};
export const UploaderDraggable = observer(UploaderDraggableComponent);
