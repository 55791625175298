import { useStore } from "hooks";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { Table } from "rsuite";
import { ActionPromotionCell, PromotionDateCell, SaleCostCell } from "./Cell";
import { ButtornCreatePromotion } from "./components/ButtornCreatePromotion";
import { Panel } from "shared/ui";
const { Column, HeaderCell, Cell } = Table;

const PromotionTableComponent: React.FC = () => {
  const { promotionStore } = useStore();

  useEffect(() => {
    promotionStore.load();
  }, []);

  return (
    <>
      <ButtornCreatePromotion />
      <Panel bordered bodyFill>
        <Table
          rowKey="id"
          loading={promotionStore.onLoading}
          height={600}
          data={promotionStore.data}
          style={{ marginTop: 25 }}
        >
          <Column width={50}>
            <HeaderCell>id</HeaderCell>
            <Cell dataKey="id" />
          </Column>
          <Column minWidth={150} flexGrow={2} fullText>
            <HeaderCell>Название</HeaderCell>
            <Cell dataKey="title" />
          </Column>
          <Column width={100}>
            <HeaderCell>скидка</HeaderCell>
            <SaleCostCell dataKey="saleCost" />
          </Column>
          <Column flexGrow={2} fullText>
            <HeaderCell>Дата с</HeaderCell>
            <PromotionDateCell dataKey="begin" />
          </Column>
          <Column flexGrow={2} fullText>
            <HeaderCell>Дата до</HeaderCell>
            <PromotionDateCell dataKey="end" />
          </Column>
          <Column minWidth={250} flexGrow={3} fullText>
            <HeaderCell>Описание</HeaderCell>
            <Cell dataKey="description" />
          </Column>
          <Column width={120} align="center">
            <HeaderCell>Кнопки</HeaderCell>
            <ActionPromotionCell dataKey="id" />
          </Column>
        </Table>
      </Panel>
    </>
  );
};
export const PromotionTable = observer(PromotionTableComponent);
