import React, { useState } from "react";
import { Uploader } from "rsuite";
import "cropperjs/dist/cropper.css";
import Api from "root/Api";
import { useStore } from "hooks";
import { NotificationHelper } from "root/NotificationHelper";
import { FileType } from "rsuite/esm/Uploader";
import { ImageCropper } from "./ImageCropper";
import { observer } from "mobx-react";
import { IImgStudentRequest } from "root/requests";
import { FileUploaderHelper } from "root/FileUploaderHelper";
import { Button } from "shared/ui";

interface IAvatarCropperProps {
  onSuccess: () => void;
}

export const AvatarCropperComponent: React.FC<IAvatarCropperProps> = (
  props
) => {
  const { studentsStore } = useStore();
  const [image, setImage] = useState<string>();
  const [file, setFile] = useState<File>();

  const onChange = (fileList: FileType[]) => {
    const file = fileList[0].blobFile;
    if (!file) {
      return;
    }
    setFile(file);
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result as any);
    };
    reader.readAsDataURL(file);
  };

  const croppedCanvasToFormData = (
    croppedCanvas: HTMLCanvasElement
  ): Promise<FormData> | undefined => {
    if (!croppedCanvas) {
      return;
    }
    return new Promise((resolve) => {
      croppedCanvas.toBlob(
        (blob: Blob | null) => {
          if (!blob) {
            return;
          }
          const formData = new FormData();
          formData.append("file", blob, file?.name);
          resolve(formData);
        },
        file?.type
      );
    });
  };

  const getFileId = async (formData: FormData): Promise<number | undefined> => {
    const response = await Api.UploadFiles(formData);
    if (!response) {
      return;
    }
    return response.id;
  };

  const updateProfile = (imgStudentRequest: IImgStudentRequest) =>
    studentsStore.updateProfile(imgStudentRequest).then(() => {
      NotificationHelper.ShowSuccess("Фотография профиля обновлена");
      props.onSuccess();
    });

  const handleSubmit = async (croppedCanvas: HTMLCanvasElement) => {
    const formData = await croppedCanvasToFormData(croppedCanvas);
    if (!formData) {
      return;
    }
    const fileImageId = await getFileId(formData);
    if (!fileImageId) {
      return;
    }
    updateProfile({ fileImageId });
  };

  return (
    <>
      {image ? (
        <ImageCropper
          imageSrc={image}
          onCrop={handleSubmit}
          onCancel={() => setImage("")}
          disabledButton={studentsStore.onSaving}
          loadingButton={studentsStore.onSaving}
          styleButton={{
            marginTop: 20,
            display: "flex",
            justifyContent: "center",
          }}
          styleCropper={{ height: 250, width: "100%" }}
        />
      ) : (
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <div style={{ textAlign: "center" }}>
            Друзьям будет проще узнать вас, если вы загрузите свою настоящую
            фотографию.
            <br />
            Вы можете загрузить изображение в формате JPG, GIF или PNG.
          </div>
          <div style={{ marginTop: 35 }}>
            <Uploader
              autoUpload={false}
              shouldQueueUpdate={(fileList: FileType[]) =>
                FileUploaderHelper.canUpload(fileList, 1, 1048576)
              }
              accept=".jpg, .jpeg, .png, .gif"
              appearance="primary"
              color="violet"
              action="#"
              onChange={onChange}
            >
              <Button appearance="primary" color="violet" size="sm">
                Выбрать файл
              </Button>
            </Uploader>
          </div>
        </div>
      )}
    </>
  );
};
export const AvatarCropper = observer(AvatarCropperComponent);
