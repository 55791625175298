import { createContext, useContext } from "react";
import { FormHelperContextValue, PersistOptions } from "./types";

export const FormHelperContext = createContext<FormHelperContextValue>({
  isSubmitting: false,
  hasResetForm: false,
});
export const useFormHelperContext = () => useContext(FormHelperContext);

export const defaultPersistOptions: PersistOptions = {
  enable: false,
  storeName: "none",
  storageType: "session",
};
