import { Cell } from "rsuite-table";
import { IDateRange, IPromotion } from "stores";
import { observer } from "mobx-react";
import { DateHelper } from "root/DateHelper";

interface IPromotionDateCellProps {
  rowData?: IPromotion;
  dataKey: keyof IDateRange;
}

const PromotionDateCellComponent: React.FC<IPromotionDateCellProps> = (
  props
) => {
  if (!props.rowData) {
    return <Cell></Cell>;
  }
  const date =
    props.dataKey === "begin"
      ? props.rowData.dateRange.begin
      : props.rowData.dateRange.end;

  return <Cell {...props}>{DateHelper.DateToStringAndTime(date)}</Cell>;
};
export const PromotionDateCell = observer(PromotionDateCellComponent);
