import { ButtonToolbar, Drawer, Table } from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import VisibleIcon from "@rsuite/icons/Visible";
import EditIcon from "@rsuite/icons/Edit";
import { observer } from "mobx-react";
import { ITask } from "stores";
import { useState } from "react";
import { TaskEditForm } from "components/TaskEditForm";
import { useStore } from "hooks";
import { ConfirmModal } from "components";
import { TaskView } from "components/BaseTask/component/TaskView";
import { IconButton } from "shared/ui";

interface IActionTaskCellProps {
  rowData?: ITask;
  dataKey: string;
}

const ActionTaskCellComponent: React.FC<IActionTaskCellProps> = (props) => {
  const { taskStore } = useStore();
  const { Cell } = Table;
  const [taskSelected, setTaskSelected] = useState<ITask | undefined>(
    undefined
  );

  const [isOpenConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [drawerTitle, setDrawerTitle] = useState("");
  const [readOnlyDetail, setReadOnlyDetail] = useState(false);
  const [idTaskFromDelete, setIdTaskFromDelete] = useState<number>(0);
  const handleDeleteTask = async () => {
    if (await taskStore.delete(idTaskFromDelete)) {
      setOpenConfirmModal(false);
    }
  };
  const handleOpenDetail = (title: string, readOnly: boolean) => {
    setOpenDetail(true);
    setDrawerTitle(title);
    setReadOnlyDetail(readOnly);
  };
  const handleOnDetail = (task: ITask, readOnly: boolean) => {
    setTaskSelected(task);
    handleOpenDetail(
      readOnly ? "Просмотр задания" : "Редактировать задание",
      readOnly
    );
  };
  const handleOnCloseFormTaskEditAdd = () => {
    setOpenDetail(false);
  };

  const handleOnDelete = (task: ITask) => {
    setIdTaskFromDelete(task.id);
    setOpenConfirmModal(true);
  };
  if (!props.rowData) {
    return <></>;
  }
  const task = props.rowData;
  return (
    <>
      <Drawer open={openDetail} onClose={() => setOpenDetail(false)} size="md">
        <Drawer.Header>
          <Drawer.Title>{drawerTitle}</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          {readOnlyDetail ? (
            <TaskView task={taskSelected} />
          ) : (
            <TaskEditForm
              task={taskSelected}
              onClose={handleOnCloseFormTaskEditAdd}
            />
          )}
        </Drawer.Body>
      </Drawer>
      <ConfirmModal
        isOpen={isOpenConfirmModal}
        onSubmit={() => {
          handleDeleteTask();
        }}
        onCancel={() => {
          setOpenConfirmModal(false);
        }}
        isLoading={taskStore.onUpdating}
        description="Вы действительно хотите удалить задание? 
        После удаления задание будет недоступно для добавления в новые
        варианты. 
        В готовых вариантах и домашках задание останется."
      />
      <Cell
        {...props}
        className="link-group"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexWrap: "wrap",
        }}
      >
        <ButtonToolbar>
          <IconButton
            onClick={() => {
              handleOnDetail(task, true);
            }}
            icon={<VisibleIcon />}
            size="sm"
            color="blue"
            appearance="primary"
          />

          <IconButton
            onClick={() => {
              handleOnDetail(task, false);
            }}
            icon={<EditIcon />}
            size="sm"
            color="orange"
            appearance="primary"
          />

          <IconButton
            onClick={() => {
              handleOnDelete(task);
            }}
            icon={<TrashIcon />}
            size="sm"
            color="red"
            appearance="primary"
          />
        </ButtonToolbar>
      </Cell>
    </>
  );
};

export const ActionTaskCell = observer(ActionTaskCellComponent);
