import { PlaceholderLessonItem } from "components";
import { ExpandableList } from "components/ExpandableList";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
import { CheckmarkPanel } from "../CheckmarkPanel";
import { LessonItem } from "./LessonItem";
import { LayoutPanelWithButton } from "../../Layout/LayoutPanelWithButton";
import { Button } from "shared/ui";

const LessonsPanelComponent: React.FC = () => {
  const { lessonsStore } = useStore();

  const lessons = lessonsStore.data?.filter(
    (lesson) => !lesson.isViewed && lesson.videoUrl
  );

  return (
    <LayoutPanelWithButton
      title="Непросмотренные вебинары"
      button={
        <Button as={Link} to="/lessons">
          Все уроки
        </Button>
      }
    >
      <ExpandableList
        data={lessons}
        keyExtractor={({ id }) => id}
        renderItem={({ item }) => <LessonItem lesson={item} />}
        listEmptyComponent={<CheckmarkPanel title="Все вебинары просмотрены" />}
        isLoading={lessonsStore.onLoading}
        listLoadingComponent={<PlaceholderLessonItem items={4} />}
      />
    </LayoutPanelWithButton>
  );
};
export const LessonsPanel = observer(LessonsPanelComponent);
