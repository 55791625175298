import { useState, useLayoutEffect } from "react";

type CurrentUrl = string | null;

const useCurrentUrl = (): CurrentUrl => {
  const [currentUrl, setCurrentUrl] = useState<CurrentUrl>(null);

  useLayoutEffect(() => {
    const protocol = window.location.protocol;
    const hostname = window.location.hostname;
    const port = window.location.port ? ":" + window.location.port : "";
    const url = `${protocol}//${hostname}${port}`;
    setCurrentUrl(url);
  }, []);

  return currentUrl;
};

export default useCurrentUrl;
