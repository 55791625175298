import { useStore } from "hooks";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { Table } from "rsuite";
import { IGlobalNotification } from "stores";
import {
  ActionGlobalNotificationCell,
  DateGlobalNotificationCell,
  RoleGlobalNotificationCell,
  TypeGlobalNotificationCell,
  UserGlobalNotificationCell,
} from "./Cell";
import {
  FilterGlobalNotification,
  IGlobalNotificationFilter,
} from "./components";
import { Panel } from "shared/ui";

const { Column, HeaderCell, Cell } = Table;

const TableGlobalNotificationComponent: React.FC = () => {
  const { globalNotificationStore } = useStore();
  const [globalNotification, setGlobalNotification] = useState<
    IGlobalNotification[]
  >([]);
  const [filter, setFilter] = useState<IGlobalNotificationFilter>({});

  useEffect(() => {
    setGlobalNotification(filterGlobalNotification(filter));
  }, [filter, globalNotificationStore.data]);

  const filterGlobalNotification = (
    filter: IGlobalNotificationFilter
  ): IGlobalNotification[] => {
    const filteredGlobalNotification = globalNotificationStore.data.filter(
      (x) =>
        (!filter.type || x.type === filter.type) &&
        (!filter.forRole || x.forRole === filter.forRole) &&
        (!filter.userId || x.userId === filter.userId)
    );
    return filteredGlobalNotification;
  };

  return (
    <>
      <FilterGlobalNotification onChange={setFilter} />
      <Panel bordered bodyFill>
        <Table
          rowKey="id"
          loading={globalNotificationStore.onLoading}
          height={600}
          data={globalNotification}
          style={{ marginTop: 25 }}
        >
          <Column width={50}>
            <HeaderCell>id</HeaderCell>
            <Cell dataKey="id" />
          </Column>
          <Column width={50}>
            <HeaderCell>Тип</HeaderCell>
            <TypeGlobalNotificationCell dataKey="type" />
          </Column>
          <Column flexGrow={1} fullText>
            <HeaderCell>Роль</HeaderCell>
            <RoleGlobalNotificationCell dataKey="forRole" />
          </Column>
          <Column width={150} fullText>
            <HeaderCell>Пользователь</HeaderCell>
            <UserGlobalNotificationCell dataKey="userId" />
          </Column>
          <Column width={120} fullText>
            <HeaderCell>Название</HeaderCell>
            <Cell dataKey="title" />
          </Column>
          <Column width={150} fullText>
            <HeaderCell>Дата с</HeaderCell>
            <DateGlobalNotificationCell dataKey="begin" />
          </Column>
          <Column width={150} fullText>
            <HeaderCell>Дата до</HeaderCell>
            <DateGlobalNotificationCell dataKey="end" />
          </Column>
          <Column minWidth={300} flexGrow={5} fullText>
            <HeaderCell>Описание</HeaderCell>
            <Cell dataKey="description" />
          </Column>
          <Column width={150} align="center">
            <HeaderCell>Кнопки</HeaderCell>
            <ActionGlobalNotificationCell dataKey="id" />
          </Column>
        </Table>
      </Panel>
    </>
  );
};
export const TableGlobalNotification = observer(
  TableGlobalNotificationComponent
);
