import { makeAutoObservable, runInAction } from "mobx";
import Api from "../root/Api";
import { NotificationHelper } from "../root";
import { IMentor } from "./models/IUser";
import { queryClient } from "provider/QueryProvider/QueryProvider";

export class MentorStore {
  mentors: IMentor[] = [];
  onLoading = false;
  onUpdating = false;

  constructor() {
    makeAutoObservable(this);
  }

  public find(id: number) {
    return this.mentors.find((x) => x.id === id);
  }
  public async load() {
    this.setOnLoading(true);
    try {
      const result = await queryClient.fetchQuery({
        queryKey: ["load", "mentors.GetAll"],
        queryFn: async () => await Api.mentors.GetAll(),
      });
      runInAction(() => result && (this.mentors = result));
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
    return null;
  }

  public async linkStudentTariff(
    mentorId: number,
    tariffId: number,
    studentId: number
  ): Promise<boolean> {
    this.setOnUpdating(true);
    try {
      await Api.mentors.LinkStudentTariff(mentorId, tariffId, studentId);
      NotificationHelper.ShowSuccess("Успешно сохранено!");
      return true;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnUpdating(false);
    }
    return false;
  }

  public async deLinkStudentTariff(
    mentorId: number,
    tariffId: number,
    studentId: number
  ) {
    this.setOnUpdating(true);
    try {
      await Api.mentors.DeLinkStudentTariff(mentorId, tariffId, studentId);
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnUpdating(false);
    }
    return null;
  }

  private setOnLoading(value: boolean) {
    this.onLoading = value;
  }
  private setOnUpdating(value: boolean) {
    this.onUpdating = value;
  }
  public clear() {
    runInAction(() => {
      this.mentors = [];
    });
  }
}
