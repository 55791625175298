import { Cell } from "rsuite-table";
import { useStore } from "hooks";
import { IStudentTariffWithPayments } from "stores";
import { observer } from "mobx-react";
import { TextHelper } from "root/TextHelpers";

interface IStudentCellProps {
  rowData?: IStudentTariffWithPayments;
  dataKey: string;
  fullName?: boolean;
}

const StudentCellComponent: React.FC<IStudentCellProps> = (props) => {
  const { studentsStore } = useStore();
  if (!props.rowData) {
    return <></>;
  }
  const studentId = props.rowData.studentId;
  const student = studentsStore.find(studentId);

  const title = props.fullName
    ? TextHelper.GetFullName(student?.user)
    : TextHelper.GetShortName(student?.user, true);

  return (
    <Cell
      {...props}
      className="link-group"
      style={{
        display: "flex",
        alignContent: "center",
        flexWrap: "wrap",
        justifyContent: "center",
      }}
    >
      {title || `ID:${studentId}. Кажется удален`}
    </Cell>
  );
};
export const StudentCell = observer(StudentCellComponent);
