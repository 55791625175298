import { FileUploader, Textarea } from "components";
import { UploaderDraggable } from "components/FormComponents/UploaderDraggable";
import { useStore } from "hooks";
import { useEffect, useState } from "react";
import {
  EnumConverter,
  FileUploaderHelper,
  IRequestTariff,
  ValidationHelper,
} from "root";
import { ButtonToolbar, Form, SelectPicker } from "rsuite";
import { FileType } from "rsuite/esm/Uploader";
import { isBefore } from "rsuite/esm/utils/dateUtils";
import { Button, DatePicker, Input, InputNumber } from "shared/ui";
import { ExamType, ITariff, TariffType } from "stores";
import { ValidationResult } from "stores/models/ValidationResult";

interface ITariffEditAddProps {
  tariffId?: number;
  onClose?: () => void;
}
const defaultTariff: IRequestTariff = {
  id: 0,
  type: TariffType.Base,
  examType: ExamType.Ege,
  subjectId: 1,
  title: "",
  description: "",
  durationInBusinessMonths: 1,
  lessonsPerMonth: 1,
  countLessonsPerMonth: 1,
  countHomeworksPerMonth: 0,
  dateBegin: new Date(Date.now()),
  dateEnd: new Date(Date.now()),
  costPerMonth: 0,
  costFull: 0,
  discountVideoLessonsPercent: 0,
  fileImageId: 0,
};

export const TariffEditAdd: React.FC<ITariffEditAddProps> = (props) => {
  const { tariffStore, subjectStore } = useStore();
  const [formValue, setFormValue] = useState<Partial<IRequestTariff>>({});
  const [validationResult, setValidationResult] = useState<ValidationResult>();
  const tariff: ITariff | undefined = tariffStore.find(props.tariffId);

  useEffect(() => {
    if (!tariff) {
      setFormValue(defaultTariff);
      return;
    }
    setFormValue({
      ...tariff,
      dateBegin: new Date(tariff.dateBegin),
      dateEnd: new Date(tariff.dateEnd),
    });
  }, [tariff]);

  const selectTypeTariff = EnumConverter.mapEnum(
    TariffType,
    EnumConverter.typeTariffToStr
  );
  const selectExamTypeTariff = EnumConverter.mapEnum(
    ExamType,
    EnumConverter.typeExamToStr
  );

  function mapFiles(): FileType[] {
    if (!tariff || !tariff.imageUrl) {
      return [];
    }
    const fileType: FileType = {
      name: `Постер "${tariff.title}"`,
      fileKey: tariff.id,
      status: "finished",
      url: tariff.imageUrl,
    };

    return [fileType];
  }
  const shouldQueueUpdate = (fileList: FileType[]) =>
    FileUploaderHelper.canUpload(fileList, 1, 1048576);
  const onSuccessUpload = (id: number) =>
    setFormValue({ ...formValue, fileImageId: id });
  const onRemoveFile = () => {
    setFormValue({ ...formValue, fileImageId: undefined });
  };

  const handleSubmit = async () => {
    const resultValidation = ValidationHelper.tariffValidate(formValue);
    setValidationResult(resultValidation);
    if (resultValidation.hasNotError() && (await tariffStore.save(formValue))) {
      props.onClose?.call(undefined);
    }
  };

  return (
    <>
      <Form
        fluid
        style={{ margin: 5 }}
        formValue={formValue}
        onChange={(formValue: any) => {
          setFormValue(formValue);
        }}
        onSubmit={handleSubmit}
      >
        <Form.Group controlId="type">
          <Form.ControlLabel>Тип курса</Form.ControlLabel>
          <Form.Control
            data-testid="course-type"
            name="type"
            accepter={SelectPicker}
            data={selectTypeTariff}
            errorMessage={validationResult?.getError("type")}
          />
        </Form.Group>
        <Form.Group controlId="examType">
          <Form.ControlLabel>Тип экзамена</Form.ControlLabel>
          <Form.Control
            style={{ maxWidth: 224 }}
            name="examType"
            accepter={SelectPicker}
            data={selectExamTypeTariff}
            errorMessage={validationResult?.getError("examType")}
          />
        </Form.Group>
        <Form.Group controlId="subjectId">
          <Form.ControlLabel>Предмет</Form.ControlLabel>
          <Form.Control
            style={{ maxWidth: 224 }}
            name="subjectId"
            accepter={SelectPicker}
            disabled={formValue.subjectId ? true : false}
            data={subjectStore.data.map((item) => ({
              label: item.title,
              value: item.id,
            }))}
            errorMessage={validationResult?.getError("subjectId")}
          />
        </Form.Group>
        <Form.Group controlId="title">
          <Form.ControlLabel>Название</Form.ControlLabel>
          <Form.Control
            name="title"
            accepter={Input}
            errorMessage={validationResult?.getError("title")}
          />
        </Form.Group>
        <Form.Group controlId="description">
          <Form.ControlLabel>Описание</Form.ControlLabel>
          <Form.Control
            name="description"
            accepter={Textarea}
            errorMessage={validationResult?.getError("description")}
          />
        </Form.Group>
        <Form.Group controlId="fileImageId">
          <Form.ControlLabel>Постер для курса</Form.ControlLabel>
          <Form.HelpText>
            Рекомендуемый размер постера 476 х 170 px
          </Form.HelpText>
          <Form.Control
            defaultFileList={mapFiles()}
            accept=".jpg, .jpeg, .png"
            name="fileImageId"
            accepter={FileUploader}
            shouldQueueUpdate={shouldQueueUpdate}
            onSuccess={onSuccessUpload}
            onRemove={onRemoveFile}
            draggable
            errorMessage={validationResult?.getError("fileImageId")}
          >
            <div>
              <UploaderDraggable />
            </div>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="durationInBusinessMonths">
          <Form.ControlLabel>
            Продолжительность курса в бизнес месяцах
          </Form.ControlLabel>
          <Form.Control
            style={{ maxWidth: 224 }}
            name="durationInBusinessMonths"
            accepter={InputNumber}
            min={1}
            errorMessage={validationResult?.getError(
              "durationInBusinessMonths"
            )}
          />
        </Form.Group>
        <Form.Group controlId="lessonsPerMonth">
          <Form.ControlLabel>
            Количество основных уроков в месяц
          </Form.ControlLabel>
          <Form.Control
            style={{ maxWidth: 224 }}
            name="lessonsPerMonth"
            accepter={InputNumber}
            min={1}
            errorMessage={validationResult?.getError("lessonsPerMonth")}
          />
        </Form.Group>
        <Form.Group controlId="countLessonsPerMonth">
          <Form.ControlLabel>Общее количество уроков в месяц</Form.ControlLabel>
          <Form.HelpText>Основные и все долнительные</Form.HelpText>
          <Form.Control
            style={{ maxWidth: 224 }}
            name="countLessonsPerMonth"
            accepter={InputNumber}
            min={1}
            errorMessage={validationResult?.getError("countLessonsPerMonth")}
          />
        </Form.Group>
        <Form.Group controlId="countHomeworksPerMonth">
          <Form.ControlLabel>
            Общее количество домашек в месяц
          </Form.ControlLabel>
          <Form.HelpText>Основные и все долнительные</Form.HelpText>
          <Form.Control
            style={{ maxWidth: 224 }}
            name="countHomeworksPerMonth"
            accepter={InputNumber}
            min={0}
            errorMessage={validationResult?.getError("countHomeworksPerMonth")}
          />
        </Form.Group>
        <Form.Group controlId="dateBegin">
          <Form.ControlLabel>Дата начала курса</Form.ControlLabel>
          <Form.Control
            data-testid="dateBegin"
            shouldDisableDate={(date: any) =>
              date && formValue.dateBegin
                ? isBefore(date, formValue.dateBegin)
                : false
            }
            name="dateBegin"
            accepter={DatePicker}
            style={{ maxWidth: 224 }}
            placeholder="Дата"
            format="dd-MM-yyyy"
            errorMessage={validationResult?.getError("dateBegin")}
          />
          <Form.ErrorMessage show={formValue.dateBegin ? false : true}>
            Укажите дату начала курса
          </Form.ErrorMessage>
        </Form.Group>
        <Form.Group controlId="dateEnd">
          <Form.ControlLabel>Дата окончания курса</Form.ControlLabel>
          <Form.Control
            data-testid="dateEnd"
            shouldDisableDate={(date: any) =>
              date && formValue.dateEnd
                ? isBefore(date, formValue.dateEnd)
                : false
            }
            name="dateEnd"
            accepter={DatePicker}
            style={{ maxWidth: 224 }}
            placeholder="Дата"
            format="dd-MM-yyyy"
            errorMessage={validationResult?.getError("dateEnd")}
          />
          <Form.ErrorMessage show={formValue.dateEnd ? false : true}>
            Укажите дату окончания курса
          </Form.ErrorMessage>
        </Form.Group>
        {formValue.type !== TariffType.Free && (
          <Form.Group>
            <Form.Group controlId="costPerMonth">
              <Form.ControlLabel>
                Базовая стоимость курса в месяц
              </Form.ControlLabel>
              <Form.Control
                style={{ maxWidth: 224 }}
                name="costPerMonth"
                postfix="₽"
                accepter={InputNumber}
                min={0}
                errorMessage={validationResult?.getError("costPerMonth")}
              />
            </Form.Group>
            <Form.Group controlId="costFull">
              <Form.ControlLabel>
                Стоимость при покупке курса целиком
              </Form.ControlLabel>
              <Form.Control
                style={{ maxWidth: 224 }}
                name="costFull"
                postfix="₽"
                accepter={InputNumber}
                min={0}
                errorMessage={validationResult?.getError("costFull")}
              />
            </Form.Group>
            <Form.Group controlId="discountVideoLessonsPercent">
              <Form.ControlLabel>
                Скидка на покупку только "видеоуроков"
              </Form.ControlLabel>
              <Form.Control
                style={{ maxWidth: 224 }}
                name="discountVideoLessonsPercent"
                postfix="%"
                accepter={InputNumber}
                max={100}
                min={0}
                errorMessage={validationResult?.getError(
                  "discountVideoLessonsPercent"
                )}
              />
            </Form.Group>
          </Form.Group>
        )}
        <Form.Group>
          <ButtonToolbar>
            <Button data-testId="submit" appearance="primary" type="submit">
              Сохранить
            </Button>
            <Button
              appearance="default"
              onClick={() => {
                props.onClose?.call(undefined);
              }}
            >
              Отмена
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
