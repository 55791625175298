import { ConfirmModal } from "components";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { CheckButton } from "./CheckButton";
import { DelayReasonModal } from "./DelayReasonModal";

type TCheckButtonProps = {
  isFirstPart?: boolean;
  isSendedAfterDeadline?: boolean;
  isChecked?: boolean;
  tasksPackHwStudentId?: number;
  onSubmit: () => void;
  onChangeDelayReason: (value?: string) => void;
};

const CheckButtonControlComponent: React.FC<TCheckButtonProps> = ({
  isChecked,
  isSendedAfterDeadline,
  tasksPackHwStudentId,
  onSubmit,
  onChangeDelayReason,
  isFirstPart,
}) => {
  const [isOpenReCheckModal, setIsOpenReCheckModal] = useState(false);
  const [isOpenPostAnswerModal, setIsOpenPostAnswerModal] = useState(false);
  const [isOpenDelayReasonModal, setIsOpenDelayReasonModal] = useState(false);

  const [reCheckComment, setReCheckComment] = useState<string>();

  const handleCancelReCheck = () => setIsOpenReCheckModal(false);

  const handleOnSubmit = () => {
    if (isChecked) {
      setIsOpenReCheckModal(true);
      return;
    }

    if (isSendedAfterDeadline) {
      setIsOpenDelayReasonModal(true);
      return;
    }

    setIsOpenPostAnswerModal(true);
  };

  const handleSubmitDelayReason = (delayReason?: string) => {
    onChangeDelayReason(delayReason);
    handleCloseDelayReasonModal();
    setIsOpenPostAnswerModal(true);
  };

  const handleCloseDelayReasonModal = () => setIsOpenDelayReasonModal(false);
  const handleCancelPostAnswer = () => setIsOpenPostAnswerModal(false);

  const { homeworkStore } = useStore();
  const navigate = useNavigate();

  const handlePostReCheck = () => {
    if (!tasksPackHwStudentId) {
      return;
    }
    homeworkStore
      .reCheck(tasksPackHwStudentId, reCheckComment || "")
      .then(() => navigate("/checks"));
  };

  return (
    <>
      <DelayReasonModal
        onClose={handleCloseDelayReasonModal}
        onSubmit={handleSubmitDelayReason}
        opened={isOpenDelayReasonModal}
      />

      <ConfirmModal
        isOpen={isOpenReCheckModal}
        onSubmit={handlePostReCheck}
        onCancel={handleCancelReCheck}
        isLoading={homeworkStore.onSaving}
        description="Вы действительно хотите отправить домашку на перепроверку?"
      />
      <ConfirmModal
        isOpen={isOpenPostAnswerModal}
        onSubmit={onSubmit}
        onCancel={handleCancelPostAnswer}
        isLoading={homeworkStore.onSaving}
        description={`Вы действительно хотите сохранить проверку?
        \nИзменить баллы и комментарий возможно в любое время до дедлайна.
        \nПосле дедлайна по проверкам домашка автоматически изменит статус на 'проверена' и изменить ничего будет нельзя.`}
      />
      <CheckButton
        isFirstPart={isFirstPart}
        isChecked={isChecked}
        setReCheckComment={setReCheckComment}
        onSubmit={handleOnSubmit}
      />
    </>
  );
};

export const CheckButtonControl = observer(CheckButtonControlComponent);
