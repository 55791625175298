import { useIsMounted, useStore } from "hooks";
import { useEffect, useState } from "react";
import { ITaskPack } from "stores";

export const useTaskPack = (packId: number) => {
  const isMounted = useIsMounted();

  const { taskPackStore } = useStore();

  const [isLoading, setIsLoading] = useState(false);
  const [taskPack, setTaskPack] = useState<ITaskPack | undefined>(undefined);

  useEffect(() => {
    loadTaskPack(packId);
  }, [packId]);

  const loadTaskPack = (packId: number) => {
    setIsLoading(true);
    taskPackStore
      .findOrLoad(packId)
      .then((res) => isMounted() && res && setTaskPack(res))
      .finally(() => setIsLoading(false));
  };

  return {
    taskPack,
    isLoading,
  };
};
