import { useStore } from "hooks";
import { observer } from "mobx-react";
import React from "react";
import { Table } from "rsuite";
import { StudentCell } from "./StudentCell";
import { ActionCell } from "./ActionCell";
import { Panel } from "shared/ui";

const StudentsComponent: React.FC = () => {
  const { studentsStore } = useStore();
  const { Column, HeaderCell } = Table;

  return (
    <>
      <Panel bordered bodyFill style={{ marginTop: 20 }}>
        <Table
          rowKey="id"
          loading={studentsStore.onLoading}
          data={studentsStore.data}
          height={650}
          style={{ marginTop: 25 }}
        >
          <Column minWidth={150} flexGrow={5} sortable>
            <HeaderCell>Ученик</HeaderCell>
            <StudentCell dataKey="id" />
          </Column>
          <Column width={120} align="center">
            <HeaderCell>Кнопки</HeaderCell>
            <ActionCell dataKey="id" />
          </Column>
        </Table>
      </Panel>
    </>
  );
};

export const Students = observer(StudentsComponent);
