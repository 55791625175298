import React from "react";
import { Placeholder } from "rsuite";
import { Col, Grid, Panel, Row } from "shared/ui";

export const PlaceholderLessonCard: React.FC = () => {
  return (
    <Grid fluid>
      <Row className="show-grid">
        <Col xs={24} sm={24} md={24} lg={24} xl={15} xxl={16}>
          <Panel bordered style={{ marginTop: 10 }}>
            <Placeholder.Paragraph rows={1} active />
          </Panel>
          <Panel bordered style={{ marginTop: 10 }}>
            <Placeholder.Graph active height={320} />
          </Panel>
          <Panel bordered style={{ marginTop: 10 }}>
            <Placeholder.Paragraph rows={3} active />
          </Panel>
        </Col>
        <Col
          xxl={8}
          xl={9}
          lgHidden
          mdHidden
          smHidden
          xsHidden
          style={{ paddingLeft: 12 }}
        >
          <Panel bordered style={{ marginTop: 10 }}>
            <Placeholder.Paragraph rows={18} active />
          </Panel>
        </Col>
      </Row>
    </Grid>
  );
};
