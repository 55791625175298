import { Cell } from "rsuite-table";
import { useStore } from "hooks";
import { IGlobalNotification } from "stores";
import { observer } from "mobx-react";
import { ButtonToolbar, Whisper } from "rsuite";
import { useState } from "react";
import TrashIcon from "@rsuite/icons/Trash";
import { ConfirmModal } from "components";
import EditIcon from "@rsuite/icons/Edit";
import { ModalFormGlobalNotification } from "../components";
import { IconButton, Tooltip } from "shared/ui";

interface IGlobalNotificationCellProps {
  rowData?: IGlobalNotification;
  dataKey: string;
}

const ActionGlobalNotificationCellComponent: React.FC<
  IGlobalNotificationCellProps
> = (props) => {
  const { globalNotificationStore } = useStore();
  const [isOpenModal, setIsOpenModal] = useState(false);
  const [openEditModal, setOpenEditModal] = useState<boolean>(false);

  const handleDeleteNotification = async () => {
    if (!props.rowData) {
      return;
    }
    (await globalNotificationStore.delete(props.rowData.id)) &&
      setIsOpenModal(false);
  };

  if (!props.rowData) {
    return <></>;
  }
  return (
    <>
      <ConfirmModal
        isOpen={isOpenModal}
        onSubmit={handleDeleteNotification}
        onCancel={() => setIsOpenModal(false)}
        isLoading={globalNotificationStore.onDeleting}
        description="Вы действительно хотите удалить уведомление?"
      />
      <ModalFormGlobalNotification
        globalNotificationId={props.rowData.id}
        isOpen={openEditModal}
        onClose={() => setOpenEditModal(false)}
      />
      <Cell
        {...props}
        className="link-group"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexWrap: "wrap",
        }}
      >
        <ButtonToolbar>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Редактировать</Tooltip>}
          >
            <IconButton
              onClick={() => {
                setOpenEditModal(true);
              }}
              icon={<EditIcon />}
              size="sm"
              color="orange"
              appearance="primary"
            />
          </Whisper>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Архивировать</Tooltip>}
          >
            <IconButton
              onClick={() => {
                setIsOpenModal(true);
              }}
              icon={<TrashIcon />}
              size="sm"
              color="red"
              appearance="primary"
            />
          </Whisper>
        </ButtonToolbar>
      </Cell>
    </>
  );
};
export const ActionGlobalNotificationCell = observer(
  ActionGlobalNotificationCellComponent
);
