import { InputGroup } from "rsuite";
import { InputRightSectionProps } from "./types";

export const InputRightSection = ({
  rightSection,
  rightSectionPointerEvents,
}: InputRightSectionProps) => {
  return (
    <>
      {rightSection && (
        <InputGroup.Addon style={{ pointerEvents: rightSectionPointerEvents }}>
          {rightSection}
        </InputGroup.Addon>
      )}
    </>
  );
};
