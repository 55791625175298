import React from "react";
import { Modal } from "rsuite";
import { RegTokenAdd } from "../RegTokenAdd";
import { RegTokenType } from "stores";

interface IEditRegTokenModalProps {
  regTokenId?: number;
  tariffId?: number;
  token?: string;
  type?: RegTokenType;
  isOpen: boolean;
  onClose: () => void;
}

export const EditRegTokenModal: React.FC<IEditRegTokenModalProps> = (props) => {
  return (
    <Modal
      backdrop="static"
      open={props.isOpen}
      onClose={() => props.onClose()}
      size="sm"
    >
      <Modal.Header>
        <Modal.Title>
          {props.regTokenId && props.regTokenId > 0
            ? "Редактирование ссылки для регистрации"
            : "Создание ссылки для регистрации"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <RegTokenAdd
          regTokenId={props.regTokenId}
          tariffId={props.tariffId}
          token={props.token}
          type={props.type}
          onClose={props.onClose}
        />
      </Modal.Body>
    </Modal>
  );
};
