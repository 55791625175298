import { useStore } from "hooks";
import { observer } from "mobx-react";
import { useEffect } from "react";
import { Table } from "rsuite";
import {
  ActionRegTokenCell,
  TariffTitleCell,
  TokenCell,
  TokenTypeCell,
} from "./Cell";
import { ButtonCreateRegToken } from "./components/ButtonCreateRegToken";
import { Panel } from "shared/ui";
const { Column, HeaderCell, Cell } = Table;

const RegTokenTableComponent: React.FC = () => {
  const { regTokenStore } = useStore();

  useEffect(() => {
    regTokenStore.load();
  }, []);

  return (
    <>
      <ButtonCreateRegToken />
      <Panel bordered bodyFill>
        <Table
          rowKey="id"
          loading={regTokenStore.onLoading}
          height={600}
          data={regTokenStore.data}
          style={{ marginTop: 25 }}
        >
          <Column width={50}>
            <HeaderCell>id</HeaderCell>
            <Cell dataKey="id" />
          </Column>
          <Column minWidth={150} flexGrow={2} fullText>
            <HeaderCell>Токен</HeaderCell>
            <TokenCell dataKey="token" />
          </Column>
          <Column flexGrow={2}>
            <HeaderCell>Курс</HeaderCell>
            <TariffTitleCell dataKey="objectId" />
          </Column>
          <Column flexGrow={2} fullText>
            <HeaderCell>Тип</HeaderCell>
            <TokenTypeCell dataKey="type" />
          </Column>
          <Column width={120} align="center">
            <HeaderCell>Кнопки</HeaderCell>
            <ActionRegTokenCell dataKey="id" />
          </Column>
        </Table>
      </Panel>
    </>
  );
};
export const RegTokenTable = observer(RegTokenTableComponent);
