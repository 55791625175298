import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { useIsMounted, useStore } from "hooks";
import CloseIcon from "@rsuite/icons/Close";
import {
  ITariff,
  ITariffsStudentPaymentStatus,
  ICartItem,
  IPayment,
} from "stores";
import {
  IMonthStatusByTariff,
  ITariffMonthStatus,
} from "components/Modal/ITariffMonthStatus";
import Api from "root/Api";
import { StackButtonTariffMonth } from ".";
import {
  ImageTariff,
  TitleAndDescriptionTariff,
  FactsTariff,
  CostPerMonth,
} from "../../components";
import _ from "lodash";
import { Col, Grid, Divider, IconButton, Panel, Row } from "shared/ui";

interface IOrderItemProps {
  payOrder?: IPayment;
  сartItem: ICartItem;
  isFastPay: boolean;
  onLoading: boolean;
  onChange: (tariffMonth: ITariffMonthStatus) => void;
  onRemove?: (tariffId: number) => void;
}

const OrderItemComponent: React.FC<IOrderItemProps> = (
  props: IOrderItemProps
) => {
  const { сartItem } = props;
  const isMounted = useIsMounted();
  const { tariffStore, paymentStore, authStore } = useStore();
  const [tariffsStudentPaymentStatus, setTariffsStudentPaymentStatus] =
    useState<ITariffsStudentPaymentStatus>();
  const [costPerMonth, setCostPerMonth] = useState(0);
  const [oldCostPerMonth, setOldCostPerMonth] = useState<number>();

  const tariff: ITariff | undefined = tariffStore.find(сartItem.tariffId);

  useEffect(() => {
    if (!authStore.me) {
      return;
    }
    const studentTariffsPaymentStatus = async (tariffId: number) => {
      const result = await Api.tariffs.GetStudentTariffsPaymentStatus(tariffId);
      if (!isMounted() || !result) {
        return;
      }
      setTariffsStudentPaymentStatus(result);
    };
    if (!tariff) {
      return;
    }
    studentTariffsPaymentStatus(tariff.id);
  }, [tariff]);

  useEffect(() => {
    if (!tariff) {
      return;
    }
    const payOrderByTariff = paymentStore.payOrder?.tariffs.find(
      (x) => x.tariffId === сartItem.tariffId
    );

    const isBaseGreaterFactAmount =
      payOrderByTariff &&
      payOrderByTariff.baseAmount > payOrderByTariff.factAmount;
    const monthsCount = payOrderByTariff?.monthNumbers?.length || 0;

    const costPerMonth = isBaseGreaterFactAmount
      ? _.ceil(payOrderByTariff.factAmount / monthsCount)
      : tariff.costPerMonth;

    const oldCostPerMonth = isBaseGreaterFactAmount
      ? _.ceil(payOrderByTariff.baseAmount / monthsCount)
      : undefined;

    setCostPerMonth(costPerMonth);
    setOldCostPerMonth(oldCostPerMonth);
  }, [paymentStore.payOrder, сartItem]);

  const handleOnChange = (tariffMonth: IMonthStatusByTariff[]) => {
    const tariffMonthStatus: ITariffMonthStatus = {
      id: tariff?.id || 0,
      monthStatus: tariffMonth,
    };
    props.onChange(tariffMonthStatus);
  };

  if (!tariff) {
    return (
      <Panel bordered style={{ marginBottom: 10 }}>
        <div style={{ padding: 76, display: "flex", justifyContent: "center" }}>
          <span>Курс не найден</span>
        </div>
      </Panel>
    );
  }
  const calculateDiscount = (): number => {
    const originalPrice = tariff.durationInBusinessMonths * tariff.costPerMonth;
    return (
      Math.floor(((originalPrice - tariff.costFull) / originalPrice) * 100) || 0
    );
  };

  return (
    <>
      <Grid fluid>
        <Row className="show-grid">
          <Col xxl={9} xl={9} lg={9} md={8} sm={10} xs={24}>
            <ImageTariff imageUrl={tariff.imageUrl} />
          </Col>
          <Col xxl={15} xl={15} lg={15} md={16} sm={14} xs={24}>
            {!props.isFastPay && (
              <IconButton
                size="sm"
                appearance="subtle"
                icon={<CloseIcon />}
                style={{
                  position: "absolute",
                  top: 0,
                  right: 0,
                  marginRight: "-15px",
                  marginTop: "-15px",
                }}
                onClick={() => {
                  props.onRemove?.call(undefined, tariff.id);
                }}
              />
            )}

            <TitleAndDescriptionTariff tariff={tariff} />

            <FactsTariff tariff={tariff} />
          </Col>
          <Col xxl={24} xl={24} lg={24} md={24} sm={24} xs={24}>
            <Divider style={{ marginTop: 20, marginBottom: 20 }} />
            {tariff.durationInBusinessMonths > 2 && <h6>выбери месяцы</h6>}
            {props.isFastPay === false && calculateDiscount() > 0 && (
              <span>
                {`при покупке всех месяцев скидка
              ${calculateDiscount()}%`}
              </span>
            )}
            <CostPerMonth cost={costPerMonth} oldCost={oldCostPerMonth} />
            <div style={{ marginTop: 8, marginBottom: 8 }}>
              <StackButtonTariffMonth
                tariff={tariff}
                tariffsStudentPaymentStatus={tariffsStudentPaymentStatus}
                onChange={handleOnChange}
                selectedMonth={сartItem.selectedMonth}
                onLoading={props.onLoading}
              />
            </div>
          </Col>
        </Row>
      </Grid>
    </>
  );
};
export const OrderItem = observer(OrderItemComponent);
