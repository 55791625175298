import { Cell } from "rsuite-table";
import { IStudentTariffWithPayments } from "stores";
import { ButtonToolbar } from "rsuite";
import { ButtonChangeMentor } from "../components/ButtonChangeMentor";
import { ButtonAddPay } from "../components/ButtonAddPay";
import { ButtonDeleteStudentTariff } from "../components/ButtonDeleteStudentTariff";

interface IActionCellProps {
  rowData?: IStudentTariffWithPayments;
  dataKey: string;
}

export const ActionCell: React.FC<IActionCellProps> = (props) => {
  const studentTariff = props.rowData;
  if (!studentTariff) {
    return <Cell {...props}></Cell>;
  }

  return (
    <>
      <Cell
        {...props}
        className="link-group"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexWrap: "wrap",
        }}
      >
        <ButtonToolbar>
          <ButtonAddPay studentTariff={studentTariff} />
          <ButtonChangeMentor studentTariff={studentTariff} />
          <ButtonDeleteStudentTariff studentTariff={studentTariff} />
        </ButtonToolbar>
      </Cell>
    </>
  );
};
