import { observer } from "mobx-react";
import React from "react";
import { Placeholder } from "rsuite";
import { Panel } from "shared/ui";

const SchedulePlaceholderComponent: React.FC = () => {
  return (
    <>
      <Panel bordered style={{ marginBottom: 12 }}>
        <Placeholder.Paragraph active rows={1} />
      </Panel>
      <Placeholder.Graph active height={650} style={{ borderRadius: 12 }} />
    </>
  );
};

export const SchedulePlaceholder = observer(SchedulePlaceholderComponent);
