import {
  ErrorFallback,
  IErrorFallback,
} from "components/ErrorBoundary/components/ErrorFallback";
import React from "react";
import { Panel } from "shared/ui";

export const ErrorFallbackPlayer: React.FC<IErrorFallback> = ({
  error,
  resetErrorBoundary,
}) => {
  return (
    <Panel bordered style={{ marginBottom: 20 }}>
      <ErrorFallback error={error} resetErrorBoundary={resetErrorBoundary}>
        <Panel bodyFill style={{ marginBottom: 20 }}>
          <h3>Не удалось загрузить вебинар</h3>
          <p>Попробуйте еще раз или обратитесь в тех.поддержку</p>
        </Panel>
      </ErrorFallback>
    </Panel>
  );
};
