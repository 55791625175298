import { PropsWithChildren } from "react";
import { InputScore } from "./InputScore";

type TTaskWithInputScoreProps = {
  disabledInput?: boolean;
  score?: number;
  onChange: (score: number | undefined) => void;
  maxScore: number;
  scoreError?: string;
};

export const TaskWithInputScore: React.FC<
  PropsWithChildren<TTaskWithInputScoreProps>
> = ({ children, onChange, disabledInput, maxScore, score, scoreError }) => {
  return (
    <>
      {children}
      <InputScore
        disabledInput={disabledInput}
        score={score}
        onChange={onChange}
        maxScore={maxScore}
        scoreError={scoreError}
      />
    </>
  );
};
