import { LoginPage } from "pages/Login";
import * as student from "../pages/Student";
import { IRoute } from "./IRoute";
import { GlobalConstants } from "root/GlobalConstants";

export const AnonymousRoutes = () => {
  const routes: IRoute[] = [];
  routes.push({ title: "Корзина", path: "/cart", element: <student.Cart /> });
  routes.push({
    title: "Все курсы для подготовки к ЕГЭ и ОГЭ",
    path: "/catalog",
    element: <student.Catalog />,
  });
  routes.push({
    title: "Бесплатные курсы для подготовки к ЕГЭ и ОГЭ",
    path: `/catalog/${GlobalConstants.onlyFreeTariff}`,
    element: <student.Catalog />,
  });
  routes.push({
    hideTitle: true,
    title: "Добро пожаловать",
    path: "/:token",
    element: <LoginPage />,
  });
  routes.push({
    hideTitle: true,
    title: "Добро пожаловать",
    path: "*",
    element: <LoginPage />,
  });

  return routes;
};
