import { useStore } from "hooks";
import { observer } from "mobx-react";
import React from "react";
import {
  FaEdit as FaEditIcon,
  FaTelegramPlane as FaTelegramPlaneIcon,
} from "react-icons/fa";
import { TextHelper } from "root";
import { ButtonToolbar } from "rsuite";
import { Col, Grid, IconButton, Panel, Row } from "shared/ui";

const AccountComponent: React.FC = () => {
  const { authStore } = useStore();

  return (
    <>
      <Panel
        header={TextHelper.GetFullName(authStore.me)}
        className="shadow panel-margin"
      >
        <Grid fluid>
          <Row>
            <Col></Col>
            <Col>
              <ButtonToolbar>
                <IconButton
                  appearance="primary"
                  icon={<FaEditIcon className="rs-icon" />}
                >
                  Редактировать
                </IconButton>
                <IconButton
                  appearance="primary"
                  color="blue"
                  icon={<FaTelegramPlaneIcon className="rs-icon" />}
                >
                  Настроить уведомления
                </IconButton>
              </ButtonToolbar>
            </Col>
          </Row>
        </Grid>
      </Panel>
    </>
  );
};

export const Account = observer(AccountComponent);
