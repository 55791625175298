import { ReCheckPanel } from "./ReCheckPanel";
import { useState } from "react";
import { authStore } from "stores/AuthStore";
import { RoleNames } from "stores";
import { Button } from "shared/ui";

type TCheckButtonProps = {
  setReCheckComment: (value: string) => void;
  onSubmit: () => void;
  isLoading?: boolean;
  isFirstPart?: boolean;
};

export const ReCheckControl: React.FC<TCheckButtonProps> = ({
  setReCheckComment,
  onSubmit,
  isLoading,
  isFirstPart,
}) => {
  const [isNeedReCheck, setIsNeedReCheck] = useState(false);
  if (isNeedReCheck) {
    return (
      <ReCheckPanel
        isLoading={isLoading}
        onClick={onSubmit}
        setReCheckComment={setReCheckComment}
      />
    );
  }
  if (authStore.me?.role === RoleNames.Mentor || isFirstPart) {
    return null;
  }

  return (
    <Button
      block
      appearance="primary"
      color="red"
      onClick={() => setIsNeedReCheck(true)}
    >
      Необходимо перепроверить
    </Button>
  );
};
