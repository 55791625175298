import { RawHTML } from "components";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { DateHelper } from "root";
import { ILesson } from "stores";
import { Player } from "./Player";
import { ErrorBoundary, ErrorFallbackPlayer } from "components/ErrorBoundary";

export interface IWebinarItemProps {
  lesson: ILesson;
}
const WebinarItemComponent: React.FC<IWebinarItemProps> = ({ lesson }) => {
  const [dataTime, setDataTime] = useState<string>();
  useEffect(() => {
    if (lesson) {
      const date = DateHelper.changeDateToStrLocaleLongWithTime(
        lesson.dateTime
      );
      setDataTime(date);
    }
  }, [lesson]);

  return (
    <>
      <ErrorBoundary fallbackComponent={ErrorFallbackPlayer}>
        <Player lesson={lesson} />
      </ErrorBoundary>

      <p style={{ fontSize: 20, fontWeight: 600 }}>{dataTime}</p>
      <RawHTML style={{ wordBreak: "break-word" }} html={lesson?.description} />
    </>
  );
};
export const WebinarItem = observer(WebinarItemComponent);
