import React from "react";
import { Modal } from "rsuite";
import { PromocodeForm } from "../PromocodeForm";

interface IEditPromocodeModalProps {
  promocodeId?: number;
  isOpen: boolean;
  onClose: () => void;
}

export const EditPromocodeModal: React.FC<IEditPromocodeModalProps> = (
  props
) => {
  return (
    <Modal
      backdrop="static"
      open={props.isOpen}
      onClose={() => props.onClose()}
      size="sm"
    >
      <Modal.Header>
        <Modal.Title>
          {props.promocodeId && props.promocodeId > 0
            ? "Редактирование промокода"
            : "Создание промокода"}
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <PromocodeForm
          promocodeId={props.promocodeId}
          onClose={props.onClose}
        />
      </Modal.Body>
    </Modal>
  );
};
