import { Cell } from "rsuite-table";
import { IUserFullData } from "stores";
import { observer } from "mobx-react";
import { EnumConverter } from "root/EnumConverter";

interface IRoleCellProps {
  rowData?: IUserFullData;
  dataKey: string;
}

const RoleCellComponent: React.FC<IRoleCellProps> = (props) => {
  if (!props.rowData) {
    return <></>;
  }
  return (
    <Cell {...props}>{EnumConverter.RoleNamesToStr(props.rowData.role)}</Cell>
  );
};
export const RoleCell = observer(RoleCellComponent);
