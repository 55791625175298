import { observer } from "mobx-react";
import React, { ReactNode } from "react";
import { Loader } from "shared/ui";

export interface IRenderMenuProps {
  menu: ReactNode;
  loading: boolean;
}

const RenderMenuComponent: React.FC<IRenderMenuProps> = (props) => {
  return (
    <>
      {props.menu}
      {props.loading && (
        <Loader
          content="Загрузка..."
          style={{
            display: "flex",
            justifyContent: "center",
            position: "absolute",
            bottom: "0",
            background: "#fff",
            width: "100%",
            padding: "4px 0",
          }}
        />
      )}
    </>
  );
};

export const RenderMenu = observer(RenderMenuComponent);
