import { Cell } from "rsuite-table";
import { useStore } from "hooks";
import { IStudentTariffWithPayments } from "stores";
import { observer } from "mobx-react";
import { TextHelper } from "root/TextHelpers";

interface IMentorCellProps {
  rowData?: IStudentTariffWithPayments;
  dataKey: string;
}

const MentorCellComponent: React.FC<IMentorCellProps> = (props) => {
  const { mentorStore } = useStore();
  if (!props.rowData) {
    return <></>;
  }

  const mentorId = props.rowData.mentorId;
  const mentor = mentorStore.find(mentorId || -1);
  return (
    <Cell
      {...props}
      className="link-group"
      style={{
        display: "flex",
        alignContent: "center",
        flexWrap: "wrap",
      }}
    >
      {mentorId
        ? TextHelper.GetShortName(mentor?.user, true) ||
          `ID: ${mentorId}. Кажется удален`
        : "-"}
    </Cell>
  );
};
export const MentorCell = observer(MentorCellComponent);
