import PlusIcon from "@rsuite/icons/Plus";
import TrashIcon from "@rsuite/icons/Trash";
import VisibleIcon from "@rsuite/icons/Visible";
import { ConfirmModal } from "components/Modal";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { ButtonToolbar, Drawer, List } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { ITask } from "stores";
import { TaskView } from "./TaskView";
import { IconButton, Panel } from "shared/ui";

export enum ListType {
  Add,
  Create,
}

interface ITaskListWithButtonsForEditingTaskPackProps {
  listType: ListType;
  tasks: ITask[] | undefined;
  taskPackId?: number;
}

interface ITaskForCreateTaskPack extends ITask {
  hasTaskPack: boolean;
}

const TaskListWithButtonsForEditingTaskPackComponent: React.FC<
  ITaskListWithButtonsForEditingTaskPackProps
> = (props) => {
  const { taskStore, taskPackStore } = useStore();
  const [tasks, setTasks] = useState<ITaskForCreateTaskPack[]>([]);
  const [openDetail, setOpenDetail] = useState(false);
  const [taskSelected, setTaskSelected] = useState<ITask>();
  const [isOpenConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [idTaskToDelete, setIdTaskToDelete] = useState(0);

  useEffect(() => {
    if (!props.tasks) {
      return;
    }
    const taskPack = taskPackStore.find(props.taskPackId);
    const taskIdByPack = taskPack?.tasks.map((task) => task.id);
    const freeTasks: ITaskForCreateTaskPack[] = props.tasks?.map((task) => {
      const hasTaskPack = taskIdByPack?.includes(task.id) ?? false;
      return {
        ...task,
        hasTaskPack,
      };
    });
    setTasks(freeTasks);
  }, [props.tasks, props.taskPackId]);

  let color: TypeAttributes.Color;
  let icon: React.JSX.Element;
  let sortable: boolean;
  switch (props.listType) {
    case ListType.Create:
      color = "red";
      icon = <TrashIcon />;
      sortable = true;
      break;
    case ListType.Add:
      color = "green";
      icon = <PlusIcon />;
      sortable = false;
      break;
  }

  const getMarkedTaskWitchHasTaskPack = (
    taskId: number,
    remove = false
  ): ITaskForCreateTaskPack[] => {
    return tasks.map((task) => {
      if (task.id === taskId) {
        return { ...task, hasTaskPack: remove ? false : true };
      }
      return task;
    });
  };

  const handleOnClick = (task: ITask) => {
    if (props.listType === ListType.Create) {
      taskStore.removeTasksForCreateTaskPack(task);
      return;
    }
    if (!props.taskPackId) {
      return;
    }
    taskPackStore.addTask(task, props.taskPackId);
    const refreshTasksList = getMarkedTaskWitchHasTaskPack(task.id);
    setTasks(refreshTasksList);
  };

  const handleSortEnd = ({ oldIndex, newIndex }: any) => {
    taskStore.tasksForCreateTaskPack = sortTasks({ oldIndex, newIndex });
  };

  const sortTasks = ({ oldIndex, newIndex }: any): ITask[] => {
    const moveData = taskStore.tasksForCreateTaskPack.splice(oldIndex, 1);
    const newData = [...taskStore.tasksForCreateTaskPack];
    newData.splice(newIndex, 0, moveData[0]);
    return newData;
  };

  const handleRemoveTask = async () => {
    if (!props.taskPackId) {
      return;
    }
    if (await taskPackStore.removeTask(idTaskToDelete, props.taskPackId)) {
      setOpenConfirmModal(false);
      const refreshTasksList = getMarkedTaskWitchHasTaskPack(
        idTaskToDelete,
        true
      );
      setTasks(refreshTasksList);
    }
  };
  return (
    <>
      <ConfirmModal
        isOpen={isOpenConfirmModal}
        onSubmit={() => {
          handleRemoveTask();
        }}
        onCancel={() => {
          setOpenConfirmModal(false);
        }}
        isLoading={taskPackStore.onUpdating}
        description="Вы действительно хотите удалить задание из варианта?"
      />

      <Drawer open={openDetail} onClose={() => setOpenDetail(false)} size="md">
        <Drawer.Header>
          <Drawer.Title>Просмотр задания</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <TaskView task={taskSelected} />
        </Drawer.Body>
      </Drawer>

      <Panel bordered bodyFill style={{ marginTop: 20 }}>
        <List sortable={sortable} onSort={handleSortEnd}>
          {tasks?.map((task, i) => (
            <List.Item
              index={i}
              key={task.id}
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <span style={{ marginLeft: 20 }}>
                Задание № {task.id}, КИМ: {task.kim}
              </span>
              <ButtonToolbar style={{ marginRight: 20 }}>
                <IconButton
                  icon={<VisibleIcon />}
                  appearance="primary"
                  size="sm"
                  onClick={() => {
                    setTaskSelected(task as ITask);
                    setOpenDetail(true);
                  }}
                />
                {task.hasTaskPack ? (
                  <IconButton
                    icon={<TrashIcon />}
                    appearance="primary"
                    color="red"
                    size="sm"
                    onClick={() => {
                      setIdTaskToDelete(task.id);
                      setOpenConfirmModal(true);
                    }}
                  />
                ) : (
                  <IconButton
                    icon={icon}
                    appearance="primary"
                    color={color}
                    size="sm"
                    onClick={() => handleOnClick(task)}
                  />
                )}
              </ButtonToolbar>
            </List.Item>
          ))}
        </List>
      </Panel>
    </>
  );
};
export const TaskListWithButtonsForEditingTaskPack = observer(
  TaskListWithButtonsForEditingTaskPackComponent
);
