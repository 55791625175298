import React, { useState } from "react";
import { Form, ButtonToolbar, InputGroup, MaskedInput } from "rsuite";
import { useStore } from "../../hooks";
import { observer } from "mobx-react";
import { ValidationHelper } from "root";
import { ValidationResult } from "stores/models/ValidationResult";
import EyeIcon from "@rsuite/icons/legacy/Eye";
import EyeSlashIcon from "@rsuite/icons/legacy/EyeSlash";
import _ from "lodash";
import { IUserRegistration } from "stores";
import { Button } from "shared/ui";

interface ISignInProps {
  title: string;
  onClose: () => void;
  children?: React.JSX.Element;
  registrationToken: string | undefined;
}

const SignInComponent: React.FC<ISignInProps> = ({
  title,
  onClose,
  children,
  registrationToken,
}) => {
  const { authStore } = useStore();
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");
  const [visible, setVisible] = useState(false);
  const [phone, setPhone] = useState("");
  const [lastName, setLastName] = useState("");
  const [firstName, setFirstName] = useState("");
  const [middleName, setMiddleName] = useState("");

  const [validationResult, setValidationResult] = useState<ValidationResult>();

  const handleChange = () => {
    setVisible(!visible);
  };

  const createUserRegistrationData = (): IUserRegistration => {
    const userRegistration: IUserRegistration = {
      email: login,
      phone: phone,
      password: password,
      lastName: _.capitalize(lastName.trim()),
      firstName: _.capitalize(firstName.trim()),
      middleName: _.capitalize(middleName.trim()),
    };
    return userRegistration;
  };

  const onSubmitRegistration = async () => {
    const userRegistration = createUserRegistrationData();

    const resultValidation =
      ValidationHelper.registerValidate(userRegistration);
    setValidationResult(resultValidation);
    if (
      resultValidation.hasNotError() &&
      (await authStore.register({
        email: userRegistration.email.trim(),
        phone: userRegistration.phone,
        password: userRegistration.password,
        lastName: userRegistration.lastName,
        firstName: userRegistration.firstName,
        middleName: userRegistration.middleName,
        token: registrationToken,
      }))
    ) {
      onClose();
    }
  };

  return (
    <>
      <h3 style={{ marginBottom: 12 }}>{title}</h3>
      {children}
      <Form style={{ margin: 5 }} fluid onSubmit={onSubmitRegistration}>
        <Form.Group controlId="email">
          <Form.ControlLabel>Email *</Form.ControlLabel>
          <Form.Control
            name="email"
            required
            placeholder="mail@example.com"
            onChange={(value: string) => setLogin(value)}
            errorMessage={validationResult?.getError("email")}
          />
        </Form.Group>
        <Form.Group controlId="phone">
          <Form.ControlLabel>Телефон *</Form.ControlLabel>
          <Form.Control
            accepter={MaskedInput}
            mask={[
              /[1-9]/,
              " ",
              "(",
              /[1-9]/,
              /\d/,
              /\d/,
              ")",
              " ",
              /\d/,
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
              "-",
              /\d/,
              /\d/,
            ]}
            placeholder="8 (999) 999-99-99"
            name="phone"
            required
            onChange={(value: string) => setPhone(value)}
            errorMessage={validationResult?.getError("phone")}
          />
        </Form.Group>
        <Form.Group controlId="password">
          <Form.ControlLabel>Пароль *</Form.ControlLabel>
          <InputGroup>
            <Form.Control
              value={password}
              name="password"
              required
              placeholder="Пароль"
              onChange={(value: string) =>
                setPassword(value.replace(/\s/g, ""))
              }
              errorMessage={validationResult?.getError("password")}
              type={visible ? "text" : "password"}
            />
            <InputGroup.Button style={{ zIndex: 0 }} onClick={handleChange}>
              {visible ? <EyeIcon /> : <EyeSlashIcon />}
            </InputGroup.Button>
          </InputGroup>

          <Form.HelpText>
            Пароль должен содержать сочетание латинских букв (a-z) и цифр (0-9).
          </Form.HelpText>
        </Form.Group>

        <Form.Group controlId="lastName">
          <Form.ControlLabel>Фамилия *</Form.ControlLabel>
          <Form.Control
            name="lastName"
            required
            placeholder="Фамилия"
            onChange={(value: string) => setLastName(value)}
            errorMessage={validationResult?.getError("lastName")}
          />
        </Form.Group>
        <Form.Group controlId="firstName">
          <Form.ControlLabel>Имя *</Form.ControlLabel>
          <Form.Control
            name="firstName"
            required
            placeholder="Имя"
            onChange={(value: string) => setFirstName(value)}
            errorMessage={validationResult?.getError("firstName")}
          />
        </Form.Group>
        <Form.Group controlId="middleName">
          <Form.ControlLabel>Отчество</Form.ControlLabel>
          <Form.Control
            name="middleName"
            placeholder="Отчество"
            onChange={(value: string) => setMiddleName(value)}
            errorMessage={
              middleName ? validationResult?.getError("middleName") : null
            }
          />
        </Form.Group>

        <Form.Group>
          <ButtonToolbar>
            <Button block appearance="primary" type="submit">
              Регистрация
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};

export const SignIn = observer(SignInComponent);
