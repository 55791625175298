import { RawHTML } from "components";
import { observer } from "mobx-react";
import React from "react";
import TextareaAutosize from "react-textarea-autosize";
import { Stack } from "rsuite";
import { IAnswer, ITask, TaskType } from "stores";
import { ButtonHomeworkCheckedStatus } from "./ButtonHomeworkCheckedStatus";
import { Button, Divider, Input, Panel } from "shared/ui";

export interface ITaskCheckedViewProps {
  answer: IAnswer | undefined;
  task?: ITask;
}
const TaskCheckedViewComponent: React.FC<ITaskCheckedViewProps> = (
  props: ITaskCheckedViewProps
) => {
  const { task, answer } = props;

  return (
    <div style={{ whiteSpace: "pre-line" }}>
      <Panel bodyFill>
        <Panel bordered>
          <RawHTML html={task?.question} />
        </Panel>
        <Panel bordered style={{ margin: "10px 0px 25px 0px" }}>
          <Stack spacing={10} style={{ marginBottom: 20 }}>
            <ButtonHomeworkCheckedStatus
              answer={props.answer}
              task={props.task}
            />
            <>
              {answer?.score || 0} из {task?.maxScore} баллов
            </>
          </Stack>
          {/* TODO проверить работоспособность */}
          {task?.type === TaskType.FileQuestion &&
            answer?.answerFileUrlList && (
              <div style={{ display: "flex", justifyContent: "space-around" }}>
                <Button
                  style={{ borderRadius: 30, cursor: "default" }}
                  size="xs"
                  active
                >
                  файлы проверены
                </Button>
              </div>
            )}
          {task?.type === TaskType.FirstPart && (
            <>
              <p
                className="p-answerTitle"
                style={{ marginBottom: 5, color: "#bbbbbb" }}
              >
                твой ответ:
              </p>
              <Input
                disabled={!!answer}
                value={answer?.answerPart1}
                placeholder="Введите ответ"
                style={{ maxWidth: 300 }}
              />
            </>
          )}
          {task?.type === TaskType.SecondPart && (
            <Panel
              header="твой ответ:"
              collapsible={!answer?.isAutoCheck}
              bodyFill
            >
              <Input
                disabled={!!answer}
                value={answer?.answerPart2}
                as={TextareaAutosize}
                placeholder="Введите ответ"
                style={{ resize: "none" }}
              />
            </Panel>
          )}
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          {task?.type === TaskType.FirstPart && (
            <>
              <p
                className="p-answerTitle"
                style={{ marginBottom: 5, color: "#bbbbbb" }}
              >
                правильный ответ:
              </p>

              <Stack spacing={15} style={{ marginBottom: 20 }}>
                <Input
                  value={task.answer}
                  as={TextareaAutosize}
                  readOnly
                  style={{
                    maxWidth: 400,
                    resize: "none",
                    borderColor: "green",
                  }}
                />
              </Stack>
            </>
          )}
          {task?.type === TaskType.SecondPart &&
            !answer?.isAutoCheck &&
            answer?.comment && (
              <>
                <p
                  className="p-answerTitle"
                  style={{ marginBottom: 5, color: "#bbbbbb" }}
                >
                  комментарий наставника:
                </p>
                <div style={{ marginBottom: 20 }}>
                  <RawHTML className="cr-input-view" html={answer?.comment} />
                </div>
              </>
            )}
          {task?.keys !== null && (
            <>
              <p style={{ marginBottom: 5, color: "#bbbbbb" }}>
                пояснения к заданию:
              </p>
              <RawHTML html={task?.keys} />
            </>
          )}
        </Panel>
      </Panel>
    </div>
  );
};
export const TaskCheckedView = observer(TaskCheckedViewComponent);
