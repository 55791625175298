import React, { useRef, useState } from "react";
import { Button } from "shared/ui";

interface Props<T> {
  data: T[] | undefined;
  renderItem: React.ComponentType<{ item: T }>;
  keyExtractor: (item: T, index: number) => number;
  listEmptyComponent?: React.JSX.Element;
  isLoading?: boolean;
  listLoadingComponent?: React.JSX.Element;
}

export const ExpandableList = <T,>({
  data,
  renderItem,
  keyExtractor,
  listEmptyComponent,
  isLoading,
  listLoadingComponent,
}: Props<T>): React.JSX.Element => {
  const [visibleItems, setVisibleItems] = useState(4);
  const ref = useRef<HTMLButtonElement>(null);

  const loadMoreItems = () => {
    setVisibleItems((prevVisibleItems) => prevVisibleItems + 4);
    ref.current?.blur();
  };

  if (isLoading) {
    return listLoadingComponent ?? <></>;
  }

  if (!data || data.length === 0) {
    return listEmptyComponent ?? <></>;
  }

  const renderedItems = data.slice(0, visibleItems).map((item, index) => {
    const RenderComponent = renderItem;
    const key = keyExtractor(item, index);

    return <RenderComponent key={key} item={item} />;
  });

  const shouldRenderLoadMoreButton = data.length > visibleItems;

  return (
    <>
      {renderedItems}
      {shouldRenderLoadMoreButton && (
        <Button ref={ref} block onClick={loadMoreItems}>
          Показать ещё
        </Button>
      )}
    </>
  );
};
