import _ from "lodash";
import { useParams } from "react-router-dom";

type ParamTypes = Record<string, number | undefined>;
type ParamValues = Record<string, string | undefined>;

export const useNumberParams = (): ParamTypes => {
  const params = useParams<ParamValues>();

  return parseParamValues(params);
};

const parseParamValues = (params: ParamValues): ParamTypes =>
  _.mapValues(params, (value) =>
    value !== undefined ? Number(value) : undefined
  );
