import ArrowBackIcon from "@rsuite/icons/ArowBack";
import { observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
import { Stack } from "rsuite";
import { useLessonHomeworkTitle } from "../hooks";
import { IconButton } from "shared/ui";

interface ILessonHomeworkTitleProps {
  lessonId: number;
  studentId: number;
}

const LessonHomeworkTitleComponent: React.FC<ILessonHomeworkTitleProps> = ({
  lessonId,
  studentId,
}) => {
  const { lessonTitle, studentName, submitDeadline } = useLessonHomeworkTitle(
    lessonId,
    studentId
  );
  return (
    <Stack spacing={8} style={{ marginBottom: 15 }}>
      <IconButton icon={<ArrowBackIcon />} size="xs" as={Link} to="/checks/">
        Назад
      </IconButton>
      <span>Урок: {lessonTitle} </span>
      <span>
        Ученик: <b>{studentName} </b>
      </span>
      <span>
        Дд домашки: <b>{submitDeadline}</b>
      </span>
    </Stack>
  );
};

export const LessonHomeworkTitle = observer(LessonHomeworkTitleComponent);
