import ImageIcon from "@rsuite/icons/Image";
import { AtomicBlockUtils, EditorState } from "draft-js";
import _ from "lodash";
import React from "react";
import { Modal, Stack } from "rsuite";
import { Button, Input } from "shared/ui";

interface IProps {
  editorState: EditorState | undefined;
  onEditorStateChange: (value: EditorState) => void;
}

export const ImageInsertButton: React.FC<IProps> = ({
  editorState,
  onEditorStateChange,
}) => {
  const [open, setOpen] = React.useState(false);
  const [url, setUrl] = React.useState<string>();
  const [widthValue, setWidthValue] = React.useState("auto");
  const [heightValue, setHeightValue] = React.useState("auto");
  const handleOpen = () => setOpen(true);
  const handleClose = () => setOpen(false);

  const addImage = () => {
    const parsedHeight = _.toNumber(heightValue);
    const parsedWidth = _.toNumber(widthValue);
    const height = isNaN(parsedHeight) ? heightValue : parsedHeight;
    const width = isNaN(parsedWidth) ? widthValue : parsedWidth;

    if (url && editorState) {
      const contentState = editorState.getCurrentContent();
      const contentStateWithEntity = contentState.createEntity(
        "IMAGE",
        "IMMUTABLE",
        {
          src: url,
          height,
          width,
        }
      );
      const entityKey = contentStateWithEntity.getLastCreatedEntityKey();
      const newEditorState = EditorState.set(editorState, {
        currentContent: contentStateWithEntity,
      });
      onEditorStateChange(
        AtomicBlockUtils.insertAtomicBlock(newEditorState, entityKey, " ")
      );
    }
    handleClose();
  };

  return (
    <>
      <Modal size="xs" open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Укажите свойства изображения</Modal.Title>
        </Modal.Header>
        <Modal.Body style={{ padding: 5 }}>
          <label>Введите URL изображения:</label>
          <Input value={url} onChange={setUrl} />
          <Stack spacing={6} style={{ marginTop: 15 }}>
            <>
              <label>Введите ширину:</label>
              <Input
                placeholder="auto"
                value={widthValue}
                onChange={(value) => setWidthValue(value || "auto")}
              />
            </>
            <>
              <label>Введите высоту:</label>
              <Input
                placeholder="auto"
                value={heightValue}
                onChange={(value) => setHeightValue(value || "auto")}
              />
            </>
          </Stack>
        </Modal.Body>
        <Modal.Footer style={{ marginTop: 20 }}>
          <Button onClick={addImage} appearance="primary">
            Вставить
          </Button>
          <Button onClick={handleClose} appearance="subtle">
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
      <div
        className="rdw-option-wrapper"
        onClick={handleOpen}
        title="Вставить изображение"
      >
        {<ImageIcon />}
      </div>
    </>
  );
};
