export interface ITariff {
  id: number;
  // Название
  title: string;
  /// Предмет
  subjectId: number;
  // Описание
  description?: string;
  // Дата начала
  dateBegin: Date;
  /// Дата окончания
  dateEnd: Date;
  // Кол-во основных уроков в месяц
  lessonsPerMonth: number;
  // Базовая стоимость курса в месяц
  costPerMonth: number;
  // Стоимость при покупке курса целиком
  costFull: number;
  // Скидка на покупку только "видеоуроков"
  discountVideoLessonsPercent: number;
  //Тип курса
  type: TariffType;
  //Тип экзамена
  examType: ExamType;
  // Продолжительность курса в бизнес месяцах
  durationInBusinessMonths: number;
  // Общее количество уроков в месяц
  countLessonsPerMonth: number;
  // Общее количество домашек в месяц
  countHomeworksPerMonth: number;
  // Картинка курса
  imageUrl?: string;
  // Доступен для покупки
  enableForBuy?: boolean;
}

export interface ITariffsStudentPaymentStatus {
  tariffId: number;
  // Базовая цена месяца
  baseCost: number;
  // Цена "только видео уроки"
  videoLessonsCost: number;
  months: ITariffMonth[];
}

export interface ITariffMonth {
  // Номер месяца
  number: number;
  // Оплачен
  isPaid: boolean;
  // Тип оплаты. Если не оплачен - null
  paymentType?: TariffType;
  // К оплате доступны только видеоуроки, если еще не оплачен
  onlyAllowableVideoLessons?: boolean;
}

export enum TariffType {
  // Основной
  Base = 1,
  // Спец-курс
  Additional = 2,
  // Бесплатный
  Free = 3,
}

export enum ExamType {
  Ege = 1,
  Oge = 2,
}
