import { useDebouncedFunction } from "hooks";
import { useCallback, useEffect, useRef, useState } from "react";
import { OverlayTriggerHandle } from "rsuite/esm/Picker";

export const useDebouncedInput = (
  onChange: (value: string | undefined) => void,
  defaultValue: string | undefined,
  delay = 700,
  minLength = 3
) => {
  const inputRef = useRef<HTMLInputElement>(null);
  const whisperRef = useRef<OverlayTriggerHandle>(null);

  const [titlePatternValue, setTitlePatternValue] = useState(defaultValue);

  useEffect(
    () => handleOnChangeTitlePattern(titlePatternValue),
    [titlePatternValue]
  );

  const setTitle = useCallback(
    useDebouncedFunction(
      (titlePattern: string | undefined) => onChange(titlePattern),
      delay
    ),
    []
  );

  const handleOnChangeTitlePattern = useCallback(
    (value: string | undefined) => {
      if (!value || value.length > minLength) {
        setTitle(value);
        whisperRef.current?.close();
        return;
      }
      whisperRef.current?.open();
    },
    []
  );

  const handleChange = useCallback((value: string | null) => {
    setTitlePatternValue(value ?? undefined);
    handleOnChangeTitlePattern(value ?? undefined);
  }, []);

  const handleClick = useCallback(() => {
    setTitlePatternValue("");
    inputRef.current?.focus();
  }, []);

  return {
    inputRef,
    whisperRef,
    titlePatternValue,
    handleChange,
    handleClick,
  };
};
