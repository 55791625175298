import { observer } from "mobx-react";
import React from "react";
import { SelectPicker } from "rsuite";
import { useSelectPickerLessonId } from "./useSelectPickerLessonId";

type TSelectPickerLessonIdProps = {
  onChange: (id: number | null) => void;
  defaultValue?: number | null;
  tariffId?: number;
};

const SelectPickerLessonIdComponent: React.FC<TSelectPickerLessonIdProps> = ({
  onChange,
  defaultValue,
  tariffId,
}) => {
  const { isLoading, lessons, listProps, renderMenu } = useSelectPickerLessonId(
    defaultValue,
    tariffId
  );

  return (
    <SelectPicker
      virtualized
      loading={isLoading}
      value={defaultValue}
      label="Урок"
      data={lessons}
      style={{ width: 224 }}
      onChange={onChange}
      renderMenu={renderMenu}
      listProps={listProps}
    />
  );
};

export const SelectPickerLessonId = observer(SelectPickerLessonIdComponent);
