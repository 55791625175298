import { PageNavigator } from "components/Filter/PageNavigator";
import { usePageFilter, useStore } from "hooks";
import { observer } from "mobx-react";
import { GlobalConstants } from "root/GlobalConstants";
import { IRequestGetLessonsByFilter, LessonsOrder } from "root/requests";
import { ILesson } from "stores";
import { FilterByLessonProperties } from "./FilterByLessonProperties";
import { LessonsList } from "./LessonsList";

const LessonsComponent: React.FC = () => {
  const { lessonsStore } = useStore();

  const initialFilter = {
    orderBy: LessonsOrder.DateTimeDesc,
    count: 10,
    page: 1,
  };

  const { filter, dataPage, setLimit, setActivePage, onChangeFilter } =
    usePageFilter<ILesson, IRequestGetLessonsByFilter>(
      initialFilter,
      lessonsStore,
      GlobalConstants.filterLessons
    );

  return (
    <>
      <div style={{ marginBottom: 15 }}>
        <FilterByLessonProperties
          onChange={onChangeFilter}
          defaultValue={filter}
        />
      </div>
      <div>
        <LessonsList
          lessons={dataPage?.data}
          loading={lessonsStore.onLoading}
        />
        <div style={{ marginTop: 12 }}>
          <PageNavigator
            total={dataPage?.total}
            limit={filter.count || initialFilter.count}
            activePage={filter.page || initialFilter.page}
            onChangeLimit={setLimit}
            onChangeActivePage={setActivePage}
          />
        </div>
      </div>
    </>
  );
};
export const Lessons = observer(LessonsComponent);
