import { observer } from "mobx-react";
import React from "react";
import { FaTelegramPlane } from "react-icons/fa";
import { SiYoutube } from "react-icons/si";
import { SlSocialVkontakte } from "react-icons/sl";
import { Modal } from "rsuite";
import { InfoItem } from "./InfoItem";
import { MentorInfo } from "./MentorInfo";
import { TeacherInfo } from "./TeacherInfo";
import { Col, Grid, Divider, Row } from "shared/ui";

interface IProps {
  isOpen: boolean;
  onClose: () => void;
}

const HelpfulModalComponent: React.FC<IProps> = ({ isOpen, onClose }) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <Modal.Header>
        <Modal.Title>Полезное</Modal.Title>
      </Modal.Header>

      <Modal.Body>
        <Grid fluid style={{ minWidth: 500 }}>
          <Row>
            <Col xs={12}>
              <TeacherInfo />
            </Col>
            <Col xs={12}>
              <MentorInfo />
            </Col>
          </Row>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <Row>
            <Col xs={12}>
              <InfoItem
                title={"YouTube"}
                description={"посмеяться и поучиться"}
                href={"https://www.youtube.com/@wavege"}
                icon={<SiYoutube />}
              />
            </Col>
            <Col xs={12}>
              <InfoItem
                title={"Telegram"}
                description={"окно в жизнь преподавателя"}
                href={"https://t.me/wavegeschool"}
                icon={<FaTelegramPlane />}
              />
            </Col>
          </Row>
          <Divider style={{ marginTop: 20, marginBottom: 20 }} />
          <Row>
            <Col xs={12}>
              <InfoItem
                title={"Группа ВК"}
                description={"повторять теорию в режиме реального времени"}
                href={"https://vk.com/wavege"}
                icon={<SlSocialVkontakte />}
              />
            </Col>
          </Row>
        </Grid>
      </Modal.Body>
    </Modal>
  );
};
export const HelpfulModal = observer(HelpfulModalComponent);
