import { FileUploader } from "components/FormComponents";
import { UploaderDraggable } from "components/FormComponents/UploaderDraggable";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FileUploaderHelper } from "root/FileUploaderHelper";
import { mapEveryField } from "root/RawHelper";
import { Form } from "rsuite";
import { FileType } from "rsuite/esm/Uploader";
import { Input } from "shared/ui";
import { FileLessonType, IFileLesson, ILessonRequest } from "stores";
import { ValidationResult } from "stores/models/ValidationResult";

interface IMaterialsProps {
  lessonRequest: ILessonRequest;
  validationResult?: ValidationResult;
}

const MaterialsComponent: React.FC<IMaterialsProps> = (
  props: IMaterialsProps
) => {
  const [valuePdfLesson, setValuePdfLesson] = useState([]);
  const [valueWallpaperLesson, setValueWallpaperLesson] = useState([]);
  const [valueWallpaperWeb, setValueWallpaperWeb] = useState([]);

  const maxQtyLoadPdfLesson = 10;
  const maxQtyLoadWallpaperLesson = 1;
  const maxQtyLoadWallpaperWeb = 1;
  const [fileLessons, setFileLessons] = useState<IFileLesson[]>(
    props.lessonRequest.files || []
  );

  useEffect(() => {
    props.lessonRequest.files = fileLessons;
  }, [fileLessons]);

  function mapFiles(type: FileLessonType): FileType[] {
    return fileLessons
      .filter((x) => x.type == type)
      .map((x) => {
        return {
          name: x.fileName,
          fileKey: x.fileId,
          status: "finished",
          url: x.url,
        };
      });
  }

  const handleUploadFile = (
    id: number,
    type: FileLessonType,
    fileName: string
  ) => {
    const fileDto: IFileLesson = {
      fileId: id,
      fileName: fileName,
      type: type,
    };
    fileLessons.push(fileDto);
  };

  const handleRemoveFile = (type: FileLessonType, fileName: string) => {
    if (!props.lessonRequest.files) {
      return;
    }
    const filteredFiles = fileLessons.filter(
      (x) => x.fileName != fileName && x.type != type
    );
    setFileLessons(filteredFiles);
  };
  return (
    <>
      <Form
        fluid
        formValue={props.lessonRequest}
        onChange={(formValue: any) => {
          mapEveryField(props.lessonRequest, formValue, true);
        }}
      >
        <Form.Group controlId="pdfLesson">
          <Form.ControlLabel>Скрипты и прочее</Form.ControlLabel>
          <Form.HelpText>
            Для одного вебинара можно прикрепить до 10 доп. файлов
          </Form.HelpText>
          <Form.Control
            data-testid="scripts"
            value={valuePdfLesson}
            onChange={setValuePdfLesson}
            disabled={valuePdfLesson.length >= maxQtyLoadPdfLesson}
            name="fakeNameForForm1"
            accepter={FileUploader}
            defaultFileList={mapFiles(FileLessonType.Material)}
            shouldQueueUpdate={(fileList: FileType[]) =>
              FileUploaderHelper.canUpload(fileList, maxQtyLoadPdfLesson)
            }
            onSuccess={(id: number, fileName: string) => {
              handleUploadFile(id, FileLessonType.Material, fileName);
            }}
            onRemove={(fileName: string) => {
              handleRemoveFile(FileLessonType.Material, fileName);
            }}
            draggable
          >
            <div>
              <UploaderDraggable />
            </div>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="wallpaperLesson">
          <Form.ControlLabel>Обложка урока</Form.ControlLabel>
          <Form.HelpText>
            Рекомендуемый размер обложки: 220 х 220 px
          </Form.HelpText>
          <Form.Control
            data-testid="wallpaperLesson"
            value={valueWallpaperLesson}
            onChange={setValueWallpaperLesson}
            disabled={valueWallpaperLesson.length >= maxQtyLoadWallpaperLesson}
            accept=".jpg, .jpeg, .png"
            name="wallpaperLesson"
            accepter={FileUploader}
            errorMessage={props.validationResult?.getError("wallpaperLesson")}
            defaultFileList={mapFiles(FileLessonType.MainImage)}
            shouldQueueUpdate={(fileList: FileType[]) =>
              FileUploaderHelper.canUpload(fileList, maxQtyLoadWallpaperLesson)
            }
            onSuccess={(id: number, fileName: string) => {
              handleUploadFile(id, FileLessonType.MainImage, fileName);
            }}
            onRemove={(fileName: string) => {
              handleRemoveFile(FileLessonType.MainImage, fileName);
            }}
            draggable
          >
            <div>
              <UploaderDraggable />
            </div>
          </Form.Control>
        </Form.Group>

        <Form.Group controlId="wallpaperWeb">
          <Form.ControlLabel>Постер для вебинара</Form.ControlLabel>
          <Form.HelpText>
            Рекомендуемый размер постера: 640 х 360 px
          </Form.HelpText>
          <Form.Control
            data-testid="wallpaperWeb"
            value={valueWallpaperWeb}
            onChange={setValueWallpaperWeb}
            disabled={valueWallpaperWeb.length >= maxQtyLoadWallpaperWeb}
            accept=".jpg, .jpeg, .png"
            name="wallpaperWeb"
            accepter={FileUploader}
            errorMessage={props.validationResult?.getError("wallpaperWeb")}
            defaultFileList={mapFiles(FileLessonType.WebinarImage)}
            shouldQueueUpdate={(fileList: FileType[]) =>
              FileUploaderHelper.canUpload(fileList, maxQtyLoadWallpaperWeb)
            }
            onSuccess={(id: number, fileName: string) => {
              handleUploadFile(id, FileLessonType.WebinarImage, fileName);
            }}
            onRemove={(fileName: string) => {
              handleRemoveFile(FileLessonType.WebinarImage, fileName);
            }}
            draggable
          >
            <div>
              <UploaderDraggable />
            </div>
          </Form.Control>
        </Form.Group>
        <Form.Group controlId="videoUrl">
          <Form.ControlLabel>Ссылка на вебинар</Form.ControlLabel>
          <Form.Control
            data-testid="videoUrl"
            name="videoUrl"
            accepter={Input} />
        </Form.Group>
      </Form>
    </>
  );
};
export const Materials = observer(MaterialsComponent);
