import { Col, FlexboxGrid } from "rsuite";
import { Panel } from "shared/ui";

type TPaymentPanel = {
  icon: React.ReactNode;
  message: React.ReactNode;
};

export const PaymentPanelLayout: React.FC<TPaymentPanel> = ({
  icon,
  message,
}) => (
  <FlexboxGrid justify="center" align="middle">
    <FlexboxGrid.Item as={Col} xs={20} sm={20} md={16} lg={12} xl={10}>
      <Panel
        bordered
        style={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-evenly",
          minHeight: 300,
        }}
      >
        <div
          style={{
            marginBottom: 20,
            display: "flex",
            justifyContent: "center",
          }}
        >
          {icon}
        </div>
        {message}
      </Panel>
    </FlexboxGrid.Item>
  </FlexboxGrid>
);
