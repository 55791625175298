import RSCopyIcon from "@rsuite/icons/Copy";
import { useCopyToClipboard } from "./useCopyToClipboard";

type CopyIconProps = {
  text: string;
};

export const CopyIcon: React.FC<CopyIconProps> = ({ text }) => {
  const copyToClipboard = useCopyToClipboard();

  return (
    <RSCopyIcon
      style={{ fontSize: "20px", cursor: "pointer" }}
      onClick={() => copyToClipboard(text)}
    />
  );
};
