import React from "react";
import { FlexboxGrid } from "rsuite";
import { ScheduleHeader } from "./ScheduleHeader";
import { ScheduleCellContent } from "./ScheduleCellContent";

const flexboxGridStyle: React.CSSProperties = {
  height: 180,
  padding: 8,
  cursor: "pointer",
  outline: "1px solid var(--rs-border-primary)",
  minWidth: 100,
};

interface IProps {
  date: Date;
  onClick: (date: Date) => void;
}

export const ScheduleCell: React.FC<IProps> = ({ date, onClick }) => {
  return (
    <FlexboxGrid.Item
      colspan={6}
      style={flexboxGridStyle}
      onClick={() => {
        onClick(date);
      }}
    >
      <ScheduleHeader date={date} />
      <ScheduleCellContent date={date} />
    </FlexboxGrid.Item>
  );
};
