import { useIsMounted, useStore } from "hooks";
import { useEffect, useState } from "react";
import Api from "root/Api";
import { StatusPaymentType } from "stores";
import {
  BlockIcon,
  FailedMessage,
  SuccessIcon,
  SuccessMessage,
  TimeOutIcon,
  TimeOutMessage,
} from "../components";
import { Loader } from "shared/ui";

export const useSuccessPayment = () => {
  const { lessonsStore, tariffStore } = useStore();

  const isMounted = useIsMounted();

  const maxRepeatCount = 10;
  let repeatCount = 0;

  const [statusIcon, setStatusIcon] = useState<React.JSX.Element>(
    <Loader
      center
      size="lg"
      content="Ожидаем ответа платежной системы..."
      vertical
    />
  );
  const [statusMessage, setStatusMessage] = useState<React.JSX.Element>();

  useEffect(() => {
    loadStatus();
  }, []);

  const loadStatus = async () => {
    const result = await Api.payment.GetPaymentStatus();
    if (!isMounted()) {
      return;
    }
    if (result === undefined || result === null) {
      setTimeoutStatus();
      return;
    }
    checkStatus(result);
  };

  const checkStatus = (status: number) => {
    switch (status) {
      case StatusPaymentType.Success:
        tariffStore.loadMyTariffs(true);
        lessonsStore.load(true);
        setStatusIcon(<SuccessIcon />);
        setStatusMessage(<SuccessMessage />);
        break;
      case StatusPaymentType.Failed:
        setStatusIcon(<BlockIcon />);
        setStatusMessage(<FailedMessage />);
        break;
      default:
        repeat();
        break;
    }
  };

  const setTimeoutStatus = () => {
    setStatusIcon(<TimeOutIcon />);
    setStatusMessage(<TimeOutMessage />);
  };

  const repeat = () => {
    setTimeout(() => {
      repeatCount++;
      if (repeatCount < maxRepeatCount) {
        loadStatus();
      } else {
        setTimeoutStatus();
      }
    }, 3000);
  };
  return { statusIcon, statusMessage };
};
