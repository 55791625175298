import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { ButtonToolbar } from "rsuite";
import { IStudentTopDto } from "stores";
import { useIsMounted, useStore } from "../../../hooks";
import { IRequestGetStudentsTop } from "../../../root";
import { SelectMentorButton } from "./SelectMentorButton";
import { SelectMonthButton } from "./SelectMonthButton";
import { SelectTariffButton } from "./SelectTariffButton";

type TFilterTop = IRequestGetStudentsTop & { mentorId?: number };

const initialFilter: TFilterTop = {
  onlyClan: false,
  tariffId: 0,
  mentorId: 0,
};

export interface IFilterMentorNames {
  title: string;
  id: number;
}

export interface IFilterProps {
  onChangeStudentsTop: (studentsTop: IStudentTopDto[]) => void;
  onChangeLoading: (isLoadingStudentTop: boolean) => void;
}

const FilterForTopComponent: React.FC<IFilterProps> = (props: IFilterProps) => {
  const isMounted = useIsMounted();
  const { tariffStore, statisticStore } = useStore();

  const [filter, setFilter] = useState<TFilterTop>(initialFilter);

  useEffect(() => {
    const selectedTariff = tariffStore.selectedTariff;
    if (!selectedTariff) {
      return;
    }
    setFilter((prev) => ({
      ...prev,
      tariffId: selectedTariff.id,
      monthNumber: undefined,
    }));
  }, [tariffStore.selectedTariff]);

  useEffect(() => {
    setFilter((prev) => ({
      ...prev,
      tariffId: tariffStore.selectedTariff?.id ?? tariffStore.data[0]?.id,
    }));
  }, []);

  useEffect(() => {
    filter.tariffId && loadTop(filter);
  }, [filter]);

  const loadTop = (filter: TFilterTop) => {
    props.onChangeLoading(true);
    statisticStore
      .loadByFilter(filter, filter.mentorId)
      .then((result) => {
        if (!result || !isMounted()) {
          return;
        }
        props.onChangeStudentsTop(result);
      })
      .catch((error) => console.error(error))
      .finally(() => props.onChangeLoading(false));
  };

  return (
    <ButtonToolbar style={{ marginBottom: 15 }}>
      <SelectTariffButton
        onChange={(tariffId) =>
          setFilter((prev) => ({
            ...prev,
            tariffId,
            mentorId: 0,
            monthNumber: undefined,
          }))
        }
        selectedTariffId={filter.tariffId}
      />
      <SelectMentorButton
        value={filter.mentorId}
        onChange={(mentorId: number) => {
          const onlyClan = mentorId !== 0;
          setFilter((prev) => ({ ...prev, mentorId, onlyClan }));
        }}
      />
      <SelectMonthButton
        value={filter.monthNumber}
        onChange={(month) => {
          const monthNumber = month === 0 ? undefined : month;
          setFilter((prev) => ({ ...prev, monthNumber }));
        }}
        tariffId={filter.tariffId}
      />
    </ButtonToolbar>
  );
};

export const FilterForTop = observer(FilterForTopComponent);
