import React, { useEffect, useState } from "react";
import { ButtonToolbar, Steps } from "rsuite";
import { observer } from "mobx-react";
import { Preview } from "./component/Preview";
import { Materials } from "./component/Materials";
import { HomeWorkList } from "./component/HomeWorkList";
import PagePreviousIcon from "@rsuite/icons/PagePrevious";
import PageNextIcon from "@rsuite/icons/PageNext";
import { IoSaveOutline } from "react-icons/io5";
import { useStore } from "../../hooks";
import { ILesson, ILessonRequest } from "stores";
import { ValidationResult } from "stores/models/ValidationResult";
import { ValidationHelper } from "root";
import { Divider, IconButton, Panel } from "shared/ui";

export interface ILessonsEditProps {
  lesson: ILesson | undefined;
  onClose: () => void;
}

const LessonsEditComponent: React.FC<ILessonsEditProps> = (
  props: ILessonsEditProps
) => {
  const { lesson } = props;
  const handleOnClose = () => {
    props.onClose();
  };
  const { lessonsStore } = useStore();
  const [content, setContent] = useState<any>(undefined);
  const [step, setStep] = useState(0);
  const [lessonRequest, setLessonRequest] = useState<ILessonRequest>({});
  const [resultValidation, setResultValidation] = useState<ValidationResult>();
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");

  const stepQuantity = 2;

  useEffect(() => {
    if (lesson) {
      setLessonRequest({
        ...lesson,
        dateTime: new Date(lesson.dateTime),
        checkDeadline: lesson.checkDeadline
          ? new Date(lesson.checkDeadline)
          : undefined,
        submitDeadline: lesson.submitDeadline
          ? new Date(lesson.submitDeadline)
          : undefined,
      });
    }
  }, []);

  const onStep = (step: number) => {
    setStep(step);
  };

  useEffect(() => {
    switch (step) {
      case 0:
        setContent(
          <Preview
            lessonRequest={lessonRequest}
            validationResult={resultValidation}
          />
        );
        setTitle("Анонс будущего занятия");
        setDescription("Сохраненный анонс отобразится в расписании учеников.");
        break;
      case 1:
        setContent(
          <Materials
            lessonRequest={lessonRequest}
            validationResult={resultValidation}
          />
        );
        setTitle("Медиа");
        setDescription(
          "После добавления медиа-материалов урок будет доступен ученикам в списке уроков."
        );

        break;
      case 2:
        setContent(
          <HomeWorkList
            lessonRequest={lessonRequest}
            validationResult={resultValidation}
          />
        );
        setTitle("Домашка и последние настройки");
        setDescription(
          "После сохранения урок полностью готов и отображается у учеников в проверках, как несданный."
        );

        break;
    }
  }, [step, lessonRequest, resultValidation]);

  const getStepStatus = (i: number, errorField: string) => {
    if (resultValidation?.hasError(errorField)) {
      return "error";
    }
    return step === i ? "process" : "wait";
  };

  return (
    <>
      <Steps current={step}>
        <Steps.Item
          data-testId='StepPreview'
          title="Анонс"
          onClick={() => onStep(0)}
          status={getStepStatus(0, "anons")}
        />
        <Steps.Item
          data-testId='StepMedia'
          title="Медиа"
          onClick={() => onStep(1)}
          status={getStepStatus(1, "media")}
        />
        <Steps.Item
          data-testId='StepHomework'
          title="Задания"
          onClick={() => onStep(2)}
          status={getStepStatus(2, "homework")}
        />
      </Steps>
      <ButtonToolbar
        style={{
          marginTop: 15,
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <IconButton
          data-testId='btnPrevStep'
          size="xs"
          icon={<PagePreviousIcon />}
          onClick={() => setStep((prev) => prev - 1)}
          placement="left"
          disabled={step === 0}
        ></IconButton>
        <IconButton
          data-testId='btnNextStep'
          size="xs"
          icon={<PageNextIcon />}
          onClick={() => setStep((prev) => prev + 1)}
          placement="right"
          appearance="primary"
          disabled={step === stepQuantity}
        ></IconButton>
      </ButtonToolbar>
      <Divider style={{ marginTop: 30 }} />
      <Panel>
        <div style={{ marginBottom: 20 }}>
          <h5>{title}</h5>
          <p style={{ color: "#7a7a7a" }}>{description}</p>
        </div>
        {content}
      </Panel>
      <Divider style={{ marginBottom: 30 }} />
      <ButtonToolbar style={{ display: "flex", justifyContent: "flex-end" }}>
        <IconButton
          data-testid="submit"
          loading={lessonsStore.onSaving}
          icon={
            <IoSaveOutline style={{ marginRight: 5, width: 18, height: 18 }} />
          }
          style={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
            paddingLeft: 12,
            marginRight: 10,
          }}
          color="green"
          appearance="primary"
          onClick={async () => {
            const resultValidation =
              ValidationHelper.lessonValidate(lessonRequest);
            setResultValidation(resultValidation);
            if (
              resultValidation.hasNotError() &&
              (await lessonsStore.save(lessonRequest))
            ) {
              handleOnClose();
            }
          }}
        >
          Сохранить
        </IconButton>
      </ButtonToolbar>
    </>
  );
};
export const LessonsEdit = observer(LessonsEditComponent);
