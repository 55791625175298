import EditIcon from "@rsuite/icons/Edit";
import VisibleIcon from "@rsuite/icons/Visible";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import { CSSProperties, useState } from "react";
import { Link } from "react-router-dom";
import { DateHelper } from "root/DateHelper";
import { ButtonToolbar, FlexboxGrid, List } from "rsuite";
import { ILesson } from "stores";
import { ModalCreateEditLesson } from "./ModalCreateEditLesson";
import { IconButton, Avatar } from "shared/ui";

const styleCenter: CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "60px",
};

const slimText: CSSProperties = {
  fontSize: "1em",
  color: "#97969B",
  fontWeight: "lighter",
  paddingBottom: 5,
};

const titleStyle: CSSProperties = {
  paddingBottom: 5,
  whiteSpace: "nowrap",
  fontWeight: 500,
};

const dataStyle: CSSProperties = {
  fontSize: "1.2em",
  fontWeight: 500,
};
interface LessonsItemProps {
  lesson: ILesson;
}

const LessonsItemComponent: React.FC<LessonsItemProps> = (props) => {
  const { tariffStore } = useStore();

  const [selectedLessonId, setSelectedLesson] = useState(0);
  const [openModal, setOpenModal] = useState(false);
  const handleOpenEdit = (id: number) => {
    setSelectedLesson(id);
    setOpenModal(true);
  };

  const isPreviewLesson =
    props.lesson.webinarImageUrl === null || props.lesson.imageUrl === null;

  return (
    <>
      <ModalCreateEditLesson
        lessonId={selectedLessonId}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      />
      <>
        <List.Item
          data-testId={props.lesson.id}
          index={props.lesson.id}
          style={
            new Date(props.lesson.dateTime) < new Date()
              ? { opacity: "70%" }
              : {}
          }
        >
          <FlexboxGrid>
            <FlexboxGrid.Item colspan={2} style={styleCenter}>
              <Avatar src={props.lesson.imageUrl} size="lg" />
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              colspan={6}
              style={{
                ...styleCenter,
                flexDirection: "column",
                alignItems: "flex-start",
                overflow: "hidden",
              }}
            >
              <div style={titleStyle}>{props.lesson.title}</div>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item colspan={6} style={styleCenter}>
              <div style={{ textAlign: "right" }}>
                <div style={slimText}>Дата проведения</div>
                <div style={dataStyle}>
                  {DateHelper.DateToStringAndTime(props.lesson.dateTime)}
                </div>
              </div>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              colspan={6}
              style={{
                ...styleCenter,
                flexDirection: "column",
                alignItems: "flex-start",
                overflow: "hidden",
              }}
            >
              <div style={titleStyle}>
                {`Курс: ${
                  tariffStore.find(props.lesson.tariffId)?.title ||
                  props.lesson.tariffId
                }`}
                <br />
                {`Месяц: ${props.lesson.monthNumber || "Доп."}`}
              </div>
            </FlexboxGrid.Item>
            <FlexboxGrid.Item
              colspan={3}
              style={{
                ...styleCenter,
              }}
            >
              <ButtonToolbar>
                <IconButton
                  disabled={isPreviewLesson}
                  size="lg"
                  color="cyan"
                  appearance="subtle"
                  icon={<VisibleIcon />}
                  as={Link}
                  to={isPreviewLesson ? "" : `/lessons/${props.lesson.id}`}
                />

                <IconButton
                  size="lg"
                  color="green"
                  appearance="subtle"
                  icon={<EditIcon />}
                  onClick={() => handleOpenEdit(props.lesson.id)}
                />
              </ButtonToolbar>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </List.Item>
      </>
    </>
  );
};
export const LessonsItem = observer(LessonsItemComponent);
