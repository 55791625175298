import CheckIcon from "@rsuite/icons/Check";
import PlusIcon from "@rsuite/icons/Plus";
import { LoginModal } from "components";
import { useRefetchTariff, useStore } from "hooks";
import { observer } from "mobx-react";
import React, { useState } from "react";
import { GlobalConstants } from "root/GlobalConstants";
import { localStorageWrapper } from "root/wrappers/LocalSessionsStorageWrapper";
import { Stack } from "rsuite";
import { Col, Grid, Button, Panel, Row } from "shared/ui";
import { ICartItem, ITariff, TariffType } from "stores";
import {
  CostPerMonth,
  FactsTariff,
  ImageTariff,
  TitleAndDescriptionTariff,
} from "../../components";

interface ICatalogItemProps {
  tariff: ITariff;
  cartItem: ICartItem[];
  onClick: (tariff: ITariff) => void;
}

const CatalogItemComponent: React.FC<ICatalogItemProps> = ({
  tariff,
  cartItem,
  onClick,
}) => {
  const { authStore, studentTariffStore } = useStore();
  const [open, setOpen] = useState(false);
  const { refetchTariff } = useRefetchTariff();

  const isCheckedTariff = cartItem.map((x) => x.tariffId).includes(tariff.id);

  const getFreeTariff = () => {
    if (!authStore.me) {
      localStorageWrapper.set(GlobalConstants.tariffIdForFreeGet, tariff.id);
      setOpen(true);
      return;
    }
    studentTariffStore.addFree(tariff.id).then(() => refetchTariff(tariff.id));
  };

  return (
    <>
      <LoginModal isOpen={open} onClose={() => setOpen(false)} />
      <Panel bordered>
        <Grid fluid>
          <Row className="show-grid">
            <Col xxl={9} xl={9} lg={9} md={8} sm={10} xs={24}>
              <ImageTariff imageUrl={tariff.imageUrl} />
            </Col>
            <Col xxl={15} xl={15} lg={15} md={16} sm={14} xs={24}>
              <TitleAndDescriptionTariff tariff={tariff} />
            </Col>
            <Col xxl={7} xl={7} lg={15} md={15} sm={24} xs={24}>
              <div style={{ marginTop: 12 }}>
                {tariff.type === TariffType.Free ? (
                  <Button
                    onClick={getFreeTariff}
                    loading={studentTariffStore.onUpdating}
                    disabled={studentTariffStore.onUpdating}
                  >
                    Получить курс
                  </Button>
                ) : (
                  <Stack spacing={10}>
                    <Button
                      appearance={isCheckedTariff ? "default" : "primary"}
                      startIcon={isCheckedTariff ? <CheckIcon /> : <PlusIcon />}
                      onClick={() => onClick(tariff)}
                    >
                      {isCheckedTariff ? "добавлен" : "в корзину"}
                    </Button>
                    <CostPerMonth cost={tariff.costPerMonth} />
                  </Stack>
                )}
              </div>
            </Col>

            <Col xxl={8} xl={8} lg={15} md={15} sm={24} xs={24}>
              <FactsTariff tariff={tariff} />
            </Col>
          </Row>
        </Grid>
      </Panel>
    </>
  );
};
export const CatalogItem = observer(CatalogItemComponent);
