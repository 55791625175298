import { del, get, post, put } from "../WebHelper";

export class CrudController<TType> {
  private controller: string;

  constructor(controller: string) {
    this.controller = controller;
  }
  public async GetAll(refetch?: boolean): Promise<TType[] | undefined> {
    return await get(`/${this.controller}/all/`, { refetch });
  }
  public async Get(id: number): Promise<TType | undefined> {
    return await get(`/${this.controller}/` + id);
  }
  public async Create(model: any): Promise<number | undefined> {
    return await post(`/${this.controller}/`, model);
  }
  public async Update(id: number, model: any): Promise<number | undefined> {
    return await put(`/${this.controller}/` + id, model);
  }
  public async Delete(id: number): Promise<void> {
    return await del(`/${this.controller}/` + id);
  }
}
