import { IMenuItem } from "./IMenuItem";
import { AdminMenu } from "./AdminMenu";

export const SuperMenu = () => {
  const menuItems: IMenuItem[] = [];
  const logMenuItem = {
    title: "Логи",
    path: "/logs",
  };
  const usersMenuItem = {
    title: "Пользователи",
    path: "/users",
  };

  menuItems.push({
    title: "[s] Админ",
    path: "superDrop",
    subItems: [logMenuItem, usersMenuItem],
  });

  const adminMenu = AdminMenu();
  return adminMenu.concat(menuItems);
};
