import { ViewFileModal } from "components/Modal/ViewFileModal/ViewFileModal";
import { useState } from "react";

export const GlobalNotificationCreationRules: React.FC = () => {
  const [isOpen, setOpen] = useState(false);
  const [imgSrc, setImgSrc] = useState("");

  const srcToTechnicalGN = `${process.env.PUBLIC_URL}/images/Technical-GN.png`;
  const srcToInfoGN = `${process.env.PUBLIC_URL}/images/Info-GN.png`;
  const srcToAlertGN = `${process.env.PUBLIC_URL}/images/Alert-GN.png`;
  return (
    <>
      <ViewFileModal
        imgSrc={imgSrc}
        isOpen={isOpen}
        onCancel={() => {
          setOpen(false);
        }}
      />
      <p>Уведомления возможно показывать:</p>
      <ul>
        <li>
          <p>
            Конкретному пользователю (<em>в разделе "Пользователь"</em>)
          </p>
        </li>
        <li>
          <p>
            Конкретным ролям (
            <em>
              например, всем ученикам/учителям, в разделе "Роль пользователей"
            </em>
            )
          </p>
        </li>
        <li>
          <p>
            Всем (<em>без указания пользователя и роли</em>)
          </p>
        </li>
        <li>
          <p>Ограниченное время</p>
        </li>
        <li>
          <p>Разделены по типу:</p>
          <ul>
            <br />
            <li>
              <p>Техническое - отображаются в самом верху и нельзя закрыть</p>
              <img
                onClick={() => {
                  setImgSrc(srcToTechnicalGN);
                  setOpen(true);
                }}
                src={srcToTechnicalGN}
                alt="Technical-GN"
                width="460"
                height="54"
              />
            </li>
            <br />
            <li>
              <p>Информационное - не всплывают, лежат в колокольчике</p>

              <img
                onClick={() => {
                  setImgSrc(srcToInfoGN);
                  setOpen(true);
                }}
                src={srcToInfoGN}
                alt="Info-GN"
                width="460"
                height="153"
              />
            </li>
            <br />
            <li>
              <p>Предупреждение - всплывают, можно закрыть</p>
              <img
                onClick={() => {
                  setImgSrc(srcToAlertGN);
                  setOpen(true);
                }}
                src={srcToAlertGN}
                alt="Alert-GN"
                width="460"
                height="235"
              />
            </li>
          </ul>
        </li>
      </ul>
    </>
  );
};
