import { runInAction, makeObservable, observable } from "mobx";
import { NotificationHelper, DataHelper } from "../root";
import Api from "../root/Api";
import {
  HomeworkStudentStatus,
  IHomeWork,
  IHomeworkCheck,
  IHomeworkResult,
  IPage,
  IPageableStore,
  IStudentHomework,
} from "./models";
import {
  HomeworksCheckOrder,
  IRequestGetHomeworksCheckByFilter,
  IRequestSaveAnswersHomework,
  IRequestSaveCheckHomework,
} from "../root/requests";
import { CrudStore } from "./CrudStore";
import { queryClient } from "provider/QueryProvider/QueryProvider";
import { StudentDraftAnswersCheckStorage } from "root/SaveCheckDraftHelper";

type TFilterOptions = {
  refetch?: boolean;
  reloadDataPage?: boolean;
};
export class HomeworkStore
  extends CrudStore<IHomeWork>
  implements IPageableStore<IRequestGetHomeworksCheckByFilter, IHomeworkCheck>
{
  homeWorksCheck: IHomeworkCheck[] = [];
  homeWorksResult: IHomeworkResult[] = [];
  homeWorks: IHomeWork[] = [];
  filter: IRequestGetHomeworksCheckByFilter | undefined;
  dataPage: IPage<IHomeworkCheck> | undefined;

  constructor() {
    super(Api.homeworks);
    makeObservable(this, {
      homeWorksCheck: observable,
      homeWorksResult: observable,
      dataPage: observable,
    });
  }
  public async loadByFilter(
    filter = this.filter,
    options: TFilterOptions = { refetch: false, reloadDataPage: true }
  ): Promise<IPage<IHomeworkCheck> | undefined> {
    this.setOnLoading(true);
    const queryKey = ["checks", filter];
    try {
      const { refetch, reloadDataPage } = options;
      refetch && queryClient.removeQueries(queryKey);
      const result = await queryClient.fetchQuery({
        queryKey,
        queryFn: async () =>
          await Api.homeworks.GetHomeworksCheckByFilter(filter),
      });
      runInAction(() => {
        if (result) {
          this.homeWorksCheck = result.data;
          this.setMyHomeWorksChecks(result.data);
        }
        this.filter = filter;
        reloadDataPage && (this.dataPage = result);
      });
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
  }

  public async getSummary(
    lessonId: number,
    studentId?: number
  ): Promise<string | undefined> {
    try {
      this.setOnLoading(true);
      const result = await queryClient.fetchQuery({
        queryKey: ["SummaryHomework", lessonId, studentId],
        queryFn: async () =>
          await Api.homeworks.GetSummaryHomework(lessonId, studentId),
      });
      return result;
    } catch (error) {
      console.error(error);
      return;
    } finally {
      this.setOnLoading(false);
    }
  }

  public async getAnswers(
    id: number,
    refetch = false
  ): Promise<IStudentHomework[] | undefined> {
    const queryKey = ["answersByLesson", id];
    try {
      this.setOnLoading(true);
      refetch && queryClient.removeQueries(queryKey);
      const result = await queryClient.fetchQuery({
        queryKey,
        queryFn: async () =>
          await Api.homeworks.GetAnswersByLesson(id, refetch),
      });
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
  }

  public async reCheck(id: number, comment: string): Promise<boolean> {
    this.setOnSaving(true);
    try {
      await Api.homeworks.ReCheck(id, comment);
      NotificationHelper.ShowSuccess("Домашка отправлена на перепроверку!");
      return true;
    } catch (error) {
      console.error(error);
      NotificationHelper.ShowError(
        "Возможно, это был временный сбой — просто попробуйте снова или обратитесь в тех. поддержку. Код ошибки: " +
          error,
        "Не отправлено!"
      );
      return false;
    } finally {
      this.setOnSaving(false);
    }
  }

  public async saveAnswers(data: IRequestSaveAnswersHomework) {
    if (this.onSaving) {
      return;
    }
    this.setOnSaving(true);
    try {
      await Api.homeworks.SaveAnswersHomework(data);
      NotificationHelper.ShowSuccess("Домашка сохранена!");
      const homeWork = this.homeWorksCheck.find(
        (x) => x.homeworkId === data.homeworkId && x.packId === data.packId
      );
      if (homeWork) {
        homeWork.homeworkStatus = HomeworkStudentStatus.sended;
      }
      return true;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnSaving(false);
    }
  }

  public async dropAnswer(
    lessonId: number,
    studentId: number,
    taskPackId: number,
    homeworkId: number
  ) {
    if (this.onSaving) {
      return;
    }
    this.setOnSaving(true);
    try {
      await Api.homeworks.DropAnswers(lessonId, studentId, taskPackId);
      this.studentAnswersByLesson(
        lessonId,
        studentId,
        homeworkId,
        taskPackId,
        true
      );
      this.studentAnswersByLesson(
        lessonId,
        studentId,
        undefined,
        undefined,
        true
      );
      this.filter &&
        this.loadByFilter(this.filter, {
          refetch: true,
          reloadDataPage: false,
        });
      StudentDraftAnswersCheckStorage.removeDraft(
        lessonId,
        homeworkId,
        taskPackId,
        studentId
      );
      NotificationHelper.ShowSuccess("Ответы сброшены!");
      return true;
    } catch (error) {
      console.error(error);
      return false;
    } finally {
      this.setOnSaving(false);
    }
  }

  public async saveAnswersCheck(data: IRequestSaveCheckHomework) {
    if (this.onSaving) {
      return;
    }
    this.setOnSaving(true);
    try {
      await Api.homeworks.SaveAnswersCheckHomework(data);
      NotificationHelper.ShowSuccess("Проверка сохранена!");
      this.filter &&
        this.loadByFilter(this.filter, {
          refetch: true,
          reloadDataPage: false,
        });
      return true;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnSaving(false);
    }
  }

  public async studentAnswersByLesson(
    lessonId: number,
    studentId: number,
    homeworkId?: number,
    packId?: number,
    refetch = false
  ): Promise<IStudentHomework[] | undefined> {
    const queryKey = [
      "studentAnswers",
      { lessonId, studentId, homeworkId, packId },
    ];
    try {
      this.setOnLoading(true);
      refetch && queryClient.removeQueries(queryKey);
      const result = await queryClient.fetchQuery({
        queryKey,
        queryFn: async () =>
          await Api.homeworks.GetStudentAnswersByLesson(
            lessonId,
            studentId,
            homeworkId,
            packId,
            refetch
          ),
      });
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
  }

  public async saveSummary(
    lessonId: number,
    studentId: number,
    summary: string
  ) {
    this.setOnSaving(true);
    try {
      await Api.homeworks.SaveSummaryHomework(lessonId, studentId, summary);
      NotificationHelper.ShowSuccess("Вывод сохранен!");
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnSaving(false);
    }
  }

  public async getRecheckComment(tasksPackHwStudentId: number) {
    this.setOnLoading(true);
    try {
      const result = await queryClient.fetchQuery({
        queryKey: ["recheckComment", tasksPackHwStudentId],
        queryFn: async () =>
          await Api.homeworks.GetRecheckComment(tasksPackHwStudentId),
      });
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
  }

  public loadUnsendedHomework() {
    const filter: IRequestGetHomeworksCheckByFilter = {
      tariffId: null,
      lessonId: null,
      studentId: null,
      homeworkStatus: HomeworkStudentStatus.notSend,
      checkStatus: null,
      deadlineStatus: null,
      mentorId: null,
      teacherId: null,
      count: 0,
      orderBy: HomeworksCheckOrder.SubmitDeadlineAsc,
    };
    this.loadByFilter(filter, { reloadDataPage: false });
  }

  private setMyHomeWorksChecks(data: IHomeworkCheck[]) {
    const groups = DataHelper.groupBy(data, (x) => x.lessonId);
    const results: IHomeworkResult[] = [];
    groups.forEach((value, key) => {
      results.push({ lessonId: key, checks: value });
    });

    this.homeWorksResult = results;
  }

  public clear() {
    super.clear();
    runInAction(() => {
      this.homeWorksCheck = [];
      this.homeWorksResult = [];
      this.homeWorks = [];
      this.filter = undefined;
      this.dataPage = undefined;
    });
  }
}
