import { CSSProperties } from "react";
import { Panel } from "shared/ui";

interface IEmptyStatePanelProps {
  bordered?: boolean;
  bodyFill?: boolean;
  children?: React.JSX.Element;
  style?: CSSProperties;
}
export const EmptyStatePanel: React.FC<IEmptyStatePanelProps> = ({
  bordered,
  bodyFill,
  children,
  style,
}) => {
  return (
    <>
      <Panel
        bodyFill={bodyFill || false}
        bordered={bordered || true}
        style={{
          ...style,
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        {children ? children : <span>Нет данных</span>}
      </Panel>
    </>
  );
};
