import { useStore } from "hooks";
import { observer } from "mobx-react";
import React, { CSSProperties } from "react";
import { NotificationEmpty } from "./components/NotificationEmpty";
import { NotificationList } from "./components/NotificationList";

type TNotificationListComponentProps = {
  containerStyle?: CSSProperties;
};

const NotificationComponent: React.FC<TNotificationListComponentProps> = ({
  containerStyle,
}) => {
  const { globalNotificationStore } = useStore();

  return (
    <div style={containerStyle}>
      {!globalNotificationStore.data.length ? (
        <NotificationEmpty />
      ) : (
        <NotificationList />
      )}
    </div>
  );
};
export const Notification = observer(NotificationComponent);
