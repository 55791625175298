import React, { useEffect } from "react";
import { FormikValues, useFormikContext } from "formik";

import { PersistOptions } from "../types";
import { useLocalStorage } from "hooks/useLocalStorage";
import { useSessionStorage } from "hooks/useSessionStorage";

export type FormPersisterProps<T extends FormikValues> = {
  options?: PersistOptions;
  initialValues: T;
};
/**
 *  Механизм сохраняющий данные формы в LocalStorage для сохранения данных между перезагрузками страницы.
 */
export default function FormPersister<T extends FormikValues>({
  initialValues,
  options,
  children = null,
}: React.PropsWithChildren<FormPersisterProps<T>>): JSX.Element {
  const context = useFormikContext<T>();
  const useStorage =
    options?.storageType === "local" ? useLocalStorage : useSessionStorage;
  const [value, setValue] = useStorage<T>(
    options?.storeName ?? "none",
    initialValues
  );

  useEffect(() => {
    if (!options?.enable) {
      return;
    }
    context.setValues(value);
  }, []);

  useEffect(() => {
    if (!options?.enable) {
      return;
    }
    setValue(context.values);
  }, [context.values, options?.enable, setValue]);

  return children as JSX.Element;
}
