import { useStore } from "hooks";
import React, { useEffect, useState } from "react";
import { Progress } from "rsuite";
import { observer } from "mobx-react";
import { IStudentPerformance, PartPercentType } from "stores";

interface IStudentProgressLineProps {
  partPercentType: PartPercentType;
  strokeWidth: number;
}

export const StudentProgressLineComponent: React.FC<
  IStudentProgressLineProps
> = (props) => {
  const { statisticStore } = useStore();
  const [progressPercent, setProgressPercent] = useState<number>(0);
  const [color, setColor] = useState("rgb(171,12,12)");

  useEffect(() => {
    switch (true) {
      case progressPercent <= 40:
        setColor("rgb(171,12,12)");
        break;
      case progressPercent <= 70:
        setColor("rgb(255,193,7)");
        break;
      case progressPercent > 71:
        setColor("rgb(173, 57, 152)");
        break;
    }
  }, [progressPercent]);

  useEffect(() => {
    switch (props.partPercentType) {
      case PartPercentType.FirstPart:
        setProgressPercent(
          calculatеPartPercent(
            statisticStore.progressChart,
            PartPercentType.FirstPart
          )
        );
        break;
      case PartPercentType.SecondPart:
        setProgressPercent(
          calculatеPartPercent(
            statisticStore.progressChart,
            PartPercentType.SecondPart
          )
        );
        break;
      case PartPercentType.AllPart:
        setProgressPercent(
          calculatеPartPercent(
            statisticStore.progressChart,
            PartPercentType.AllPart
          )
        );
        break;
    }
  }, [statisticStore.onLoading, statisticStore.progressChart]);

  const calculatеPartPercent = (
    progressChart: IStudentPerformance[],
    partPercentType: number
  ): number => {
    return round10(
      sumPartPercent(progressChart, partPercentType) /
        (progressChart.length *
          (partPercentType === PartPercentType.AllPart ? 2 : 1))
    );
  };
  const sumPartPercent = (
    progressChart: IStudentPerformance[],
    partPercentType: number
  ): number => {
    return progressChart.reduce(function (total, progressChart) {
      if (partPercentType === PartPercentType.AllPart) {
        return (
          total +
          progressChart.firstPartPercent +
          progressChart.secondPartPercent
        );
      }
      return (
        total +
        (partPercentType === PartPercentType.FirstPart
          ? progressChart.firstPartPercent
          : progressChart.secondPartPercent)
      );
    }, 0);
  };
  //округление до сотых
  const round10 = (decimal: number): number => {
    return Math.floor(decimal * 100) / 100;
  };

  return (
    <>
      <Progress.Line
        strokeWidth={props.strokeWidth}
        percent={progressPercent || 0}
        status="active"
        strokeColor={color}
      />
    </>
  );
};
export const StudentProgressLine = observer(StudentProgressLineComponent);
