import React, { useMemo } from "react";
import { observer } from "mobx-react";
import { SelectPicker } from "rsuite";
import { useStore } from "../../hooks";

interface IProps {
  onChange: (value: number | null) => void;
  defaultValue?: number;
}

const SelectPickerTariffIdComponent: React.FC<IProps> = ({
  defaultValue,
  onChange,
}) => {
  const { tariffStore } = useStore();

  const tariffs = useMemo(
    () =>
      tariffStore.data
        .slice()
        .sort(
          (a, b) =>
            new Date(b.dateBegin).getTime() - new Date(a.dateBegin).getTime()
        )
        .map((x) => ({
          label: x.title,
          value: x.id,
        })),
    [tariffStore.data]
  );

  return (
    <SelectPicker
      defaultValue={defaultValue}
      onChange={onChange}
      label="Курс"
      data={tariffs}
      style={{ width: 224 }}
    />
  );
};
export const SelectPickerTariffId = observer(SelectPickerTariffIdComponent);
