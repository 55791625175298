import { DateCell } from "components";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import { StudentCell } from "pages/Admin/MentorStudentTariffs";
import { Table } from "rsuite";
import { Cell, Column, HeaderCell } from "rsuite-table";
import { TOrder } from "stores";
import { useHistoryOrdersTable } from "../hooks/useHistoryOrdersTable";
import { AmountCell } from "./cell/AmountCell";
import { InfoCell } from "./cell/InfoCell";
import { RenderRowExpanded } from "./cell/RenderRowExpanded";
import { StatusCell } from "./cell/StatusCell";
import { Panel } from "shared/ui";

type TStudentTariffTableProps = { data?: TOrder[] };

const HistoryOrdersTableComponent: React.FC<TStudentTariffTableProps> = ({
  data,
}) => {
  const { paymentStore } = useStore();

  const { expandedRowKeys, handleExpanded } = useHistoryOrdersTable();

  return (
    <Panel
      bordered
      bodyFill
      style={{ marginTop: 15, maxHeight: "60vh", overflow: "auto" }}
    >
      <Table
        shouldUpdateScroll={false}
        rowKey="paymentId"
        autoHeight
        loading={paymentStore.onLoading}
        data={data}
        expandedRowKeys={expandedRowKeys}
        renderRowExpanded={(rowData) => <RenderRowExpanded rowData={rowData} />}
      >
        <Column width={70} align="center">
          <HeaderCell>#</HeaderCell>
          <InfoCell
            dataKey="id"
            expandedRowKeys={expandedRowKeys}
            onChange={handleExpanded}
          />
        </Column>
        <Column width={50}>
          <HeaderCell>№</HeaderCell>
          <Cell dataKey="id" />
        </Column>
        <Column width={140}>
          <HeaderCell align="center">Дата</HeaderCell>
          <DateCell dataKey="dateCreate" />
        </Column>
        <Column width={110}>
          <HeaderCell align="center">Статус</HeaderCell>
          <StatusCell dataKey="status" />
        </Column>
        <Column minWidth={100} flexGrow={2}>
          <HeaderCell align="center">Студент</HeaderCell>
          <StudentCell dataKey="studentId" fullName />
        </Column>

        <Column minWidth={100} flexGrow={2}>
          <HeaderCell>Сумма(₽)</HeaderCell>
          <AmountCell dataKey="amount" />
        </Column>
      </Table>
    </Panel>
  );
};
export const HistoryOrdersTable = observer(HistoryOrdersTableComponent);
