import React from "react";
import { Loader as RSLoader } from "rsuite";
import { RefForwardingComponent } from "../types";
import { LoaderProps } from "./types";

const loader = <E extends React.ElementType>(
  { ...props }: LoaderProps,
  ref: React.ForwardedRef<E>
) => <RSLoader {...props} ref={ref} />;

export const Loader: RefForwardingComponent<"div", LoaderProps> =
  React.forwardRef(loader);
