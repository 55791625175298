import { BigMessage } from "components";

type TDelayReasonMessageProps = {
  message?: string;
};

export const DelayReasonMessage: React.FC<TDelayReasonMessageProps> = ({
  message,
}) => {
  if (!message || !message.length) {
    return null;
  }

  return (
    <BigMessage
      type="success"
      maxLength={200}
      title="Домашка сдана после дедлайна по уважительной причине"
      description={message}
      style={{
        marginBottom: 10,
      }}
      textStyle={{ whiteSpace: "pre-line", wordBreak: "break-word" }}
    />
  );
};
