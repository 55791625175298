import { RawHTML } from "components";
import { observer } from "mobx-react";
import { Whisper } from "rsuite";
import { Rate, Panel, Tooltip } from "shared/ui";

interface ITaskQuestionProps {
  kim: number;
  difficulty: number;
  question: string;
}

const TaskQuestionComponent: React.FC<ITaskQuestionProps> = ({
  difficulty,
  kim,
  question,
}) => {
  return (
    <div style={{ whiteSpace: "pre-line" }}>
      <Panel
        bordered
        header={
          <div
            style={{
              display: "flex",
              alignItems: "flex-start",
              justifyContent: "space-between",
            }}
          >
            <p style={{ marginBottom: 5, marginTop: 15, color: "#bbbbbb" }}>
              {`ким: ${kim || "доп."} `}
            </p>
            <div>
              <Whisper
                placement="auto"
                speaker={
                  <Tooltip>{`Сложность задания ${
                    difficulty || 0
                  } из 5`}</Tooltip>
                }
              >
                <Rate readOnly defaultValue={difficulty} size="xs" allowHalf />
              </Whisper>
            </div>
          </div>
        }
      >
        <RawHTML html={question} />
      </Panel>
    </div>
  );
};

export const TaskQuestion = observer(TaskQuestionComponent);
