import { emitter } from "./EventHelper";
import { GlobalConstants } from "./GlobalConstants";
import { IAnswerHomework } from "./requests";
import { localStorageWrapper } from "./wrappers";

interface IDraftAnswer {
  lessonId: number;
  homeworkId: number;
  taskpackId: number;
  answers: IAnswerHomework[];
}

export class StudentDraftAnswersStorage {
  private static key = `wavege_studentAnswers`;

  public static saveDraft = (
    answers: IAnswerHomework[],
    lessonId: number,
    homeworkId: number,
    taskpackId: number
  ) => {
    let data: IDraftAnswer[] = [];
    if (localStorageWrapper.has(this.key)) {
      data = (localStorageWrapper.get(this.key) as IDraftAnswer[]) || [];
    }
    const draftAnswers = data;
    const index = draftAnswers.findIndex(
      (item) =>
        item.lessonId === lessonId &&
        item.homeworkId === homeworkId &&
        item.taskpackId === taskpackId
    );

    if (index !== -1) {
      draftAnswers[index].answers = answers;
    } else {
      const draft: IDraftAnswer = {
        lessonId,
        homeworkId,
        taskpackId,
        answers,
      };
      draftAnswers.push(draft);
    }
    localStorageWrapper.set(this.key, draftAnswers);
    emitter.emit(GlobalConstants.eventDraftSaved);
  };

  public static removeDraft = (
    lessonId: number,
    homeworkId: number,
    taskpackId: number
  ) => {
    if (!localStorageWrapper.has(this.key)) {
      return;
    }

    const data = localStorageWrapper.get(this.key) as IDraftAnswer[];
    if (!data) {
      return [];
    }
    const filteredData = data.filter(
      (item) =>
        item.lessonId !== lessonId ||
        item.homeworkId !== homeworkId ||
        item.taskpackId !== taskpackId
    );

    localStorageWrapper.set(this.key, filteredData);
  };

  public static getDraftAnswers = (
    lessonId: number | undefined,
    homeworkId: number,
    taskpackId: number
  ): IAnswerHomework[] | [] => {
    if (!localStorageWrapper.has(this.key)) {
      return [];
    }

    const data = localStorageWrapper.get(this.key) as IDraftAnswer[];
    if (!data) {
      return [];
    }
    const existedDraft = data.find(
      (x) =>
        x.lessonId == lessonId &&
        x.homeworkId == homeworkId &&
        x.taskpackId == taskpackId
    );
    return existedDraft?.answers || [];
  };
}
