import { PlaceholderLessonItem } from "components";
import { ExpandableList } from "components/ExpandableList";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import {
  HomeworksCheckOrder,
  IRequestGetHomeworksCheckByFilter,
} from "root/requests";
import { HomeworkStudentStatus } from "stores";
import { CheckmarkPanel } from "../CheckmarkPanel";
import { HomeworkItem } from "./HomeworkItem";
import { LayoutPanelWithButton } from "../../Layout/LayoutPanelWithButton";
import { Button } from "shared/ui";

const HomeworksPanelComponent: React.FC = () => {
  const { homeworkStore, lessonsStore } = useStore();

  useEffect(() => {
    const filter: IRequestGetHomeworksCheckByFilter = {
      tariffId: null,
      lessonId: null,
      studentId: null,
      homeworkStatus: null,
      checkStatus: null,
      deadlineStatus: null,
      mentorId: null,
      teacherId: null,
      count: 0,
      orderBy: HomeworksCheckOrder.SubmitDeadlineAsc,
    };

    homeworkStore.loadByFilter(filter, { reloadDataPage: false });
  }, []);

  const homeworks = homeworkStore.homeWorksCheck.filter(
    (homework) => homework.homeworkStatus === HomeworkStudentStatus.notSend
  );

  return (
    <LayoutPanelWithButton
      title="Несданные домашки"
      button={
        <Button as={Link} to="/checks">
          Все домашки
        </Button>
      }
    >
      <ExpandableList
        data={homeworks}
        keyExtractor={({ tasksPackHwStudentId }) => tasksPackHwStudentId}
        renderItem={({ item }) => <HomeworkItem homework={item} />}
        listEmptyComponent={<CheckmarkPanel title="Все домашки сданы" />}
        isLoading={homeworkStore.onLoading || lessonsStore.onLoading}
        listLoadingComponent={<PlaceholderLessonItem items={4} />}
      />
    </LayoutPanelWithButton>
  );
};
export const HomeworksPanel = observer(HomeworksPanelComponent);
