import React from "react";
import { observer } from "mobx-react";
import { IAnswerHomework } from "root/requests";
import { ITask, TaskType } from "stores";
import { TaskViewFormControlFirstPart } from "./TaskViewFormControlFirstPart";
import { TaskViewFormControlSecondPart } from "./TaskViewFormControlSecondPart";
import { TaskViewFormControlFileAnswer } from "./TaskViewFormControlFileAnswer";

export interface ITaskViewFormControlsProps {
  answer: IAnswerHomework | undefined;
  task: ITask;
  onChange: (
    answer: any,
    taskId: number,
    taskType: TaskType,
    fileName?: string
  ) => void;
  onRemoveFile: (fileName: string, taskId: number) => void;
}
const TaskViewFormControlsComponent: React.FC<ITaskViewFormControlsProps> = (
  props
) => {
  switch (props.task.type) {
    case TaskType.FirstPart:
      return (
        <TaskViewFormControlFirstPart
          answer={props.answer?.answerPart1}
          task={props.task}
          onChange={props.onChange}
        />
      );
    case TaskType.SecondPart:
      return (
        <TaskViewFormControlSecondPart
          answer={props.answer?.answerPart2}
          task={props.task}
          onChange={props.onChange}
        />
      );
    case TaskType.FileAnswer:
      return (
        <TaskViewFormControlFileAnswer
          answer={props.answer?.answerFileList}
          task={props.task}
          onChange={props.onChange}
          onRemoveFile={props.onRemoveFile}
        />
      );

    default:
      return <></>;
  }
};
export const TaskViewFormControls = observer(TaskViewFormControlsComponent);
