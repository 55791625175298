import React, { PropsWithChildren } from "react";
import {
  ErrorBoundary as ReactErrorBoundary,
  FallbackProps,
} from "react-error-boundary";
import { notify } from "./helpers";

type TErrorBoundaryProps = {
  fallbackComponent: React.ComponentType<FallbackProps>;
};

export const ErrorBoundary: React.FC<
  PropsWithChildren<TErrorBoundaryProps>
> = ({ children, fallbackComponent }) => (
  <ReactErrorBoundary FallbackComponent={fallbackComponent} onError={notify}>
    {children}
  </ReactErrorBoundary>
);
