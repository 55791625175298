export class ExtensionHelper {
  /**
   * @description Проверяет являет ли объект по ссылке изображением формата **`pdf`**.
   *
   *@param fileUrl Ссылка на объект проверки.
   * @returns Вовращает **`true`** если ссылка на изображение,
   * **`false`**, если это не изображение или расширение не удалось распознать
   */
  public static isTxt(fileUrl: string) {
    return this.isExtensionFromList(fileUrl, ["txt"]);
  }
  /**
   * @description Проверяет являет ли объект по ссылке изображением формата **`pdf`**.
   *
   *@param fileUrl Ссылка на объект проверки.
   * @returns Вовращает **`true`** если ссылка на изображение,
   * **`false`**, если это не изображение или расширение не удалось распознать
   */
  public static isPdf(fileUrl: string) {
    return this.isExtensionFromList(fileUrl, ["pdf"]);
  }

  /**
   * @description Проверяет являет ли объект по ссылке изображением формата **`jpg, jpeg или png`**.
   *
   *@param fileUrl Ссылка на объект проверки.
   * @returns Вовращает **`true`** если ссылка на изображение,
   * **`false`**, если это не изображение или расширение не удалось распознать
   */
  public static isImage(fileUrl: string) {
    return this.isExtensionFromList(fileUrl, ["jpg", "jpeg", "png"]);
  }

  /**
   * @description Проверяет являет ли объект по ссылке запрашиваемым форматом.
   *
   *@param file_name Ссылка на объект проверки.
   *@param extensions Массив строковых значений для проверки расширения.
   * @returns Вовращает **`boolean`**
   */
  private static isExtensionFromList(
    file_name: string | undefined,
    extensions: string[]
  ): boolean {
    if (!file_name) {
      return false;
    }
    const match = file_name.toLowerCase().match(/\.([a-zA-Z]+)$/);
    if (match === null) {
      return false;
    }
    return extensions.includes(match[1]);
  }
}
