import {
  FileLessonType,
  IFormPromocode,
  IGlobalNotification,
  ILessonRequest,
  IPromotion,
  ITask,
  ITaskPackRequest,
  IUserRegistration,
  SaleType,
  TaskType,
} from "stores";
import { ValidationResult } from "stores/models/ValidationResult";
import { TextHelper } from "./TextHelpers";
import {
  IRequestPayTariff,
  IRequestSaveCheckHomework,
  IRequestTariff,
  IRequestUpdateStudentProfile,
} from "./requests";

export class ValidationHelper {
  public static lessonValidate(
    lessonRequest: ILessonRequest
  ): ValidationResult {
    const result = new ValidationResult();
    result
      .required(lessonRequest.title, "title", "Не заполнено название урока")
      ?.lengthLess(
        lessonRequest.title,
        30,
        "title",
        "Максимальная длина 30 символов"
      );
    result
      .lengthMore(
        lessonRequest.description,
        50,
        "description",
        "Минимальная длина 50 символов"
      )
      ?.lengthLess(
        lessonRequest.description,
        500,
        "description",
        "Максимальная длина 500 символов"
      );

    result.required(lessonRequest.tariffId, "tariffId", "Не выбран курс");
    result.required(
      lessonRequest.dateTime,
      "dateTime",
      "Не указана дата урока"
    );
    result.required(
      lessonRequest.lessonType,
      "lessonType",
      "Не указан тип урока"
    );

    if (
      lessonRequest.files?.find((x) => x.type === FileLessonType.WebinarImage)
        ?.fileId ||
      lessonRequest.files?.find((x) => x.type === FileLessonType.MainImage)
        ?.fileId ||
      lessonRequest.files?.find((x) => x.type === FileLessonType.Material)
        ?.fileId ||
      lessonRequest.videoUrl
    ) {
      result.required(
        lessonRequest.files?.find((x) => x.type === FileLessonType.MainImage)
          ?.fileId,
        "wallpaperLesson",
        "Не добавлена картинка урока"
      );

      result.required(
        lessonRequest.files?.find((x) => x.type === FileLessonType.WebinarImage)
          ?.fileId,
        "wallpaperWeb",
        "Не добавлен постер для вебчика"
      );
    }

    if (
      lessonRequest.checkHomeworkType ||
      lessonRequest.submitDeadline ||
      lessonRequest.checkDeadline ||
      (lessonRequest.homeWorks && lessonRequest.homeWorks.length !== 0)
    ) {
      result.required(
        lessonRequest.checkDeadline,
        "checkDeadline",
        "Не указан дд проверки"
      );

      result.required(
        lessonRequest.submitDeadline,
        "submitDeadline",
        "Не указан дд домашки"
      );
      result.required(
        lessonRequest.checkHomeworkType,
        "checkHomeworkType",
        "Не указан тип проверки"
      );
    }

    lessonRequest.homeWorks?.map((x, i) => {
      result
        .required(x.title, `title-${i}`, "Не указано название домашки")
        ?.lengthMore(x.title, 5, `title-${i}`, "Минимальная длина 5 символов")
        ?.lengthLess(
          x.title,
          30,
          `title-${i}`,
          "Максимальная длина 30 символов"
        );
    });
    lessonRequest.homeWorks?.map((x, i) => {
      x.packs?.map((p, k) => {
        result
          .required(
            p.btnTitle,
            `btnTitle-${i}-${k}`,
            "Не указано название кнопки"
          )
          ?.lengthLess(
            p.btnTitle,
            25,
            `btnTitle-${i}-${k}`,
            "Максимальная длина 25 символов"
          );
      });
    });
    const anonsFields = [
      "title",
      "description",
      "tariffId",
      "dateTime",
      "lessonType",
    ];

    const mediaFields = ["wallpaperLesson", "wallpaperWeb"];

    const homeworkFields = [
      "checkDeadline",
      "submitDeadline",
      "btnTitle-",
      "title-",
      "checkHomeworkType",
    ];

    if (anonsFields.some((x) => result.hasError(x))) {
      result.required(null, "anons", "");
    }

    if (mediaFields.some((x) => result.hasError(x))) {
      result.required(null, "media", "");
    }
    if (homeworkFields.some((x) => result.hasError(x))) {
      result.required(null, "homework", "");
    }
    return result;
  }

  public static taskValidate(task: ITask): ValidationResult {
    let result = new ValidationResult();
    if (task.type === TaskType.FirstPart) {
      result = this.taskFirstSecondValidate(task, result);
      result = this.taskQuestionValidate(task, result);
      result
        .required(task.answer, "answer", "Не указан ответ")
        ?.isInteger(task.answer, "answer", "Только числа");
    }

    if (task.type === TaskType.SecondPart) {
      result = this.taskFirstSecondValidate(task, result);
      result = this.taskQuestionValidate(task, result);
      const commentForReviewer = TextHelper.removeHtmlTags(
        task.commentForReviewer
      );
      result.lengthLess(
        commentForReviewer,
        2000,
        "commentForReviewer",
        "Максимальная длина 2000 символов"
      );
    }

    if (task.type === TaskType.FileAnswer) {
      result = this.taskQuestionValidate(task, result);
    }

    if (task.type === TaskType.FileQuestion) {
      result = this.taskQuestionValidate(task, result);
      result.required(
        task.fileQuestionId,
        "fileQuestionId",
        "Не прикреплен файл"
      );
    }

    result.required(task.type, "type", "Не указан тип задания");
    result.required(task.source, "source", "Не указан источник");
    result
      .required(task.themeId, "themeId", "Не указана тема")
      ?.min(task.themeId, 1, "themeId", "Не указана тема");
    result
      .required(task.maxScore, "maxScore", "Не указан максимальный балл")
      ?.min(task.maxScore, 1, "maxScore", "Минимальный балл 1");
    result.required(task.difficulty, "difficulty", "Не указана сложность");

    return result;
  }

  public static taskPackValidate(taskPack: ITaskPackRequest): ValidationResult {
    const result = new ValidationResult();
    result
      .required(taskPack.name, "name", "Не указано название варианта")
      ?.lengthLess(
        taskPack.name,
        150,
        "name",
        "Максимальная длина 150 символов"
      );
    return result;
  }

  public static homeworkCheckValidate(
    homeworkCheck: IRequestSaveCheckHomework
  ): ValidationResult {
    const result = new ValidationResult();

    homeworkCheck.answers?.map((x) => {
      result.required(x.score, `score-${x.taskId}`, "Не указаны баллы");
    });
    return result;
  }

  public static userNameValidate(
    user: IRequestUpdateStudentProfile,
    validation?: ValidationResult
  ): ValidationResult {
    const result = validation ? validation : new ValidationResult();
    const firstNameMessage = "Имя должно содержать от 2 до 50 символов";
    result
      .required(user.firstName, "firstName", firstNameMessage)
      ?.matches(
        user.firstName,
        /^[A-Za-zА-Яа-яЁё\s]+$/,
        "firstName",
        "Допускаются только буквы и пробелы"
      )
      ?.lengthMore(user.firstName, 2, "firstName", firstNameMessage)
      ?.lengthLess(user.firstName, 50, "firstName", firstNameMessage);
    //Отчество
    const middleNameMessage = "Отчество должно содержать от 2 до 50 символов";
    result
      .matches(
        user.middleName,
        /^[A-Za-zА-Яа-яЁё\s\-']+$/,
        "middleName",
        "Допускаются буквы, пробелы и дефис"
      )
      ?.lengthMore(user.middleName, 2, "middleName", middleNameMessage)
      ?.lengthLess(user.middleName, 50, "middleName", middleNameMessage);
    //Фамилия
    const lastNameMessage = "Фамилия должна содержать от 2 до 50 символов";
    result
      .required(user.lastName, "lastName", lastNameMessage)
      ?.matches(
        user.lastName,
        /^[A-Za-zА-Яа-яЁё\s\-']+$/,
        "lastName",
        "Допускаются буквы, пробелы и дефис"
      )
      ?.lengthMore(user.lastName, 2, "lastName", lastNameMessage)
      ?.lengthLess(user.lastName, 50, "lastName", lastNameMessage);

    return result;
  }

  private static userPasswordValidate(password: string): ValidationResult {
    const result = new ValidationResult();
    const passwordMessage =
      "Пароль недостаточно надежен. Попробуйте сочетание латинских букв (a-z) и цифр (0-9).";
    result
      .required(password, "password", "Не введен пароль")
      ?.lengthMore(password, 6, "password", "Минимальная длина 6 символов")
      ?.containsNumber(password, "password", passwordMessage)
      ?.noRussiaWord(password, "password", "Используйте латинские символы");

    return result;
  }

  private static taskFirstSecondValidate(
    task: ITask,
    result: ValidationResult
  ): ValidationResult {
    const keys = TextHelper.removeHtmlTags(task.keys);

    result.required(task.kim, "kim", "Не указан КИМ");
    result
      .required(keys, "keys", "Не указаны ключи")
      ?.lengthMore(keys, 10, "keys", "Минимальная длина 10 символов")
      ?.lengthLess(keys, 5000, "keys", "Максимальная длина 5000 символов");
    return result;
  }

  private static taskQuestionValidate(
    task: ITask,
    result: ValidationResult
  ): ValidationResult {
    const question = TextHelper.removeHtmlTags(task.question);

    result
      .required(question, "question", "Не заполнено задание")
      ?.lengthMore(question, 10, "question", "Минимальная длина 10 символов")
      ?.lengthLess(
        question,
        5000,
        "question",
        "Максимальная длина 5000 символов"
      );

    return result;
  }

  public static userPasswordChangeValidate(
    userPassword: IRequestUpdateStudentProfile
  ): ValidationResult {
    const result = this.userPasswordValidate(userPassword.password ?? "");
    result
      .required(
        userPassword.passwordConfirm,
        "passwordConfirm",
        "Не введено подтверждение пароля"
      )
      ?.mismatch(
        userPassword.password,
        userPassword.passwordConfirm,
        "passwordConfirm",
        "Пароли не совпадают"
      );

    return result;
  }

  public static registerValidate(user: IUserRegistration): ValidationResult {
    //Пароль
    let result = this.userPasswordValidate(user.password);
    //ФИО
    result = this.userNameValidate(user, result);
    //E-mail
    result.required(user.email, "email", "Не указан E-mail");
    //Телефон
    result.required(user.phone, "phone", "Не указан телефон");

    return result;
  }
  public static studentTariffPayValidate(
    studentTariffPay: Partial<IRequestPayTariff>
  ): ValidationResult {
    const result = new ValidationResult();

    result.required(
      studentTariffPay.amount,
      "amount",
      "Не указана сумма оплаты"
    );
    result.required(
      studentTariffPay.monthNumber,
      "monthNumber",
      "Не указан оплаченный месяц"
    );
    result.required(
      studentTariffPay.studentId,
      "studentId",
      "Не указан ученик"
    );
    result.required(studentTariffPay.tariffId, "tariffId", "Не указан курс");
    result.required(studentTariffPay.type, "type", "Не указан тип доступа");
    return result;
  }
  public static tariffValidate(
    tariffRequest: Partial<IRequestTariff>
  ): ValidationResult {
    const result = new ValidationResult();
    //Тип курса
    result.required(tariffRequest.type, "type", "Укажите тип курса");
    //Тип экзамена курса
    result.required(tariffRequest.examType, "examType", "Укажите тип экзамена");
    //Предмет курса
    result.required(tariffRequest.subjectId, "subjectId", "Укажите предмет");
    //Название курса
    result
      .required(tariffRequest.title, "title", "Укажите название курса")
      ?.lengthMore(
        tariffRequest.title,
        10,
        "title",
        "Минимальная длина 10 символов"
      )
      ?.lengthLess(
        tariffRequest.title,
        40,
        "title",
        "Максимальная длина 40 символов"
      );
    //Описание курса
    result
      .required(
        tariffRequest.description,
        "description",
        "Укажите описание курса"
      )
      ?.lengthMore(
        tariffRequest.description,
        40,
        "description",
        "Минимальная длина 40 символов"
      )
      ?.lengthLess(
        tariffRequest.description,
        500,
        "description",
        "Максимальная длина 500 символов"
      );
    //Картинка курса
    if (Array.isArray(tariffRequest.fileImageId)) {
      result.required(
        tariffRequest.fileImageId[0],
        "fileImageId",
        "Прикрепите картинку"
      );
    }
    result.required(
      tariffRequest.fileImageId || tariffRequest.imageUrl,
      "fileImageId",
      "Прикрепите картинку"
    );
    //Продожительность курса(бизнес)
    result
      .required(
        tariffRequest.durationInBusinessMonths,
        "durationInBusinessMonths",
        "Укажите продолжительность обучения"
      )
      ?.min(
        tariffRequest.durationInBusinessMonths,
        1,
        "durationInBusinessMonths",
        "Обучение не может длиться меньше 1"
      );
    //Количество основных уроков в месяц
    result
      .required(
        tariffRequest.lessonsPerMonth,
        "lessonsPerMonth",
        "Укажите количество уроков в месяц"
      )
      ?.min(tariffRequest.lessonsPerMonth, 1, "lessonsPerMonth", "Не меньше 1");
    //Общее количество уроков в месяц
    result
      .required(
        tariffRequest.countLessonsPerMonth,
        "countLessonsPerMonth",
        "Укажите количество уроков в месяц"
      )
      ?.min(
        tariffRequest.countLessonsPerMonth,
        1,
        "countLessonsPerMonth",
        "Не меньше 1"
      );
    //Общее количество домашек в месяц
    result
      .required(
        tariffRequest.countHomeworksPerMonth,
        "countHomeworksPerMonth",
        "Укажите количество уроков в месяц"
      )
      ?.min(
        tariffRequest.countHomeworksPerMonth,
        0,
        "countHomeworksPerMonth",
        "Не меньше 0"
      );
    //Дата начала курса
    result.required(
      tariffRequest.dateBegin,
      "dateBegin",
      "Укажите дату старта курса"
    );
    //Дата начала курса
    result.required(
      tariffRequest.dateEnd,
      "dateEnd",
      "Укажите дату окончания курса"
    );
    //Базовая стоимость курса в месяц
    result
      .required(
        tariffRequest.costPerMonth,
        "costPerMonth",
        "Укажите стоимость курса"
      )
      ?.min(tariffRequest.costPerMonth, 0, "costPerMonth", "Не меньше 0");
    //Стоимость при покупке курса целиком
    result
      .required(
        tariffRequest.costFull,
        "costFull",
        "Укажите стоимость курса целиком"
      )
      ?.min(tariffRequest.costFull, 0, "costFull", "Не меньше 0");
    const duration = tariffRequest.durationInBusinessMonths || 1;
    const costPerMonth = tariffRequest.costPerMonth || 1;
    const originalPrice = duration * costPerMonth;
    result.min(
      originalPrice,
      tariffRequest.costFull || 0,
      "costFull",
      "Курс целиком не может иметь стоимость выше, чем общая покупка всех месяцев"
    );
    //Скидка на покупку только "видеоуроков"
    result
      .required(
        tariffRequest.discountVideoLessonsPercent,
        "discountVideoLessonsPercent",
        "Укажите размер скидки на только 'видеоуроки'"
      )
      ?.min(
        tariffRequest.discountVideoLessonsPercent,
        0,
        "discountVideoLessonsPercent",
        "Не меньше 0"
      );
    return result;
  }
  public static promocodeValidate(
    promocodeRequest: IFormPromocode
  ): ValidationResult {
    const result = new ValidationResult();
    const isPercent = promocodeRequest.saleType === SaleType.Percent;
    // code: string;
    result
      .required(promocodeRequest.code, "code", "Задайте промокод")
      ?.lengthMore(
        promocodeRequest.code,
        5,
        "code",
        "Минимальная длина промокода 5 символов"
      )
      ?.lengthLess(
        promocodeRequest.code,
        25,
        "code",
        "Максимальная длина промокода 25 символов"
      );
    // saleType: SaleType;
    result.required(
      promocodeRequest.saleType,
      "saleType",
      "Укажите тип промокода"
    );
    // saleCost: number;
    result
      .required(promocodeRequest.saleCost, "saleCost", "Укажите размер скидки")
      ?.min(promocodeRequest.saleCost, 1, "saleCost", "Минимальная скидка 1")
      ?.max(
        promocodeRequest.saleCost,
        isPercent ? 100 : 5000,
        "saleCost",
        isPercent ? "Максимально 100%" : "Максимальная скидка 5000р"
      );
    // dateBegin: Date;
    result
      .required(
        promocodeRequest.dateBegin,
        "dateBegin",
        "Укажите дату начала действия промокода"
      )
      ?.dateBefore(
        promocodeRequest.dateBegin,
        promocodeRequest.dateEnd,
        "dateBegin",
        "Начало действия промокода должно быть позже окончания"
      );
    // dateEnd: Date;
    result
      .required(
        promocodeRequest.dateEnd,
        "dateEnd",
        "Укажите дату окончания действия промокода"
      )
      ?.dateAfter(
        promocodeRequest.dateEnd,
        promocodeRequest.dateBegin,
        "dateEnd",
        "Окончание действия промокода должно быть позже начала"
      );
    return result;
  }

  public static promotionValidate(
    promotionRequest: IPromotion
  ): ValidationResult {
    const result = new ValidationResult();
    //Название
    result
      .required(promotionRequest.title, "title", "Укажите название акции")
      ?.lengthMore(
        promotionRequest.title,
        5,
        "title",
        "Минимальная длина названия акции 5 символов"
      )
      ?.lengthLess(
        promotionRequest.title,
        44,
        "title",
        "Максимальная длина названия акции 25 символов"
      );
    //Описание
    result
      .required(
        promotionRequest.description,
        "description",
        "Добавьте описание для акции"
      )
      ?.lengthMore(
        promotionRequest.description,
        10,
        "description",
        "Минимальная длина описания акции 10 символов"
      )
      ?.lengthLess(
        promotionRequest.description,
        200,
        "description",
        "Максимальная длина описания акции 200 символов"
      );
    // Сумма или процент промокода (зависит от SaleType)
    result
      .required(promotionRequest.saleCost, "saleCost", "Укажите размер скидки")
      ?.min(promotionRequest.saleCost, 1, "saleCost", "Минимальная скидка 1");
    // Тип скидки (фикс или %)
    result.required(
      promotionRequest.saleType,
      "saleType",
      "Укажите тип скидки"
    );

    // dateBegin: Date;
    result
      .required(
        promotionRequest.dateRange.begin,
        "begin",
        "Укажите дату начала действия акции"
      )
      ?.dateBefore(
        promotionRequest.dateRange.begin,
        promotionRequest.dateRange.end,
        "begin",
        "Начало действия акции должно быть раньше окончания"
      );
    // dateEnd: Date;
    result
      .required(
        promotionRequest.dateRange.end,
        "end",
        "Укажите дату окончания действия акции"
      )
      ?.dateAfter(
        promotionRequest.dateRange.end,
        promotionRequest.dateRange.begin,
        "end",
        "Окончание действия акции должно быть позже начала"
      );
    // Правила применения
    // количество месяцев
    promotionRequest.rules.forEach((x, i) => {
      if (!x.isAnyTariff) {
        result.required(x.tariffId, `tariffId-${i}`, "Укажите тариф");
      }
      result
        .required(
          x.countMonths,
          `countMonths-${i}`,
          "Укажите количество месяцев"
        )
        ?.min(
          x.countMonths,
          1,
          `countMonths-${i}`,
          "Минимальное количество 1 месяц"
        );
    });

    return result;
  }

  public static globalNotificationValidate(
    globalNotification: Partial<IGlobalNotification>
  ): ValidationResult {
    const result = new ValidationResult();
    //Название
    result
      .required(globalNotification.title, "title", "Укажите название акции")
      ?.lengthMore(
        globalNotification.title,
        2,
        "title",
        "Минимальная длина названия акции 2 символов"
      )
      ?.lengthLess(
        globalNotification.title,
        50,
        "title",
        "Максимальная длина названия акции 50 символов"
      );
    //Описание
    result
      .required(
        globalNotification.description,
        "description",
        "Добавьте описание для акции"
      )
      ?.lengthMore(
        globalNotification.description,
        10,
        "description",
        "Минимальная длина описания акции 10 символов"
      )
      ?.lengthLess(
        globalNotification.description,
        500,
        "description",
        "Максимальная длина описания акции 500 символов"
      );
    // Тип уведомления
    result.required(globalNotification.type, "type", "Укажите тип уведомления");
    // Дата начала и окончания уведомления
    result.required(
      globalNotification.dateRange,
      "dateRange",
      "Укажите период уведомления"
    );
    return result;
  }
}
