import React from "react";
import { observer } from "mobx-react";
import { Stack } from "rsuite";
import { SlSocialVkontakte } from "react-icons/sl";
import { FaTelegramPlane } from "react-icons/fa";
import { SiTiktok, SiYoutube } from "react-icons/si";
import { IconButton, Avatar } from "shared/ui";

const TeacherInfoComponent: React.FC = () => {
  return (
    <div style={{ display: "flex" }}>
      <Avatar
        src={`${process.env.PUBLIC_URL}/images/socialStudiesTeacher.jpeg`}
      />
      <div style={{ marginLeft: 12 }}>
        <p
          style={{
            fontSize: 15,
            marginBottom: "-3px",
            whiteSpace: "nowrap",
          }}
        >
          Лера Толмачёва
        </p>
        <p style={{ color: "var(--rs-text-tertiary)", marginTop: 0 }}>
          твой преподаватель
        </p>
        <Stack spacing={6}>
          <IconButton
            icon={<SiTiktok />}
            size="xs"
            style={{ display: "flex" }}
            target="_blank"
            href="https://www.tiktok.com/@wavegeschool"
          />
          <IconButton
            icon={<SiYoutube />}
            size="xs"
            style={{ display: "flex" }}
            target="_blank"
            href="https://www.youtube.com/@wavege"
          />
          <IconButton
            icon={<FaTelegramPlane />}
            size="xs"
            style={{ display: "flex" }}
            target="_blank"
            href="https://t.me/wavegeschool"
          />
          <IconButton
            icon={<SlSocialVkontakte />}
            size="xs"
            style={{ display: "flex" }}
            target="_blank"
            href="https://vk.com/ltolmacheva99"
          />
        </Stack>
      </div>
    </div>
  );
};
export const TeacherInfo = observer(TeacherInfoComponent);
