import Api from "../root/Api";
import { AuthStore } from "./AuthStore";
import { CrudStore } from "./CrudStore";
import { IStudent } from "./models";
import { IRequestUpdateStudentProfile } from "root";

export class StudentsStore extends CrudStore<IStudent> {
  private authStore: AuthStore;
  constructor(authStore: AuthStore) {
    super(Api.students);
    this.authStore = authStore;
  }

  public async updateProfile(
    data: IRequestUpdateStudentProfile
  ): Promise<void> {
    this.setOnSaving(true);
    try {
      const userFullData = await Api.students.UpdateProfile(data);
      const studentData = this.authStore.myStudentData;
      if (!userFullData || !studentData) {
        return;
      }
      this.authStore.updateUser(userFullData, studentData);
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnSaving(false);
    }
  }
}
