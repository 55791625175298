import React, { CSSProperties } from "react";
import { Panel } from "shared/ui";

const defaultImgStyle: CSSProperties = {
  verticalAlign: "top",
  width: 52,
  borderRadius: 10,
  objectFit: "cover",
  height: 52,
  fontSize: "xx-small",
  display: "-webkit-box",
  overflow: "hidden",
  textOverflow: "ellipsis",
};

const titleStyle: CSSProperties = {
  overflow: "hidden",
  textOverflow: "ellipsis",
  display: "-webkit-box",
  WebkitLineClamp: 2,
  WebkitBoxOrient: "vertical",
  whiteSpace: "initial",
};

const buttonStyle: CSSProperties = {
  display: "flex",
  justifyContent: "flex-end ",
};

const gridStyle: CSSProperties = {
  gap: 10,
  display: "grid",
  gridTemplateColumns: "55px 1fr 115px",
  alignItems: "center",
  justifyContent: "space-between",
};

const defaultPanelStyle: CSSProperties = {
  marginBottom: 10,
  padding: 8,
};
interface ILayoutProps {
  button?: React.JSX.Element;
  title?: string;
  image: React.JSX.Element;
  containerStyle?: CSSProperties;
}
export const LayoutImagePanel: React.FC<ILayoutProps> = ({
  button,
  title,
  image,
  containerStyle,
}) => {
  const imageWithDefaultStyles = React.cloneElement(image, {
    style: { ...defaultImgStyle, ...image.props.style },
  });
  return (
    <Panel
      bordered
      bodyFill
      style={{ ...defaultPanelStyle, ...containerStyle }}
    >
      <div style={gridStyle}>
        <div>{imageWithDefaultStyles}</div>
        <div style={titleStyle}>{title}</div>
        <div style={buttonStyle}>{button}</div>
      </div>
    </Panel>
  );
};
