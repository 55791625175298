import { DeadlineBtnComponent } from "components";
import { useIsMounted, useStore } from "hooks";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { FlexboxGrid, Progress, Stack } from "rsuite";
import { TypeAttributes } from "rsuite/esm/@types/common";
import { Pill } from "shared/ui";
import {
  HomeworkStudentCheckStatus,
  HomeworkStudentStatus,
  IHomeworkResult,
  ILesson,
} from "stores";

interface IHeaderPanelProps {
  homeWorkResult: IHomeworkResult;
}

const HeaderPanelComponent: React.FC<IHeaderPanelProps> = (
  props: IHeaderPanelProps
) => {
  const isMounted = useIsMounted();
  const { lessonsStore, homeworkStore } = useStore();
  const { homeWorkResult } = props;
  const [lesson, setLesson] = useState<ILesson>();

  const [score, setScore] = useState(0);
  const [maxScore, setMaxScore] = useState(0);

  useEffect(() => {
    lessonsStore.findOrLoad(homeWorkResult.lessonId).then((lesson) => {
      if (!isMounted()) {
        return;
      }
      setLesson(lesson);
    });
  }, [homeWorkResult.lessonId]);

  useEffect(() => {
    if (!lesson || !homeworkStore.homeWorksCheck) {
      return;
    }

    const homeWorksByLesson = homeworkStore.homeWorksCheck.filter(
      (x) => x.lessonId === lesson.id
    );
    const [score, maxScore] = homeWorksByLesson.reduce(
      (accumulator, currentValue) => [
        accumulator[0] + currentValue.score,
        accumulator[1] + currentValue.maxScore,
      ],
      [0, 0]
    );
    setScore(score);
    setMaxScore(maxScore);
  }, [lesson, homeworkStore]);

  const progressHomeWorkSend: number = Math.round(
    (homeWorkResult.checks.filter(
      (x) => x.homeworkStatus !== HomeworkStudentStatus.notSend
    ).length /
      homeWorkResult.checks.length) *
      100
  );

  const progressHomeWorkCheck: number = Math.round(
    (homeWorkResult.checks.filter(
      (x) => x.checkStatus !== HomeworkStudentCheckStatus.notChecked
    ).length /
      homeWorkResult.checks.length) *
      100
  );

  const statusProgressHomeWork =
    progressHomeWorkSend === 100 ? "success" : "active";
  const colorProgressHomeWork =
    progressHomeWorkSend === 100 ? "#52c41a" : "#3385ff";

  let colorBtnProgress: TypeAttributes.Color | undefined = undefined;
  let titleBtnProgress = "";

  if (progressHomeWorkCheck === 100) {
    colorBtnProgress = "green";
    titleBtnProgress = `${score} баллов из ${maxScore}`;
  } else if (progressHomeWorkSend === 100) {
    colorBtnProgress = "orange";
    titleBtnProgress = "на проверке";
  }

  return (
    <FlexboxGrid style={{ display: "flex", alignItems: "center" }}>
      <FlexboxGrid.Item colspan={12}>
        <Stack spacing={10}>
          {lesson?.title}
          <DeadlineBtnComponent deadlineDate={lesson?.submitDeadline} />

          {(progressHomeWorkCheck === 100 || progressHomeWorkSend === 100) && (
            <Pill color={colorBtnProgress} size="sm">
              {titleBtnProgress}
            </Pill>
          )}
        </Stack>
      </FlexboxGrid.Item>
      <FlexboxGrid.Item
        colspan={8}
        style={{ display: "flex", alignItems: "baseline" }}
      >
        {progressHomeWorkSend !== 100 && (
          <Progress.Line
            percent={progressHomeWorkSend}
            strokeColor={colorProgressHomeWork}
            status={statusProgressHomeWork}
          />
        )}
      </FlexboxGrid.Item>
    </FlexboxGrid>
  );
};

export const HeaderPanel = observer(HeaderPanelComponent);
