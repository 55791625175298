import React, { PropsWithChildren } from "react";
import { YMInitializer } from "react-yandex-metrika";

export const YandexMetrikaProvider: React.FC<PropsWithChildren> = ({
  children,
}) => {
  const idYandexMetrika = 93125247;

  if (process.env.NODE_ENV === "development") {
    return <>{children}</>;
  }

  return (
    <>
      <YMInitializer
        accounts={[idYandexMetrika]}
        version="2"
        options={{ webvisor: true }}
      />
      {children}
    </>
  );
};
