import * as student from "../pages/Student";
import { IRoute } from "./IRoute";
import { GeneralRoutes } from "./GeneralRoutes";
import { GlobalConstants } from "root/GlobalConstants";

export const StudentRoutes = () => {
  const routes: IRoute[] = [];
  routes.push({
    hideTitle: true,
    title: "Главная",
    path: "*",
    element: <student.HomeCard />,
  });
  routes.push({
    hideTitle: true,
    title: "Главная",
    path: "/",
    element: <student.HomeCard />,
  });
  routes.push({
    hideTitle: true,
    title: "Главная",
    path: "/home",
    element: <student.HomeCard />,
  });
  routes.push({
    title: "Проверки",
    path: "/checks",
    element: <student.Status />,
  });
  routes.push(GeneralRoutes.Top);
  routes.push(GeneralRoutes.Schedule);
  routes.push({
    title: "Корзина",
    path: "/cart",
    element: <student.Cart />,
  });
  routes.push({
    title: "Уроки",
    path: "/lessons",
    element: <student.PageLessonList />,
  });
  routes.push(GeneralRoutes.SummaryByLesson);
  routes.push(GeneralRoutes.LessonsCard);
  routes.push(GeneralRoutes.LessonsCardWithHw);
  routes.push({
    title: "Успеваемость",
    path: "/dashboard",
    element: <student.PageDashboard />,
  });
  routes.push({
    title: "Рефералка",
    path: "/referal",
    element: <student.ReferalCard />,
  });
  routes.push({
    title: "Профиль",
    path: "/profile",
    element: <student.Account />,
  });
  routes.push({
    hideTitle: true,
    title: "Оплата отклонена",
    path: "/payment-result/fail",
    element: <student.FailPayment />,
  });
  routes.push({
    hideTitle: true,
    title: "Успешная оплата",
    path: "/payment-result/success",
    element: <student.SuccessPayment />,
  });
  routes.push({
    title: "Все курсы",
    path: "/catalog",
    element: <student.Catalog />,
  });
  routes.push({
    title: "Бесплатные курсы для подготовки к ЕГЭ и ОГЭ",
    path: `/catalog/${GlobalConstants.onlyFreeTariff}`,
    element: <student.Catalog />,
  });
  return routes;
};
