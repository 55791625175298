import { useEffect, useState } from "react";

const buttonToolBarStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};

export const useViewFileModal = (imgSrc: string, imgSrcList?: string[]) => {
  const [imgIndex, setImgIndex] = useState<number>();

  useEffect(() => {
    if (!imgSrcList?.length) {
      return;
    }
    const foundIndex = imgSrcList?.findIndex((x) => x === imgSrc);
    setImgIndex(foundIndex);
  }, [imgSrcList, imgSrc]);

  const switchRight = () =>
    setImgIndex((prev) => (prev !== undefined ? prev + 1 : 0));
  const switchLeft = () =>
    setImgIndex((prev) => (prev !== undefined ? prev - 1 : 0));

  const currentImgSrc =
    imgIndex !== undefined && imgSrcList ? imgSrcList[imgIndex] : imgSrc;

  const isRightButtonDisabled =
    imgSrcList && imgIndex === imgSrcList.length - 1;

  const isLeftButtonDisabled = imgIndex === 0;

  const hasButtonSwtich = imgSrcList?.length && imgSrcList?.length > 1;

  return {
    buttonToolBarStyle,
    hasButtonSwtich,
    isLeftButtonDisabled,
    isRightButtonDisabled,
    currentImgSrc,
    switchRight,
    switchLeft,
  };
};
