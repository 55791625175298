import { useState } from "react";
import { ILesson } from "stores";

export const useLessonsControlPanel = (lesson: ILesson) => {
  const [selectedLesson, setSelectedLesson] = useState<ILesson>(lesson);
  const handleOnChange = (currentLesson: ILesson) => {
    setSelectedLesson(currentLesson);
  };
  return { selectedLesson, handleOnChange };
};
