import { observer } from "mobx-react";
import React from "react";
import { Notification } from "shared/ui";
import { ILesson } from "stores";
import { usePaymentNotificationContent } from "./usePaymentNotificationContent";
import { Button } from "shared/ui";

type TPaymentNotificationContentProps = {
  lessonNotPayment: ILesson;
  onClick: () => void;
};

const PaymentNotificationContentComponent = React.forwardRef<
  HTMLDivElement,
  TPaymentNotificationContentProps
>(function PaymentNotificationContent({ lessonNotPayment, onClick }, ref) {
  const { firstName, tariffTitle } =
    usePaymentNotificationContent(lessonNotPayment);
  return (
    <Notification
      ref={ref}
      style={{ whiteSpace: "pre-wrap" }}
      type="info"
      header="Закончился доступ к курсу!"
      closable
    >
      {firstName && <p>{`${firstName}!`}</p>}
      {tariffTitle && <p>{`Закончился доступ к курсу "${tariffTitle}".`}</p>}

      <p>
        Спеши продлить курс уже сейчас! Продолжение курса поможет тебе глубже
        погрузиться в предмет и лучше усвоить новую информацию. 💡💪
      </p>
      <p>С наилучшими пожеланиями, команда поддержки Wavege School 🌊🌊</p>
      <br />
      <Button appearance="primary" onClick={onClick}>
        Продлить сейчас
      </Button>
    </Notification>
  );
});

export const PaymentNotificationContent = observer(
  PaymentNotificationContentComponent
);
