import { List } from "rsuite";
import { observer } from "mobx-react";
import { IHomeworkCheck } from "stores";
import { EmptyStatePanel } from "components";
import { useStore } from "hooks";
import { CheckItem } from "./CheckItem";
import { Scrollbars } from "react-custom-scrollbars-2";
import { CSSProperties } from "react";
import { PlaceholderLessonItem } from "./PlaceholderLessonItem";

interface IProps {
  checks: IHomeworkCheck[] | undefined;
  style?: CSSProperties;
  countChecks: number;
}
const CheckListComponent: React.FC<IProps> = ({
  checks,
  countChecks,
  style = {},
}) => {
  const { homeworkStore } = useStore();

  if (homeworkStore.onLoading) {
    return <PlaceholderLessonItem items={countChecks} style={style} />;
  }

  if (!checks || checks.length === 0) {
    return <EmptyStatePanel style={style} />;
  }

  return (
    <List hover bordered style={style}>
      <Scrollbars>
        {checks.map((check) => (
          <CheckItem key={check.tasksPackHwStudentId} check={check} />
        ))}
      </Scrollbars>
    </List>
  );
};
export const CheckList = observer(CheckListComponent);
