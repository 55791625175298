import { SelectPickerStudents } from "components/FormComponents/SelectPickerStudents";
import { SelectPickerTariffId } from "components/FormComponents/SelectPickerTariffId";
import { IRequestGetPaymentsByFilter } from "root/requests";
import { Stack } from "rsuite";
import { SelectPickerOrderPaymentStatus } from "./SelectPickerOrderPaymentStatus";

type TStudentTariffFilterProps = {
  onChange: (studentTariffsFilter: IRequestGetPaymentsByFilter) => void;
  value: IRequestGetPaymentsByFilter;
};

export const HistoryOrdersFilter: React.FC<TStudentTariffFilterProps> = ({
  value,
  onChange,
}) => (
  <Stack spacing={10} style={{ marginBottom: 15 }}>
    <SelectPickerTariffId
      defaultValue={value.tariffId}
      onChange={(tariffId) =>
        onChange({ ...value, tariffId: tariffId ?? undefined })
      }
    />
    <SelectPickerStudents
      defaultValue={value.studentId}
      onChange={(studentId) =>
        onChange({ ...value, studentId: studentId ?? undefined })
      }
    />
    <SelectPickerOrderPaymentStatus
      defaultValue={value.status}
      onChange={(status) => onChange({ ...value, status: status ?? undefined })}
    />
  </Stack>
);
