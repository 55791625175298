import { RawHTML } from "components/FormComponents";
import { observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
import { Button, Divider } from "shared/ui";
import { IEvent } from "stores";
import { useScheduleDrawer } from "../hooks/useScheduleDrawer";

interface IProps {
  event: IEvent;
}
const ScheduleDrawerItemComponent: React.FC<IProps> = ({ event }) => {
  const { backgroundColor, icon, lessonId, time, title } =
    useScheduleDrawer(event);
  return (
    <p style={{ padding: 3 }}>
      <div
        className="we-calendar-todo-list-home-item"
        style={{
          backgroundColor,
        }}
      >
        <div
          style={{
            display: "flex",
            alignItems: "center",
          }}
        >
          {icon}
          <span style={{ marginLeft: 8 }}>{time}</span>
        </div>
        <Divider />
        <span>{title}</span>
        <p>{<RawHTML html={event.description} />}</p>
        {lessonId && (
          <Button
            size="sm"
            block
            appearance="default"
            style={{ marginTop: 10, marginBottom: 5 }}
            as={Link}
            to={"/lessons/" + lessonId}
          >
            Перейти
          </Button>
        )}
      </div>
    </p>
  );
};

export const ScheduleDrawerItem = observer(ScheduleDrawerItemComponent);
