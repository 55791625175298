import React, { Ref, useMemo, useState } from "react";
import { Formik, FormikConfig, FormikProps, FormikValues } from "formik";
import { toFormikValidationSchema } from "zod-formik-adapter";
import { BaseForm, BaseFormProps } from "./BaseForm";
import FormPersister from "./components/FormPersister";
import FormOverlay from "./components/FormOverlay";
import { FormHelperContext, defaultPersistOptions } from "./constants";
import { DetectorFormUpdate } from "./components";
import { PersistOptions } from "./types";

export type FormProps<T extends FormikValues = FormikValues> = Omit<
  React.HTMLProps<HTMLFormElement>,
  "form" | "children" | "onChange"
> & {
  config: FormikConfig<T>;
  disableOverlay?: boolean;
  form?: Omit<BaseFormProps, "onSubmit">;
  children?: React.ComponentProps<typeof Formik<T>>["children"];
  isLoading?: boolean;
  onChange?: (formikProps: FormikProps<T>) => void;
  customRef?: Ref<FormikProps<T>>;
  //TODO: Улучшить работу persist:
  // добавить возможность исключать поля (например пароль из persist'a),
  // добавить возможность указания времени debounce.
  // добавить возможность указывать очищать стор после отправки формы
  persistOptions?: PersistOptions;
};

function Form<T extends FormikValues = FormikValues>({
  config,
  children,
  form,
  customRef,
  onChange = () => undefined,
  persistOptions = defaultPersistOptions,
  disableOverlay = false,
  isLoading = false,
}: FormProps<T>) {
  const [hasResetForm, setHasResetForm] = useState(false);

  const onSubmit: FormikConfig<T>["onSubmit"] = React.useCallback(
    (values, helpers) => {
      Promise.resolve(config.onSubmit(values, helpers)).finally(() =>
        helpers.setSubmitting(false)
      );
    },
    [config.onSubmit]
  );
  const validationSchema = useMemo(
    () =>
      config.validationSchema
        ? toFormikValidationSchema(config.validationSchema)
        : null,
    [config.validationSchema]
  );

  const onChangeForm = (formikProps: FormikProps<T>) => {
    setHasResetForm(false);
    onChange(formikProps);
  };

  const onResetForm = () => setHasResetForm(true);

  const contextProviderValue = useMemo(
    () => ({
      isSubmitting: isLoading,
      hasResetForm,
    }),
    [isLoading, hasResetForm]
  );

  return (
    <Formik<T>
      {...config}
      validationSchema={validationSchema}
      onSubmit={onSubmit}
      onReset={onResetForm}
      innerRef={customRef}
    >
      {(formikProps) => (
        <FormPersister
          initialValues={config.initialValues}
          options={persistOptions}
        >
          <BaseForm {...form} noValidate>
            {!disableOverlay && <FormOverlay isLoading={isLoading} />}
            <FormHelperContext.Provider value={contextProviderValue}>
              {typeof children === "function"
                ? children(formikProps)
                : children}
              <DetectorFormUpdate onChange={() => onChangeForm(formikProps)} />
            </FormHelperContext.Provider>
          </BaseForm>
        </FormPersister>
      )}
    </Formik>
  );
}
export default Form;
