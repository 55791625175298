import { observer } from "mobx-react";
import React from "react";
import {
  CheckHomeworkType,
  IAnswer,
  ILesson,
  ITaskPack,
  TaskType,
} from "stores";

import { RawHTML } from "components";
import { Panel, Pill } from "shared/ui";
import { InputAnswer } from "./component/InputAnswer";

export interface IHomeWorkSendedProps {
  lesson: ILesson;
  taskPackId: number;
  taskPack?: ITaskPack;
  homeWorkId: number;
  answers?: IAnswer[];
}
const HomeWorkSendedComponent: React.FC<IHomeWorkSendedProps> = (props) => {
  return (
    <>
      <div style={{ whiteSpace: "pre-line", paddingRight: 12 }}>
        {props.taskPack?.tasks.map((task) => {
          return (
            <Panel
              key={task.id}
              bodyFill
              style={{ margin: "10px 0px 25px 0px" }}
            >
              <Panel bordered>
                <RawHTML html={task?.question} />
                {task.type === TaskType.FileAnswer && (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "space-around",
                    }}
                  >
                    <Pill>файлы отправлены на проверку</Pill>
                  </div>
                )}
              </Panel>

              {task.type !== TaskType.FileAnswer &&
                task.type !== TaskType.FileQuestion && (
                  <Panel bordered style={{ marginTop: "8px" }}>
                    <p
                      className="p-answerTitle"
                      style={{ marginBottom: 5, color: "#bbbbbb" }}
                    >
                      твой ответ:
                    </p>
                    <InputAnswer
                      disabled
                      answer={props.answers?.find((x) => x.taskId === task.id)}
                      homeWorkId={props.homeWorkId}
                      task={task}
                    />
                    {props.lesson?.checkHomeworkType ===
                      CheckHomeworkType.Auto &&
                      !!task?.keys && (
                        <>
                          <p style={{ marginTop: 20, color: "#bbbbbb" }}>
                            пояснения к заданию:
                          </p>
                          <RawHTML html={task?.keys} />
                        </>
                      )}
                  </Panel>
                )}
            </Panel>
          );
        })}
      </div>
    </>
  );
};
export const HomeWorkSended = observer(HomeWorkSendedComponent);
