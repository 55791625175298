import { store } from "App";
import { toaster } from "rsuite";
import { authStore } from "stores/AuthStore";

export class logOutHelper {
  /**
   * @function функция для очистки store, mobx, session, query кэш клиента
   * @param вызов этой функции внутри стора роняет тесты
   */
  public static logout() {
    authStore.logout();
    store.clear();
    toaster.clear();
    localStorage.clear();
    sessionStorage.clear();
  }
}
