export const badgeStyle: React.CSSProperties = {
  position: "absolute",
  top: 0,
  right: 0,
  borderRadius: 8,
  backgroundColor: "red",
  padding: "0 5px",
  transform: "translate(50%,-50%)",
  lineHeight: "16px",
  fontSize: 12,
  color: "#fff",
  cursor: "default",
};

export const emptyBadgeStyle = {
  height: 8,
  width: 8,
  padding: 0,
};

export const unionStyle: React.CSSProperties = { position: "relative" };
