import { PageNavigator } from "components/Filter";
import { usePageFilter, useStore } from "hooks";
import { observer } from "mobx-react";
import React from "react";
import { GlobalConstants, IRequestGetTasksByFilter, TaskOrder } from "root";
import { Modal } from "rsuite";
import { ITask } from "stores";
import { FilterByTaskProperties } from "../component/FilterByTaskProperties";
import {
  ListType,
  TaskListWithButtonsForEditingTaskPack,
} from "../component/TaskListWithButtonsForEditingTaskPack";

interface IModalAddTaskProps {
  title: string;
  isOpen: boolean;
  onClose: () => void;
  taskPackId?: number;
}

const ModalAddTaskComponent: React.FC<IModalAddTaskProps> = (props) => {
  const { taskStore } = useStore();

  const initialFilter = {
    orderBy: TaskOrder.DifficultyAsc,
    count: 10,
    page: 1,
  };

  const { filter, dataPage, setLimit, setActivePage, onChangeFilter } =
    usePageFilter<ITask, IRequestGetTasksByFilter>(
      initialFilter,
      taskStore,
      GlobalConstants.filterLessons
    );

  return (
    <Modal size="lg" open={props.isOpen} onClose={() => props.onClose()}>
      <Modal.Header>
        <Modal.Title>Добавить задание в вариант № {props.title}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <FilterByTaskProperties
          onChange={onChangeFilter}
          defaultValue={filter}
        />
        <TaskListWithButtonsForEditingTaskPack
          taskPackId={props.taskPackId}
          tasks={dataPage?.data}
          listType={ListType.Add}
        />
      </Modal.Body>
      <Modal.Footer>
        <PageNavigator
          total={dataPage?.total}
          limit={filter.count || initialFilter.count}
          activePage={filter.page || initialFilter.page}
          onChangeLimit={setLimit}
          onChangeActivePage={setActivePage}
        />
      </Modal.Footer>
    </Modal>
  );
};

export const ModalAddTask = observer(ModalAddTaskComponent);
