import { useStore } from "hooks";
import { observer } from "mobx-react";
import React from "react";
import { HelpfulInformation } from "./HelpfulInformation/HelpfulInformation";
import { HomeworksPanel } from "./Reminder/UnfinishedHomework/HomeworksPanel";
import { LessonsPanel } from "./Reminder/UnwatchedWebinar/LessonsPanel";
import { ProgressClassUser } from "./ProgressClassUser";
import { ScheduleUser } from "./Schedule/ScheduleUser";
import { ToggleWebinarHomework } from "./Reminder/ToggleWebinarHomework";
import { WelcomeUser } from "./WelcomeUser";
import { Col, Grid, Row } from "shared/ui";

const HomeCardComponent: React.FC = () => {
  const { tariffStore } = useStore();

  return (
    <>
      <Grid fluid>
        <Row>
          {tariffStore.myTariffs && tariffStore.myTariffs.length > 0 ? (
            <>
              <Col xs={24} sm={24} md={15}>
                <WelcomeUser />
              </Col>
              <Col xs={24} sm={24} md={9} className="we-progress-panel">
                <ProgressClassUser />
              </Col>
            </>
          ) : (
            <Col xs={24}>
              <WelcomeUser />
            </Col>
          )}
        </Row>
        <Row>
          <Col xs={24} sm={24} md={15}>
            <ScheduleUser />
          </Col>
          <Col xs={24} sm={24} md={9} className="we-progress-panel">
            <HelpfulInformation />
          </Col>
        </Row>
        <Row>
          <Col xs={24} sm={24} mdHidden lgHidden xlHidden xxlHidden>
            <ToggleWebinarHomework />
          </Col>
        </Row>
        <Row>
          <Col xsHidden smHidden md={12}>
            <LessonsPanel />
          </Col>
          <Col xsHidden smHidden md={12}>
            <HomeworksPanel />
          </Col>
        </Row>
      </Grid>
    </>
  );
};
export const HomeCard = observer(HomeCardComponent);
