import { ISummary } from "stores";
import { emitter } from "./EventHelper";
import { localStorageWrapper } from "./wrappers";
import { GlobalConstants } from "./GlobalConstants";

export interface IDraftSummary {
  lessonId: number;
  studentId: number;
  summary: ISummary;
}

export class SaveSummaryDraftHelper {
  private static key = `wavege_student_summary`;

  public static saveDraft = (
    summary: ISummary,
    lessonId: number,
    studentId: number
  ) => {
    const draftAnswers =
      (localStorageWrapper.get(this.key) as IDraftSummary[]) || [];
    const index = draftAnswers.findIndex(
      (item) => item.lessonId === lessonId && item.studentId === studentId
    );

    if (index !== -1) {
      draftAnswers[index].summary = summary;
    } else {
      const draft: IDraftSummary = {
        lessonId,
        studentId,
        summary,
      };
      draftAnswers.push(draft);
    }
    localStorageWrapper.set(this.key, draftAnswers);
    emitter.emit(GlobalConstants.eventDraftSaved);
  };

  public static removeDraft = (lessonId: number, studentId: number) => {
    const data = localStorageWrapper.get(this.key) as IDraftSummary[];
    if (!data) {
      return;
    }
    const filteredData = data.filter(
      (item) => item.lessonId !== lessonId || item.studentId !== studentId
    );
    localStorageWrapper.set(this.key, filteredData);
  };

  public static getDraftAnswers = (
    lessonId: number | undefined,
    studentId: number
  ): ISummary | undefined => {
    const data = localStorageWrapper.get(this.key) as IDraftSummary[];
    if (!data) {
      return;
    }
    const existedDraft = data.find(
      (x) => x.lessonId == lessonId && x.studentId === studentId
    );
    return existedDraft?.summary || undefined;
  };
}
