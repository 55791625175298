import React from "react";
import { observer } from "mobx-react";

const costStyleCompleted: React.CSSProperties = {
  display: "flex",
  alignItems: "center",
  margin: "10px 0px",
  gap: 4,
};

const oldCostStyleCompleted: React.CSSProperties = {
  color: "var(--rs-text-tertiary)",
  textDecoration: "line-through",
  textDecorationColor: "red",
  textDecorationThickness: 2,
};

interface IFactsTariffProps {
  cost: number;
  oldCost?: number;
}

const CostPerMonthComponent: React.FC<IFactsTariffProps> = (props) => {
  return (
    <div style={costStyleCompleted}>
      <>
        <h4>{props.cost.toLocaleString("ru-RU")} ₽</h4>
        <span>/мес</span>
        {props.oldCost ? (
          <>
            <p style={oldCostStyleCompleted}>
              {props.oldCost.toLocaleString("ru-RU")} ₽
            </p>
            <span style={{ color: "var(--rs-text-tertiary)" }}>/мес</span>
          </>
        ) : null}
      </>
    </div>
  );
};
export const CostPerMonth = observer(CostPerMonthComponent);
