import { PlaceholderTaskPack } from "components/Placeholder/PlaceholderTaskPack";
import { useIsMounted, useStore } from "hooks";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { LogHelper } from "root";
import { IAnswer, ILesson, ITaskPack } from "stores";
import { HomeWorkSended } from "./HomeWorkSended";
import { HomeworkChecked } from "./component/HomeworkChecked";
import { TaskPackAnswers } from "./component/TaskPackAnswers";

export interface ITaskPackItemProps {
  answers?: IAnswer[];
  taskPackId: number;
  homeWorkId: number;
  lesson: ILesson;
}
const TaskPackItemComponent: React.FC<ITaskPackItemProps> = (
  props: ITaskPackItemProps
) => {
  const isMounted = useIsMounted();
  const { taskPackStore, lessonsStore } = useStore();
  const [selectedTaskPack, setSelectedTaskPack] = useState<
    ITaskPack | undefined
  >(undefined);
  const [isHomeWorkChecked, setIsHomeWorkChecked] = useState(false);
  const [isHomeWorkSended, setIsHomeWorkSended] = useState(false);
  const loadTaskPack = async () => {
    const taskPack = await taskPackStore.findOrLoad(props.taskPackId);
    if (!isMounted()) {
      return;
    }
    if (taskPack?.id === undefined) {
      LogHelper.Logging(
        "Повторите попытку позже",
        "С сервера был запрошен taskPack №" +
          props.taskPackId +
          ". Сервер вернул пустой taskPack."
      );
    } else {
      setSelectedTaskPack(taskPack);
    }
  };

  useEffect(() => {
    loadTaskPack();
  }, [props.taskPackId, props.homeWorkId]);

  useEffect(() => {
    const taskPack = lessonsStore.getHomeworkTaskPack(
      props.lesson.id,
      props.homeWorkId,
      props.taskPackId
    );
    setIsHomeWorkSended(taskPack?.isHomeWorkSended || false);
    setIsHomeWorkChecked(taskPack?.isHomeWorkChecked || false);
  }, [props.lesson, props.homeWorkId, props.taskPackId]);

  if (taskPackStore.onLoading) {
    return <PlaceholderTaskPack />;
  }

  if (isHomeWorkChecked) {
    return (
      <HomeworkChecked answers={props.answers} taskPack={selectedTaskPack} />
    );
  }

  if (isHomeWorkSended) {
    return (
      <HomeWorkSended
        taskPackId={props.taskPackId}
        lesson={props.lesson}
        taskPack={selectedTaskPack}
        homeWorkId={props.homeWorkId}
        answers={props.answers}
      />
    );
  }

  return (
    <>
      <TaskPackAnswers
        taskPackId={props.taskPackId}
        lesson={props.lesson}
        taskPack={selectedTaskPack}
        homeWorkId={props.homeWorkId}
      />
    </>
  );
};
export const TaskPackItem = observer(TaskPackItemComponent);
