import { useDocumentTitle } from "hooks";
import React, { PropsWithChildren } from "react";
import { GlobalConstants } from "root/GlobalConstants";

interface IContentContainerProps {
  title: string;
  hideTitle?: boolean;
}
export const ContentContainer: React.FC<
  PropsWithChildren<IContentContainerProps>
> = ({ title, children, hideTitle }) => {
  useDocumentTitle(`${title} ${GlobalConstants.postFixedBrowserTabTitle}`);

  return (
    <>
      {!hideTitle && <h4>{title}</h4>}
      <div style={{ marginTop: 10 }}>{children}</div>
    </>
  );
};
