import React from "react";
import { Modal } from "rsuite";
import "cropperjs/dist/cropper.css";
import { AvatarCropper } from "../Cropper/AvatarCropper";

interface ICropperModalProps {
  isOpen: boolean;
  onCancel: () => void;
}

export const CropperModal: React.FC<ICropperModalProps> = (props) => {
  return (
    <Modal open={props.isOpen} onClose={props.onCancel} size="md">
      <Modal.Header>
        <Modal.Title>Загрузка новой фотографии</Modal.Title>
      </Modal.Header>
      <hr />
      <Modal.Body>
        <AvatarCropper onSuccess={props.onCancel} />
      </Modal.Body>
      <hr />
      <Modal.Footer>
        <span style={{ textAlign: "center", fontSize: 12 }}></span> Если у вас
        возникают сложности с загрузкой, попробуйте выбрать фотографию меньшего
        размера.
      </Modal.Footer>
    </Modal>
  );
};
