import React from "react";
import { Modal } from "rsuite";
import { Button } from "shared/ui";

interface IConfirmModalProps {
  description: string;
  isOpen: boolean;
  isLoading: boolean;
  onSubmit: () => void;
  onCancel: () => void;
}

export const ConfirmModal: React.FC<IConfirmModalProps> = (props) => {
  return (
    <Modal open={props.isOpen} onClose={props.onCancel} size="sm">
      <Modal.Header>
        <Modal.Title>{"Подтверждение"}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div style={{ whiteSpace: "pre-wrap" }}>{props.description}</div>
      </Modal.Body>
      <Modal.Footer>
        <Button onClick={props.onCancel} appearance="subtle">
          Отмена
        </Button>
        <Button
          data-testId="confirm-modal-submit"
          loading={props.isLoading}
          disabled={props.isLoading}
          onClick={props.onSubmit}
          color="green"
          appearance="primary"
        >
          Да, я подтверждаю
        </Button>
      </Modal.Footer>
    </Modal>
  );
};
