import { useState } from "react";
import { Whisper } from "rsuite";
import { observer } from "mobx-react";
import PlusIcon from "@rsuite/icons/Plus";
import { ModalCreateEditLesson } from "./ModalCreateEditLesson";
import { IconButton, Tooltip } from "shared/ui";

const CreateLessonComponent: React.FC = () => {
  const [openModal, setOpenModal] = useState(false);

  return (
    <>
      <ModalCreateEditLesson
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      />
      <Whisper speaker={<Tooltip>Создать урок</Tooltip>}>
        <IconButton
          id="createLesson"
          icon={<PlusIcon />}
          size="sm"
          appearance="primary"
          color="orange"
          onClick={() => setOpenModal(true)}
        />
      </Whisper>
    </>
  );
};
export const CreateLesson = observer(CreateLessonComponent);
