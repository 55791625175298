import {
  ListType,
  TaskListWithButtonsForEditingTaskPack,
} from "components/BaseTask/component/TaskListWithButtonsForEditingTaskPack";
import { FormikConfig } from "formik";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import { Button } from "shared/ui";
import { Form } from "shared/ui/forms/form";
import { FInput } from "shared/ui/forms/input/FInput";
import { ITask } from "stores";
import { $CreateTaskPack, CreateTaskPack } from "./types";
import { initialValues } from "./constants";

const style = { form: { margin: 8 } };
type FormCreateTaskPackProps = {
  tasks: ITask[];
};

const FormCreateTaskPackComponent: React.FC<FormCreateTaskPackProps> = ({
  tasks,
}) => {
  const { taskStore, taskPackStore } = useStore();

  const cfg: FormikConfig<CreateTaskPack> = {
    initialValues,
    validationSchema: $CreateTaskPack,
    onSubmit: async (values) => {
      const idTasks = taskStore.tasksForCreateTaskPack.map((x) => x.id);

      if (await taskPackStore.createNewPack({ ...values, taskIds: idTasks })) {
        taskStore.clearTasksForCreateTaskPack();
      }
    },
  };

  return (
    <Form config={cfg} form={{ style: style.form }}>
      <FInput name="name" placeholder="Название варианта" />
      <TaskListWithButtonsForEditingTaskPack
        tasks={tasks}
        listType={ListType.Create}
      />
      <Button
        style={{ marginTop: 20 }}
        loading={taskPackStore.onUpdating}
        appearance="primary"
        type="submit"
      >
        Сформировать
      </Button>
    </Form>
  );
};
export const FormCreateTaskPack = observer(FormCreateTaskPackComponent);
