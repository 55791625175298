import axios, {
  AxiosError,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { emitter } from "./EventHelper";
import { NotificationHelper } from "./NotificationHelper";
import { GlobalConstants } from "./GlobalConstants";
import { authStore } from "stores/AuthStore";

type ErrorResponse = { message: string };

export const serverUrl =
  process.env.NODE_ENV === "development"
    ? "http://valerian999.ddns.net:61000" //"https://localhost:44309"
    : "";

const apiClient = axios.create({
  baseURL: serverUrl,
});

const headersNoCache = {
  "Cache-Control": "no-cache",
};

apiClient.interceptors.request.use(handleRequest, handleErrorRequest);

apiClient.interceptors.response.use(handleResponse, handleErrorResponse);

export async function get<T>(
  url: string,
  config?: { params?: any; refetch?: boolean }
): Promise<T | undefined> {
  const headers = config?.refetch ? headersNoCache : undefined;
  const configRequest = { params: config?.params, headers };
  return await apiClient.get(url, configRequest);
}

export async function post<T>(
  url: string,
  body: any = []
): Promise<T | undefined> {
  return await apiClient.post(url, body);
}

export async function put<T>(
  url: string,
  body: any = []
): Promise<T | undefined> {
  return await apiClient.put(url, body);
}

export async function del<T>(url: string): Promise<T | undefined> {
  return await apiClient.delete(url);
}

function handleErrorResponse(error: AxiosError<ErrorResponse>) {
  const status = error.response?.status;
  const message = error.response?.data.message ?? error.message;
  const isErrorNetwork = error.code === "ERR_NETWORK";

  if (isErrorNetwork) {
    NotificationHelper.ShowError(
      "Проверьте подключение к интернету",
      "Ошибка сети"
    );
    return Promise.reject(error);
  }

  if (status === 500) {
    NotificationHelper.ShowError("Произошла ошибка сервера, мы уже чиним");
    return Promise.reject(error);
  }

  if (status === 401) {
    emitter.emit(GlobalConstants.eventFailedAuth);
    return Promise.reject(error);
  }

  NotificationHelper.ShowError(message);
  return Promise.reject(error);
}

function handleErrorRequest(error: AxiosError) {
  const message =
    error.message ??
    `Произошел сбой при настройке запроса. ${(
      <br />
    )}Попробуйте снова или обратитесь в тех. поддержку.`;
  NotificationHelper.ShowError(message);
  return Promise.reject(error);
}

function handleRequest(config: InternalAxiosRequestConfig) {
  config.headers.Authorization = `Bearer ${authStore.me?.token}`;
  config.headers["Content-Type"] = config.url?.includes("file/upload")
    ? "multipart/form-data"
    : "application/json";
  return config;
}

function handleResponse(response: AxiosResponse) {
  return response.data;
}
