import { BigMessage } from "components";

type TReCheckCommentProps = {
  reCheckComment?: string;
};

export const ReCheckComment: React.FC<TReCheckCommentProps> = ({
  reCheckComment,
}) => {
  if (!reCheckComment || !reCheckComment.length) {
    return null;
  }

  return (
    <BigMessage
      type="info"
      maxLength={200}
      title="Работа пришла на перепроверку"
      description={reCheckComment}
      style={{
        marginBottom: 10,
      }}
      textStyle={{ whiteSpace: "pre-line", wordBreak: "break-word" }}
    />
  );
};
