import { makeAutoObservable, runInAction } from "mobx";
import Api from "../root/Api";
import { IStudentPerformance } from "./models";
import { IRequestGetStudentsTop } from "root/requests";
import { queryClient } from "provider/QueryProvider/QueryProvider";

export class StatisticStore {
  progressChart: IStudentPerformance[] = [];
  onLoading = false;
  onUpdating = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async getProgressChartData(studentId: number) {
    this.setOnLoading(true);
    try {
      const result = await queryClient.fetchQuery({
        queryKey: ["statistics.GetStudentPerformance", studentId],
        queryFn: async () =>
          await Api.statistics.GetStudentPerformance(studentId),
      });
      runInAction(() => result && (this.progressChart = result));
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
    return null;
  }

  public async loadByFilter(filter: IRequestGetStudentsTop, mentorId = 0) {
    this.setOnLoading(true);
    try {
      const result = await queryClient.fetchQuery({
        queryKey: ["statistics.GetStudentsTop", filter, mentorId],
        queryFn: async () =>
          await Api.statistics.GetStudentsTop(filter, mentorId),
      });
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
    return null;
  }

  public async getTopMonths(tariffId: number) {
    this.setOnLoading(true);
    try {
      const result = await queryClient.fetchQuery({
        queryKey: ["statistics.getTopMonths", tariffId],
        queryFn: async () => await Api.statistics.getTopMonths(tariffId),
      });
      return result;
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
    return null;
  }

  private setOnLoading(value: boolean) {
    this.onLoading = value;
  }
  public clear() {
    runInAction(() => {
      this.progressChart = [];
    });
  }
}
