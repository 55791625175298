import { Link } from "react-router-dom";
import {
  SuperRoutes,
  AdminRoutes,
  TeacherRoutes,
  MentorRoutes,
  StudentRoutes,
} from "routes";
import { AnonymousRoutes } from "routes/AnonymousRoutes";
import { IRoute } from "routes/IRoute";
import { Nav } from "rsuite";
import { RoleNames } from "stores";
import {
  AdminMenu,
  AnonymousMenu,
  IMenuItem,
  MentorMenu,
  StudentMenu,
  SuperMenu,
  TeacherMenu,
} from ".";

export const getMenu = (role?: RoleNames) => {
  let menuItems: IMenuItem[] = [];
  let routes: IRoute[] = [];
  switch (role) {
    case RoleNames.Super:
      routes = SuperRoutes();
      menuItems = SuperMenu();
      break;
    case RoleNames.Admin:
      routes = AdminRoutes();
      menuItems = AdminMenu();
      break;
    case RoleNames.Teacher:
      routes = TeacherRoutes();
      menuItems = TeacherMenu();
      break;
    case RoleNames.Mentor:
      routes = MentorRoutes();
      menuItems = MentorMenu();
      break;
    case RoleNames.Student:
      routes = StudentRoutes();
      menuItems = StudentMenu();
      break;
    default:
      routes = AnonymousRoutes();
      menuItems = AnonymousMenu();
      break;
  }
  if (process.env.NODE_ENV === "development") {
    validate(menuItems, routes);
  }
  return mapMenu(menuItems);
};

function validate(menuItems: IMenuItem[], routes: IRoute[]) {
  menuItems.forEach((menuItem) => {
    if (menuItem.subItems) {
      validate(menuItem.subItems, routes);
      return;
    }
    if (!routes.some((x) => x.path == menuItem.path)) {
      console.error(
        `В меню есть ссылка, но нет маршрута до ${menuItem.path}; Откроется страница по умолчанию`
      );
    }
    if (menuItems.filter((x) => x.path == menuItem.path).length > 1) {
      console.error(`В меню есть дубликат: ${menuItem.path};`);
    }
  });
}
function mapMenu(menuItems: IMenuItem[]) {
  return menuItems.map((item) => {
    if (!item.title || item.title === "") {
      console.error(`В меню есть элемент без заголовка: ${item.path};`);
      return;
    }
    if (item.subItems) {
      return (
        <Nav.Menu
          eventKey={item.path}
          key={item.path}
          trigger="hover"
          title={item.title}
          icon={item.icon}
          placement="rightStart"
        >
          {item.subItems.map((subItem) => (
            <Nav.Item
              style={{ display: "flex", alignItems: "center" }}
              eventKey={subItem.path}
              key={subItem.path}
              icon={subItem.icon}
              as={Link}
              to={subItem.path}
            >
              {subItem.title}
            </Nav.Item>
          ))}
        </Nav.Menu>
      );
    }
    return (
      <Nav.Item
        eventKey={item.path}
        key={item.path}
        icon={item.icon}
        as={Link}
        to={item.path}
      >
        {item.title}
      </Nav.Item>
    );
  });
}
