import { ITask } from "../../stores/models";
import { get, post } from "../WebHelper";
import { CrudController } from "./CrudController";
import * as requests from "../requests";
import { IPage } from "stores/models/IPage";

export class TaskController extends CrudController<ITask> {
  constructor() {
    super("Task");
  }

  public async AddFavorite(taskId: number) {
    await post(`/Task/favorite/add/` + taskId);
  }
  public async RemoveFavorite(taskId: number) {
    await post(`/Task/favorite/remove/` + taskId);
  }

  public async GetFavoriteForUser(): Promise<number[] | undefined> {
    return await get(`/Task/favorite/`);
  }

  public async GetTasksByFilter(
    data: requests.IRequestGetTasksByFilter
  ): Promise<IPage<ITask> | undefined> {
    return await post(`/Task/filter/`, data);
  }
}
