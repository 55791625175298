import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { mapEveryField } from "root/RawHelper";
import { Form, PanelGroup, RadioGroup } from "rsuite";
import { isBefore } from "rsuite/esm/utils/dateUtils";
import { CheckHomeworkType, IHomeWorkRequest, ILessonRequest } from "stores";
import { ValidationResult } from "stores/models/ValidationResult";
import { HomeWorkPick } from "./HomeWorkPick";
import { Button, DatePicker, Divider, Panel, Radio } from "shared/ui";

interface IHomeWorkListProps {
  lessonRequest: ILessonRequest;
  validationResult?: ValidationResult;
}

const HomeWorkListComponent: React.FC<IHomeWorkListProps> = (
  props: IHomeWorkListProps
) => {
  const [homeWorks, setHomeWorks] = useState<IHomeWorkRequest[]>(
    props.lessonRequest.homeWorks?.map((x) => {
      return { ...x };
    }) || []
  );
  useEffect(() => {
    props.lessonRequest.homeWorks = homeWorks;
  }, [homeWorks]);

  return (
    <>
      <Form
        fluid
        formValue={props.lessonRequest}
        onChange={(formValue: any) => {
          mapEveryField(props.lessonRequest, formValue, true);
        }}
      >
        <Form.Group controlId="submitDeadline">
          <Form.ControlLabel>Дд домашки</Form.ControlLabel>
          <Form.Control
            data-testid="submitDeadline"
            disabled={props.lessonRequest.dateTime ? false : true}
            shouldDisableDate={(date: any) =>
              date && props.lessonRequest.dateTime
                ? isBefore(date, props.lessonRequest.dateTime)
                : false
            }
            name="submitDeadline"
            accepter={DatePicker}
            errorMessage={props.validationResult?.getError("submitDeadline")}
            style={{ width: 260 }}
            placeholder="Дата и время"
            format="dd-MM-yyyy HH:mm"
          />
          <Form.ErrorMessage show={props.lessonRequest.dateTime ? false : true}>
            Укажите дату проведения урока
          </Form.ErrorMessage>
        </Form.Group>
        <Form.Group controlId="checkDeadline">
          <Form.ControlLabel>Дд проверки</Form.ControlLabel>
          <Form.Control
            data-testid="checkDeadline"
            name="checkDeadline"
            accepter={DatePicker}
            errorMessage={props.validationResult?.getError("checkDeadline")}
            style={{ width: 260 }}
            placeholder="Дата и время"
            format="dd-MM-yyyy HH:mm"
            disabled={props.lessonRequest.dateTime ? false : true}
            shouldDisableDate={(date: any) =>
              date && props.lessonRequest.submitDeadline
                ? isBefore(date, props.lessonRequest.submitDeadline)
                : true
            }
          />
          <Form.ErrorMessage show={props.lessonRequest.dateTime ? false : true}>
            Укажите дату проведения урока
          </Form.ErrorMessage>
        </Form.Group>
        <Form.Group controlId="checkHomeworkType">
          <Form.ControlLabel>
            Укажите тип проверки домашки в уроке
          </Form.ControlLabel>
          <Form.Control
            data-testid="checkHomeworkType"
            name="checkHomeworkType"
            accepter={RadioGroup}
            errorMessage={props.validationResult?.getError("checkHomeworkType")}
          >
            <Radio
              data-testid="checkHomeworkType-Manual"
              value={CheckHomeworkType.Manual}
            >
              Наставник+
            </Radio>
            <Form.HelpText>
              Домашнюю работу проверяют наставники и учителя
            </Form.HelpText>
            <Radio
              data-testid="checkHomeworkType-Auto"
              value={CheckHomeworkType.Auto}
            >
              Робокоп
            </Radio>
            <Form.HelpText>
              Ученик сразу получает правильные ответы для самопроверки
            </Form.HelpText>
          </Form.Control>
        </Form.Group>
      </Form>

      <PanelGroup accordion bordered style={{ marginTop: 25 }}>
        {homeWorks.map((homeWorkRequest, i) => {
          const validationResult = props.validationResult?.getSubResult(
            ["title", "btnTitle"],
            i
          );

          return (
            <>
              <Panel
                header={
                  <Divider
                    label={`Задание ${i + 1}`}
                    style={validationResult?.hasError() ? { color: "red" } : {}}
                  />
                }
                eventKey={i}
                id={homeWorkRequest.id}
                key={homeWorkRequest.id}
                data-testId={"homeWorkRequest-" + homeWorkRequest.id}
              >
                <HomeWorkPick
                  homeWorkRequest={homeWorkRequest}
                  validationResult={validationResult}
                />
              </Panel>
            </>
          );
        })}
      </PanelGroup>
      <Button
        data-testid="addHomeWork"
        style={{ marginTop: 20 }}
        onClick={() => {
          setHomeWorks([
            ...homeWorks,
            {
              title: "",
            },
          ]);
        }}
      >
        Добавить задание
      </Button>
    </>
  );
};
export const HomeWorkList = observer(HomeWorkListComponent);
