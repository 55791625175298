import { PropsWithChildren } from "react";
import { Col } from "shared/ui";

export const LessonCol: React.FC<PropsWithChildren> = ({ children }) => (
  <Col
    xxl={6}
    xl={8}
    lg={12}
    md={8}
    sm={12}
    xs={24}
    style={{ marginBottom: 20 }}
  >
    {children}
  </Col>
);
