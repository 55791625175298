import { IRoute } from "./IRoute";
import { AdminRoutes } from "./AdminRoutes";
import * as superAdmin from "../pages/AdminSuper";

export const SuperRoutes = () => {
  const routes: IRoute[] = [];
  routes.push({
    title: "Логи",
    path: "/logs",
    element: <superAdmin.LogsTable />,
  });
  routes.push({
    title: "Пользователи",
    path: "/users",
    element: <superAdmin.UsersTable />,
  });

  const adminRoutes = AdminRoutes();
  const superRoutes = routes;
  return adminRoutes.concat(superRoutes);
};
