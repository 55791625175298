import { RawHTML } from "components";
import { observer } from "mobx-react";
import { TextHelper } from "root/TextHelpers";
import { ITask } from "stores";

interface ICommentForReviewerProps {
  commentForReviewer: ITask["commentForReviewer"];
}

const CommentForReviewerComponent: React.FC<ICommentForReviewerProps> = ({
  commentForReviewer,
}) => {
  const hasCommentForReviewer =
    TextHelper.removeHtmlTags(commentForReviewer).length > 1;

  if (!hasCommentForReviewer) {
    return null;
  }

  return (
    <>
      <p
        style={{
          marginBottom: 5,
          marginTop: 15,
          color: "#bbbbbb",
        }}
      >
        пояснения для проверяющего:
      </p>
      <RawHTML html={commentForReviewer} />
    </>
  );
};

export const CommentForReviewer = observer(CommentForReviewerComponent);
