import { LogHelper } from "root/LogHelper";
import { authStore } from "stores/AuthStore";

export const notify = (
  error: Error,
  info: { componentStack?: string | null }
) => {
  LogHelper.LogFatalToBackend(
    error,
    authStore.me?.lastName || "unknown Person",
    info.componentStack
  );
};
