import { GlobalConstants, LinkHelper } from "root";
import { Button } from "shared/ui";

type TFailedMessageProps = { message?: string | null };

export const FailedMessage: React.FC<TFailedMessageProps> = ({ message }) => (
  <>
    <h5 style={{ marginBottom: 10, textAlign: "center" }}>
      Не получилось оплатить
    </h5>
    {message && <p style={{ textAlign: "center" }}>Ошибка: {message}</p>}

    <Button
      style={{ marginTop: 30 }}
      block
      onClick={() =>
        LinkHelper.openLink(GlobalConstants.telegramLinkToSupport, true)
      }
    >
      Тех. поддержка
    </Button>
    <p style={{ textAlign: "center", marginTop: 10 }}>
      Попробуйте провести оплату ещё раз. Либо обратитесь в тех. поддержку.
    </p>
  </>
);
