import React, { PropsWithChildren } from "react";
import { Panel as RSPanel } from "rsuite";
import { RefForwardingComponent } from "../types";
import { PanelProps } from "./types";

const panel = <E extends React.ElementType>(
  { children, ...props }: PropsWithChildren<PanelProps>,
  ref: React.ForwardedRef<E>
) => (
  <RSPanel {...props} ref={ref}>
    {children}
  </RSPanel>
);

export const Panel: RefForwardingComponent<"div", PanelProps> =
  React.forwardRef(panel);
