import { UIStore } from "./UIStore";
import { ExamStore } from "./ExamStore";
import { EventStore } from "./EventStore";
import { LessonsStore } from "./LessonsStore";
import { StudentsStore } from "./StudentsStore";
import { AchievementsStore } from "./AchievementsStore";
import { TariffStore } from "./TariffStore";
import { HomeworkStore } from "./HomeworkStore";
import { authStore, AuthStore } from "./AuthStore";
import { RoleNames } from "./models";
import { TaskStore } from "./TaskStore";
import { ThemaStore } from "./ThemaStore";
import { SubjectStore } from "./SubjectStore";
import { ThemaCategoryStore } from "./ThemaCategoryStore";
import { TaskPackStore } from "./TaskPackStore";
import { StudentTariffStore } from "./StudentTariffStore";
import { MentorStore } from "./MentorStore";
import { TeacherStore } from "./TeacherStore";
import { StatisticStore } from "./StatisticStore";
import { PromotionStore } from "./PromotionStore";
import { LogHelper } from "root";
import { PaymentStore } from "./PaymentStore";
import { PromocodeStore } from "./PromocodeStore";
import { RegTokenStore } from "./RegTokenStore";
import { GlobalNotificationStore } from "./GlobalNotificationStore";
import { UserStore } from "./UserStore";
import { NotificationStore } from "./NotificationStore";
import { queryClient } from "provider";

class RootStore {
  authStore: AuthStore;
  uiStore: UIStore;
  examStore: ExamStore;
  eventStore: EventStore;
  lessonsStore: LessonsStore;
  studentsStore: StudentsStore;
  achievementsStore: AchievementsStore;
  tariffStore: TariffStore;
  promotionStore: PromotionStore;
  promocodeStore: PromocodeStore;
  homeworkStore: HomeworkStore;
  taskStore: TaskStore;
  taskPackStore: TaskPackStore;
  themaStore: ThemaStore;
  subjectStore: SubjectStore;
  themaCategoryStore: ThemaCategoryStore;
  studentTariffStore: StudentTariffStore;
  mentorStore: MentorStore;
  teacherStore: TeacherStore;
  statisticStore: StatisticStore;
  paymentStore: PaymentStore;
  regTokenStore: RegTokenStore;
  globalNotificationStore: GlobalNotificationStore;
  notificationStore: NotificationStore;
  userStore: UserStore;

  constructor() {
    this.authStore = authStore;
    this.uiStore = new UIStore();
    this.userStore = new UserStore();
    this.examStore = new ExamStore();
    this.eventStore = new EventStore();
    this.lessonsStore = new LessonsStore();
    this.studentsStore = new StudentsStore(this.authStore);
    this.achievementsStore = new AchievementsStore();
    this.tariffStore = new TariffStore();
    this.promotionStore = new PromotionStore();
    this.promocodeStore = new PromocodeStore();
    this.paymentStore = new PaymentStore();
    this.homeworkStore = new HomeworkStore();
    this.taskStore = new TaskStore();
    this.taskPackStore = new TaskPackStore();
    this.themaStore = new ThemaStore();
    this.subjectStore = new SubjectStore();
    this.themaCategoryStore = new ThemaCategoryStore();
    this.studentTariffStore = new StudentTariffStore();
    this.mentorStore = new MentorStore();
    this.teacherStore = new TeacherStore();
    this.statisticStore = new StatisticStore();
    this.regTokenStore = new RegTokenStore();
    this.globalNotificationStore = new GlobalNotificationStore();
    this.notificationStore = new NotificationStore();
    this.authStore.setActionAfterAuth({
      action: () => {
        try {
          this.globalNotificationStore.load();
          this.tariffStore.load();
          this.subjectStore.load();
          this.studentsStore.load();
          this.mentorStore.load();
          this.teacherStore.load();
          if (this.authStore.me?.role !== RoleNames.Student) {
            this.themaCategoryStore.load();
            this.themaStore.load();
          } else {
            this.lessonsStore.load();
            this.homeworkStore.loadUnsendedHomework();
            this.tariffStore.loadMyTariffs();
            this.examStore.load();
            this.statisticStore.getProgressChartData(authStore.me?.id || 0);
          }
        } catch (error: any) {
          console.error(error);
          LogHelper.LogFatalToBackend(
            error,
            "",
            authStore.me?.lastName || "unknown Person"
          );
        }
      },
    });
  }

  public clear() {
    this.authStore.clear();
    this.userStore.clear();
    this.examStore.clear();
    this.eventStore.clear();
    this.lessonsStore.clear();
    this.studentsStore.clear();
    this.achievementsStore.clear();
    this.tariffStore.clear();
    this.promotionStore.clear();
    this.promocodeStore.clear();
    this.paymentStore.clear();
    this.homeworkStore.clear();
    this.taskStore.clear();
    this.taskPackStore.clear();
    this.themaStore.clear();
    this.subjectStore.clear();
    this.themaCategoryStore.clear();
    this.studentTariffStore.clear();
    this.mentorStore.clear();
    this.teacherStore.clear();
    this.statisticStore.clear();
    this.regTokenStore.clear();
    this.globalNotificationStore.clear();
    this.notificationStore.clear();
    queryClient.clear();
  }
}
export default RootStore;
