import { App } from "App";
import { createRoot } from "react-dom/client";
import "./index.css";

const container = document.getElementById("root");

// container! - не ошибка, разница типов в ts и react
// официальный ответ команды react:
// createRoot(container!) if you use TypeScript
const root = createRoot(container!);

root.render(<App />);
