import { Whisper } from "rsuite";
import { IStudentTariffWithPayments } from "stores";
import { useState } from "react";
import { PayModal } from "./PayModal";
import CreditCardPlusIcon from "@rsuite/icons/CreditCardPlus";
import { IconButton, Tooltip } from "shared/ui";

interface IButtonAddPayProps {
  studentTariff: IStudentTariffWithPayments;
}

export const ButtonAddPay: React.FC<IButtonAddPayProps> = (props) => {
  const [isOpenPayModal, setOpenPayModal] = useState<boolean>(false);
  return (
    <>
      <PayModal
        tariffId={props.studentTariff?.tariffId}
        studentId={props.studentTariff?.studentId}
        payments={props.studentTariff?.payments}
        isOpen={isOpenPayModal}
        onClose={() => setOpenPayModal(false)}
      />
      <Whisper
        placement="top"
        controlId="control-id-hover"
        trigger="hover"
        speaker={<Tooltip>Добавить оплату</Tooltip>}
      >
        <IconButton
          disabled={props.studentTariff.dateDelete !== null}
          onClick={() => {
            setOpenPayModal(true);
          }}
          icon={<CreditCardPlusIcon />}
          size="sm"
          color="green"
          appearance="primary"
        />
      </Whisper>
    </>
  );
};
