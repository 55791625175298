import React from "react";
import { observer } from "mobx-react";
import { Form } from "rsuite";
import {
  IAnswerHomework,
  IRequestAnswerHomework,
  IRequestSaveAnswersHomework,
} from "root/requests";
import { ILesson, ITaskPack, RoleNames, TaskType } from "stores";

import { RawHTML } from "components";
import { authStore } from "stores/AuthStore";
import { TaskViewFormControls } from "./TaskViewFormControls";
import { AnswerHomeworkHelper } from "root/AnswerHomeworkHelper";
import { Button, Panel } from "shared/ui";

export interface ITaskViewFormProps {
  selectedTaskId: number | undefined;
  answers?: IAnswerHomework[];
  taskPack?: ITaskPack;
  lesson: ILesson;
  homeWorkId: number;
  onChange: (
    answer: any,
    taskId: number,
    taskType: TaskType,
    fileName?: string
  ) => void;
  onSubmite: (request: IRequestSaveAnswersHomework) => void;
  onRemoveFile: (fileName: string, taskId: number) => void;
}
const TaskViewFormComponent: React.FC<ITaskViewFormProps> = (props) => {
  const handleOnSubmiteForm = async (
    event: React.FormEvent<HTMLFormElement>
  ) => {
    if (!props.taskPack) {
      return;
    }
    const formData = new FormData(event.currentTarget);
    const answers: IRequestAnswerHomework[] =
      AnswerHomeworkHelper.convertToRequestAnswerHomeworkList(
        formData,
        props.taskPack
      );
    const request: IRequestSaveAnswersHomework = {
      isDraft: false,
      homeworkId: props.homeWorkId,
      packId: props.taskPack.id,
      answers: answers,
    };
    props.onSubmite(request);
  };

  return (
    <Form
      fluid
      onSubmit={(_, event) => {
        handleOnSubmiteForm(event);
      }}
    >
      {props.taskPack?.tasks.map((task) => {
        const answer = props.answers?.find(
          (x) =>
            x.homeWorkId === props.homeWorkId &&
            x.taskPackId === props.taskPack?.id &&
            x.taskId === task.id
        );

        const styleAnswerBlock: React.CSSProperties =
          task.id === props.selectedTaskId
            ? { marginBottom: 25 }
            : { marginBottom: 25, display: "none" };
        return (
          <Panel key={task.id} bodyFill style={styleAnswerBlock}>
            <Panel bordered>
              <RawHTML html={task.question} />
            </Panel>
            <Panel bordered style={{ marginTop: "8px" }}>
              <TaskViewFormControls
                answer={answer}
                task={task}
                onChange={props.onChange}
                onRemoveFile={props.onRemoveFile}
              />
            </Panel>
          </Panel>
        );
      })}
      {authStore.me?.role == RoleNames.Student && (
        <Form.Group style={{ display: "flex", justifyContent: "flex-end" }}>
          <Button
            appearance={
              props.answers?.length === props.taskPack?.tasks.length
                ? "primary"
                : "default"
            }
            color="violet"
            type="submit"
          >
            на проверку
          </Button>
        </Form.Group>
      )}
    </Form>
  );
};
export const TaskViewForm = observer(TaskViewFormComponent);
