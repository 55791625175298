import { Cell } from "rsuite-table";
import { useStore } from "hooks";
import { IStudentTariffWithPayments } from "stores";
import { observer } from "mobx-react";

interface ITariffCellProps {
  rowData?: IStudentTariffWithPayments;
  dataKey: string;
}

const TariffCellComponent: React.FC<ITariffCellProps> = (props) => {
  const { tariffStore } = useStore();
  if (!props.rowData) {
    return <></>;
  }

  const tariffId = props.rowData.tariffId;
  const tariff = tariffStore.find(tariffId);
  return (
    <Cell
      {...props}
      className="link-group"
      style={{
        display: "flex",
        alignContent: "center",
        flexWrap: "wrap",
      }}
    >
      {tariff?.title || `${tariffId}. Кажется удален`}
    </Cell>
  );
};
export const TariffCell = observer(TariffCellComponent);
