import { makeAutoObservable, runInAction } from "mobx";
import Api from "../root/Api";
import { IExam } from "./models";

export class ExamStore {
  exams: IExam[] = [];
  onLoading = false;

  constructor() {
    makeAutoObservable(this);
  }

  public async load() {
    this.setOnLoading(true);
    try {
      const result = await Api.GetExamsForStudent();
      const data = result as IExam[];
      runInAction(() => {
        this.exams = data;
      });
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
    return null;
  }

  private setOnLoading(value: boolean) {
    this.onLoading = value;
  }
  public clear() {
    runInAction(() => {
      this.exams = [];
    });
  }
}
