import { Textarea } from "components/FormComponents/Textarea";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { mapEveryField } from "root/RawHelper";
import { CheckPicker, Form, Toggle } from "rsuite";
import { IHomeWorkRequest, IHomeWorkTaskPackRequest } from "stores";
import { ValidationResult } from "stores/models/ValidationResult";
import { HomeWorkPackEdit } from "./HomeWorkPackEdit";
import { Input } from "shared/ui";

interface IHomeWorkPickProps {
  homeWorkRequest: IHomeWorkRequest;
  validationResult?: ValidationResult;
}

const HomeWorkPickComponent: React.FC<IHomeWorkPickProps> = (
  props: IHomeWorkPickProps
) => {
  const { taskPackStore } = useStore();
  const [packs, setPacks] = useState<IHomeWorkTaskPackRequest[]>(
    props.homeWorkRequest.packs?.map((x) => {
      return { ...x };
    }) || []
  );

  useEffect(() => {
    props.homeWorkRequest.packs = packs;
  }, [packs]);

  useEffect(() => {
    taskPackStore.load();
  }, []);
  const selectData = taskPackStore.packs.map((item) => ({
    label: item.name,
    value: item.id,
  }));

  const changeTaskPacks = (packIds: number[]) => {
    const newPacks = packIds.map((taskPackId) => {
      return {
        id: taskPackId,
        name: taskPackStore.find(taskPackId)?.name,
        btnTitle: packs.find((x) => x.id == taskPackId)?.btnTitle || "",
        tasks: [],
      };
    });

    setPacks(newPacks);
  };

  const handleRemoveTaskPack = (id: number) => {
    const filteredPacks = packs.filter((x) => x.id !== id);
    setPacks(filteredPacks);
  };

  const onChange = (id: number, btnTitle: string) => {
    const pack = packs.find((x) => x.id == id);
    if (pack) {
      pack.btnTitle = btnTitle;
      setPacks([...packs]);
    }
  };

  return (
    <>
      <Form
        fluid
        formValue={props.homeWorkRequest}
        onChange={(formValue: any) => {
          mapEveryField(props.homeWorkRequest, formValue, true);
        }}
      >
        <Form.Group controlId="title">
          <Form.ControlLabel>Название</Form.ControlLabel>
          <Form.Control
            data-testid="HwTitle"
            name="title"
            accepter={Input}
            errorMessage={props.validationResult?.getError(`title`)}
          />
        </Form.Group>
        <Form.Group controlId="description">
          <Form.ControlLabel>Описание</Form.ControlLabel>
          <Form.Control
            data-testid="HwDescription"
            name="description"
            accepter={Textarea} />
        </Form.Group>
        <Form.Group controlId="disableForTop">
          <Form.Control
            data-testid="HwDisableForTop"
            name="disableForTop"
            accepter={Toggle}
            checkedChildren="Не учитывать в топе"
            unCheckedChildren="Учитывать в топе"
          />
        </Form.Group>
        <p style={{ marginBottom: 4 }}>Укажите вариант к заданию:</p>
        <CheckPicker
          data-testid="HwTaskPackSelect"
          loading={taskPackStore.onLoading}
          value={packs.map((x) => x.id)}
          data={selectData}
          style={{ width: 224 }}
          onChange={(value) => changeTaskPacks(value)}
        />
        {packs.map((taskPack, i) => {
          const validationResult = props.validationResult?.getSubResult(
            ["btnTitle"],
            i
          );
          return (
            <HomeWorkPackEdit
              validationResult={validationResult}
              key={taskPack.id}
              taskPack={taskPack}
              onRemoveTaskPack={handleRemoveTaskPack}
              onChangeTaskPack={onChange}
            />
          );
        })}
      </Form>
    </>
  );
};
export const HomeWorkPick = observer(HomeWorkPickComponent);
