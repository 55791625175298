import { ConfirmModal } from "components/Modal";
import { observer } from "mobx-react";
import React from "react";
import { useDropAnswersButton } from "../hooks";
import { Button } from "shared/ui";

const DropAnswersButtonComponent: React.FC = () => {
  const {
    isOpenConfirmModal,
    isRenderButton,
    isLoading,
    onSubmit,
    setOpenConfirmModal,
  } = useDropAnswersButton();

  if (!isRenderButton) {
    return null;
  }

  return (
    <>
      <ConfirmModal
        isOpen={isOpenConfirmModal}
        description={`Вы действительно хотите сбросить ответы ученика? \nРабота будет переведена в статус "не сдана"`}
        isLoading={isLoading}
        onCancel={() => setOpenConfirmModal(false)}
        onSubmit={onSubmit}
      />
      <Button
        appearance="primary"
        color="red"
        onClick={() => setOpenConfirmModal(true)}
      >
        Cбросить ответы
      </Button>
    </>
  );
};

export const DropAnswersButton = observer(DropAnswersButtonComponent);
