import { observer } from "mobx-react-lite";
import { PropsWithChildren } from "react";
import { Button, Panel } from "shared/ui";

export interface IErrorFallback {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
  withButton?: boolean;
}

const ErrorFallbackComponent: React.FC<PropsWithChildren<IErrorFallback>> = ({
  children,
  error,
  resetErrorBoundary,
  withButton = true,
}) => {
  const body = children ? (
    children
  ) : (
    <>
      <h1>Что то пошло не так:</h1>
      <pre>{error.message}</pre>
    </>
  );
  return (
    <Panel style={{ margin: 30 }}>
      {body}
      {withButton && (
        <Button onClick={resetErrorBoundary}>Попробовать еще раз</Button>
      )}
    </Panel>
  );
};
export const ErrorFallback = observer(ErrorFallbackComponent);
