import { EnumConverter } from "root/EnumConverter";
import { Cell } from "rsuite-table";
import { IGlobalNotification } from "stores";

interface IRoleGlobalNotificationCellProps {
  rowData?: IGlobalNotification;
  dataKey: string;
}

export const RoleGlobalNotificationCell: React.FC<
  IRoleGlobalNotificationCellProps
> = (props) => {
  const role = props.rowData?.forRole;

  if (!role) {
    return <Cell {...props}>—</Cell>;
  }

  return <Cell {...props}>{EnumConverter.RoleToStr(role)}</Cell>;
};
