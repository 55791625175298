import { useStore } from "hooks";
import { useMemo } from "react";
import { EventType } from "stores";

const maxRenderEvent = 3;
const minRenderEvent = 1;

export const useEvents = (
  date: Date,
  eventType: EventType | null,
  tariffId: number | null
) => {
  const { eventStore } = useStore();

  const currentEvents = useMemo(
    () =>
      eventStore
        .getEventList(date)
        ?.filter((x) => !eventType || x.type === eventType)
        .filter((y) => !tariffId || y.tariffId === tariffId),
    [date, eventType, tariffId]
  );

  const events = useMemo(() => {
    if (!currentEvents) {
      return;
    }
    const countRenderEvent =
      currentEvents.length > maxRenderEvent ? minRenderEvent : maxRenderEvent;
    return currentEvents?.slice(0, countRenderEvent);
  }, [currentEvents]);

  const leftEvents = useMemo(() => {
    const result =
      currentEvents && currentEvents.length > minRenderEvent
        ? currentEvents.length - minRenderEvent
        : 0;

    return result;
  }, [currentEvents]);

  return { events, leftEvents };
};
