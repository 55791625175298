import { CropperModal } from "components";
import { useIsMounted, useStore } from "hooks";
import _ from "lodash";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { FaEdit as FaEditIcon } from "react-icons/fa";
import { NotificationHelper, ValidationHelper } from "root";
import { Col, FlexboxGrid, Modal, Placeholder, Stack } from "rsuite";
import { IUserFullData, IUserWithContactDataDto } from "stores";
import { ValidationResult } from "stores/models/ValidationResult";
import EditAccountForm from "./EditNameStudentForm";
import EditPasswordStudentForm from "./EditPasswordStudentForm";
import { StudentData } from "./StudentData";
import { Grid, Button, IconButton, Panel, Row } from "shared/ui";
interface INameStudentRequestProps {
  firstName: string;
  middleName: string;
  lastName: string;
}
interface IPasswordStudentRequestProps {
  password: string;
  passwordConfirm: string;
}

interface IAccountComponentProps {
  studentId?: number;
}

const AccountComponent: React.FC<IAccountComponentProps> = (
  props: IAccountComponentProps
) => {
  const isMounted = useIsMounted();
  const { authStore, studentsStore } = useStore();

  const [openEditAccount, setOpenEditAccount] = useState(false);
  const [openEditPasswordStudent, setOpenEditPasswordStudent] = useState(false);
  const [openEditImgStudent, setOpenEditImgStudent] = useState(false);

  const [showButton, setShowButton] = useState(false);

  const [nameStudentRequest, setNameStudentRequest] =
    useState<INameStudentRequestProps>();
  const [passwordStudentRequest, setPasswordStudentRequest] =
    useState<IPasswordStudentRequestProps>();

  const [validationResult, setValidationResult] = useState<ValidationResult>();

  const [account, setAccount] = useState<
    IUserFullData | IUserWithContactDataDto
  >();

  useEffect(() => {
    if (props.studentId) {
      setAccount(studentsStore.find(props.studentId)?.user);
      return;
    }
    setAccount(authStore.me);
  }, [authStore.me, studentsStore.data]);

  const handleOpenEditAccount = () => setOpenEditAccount(true);

  const handleClose = () => {
    setOpenEditAccount(false);
    setOpenEditPasswordStudent(false);
    setOpenEditImgStudent(false);
    setPasswordStudentRequest(undefined);
    setNameStudentRequest(undefined);
  };

  if (!account) {
    return (
      <Panel bordered>
        <Placeholder.Paragraph rows={8} graph="image" active />
      </Panel>
    );
  }

  return (
    <>
      <div className="modal-container">
        <Modal open={openEditAccount} onClose={handleClose}>
          <Modal.Header>
            <Modal.Title>Редактирование профиля</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditAccountForm
              student={authStore.myStudentData}
              onChangeForm={setNameStudentRequest}
              validationResult={validationResult}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={!nameStudentRequest}
              loading={studentsStore.onSaving}
              appearance="primary"
              onClick={() => {
                if (!nameStudentRequest) {
                  return;
                }
                const resultValidation =
                  ValidationHelper.userNameValidate(nameStudentRequest);
                setValidationResult(resultValidation);
                if (resultValidation.hasNotError()) {
                  studentsStore
                    .updateProfile({
                      firstName: _.capitalize(
                        nameStudentRequest.firstName.trim()
                      ),
                      lastName: _.capitalize(
                        nameStudentRequest.lastName.trim()
                      ),
                      middleName: _.capitalize(
                        nameStudentRequest.middleName.trim()
                      ),
                    })
                    .then(() => {
                      NotificationHelper.ShowSuccess("Успешно сохранено!");
                      if (!isMounted()) {
                        return;
                      }
                      setNameStudentRequest(undefined);
                      handleClose();
                    });
                }
              }}
            >
              Сохранить
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Отмена
            </Button>
          </Modal.Footer>
        </Modal>
        <Modal open={openEditPasswordStudent} onClose={handleClose}>
          <Modal.Header>
            <Modal.Title>Смена пароля</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <EditPasswordStudentForm
              onChangeForm={setPasswordStudentRequest}
              validationResult={validationResult}
            />
          </Modal.Body>
          <Modal.Footer>
            <Button
              disabled={!passwordStudentRequest}
              loading={studentsStore.onSaving}
              appearance="primary"
              onClick={() => {
                if (!passwordStudentRequest) {
                  return;
                }
                const resultValidation =
                  ValidationHelper.userPasswordChangeValidate(
                    passwordStudentRequest
                  );
                setValidationResult(resultValidation);

                if (resultValidation.hasNotError()) {
                  studentsStore
                    .updateProfile(passwordStudentRequest)
                    .then(() => {
                      if (!isMounted) {
                        return;
                      }
                      NotificationHelper.ShowSuccess("Пароль изменен!");
                      setPasswordStudentRequest(undefined);
                      handleClose();
                    });
                }
              }}
            >
              Сохранить
            </Button>
            <Button onClick={handleClose} appearance="subtle">
              Отмена
            </Button>
          </Modal.Footer>
        </Modal>

        <CropperModal isOpen={openEditImgStudent} onCancel={handleClose} />
      </div>

      <Panel
        bordered
        header={
          <FlexboxGrid align-items="center" justify="space-between">
            <FlexboxGrid.Item colspan={12}>
              {`${account.firstName} ${account.middleName} ${account.lastName}`}
            </FlexboxGrid.Item>
            {!props.studentId && (
              <FlexboxGrid.Item as={Col} colspan={12} xsHidden>
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    onClick={handleOpenEditAccount}
                    appearance="primary"
                    icon={<FaEditIcon className="rs-icon" />}
                  >
                    Редактировать
                  </IconButton>
                </div>
              </FlexboxGrid.Item>
            )}
            {!props.studentId && (
              <FlexboxGrid.Item
                as={Col}
                colspan={12}
                xs={6}
                smHidden
                mdHidden
                lgHidden
                xlHidden
                xxlHidden
              >
                <div style={{ display: "flex", justifyContent: "flex-end" }}>
                  <IconButton
                    onClick={handleOpenEditAccount}
                    appearance="primary"
                    icon={<FaEditIcon className="rs-icon" />}
                  />
                </div>
              </FlexboxGrid.Item>
            )}
          </FlexboxGrid>
        }
        className="panel-margin"
      >
        <Grid fluid>
          <Row>
            <Col xs={24} sm={12} style={{ maxWidth: 300 }}>
              <div
                onMouseOver={() => setShowButton(true)}
                onMouseLeave={() => setShowButton(false)}
                style={{ display: "flex", justifyContent: "center" }}
              >
                {account.imageUrl ? (
                  <img
                    src={account.imageUrl}
                    style={{ width: 250, borderRadius: 12 }}
                  />
                ) : (
                  <Placeholder.Paragraph
                    rowHeight={10}
                    style={{ margin: 30 }}
                    graph="image"
                    active={true}
                    onClick={() => {
                      if (!props.studentId) {
                        setOpenEditImgStudent(true);
                      }
                    }}
                  />
                )}
                {showButton && !props.studentId && (
                  <Button
                    block
                    style={{
                      position: "absolute",
                      bottom: 0,
                    }}
                    onClick={() => {
                      setOpenEditImgStudent(true);
                      setShowButton(false);
                    }}
                  >
                    Изменить картинку
                  </Button>
                )}
              </div>
            </Col>
            <Col
              xs={24}
              sm={12}
              style={{
                paddingLeft: 15,
                maxWidth: 224,
              }}
            >
              <Stack spacing={12} direction="column">
                <StudentData label="Email" text={account.email} />
                <StudentData label="Телефон" text={account.phone} />
              </Stack>

              {!props.studentId && (
                <Button
                  style={{ marginTop: 24 }}
                  onClick={() => setOpenEditPasswordStudent(true)}
                >
                  Изменить пароль
                </Button>
              )}
            </Col>
          </Row>
        </Grid>
      </Panel>
    </>
  );
};

export const Account = observer(AccountComponent);
