import { ConfirmModal } from "components";
import { useDebouncedFunction, useStore } from "hooks";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import {
  AnswerHomeworkHelper,
  GlobalConstants,
  IAnswerHomework,
  IRequestSaveAnswersHomework,
  StudentDraftAnswersStorage,
} from "root";
import { emitter } from "root/EventHelper";
import { ILesson, ITask, ITaskPack, TaskType } from "stores";
import { TaskSwitch } from "./TaskSwitch";
import { TaskViewForm } from "./form/TaskViewForm";

export interface ITaskPackAnswersProps {
  lesson: ILesson;
  taskPackId: number;
  taskPack?: ITaskPack;
  homeWorkId: number;
}
const TaskPackAnswersComponent: React.FC<ITaskPackAnswersProps> = (
  props: ITaskPackAnswersProps
) => {
  const { homeworkStore, lessonsStore } = useStore();
  const { homeWorkId, taskPackId, lesson } = props;

  const [isOpenConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [taskId, setTaskId] = useState<number>();
  const [selectedTask, setSelectedTask] = useState<ITask>();
  const [answers, setAnswers] = useState<IAnswerHomework[]>([]);

  const [request, setRequest] = useState<IRequestSaveAnswersHomework>();

  const firstTaskFromPack = props.taskPack?.tasks[0];
  useEffect(() => {
    setSelectedTask(firstTaskFromPack);
    setTaskId(firstTaskFromPack?.id);
  }, [props.taskPack]);

  useEffect(() => {
    if (taskId && taskId > 0) {
      setSelectedTask(props.taskPack?.tasks.find((x) => x.id === taskId));
    }
  }, [taskId]);

  useEffect(() => {
    const hasLessonAnswers = answers?.some(
      (item) =>
        item.lessonId === lesson.id &&
        item.homeWorkId === homeWorkId &&
        item.taskPackId === taskPackId
    );
    if (hasLessonAnswers) {
      return;
    }

    const draftAnswers = StudentDraftAnswersStorage.getDraftAnswers(
      lesson.id,
      homeWorkId,
      taskPackId
    );
    setAnswers(draftAnswers);
  }, [props.homeWorkId, props.taskPackId, props.lesson.id]);

  const doSaveDraft = useDebouncedFunction(
    (answers: IAnswerHomework[]) =>
      StudentDraftAnswersStorage.saveDraft(
        answers,
        props.lesson.id,
        props.homeWorkId,
        props.taskPackId
      ),
    3_000
  );
  const saveAnswers = (answers: IAnswerHomework[]) => {
    emitter.emit(GlobalConstants.eventDraftSaving);
    setAnswers(answers);
    doSaveDraft(answers);
  };

  const handleInputAnswer = (
    result: unknown,
    taskId: number,
    taskType: TaskType
  ) => {
    if (taskType === TaskType.FileQuestion) {
      return;
    }
    const lessonId = lesson.id;
    const answerHomeworkList = AnswerHomeworkHelper.convertToAnswerHomeworkList(
      result,
      taskId,
      taskType,
      lessonId,
      taskPackId,
      homeWorkId,
      answers
    );
    if (!answerHomeworkList) {
      return;
    }
    saveAnswers(answerHomeworkList);
  };

  const handlePostAnswers = async () => {
    if (!request) {
      return;
    }

    if (await homeworkStore.saveAnswers(request)) {
      StudentDraftAnswersStorage.removeDraft(
        props.lesson.id,
        props.homeWorkId,
        props.taskPackId
      );
      setOpenConfirmModal(false);
      lessonsStore.refresh(props.lesson.id);
      homeworkStore.getAnswers(props.lesson.id, true);
    }
  };

  const handleSubmit = async (request: IRequestSaveAnswersHomework) => {
    setRequest(request);

    setOpenConfirmModal(true);
  };

  const handleRemoveFile = (fileName: string, taskId: number): void => {
    if (!answers) return;

    const updatedAnswers = answers.map((answer) => {
      if (
        answer.homeWorkId !== homeWorkId ||
        answer.taskPackId !== taskPackId ||
        answer.taskId !== taskId
      ) {
        return answer;
      }

      const updatedAnswerFileList = answer.answerFileList?.filter(
        (file) => file.fileName !== fileName
      );

      return {
        ...answer,
        answerFileList: updatedAnswerFileList,
      };
    });

    saveAnswers(updatedAnswers);
  };

  return (
    <>
      <ConfirmModal
        isOpen={isOpenConfirmModal}
        onSubmit={() => {
          handlePostAnswers();
        }}
        onCancel={() => {
          setOpenConfirmModal(false);
        }}
        isLoading={homeworkStore.onSaving}
        description="Действительно хочешь сдать домашку? Изменить ответы будет нельзя."
      />
      {props.taskPack?.tasks.length && props.taskPack?.tasks.length > 1 && (
        <TaskSwitch
          taskPack={props.taskPack}
          answers={answers}
          onChange={(id) => setTaskId(id)}
          taskId={taskId}
        />
      )}

      <TaskViewForm
        selectedTaskId={selectedTask?.id}
        answers={answers}
        taskPack={props.taskPack}
        lesson={props.lesson}
        homeWorkId={props.homeWorkId}
        onChange={handleInputAnswer}
        onSubmite={handleSubmit}
        onRemoveFile={handleRemoveFile}
      />
    </>
  );
};
export const TaskPackAnswers = observer(TaskPackAnswersComponent);
