import { useEffect, useState } from "react";
import { ButtonGroup, Dropdown, Whisper } from "rsuite";
import ArrowDownIcon from "@rsuite/icons/ArrowDown";
import { observer } from "mobx-react";
import { useIsMounted, useStore } from "../../../hooks";
import { Button, IconButton, Popover } from "shared/ui";

export interface IFilterProps {
  value?: number;
  tariffId: number | undefined;
  onChange: (month: number) => void;
}

const SelectMonthButtonComponent: React.FC<IFilterProps> = ({
  value: selectedMonth = 0,
  tariffId,
  onChange,
}) => {
  const isMounted = useIsMounted();
  const { statisticStore } = useStore();
  const [isLoading, setIsLoading] = useState(false);
  const [monthsNames, setMonthsNames] = useState<string[]>(["Все месяца"]);

  useEffect(() => {
    tariffId && loadTopMonthsByTariff(tariffId);
  }, [tariffId]);

  const loadTopMonthsByTariff = (tariffId: number) => {
    setIsLoading(true);
    statisticStore
      .getTopMonths(tariffId)
      .then(
        (month) =>
          isMounted() &&
          month &&
          setMonthsNames(["Все месяца"].concat(month.map((m) => `Месяц ${m}`)))
      )
      .finally(() => setIsLoading(false));
  };

  const isLoadingTop = statisticStore.onLoading;

  return (
    <ButtonGroup>
      <Button appearance="primary" color="cyan" loading={isLoading}>
        {monthsNames[selectedMonth]}
      </Button>
      <Whisper
        placement="bottomStart"
        trigger="click"
        speaker={({ onClose, left, top, className }, ref) => {
          const handleSelect = (eventKey: any) => {
            onClose();
            onChange(eventKey);
          };
          return (
            <Popover ref={ref} className={className} style={{ left, top }} full>
              <Dropdown.Menu onSelect={handleSelect}>
                {monthsNames.map((x, i) => {
                  return (
                    <Dropdown.Item disabled={isLoadingTop} key={x} eventKey={i}>
                      {x}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Popover>
          );
        }}
      >
        <IconButton
          appearance="primary"
          color="cyan"
          icon={<ArrowDownIcon />}
        />
      </Whisper>
    </ButtonGroup>
  );
};

export const SelectMonthButton = observer(SelectMonthButtonComponent);
