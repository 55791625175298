import { IStudentTariffWithPayments } from ".";

export enum RoleNames {
  "Student" = "Student",
  "Teacher" = "Teacher",
  "Admin" = "Admin",
  "Super" = "SuperAdmin",
  "Mentor" = "Mentor",
}

export enum Role {
  Student = 1,
  Teacher = 2,
  Admin = 3,
  Super = 4,
  Mentor = 5,
}

export interface IUser {
  id: number;
  imageUrl: string;
  firstName: string;
  middleName: string;
  lastName: string;
  role: RoleNames;
}

export interface IUserFullData extends IUser {
  // Почта
  email: string;
  // Почта подтверждена
  emailConfirmed: boolean;
  // Подключен телеграм
  enableTelegram: boolean;
  // Телефон
  phone: string;
  allowedGrants: string[];
  token: string;
}

export interface IUserWithContactDataDto extends IUser {
  // Мыло
  email: string;
  // Телефон (нет, не модель)
  phone: string;
}

export interface IUserRegistration {
  email: string;
  phone?: string;
  password: string;
  lastName: string;
  firstName: string;
  middleName?: string;
  token?: string;
}

export interface ITeacher {
  id: number;
  user: IUser;
  subjectId: number;
}

export interface IMentor {
  id: number;
  user: IUser;
  studentTariffs: IStudentTariffWithPayments[];
}

export type TStudentMonthNumbersVideoLessons = {
  monthNumber: number;
  tariffId: number;
};
