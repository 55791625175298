import ArrowDownIcon from "@rsuite/icons/ArrowDown";
import { observer } from "mobx-react";
import { useEffect, useState } from "react";
import { ButtonGroup, Dropdown, Whisper } from "rsuite";
import { ITariff, RoleNames } from "stores";
import { useStore } from "../../../hooks";
import { Button, IconButton, Popover } from "shared/ui";

type TSelectTariffButtonProps = {
  onChange: (tariffId: number) => void;
  selectedTariffId?: number;
};

const SelectTariffButtonComponent: React.FC<TSelectTariffButtonProps> = ({
  onChange,
  selectedTariffId,
}) => {
  const { tariffStore, authStore } = useStore();
  const [tariffs, setTariffs] = useState<ITariff[] | undefined>();

  useEffect(() => {
    if (authStore.me?.role !== RoleNames.Student) {
      setTariffs(tariffStore.data);
      return;
    }
    const enabledTariffs = tariffStore.getEnabledMyTariffs();
    setTariffs(enabledTariffs);
  }, [tariffStore.onLoading, tariffStore.data, tariffStore.myTariffs]);

  const buttonTitle =
    tariffs?.length === 0
      ? "Курс"
      : tariffs?.find((x) => x.id === selectedTariffId)?.title;

  if (authStore.me?.role === RoleNames.Student) {
    return null;
  }

  return (
    <ButtonGroup>
      <Button loading={!tariffs} appearance="primary" color="blue">
        {buttonTitle}
      </Button>
      <Whisper
        placement="bottomStart"
        trigger="click"
        speaker={({ onClose, left, top, className }, ref) => {
          const handleSelect = (eventKey: any) => {
            onClose();
            onChange(eventKey);
          };
          return (
            <Popover ref={ref} className={className} style={{ left, top }} full>
              <Dropdown.Menu onSelect={handleSelect}>
                {tariffs?.map((x) => {
                  return (
                    <Dropdown.Item key={x.id} eventKey={x.id}>
                      {x.title}
                    </Dropdown.Item>
                  );
                })}
              </Dropdown.Menu>
            </Popover>
          );
        }}
      >
        <IconButton
          appearance="primary"
          color="blue"
          icon={<ArrowDownIcon />}
        />
      </Whisper>
    </ButtonGroup>
  );
};

export const SelectTariffButton = observer(SelectTariffButtonComponent);
