import { useState } from "react";
import { TOrder } from "stores";

export const useHistoryOrdersTable = () => {
  const [expandedRowKeys, setExpandedRowKeys] = useState<string[]>([]);

  const handleExpanded = (rowData: TOrder) => {
    const isOpen = expandedRowKeys.some((key) => key === rowData.paymentId);
    const nextExpandedRowKeys = isOpen
      ? expandedRowKeys.filter((key) => key !== rowData.paymentId)
      : [...expandedRowKeys, rowData.paymentId];

    setExpandedRowKeys(nextExpandedRowKeys);
  };

  return { expandedRowKeys, handleExpanded };
};
