import React, { useState } from "react";
import { observer } from "mobx-react";
import { ButtonToolbar, Drawer, Table, Whisper } from "rsuite";
import VisibleIcon from "@rsuite/icons/Visible";
import TrashIcon from "@rsuite/icons/Trash";
import PlusIcon from "@rsuite/icons/Plus";
import { ITask } from "stores";
import { ConfirmModal } from "components/Modal";
import { useStore } from "hooks";
import { ModalAddTask } from "../ModalAddTask";
import { TaskView } from "components/BaseTask/component/TaskView";
import { IconButton, Tooltip } from "shared/ui";

interface IActionCellProps {
  rowData?: any;
  dataKey: string;
}

const ActionCellComponent: React.FC<IActionCellProps> = (props) => {
  const { taskPackStore } = useStore();

  const [titleModal, setTitleModal] = useState("");
  const [openModal, setOpenModal] = useState(false);
  const [openDetail, setOpenDetail] = useState(false);
  const [taskSelected, setTaskSelected] = useState<ITask>();
  const [isOpenConfirmModal, setOpenConfirmModal] = useState<boolean>(false);
  const [idTaskToDelete, setIdTaskToDelete] = useState(0);
  const [idTaskPackToDelete, setIdTaskPackToDelete] = useState(0);
  const [taskPack, setTaskPack] = useState<number>();

  const handleOnClose = () => {
    setOpenConfirmModal(false);
  };
  const handleRemoveTask = async () => {
    if (await taskPackStore.removeTask(idTaskToDelete, idTaskPackToDelete)) {
      handleOnClose();
    }
  };

  const { Cell } = Table;
  if (!props.rowData || props.rowData.id.toString().indexOf("_") < 0) {
    return <Cell {...props}></Cell>;
  }

  return (
    <>
      <ModalAddTask
        taskPackId={taskPack}
        title={titleModal}
        isOpen={openModal}
        onClose={() => setOpenModal(false)}
      />

      <Drawer open={openDetail} onClose={() => setOpenDetail(false)} size="md">
        <Drawer.Header>
          <Drawer.Title>Просмотр задания</Drawer.Title>
        </Drawer.Header>
        <Drawer.Body>
          <TaskView task={taskSelected} />
        </Drawer.Body>
      </Drawer>
      <ConfirmModal
        isOpen={isOpenConfirmModal}
        onSubmit={() => {
          handleRemoveTask();
        }}
        onCancel={() => {
          setOpenConfirmModal(false);
        }}
        isLoading={taskPackStore.onUpdating}
        description="Вы действительно хотите удалить задание из варианта?"
      />
      <Cell
        {...props}
        className="link-group"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexWrap: "wrap",
        }}
      >
        {props.rowData.id.toString().indexOf("_last_") < 0 ? (
          <ButtonToolbar>
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={<Tooltip>Подробно</Tooltip>}
            >
              <IconButton
                onClick={() => {
                  setTaskSelected(props.rowData);
                  setOpenDetail(true);
                }}
                icon={<VisibleIcon />}
                size="sm"
                color="blue"
                appearance="primary"
              />
            </Whisper>
            <Whisper
              placement="top"
              controlId="control-id-hover"
              trigger="hover"
              speaker={<Tooltip>Удалить из варианта</Tooltip>}
            >
              <IconButton
                onClick={() => {
                  setIdTaskToDelete(props.rowData.taskId);
                  setIdTaskPackToDelete(props.rowData.packId);

                  setOpenConfirmModal(true);
                }}
                icon={<TrashIcon />}
                size="sm"
                color="red"
                appearance="primary"
              />
            </Whisper>
          </ButtonToolbar>
        ) : (
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Добавить задание</Tooltip>}
          >
            <IconButton
              onClick={() => {
                setTitleModal(props.rowData.packId);
                setOpenModal(true);
                setTaskPack(props.rowData.packId);
              }}
              icon={<PlusIcon />}
              size="sm"
              color="green"
              appearance="primary"
            />
          </Whisper>
        )}
      </Cell>
    </>
  );
};

export const ActionCell = observer(ActionCellComponent);
