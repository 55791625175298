import { RawHTML, RichTextArea } from "components";
import { Panel } from "shared/ui";

type TInputCommentProps = {
  answerComment: string;
  disabledInput: boolean | undefined;
  onChange: (comment: string | undefined) => void;
};

export const InputComment: React.FC<TInputCommentProps> = ({
  disabledInput,
  answerComment,
  onChange,
}) => {
  if (disabledInput) {
    return (
      <Panel bordered>
        <RawHTML html={answerComment} />
      </Panel>
    );
  }

  return (
    <RichTextArea
      isPreview={false}
      defaultValue={answerComment}
      onChange={onChange}
    />
  );
};
