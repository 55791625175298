import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Link, useNavigate } from "react-router-dom";
import { ICart, ICartItem } from "stores";
import { useStore } from "hooks";
import { OrderDetails } from "./components/OrderDetails";
import { GlobalConstants } from "root";
import { ITariffMonthStatus } from "components";
import { useLocalStorage } from "hooks/useLocalSessionsStorage";
import { OrderItem } from "..";
import ArowBackIcon from "@rsuite/icons/ArowBack";
import { Col, Grid, Button, IconButton, Panel, Row } from "shared/ui";

const CartComponent: React.FC = () => {
  const { authStore, paymentStore, tariffStore } = useStore();
  const navigate = useNavigate();
  const [cartItems, setCartItems] = useState<ICartItem[]>([]);
  const [tariffsInCart, setTariffsInCart] = useLocalStorage<ICart>(
    GlobalConstants.wavegeCart,
    { promocode: "", items: [] }
  );

  useEffect(() => {
    if (authStore.me || tariffStore.data.length !== 0) {
      return;
    }
    tariffStore.load();
  }, []);

  useEffect(() => {
    if (!tariffStore.myTariffs || !tariffsInCart) {
      return;
    }

    const myTariffsId = tariffStore.myTariffs?.map((x) => x.tariff.id);
    const tariffsAvailableForOrder = tariffsInCart.items.filter(
      (x) => myTariffsId?.includes(x.tariffId) === false
    );

    setTariffsInCart((prev) => ({
      promocode: prev.promocode,
      items: tariffsAvailableForOrder,
    }));
  }, [tariffStore.myTariffs]);

  useEffect(() => {
    if (!tariffsInCart) {
      return;
    }
    setCartItems(tariffsInCart.items);
  }, [tariffsInCart]);

  const handleOnChange = (tariffMonth: ITariffMonthStatus) => {
    const foundTariff = tariffsInCart?.items.find(
      (x) => x.tariffId === tariffMonth.id
    );
    if (!foundTariff || !tariffsInCart) {
      return;
    }
    const selectedMonths = tariffMonth.monthStatus
      .filter((x) => x.isChecked)
      .map((x) => x.monthNumber);

    const updatedItems: ICartItem[] = tariffsInCart.items.map((x) => {
      if (x.tariffId === tariffMonth.id) {
        return { ...x, selectedMonth: selectedMonths };
      }
      return x;
    });
    setTariffsInCart((prev) => ({
      promocode: prev.promocode,
      items: updatedItems,
    }));
  };
  const handleOnRemove = (tariffId: number) => {
    if (!tariffsInCart) {
      return;
    }
    const filteredTariffsInCart = tariffsInCart.items.filter(
      (x) => x.tariffId !== tariffId
    );
    setTariffsInCart((prev) => ({
      promocode: prev.promocode,
      items: filteredTariffsInCart,
    }));
  };

  if (!cartItems || cartItems.length === 0) {
    return (
      <div
        style={{
          display: "grid",
          gridTemplateColumns: "1fr fit-content(20%)",
          columnGap: 48,
          marginTop: 32,
        }}
      >
        <div style={{ padding: 76, display: "flex", justifyContent: "center" }}>
          <Button appearance="primary" as={Link} to={"/catalog"}>
            Выбрать курс
          </Button>
        </div>
      </div>
    );
  }

  return (
    <>
      <div style={{ marginBottom: 12 }}>
        <IconButton
          icon={<ArowBackIcon />}
          size="xs"
          onClick={() => navigate(-1)}
        >
          Назад
        </IconButton>
      </div>
      <Grid fluid>
        <Row className="show-grid">
          <Col xxl={16} xl={16} lg={24} md={24} sm={24} xs={24}>
            {cartItems.map((x) => {
              return (
                <Panel bordered style={{ marginBottom: 10 }} key={x.tariffId}>
                  <OrderItem
                    сartItem={x}
                    isFastPay={false}
                    onChange={handleOnChange}
                    onLoading={paymentStore.onLoading}
                    onRemove={(tariffId) => handleOnRemove(tariffId)}
                  />
                </Panel>
              );
            })}
          </Col>
          <Col xxl={8} xl={8} lg={24} md={24} sm={24} xs={24}>
            <OrderDetails
              cartItems={tariffsInCart}
              onChangePromocode={(promocode) =>
                setTariffsInCart((prev) => ({ ...prev, promocode }))
              }
            />
          </Col>
        </Row>
      </Grid>
    </>
  );
};
export const Cart = observer(CartComponent);
