import React, { Suspense } from "react";
import ReactPlayer from "react-player/youtube";
import { Col, FlexboxGrid } from "rsuite";
import { Loader, Panel } from "shared/ui";
import { ILesson } from "stores";

export interface IWebinarItemProps {
  lesson: ILesson;
}

export const Player: React.FC<IWebinarItemProps> = ({ lesson }) => (
  <FlexboxGrid>
    <FlexboxGrid.Item as={Col} xs={24} sm={24} md={24} lg={24} xl={24} xxl={24}>
      <Panel
        bodyFill
        style={{
          borderRadius: 15,
          marginBottom: 20,
        }}
      >
        {lesson.videoUrl ? (
          <Suspense fallback={<Loader />}>
            <ReactPlayer
              playIcon={
                <img
                  src={`${process.env.PUBLIC_URL}/images/icon-play-button.png`}
                  alt="Воспроизвести"
                  style={{
                    position: "absolute",
                    height: 70,
                  }}
                />
              }
              light={
                <img
                  src={lesson.webinarImageUrl}
                  alt="Заставка вебинара"
                  style={{
                    objectFit: "cover",
                    width: "100%",
                    height: 360,
                    borderRadius: 15,
                  }}
                />
              }
              playing
              url={lesson.videoUrl}
              controls={true}
              width={"100%"}
              volume={0.5}
              controlsList="nodownload"
            />
          </Suspense>
        ) : (
          <img
            src={lesson.webinarImageUrl}
            alt="Заставка вебинара"
            style={{
              objectFit: "cover",
              width: "100%",
              height: 360,
              borderRadius: 15,
            }}
          />
        )}
      </Panel>
    </FlexboxGrid.Item>
  </FlexboxGrid>
);
