import React, { useEffect } from "react";
import { Container, Content, FlexboxGrid, Col } from "rsuite";
import { observer } from "mobx-react";
import { useLocation, useParams } from "react-router-dom";
import Api from "../../root/Api";
import { NotificationHelper } from "root";
import { LoginCard } from "./LoginCard";
import { Panel } from "shared/ui";

const LoginPageComponent: React.FC = () => {
  const { token } = useParams();

  const { search } = useLocation();
  const searchParams = new URLSearchParams(search);
  const registrationToken =
    searchParams.get("promo") || searchParams.get("registration") || undefined;

  useEffect(() => {
    if (registrationToken) {
      return;
    }

    //прим: 3eb0a4516b8a48d580e3e1daf67a2784
    if (token && token.length > 30) {
      Api.users
        .Confirm(token)
        .then(() => NotificationHelper.ShowSuccess("Ваш email подтвержден"));
    }
  }, []);

  return (
    <div
      className="show-fake-browser login-page "
      style={{ minHeight: 400, marginTop: 50 }}
    >
      <Container>
        <Content>
          <FlexboxGrid
            justify="center"
            align="middle"
            style={{ height: "85vh", paddingBottom: 150 }}
          >
            <FlexboxGrid.Item as={Col} xs={24} sm={15} md={12} lg={10} xl={9}>
              <Panel bordered>
                <LoginCard token={registrationToken} />
              </Panel>
            </FlexboxGrid.Item>
          </FlexboxGrid>
        </Content>
      </Container>
    </div>
  );
};
export const LoginPage = observer(LoginPageComponent);
