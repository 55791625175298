import React from "react";
import { DateHelper } from "root/DateHelper";
import { Pill } from "shared/ui";

interface IDeadlineBtnProps {
  deadlineDate?: Date;
}
//TODO пофиксить размеры при маленьком экране, текст не должен переходить на другую строчку
export const DeadlineBtnComponent: React.FC<IDeadlineBtnProps> = (props) => {
  const deadlineTitleBtn =
    "дедлайн " + DateHelper.getDateToStrLocaleShort(props.deadlineDate, true);
  return <Pill>{deadlineTitleBtn}</Pill>;
};
