import { Icon as RSIcon } from "@rsuite/icons";
import { IconProps } from "./types";
import React from "react";

const icon = (props: IconProps, ref: React.Ref<SVGElement>) => (
  <RSIcon {...props} ref={ref} />
);

export const Icon: React.ForwardRefExoticComponent<
  IconProps & React.RefAttributes<SVGElement>
> = React.forwardRef(icon);
