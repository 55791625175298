import React from "react";
import { Uploader } from "rsuite";
import { FileType } from "rsuite/esm/Uploader";
import Api from "../../root/Api";
import { authStore } from "../../stores/AuthStore";

interface IFileUploaderProps {
  onError?: () => void;
  onSuccess: (id: number, name: string) => void;
  onRemove: (name: string) => void;

  /** File types that can be accepted. See input accept Attribute */
  accept?: string;
  /** Automatically upload files after selecting them */
  autoUpload?: boolean;
  /** Primary content */
  children?: React.ReactNode;
  /** List of uploaded files */
  defaultFileList?: FileType[];
  /** List of uploaded files （Controlled） */
  fileList?: FileType[];
  /** Upload the parameters with */
  data?: any;
  /** Allow multiple file uploads to be selected at a time */
  multiple?: boolean;
  /** Disabled upload button */
  disabled?: boolean;
  /** Disabled file item */
  disabledFileItem?: boolean;
  /** Render the control as plain text */
  plaintext?: boolean;
  /** Make the control readonly */
  readOnly?: boolean;
  /** Set upload timeout */
  timeout?: number;
  /** Upload list Style */
  listType?: "text" | "picture-text" | "picture";
  /** Max file size limit of the preview file */
  maxPreviewFileSize?: number;
  /** Removable list file  */
  removable?: boolean;
  /** File list can be rendered */
  fileListVisible?: boolean;
  /** Supported Drag and drop upload **/
  draggable?: boolean;
  /** Allow the queue to be updated. After you select a file, update the checksum function before the upload file queue, and return false to not update */
  shouldQueueUpdate?: (
    fileList: FileType[],
    newFile: FileType[] | FileType
  ) => boolean | Promise<boolean>;
  /** Allow uploading of files. Check function before file upload, return false without uploading  */
  shouldUpload?: (file: FileType) => boolean | Promise<boolean>;
}
export const FileUploader: React.FC<IFileUploaderProps> = (props) => {
  const listType = props.listType ? props.listType : "picture-text";

  const handleOnError = (reason: any) => {
    console.error("FileUploader: ", reason);
    if (props.onError) {
      props.onError();
    }
  };

  const handleOnSuccess = (response: any, fileinfo: any) => {
    props.onSuccess(response.id, fileinfo.name);
  };

  const handleOnRemove = (fileinfo: any) => {
    props.onRemove(fileinfo.name);
  };

  return (
    <Uploader
      {...props}
      listType={listType}
      name="file"
      action={Api.uploadFileEndpoint}
      onError={handleOnError}
      onSuccess={handleOnSuccess}
      onRemove={handleOnRemove}
      headers={{
        Authorization: "Bearer " + authStore.me?.token,
      }}
    />
  );
};
