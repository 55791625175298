import React, { useState } from "react";
import { ButtonGroup, ButtonToolbar, Stack } from "rsuite";
import { HomeworksPanel } from "./UnfinishedHomework/HomeworksPanel";
import { LessonsPanel } from "./UnwatchedWebinar/LessonsPanel";
import { ToggleType } from "./ToggleType";
import { Button } from "shared/ui";

export const ToggleWebinarHomework: React.FC = () => {
  const [taskType, setTaskType] = useState(ToggleType.Webinar);

  const toggleTaskType = (type: ToggleType) => {
    setTaskType(type);
  };

  return (
    <>
      <Stack
        justifyContent="flex-end"
        style={{ paddingRight: 20, marginTop: 10 }}
      >
        <ButtonToolbar>
          <ButtonGroup>
            <Button
              active={taskType === ToggleType.Webinar}
              onClick={() => toggleTaskType(ToggleType.Webinar)}
            >
              вебчики
            </Button>
            <Button
              active={taskType === ToggleType.Homework}
              onClick={() => toggleTaskType(ToggleType.Homework)}
            >
              домашки
            </Button>
          </ButtonGroup>
        </ButtonToolbar>
      </Stack>
      {taskType === ToggleType.Webinar ? <LessonsPanel /> : <HomeworksPanel />}
    </>
  );
};
