import { observer } from "mobx-react";
import React from "react";
import { FailedAuthModal } from "./FailedAuthModal";
import {
  LeftSideHeader,
  NotificationView,
  RightSideHeader,
  TariffHandler,
} from "./components";
import { Col, Grid, Row } from "shared/ui";

type THeaderProps = {
  onClick: () => void;
};

const HeaderComponent: React.FC<THeaderProps> = ({ onClick }) => {
  return (
    <>
      <FailedAuthModal />
      <TariffHandler />
      <NotificationView />

      <Grid fluid className="we-header-container">
        <Row className="we-header-row">
          <Col lg={5} xl={5} xxl={5}>
            <LeftSideHeader onClick={onClick} />
          </Col>

          <Col xs={18} lg={18} xl={18} xxl={16}>
            <RightSideHeader />
          </Col>
        </Row>
      </Grid>
    </>
  );
};
export const Header = observer(HeaderComponent);
