import React, { PropsWithChildren } from "react";
import { DatePicker as RSDatePicker } from "rsuite";
import { RefForwardingComponent } from "../types";
import { DatePickerProps } from "./types";

const datePicker = <E extends React.ElementType>(
  { children, ...props }: PropsWithChildren<DatePickerProps>,
  ref: React.ForwardedRef<E>
) => (
  <RSDatePicker {...props} ref={ref}>
    {children}
  </RSDatePicker>
);

export const DatePicker: RefForwardingComponent<"div", DatePickerProps> =
  React.forwardRef(datePicker);
