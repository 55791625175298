import { observer } from "mobx-react";
import React from "react";
import { Link } from "react-router-dom";
import { Placeholder, Stack } from "rsuite";
import { useStudentHomeworkSwitch } from "../hooks";
import { StudentHomeworkSwitchButton } from "./StudentHomeworkSwitchButton";
import { Button } from "shared/ui";

const StudentHomeworkSwitchComponent: React.FC = () => {
  const { homeworksByLesson, isActive, linkTo, isLoading } =
    useStudentHomeworkSwitch();

  if (isLoading) {
    return (
      <Placeholder.Paragraph
        rows={1}
        rowHeight={32}
        rowMargin={0}
        style={{ maxWidth: 1000, marginBottom: 15 }}
        active
      />
    );
  }

  return (
    <Stack wrap spacing={8} alignItems="center" style={{ marginBottom: 15 }}>
      {homeworksByLesson.map((studentHomework) => {
        return (
          <StudentHomeworkSwitchButton
            key={studentHomework.tasksPackHwStudentId}
            studentHomework={studentHomework}
          />
        );
      })}
      <Button size="sm" as={Link} to={linkTo} active={isActive}>
        Вывод по домашке
      </Button>
    </Stack>
  );
};

export const StudentHomeworkSwitch = observer(StudentHomeworkSwitchComponent);
