import { observer } from "mobx-react";
import { SelectPicker, Stack } from "rsuite";
import { EnumConverter } from "root/EnumConverter";
import { GlobalNotificationType, Role } from "stores";
import { useEffect } from "react";
import { TextHelper } from "root/TextHelpers";
import { useSessionStorage } from "hooks/useLocalSessionsStorage";
import { GlobalConstants } from "root/GlobalConstants";
import { IGlobalNotificationFilter } from "./IGlobalNotificationFilter";
import { ButtonCreateGlobalNotification } from "./ButtonCreateGlobalNotification";
import { useStore } from "hooks";

const selectStyle: React.CSSProperties = {
  width: 224,
};

interface IFilterGlobalNotification {
  onChange: (filter: IGlobalNotificationFilter) => void;
}

const FilterGlobalNotificationComponent: React.FC<
  IFilterGlobalNotification
> = ({ onChange }) => {
  const { userStore } = useStore();
  const [filter, setFilter] = useSessionStorage<IGlobalNotificationFilter>(
    GlobalConstants.globalNotificationFilterForSessionStorage,
    {}
  );

  useEffect(() => {
    userStore.load();
  }, []);

  useEffect(() => {
    onChange(filter);
  }, [filter]);

  const notificationTypeList = EnumConverter.mapEnum(
    GlobalNotificationType,
    EnumConverter.globalNotificationTypeToStr
  );
  const roleList = EnumConverter.mapEnum(Role, EnumConverter.RoleToStr);
  const userList = userStore.users.map((x) => ({
    label: TextHelper.GetShortName(x),
    value: x.id,
  }));

  return (
    <Stack spacing={10} wrap style={{ marginBottom: 10 }}>
      <ButtonCreateGlobalNotification />
      <SelectPicker
        value={filter.type}
        label="Тип уведомления"
        data={notificationTypeList}
        style={selectStyle}
        onChange={(value) => {
          const type = value ? value : undefined;
          setFilter((prev) => ({ ...prev, type }));
        }}
      />
      <SelectPicker
        value={filter.forRole}
        label="Роль"
        data={roleList}
        style={selectStyle}
        onChange={(value) => {
          const forRole = value ? value : undefined;
          setFilter((prev) => ({ ...prev, forRole }));
        }}
      />
      <SelectPicker
        value={filter.userId}
        label="Пользователь"
        data={userList}
        style={{ width: 224 }}
        loading={userStore.onLoading}
        onChange={(value) => {
          const userId = value ? value : undefined;
          setFilter((prev) => ({ ...prev, userId }));
        }}
      />
    </Stack>
  );
};
export const FilterGlobalNotification = observer(
  FilterGlobalNotificationComponent
);
