import React, { useRef } from "react";
import { observer } from "mobx-react";
import { Whisper } from "rsuite";
import { MdCheckBoxOutlineBlank, MdCheckBox } from "react-icons/md";
import { AutoCheckIcon } from "components/Icon/AutoCheckIcon";
import { AutoCheckWhisper } from "components";
import { Button, Tooltip } from "shared/ui";

interface IStackTariffMonthButtonProps {
  isPaid: boolean;
  isAutoCheck: boolean;
  month: number;
  checked: boolean;
  onLoading: boolean;
  onClick: (month: number) => void;
}

const MonthButtonWithCheckboxComponent: React.FC<
  IStackTariffMonthButtonProps
> = (props: IStackTariffMonthButtonProps) => {
  const ref = useRef<HTMLButtonElement>(null);

  const handleOnClick = () => {
    props.onClick(props.month);
    ref.current?.blur();
  };

  const ButtonWithChecked = () => (
    <Button
      ref={ref}
      style={props.isPaid ? { pointerEvents: "none" } : {}}
      disabled={props.onLoading || props.isPaid}
      active={props.checked}
      startIcon={
        props.checked || props.isPaid ? (
          <MdCheckBox />
        ) : (
          <MdCheckBoxOutlineBlank />
        )
      }
      endIcon={props.isAutoCheck ? <AutoCheckIcon /> : <></>}
      size="sm"
      onClick={handleOnClick}
    >{`Месяц ${props.month}`}</Button>
  );

  if (props.isPaid) {
    return (
      <Whisper
        placement="top"
        controlId="control-id-hover"
        trigger="hover"
        speaker={<Tooltip>Месяц оплачен</Tooltip>}
      >
        <div>
          <ButtonWithChecked />
        </div>
      </Whisper>
    );
  }

  if (props.isAutoCheck) {
    return (
      <AutoCheckWhisper>
        <div>
          <ButtonWithChecked />
        </div>
      </AutoCheckWhisper>
    );
  }
  return <ButtonWithChecked />;
};
export const MonthButtonWithCheckbox = observer(
  MonthButtonWithCheckboxComponent
);
