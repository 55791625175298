import { useStore } from "hooks";

export const useRefetchTariff = () => {
  const { tariffStore, lessonsStore, eventStore, homeworkStore } = useStore();
  const refetchTariff = async (tariffId: number) => {
    await tariffStore.loadMyTariffs(true);
    await tariffStore.setSelectedTariff(tariffId);
    await lessonsStore.load(true);
    await eventStore.load(new Date(), true);
    await homeworkStore.loadByFilter(undefined, { refetch: true });
  };
  return { refetchTariff };
};
