import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { BsCloudCheck } from "react-icons/bs";
import { useDebouncedFunction } from "hooks";
import SpinnerIcon from "@rsuite/icons/legacy/Spinner";
import { emitter } from "root/EventHelper";
import { GlobalConstants } from "root/GlobalConstants";

interface IStatusSaveStudentDraft {
  text: string;
}

const StatusSaveDraftComponent: React.FC<IStatusSaveStudentDraft> = (props) => {
  const [showText, setShowText] = useState(false);
  const [isSaving, setIsSaving] = useState(false);

  const hideText = useDebouncedFunction(() => setShowText(false), 5_000);

  useEffect(() => {
    const handleDraftSaved = () => {
      setIsSaving(false);
      setShowText(true);
      hideText();
    };
    emitter.on(GlobalConstants.eventDraftSaved, handleDraftSaved);
    return () => {
      emitter.off(GlobalConstants.eventDraftSaved, handleDraftSaved);
    };
  }, [hideText]);

  useEffect(() => {
    const handleDraftSaving = () => {
      setIsSaving(true);
    };
    emitter.on(GlobalConstants.eventDraftSaving, handleDraftSaving);
    return () => {
      emitter.off(GlobalConstants.eventDraftSaving, handleDraftSaving);
    };
  }, []);

  const divStyle: React.CSSProperties = {
    display: "flex",
    alignItems: "center",
  };

  return (
    <>
      {isSaving ? (
        <div style={divStyle}>
          <SpinnerIcon pulse style={{ fontSize: "1em" }} />
          <span style={{ marginLeft: 4 }}>Сохранение...</span>
        </div>
      ) : (
        <div
          className="fade-out"
          style={{ ...divStyle, opacity: showText ? 1 : 0 }}
        >
          <BsCloudCheck />
          <span style={{ marginLeft: 4 }}>{props.text}</span>
        </div>
      )}
    </>
  );
};
export const StatusSaveDraft = observer(StatusSaveDraftComponent);
