import { IMentor, IUserFullData } from "stores";
import { del, get, post } from "../WebHelper";
import * as requests from "../requests";

export class MentorController {
  public async GetAll(): Promise<IMentor[] | undefined> {
    return await get(`/Mentor/all/`);
  }

  public async LinkStudentTariff(
    mentorId: number,
    tariffId: number,
    studentId: number
  ) {
    await post(
      `/Mentor/LinkStudentTariff/${mentorId}/${tariffId}/${studentId}`
    );
  }

  public async DeLinkStudentTariff(
    mentorId: number,
    tariffId: number,
    studentId: number
  ) {
    await del(
      `/Mentor/DeLinkStudentTariff/${mentorId}/${tariffId}/${studentId}`
    );
  }
  public async UpdateProfile(
    data: requests.IRequestUpdateUserProfile
  ): Promise<IUserFullData | undefined> {
    return await post(`/Mentor/Update`, data);
  }
}
