import { PropsWithChildren } from "react";
import { AvatarProps } from "./types";
import { Avatar as RSAvatar } from "rsuite";
import { RefForwardingComponent } from "../types";
import React from "react";

const avatar = <E extends React.ElementType>(
  { children, ...props }: PropsWithChildren<AvatarProps>,
  ref: React.ForwardedRef<E>
) => (
  <RSAvatar {...props} ref={ref}>
    {children}
  </RSAvatar>
);

export const Avatar: RefForwardingComponent<"div", AvatarProps> =
  React.forwardRef(avatar);
