import React from "react";
import { observer } from "mobx-react";
import { StudentProgressLine } from "components/ProgressChart";
import { PartPercentType } from "stores";
import { Panel } from "shared/ui";

const ProgressClassUserComponent: React.FC = () => {
  return (
    <Panel bodyFill>
      <Panel bordered>
        <span>Понимание материала</span>
        <StudentProgressLine
          strokeWidth={24}
          partPercentType={PartPercentType.AllPart}
        />
      </Panel>
    </Panel>
  );
};
export const ProgressClassUser = observer(ProgressClassUserComponent);
