import { makeAutoObservable, runInAction } from "mobx";
import Api from "../root/Api";
import { IThema } from "./models";
import { queryClient } from "provider/QueryProvider/QueryProvider";

export class ThemaStore {
  themes: IThema[];

  constructor() {
    makeAutoObservable(this);
    this.themes = [];
  }

  public filterByCategoryId(themeCategoryId?: number) {
    return this.themes.filter((x) => x.themeCategoryId === themeCategoryId);
  }

  onLoading = false;

  public find(id?: number) {
    return this.themes.find((x) => x.id === id);
  }

  public async load(refetch = false) {
    this.setOnLoading(true);
    const queryKey = ["load", "Api.themes.GetAll"];
    try {
      refetch && queryClient.removeQueries(queryKey);
      const result = await queryClient.fetchQuery({
        queryKey,
        queryFn: async () => await Api.themes.GetAll(refetch),
      });
      runInAction(() => result && (this.themes = result));
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
  }

  private setOnLoading(value: boolean) {
    this.onLoading = value;
  }
  public clear() {
    runInAction(() => {
      this.themes = [];
    });
  }
}
