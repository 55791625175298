import React, { CSSProperties } from "react";

interface ICardWithImageProps {
  imgSrc: string;
  title: string;
  description?: string;
  containerStyle?: CSSProperties;
  imageStyle?: CSSProperties;
  titleStyle?: CSSProperties;
  descriptionStyle?: CSSProperties;
}

export const CardWithImage: React.FC<ICardWithImageProps> = ({
  imgSrc,
  title,
  description,
  containerStyle,
  imageStyle,
  titleStyle,
  descriptionStyle,
}) => {
  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        flexDirection: "column",
        justifyContent: "center",
        ...containerStyle,
      }}
    >
      <img src={imgSrc} style={imageStyle}></img>
      <h3 style={titleStyle}>{title}</h3>
      {description && <p style={descriptionStyle}>{description}</p>}
    </div>
  );
};
