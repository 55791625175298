import { useThemeButton } from "containers/hooks";
import { observer } from "mobx-react";
import React from "react";
import { IconButton } from "shared/ui";

const ThemeButtonComponent: React.FC = () => {
  const { ref, handleClick, icon, style } = useThemeButton();

  return (
    <IconButton ref={ref} style={style} icon={icon} onClick={handleClick} />
  );
};
export const ThemeButton = observer(ThemeButtonComponent);
