import { useField } from "formik";
import React from "react";
import { PasswordInput } from "shared/ui/input";
import { FPasswordInputProps } from "./type";

export function FPasswordInput(props: FPasswordInputProps) {
  const [field, meta, helpers] = useField(props.name);
  const error = React.useMemo(
    () => (meta.touched && meta.error) || null,
    [meta.error, meta.touched]
  );

  const handleChange = (value: string) => {
    helpers.setValue(value);
  };

  return (
    <PasswordInput
      {...props}
      onChange={handleChange}
      onBlur={field.onBlur}
      value={field.value}
      error={error}
    />
  );
}
