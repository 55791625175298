import React, { PropsWithChildren, useId } from "react";
import { InputGroup, Input as RSInput } from "rsuite";
import { Error } from "shared/ui";
import { RefForwardingComponent } from "shared/ui/types";
import { InputProps } from "./types";
import { InputLabel } from "../InputLabel";
import { InputLeftSection, InputRightSection } from "./components";

const _Input = <E extends React.ElementType>(
  {
    children,
    error,
    labelProps,
    label,
    withAsterisk,
    wrapperProps,
    rightSection,
    leftSection,
    rightSectionPointerEvents,
    leftSectionPointerEvents,
    ...props
  }: PropsWithChildren<InputProps>,
  ref: React.ForwardedRef<E>
) => {
  const id = useId();
  const inputId = `wavege-input-${id}`;
  return (
    <InputLabel
      {...labelProps}
      label={label}
      withAsterisk={withAsterisk}
      htmlFor={inputId}
    >
      <InputGroup
        {...wrapperProps}
        className={error ? "wavege-error-form-field" : ""}
        disabled={props.disabled}
        onFocus={props.onFocus}
        onBlur={props.onBlur}
      >
        <InputLeftSection
          leftSection={leftSection}
          leftSectionPointerEvents={leftSectionPointerEvents}
        />
        <RSInput
          {...props}
          id={inputId}
          className={error ? "wavege-error-form-field" : ""}
          ref={ref}
        >
          {children}
        </RSInput>
        <InputRightSection
          rightSection={rightSection}
          rightSectionPointerEvents={rightSectionPointerEvents}
        />
      </InputGroup>
      <Error error={error} />
    </InputLabel>
  );
};

export const Input: RefForwardingComponent<"input", InputProps> =
  React.forwardRef(_Input);
