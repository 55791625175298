import { Textarea } from "components";
import { observer } from "mobx-react";
import React, { useEffect, useState } from "react";
import { DataRangeAdapter } from "root/DataRangeAdapter";
import { EnumConverter } from "root/EnumConverter";
import { TextHelper } from "root/TextHelpers";
import { ButtonToolbar, DateRangePicker, Form, SelectPicker } from "rsuite";
import { ItemDataType } from "rsuite/esm/@types/common";
import {
  DateRange,
  GlobalNotificationType,
  IGlobalNotification,
  Role,
} from "stores";
import { useStore } from "hooks";
import { startOfDay, endOfDay } from "date-fns";
import { ValidationHelper } from "root/ValidationHelper";
import { ValidationResult } from "stores/models/ValidationResult";
import { DateHelper } from "root/DateHelper";
import { Button, Input } from "shared/ui";

interface IFormGlobalNotificationProps {
  globalNotificationId?: number;
  onClose: () => void;
}

const defaultGlobalNotification: IGlobalNotification = {
  id: 0,
  title: "",
  description: "",
  dateRange: {
    begin: startOfDay(new Date(Date.now())),
    end: endOfDay(new Date(Date.now())),
  },
  type: GlobalNotificationType.Info,
};

const FormGlobalNotificationComponent: React.FC<
  IFormGlobalNotificationProps
> = ({ globalNotificationId, onClose }) => {
  const { globalNotificationStore, userStore } = useStore();
  const [formValue, setFormValue] = useState<Partial<IGlobalNotification>>(
    defaultGlobalNotification
  );
  const [validationResult, setValidationResult] = useState<ValidationResult>();
  const [userList, setUserList] = useState<ItemDataType<unknown>[]>([]);

  useEffect(() => {
    const globalNotification =
      globalNotificationStore.find(globalNotificationId);

    if (!globalNotification) {
      return;
    }
    const dateRange = {
      begin: new Date(globalNotification.dateRange.begin),
      end: new Date(globalNotification.dateRange.end),
    };

    setFormValue({
      ...globalNotification,
      dateRange,
    });
  }, [globalNotificationId]);

  useEffect(() => {
    userStore.load();
  }, []);

  useEffect(() => {
    if (!formValue) {
      return;
    }
    const filteredUsers = formValue.forRole
      ? userStore.users.filter(
          (user) =>
            EnumConverter.GetNumberByRoleNames(user.role) === formValue.forRole
        )
      : userStore.users;

    const userList = filteredUsers.map((user) => {
      const label = TextHelper.GetShortName(user);
      const value = user.id;
      return { label, value };
    });

    setUserList(userList);
  }, [userStore.users, formValue?.forRole]);

  const roleList = EnumConverter.mapEnum(Role, EnumConverter.RoleToStr);
  const notificationTypeList = EnumConverter.mapEnum(
    GlobalNotificationType,
    EnumConverter.globalNotificationTypeToStr
  );

  const onSubmit = async () => {
    if (!formValue) {
      return;
    }

    const resultValidation =
      ValidationHelper.globalNotificationValidate(formValue);
    setValidationResult(resultValidation);
    if (
      resultValidation.hasNotError() &&
      (await globalNotificationStore.save(formValue))
    ) {
      onClose();
    }
  };

  return (
    <>
      <Form
        fluid
        style={{ margin: 5 }}
        formValue={formValue}
        onChange={(value) => setFormValue(value)}
        onSubmit={onSubmit}
      >
        <Form.Group controlId="title">
          <Form.ControlLabel>Название</Form.ControlLabel>
          <Form.Control
            name="title"
            accepter={Input}
            errorMessage={validationResult?.getError("title")}
          />
        </Form.Group>
        <Form.Group controlId="description">
          <Form.ControlLabel>Описание</Form.ControlLabel>
          <Form.Control
            name="description"
            accepter={Textarea}
            errorMessage={validationResult?.getError("description")}
          />
        </Form.Group>
        <Form.Group controlId="dateRange">
          <Form.ControlLabel>Период проведения</Form.ControlLabel>
          <Form.Control
            value={
              formValue && formValue.dateRange
                ? new DataRangeAdapter(formValue.dateRange).toArray()
                : null
            }
            name="dateRange"
            accepter={DateRangePicker}
            showOneCalendar
            placeholder="Период проведения"
            ranges={DateHelper.GetPredefinedRanges(new Date())}
            placement="auto"
            format="dd-MM-yyyy HH:mm"
            errorMessage={validationResult?.getError("dateRange")}
            onChange={(value: DateRange | null) => {
              const dateRange = value
                ? new DataRangeAdapter(value).toObject()
                : undefined;
              setFormValue((prev) => ({ ...prev, dateRange }));
            }}
          />
        </Form.Group>
        <Form.Group controlId="type">
          <Form.ControlLabel>Тип уведомления</Form.ControlLabel>
          <Form.Control
            name="type"
            accepter={SelectPicker}
            data={notificationTypeList}
            style={{ width: 224 }}
            errorMessage={validationResult?.getError("type")}
          />
        </Form.Group>
        <Form.Group controlId="forRole">
          <Form.ControlLabel>Роль пользователей</Form.ControlLabel>
          <Form.Control
            name="forRole"
            accepter={SelectPicker}
            style={{ width: 224 }}
            data={roleList}
          />
        </Form.Group>
        <Form.Group controlId="userId">
          <Form.ControlLabel>Пользователь</Form.ControlLabel>
          <Form.Control
            name="userId"
            accepter={SelectPicker}
            data={userList}
            style={{ width: 224 }}
          />
        </Form.Group>
        <Form.Group>
          <ButtonToolbar>
            <Button appearance="primary" type="submit">
              Сохранить
            </Button>
            <Button
              appearance="default"
              onClick={() => {
                onClose();
              }}
            >
              Отмена
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </>
  );
};
export const FormGlobalNotification = observer(FormGlobalNotificationComponent);
