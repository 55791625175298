import { useCopyToClipboard } from "hooks";
import { Modal, Table, Whisper } from "rsuite";
import { IRegToken } from "stores";
import CopyIcon from "@rsuite/icons/Copy";
import CheckOutlineIcon from "@rsuite/icons/CheckOutline";
import { useState } from "react";
import useCurrentUrl from "hooks/useCurrentUrl";
import { Button, Input, Tooltip } from "shared/ui";

const { Cell } = Table;

interface ITokenCellProps {
  rowData?: IRegToken;
  dataKey: string;
}

export const TokenCell: React.FC<ITokenCellProps> = (props) => {
  const currentUrl = useCurrentUrl();
  const copyToClipboard = useCopyToClipboard();
  const [isCopied, setIsCopied] = useState(false);
  const [open, setOpen] = useState(false);
  const handleClose = () => setOpen(false);

  const token = props.rowData?.token;
  if (!token) {
    return <Cell {...props}></Cell>;
  }
  const copiedTimeoutDuration = 3_000;
  const linkToRegistrationWithToken = `${currentUrl}/?promo=${token}`;

  const handleOnCopy = () => {
    if (isCopied) {
      return;
    }
    setIsCopied(true);
    const timeoutId = setTimeout(
      () => setIsCopied(false),
      copiedTimeoutDuration
    );
    return () => clearTimeout(timeoutId);
  };

  const handleOnClickByCell = async () => {
    const isCopied = await copyToClipboard(linkToRegistrationWithToken);
    isCopied ? handleOnCopy() : setOpen(true);
  };

  return (
    <>
      <Modal open={open} onClose={handleClose}>
        <Modal.Header>
          <Modal.Title>Cсылка на регистрацию</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <p style={{ marginBottom: 12 }}>
            К сожалению, нам не удалось скопировать ссылку в буферобмена.
            <br />
            Выделите и скопируйте ссылку вручную.
          </p>
          <div style={{ margin: 4 }}>
            <Input readOnly value={linkToRegistrationWithToken} />
          </div>
        </Modal.Body>
        <Modal.Footer>
          <Button onClick={handleClose} appearance="subtle">
            Отмена
          </Button>
        </Modal.Footer>
      </Modal>
      <Whisper
        placement="top"
        speaker={
          <Tooltip>
            {isCopied ? "Скопированно" : "Копировать ссылку для регистрации"}
          </Tooltip>
        }
      >
        <Cell
          {...props}
          onClick={handleOnClickByCell}
          style={{ cursor: "pointer" }}
        >
          <div
            style={{
              display: "flex",
              alignItems: "center",
              gap: 4,
            }}
          >
            <span>{token}</span>
            {isCopied ? <CheckOutlineIcon color="green" /> : <CopyIcon />}
          </div>
        </Cell>
      </Whisper>
    </>
  );
};
