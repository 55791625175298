import React from "react";
import { HomeworkSummaryContent } from "./HomeworkSummaryContent";
import { Panel } from "shared/ui";

type THomeworkSummaryProps = {
  lessonId: number;
  studentId: number;
};

export const HomeworkSummary: React.FC<THomeworkSummaryProps> = ({
  lessonId,
  studentId,
}) => (
  <Panel
    bordered
    style={{
      whiteSpace: "pre-line",
      wordBreak: "break-word",
    }}
  >
    <HomeworkSummaryContent lessonId={lessonId} studentId={studentId} />
  </Panel>
);
