import { Whisper } from "rsuite";
import PlusIcon from "@rsuite/icons/Plus";
import { Link } from "react-router-dom";
import { IconButton, Tooltip } from "shared/ui";

export const CreateTask: React.FC = () => {
  return (
    <>
      <Whisper
        placement="top"
        controlId="control-id-hover"
        trigger="hover"
        speaker={<Tooltip>Добавить задание в базу</Tooltip>}
      >
        <IconButton
          size="sm"
          icon={<PlusIcon />}
          appearance="primary"
          color="orange"
          as={Link}
          to="/createtask"
        />
      </Whisper>
    </>
  );
};
