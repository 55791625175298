import { CSSProperties } from "react";
import { Placeholder } from "rsuite";
import { Panel } from "shared/ui";

interface IPlaceholderLessonItemProps {
  items: number;
  style?: CSSProperties;
}
export const PlaceholderLessonItem: React.FC<IPlaceholderLessonItemProps> = ({
  items,
  style = {},
}) => {
  const fakeArray = Array(items).fill(0);
  return (
    <>
      {fakeArray.map((_x, i) => (
        <Panel
          bordered
          style={{ ...style, marginBottom: 10, height: 72 }}
          key={i}
        >
          <Placeholder.Paragraph rows={1} active />
        </Panel>
      ))}
    </>
  );
};
