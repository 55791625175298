import { observer } from "mobx-react";
import { Whisper } from "rsuite";
import { IStudentTariffWithPayments } from "stores";
import { useState } from "react";
import { LinkModal } from "./LinkModal";
import AdminIcon from "@rsuite/icons/Admin";
import { ConfirmModal } from "components";
import { TextHelper } from "root/TextHelpers";
import { useStore } from "hooks";
import { IconButton, Tooltip } from "shared/ui";

interface IButtonChangeMentorProps {
  studentTariff: IStudentTariffWithPayments;
}

const ButtonChangeMentorComponent: React.FC<IButtonChangeMentorProps> = (
  props
) => {
  const { studentTariffStore, mentorStore, studentsStore } = useStore();
  const [isOpenLinkModal, setOpenLinkModal] = useState<boolean>(false);
  const [isOpenConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  const student = studentsStore.find(props.studentTariff.studentId);
  const studentName = student
    ? TextHelper.GetShortName(student.user, true)
    : `ID: ${props.studentTariff.studentId}. Кажется удален`;

  const mentor = mentorStore.find(props.studentTariff.mentorId);
  const mentorName = mentor
    ? TextHelper.GetShortName(mentor.user, true)
    : `ID: ${props.studentTariff.mentorId}. Кажется удален`;

  return (
    <>
      <LinkModal
        title="Назначение ментора"
        tariffId={props.studentTariff?.tariffId || undefined}
        studentId={props.studentTariff?.studentId || undefined}
        isOpen={isOpenLinkModal}
        onClose={() => {
          setOpenLinkModal(false);
        }}
      />
      <ConfirmModal
        isOpen={isOpenConfirmModal}
        onSubmit={() => {
          setOpenConfirmModal(false);
          if (props.studentTariff.mentorId) {
            mentorStore
              .deLinkStudentTariff(
                props.studentTariff.mentorId,
                props.studentTariff.tariffId,
                props.studentTariff.studentId
              )
              .then(() => studentTariffStore.loadByFilter(undefined, true));
          }
        }}
        onCancel={() => {
          setOpenConfirmModal(false);
        }}
        isLoading={studentTariffStore.onLoading}
        description={`Вы действительно хотите отвязать ментора "${mentorName}" от ученика "${studentName}"?`}
      />
      {props.studentTariff.mentorId ? (
        <Whisper
          placement="top"
          controlId="control-id-hover"
          trigger="hover"
          speaker={<Tooltip>Убрать ментора</Tooltip>}
        >
          <IconButton
            onClick={() => {
              setOpenConfirmModal(true);
            }}
            icon={<AdminIcon />}
            disabled={props.studentTariff.dateDelete !== null}
            size="sm"
            color="red"
            appearance="primary"
          />
        </Whisper>
      ) : (
        <Whisper
          placement="top"
          controlId="control-id-hover"
          trigger="hover"
          speaker={<Tooltip>Назначить ментора</Tooltip>}
        >
          <IconButton
            onClick={() => {
              setOpenLinkModal(true);
            }}
            icon={<AdminIcon />}
            disabled={props.studentTariff.dateDelete !== null}
            size="sm"
            color="green"
            appearance="primary"
          />
        </Whisper>
      )}
    </>
  );
};
export const ButtonChangeMentor = observer(ButtonChangeMentorComponent);
