import React, { useEffect } from "react";
import { observer } from "mobx-react";
import { IPaymentTariffs } from "stores";
import { useStore } from "hooks";
import { Col, Grid, Divider, Loader, Panel, Row } from "shared/ui";

interface ITariffOrderDetailsProps {
  tariff: IPaymentTariffs;
}

const TariffOrderDetailsComponent: React.FC<ITariffOrderDetailsProps> = (
  props: ITariffOrderDetailsProps
) => {
  const { tariff } = props;
  const { subjectStore, tariffStore, authStore } = useStore();

  const sale = tariff.baseAmount - tariff.factAmount;

  useEffect(() => {
    if (authStore.me) {
      return;
    }
    subjectStore.load();
  }, []);

  if (!tariff) {
    return (
      <Panel bordered style={{ marginBottom: 10 }}>
        <div style={{ padding: 76, display: "flex", justifyContent: "center" }}>
          <Loader size="lg" />
        </div>
      </Panel>
    );
  }
  return (
    <>
      <Grid fluid>
        <Row className="show-grid">
          <Col xxl={17} xl={17} lg={17} md={17} sm={17} xs={17}>
            <p>{`${subjectStore.find(tariff.subjectId)?.title || "Предмет"}. ${
              tariffStore.find(tariff.tariffId)?.title || "Тариф"
            }`}</p>
          </Col>
          <Col
            xxl={7}
            xl={7}
            lg={7}
            md={7}
            sm={7}
            xs={7}
            style={{ display: "flex", justifyContent: "flex-end" }}
          >
            {sale && sale > 0 ? (
              <div>
                <div>{tariff.factAmount.toLocaleString("ru-RU")} ₽</div>
                <div
                  style={{
                    color: "var(--rs-text-tertiary)",
                    textDecoration: "line-through",
                    textDecorationColor: "red",
                    textDecorationThickness: 2,
                  }}
                >
                  {tariff.baseAmount.toLocaleString("ru-RU")} ₽
                </div>
              </div>
            ) : (
              `${tariff.baseAmount.toLocaleString("ru-RU")} ₽`
            )}
          </Col>
        </Row>
        <Row className="show-grid">
          <Col
            xxl={20}
            xl={20}
            lg={20}
            md={20}
            sm={20}
            xs={20}
            style={{ color: "var(--rs-text-tertiary)" }}
          >
            {tariff.monthNumbers.map((x) => `Месяц ${x}`).join(", ")}
          </Col>
        </Row>
      </Grid>
      <Divider style={{ marginTop: 20, marginBottom: 20 }} />
    </>
  );
};
export const TariffOrderDetails = observer(TariffOrderDetailsComponent);
