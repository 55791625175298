import React from "react";
import { observer } from "mobx-react";
import ArrowRightLineIcon from "@rsuite/icons/ArrowRightLine";
import { Link } from "react-router-dom";
import { IconButton } from "shared/ui";

interface IPanelGoCourseProps {
  text?: string;
}

const PanelGoCourseComponent: React.FC<IPanelGoCourseProps> = (props) => {
  const text = props.text ? props.text : "давай подберем твой первый курс";
  return (
    <div
      style={{
        padding: 12,
        whiteSpace: "nowrap",
      }}
    >
      <p
        style={{
          fontSize: 15,
          overflow: "hidden",
          whiteSpace: "nowrap",
          textOverflow: "ellipsis",
          textAlign: "center",
        }}
      >
        {text}
      </p>
      <div
        style={{
          marginTop: 8,
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <IconButton
          icon={<ArrowRightLineIcon />}
          placement="right"
          appearance="primary"
          color="violet"
          as={Link}
          to="/catalog"
        >
          выбрать первый курс
        </IconButton>
      </div>
    </div>
  );
};
export const PanelGoCourse = observer(PanelGoCourseComponent);
