import { useMemo } from "react";
import { StyleHelper } from "root/StyleHelper";

const defaultStyle = { maxWidth: 300 };

export const useAnswerFirstPart = (score = 0, maxScore: number) => {
  const borderColor = useMemo(
    () => StyleHelper.getColorByScore(score, maxScore),
    [score, maxScore]
  );
  const color = useMemo(
    () => StyleHelper.getColorByScore(score, maxScore),
    [score, maxScore]
  );
  const inputStyle = { ...defaultStyle, borderColor };
  return { inputStyle, color };
};
