import { List } from "rsuite";
import { observer } from "mobx-react";
import { ILesson } from "stores";
import { EmptyStatePanel } from "components";
import { LessonsItem } from "./LessonsItem";
import { PlaceholderLessonItem } from "./PlaceholderLessonItem";

interface ILessonListProps {
  lessons: ILesson[] | undefined;
  loading: boolean;
}
const LessonsListComponent: React.FC<ILessonListProps> = (props) => {
  if (props.loading) {
    return <PlaceholderLessonItem items={props.lessons?.length || 3} />;
  }

  if (!props.lessons || props.lessons.length === 0) {
    return <EmptyStatePanel />;
  }

  return (
    <List hover bordered>
      {props.lessons.map((lesson) => (
        <LessonsItem key={lesson.id} lesson={lesson} />
      ))}
    </List>
  );
};
export const LessonsList = observer(LessonsListComponent);
