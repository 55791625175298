import { observer } from "mobx-react";
import React, { CSSProperties } from "react";
import GearIcon from "@rsuite/icons/Gear";
import { Notification } from "components/Notification/";
import { TPopoverProps } from "..";
import Scrollbars from "react-custom-scrollbars-2";
import { IconButton, Panel, Popover } from "shared/ui";

const headerStyle = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

const notificationContainerStyle: CSSProperties = {
  maxHeight: 500,
  whiteSpace: "pre-wrap",
  display: "flex",
  alignItems: "center",
  flexWrap: "wrap",
  justifyContent: "center",
};

const hrStyle = { marginBottom: 0, marginTop: 5 };

const scrollbarStyle = { maxHeight: 500, height: 500 };

const popoverStyle = { width: 400 };
const titleStyle = { fontSize: 18 };

type TPopoverNotificationProps = TPopoverProps & {
  onClickSettingsButton: () => void;
};

const PopoverNotificationComponent = React.forwardRef<
  HTMLDivElement,
  TPopoverNotificationProps
>(function PopoverProfileMenu(
  { className, left, top, onClose, onClickSettingsButton },
  ref
) {
  const handleOpenModal = () => {
    onClose();
    onClickSettingsButton();
  };

  return (
    <Popover
      ref={ref}
      className={className}
      style={{ ...popoverStyle, left, top }}
    >
      <Panel bodyFill>
        <div style={headerStyle}>
          <span style={titleStyle}>Уведомления</span>
          <IconButton
            icon={<GearIcon className="rs-icon" />}
            onClick={handleOpenModal}
          />
        </div>
      </Panel>
      <hr style={hrStyle} />
      <Scrollbars style={scrollbarStyle}>
        <Notification containerStyle={notificationContainerStyle} />
      </Scrollbars>
    </Popover>
  );
});
export const PopoverNotification = observer(PopoverNotificationComponent);
