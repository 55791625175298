import { useStore } from "hooks";
import { useMemo } from "react";
import {
  HomeworkStudentCheckStatus,
  HomeworkStudentStatus,
  IHomeworkCheck,
} from "stores";

export const useStudentHomeworkStatus = (homeworkCheck: IHomeworkCheck) => {
  const { lessonsStore } = useStore();

  const taskTitle = useMemo(
    () =>
      lessonsStore.getHomeworkTaskPack(
        homeworkCheck.lessonId,
        homeworkCheck.homeworkId,
        homeworkCheck.packId
      )?.btnTitle ?? "Домашка",
    [homeworkCheck, lessonsStore.data]
  );

  const isNotChecked =
    homeworkCheck.homeworkStatus !== HomeworkStudentStatus.sended &&
    homeworkCheck.checkStatus === HomeworkStudentCheckStatus.notChecked;

  const isNotSend =
    homeworkCheck.homeworkStatus === HomeworkStudentStatus.notSend;

  return {
    taskTitle,
    isNotSend,
    isNotChecked,
  };
};
