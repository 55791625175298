import {
  AiOutlineLike,
  AiOutlineLink,
  AiOutlineNotification,
  AiOutlinePercentage,
  AiOutlineTag,
} from "react-icons/ai";
import { BsFillJournalBookmarkFill } from "react-icons/bs";
import { FaMoneyBillTransfer } from "react-icons/fa6";
import { GoRepoForked } from "react-icons/go";
import {
  GeneralMenuItems,
  iconDropdownItemStyle,
  iconStyle,
} from "./GeneralMenuItems";
import { IMenuItem } from "./IMenuItem";

export const AdminMenu = () => {
  const menuItems: IMenuItem[] = [];
  menuItems.push(GeneralMenuItems.Home);
  menuItems.push(GeneralMenuItems.Schedule);
  menuItems.push(GeneralMenuItems.StudentDropdown);
  menuItems.push(GeneralMenuItems.Lessons);
  menuItems.push({
    title: "Курсы",
    path: "/tariffs",
    icon: <BsFillJournalBookmarkFill style={iconStyle} />,
  });
  menuItems.push({
    title: "Курсы учеников",
    path: "/student-tariffs",
    icon: <GoRepoForked style={iconStyle} />,
  });

  menuItems.push({
    title: "История оплат",
    path: "/history-orders",
    icon: <FaMoneyBillTransfer style={iconStyle} />,
  });

  menuItems.push(GeneralMenuItems.TasksDropdown);
  menuItems.push({
    title: "Скидки",
    path: "saleDrop",
    icon: <AiOutlinePercentage style={iconStyle} />,
    subItems: [
      {
        title: "Акции",
        path: "/promotions",
        icon: <AiOutlineLike style={iconDropdownItemStyle} />,
      },
      {
        title: "Промокоды",
        path: "/promocodes",
        icon: <AiOutlineTag style={iconDropdownItemStyle} />,
      },
      {
        title: "Ссылки",
        path: "/regtokens",
        icon: <AiOutlineLink style={iconDropdownItemStyle} />,
      },
    ],
  });
  menuItems.push({
    title: "Уведомления",
    path: "/notifications",
    icon: <AiOutlineNotification style={iconStyle} />,
  });

  return menuItems;
};
