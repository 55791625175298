import { makeAutoObservable, runInAction } from "mobx";
import Api from "../root/Api";
import { IUser } from "./models";
import { queryClient } from "provider/QueryProvider/QueryProvider";

export class UserStore {
  users: IUser[];
  onLoading = false;

  constructor() {
    makeAutoObservable(this);
    this.users = [];
  }

  public find(id?: number) {
    return this.users.find((x) => x.id === id);
  }

  public async load() {
    this.setOnLoading(true);
    try {
      const result = await queryClient.fetchQuery({
        queryKey: ["users"],
        queryFn: async () => await Api.users.GetAll(),
      });
      runInAction(() => result && (this.users = result));
    } catch (error) {
      console.error(error);
    } finally {
      this.setOnLoading(false);
    }
  }

  private setOnLoading(value: boolean) {
    this.onLoading = value;
  }
  public clear() {
    runInAction(() => {
      this.users = [];
    });
  }
}
