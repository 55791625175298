import { Cell } from "rsuite-table";
import { useStore } from "hooks";
import { IPromocode } from "stores";
import { observer } from "mobx-react";
import { ButtonToolbar, Whisper } from "rsuite";
import { TiUserAdd, TiUserDeleteOutline } from "react-icons/ti";
import { BsTagFill, BsCartDash, BsFillCartPlusFill } from "react-icons/bs";
import { FaUserCheck } from "react-icons/fa";
import { TbUsers } from "react-icons/tb";
import { TextHelper } from "root/TextHelpers";
import { IconButton, Tooltip } from "shared/ui";

interface IRuleCellProps {
  rowData?: IPromocode;
  dataKey: string;
}

const RuleCellComponent: React.FC<IRuleCellProps> = (props) => {
  const { studentsStore, tariffStore } = useStore();
  if (!props.rowData) {
    return <></>;
  }
  return (
    <>
      <Cell
        {...props}
        className="link-group"
        style={{
          display: "flex",

          alignContent: "center",
          flexWrap: "wrap",
        }}
      >
        <ButtonToolbar>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={
              <Tooltip>
                {props.rowData.rule.tariffId
                  ? `Промокод для курса "${
                      tariffStore.find(props.rowData.rule.tariffId)?.title
                    }"`
                  : "Промокод на всю корзину"}
              </Tooltip>
            }
          >
            <IconButton
              active
              icon={
                props.rowData.rule.tariffId ? (
                  <BsCartDash />
                ) : (
                  <BsFillCartPlusFill />
                )
              }
              size="sm"
              color="blue"
              appearance={props.rowData.rule.tariffId ? "primary" : "default"}
            />
          </Whisper>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={
              <Tooltip>
                {props.rowData.rule.studentId
                  ? `Промокод для студента "${TextHelper.GetShortName(
                      studentsStore.find(props.rowData.rule.studentId)?.user
                    )}"`
                  : "Промокод для всех учеников"}
              </Tooltip>
            }
          >
            <IconButton
              active
              icon={
                props.rowData.rule.studentId ? (
                  <TiUserAdd />
                ) : (
                  <TiUserDeleteOutline />
                )
              }
              size="sm"
              color="blue"
              appearance={props.rowData.rule.studentId ? "primary" : "default"}
            />
          </Whisper>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={
              <Tooltip>
                {props.rowData.rule.onlyWhiteCost
                  ? 'Только "белая" цена'
                  : "Акции суммируются"}
              </Tooltip>
            }
          >
            <IconButton
              active
              icon={<BsTagFill />}
              size="sm"
              color="blue"
              appearance={
                props.rowData.rule.onlyWhiteCost ? "primary" : "default"
              }
            />
          </Whisper>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={
              <Tooltip>
                {props.rowData.rule.onePerUser
                  ? "Одноразовый промокод"
                  : "Смогут применять много раз"}
              </Tooltip>
            }
          >
            <IconButton
              active
              icon={
                props.rowData.rule.onePerUser ? <FaUserCheck /> : <TbUsers />
              }
              size="sm"
              color="blue"
              appearance={props.rowData.rule.onePerUser ? "primary" : "default"}
            />
          </Whisper>
        </ButtonToolbar>
      </Cell>
    </>
  );
};
export const RuleCell = observer(RuleCellComponent);
