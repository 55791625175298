import React from "react";
import { useField } from "formik";
import { Input } from "shared/ui/input";
import { FInputProps } from "./types";

export function FInput(props: FInputProps) {
  const [field, meta, helpers] = useField(props.name);

  const error = React.useMemo(
    () => (meta.touched && meta.error) || null,
    [meta.error, meta.touched]
  );

  const handleChange = (value: string) => {
    helpers.setValue(value);
  };

  return (
    <Input
      {...props}
      onChange={handleChange}
      onBlur={field.onBlur}
      value={field.value}
      error={error}
    />
  );
}
