import { LoginCard } from "pages/Login/LoginCard";
import React from "react";
import { Modal } from "rsuite";

interface ILoginModalProps {
  isOpen: boolean;
  onClose: () => void;
}

export const LoginModal: React.FC<ILoginModalProps> = ({ isOpen, onClose }) => {
  return (
    <Modal backdrop="static" size="xs" open={isOpen} onClose={onClose}>
      <Modal.Header></Modal.Header>
      <Modal.Body>
        <LoginCard onLogin={onClose} />
      </Modal.Body>
    </Modal>
  );
};
