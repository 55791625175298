import React from "react";
import { observer } from "mobx-react";
import { List, Placeholder } from "rsuite";

const PlaceholderHomeworkChecksComponent: React.FC = () => {
  return (
    <List bordered hover>
      <List.Item>
        <Placeholder.Paragraph active />
      </List.Item>
      <List.Item>
        <Placeholder.Paragraph active />
      </List.Item>
      <List.Item>
        <Placeholder.Paragraph active />
      </List.Item>
    </List>
  );
};
export const PlaceholderHomeworkChecks = observer(
  PlaceholderHomeworkChecksComponent
);
