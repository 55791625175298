import ExpandOutlineIcon from "@rsuite/icons/ExpandOutline";
import SearchPeopleIcon from "@rsuite/icons/SearchPeople";
import TaskIcon from "@rsuite/icons/Task";
import TextImageIcon from "@rsuite/icons/TextImage";
import TrashIcon from "@rsuite/icons/Trash";
import { CSSProperties } from "react";
import { AiOutlineTrophy } from "react-icons/ai";
import {
  BsCalendar,
  BsPencilSquare,
  BsPeople,
  BsPlayBtnFill,
  BsStar,
} from "react-icons/bs";
import { FaHome as FaHomeIcon } from "react-icons/fa";
import { FiDatabase } from "react-icons/fi";
import { IMenuItem } from "./IMenuItem";

export const iconStyle: CSSProperties = {
  left: 20,
  marginRight: 20,
  position: "absolute",
};

export const iconDropdownItemStyle: CSSProperties = {
  marginRight: 6,
  textAlign: "center",
  width: 14,
};

export class GeneralMenuItems {
  /**
   * 'Главная'
   */
  static Home: IMenuItem = {
    title: "Главная",
    path: "/",
    icon: <FaHomeIcon style={iconStyle} />,
  };

  /**
   * 'Студенты'
   */
  static Students: IMenuItem = {
    title: "Студенты",
    path: "/students",
    icon: <SearchPeopleIcon />,
  };
  /**
   * 'Проверки'
   */
  static Checks: IMenuItem = {
    title: "Проверки",
    path: "/checks",
    icon: <BsPencilSquare style={iconStyle} />,
  };
  /**
   * 'Топ'
   */
  static Top: IMenuItem = {
    title: "Топ",
    path: "/top",
    icon: <AiOutlineTrophy style={iconStyle} />,
  };
  /**
   * 'Уроки'
   */
  static Lessons: IMenuItem = {
    title: "Уроки",
    path: "/lessons",
    icon: <BsPlayBtnFill style={iconStyle} />,
  };

  /**
   * 'Расписание'
   */
  static Schedule: IMenuItem = {
    title: "Расписание",
    path: "/schedule",
    icon: <BsCalendar style={iconStyle} />,
  };

  /**
   * 'База заданий' (создание, все задания, Варианты, архив и избранное)
   */
  static TasksDropdown: IMenuItem = {
    title: "База заданий",
    path: "basetaskDrop",
    icon: <FiDatabase style={iconStyle} />,
    subItems: [
      {
        title: "Создать задание",
        path: "/createtask",
        icon: <ExpandOutlineIcon />,
      },
      {
        title: "Задания",
        path: "/alltask",
        icon: <TextImageIcon />,
      },
      {
        title: "Избранное",
        path: "/taskfavorite",
        icon: <BsStar style={iconDropdownItemStyle} />,
      },
      {
        title: "Архив",
        path: "/taskarchive",
        icon: <TrashIcon />,
      },
      {
        title: "Варианты",
        path: "/alltaskpack",
        icon: <TaskIcon />,
      },
    ],
  };

  static StudentDropdown: IMenuItem = {
    title: "Ученики",
    path: "studentDrop",
    icon: <BsPeople style={iconStyle} />,
    subItems: [
      {
        ...GeneralMenuItems.Students,
        icon: GeneralMenuItems.Students.icon && (
          <GeneralMenuItems.Students.icon.type
            {...GeneralMenuItems.Students.icon.props}
            style={iconDropdownItemStyle}
          />
        ),
      },
      {
        ...GeneralMenuItems.Checks,
        icon: GeneralMenuItems.Checks.icon && (
          <GeneralMenuItems.Checks.icon.type
            {...GeneralMenuItems.Checks.icon.props}
            style={iconDropdownItemStyle}
          />
        ),
      },
      {
        ...GeneralMenuItems.Top,
        icon: GeneralMenuItems.Top.icon && (
          <GeneralMenuItems.Top.icon.type
            {...GeneralMenuItems.Top.icon.props}
            style={iconDropdownItemStyle}
          />
        ),
      },
    ],
  };
}
