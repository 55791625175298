import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { ICart, IPayOrder, IPayment, PaymentType } from "stores";
import { TariffOrderDetails } from "./TariffOrderDetails";
import { useDebouncedFunction, useIsMounted, useStore } from "hooks";
import { LinkHelper } from "root/LinkHelper";
import { Promocode } from "./Promocode";
import { DescriptionPromotions } from "./DescriptionPromotions";
import { LoginModal } from "components";
import { authStore } from "stores/AuthStore";
import { useLocalStorage } from "hooks/useLocalSessionsStorage";
import { GlobalConstants } from "root/GlobalConstants";
import { Button, Loader, Panel } from "shared/ui";

interface IOrderDetailsProps {
  cartItems: ICart;
  onChangePromocode: (promocode: string) => void;
}

const OrderDetailsComponent: React.FC<IOrderDetailsProps> = ({
  cartItems,
  onChangePromocode,
}) => {
  const isMounted = useIsMounted();
  const { paymentStore } = useStore();
  const [order, setOrder] = useState<IPayOrder>({ tariffs: [], promocode: "" });
  const [payOrder, setPayOrder] = useState<IPayment | undefined>();
  const [finishOrder, setFinishOrder] = useState<IPayOrder>();
  const [open, setOpen] = useState(false);
  const [isUnauthorizedGetPayUrl, setIsUnauthorizedGetPayUrl] =
    useLocalStorage<boolean>(GlobalConstants.isUnauthorizedGetPayUrl, false);

  useEffect(() => {
    if (!payOrder || !finishOrder || !isUnauthorizedGetPayUrl) {
      return;
    }
    getPayUrl(payOrder.factAmount);
  }, [payOrder, finishOrder]);

  useEffect(() => {
    if (!cartItems || cartItems.items.length === 0) {
      return;
    }
    const tariffsFromOrder = cartItems.items.map((x) => {
      return {
        tariffId: x.tariffId,
        type: PaymentType.Full,
        monthNumbers: x.selectedMonth,
        fullPay: false,
      };
    });
    setOrder({
      tariffs: tariffsFromOrder,
      promocode: cartItems.promocode,
    });
  }, [cartItems]);

  useEffect(() => {
    doCalculateAmount(order);
  }, [order]);

  const doCalculateAmount = useDebouncedFunction(async (order: IPayOrder) => {
    const result = await paymentStore.calculateAmount(order);
    if (!isMounted() || !result) {
      return;
    }
    setPayOrder(result);
    setFinishOrder(order);
  }, 250);

  const getPayUrl = async (factAmount: number) => {
    if (!authStore.me) {
      setIsUnauthorizedGetPayUrl(true);
      setOpen(true);
      return;
    }
    if (!finishOrder) {
      return;
    }
    const result = await paymentStore.getPayUrl(finishOrder, factAmount);
    if (result) {
      setIsUnauthorizedGetPayUrl(false);
      LinkHelper.openLink(result);
    }
  };

  const handleChangePromocode = (inputPromocode: string) => {
    if (!order) {
      return;
    }
    onChangePromocode(inputPromocode);
    setOrder((prev) => ({
      tariffs: prev.tariffs,
      promocode: inputPromocode.trim(),
    }));
  };

  if (!payOrder) {
    return (
      <>
        <h4>подробности заказа</h4>
        <Panel bordered style={{ marginBottom: 10 }}>
          <div
            style={{ padding: 76, display: "flex", justifyContent: "center" }}
          >
            <Loader size="lg" />
          </div>
        </Panel>
      </>
    );
  }

  const sale = payOrder.baseAmount - payOrder.factAmount;
  return (
    <>
      <LoginModal isOpen={open} onClose={() => setOpen(false)} />
      <h4>подробности заказа</h4>
      <Panel bordered style={{ marginBottom: 10 }}>
        {payOrder.tariffs.map((x) => {
          return <TariffOrderDetails key={x.tariffId} tariff={x} />;
        })}

        {sale > 0 && (
          <div style={{ marginBottom: 20 }}>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "-15px",
              }}
            >
              <span>сумма скидки</span>
              <span style={{ color: "green" }}>{`-${sale.toLocaleString(
                "ru-RU"
              )} ₽`}</span>
            </div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                marginTop: "12px",
              }}
            >
              <DescriptionPromotions promotions={payOrder.promotions} />
              {payOrder.promotions.find((x) => x.isPromocode) && (
                <Button
                  appearance="link"
                  color="red"
                  style={{
                    display: "flex",
                    alignItems: "flex-start",
                    width: 100,
                  }}
                  onClick={() => handleChangePromocode("")}
                >
                  отмена
                </Button>
              )}
            </div>
          </div>
        )}
        <Promocode
          payOrder={payOrder}
          onChange={(inputPromocode: string) =>
            handleChangePromocode(inputPromocode)
          }
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            marginTop: 15,
          }}
        >
          <Button
            block
            appearance="primary"
            color="violet"
            loading={paymentStore.onLoading}
            disabled={paymentStore.onLoading}
            onClick={() => {
              getPayUrl(payOrder.factAmount);
            }}
          >
            <h5>{`перейти к оплате ${payOrder.factAmount.toLocaleString(
              "ru-RU"
            )} ₽`}</h5>
          </Button>
        </div>
      </Panel>
    </>
  );
};
export const OrderDetails = observer(OrderDetailsComponent);
