import { ITag } from "./ITag";
import { ITaskText } from "./ITaskText";

export interface ITask {
  id: number;
  subject: string;
  themeId: number;
  kim: number;
  source: string;
  type: TaskType;
  question: string;
  answer: string;
  maxScore?: number;
  keys: string;
  commentForReviewer: string;
  isFavorite: boolean;
  textDto: ITaskText;
  difficulty: number;
  fileCriteriaId?: number;
  fileCriteriaUrl?: string;
  fileQuestionId?: number;
  fileQuestionUrl?: string;
  tags: ITag[];
}

export enum TaskType {
  // Первая часть (Краткий ответ)
  FirstPart = 1,
  // Вторая часть (Развернутый ответ)
  SecondPart = 2,
  // Ответ файлом
  FileAnswer = 3,
  // Задание файлом
  FileQuestion = 4,
}
