import React from "react";
import { observer } from "mobx-react";
import { Table } from "rsuite";
import { ITask } from "stores";
import { ButtonCreateTaskPack } from "./ButtonCreateTaskPack";
import {
  ActionTaskCell,
  CheckTaskCell,
  FavoritesCell,
  HtmlCell,
} from "../Cell";
import { useStore } from "hooks";
import { ThemeCell } from "components/BaseTask/Cell/ThemeCell";
import { Panel } from "shared/ui";

interface ITableTasksProps {
  tasks: ITask[] | undefined;
}

const TableTasksComponent: React.FC<ITableTasksProps> = ({ tasks }) => {
  const { taskStore } = useStore();
  const { Column, HeaderCell, Cell } = Table;

  return (
    <>
      <ButtonCreateTaskPack />

      <Panel
        bordered
        bodyFill
        style={{ marginTop: 15, maxHeight: "60vh", overflow: "auto" }}
      >
        <Table autoHeight loading={taskStore.onLoading} data={tasks}>
          <Column flexGrow={0.5} align="center">
            <HeaderCell> </HeaderCell>
            <CheckTaskCell dataKey="themeId" />
          </Column>
          <Column flexGrow={0.5} align="center">
            <HeaderCell> </HeaderCell>
            <FavoritesCell dataKey="themeId" />
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>КИМ</HeaderCell>
            <Cell dataKey="kim" />
          </Column>
          <Column flexGrow={3} fullText>
            <HeaderCell>Тема</HeaderCell>
            <ThemeCell dataKey="themeId" />
          </Column>
          <Column flexGrow={3} fullText>
            <HeaderCell>Текст задания</HeaderCell>
            <HtmlCell dataKey="question" />
          </Column>
          <Column flexGrow={2} fullText>
            <HeaderCell>Источник</HeaderCell>
            <Cell dataKey="source" />
          </Column>
          <Column flexGrow={1} align="center">
            <HeaderCell>⭐</HeaderCell>
            <Cell dataKey="difficulty" />
          </Column>
          <Column flexGrow={3} minWidth={150} align="center">
            <HeaderCell>Кнопки</HeaderCell>
            <ActionTaskCell dataKey="id" />
          </Column>
        </Table>
      </Panel>
    </>
  );
};
export const TableTasks = observer(TableTasksComponent);
