import React from "react";

interface ILogoIconProps {
  size: number;
  color: string;
}

export const LogoIcon: React.FC<ILogoIconProps> = (props: ILogoIconProps) => {
  return (
    <svg
      version="1.0"
      xmlns="http://www.w3.org/2000/svg"
      width={props.size}
      height={props.size}
      viewBox="0 0 734.000000 730.000000"
      preserveAspectRatio="xMidYMid meet"
    >
      <g
        transform="translate(0.000000,730.000000) scale(0.100000,-0.100000)"
        fill={props.color}
        stroke="none"
      >
        <path
          d="M3445 7284 c-22 -2 -92 -9 -155 -15 -441 -41 -921 -179 -1318 -378
   -351 -176 -628 -376 -907 -655 -286 -287 -470 -544 -650 -911 -222 -451 -348
   -927 -395 -1490 -18 -213 -16 -654 5 -800 88 -640 348 -1217 776 -1721 106
   -125 371 -378 504 -482 599 -465 1295 -741 2050 -812 166 -15 542 -12 717 6
   1062 109 1970 634 2575 1490 478 675 729 1560 674 2374 -57 841 -306 1515
   -780 2110 -98 123 -348 374 -476 476 -469 377 -1016 628 -1620 744 -242 46
   -400 61 -685 65 -151 1 -293 1 -315 -1z m390 -605 c875 -66 1759 -564 2316
   -1305 277 -369 432 -744 498 -1209 94 -655 18 -1356 -192 -1770 -37 -74 -136
   -220 -143 -212 -2 2 5 42 15 88 36 153 54 316 54 504 0 197 -12 319 -49 496
   -224 1073 -1215 1982 -2375 2180 -180 30 -508 37 -689 14 -306 -38 -588 -138
   -849 -300 -159 -99 -412 -318 -397 -344 13 -21 46 -11 135 40 267 151 475 175
   811 93 118 -29 187 -64 312 -156 376 -278 575 -690 555 -1148 -12 -283 -102
   -550 -268 -800 -85 -128 -291 -334 -419 -419 -282 -188 -572 -275 -910 -276
   -181 0 -267 14 -422 65 -538 180 -935 727 -1023 1413 -17 137 -20 433 -4 575
   22 204 90 482 164 673 257 657 677 1130 1260 1421 339 170 851 331 1165 367
   181 21 281 23 455 10z"
        />
      </g>
    </svg>
  );
};
