import Api from "./Api";
import { NotificationHelper } from "./NotificationHelper";

export class LogHelper {
  /**
   * @description Ловит ошибки, информирует пользователя о дальнейших действиях
   * и предупреждает Super`a о произошедшем событии.
   *
   * @param messageForUser Какое сообщение вывести для пользователя.
   * @param messageForSuper Что передать Super`u.
   * @param userName Имя пользователя, у кого случилось исключение, по дэфолту пустая строка.
   * @param level Уровень опасности исключения, по дэфолту **`Info`**.
   */
  public static Logging(
    messageForUser: string,
    messageForSuper: string,
    userName = "",
    level: LevelLoggingType = 1
  ) {
    if (level === LevelLoggingType.Info) {
      NotificationHelper.ShowInfo(messageForUser);
      Api.logger.LogInfo(userName, messageForUser, messageForSuper);
    }
    if (level === LevelLoggingType.Warning) {
      NotificationHelper.ShowAlert(messageForUser);
      Api.logger.LogInfo(userName, messageForUser, messageForSuper);
    }
    if (level === LevelLoggingType.Error) {
      NotificationHelper.ShowError(messageForUser);
      Api.logger.LogError(userName, messageForUser, messageForSuper);
    }
    if (level === LevelLoggingType.Critical) {
      NotificationHelper.ShowError(messageForUser);
      Api.logger.LogFatal(userName, messageForUser, messageForSuper);
    }
  }

  /**
   * @description Ловит ошибки, информирует пользователя о дальнейших действиях
   * и предупреждает Super`a о произошедшем событии.
   *
   * @param error Ошибка типа **`Error`**.
   * @param componentStack Компонент в котором произошла ошибка.
   * @param userName Имя пользователя, кто поймал ошибку.
   */
  public static LogFatalToBackend(
    error: Error,
    userName: string,
    componentStack: string | null = "unknown componentStack"
  ) {
    Api.logger.LogFatal(
      userName,
      error.message,
      error.stack + "\r\nAdditionalInfo componentStack: " + componentStack
    );
  }
}
export enum LevelLoggingType {
  Info = 1,
  Warning = 2,
  Error = 3,
  Critical = 4,
}
