import { LoginModal } from "components";
import React, { useState } from "react";
import { Link } from "react-router-dom";
import { GlobalConstants } from "root/GlobalConstants";
import { ButtonToolbar } from "rsuite";
import { Button } from "shared/ui";

export const ButtonToolbarForUnauthorized: React.FC = () => {
  const [open, setOpen] = useState(false);

  return (
    <>
      <LoginModal isOpen={open} onClose={() => setOpen(false)} />
      <ButtonToolbar>
        <Button onClick={() => setOpen(true)}>Войти</Button>
        <Button
          appearance="primary"
          as={Link}
          to={`/catalog/${GlobalConstants.onlyFreeTariff}`}
          className="invisible-by-max-width-993"
        >
          Попробовать бесплатно
        </Button>
      </ButtonToolbar>
    </>
  );
};
