import { z } from "zod";

export const $RequiredString = z.string({
  required_error: "Обязательное поле",
});
export const $RequiredEmail = $RequiredString.email({
  message: "Некорректный email",
});
export const $RequiredPhone = $RequiredString.min(11, {
  message: "Минимальная длина 11 символов",
});
