import React, { PropsWithChildren } from "react";
import { RefForwardingComponent } from "../types";
import { TooltipProps } from "./types";
import { Tooltip as RSTooltip } from "rsuite";

const tooltip = <E extends React.ElementType>(
  { children, ...props }: PropsWithChildren<TooltipProps>,
  ref: React.ForwardedRef<E>
) => (
  <RSTooltip {...props} ref={ref}>
    {children}
  </RSTooltip>
);

export const Tooltip: RefForwardingComponent<"div", TooltipProps> =
  React.forwardRef(tooltip);
