import { Cell } from "rsuite-table";
import { useStore } from "hooks";
import { IRegToken } from "stores";
import { observer } from "mobx-react";
import { ButtonToolbar, Whisper } from "rsuite";
import TrashIcon from "@rsuite/icons/Trash";
import { useState } from "react";
import EditIcon from "@rsuite/icons/Edit";
import { ConfirmModal } from "components";
import { EditRegTokenModal } from "../components/EditRegTokenModal";
import { IconButton, Tooltip } from "shared/ui";

interface IActionRegTokenCellProps {
  rowData?: IRegToken;
  dataKey: string;
}

const ActionRegTokenCellComponent: React.FC<IActionRegTokenCellProps> = (
  props
) => {
  const { regTokenStore } = useStore();
  const [selectedRegToken, setSelectedRegToken] = useState<
    IRegToken | undefined
  >(undefined);
  const [isOpenEditModal, setOpenEditModal] = useState<boolean>(false);
  const [isOpenConfirmModal, setOpenConfirmModal] = useState<boolean>(false);

  if (!props.rowData) {
    return <></>;
  }
  return (
    <>
      <ConfirmModal
        isOpen={isOpenConfirmModal}
        onSubmit={() => {
          setOpenConfirmModal(false);
          if (selectedRegToken) {
            regTokenStore.delete(selectedRegToken.id);
          }
        }}
        onCancel={() => {
          setOpenConfirmModal(false);
        }}
        isLoading={regTokenStore.onDeleting}
        description="Вы действительно хотите поместить акцию в архив?"
      />
      <EditRegTokenModal
        regTokenId={selectedRegToken?.id}
        tariffId={selectedRegToken?.objectId}
        token={selectedRegToken?.token}
        type={selectedRegToken?.type}
        isOpen={isOpenEditModal}
        onClose={() => setOpenEditModal(false)}
      />
      <Cell
        {...props}
        className="link-group"
        style={{
          display: "flex",
          justifyContent: "center",
          alignContent: "center",
          flexWrap: "wrap",
        }}
      >
        <ButtonToolbar>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Редактировать</Tooltip>}
          >
            <IconButton
              onClick={() => {
                setSelectedRegToken(props.rowData);
                setOpenEditModal(true);
              }}
              icon={<EditIcon />}
              size="sm"
              color="orange"
              appearance="primary"
            />
          </Whisper>
          <Whisper
            placement="top"
            controlId="control-id-hover"
            trigger="hover"
            speaker={<Tooltip>Архивировать</Tooltip>}
          >
            <IconButton
              onClick={() => {
                setSelectedRegToken(props.rowData);
                setOpenConfirmModal(true);
              }}
              icon={<TrashIcon />}
              size="sm"
              color="red"
              appearance="primary"
            />
          </Whisper>
        </ButtonToolbar>
      </Cell>
    </>
  );
};
export const ActionRegTokenCell = observer(ActionRegTokenCellComponent);
