import { DateHelper } from "root";
import { Table } from "rsuite";
const { Cell } = Table;

export const DateCell = ({ rowData, dataKey, ...props }: any) => (
  <Cell
    {...props}
    style={{
      display: "flex",
      alignContent: "center",
      flexWrap: "wrap",
      justifyContent: "center",
    }}
  >
    {DateHelper.DateToStringAndTime(rowData[dataKey])}
  </Cell>
);
