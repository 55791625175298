import { Cell } from "rsuite-table";
import { useStore } from "hooks";
import { IStudent } from "stores";
import { observer } from "mobx-react";
import { TextHelper } from "root/TextHelpers";

interface IStudentCellProps {
  rowData?: IStudent;
  dataKey: string;
}

const StudentCellComponent: React.FC<IStudentCellProps> = (props) => {
  const { studentsStore } = useStore();
  if (!props.rowData) {
    return <></>;
  }
  const studentId = props.rowData.id;
  const student = studentsStore.find(studentId);
  return (
    <Cell {...props}>
      {TextHelper.GetShortName(student?.user, true) ||
        `${studentId}. Кажется удален`}
    </Cell>
  );
};
export const StudentCell = observer(StudentCellComponent);
