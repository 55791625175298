import React from "react";
import { Tag as RSTag } from "rsuite";
import { RefForwardingComponent } from "../types";
import { PillProps } from "./types";

const pill = <E extends React.ElementType>(
  { style, children, ...props }: PillProps,
  ref: React.ForwardedRef<E>
) => (
  <RSTag
    {...props}
    ref={ref}
    style={{
      wordWrap: "break-word",
      cursor: "default",
      borderRadius: 30,
      whiteSpace: "nowrap",
      ...style,
    }}
  >
    {children}
  </RSTag>
);

export const Pill: RefForwardingComponent<"div", PillProps> =
  React.forwardRef(pill);
