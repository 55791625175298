import React, { PropsWithChildren } from "react";
import { Button as RSButton } from "rsuite";
import { RefForwardingComponent } from "../types";
import { ButtonProps } from "./types";

const button = <E extends React.ElementType>(
  { children, ...props }: PropsWithChildren<ButtonProps>,
  ref: React.ForwardedRef<E>
) => (
  <RSButton {...props} ref={ref}>
    {children}
  </RSButton>
);

export const Button: RefForwardingComponent<"button", ButtonProps> =
  React.forwardRef(button);
