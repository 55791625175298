import React from "react";
import { observer } from "mobx-react";
import { FilterByTaskProperties } from "../component/FilterByTaskProperties";
import { TableTasks } from "./component/TableTasks";
import { PageNavigator } from "../../Filter/PageNavigator";
import { usePageFilter, useStore } from "hooks";
import { GlobalConstants, IRequestGetTasksByFilter, TaskOrder } from "root";
import { ITask } from "stores";

export interface ITasksBaseProps {
  isFavorite?: boolean;
  isArchive?: boolean;
}

const TasksBaseComponent: React.FC<ITasksBaseProps> = ({
  isFavorite,
  isArchive,
}) => {
  const { taskStore } = useStore();

  const initialFilter = {
    orderBy: TaskOrder.DifficultyAsc,
    count: 10,
    page: 1,
  };

  const { filter, dataPage, setLimit, setActivePage, onChangeFilter } =
    usePageFilter<ITask, IRequestGetTasksByFilter>(
      initialFilter,
      taskStore,
      GlobalConstants.filterTasks
    );

  return (
    <>
      <FilterByTaskProperties
        onChange={onChangeFilter}
        defaultValue={filter}
        isFavorite={isFavorite}
        isArchive={isArchive}
      />
      <TableTasks tasks={dataPage?.data} />
      <div style={{ marginTop: 12 }}>
        <PageNavigator
          total={dataPage?.total}
          limit={filter.count || initialFilter.count}
          activePage={filter.page || initialFilter.page}
          onChangeLimit={setLimit}
          onChangeActivePage={setActivePage}
        />
      </div>
    </>
  );
};
export const TasksBase = observer(TasksBaseComponent);
