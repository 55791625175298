import { IStudentTariffPayment } from "stores";
import { StudentTariffPaymentIcon } from "./StudentTariffPaymentIcon";

interface IStudentTariffPaymentProps {
  payments?: IStudentTariffPayment[];
}
export const StudentTariffPayment: React.FC<IStudentTariffPaymentProps> = ({
  payments,
}) => {
  if (!payments) {
    return null;
  }
  return (
    <p>
      У студента оплачены месяца:{" "}
      {payments.map((studentTariffPayment, index, arr) => (
        <span key={studentTariffPayment.monthNumber}>
          {studentTariffPayment.monthNumber}
          <StudentTariffPaymentIcon
            studentTariffPayment={studentTariffPayment}
          />
          {index !== arr.length - 1 && `, `}
        </span>
      ))}
    </p>
  );
};
