import { FlexboxGrid, List } from "rsuite";
import { IHomeworkCheck } from "stores";
import { ActionCell } from "./Cell/ActionCell";
import { DeadlineCell } from "./Cell/DeadlineCell";
import { LessonCell } from "./Cell/LessonCell";
import { StudentCell } from "./Cell/StudentCell";

interface IProps {
  check: IHomeworkCheck;
}

export const CheckItem: React.FC<IProps> = ({ check }) => {
  return (
    <List.Item index={check.tasksPackHwStudentId}>
      <FlexboxGrid>
        <StudentCell studentId={check.studentId} />
        <LessonCell check={check} />
        <DeadlineCell check={check} />
        <ActionCell check={check} />
      </FlexboxGrid>
    </List.Item>
  );
};
