import React from "react";
import { Form } from "rsuite";
import { IStudentWithPayments } from "stores";
import { ValidationResult } from "stores/models/ValidationResult";

export interface IEditAccountFormProps {
  student?: IStudentWithPayments | null;
  onChangeForm: (formValue: any) => void;
  validationResult?: ValidationResult;
}

const EditAccountForm: React.FC<IEditAccountFormProps> = (
  props: IEditAccountFormProps
) => (
  <div style={{ marginTop: 10 }}>
    <Form
      formDefaultValue={props.student?.user}
      layout="horizontal"
      onChange={(formValue: any) => {
        props.onChangeForm(formValue);
      }}
    >
      <Form.Group controlId="lastName">
        <Form.ControlLabel>Фамилия</Form.ControlLabel>
        <Form.Control
          name="lastName"
          errorMessage={props.validationResult?.getError("lastName")}
        />
      </Form.Group>
      <Form.Group controlId="firstName">
        <Form.ControlLabel>Имя</Form.ControlLabel>
        <Form.Control
          name="firstName"
          errorMessage={props.validationResult?.getError("firstName")}
        />
      </Form.Group>
      <Form.Group controlId="middleName">
        <Form.ControlLabel>Отчество</Form.ControlLabel>
        <Form.Control
          name="middleName"
          errorMessage={props.validationResult?.getError("middleName")}
        />
      </Form.Group>
    </Form>
  </div>
);

export default EditAccountForm;
