import React from "react";
import { BiTimeFive } from "react-icons/bi";

interface ITimeEventIconProps {
  style: React.CSSProperties;
}

export const TimeEventIcon: React.FC<ITimeEventIconProps> = (
  props: ITimeEventIconProps
) => {
  return <BiTimeFive style={props.style} />;
};
