import { ITariff, IUserWithContactDataDto } from ".";

export interface IStudent {
  id: number;
  goal?: number;
  firstTestResult: number;
  userId: number;
  user: IUserWithContactDataDto;
}

export interface IStudentWithPayments extends IStudent {
  tariffs: IStudentTariffWithPayments[];
}
export interface IStudentTariffWithPayments {
  dateDelete: Date;
  enabled: boolean;
  lastMonth: number;
  lastPayedMonth: number;
  mentorId: number;
  payments: IStudentTariffPayment[];
  studentId: number;
  tariffId: number;
}

export interface IStudentTariff {
  tariff: ITariff;
  lastPayedMonth: number;
  enabled: boolean;
}

export interface IStudentTariffPayment {
  studentId: number;
  tariffId: number;
  type: PaymentType;
  amount: number;
  monthNumber?: number;
  comment: string | null;
  dateCreate: Date;
}

export enum PaymentType {
  //   Полное сопровождение. Оплата уроков с проверками домашнего задания.
  Full = 1,
  // Без сопровождения. Оплата только видео-уроков.
  VideoLessons = 2,
}
