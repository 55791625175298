import {
  IStudentTariff,
  ITariff,
  ITariffsStudentPaymentStatus,
} from "../../stores/models";
import { get } from "../WebHelper";
import { CrudController } from "./CrudController";

export class TariffController extends CrudController<ITariff> {
  constructor() {
    super("Tariff");
  }

  public async GetTariffsForStudent(
    refetch?: boolean
  ): Promise<IStudentTariff[] | undefined> {
    return await get(`/Tariff/GetForStudent/`, { refetch });
  }

  public async GetStudentTariffsPaymentStatus(
    tariffId: number
  ): Promise<ITariffsStudentPaymentStatus | undefined> {
    return await get(`/Tariff/Payments/${tariffId}`);
  }
}
