import { useStore } from "hooks";
import { useState } from "react";
import { EnumConverter, IRequestRegToken } from "root";
import { ButtonToolbar, Form, SelectPicker } from "rsuite";
import { Button, Input } from "shared/ui";
import { IRegToken, RegTokenType } from "stores";

interface IRegTokenAddProps {
  regTokenId?: number;
  tariffId?: number;
  token?: string;
  type?: RegTokenType;
  onClose?: () => void;
}

export const RegTokenAdd: React.FC<IRegTokenAddProps> = (props) => {
  const { tariffStore, regTokenStore } = useStore();
  const [formValue, setFormValue] = useState<Partial<IRegToken>>({
    id: props.regTokenId,
    objectId: props.tariffId,
    token: props.token,
    type: props.type,
  });

  const handleOnSubmit = async () => {
    let success = false;
    if (!formValue.objectId || !formValue.type) {
      return;
    }
    const regToken: IRequestRegToken = {
      objectId: formValue.objectId,
      type: formValue.type,
      token: formValue.token,
    };

    if (formValue.id) {
      success = await regTokenStore.save(
        { id: formValue.id, ...regToken },
        formValue.id
      );
    } else {
      success = await regTokenStore.save(regToken);
    }
    if (success) {
      props.onClose?.call(undefined);
    }
  };

  return (
    <div style={{ margin: 5 }}>
      <Form
        formValue={formValue}
        onChange={(formValue: Partial<IRegToken>) => {
          setFormValue(formValue);
        }}
        onSubmit={handleOnSubmit}
      >
        <Form.Group controlId="token">
          <Form.ControlLabel>Токен</Form.ControlLabel>
          <Form.Control
            name="token"
            accepter={Input}
            disabled={props.token ? true : false}
          />
        </Form.Group>

        <Form.Group controlId="objectId">
          <Form.ControlLabel>Курс</Form.ControlLabel>
          <Form.Control
            name="objectId"
            accepter={SelectPicker}
            data={tariffStore.data.map((item) => ({
              label: item.title,
              value: item.id,
            }))}
          />
        </Form.Group>
        <Form.Group controlId="type">
          <Form.ControlLabel>Тип доступа</Form.ControlLabel>
          <Form.Control
            name="type"
            accepter={SelectPicker}
            data={EnumConverter.mapEnum(
              RegTokenType,
              EnumConverter.regTokenTypeToStr
            )}
          />
        </Form.Group>

        <Form.Group>
          <ButtonToolbar>
            <Button appearance="primary" type="submit">
              Сохранить
            </Button>
            <Button
              appearance="default"
              onClick={() => {
                props.onClose?.call(undefined);
              }}
            >
              Отмена
            </Button>
          </ButtonToolbar>
        </Form.Group>
      </Form>
    </div>
  );
};
