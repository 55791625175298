import { IUser } from "../stores/models";

export class TextHelper {
  /**
   * @description Получение сокращеного ФИО, вида **`Иванов И. И.`** или **`И. И. Иванов`**
   *
   *@param user Передаём юзера **`IUser`**.
   *@param familyFirst Флаг **`boolean`** поставить фамилию в начале.

   * @returns
   * * Вовращает сокращеное ФИО юзера
   *
   * * Если юзера нет — вернет пустую строку
   */

  public static GetShortName(user?: IUser, familyFirst = false): string {
    if (user === undefined) {
      return "";
    }
    if (
      !user.firstName ||
      user.firstName.length < 1 ||
      !user.middleName ||
      user.middleName.length < 1
    ) {
      return user.lastName;
    }
    return familyFirst
      ? `${user.lastName} ${user.firstName
          .substring(0, 1)
          .toUpperCase()}.${user.middleName.substring(0, 1).toUpperCase()}.`
      : `${user.firstName.substring(0, 1).toUpperCase()}.${user.middleName
          .substring(0, 1)
          .toUpperCase()}. ${user.lastName}`;
  }
  /**
   * @description Получение полного ФИО, вида **`Иван Иванович Иванов`**.
   *
   *@param user Передаём юзера **`IUser`**.
   *
   * @returns
   * * Вовращает полное ФИО юзера
   *
   * * Если юзера нет — вернет пустую строку
   */
  public static GetFullName(user?: IUser): string {
    if (user === undefined) {
      return "";
    }
    return `${user.firstName} ${user.middleName} ${user.lastName}`;
  }
  /**
   * @description Обрезаем длинные строки.
   *
   *@param text Проверяемая строка.
   * @param maxLenght Максимальная длинна строки.
   *
   * @returns
   * * Если строка больше **`maxLenght`**
   * режет строку до разрешенного максимума и добавляет в конец **`...`**
   *
   * * Возвращает ту же строку **`text`**, если она меньше
   * или равна максимальной длинне **`maxLenght`**
   *
   * * Возвращает пустую строку, если не было **`text`**
   */
  public static ShrinkText(text: string, maxLenght = 100): string {
    if (!text) {
      return "";
    }
    if (text.length <= maxLenght) {
      return text;
    }
    return text.substring(0, maxLenght) + "...";
  }

  /**
   * @description Удаляет **`html теги`** из строки.
   *
   *@param text Проверяемая строка.
   *
   * @returns
   *  Возвращает переданную строку, без **`html тегов`**
   */
  public static removeHtmlTags = (text?: string): string => {
    if (!text) return "";
    const clean = /<[^>]*>/g;
    return text.replace(clean, "");
  };

  // TODO проверить, возможно, этот подход лучше
  // public static removeHtmlTags = (html: string): string => {
  //   const doc = new DOMParser().parseFromString(html, "text/html");
  //   return doc.documentElement.textContent || "";
  // };
}
