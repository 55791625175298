import { RawHTML } from "components/FormComponents";
import { observer } from "mobx-react";
import React from "react";
import { FormHomeworkSummary } from "./FormHomeworkSummary";
import { useHomeworkSummaryContent } from "./useHomeworkSummaryContent";

type THomeworkSummaryContentProps = {
  lessonId: number;
  studentId: number;
};

const HomeworkSummaryContentComponent: React.FC<
  THomeworkSummaryContentProps
> = ({ lessonId, studentId }) => {
  const { summary } = useHomeworkSummaryContent(lessonId, studentId);
  if (summary) {
    return <RawHTML html={summary} />;
  }

  return <FormHomeworkSummary lessonId={lessonId} studentId={studentId} />;
};

export const HomeworkSummaryContent = observer(HomeworkSummaryContentComponent);
