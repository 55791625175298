import { isBefore, isAfter } from "date-fns";

export class ValidationResult {
  private errors: IValidationError[];
  constructor() {
    this.errors = [];
  }

  /**
   * @description Получить описание ошибки по названию полю.
   *
   * @param fieldName Название поля для поиска ошибки.
   *
   * @returns Возвращает строковое описание ошибки.
   */
  public getError(fieldName: string): string | undefined {
    return this.errors.find((x) => x.fieldName === fieldName)?.message;
  }

  public hasError(fieldNameReg: string | undefined = undefined): boolean {
    if (!fieldNameReg) {
      return this.errors.length > 0;
    }
    return this.errors.some((error) => error.fieldName.includes(fieldNameReg));
  }

  /**
   * @description Получить **`ValidationResult`** для вложенной сущности.
   *
   * @param fieldNames Массив названий полей, вложенной сущности. Указывается без индекса.
   * @param index Порядковый номер сущности.
   *
   * @returns Возвращает новый экземпляр **`ValidationResult`** с ошибками вложенной сущности.
   */
  public getSubResult(fieldNames: string[], index: number): ValidationResult {
    const result = new ValidationResult();
    const namesWithIndex = fieldNames.map((f) => `${f}-${index}`);
    const filtered = this.errors.filter((error) =>
      namesWithIndex.some((name) => error.fieldName.includes(name))
    );
    const edited = filtered.map((x) => {
      const clearName = x.fieldName.replace(`-${index}`, "");
      return {
        fieldName: clearName,
        message: x.message,
      };
    });

    result.errors = edited;
    return result;
  }

  /**
   * @description Проверка отсутствия ошибок в **`ValidationResult`**.
   *
   * @returns Возвращает **`boolean`**.
   */
  public hasNotError(): boolean {
    return this.errors.length === 0;
  }

  /**
   * @description Проверяет отсутствие пробелов.
   *
   * @param value Проверяемый объект.
   * @param fieldName Название объекта с ошибкой.
   * @param message Описания ошибки.
   *
   * @returns Возвращает **`boolean`**.
   */
  public noSpace(
    value: any,
    fieldName: string,
    message: string
  ): ValidationResult | undefined {
    const valueWithoutSpaces = value.replace(/\s/g, "");
    if (value !== valueWithoutSpaces) {
      this.errors.push({
        fieldName,
        message,
      });
      return undefined;
    }
    return this;
  }

  /**
   * @description Проверяет отсутствие Русских букв.
   *
   * @param value Проверяемый объект.
   * @param fieldName Название объекта с ошибкой.
   * @param message Описания ошибки.
   *
   * @returns Возвращает **`boolean`**.
   */
  public noRussiaWord(
    value: any,
    fieldName: string,
    message: string
  ): ValidationResult | undefined {
    if (/[а-яё]/i.test(value)) {
      this.errors.push({
        fieldName,
        message,
      });
      return undefined;
    }
    return this;
  }

  /**
   * @description Проверяет что в проверяемом объекте только текст.
   *
   * @param value Проверяемый объект.
   * @param fieldName Название объекта с ошибкой.
   * @param message Описания ошибки.
   *
   * @returns Возвращает **`boolean`**.
   */
  public matches(
    value: string | undefined,
    regEx: RegExp,
    fieldName: string,
    message: string
  ): ValidationResult | undefined {
    if (value && !regEx.test(value)) {
      this.errors.push({
        fieldName,
        message,
      });
      return undefined;
    }
    return this;
  }

  /**
   * @description Проверяет что в проверяемом объекте содержатся латинские буквы.
   *
   * @param value Проверяемый объект.
   * @param fieldName Название объекта с ошибкой.
   * @param message Описания ошибки.
   *
   * @returns Возвращает **`boolean`**.
   */
  public containLetter(
    value: string | undefined,
    fieldName: string,
    message: string
  ): ValidationResult | undefined {
    if (value && !/[A-Z-a-z]/.test(value)) {
      this.errors.push({
        fieldName,
        message,
      });
      return undefined;
    }
    return this;
  }

  /**
   * @description Проверяет содержит ли проверяемый объект хоть одну заглавную букву.
   *
   * @param value Проверяемый объект.
   * @param fieldName Название объекта с ошибкой.
   * @param message Описания ошибки.
   *
   * @returns Возвращает **`boolean`**.
   */
  public containsUppercase(
    value: any,
    fieldName: string,
    message: string
  ): ValidationResult | undefined {
    if (!/[A-Z]/.test(value)) {
      this.errors.push({
        fieldName,
        message,
      });
      return undefined;
    }
    return this;
  }
  /**
   * @description Проверяет содержит ли проверяемый объект хоть одну строчную букву.
   *
   * @param value Проверяемый объект.
   * @param fieldName Название объекта с ошибкой.
   * @param message Описания ошибки.
   *
   * @returns Возвращает **`boolean`**.
   */
  public containsLowercase(
    value: any,
    fieldName: string,
    message: string
  ): ValidationResult | undefined {
    if (!/[a-z]/.test(value)) {
      this.errors.push({
        fieldName,
        message,
      });
      return undefined;
    }
    return this;
  }
  /**
   * @description Проверяет содержит ли проверяемый объект хоть одну цифру.
   *
   * @param value Проверяемый объект.
   * @param fieldName Название объекта с ошибкой.
   * @param message Описания ошибки.
   *
   * @returns Возвращает **`boolean`**.
   */
  public containsNumber(
    value: any,
    fieldName: string,
    message: string
  ): ValidationResult | undefined {
    if (!/\d/.test(value)) {
      this.errors.push({
        fieldName,
        message,
      });
      return undefined;
    }
    return this;
  }
  /**
   * @description Проверяет совпадают ли проверяемые объекты.
   *
   * @param valueOne Проверяемый объект №1.
   * @param valueTwo Проверяемый объект №2.
   * @param fieldName Название объекта с ошибкой.
   * @param message Описания ошибки.
   *
   * @returns Возвращает **`boolean`**.
   */
  public mismatch(
    valueOne: any,
    valueTwo: any,
    fieldName: string,
    message: string
  ): ValidationResult | undefined {
    if (valueOne !== valueTwo) {
      this.errors.push({
        fieldName,
        message,
      });
      return undefined;
    }
    return this;
  }

  /**
   * @description Проверка на заполнение.
   *
   * @param value Проверяемый объект.
   * @param fieldName Название объекта с ошибкой.
   * @param message Описания ошибки.
   *
   * @returns
   * *Если проверяемый объект существует — возвращает исходный **`ValidationResult`**.
   * * Если объект отсутствует  — записывает ошибку с **`fieldName`** и описанием **`message`**
   * в исходный **`ValidationResult`** и возвращает **`undefined`**.
   */

  public required(
    value: any,
    fieldName: string,
    message: string
  ): ValidationResult | undefined {
    if (value === 0) {
      return this;
    }

    if (!value) {
      this.errors.push({
        fieldName,
        message,
      });
      return undefined;
    }
    return this;
  }

  /**
   * @description Проверка минимальной длинны строки.
   * @param value Проверяемый объект.
   * @param minLength Минимальная длинна строки.
   * @param fieldName Название объекта с ошибкой.
   * @param message Описания ошибки.
   *
   * @returns
   * * Если объект отсутствует или длинна больше **`minLength`** — возвращает **`ValidationResult`** .
   * * Если проверяемый объект существует и короче минимальной
   * длинны — записывает ошибку с **`fieldName`** и описанием **`message`**
   * в исходный **`ValidationResult`** и возвращает **`undefined`**.
   */
  public lengthMore(
    value: string | undefined,
    minLength: number,
    fieldName: string,
    message: string
  ): ValidationResult | undefined {
    if (value && value.length < minLength) {
      this.errors.push({
        fieldName,
        message,
      });
      return undefined;
    }
    return this;
  }

  /**
   * @description Проверка максимальной длинны строки.
   *
   * @param value Проверяемый объект.
   * @param maxLength Максимальная длинна строки.
   * @param fieldName Название объекта с ошибкой.
   * @param message Описания ошибки.
   *
   * @returns
   * * Если объект отсутствует или меньше **`minLength`** — возвращает **`ValidationResult`** .
   * * Если проверяемый объект существует и короче минимальной
   * длинны — записывает ошибку с **`fieldName`** и описанием **`message`**
   * в исходный **`ValidationResult`** и возвращает **`undefined`**.
   */
  public lengthLess(
    value: string | undefined,
    maxLength: number,
    fieldName: string,
    message: string
  ): ValidationResult | undefined {
    if (value && value.length > maxLength) {
      this.errors.push({
        fieldName,
        message,
      });
      return undefined;
    }
    return this;
  }

  /**
   * @description Проверка на целое число.
   *
   * @param value Проверяемый объект.
   * @param fieldName Название объекта с ошибкой.
   * @param message Описания ошибки.
   *
   * @returns
   * * Если объект существует и не содержит строку — возвращает **`ValidationResult`** .
   * * Если объект отсутствует или содержит строку  — записывает ошибку
   * с **`fieldName`** и описанием **`message`**
   * в исходный **`ValidationResult`** и возвращает **`undefined`**.
   */
  public isInteger(
    value: any,
    fieldName: string,
    message: string
  ): ValidationResult | undefined {
    if (!value) {
      this.errors.push({
        fieldName,
        message,
      });
      return undefined;
    }
    const type = value?.match(/^\d+$/);
    if (!type) {
      this.errors.push({
        fieldName,
        message,
      });
      return undefined;
    }
    return this;
  }

  /**
   * @description Проверка числа минимальной допустимой границей.
   *
   * @param value Проверяемый объект.
   * @param min Минимальная граница.
   * @param fieldName Название объекта с ошибкой.
   * @param message Описания ошибки.
   *
   * @returns
   * * Если объект существует и больше минимума — возвращает **`ValidationResult`** .
   * * Если объект отсутствует меньше минимальной границы  — записывает ошибку
   * с **`fieldName`** и описанием **`message`**
   * в исходный **`ValidationResult`** и возвращает **`undefined`**.
   */
  public min(
    value: number | undefined,
    min: number,
    fieldName: string,
    message: string
  ): ValidationResult | undefined {
    if (value !== null && value !== undefined && value < min) {
      this.errors.push({
        fieldName,
        message,
      });
      return undefined;
    }
    return this;
  }

  /**
   * @description Проверка числа минимальной допустимой границей.
   *
   * @param value Проверяемый объект.
   * @param max Максимальная граница.
   * @param fieldName Название объекта с ошибкой.
   * @param message Описания ошибки.
   *
   * @returns
   * * Если объект существует и меньше минимума — возвращает **`ValidationResult`** .
   * * Если объект отсутствует больше минимальной границы  — записывает ошибку
   * с **`fieldName`** и описанием **`message`**
   * в исходный **`ValidationResult`** и возвращает **`undefined`**.
   */
  public max(
    value: number | undefined,
    max: number,
    fieldName: string,
    message: string
  ): ValidationResult | undefined {
    if (!value || value > max) {
      this.errors.push({
        fieldName,
        message,
      });
      return undefined;
    }
    return this;
  }

  /**
   * @description Проверка числа минимальной допустимой границей.
   *
   * @param value Проверяемый объект.
   * @param date Дата для проверки.
   * @param fieldName Название объекта с ошибкой.
   * @param message Описания ошибки.
   *
   * @returns
   * * Если объект существует раньше **`date`** — возвращает **`ValidationResult`** .
   * * Если объект отсутствует или после **`date`**  — записывает ошибку
   * с **`fieldName`** и описанием **`message`**
   * в исходный **`ValidationResult`** и возвращает **`undefined`**.
   */
  public dateBefore(
    value: Date | undefined,
    date: Date | undefined,
    fieldName: string,
    message: string
  ): ValidationResult | undefined {
    if (!value || !date || !isBefore(value, date)) {
      this.errors.push({
        fieldName,
        message,
      });
      return undefined;
    }
    return this;
  }

  /**
   * @description Проверка числа минимальной допустимой границей.
   *
   * @param value Проверяемый объект.
   * @param date Дата для проверки.
   * @param fieldName Название объекта с ошибкой.
   * @param message Описания ошибки.
   *
   * @returns
   * * Если объект существует после **`date`** — возвращает **`ValidationResult`** .
   * * Если объект отсутствует или раньше **`date`**  — записывает ошибку
   * с **`fieldName`** и описанием **`message`**
   * в исходный **`ValidationResult`** и возвращает **`undefined`**.
   */

  public dateAfter(
    value: Date | undefined,
    date: Date | undefined,
    fieldName: string,
    message: string
  ): ValidationResult | undefined {
    if (!value || !date || !isAfter(value, date)) {
      this.errors.push({
        fieldName,
        message,
      });
      return undefined;
    }
    return this;
  }
}

export interface IValidationError {
  fieldName: string;
  message: string;
}
