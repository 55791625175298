import { observer } from "mobx-react";
import { PartPercentType } from "stores";
import {
  StudentProgressChart,
  StudentProgressPart,
} from "components/ProgressChart";
import { Grid, Col, Row } from "shared/ui";

const DashboardCardComponent: React.FC = () => {
  return (
    <>
      <Grid fluid>
        <Row className="show-grid">
          <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
            <StudentProgressChart />
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <StudentProgressPart partPercentType={PartPercentType.AllPart} />
            <StudentProgressPart partPercentType={PartPercentType.FirstPart} />
            <StudentProgressPart partPercentType={PartPercentType.SecondPart} />
          </Col>
        </Row>
      </Grid>
    </>
  );
};
export const DashboardCard = observer(DashboardCardComponent);
