import { CopyIcon } from "hooks/copyComponent";
import { observer } from "mobx-react";
import { Stack } from "rsuite";
import { Input } from "shared/ui";
import { RoleNames } from "stores";
import { authStore } from "stores/AuthStore";

type StudentDataProps = {
  label: string;
  text: string;
};

const StudentDataComponent: React.FC<StudentDataProps> = ({ label, text }) => (
  <Stack spacing={6} direction="column" alignItems="flex-start">
    {label}
    <Stack spacing={8}>
      <Input disabled defaultValue={text} />
      {authStore.me?.role !== RoleNames.Student && <CopyIcon text={text} />}
    </Stack>
  </Stack>
);

export const StudentData = observer(StudentDataComponent);
