import React from "react";
import { LessonCol } from "../Lessons/LessonCol";
import { PlaceholderLesson } from "../Lessons/component/PlaceholderLesson";
import { Row } from "shared/ui";

export const PlaceholderLessonList: React.FC = () => (
  <Row>
    {Array(8)
      .fill(0)
      .map((_x, i) => (
        <LessonCol key={i}>
          <PlaceholderLesson items={1} />
        </LessonCol>
      ))}
  </Row>
);
