import { observer } from "mobx-react";
import React from "react";
import { usePageFilter, useStore } from "hooks";
import {
  GlobalConstants,
  HomeworksCheckOrder,
  IRequestGetHomeworksCheckByFilter,
} from "root";
import {
  HomeworkStudentCheckStatus,
  HomeworkStudentStatus,
  IHomeworkCheck,
} from "stores";
import { FilterByHomeworkCheck } from "./FilterByHomeworkCheck";
import { PageNavigator } from "components";
import { CheckList } from "./CheckList";

const ChecksBaseComponent: React.FC = () => {
  const { homeworkStore } = useStore();

  const initialFilter = {
    checkStatus: HomeworkStudentCheckStatus.notChecked,
    homeworkStatus: HomeworkStudentStatus.sended,
    count: 10,
    page: 1,
    orderBy: HomeworksCheckOrder.CheckDeadlineAsc,
  };

  const { filter, dataPage, setLimit, setActivePage, onChangeFilter } =
    usePageFilter<IHomeworkCheck, IRequestGetHomeworksCheckByFilter>(
      initialFilter,
      homeworkStore,
      GlobalConstants.filterCheck
    );

  return (
    <>
      <FilterByHomeworkCheck onChange={onChangeFilter} defaultValue={filter} />
      <CheckList
        checks={dataPage?.data}
        style={{
          marginTop: 12,
          height: "60vh",
          backgroundColor: "var(--rs-list-bg)",
        }}
        countChecks={filter.count || initialFilter.count}
      />
      <PageNavigator
        total={dataPage?.total}
        limit={filter.count || initialFilter.count}
        activePage={filter.page || initialFilter.page}
        onChangeLimit={setLimit}
        onChangeActivePage={setActivePage}
        style={{ marginTop: 12 }}
      />
    </>
  );
};

export const ChecksBase = observer(ChecksBaseComponent);
