import { CardWithImage } from "components";
import { useStore } from "hooks";
import { observer } from "mobx-react";
import { Placeholder } from "rsuite";
import { DashboardCard } from "./DashboardCard";
import { Col, Grid, Panel, Row } from "shared/ui";

const PageDashboardComponent: React.FC = () => {
  const { tariffStore } = useStore();

  if (tariffStore.onLoading) {
    return (
      <Grid fluid>
        <Row className="show-grid">
          <Col xs={24} sm={24} md={24} lg={16} xl={16} xxl={16}>
            <Panel bordered>
              <Placeholder.Graph active height={290} />
            </Panel>
          </Col>
          <Col xs={24} sm={24} md={24} lg={8} xl={8} xxl={8}>
            <Panel bordered style={{ marginBottom: 11 }}>
              <Placeholder.Paragraph active={true} />
            </Panel>
            <Panel bordered style={{ marginBottom: 11 }}>
              <Placeholder.Paragraph active={true} />
            </Panel>
            <Panel bordered style={{ marginBottom: 11 }}>
              <Placeholder.Paragraph active={true} />
            </Panel>
          </Col>
        </Row>
      </Grid>
    );
  }

  if (tariffStore.myTariffs && tariffStore.myTariffs.length === 0) {
    return (
      <CardWithImage
        imgSrc={`${process.env.PUBLIC_URL}/images/no-dashbord.png`}
        title="здесь пока ничего нет"
        description="позже здесь появятся графики твоей успеваемости"
        imageStyle={{ width: "auto", height: 125 }}
      />
    );
  }

  return <DashboardCard />;
};
export const PageDashboard = observer(PageDashboardComponent);
