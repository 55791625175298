import { observer } from "mobx-react";
import { HiOutlineShoppingBag } from "react-icons/hi";
import { useStore } from "hooks";
import { Filter } from "./components/Filter";
import { useEffect, useState } from "react";
import { IFilterCatalog } from "./IFilterCatalog";
import { ICart, ICartItem, ITariff } from "stores";
import { useLocalStorage } from "hooks/useLocalSessionsStorage";
import { Link } from "react-router-dom";
import { GlobalConstants } from "root";
import { TariffList } from "./TariffList";
import { Affix, Badge, Button } from "shared/ui";

const CatalogComponent: React.FC = () => {
  const { tariffStore, authStore, subjectStore } = useStore();
  const [tariffs, setTariffs] = useState<ITariff[]>([]);
  const [idMyTariffs, setIdMyTariffs] = useState<number[]>();
  const [tariffsInCart, setTariffsInCart] = useLocalStorage<ICart>(
    GlobalConstants.wavegeCart,
    { promocode: "", items: [] }
  );
  const [filter, setFilter] = useState<IFilterCatalog>({});

  useEffect(() => {
    if (authStore.me) {
      tariffStore.loadMyTariffs();
    }

    if (tariffStore.data.length === 0) {
      tariffStore.load();
    }
    subjectStore.load();
  }, [tariffStore.data]);

  useEffect(() => {
    if (!tariffStore.myTariffs || !tariffsInCart) {
      setIdMyTariffs([]);
      return;
    }

    const tariffIds = tariffStore.myTariffs.map((x) => x.tariff.id);
    setIdMyTariffs(tariffIds);

    const tariffsAvailableForOrder = tariffsInCart.items.filter(
      (x) => !tariffIds?.includes(x.tariffId)
    );

    setTariffsInCart((prev) => ({
      promocode: prev.promocode,
      items: tariffsAvailableForOrder,
    }));
  }, [tariffStore.myTariffs]);

  useEffect(() => {
    const filteredTariff = filterTariff(filter);
    setTariffs(filteredTariff);
  }, [tariffStore.data, idMyTariffs, filter]);

  const getTariffsAvailableForOrder = (): ITariff[] => {
    const tariffsAvailableForOrder = tariffStore.data.filter(
      (x) => x.enableForBuy && !idMyTariffs?.includes(x.id)
    );
    return tariffsAvailableForOrder;
  };

  const filterTariff = (filterCatalog: IFilterCatalog) => {
    const filteredTariff = getTariffsAvailableForOrder().filter(
      (x) =>
        (filterCatalog.tariff === 0 ? true : x.type === filterCatalog.tariff) &&
        (filterCatalog.exam === 0 ? true : x.examType === filterCatalog.exam)
    );
    return filteredTariff;
  };

  const handleOnClick = (tariff: ITariff) => {
    const foundTariff = tariffsInCart?.items.find(
      (x) => x.tariffId === tariff.id
    );
    if (!foundTariff) {
      const cartItem: ICartItem = {
        tariffId: tariff.id,
        selectedMonth: [1],
      };

      setTariffsInCart((prev) => ({
        promocode: prev.promocode,
        items: [...prev.items, cartItem],
      }));
      return;
    }
    if (!tariffsInCart) {
      return;
    }
    const filteredTariffsInCart = tariffsInCart.items.filter(
      (x) => x.tariffId !== tariff.id
    );
    setTariffsInCart((prev) => ({
      promocode: prev.promocode,
      items: filteredTariffsInCart,
    }));
  };

  return (
    <>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          padding: "0px 5px 0px 5px",
        }}
      >
        <Filter onChange={(filter) => setFilter(filter)} />
        <Affix top={50}>
          <Badge
            content={
              tariffsInCart && tariffsInCart.items.length > 0
                ? tariffsInCart?.items.length
                : false
            }
          >
            <Button
              startIcon={<HiOutlineShoppingBag />}
              appearance="primary"
              color="violet"
              size="lg"
              as={Link}
              to="/cart"
            >
              Корзина
            </Button>
          </Badge>
        </Affix>
      </div>
      <div style={{ marginTop: 20 }}>
        <TariffList
          loading={tariffStore.onLoading}
          tariffs={tariffs}
          cartItem={tariffsInCart?.items || []}
          onClick={handleOnClick}
        />
      </div>
    </>
  );
};

export const Catalog = observer(CatalogComponent);
