import { useMemo } from "react";
import { EnumConverter } from "root/EnumConverter";
import { SelectPicker } from "rsuite";
import { EOrderPaymentStatus } from "stores";

type TSelectPickerOrderPaymentStatusProps = {
  onChange: (value: EOrderPaymentStatus | null) => void;
  defaultValue?: EOrderPaymentStatus | null;
};

export const SelectPickerOrderPaymentStatus: React.FC<
  TSelectPickerOrderPaymentStatusProps
> = ({ defaultValue, onChange }) => {
  const orderPaymentStatusList = useMemo(
    () =>
      EnumConverter.mapEnum(
        EOrderPaymentStatus,
        EnumConverter.orderPaymentStatusToStr
      ),
    []
  );
  return (
    <SelectPicker
      defaultValue={defaultValue ?? undefined}
      label="Стутус оплаты"
      data={orderPaymentStatusList}
      style={{ width: 224 }}
      onChange={onChange}
    />
  );
};
