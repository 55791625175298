import { useIsMounted, useStore } from "hooks";
import { useEffect, useState } from "react";

export const useHomeworkSummaryContent = (
  lessonId: number,
  studentId: number
) => {
  const { homeworkStore } = useStore();
  const isMounted = useIsMounted();
  const [summary, setSummary] = useState<string>();

  useEffect(() => {
    homeworkStore
      .getSummary(lessonId, studentId)
      .then((summary) => isMounted() && setSummary(summary));
  }, []);

  return { summary };
};
