import { useStore } from "hooks";
import { observer } from "mobx-react";
import React, { PropsWithChildren } from "react";
import { CustomProvider } from "rsuite";
import "rsuite/dist/rsuite.min.css";
import ruRU from "rsuite/locales/ru_RU";
import "../../../node_modules/react-draft-wysiwyg/dist/react-draft-wysiwyg.css";
import "../../App.css";

const ThemeProviderComponent: React.FC<PropsWithChildren> = ({ children }) => {
  const { uiStore } = useStore();

  return (
    <CustomProvider theme={uiStore.theme} locale={ruRU}>
      {children}
    </CustomProvider>
  );
};

export const ThemeProvider = observer(ThemeProviderComponent);
