import { observer } from "mobx-react";
import PlusIcon from "@rsuite/icons/Plus";
import { useState } from "react";
import { EditTariffModal } from "./EditTariffModal";
import { Button } from "shared/ui";

const ButtonCreateTariffComponent: React.FC = () => {
  const [isOpenEditModal, setOpenEditModal] = useState<boolean>(false);

  return (
    <>
      <EditTariffModal
        isOpen={isOpenEditModal}
        onClose={() => setOpenEditModal(false)}
      />
      <Button
        data-testId="create-course-button"
        onClick={() => {
          setOpenEditModal(true);
        }}
        appearance="primary"
        color="orange"
        style={{ marginBottom: 15 }}
      >
        <PlusIcon />
      </Button>
    </>
  );
};
export const ButtonCreateTariff = observer(ButtonCreateTariffComponent);
